import { generateColors } from "@mantine/colors-generator";
import { alpha, createTheme, rem } from "@mantine/core";
import { create } from "zustand";

const defaultPrimaryColor = "#3f51b5";
const defaultSecondaryColor = "#F0B01A";

const setupTheme = (
  primaryColor,
  secondaryColor,
  tertiaryColor = "#0caf49"
) => {
  return createTheme({
    colors: {
      primary: generateColors(primaryColor),
      secondary: generateColors(secondaryColor),
      tertiary: [
        alpha(tertiaryColor, 0.1),
        alpha(tertiaryColor, 0.2),
        alpha(tertiaryColor, 0.3),
        alpha(tertiaryColor, 0.4),
        alpha(tertiaryColor, 0.6),
        alpha(tertiaryColor, 1),
        alpha(tertiaryColor, 1),
        alpha(tertiaryColor, 1),
        alpha(tertiaryColor, 1),
        alpha(tertiaryColor, 1),
      ],
    },
    primaryColor: "primary",
    fontFamily: "Open sans",
    fontSizes: {
      xs: rem(10),
      sm: rem(12),
      md: rem(14),
      lg: rem(16),
      xl: rem(20),
    },
    components: {
      Indicator: {
        styles: {
          indicator: {
            zIndex: 10
          },
        },
      },
      Tooltip: {
        defaultProps: {
          maw: 300,
          multiline: true,
          zIndex: 9999,
        },
      },
      Combobox: {
        defaultProps: {
          keepMounted: false,
          zIndex: 1500,
        },
      },
      Popover: {
        defaultProps: {
          shadow: "md",
          zIndex: 120,
        },
      },
      Modal: {
        styles: {
          title: {
            lineHeight: 1.5,
          },
        },
      },
      Text: {
        defaultProps: {
          fw: 500,
          ff: "Open sans",
          // truncate: "end"
        },
      },
      Anchor: {
        defaultProps: {
          fw: 500,
          ff: "Open sans",
        },
      },
      LoadingOverlay: {
        defaultProps: {
          zIndex: 100,
        },
      },
    },
    cursorType: "pointer",
  });
};

const themeStore = (set) => ({
  theme: setupTheme(defaultPrimaryColor, defaultSecondaryColor),
  setTheme: (primary, secondary) =>
    set({
      theme: setupTheme(
        primary || defaultPrimaryColor,
        secondary || defaultSecondaryColor
      ),
    }),
  asideCollapsed: false,
  setAsideCollapsed: (newData) => set({ asideCollapsed: newData }),

  actionColors: {
    email: "#ffb120",
    linkedin_visit: "#2075ff",
    linkedin_invitation: "#2075ff",
    linkedin_message: "#2075ff",
    event: "#b120ff",
    sms: "#0bef1a",
    smv: "#00cdd8",
    call: "#222222",
    remove_prospect: "#aa0000",
  },
  setActionColor: (newData) => set({ actionColors: newData }),
});

export const useThemeStore = create(themeStore);
