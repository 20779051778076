import { ActionIcon, Button, Group, Tooltip } from "@mantine/core";
import {
  copyWorkflow,
  deleteWorkflow,
  editWorkflow,
  getWorkflows,
} from "../../../../api/WorkflowAPI";
import TableLayoutBlock from "../../../../layouts/Table/TableLayoutBlock";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useCallback, useMemo, useState } from "react";
import numberColumn from "../../../../layouts/Table/columns/numberColumn";
import { nameColumn } from "../../../../layouts/Table/columns/nameColumn";
import { booleanColumn } from "../../../../layouts/Table/columns/booleanColumn";
import dateColumn from "../../../../layouts/Table/columns/dateColumn";
import { sharingColumn } from "../../../../layouts/Table/columns/sharingColumn";
import SelectionDeleter from "../../../../components/TableComponents/SelectionDeleter";
import ItemDeleter from "../../../../components/TableComponents/ItemDeleter";
import { useListingLoader } from "../../../../components/hooks/ListingHooks";
import MainTable from "../../../../layouts/Table/MainTable";
import { IconPlus, IconSend } from "@tabler/icons-react";
import DuplicateRessource from "../../../../components/View/DuplicateRessource";
import { tagsColumn } from "../../../../layouts/Table/columns/tagsColumn";
import WorkflowTemplates from "../templates/WorkflowTemplates";
import PageTitle from "../../../../components/View/PageTitle";
import SelectionSharer from "../../../../components/TableComponents/SelectionSharer";
import RequiredActions from "../../account/RequiredActions/RequiredActions";

const WorkflowListingPage = () => {
  const lang = useLanguage((s) => s.language);
  const apiRef = useGridApiRef();
  const { folderId } = useParams();
  const [highlight, setHighlight] = useState(null);
  const [hasQuery, setHasQuery] = useState(false);
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      numberColumn(),
      nameColumn({
        parentPath: `/campaign/create?workflow=`,
        editFunction: () => editWorkflow,
        apiRef,
        withoutSlash: true,
      }),
      booleanColumn({
        field: "programmed",
        headerName: lang.workflows.columns.programmed.name,
        falseLabel: lang.workflows.columns.programmed.false,
        trueLabel: lang.workflows.columns.programmed.true,
        width: 130,
        noColor: { error: true },
      }),
      tagsColumn({ apiRef, editFunction: () => editWorkflow }),
      sharingColumn({ apiRef, editFunction: () => editWorkflow }),
      dateColumn(),
      dateColumn("updated_on", lang.workflows.columns.updated_on),
    ],
    [
      apiRef,
      lang.workflows.columns.programmed.name,
      lang.workflows.columns.programmed.false,
      lang.workflows.columns.programmed.true,
      lang.workflows.columns.updated_on,
    ]
  );

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);

  const toolbarActions = useCallback(
    ({ selectedIds }) => {
      return (
        <>
          <SelectionDeleter
            handleDelete={deleteWorkflow}
            apiRef={apiRef}
            setTableLoading={setLoading}
            ids={selectedIds}
          />
          <SelectionSharer
            handleShare={editWorkflow}
            apiRef={apiRef}
            setTableLoading={setLoading}
            ids={selectedIds}
          />
        </>
      );
    },
    [apiRef]
  );

  const actionsCell = useCallback(
    ({ row, value }) => {
      return (
        <Group wrap="nowrap" gap={4}>
          <Tooltip label={lang.global.send}>
            <ActionIcon
              variant="subtle"
              component={Link}
              to={`/campaign/create?workflow=${row.id}`}
            >
              <IconSend size={16} />
            </ActionIcon>
          </Tooltip>

          <DuplicateRessource
            current={row}
            onFinish={() => setLoading(true)}
            closeMenu={() => {}}
            handler={copyWorkflow}
            responseField={"workflow_id"}
            isIcon
          />
          <ItemDeleter
            title_bold={`${row.name} #${row.id}`}
            handleDelete={deleteWorkflow}
            deletedMessage={lang.workflows.delete_success}
            id={row.id}
            disabled={
              !row?.delete_permission && !row?.permission?.delete_permission
            }
            apiRef={apiRef}
          />
        </Group>
      );
    },
    [apiRef, lang]
  );

  useListingLoader({
    loading,
    setLoading,
    listGetter: getWorkflows,
    responseField: "workflows",
    setRows,
    folderRouteId: "workflow-folder",
  });

  return (
    <>
      <Group className="layout-block top" w={"100%"} justify="space-between">
        <PageTitle title={lang.workflows.browser_tab_title_listing} />

        <WorkflowTemplates
          setTableLoading={setLoading}
          setHighlight={setHighlight}
          onCreated={(item) =>
            navigate("/campaign/create?libraryId=" + item?.id)
          }
        />

        <Button
          leftSection={<IconPlus size={18} />}
          radius={"sm"}
          variant="filled"
          color="primary"
          component={Link}
          to={"/campaign/create"}
        >
          {lang.header.menus.create_campaign}
        </Button>
      </Group>

      <TableLayoutBlock
        folderType={"Workflow"}
        parentRoute={"/workflows"}
        editFunction={editWorkflow}
        apiRef={apiRef}
        setTableLoading={setLoading}
      >
        <MainTable
          apiRef={apiRef}
          columns={columns}
          rows={rows.filter((item) =>
            hasQuery || folderId ? true : !item.folder
          )}
          loading={loading}
          setLoading={setLoading}
          id={"workflows-table"}
          toolbarActions={toolbarActions}
          actionsCell={actionsCell}
          hasFolder
          pageName={"workflows"}
          defaultHighlight={highlight}
          setDefaultHighlight={setHighlight}
          setHasQuery={setHasQuery}
          hasQuery={hasQuery}
        />
      </TableLayoutBlock>

      <RequiredActions />
    </>
  );
};

export default WorkflowListingPage;
