import {
  Accordion,
  ActionIcon,
  Anchor,
  Blockquote,
  CopyButton,
  Group,
  Stack,
  Switch,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import CreateApiKeyButton from "./CreateApiKeyButton";
import { useLoaderData } from "react-router-dom";
import { useLanguage } from "../../../stores/LanguageStore";
import { useMemo, useState } from "react";
import { IconCheck, IconCopy, IconInfoCircle } from "@tabler/icons-react";
import { useSessionStore } from "../../../stores/UserStore";
import PageTitle from "../../../components/View/PageTitle";

const ApiKeyUse = () => {
  const lang = useLanguage((s) => s.language);
  const reseller = useSessionStore((s) => s.reseller);
  const apiKeyProfile = useLoaderData();
  const token = apiKeyProfile.token;

  const [letPageChange, setLetPageChange] = useState(false);
  const [disableNav, setDisableNav] = useState(false);
  const [disableHeading, setDisableHeading] = useState(false);
  const [disableActions, setDisableActions] = useState(false);
  const [disableSelection, setDisableSelection] = useState(false);

  const apiKeyPages = useMemo(
    () => [
      {
        group: lang.api_keys.uses.lists.group_name,
        parent: "/contact-lists",
        values: [
          {
            name: lang.api_keys.uses.lists.all,
            value: "/",
            isList: true,
          },
          // {
          //   name: lang.api_keys.uses.lists.folder,
          //   value: "/folder",
          //   isFolder: true,
          //   type: "ContactList",
          // },
        ],
      },
      {
        group: lang.api_keys.uses.models.group_name,
        parent: "/models",
        values: [
          // {
          //   name: lang.api_keys.uses.models.folders,
          //   value: "/folder",
          //   isFolder: true,
          //   type: "Model",
          // },
          {
            name: lang.api_keys.uses.models.emails,
            value: "/email",
          },
          {
            name: lang.api_keys.uses.models.linkedin_invitations,
            value: "/linkedin-invitation",
          },
          {
            name: lang.api_keys.uses.models.linkedin_messages,
            value: "/linkedin-message",
          },
          {
            name: lang.api_keys.uses.models.sms,
            value: "/sms",
          },
          {
            name: lang.api_keys.uses.models.smv,
            value: "/smv",
          },
        ],
      },
      {
        group: lang.api_keys.uses.stats.group_name,
        parent: "/statistics",
        values: [
          {
            name: lang.api_keys.uses.stats.campaigns,
            value: "/campaigns",
          },
          {
            name: lang.api_keys.uses.stats.integrations,
            value: "/global-report",
          },
        ],
      },
      {
        group: lang.api_keys.uses.prm.group_name,
        parent: "/leads-tracking",
        values: [
          {
            name: lang.api_keys.uses.prm.main,
            value: "/",
          },
        ],
      },
    ],
    [lang]
  );

  const value = useMemo(() => {
    let pageChangeValue = letPageChange ? "&activate=true" : "";
    let navValue = disableNav ? "&disableNav=true" : "";
    let headingValue = disableHeading ? "&disableHeading=true" : "";
    let actionsValue = disableActions ? "&disableActions=true" : "";
    let selectionValue = disableSelection ? "&disableSelection=true" : "";

    return (
      `?apiKey=${token}` +
      navValue +
      headingValue +
      actionsValue +
      selectionValue +
      pageChangeValue
    );
  }, [
    disableActions,
    disableHeading,
    disableNav,
    disableSelection,
    token,
    letPageChange,
  ]);

  return (
    <>
      <Group
        className="layout-block top"
        w={"100%"}
        gap={0}
        justify="space-between"
      >
        <PageTitle title={"API : " + apiKeyProfile?.name} />

        <CreateApiKeyButton />
      </Group>

      <Stack className="layout-block" gap={"xs"} py={"lg"}>
        <Text size="sm">{lang.api_keys.uses_dialog.text}</Text>

        <Group gap={"lg"} my={"xs"}>
          <Switch
            checked={letPageChange}
            size="sm"
            onChange={(e) => setLetPageChange(e.target.checked)}
            color="green"
            label={lang.api_keys.uses_dialog.page_change}
          />
          <Switch
            checked={disableNav}
            size="sm"
            onChange={(e) => setDisableNav(e.target.checked)}
            color="red"
            label={lang.api_keys.uses_dialog.disable_header}
          />
          <Switch
            checked={disableHeading}
            size="sm"
            onChange={(e) => setDisableHeading(e.target.checked)}
            color="red"
            label={lang.api_keys.uses_dialog.disable_heading}
          />
          <Switch
            checked={disableActions}
            size="sm"
            onChange={(e) => setDisableActions(e.target.checked)}
            color="red"
            label={lang.api_keys.uses_dialog.disable_actions}
          />
          <Switch
            checked={disableSelection}
            size="sm"
            onChange={(e) => setDisableSelection(e.target.checked)}
            color="red"
            label={lang.api_keys.uses_dialog.disable_selections}
          />
        </Group>
        <Text size="sm">
          {lang.api_keys.uses_dialog.text1}{" "}
          <b>{lang.api_keys.uses_dialog.text2}</b>
        </Text>

        <TextInput
          w={"100%"}
          value={value}
          size="md"
          readOnly
          leftSection={
            <Text>
              https://{reseller?.domain_saas2 || reseller?.domain_saas}/page
            </Text>
          }
          leftSectionWidth={275}
          rightSection={
            <CopyButton value={value}>
              {({ copied, copy }) => (
                <Tooltip
                  label={copied ? lang.global.copied : lang.global.copy}
                  color={copied ? "green" : "black"}
                >
                  <ActionIcon
                    size={"lg"}
                    variant="light"
                    color={copied ? "green" : "primary"}
                    onClick={copy}
                  >
                    {copied ? <IconCheck size={20} /> : <IconCopy size={20} />}
                  </ActionIcon>
                </Tooltip>
              )}
            </CopyButton>
          }
        />

        <Blockquote
          color="secondary"
          radius={"md"}
          py={"xs"}
          pl={"lg"}
          ml={-24}
        >
          <Group>
            <IconInfoCircle size={28} opacity={0.4} />
            <Stack gap={0}>
              <Group>
                <Text>{lang.api_keys.clear_text}</Text>
                <CopyButton value={`?clearAll=true`}>
                  {({ copied, copy }) => (
                    <Tooltip
                      label={copied ? lang.global.copied : lang.global.copy}
                      color={copied ? "green" : "black"}
                    >
                      <Anchor fw={600} onClick={copy} truncate="end">
                        ?clearAll=true
                      </Anchor>
                    </Tooltip>
                  )}
                </CopyButton>
                <Text>{lang.api_keys.clear_text2}</Text>
              </Group>

              <Text c={"dimmed"}>
                Ex: https://{reseller?.domain_saas2}?clearAll=true
              </Text>
            </Stack>
          </Group>
        </Blockquote>

        <Text fw={500}>{lang.api_keys.uses_dialog.examples}</Text>

        <Accordion defaultValue={"contact-lists"} variant="contained">
          {apiKeyPages.map((page) => (
            <Accordion.Item key={page.group} value={page.group}>
              <Accordion.Control>{page.group}</Accordion.Control>
              <Accordion.Panel>
                {page.values.map((item) => (
                  <Stack key={item.value}>
                    <Group mb={"sm"}>
                      <TextInput
                        key={item.value}
                        value={`https://${
                          reseller?.domain_saas2 || reseller?.domain_saas
                        }${page.parent}${item.value}${value}`}
                        label={item.name}
                        readOnly
                        w={"100%"}
                        rightSection={
                          <CopyButton
                            value={`https://${
                              reseller?.domain_saas2 || reseller?.domain_saas
                            }${page.parent}${item.value}${value}`}
                          >
                            {({ copied, copy }) => (
                              <Tooltip
                                label={
                                  copied ? lang.global.copied : lang.global.copy
                                }
                                color={copied ? "green" : "black"}
                              >
                                <ActionIcon
                                  size={"lg"}
                                  variant="light"
                                  color={copied ? "green" : "primary"}
                                  onClick={copy}
                                >
                                  {copied ? (
                                    <IconCheck size={20} />
                                  ) : (
                                    <IconCopy size={20} />
                                  )}
                                </ActionIcon>
                              </Tooltip>
                            )}
                          </CopyButton>
                        }
                      />
                    </Group>
                  </Stack>
                ))}
              </Accordion.Panel>
            </Accordion.Item>
          ))}
        </Accordion>
      </Stack>
    </>
  );
};

export default ApiKeyUse;
