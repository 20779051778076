import { ActionIcon, Button, Tooltip } from "@mantine/core";
import { IconArrowLeft } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { useLanguage } from "../../stores/LanguageStore";

const BackButton = ({ url, isArrow = false, onClick, label, ...props }) => {
  const lang = useLanguage((s) => s.language);
  return isArrow ? (
    <Tooltip label="{lang.global.back}">
      <ActionIcon
        size={"md"}
        component={Link}
        to={url || ".."}
        variant="default"
        w={42}
        {...props}
      >
        <IconArrowLeft size={16} />
      </ActionIcon>
    </Tooltip>
  ) : (
    <Button
      onClick={onClick}
      component={onClick ? undefined : Link}
      to={onClick ? undefined : url || ".."}
      variant="subtle"
      {...props}
    >
      {label ? label : onClick ? lang.global.close : lang.global.back}
    </Button>
  );
};

export default BackButton;
