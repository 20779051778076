import { useAccountsStore } from "../stores/ConfigStore";
import { useSessionStore } from "../stores/UserStore";
import { mainAxios as axios } from "./axios";

export const getEmails = async () => {
  let response = await axios.get(`/integrations/email`);

  if (response.data.state) {
    useAccountsStore
      .getState()
      .setEmailAccounts(response.data.email_accounts_list);
    return response;
  }
};

// Mass Mailing
export const createMassMailing = async ({
  name,
  email,
  sender,
  reply_to,
  signature_id,
  sharing,
}) => {
  return axios.post(`/integrations/email/mass-mailing`, {
    name: name,
    email: email,
    reply_to: reply_to,
    sharing: sharing,
    sender,
    validated_url: `https://${
      useSessionStore.getState().reseller.domain_saas2
    }/success/email?id=%id%`,
    not_validated_url: `https://${
      useSessionStore.getState().reseller.domain_saas2
    }/error/email?id=%id%`,
    email_signatures_id: signature_id,
  });
};

export const editMassMailing = async ({
  id,
  name,
  email,
  sender,
  reply_to,
  email_signatures_id,
  sharing,
  data,
}) => {
  let request = axios.put(`/integrations/email/mass-mailing/${id}`, {
    name: name,
    email: email,
    reply_to: reply_to,
    sender,
    sharing: sharing,
    validated_url: `https://${
      useSessionStore.getState().reseller.domain_saas2
    }/success/email?id=%id%`,
    not_validated_url: `https://${
      useSessionStore.getState().reseller.domain_saas2
    }/error/email?id=%id%`,
    email_signatures_id,
    ...data,
  });

  return request;
};
export const forceValidateMassMailing = async (email_account_id) => {
  return axios.put(
    `/integrations/email/mass-mailing/${email_account_id}/force/validate`
  );
};

// SMTP
export const createSMTP = async (data, sharing) => {
  return axios.post(`/integrations/email/smtp`, {
    ...data,
    sharing: sharing,
  });
};

export const editSMTP = async ({ id, data, sharing }) => {
  let request = axios.put(`/integrations/email/smtp/${id}`, {
    ...data,
    connection_test: data?.connection_test || false,
    sharing: sharing,
  });

  return request;
};

export const deleteEmail = async ({ id }) => {
  let a = axios.delete(`/integrations/email/${id}`);

  return a;
};

export const findEmail = async (id) => {
  return axios.get(`/integrations/email/${id}`);
};

export const discoverEmail = async (email) => {
  let a = axios.get(`/integrations/email/discover/${email}`);

  return a;
};

export const updateWarmup = async ({
  id,
  warmup_enabled,
  total_warmup_per_day,
  daily_rampup,
  reply_rate_percentage,
}) => {
  return axios.put(`/integrations/email/smtp/${id}/warmup`, {
    warmup_enabled,
    total_warmup_per_day,
    daily_rampup,
    reply_rate_percentage,
  });
};

export const getWarmupStats = async (id) => {
  return axios.get(`/integrations/email/smtp/${id}/warmup/stats`);
};

// Gmail
export const createGmail = async ({
  data,
  sharing,
  redirect_url = `https://${
    useSessionStore.getState().reseller.domain_saas2
  }/success/email?id=%id%`,
}) => {
  return axios.post(`/integrations/email/gmail`, {
    ...data,
    sharing: sharing,
    final_redirect_url: redirect_url,
  });
};
export const editGmail = async ({ id, data, sharing }) => {
  return axios.put(`/integrations/email/gmail/${id}`, {
    ...data,
    sharing: sharing,
    final_redirect_url: `https://${
      useSessionStore.getState().reseller.domain_saas2
    }/success/email?id=%id%`,
  });
};

// Outlook
export const createOutlook = async ({
  data,
  sharing,
  redirect_url = `https://${
    useSessionStore.getState().reseller.domain_saas2
  }/success/email?id=%id%`,
}) => {
  return axios.post(`/integrations/email/outlook`, {
    ...data,
    sharing: sharing,
    final_redirect_url: redirect_url,
  });
};
export const editOutlook = async ({ id, data, sharing }) => {
  return axios.put(`/integrations/email/outlook/${id}`, {
    ...data,
    sharing: sharing,
    final_redirect_url: `https://${
      useSessionStore.getState().reseller.domain_saas2
    }/success/email?id=%id%`,
  });
};

// LinkedIn
export const getLinkedinAccounts = async () => {
  let response = await axios.get(`/integrations/linkedin`);

  if (response.data.state) {
    useAccountsStore
      .getState()
      .setLinkedinAccounts(response.data.linkedin_accounts_list);
    return response;
  }
};

export const findLinkedinAccount = async (id) => {
  return axios.get(`/integrations/linkedin/${id}`);
};

export const createLinkedinAccount = async (data, sharing) => {
  return axios.post(`/integrations/linkedin`, {
    ...data,
    sharing: sharing,
  });
};

export const editLinkedinAccount = async ({ id, data, sharing }) => {
  return axios.put(`/integrations/linkedin/${id}`, {
    ...data,
    sharing: sharing,
  });
};

export const deleteLinkedinAccount = async ({ id }) => {
  return axios.delete(`/integrations/linkedin/${id}`);
};

// Pools
export const getPools = async () => {
  let response = await axios.get(`/pools`);

  if (response.data.state) {
    useAccountsStore
      .getState()
      .setEmailPools(
        response.data.pools_list.filter((x) => x.type === "Email")
      );
    useAccountsStore
      .getState()
      .setLinkedinPools(
        response.data.pools_list.filter((x) => x.type === "Linkedin")
      );
  }
  return response;
};

export const findPool = async (id) => {
  let a = axios.get(`/pools/${id}`);

  return a;
};

export const createPool = async ({ name, type, sharing }) => {
  let a = axios.post(`/pools`, {
    name: name,
    type: type,
    sharing: sharing,
  });

  return a;
};

export const editPool = async ({ id, name, sharing }) => {
  let a = axios.put(`/pools/${id}`, {
    name: name,
    sharing: sharing,
  });

  return a;
};

export const deletePool = async ({ id }) => {
  let a = axios.delete(`/pools/${id}`);

  return a;
};

export const addToPool = async (pool_id, id) => {
  let a = axios.post(`/pools/${pool_id}/${id}`);

  return a;
};

export const removeFromPool = async (pool_id, id) => {
  let a = axios.delete(`/pools/${pool_id}/${id}`);

  return a;
};
