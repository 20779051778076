import { useEffect, useMemo, useRef, useState } from "react";
import { createMEmail, getMEmails } from "../../api/ModelsApi";
import {
  Button,
  Divider,
  Group,
  Loader,
  LoadingOverlay,
  Paper,
  Stack,
  TextInput,
} from "@mantine/core";
import { useLanguage } from "../../stores/LanguageStore";
import { IconDeviceFloppy } from "@tabler/icons-react";
import AddTokens from "../Adder/AddTokens";
import { getFields } from "../../api/ContactAPI";
import { useConfigStore } from "../../stores/ConfigStore";
import TextEditor from "../ExternalEditors/TextEditor";
import FilesModal from "../../pages/protected/files/FilesModal";
import Spintax from "../../pages/protected/models/emails/Spintax";
import { notifications } from "@mantine/notifications";

const EmailPreviewerEditable = ({ paperStyle, onFinish }) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(false);

  const editorRef = useRef();

  const datafields = useConfigStore((s) => s.datafields);
  const datafieldTokens = useMemo(() => {
    let df = datafields.map((x) => ({
      label: x.name,
      value: x.identifier_placeholder,
    }));

    df = [
      {
        label: lang.components.variable_manager.dialog.signature_variable_name,
        value: "%signature%",
        name: lang.components.variable_manager.dialog.signature_variable_name,
      },
      {
        label:
          lang.components.variable_manager.dialog.unsubscribe_variable_name,
        value: "%unsubscribe%",
        name: lang.components.variable_manager.dialog.unsubscribe_variable_name,
      },
      {
        label: lang.components.variable_manager.dialog.webversion_variable_name,
        value: "%webversion%",
        name: lang.components.variable_manager.dialog.webversion_variable_name,
      },
      {
        label: lang.components.variable_manager.dialog.b64_variable_name,
        value: "%email_b64%",
        name: lang.components.variable_manager.dialog.b64_variable_name,
      },
      ...df,
    ];

    return df;
  }, [datafields, lang]);

  const onSubmit = async (e) => {
    e.preventDefault();
    const fd = new FormData(e.target);
    const data = Object.fromEntries(fd);

    let html = "";
    let text = "";

    html = editorRef?.current?.getContent();
    text = editorRef?.current?.getContent({ format: "text" }) || "text";

    setLoading(true);
    return createMEmail({
      name: `Draft - ${data.subject} - ${new Date().toLocaleDateString()}`,
      html,
      text,
      subject: data.subject,
    })
      .then((response) => {
        let newId = response.data.model_id;

        getMEmails()
          .then((res) => {
            let newItemsList = res.data.models_list;
            let newItem = newItemsList.find((x) => x.id === newId);
            notifications.show({
              title: lang.models.created,
              message: lang.models.created_text,
              color: "tertiary.8",
            });
            onFinish?.(newItem);
          })
          .finally(() => {
            setLoading(false);
          });
        onFinish?.(newId);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    getFields().finally(() => {
      setLoading(false);
    });
  }, []);

  return (
    <Stack
      pos={"relative"}
      h={"100%"}
      flex={1}
      component={"form"}
      onSubmit={onSubmit}
      gap={"xs"}
    >
      <LoadingOverlay visible={loading} />
      <Paper
        component={Stack}
        withBorder
        h={"100%"}
        flex={1}
        gap={0}
        {...paperStyle}
      >
        <TextInput
          placeholder={`${lang.model_emails.create.dialog.subject} *`}
          styles={{ input: { border: 0 } }}
          id="email-subject"
          name="subject"
          required
          rightSection={
            loading ? (
              <Loader type="dots" size={16} />
            ) : (
              <AddTokens
                items={datafieldTokens}
                handleAdd={(item) => {
                  let input = document.getElementById("email-subject");
                  input.value += `{${item?.value.replaceAll("%", "")}}`;
                  input.focus();
                }}
              />
            )
          }
        />

        <Divider mt={2} />

        <TextEditor
          editorRef={editorRef}
          placeholder={lang.model_emails.create.dialog.placeholder}
          tokens={datafieldTokens}
          autoFocus={false}
        >
          <Group
            wrap="nowrap"
            style={{
              position: "absolute",
              top: 8,
              left: 140,
              zIndex: 4,
            }}
            gap={"lg"}
          >
            <Spintax editorRef={editorRef} />
            <FilesModal
              editorRef={editorRef}
              buttonProps={{ variant: "light" }}
            />
          </Group>
        </TextEditor>
      </Paper>
      <Group justify="center">
        <Button
          leftSection={<IconDeviceFloppy size={18} />}
          loading={loading}
          type="submit"
        >
          {lang.model_emails.create.dialog.submit_button}
        </Button>
      </Group>
    </Stack>
  );
};

export default EmailPreviewerEditable;
