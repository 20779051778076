import { useMemo, useState } from "react";
import { Group } from "@mantine/core";
import { useConfigStore } from "../../stores/ConfigStore";
import { useLanguage } from "../../stores/LanguageStore";
import Selector from "./Selector";
import { getSignatures } from "../../api/ConfigsApi";

function getOptionName(option) {
  return `${option.name} #${option.id}`;
}

const SignatureSelector = ({
  value,
  onChange,
  size = "sm",
  leftSection,
  ...other
}) => {
  const lang = useLanguage((s) => s.language);
  const items = useConfigStore((s) => s.email_signatures);
  const [loading, setLoading] = useState(false);
  const handleLoad = () => {
    setLoading(true);
    getSignatures().finally(() => setLoading(false));
  };

  const options = useMemo(() => {
    let result = [
      {
        label: "Miens",
        options: [],
      },
      { label: "Par partage", options: [] },
    ];
    items?.forEach((item) => {
      let shared = item?.from_sharing;
      result[shared ? 1 : 0].options.push({
        ...item,
        option_name: getOptionName(item),
      });
    });
    return items?.length > 0 ? result : [];
  }, [items]);

  return (
    <Group wrap="nowrap" align="flex-start" w={"100%"}>
      <Selector
        label={lang.emails_signature.selector_label}
        placeholder={lang.emails_signature.selector_placeholder}
        leftSection={leftSection}
        loading={loading}
        onChange={onChange}
        onDropdownOpen={handleLoad}
        options={options}
        size={size}
        value={value}
        getOptionName={getOptionName}
        hasGroup
        other={{ ...other }}
      />
    </Group>
  );
};

export default SignatureSelector;
