import { randomId } from "@mantine/hooks";
import { create } from "zustand";
import { persist } from "zustand/middleware";

const themeStore = (set) => ({
  layoutIsVertical: true,
  setLayoutIsVertical: (newData) => set({ layoutIsVertical: newData }),

  navbarClosed: false,
  setNavbarClosed: (newData) => set({ navbarClosed: newData }),
  campaignOpened: false,
  setCampaignOpened: (newData) => set({ campaignOpened: newData }),

  folderOpened: false,
  setFolderOpened: (newData) => set({ folderOpened: newData }),

  windowLoading: true,
  setWindowLoading: (newData) => set({ windowLoading: newData }),

  noAside: true,
  setNoAside: (newData) => set({ noAside: newData }),
  asideCollapsed: false,
  setAsideCollapsed: (newData) => set({ asideCollapsed: newData }),
  asideLoading: true,
  setAsideLoading: (newData) => set({ asideLoading: newData }),

  footerCollapsed: false,
  setFooterCollapsed: (newData) => set({ footerCollapsed: newData }),

  mainLoading: false,
  setMainLoading: (newData) => set({ mainLoading: newData }),

  pageTitle: "",
  setPageTitle: (newData) => set({ pageTitle: newData }),

  dragging: "",
  setDragging: (newData) => set({ dragging: newData }),

  networkError: false,
  setNetworkError: (newData) => set({ networkError: newData }),

  openedSupportLeft: false,
  setOpenedSupportLeft: (newData) => set({ openedSupportLeft: newData }),

  versionKey: randomId(),
  setVersionKey: (newData) => set({ versionKey: newData }),

  openedMaintenance: false,
  setOpenedMaintenance: (newData) => set({ openedMaintenance: newData }),
});

const errorStore = (set) => ({
  networkError: false,
  setNetworkError: (newData) => set({ networkError: newData }),
});

export const useUIStore = create(persist(themeStore, { name: "ui" }));
export const useErrorStore = create(errorStore);
