import { Popover } from "@mui/material";
import { useRef, useState } from "react";

// opener: (handleOpen, ref, opened)
const PopOver = ({ popoverChildren, opener, paperSx, onClose, ...props }) => {
  const ref = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpen = () => {
    setAnchorEl(ref.current);
    document.querySelectorAll("[data-field='sharing']").forEach((el) => {
      el.removeAttribute("tabIndex");
    });
  };
  const handleClose = () => {
    setAnchorEl(null);
    onClose?.();
  };

  return (
    <>
      {opener(handleOpen, ref, !!anchorEl)}
      {!!anchorEl && (
        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          slotProps={{
            paper: {
              sx: {
                p: 1,
                border: "1px solid #00000021",
                boxShadow: "#00000021 0 4px 10px",
                mt: 0.5,
                ...paperSx,
              },
            },
          }}
          container={document.getElementById("app")}
          // disableEnforceFocus
          disableAutoFocus
          disableRestoreFocus
          {...props}
        >
          {popoverChildren(() => setAnchorEl(null))}
        </Popover>
      )}
    </>
  );
};

export default PopOver;
