export const defaultSMTPConfigs = {
  name: "",
  email: "",
  sender: "",
  smtp_username: "",
  smtp_password: "",
  smtp_host: "",
  smtp_port: "",
  smtp_secure: "ssl",
  imap_username: null,
  imap_password: null,
  imap_host: null,
  imap_port: null,
  imap_secure: null,
  enable_tracking: true,
  sending_frequency_start: 30,
  sending_frequency_end: 300,
  warm_up: {
    day_1: 10,
    day_2: 15,
    day_3: 20,
    day_4: 25,
    day_5: 30,
    day_6: 35,
    day_7: 40,
    day_8: 45,
    day_9: 50,
    day_10: 55,
    day_11: 60,
    day_12: 70,
    day_13: 80,
    day_14: 90,
    day_15: 100,
  },
};
