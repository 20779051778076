import { getKnowledges } from "../knowledges";
import { useLanguage } from "../../../stores/LanguageStore";
import BaseGroupItem from "./BaseGroupItem";
import { Group, Title } from "@mantine/core";

const BaseList = () => {
  const lang = useLanguage((s) => s.language);
  return (
    <>
      <Title order={2} fw={500} ta={"center"}>
        Explorez la base de connaissance
      </Title>
      <Group justify="center" mt={"xl"} gap={"xl"} align="stretch">
        {getKnowledges(lang).map((item, index) => (
          <BaseGroupItem key={index} {...item} />
        ))}
      </Group>

      {/* <PopularArticles /> */}
    </>
  );
};

export default BaseList;
