import { Button, Checkbox, Paper, Stack, Text } from "@mantine/core";
import { IconChevronLeft, IconFoldUp } from "@tabler/icons-react";
import { useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";

const StepImportSettings = ({ data, handlePrev, handleImport }) => {
  const lang = useLanguage((s) => s.language);
  const [tlsSmtp, setTlsSmtp] = useState(false);
  const [tlsImap, setTlsImap] = useState(false);

  return (
    <Paper
      component={Stack}
      justify="center"
      w={500}
      radius={"lg"}
      mx={"auto"}
      ta={"center"}
      p={"xl"}
      shadow="sm"
      mt={64}
      withBorder
    >
      <Text size="lg">
        <b>{data?.length}</b> emails SMTP seront importés
      </Text>
      <Checkbox
        label={"Utiliser TLS pour les SMTP"}
        checked={tlsSmtp}
        onChange={(e) => setTlsSmtp(e.target.checked)}
        size="md"
      />
      <Checkbox
        label={"Utiliser TLS pour les IMAP"}
        checked={tlsImap}
        onChange={(e) => setTlsImap(e.target.checked)}
        size="md"
      />
      <Button
        mt={"md"}
        variant="gradient"
        leftSection={<IconFoldUp />}
        onClick={() => handleImport({ tlsSmtp, tlsImap })}
        size="md"
      >
        {lang.contact_lists.import.step_importing.import}
      </Button>
      <Button
        variant="subtle"
        onClick={handlePrev}
        leftSection={<IconChevronLeft />}
        size="md"
      >
        {lang.global.back}
      </Button>
    </Paper>
  );
};

export default StepImportSettings;
