import {
  Anchor,
  Button,
  Flex,
  Group,
  Menu,
  Stack,
  Text,
  ThemeIcon,
} from "@mantine/core";
import {
  IconAi,
  IconArrowBadgeUpFilled,
  IconBoxModel2,
  IconChevronDown,
  IconDeviceMobileMessage,
  IconFilePencil,
  IconHtml,
  IconMail,
  IconMessage,
  IconPlus,
  IconSparkles,
  IconUserPlus,
} from "@tabler/icons-react";
import { Link, useParams } from "react-router-dom";
import { getModelTypeProps } from "./modelUtils";
import { useLanguage } from "../../../stores/LanguageStore";
import { useProfileStore } from "../../../stores/UserStore";
import { useMemo } from "react";
import { modals } from "@mantine/modals";
import { getPermission } from "../../../functions/utilFunctions";
import UpgradeIllustration from "../../../layouts/ErrorModal/UpgradeIllustration";

const iconStyles = { size: 18 };

const ModalContent = () => {
  const lang = useLanguage((s) => s.language);

  const handleClose = () => {
    modals.close("not-allowed");
  };

  return (
    <Stack>
      <Text c={"red"} size="xl" ta={"center"} pl={32}>
        {lang.model_invitation_linkedin.modal_error.text}
      </Text>

      {getPermission("displayPricing") && (
        <Group wrap="nowrap" pl={32}>
          <Stack gap={"md"}>
            <Text size="lg">{lang.errors.modal.not_allowed}</Text>

            <Group>
              <Anchor href={"/account-settings/subscription"} target="_blank">
                <Button
                  size="md"
                  leftSection={
                    <ThemeIcon variant={"subtle"} size={28} color={"white"}>
                      <IconArrowBadgeUpFilled size={20} />
                    </ThemeIcon>
                  }
                  onClick={handleClose}
                >
                  {lang.errors.modal.upgrade}
                </Button>
              </Anchor>
            </Group>
          </Stack>

          <Flex w={300}>
            <UpgradeIllustration />
          </Flex>
        </Group>
      )}
    </Stack>
  );
};

const CreateModel = ({ inTable, variant = "filled" }) => {
  const profile = useProfileStore((s) => s.profile);
  const lang = useLanguage((s) => s.language);
  const { folderId, modelType } = useParams();

  const allowed = useMemo(() => {
    return modelType === "email"
      ? true
      : profile.permissions?.find(
          (x) => x.name === getModelTypeProps(modelType)?.create_permission
        )?.value === true;
  }, [modelType, profile?.permissions]);

  const handleModal = () => {
    modals.open({
      modalId: "not-allowed",
      children: <ModalContent />,
      size: "lg",
    });
  };

  return folderId ? (
    <Menu shadow="md" width={200} position="bottom-end" withArrow offset={2}>
      <Menu.Target>
        <Button
          leftSection={<IconPlus size={18} />}
          radius={"sm"}
          variant={variant}
          color="primary"
          rightSection={<IconChevronDown size={18} />}
        >
          {lang.models.create_model}
        </Button>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item
          leftSection={<IconMail {...iconStyles} />}
          component={Link}
          to={`/models/folder/${folderId}/create/email`}
        >
          Email
        </Menu.Item>

        {profile.permissions?.find(
          (x) =>
            x.name ===
            getModelTypeProps("linkedin-invitation")?.create_permission
        )?.value === true && (
          <Menu.Item
            leftSection={<IconUserPlus {...iconStyles} />}
            component={Link}
            to={`/models/folder/${folderId}/create/linkedin-invitation`}
          >
            Invitation LinkedIn
          </Menu.Item>
        )}

        {profile.permissions?.find(
          (x) =>
            x.name === getModelTypeProps("linkedin-message")?.create_permission
        )?.value === true && (
          <Menu.Item
            leftSection={<IconMessage {...iconStyles} />}
            component={Link}
            to={`/models/folder/${folderId}/create/linkedin-message`}
          >
            Message LinkedIn
          </Menu.Item>
        )}

        {profile.permissions?.find(
          (x) => x.name === getModelTypeProps("sms")?.create_permission
        )?.value === true && (
          <Menu.Item
            leftSection={<IconDeviceMobileMessage {...iconStyles} />}
            component={Link}
            to={`/models/folder/${folderId}/create/sms`}
          >
            SMS
          </Menu.Item>
        )}
      </Menu.Dropdown>
    </Menu>
  ) : inTable ? (
    <Button
      component={allowed ? Link : undefined}
      to={
        folderId
          ? `/models/folder/${folderId}/create`
          : `/models/${modelType}/create`
      }
      leftSection={<IconPlus size={32} />}
      radius={"sm"}
      variant="light"
      color="tertiary.8"
      size="xl"
      onClick={allowed ? undefined : handleModal}
    >
      {lang.models.create_an} {getModelTypeProps(modelType)?.name}
    </Button>
  ) : (
    //  : modelType === "email" && profile?.level === "admin" ? (
    //   <Menu shadow="md" width={175} position="bottom-end" withArrow offset={2}>
    //     <Menu.Target>
    //       <Button
    //         leftSection={<IconPlus size={18} />}
    //         rightSection={<IconChevronDown size={16} />}
    //         radius={"sm"}
    //         color="primary"
    //         variant={variant}
    //         onClick={allowed ? undefined : handleModal}
    //       >
    //         {lang.models.create_an} {getModelTypeProps(modelType)?.name}
    //       </Button>
    //     </Menu.Target>

    //     <Menu.Dropdown>
    //       <Menu.Item
    //         leftSection={<IconFilePencil {...iconStyles} />}
    //         component={Link}
    //         to={
    //           folderId
    //             ? `/models/folder/${folderId}/create`
    //             : `/models/${modelType}/create`
    //         }
    //       >
    //         {lang.models.html_model}
    //       </Menu.Item>
    //       <Menu.Item
    //         leftSection={<IconSparkles {...iconStyles} />}
    //         component={Link}
    //         to={
    //           folderId
    //             ? `/models/folder/${folderId}/create-ai`
    //             : `/models/${modelType}/create-ai`
    //         }
    //       >
    //         {lang.models.ai_template}
    //       </Menu.Item>
    //     </Menu.Dropdown>
    //   </Menu>
    // )
    <Button
      component={allowed ? Link : undefined}
      to={
        folderId
          ? `/models/folder/${folderId}/create`
          : `/models/${modelType}/create`
      }
      leftSection={<IconPlus size={18} />}
      radius={"sm"}
      color="primary"
      onClick={allowed ? undefined : handleModal}
      variant={variant}
    >
      {lang.models.create_an} {getModelTypeProps(modelType)?.name}
    </Button>
  );
};

export default CreateModel;
