import { Button, Group, Radio, Stack, Tooltip } from "@mantine/core";
import { useState } from "react";
import CreatableContactListSelector from "../../../../../components/Selectors/CreatableContactListSelector";
import PRMStatusSelector from "../../../../../components/Selectors/PRMStatusSelector";
import { useLanguage } from "../../../../../stores/LanguageStore";
import UserSelector from "../../../../../components/UserSelector/UserSelector";
import { IconCopy } from "@tabler/icons-react";
import { transformFilterToServer } from "../../../../../functions/utilFunctions";
import {
  copyContactListToPrm,
  copyContactsToPrm,
  copyList,
  copyListGlobal,
  copyListToUser,
} from "../../../../../api/ContactAPI";
import { notifications } from "@mantine/notifications";

const CopyDestination = ({
  id,
  type,
  label,
  isGlobal,
  handleClose,
  onFinish,
  selectedIds,
  filter,
}) => {
  const lang = useLanguage((s) => s.language);
  const [value, setValue] = useState("list");
  const [list, setList] = useState(null);
  const [status, setStatus] = useState(null);
  const [user, setUser] = useState(null);

  const [loading, setLoading] = useState(false);

  const handleValueChange = (change) => {
    setValue(change);
    setTimeout(() => {
      document.getElementById(`${change}-selector`);
    }, 50);
  };

  const main = () => {
    let mainFunction;

    if (value === "list" || value === "all") {
      mainFunction = isGlobal ? copyListGlobal : copyList;
    }
    if (value === "user") {
      mainFunction = copyListToUser;
    }
    if (value === "prm") {
      mainFunction = isGlobal ? copyContactsToPrm : copyContactListToPrm;
    }

    const data = {
      id,
      user_id: user?.id,
      destination_id: list?.id,
      status: parseInt(status) ? "opener" : status,
      custom_status: parseInt(status) || null,
      ids: selectedIds, // On GLobal & PRM select only
      contact_list_id: id,
      contacts_selection:
        type === "all" && !isGlobal
          ? undefined
          : {
              contact_ids:
                type === "selection_inverse" ? [] : selectedIds || [],
              excluded_contact_ids:
                type === "selection_inverse" ? selectedIds : [],
              filter:
                type === "filter" || type === "all"
                  ? transformFilterToServer(filter)
                  : { mode: "or", values: [] },
            },
    };

    setLoading(true);
    mainFunction(data)
      .then((res) => {
        onFinish?.(type);
        notifications.show({
          message: lang.contact_lists.content.copy_selection.success,
          color: "tertiary.8",
        });
        handleClose();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Stack w={300}>
      <Radio.Group
        value={value}
        onChange={handleValueChange}
        name="value"
        label={label + " vers"}
      >
        <Stack gap={4} mt={"sm"}>
          <Radio
            size="sm"
            value="list"
            label={lang.contact_lists.to_other_list}
          />
          <CreatableContactListSelector
            noLabel
            setValue={setList}
            value={list}
            disabled={value !== "list"}
            id={"list-selector"}
          />
        </Stack>
        {((isGlobal && type === "selection") || !isGlobal) && (
          <Stack mt={"sm"} gap={4}>
            <Radio size="sm" value="prm" label={lang.routes.prm} />
            <PRMStatusSelector
              onChange={setStatus}
              value={status}
              disabled={value !== "prm"}
              noLabel
              id={"prm-selector"}
            />
          </Stack>
        )}
        {!isGlobal && (
          <Stack mt={"sm"} gap={4}>
            <Radio
              size="sm"
              value="user"
              label={lang.contact_lists.copy_to_user.dialog.user}
            />
            <UserSelector
              onChange={setUser}
              placeholder={lang.contact_lists.copy_to_user.dialog.user}
              disabled={value !== "user"}
              id={"user-selector"}
            />
          </Stack>
        )}
      </Radio.Group>
      <Group justify="end">
        <Tooltip label="En developpement">
          <Button
            leftSection={<IconCopy size={16} />}
            onClick={main}
            loading={loading}
          >
            {lang.contact_lists.copy_to_user.dialog.submit_button}
          </Button>
        </Tooltip>
      </Group>
    </Stack>
  );
};

export default CopyDestination;
