import EmailEditor from "react-email-editor";
import { useLanguage } from "../../stores/LanguageStore";
import { useState } from "react";
import { Loader, Skeleton } from "@mantine/core";
import { Stack } from "@mui/material";

const TemplateEditor = ({ editorRef, onReady, height }) => {
  const lang = useLanguage((s) => s.language);
  const [initialized, setInitialized] = useState(false);

  return (
    <Stack
      position={"relative"}
      height={height}
      width={"100%"}
      sx={{
        flex: "1 1 auto",
        "& iframe": {
          minWidth: "480px !important",
        },
      }}
    >
      {!initialized && (
        <>
          <Stack
            pos={"absolute"}
            top={0}
            left={0}
            right={0}
            bottom={0}
            style={{
              zIndex: 9,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
            radius={8}
          >
            <Stack pos={"relative"} w={"100%"} h={"100%"}>
              <Skeleton w={"100%"} h={"100%"} pos={"absolute"} />
              <Loader
                type="dots"
                pos={"absolute"}
                top={"50%"}
                left={"50%"}
                style={{ transform: "translate(-50%)" }}
              />
            </Stack>
          </Stack>
        </>
      )}
      <EmailEditor
        ref={editorRef}
        onReady={(editor) => {
          setInitialized(true);
          onReady(editor);
        }}
        minHeight="350px"
        style={{
          minWidth: "480px !important",
        }}
        options={{
          displayMode: "email",
          mergeTagsConfig: {
            sort: false,
          },
          locale: lang.id === "FRA" ? "fr-FR" : "en-EN",
          features: {
            smartMergeTags: false,
          },
          translations: {
            "fr-FR": {
              "labels.merge_tags":
                lang.id === "FRA" ? "Variables " : "Variables ",
            },
          },
        }}
      />
    </Stack>
  );
};

export default TemplateEditor;
