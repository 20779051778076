import { create } from "zustand";
import { useLanguage } from "./LanguageStore";

const listStore = (set) => ({
  lists: [],
  setLists: (newData) => set({ lists: newData }),

  mainLoad: true,
  setMainLoad: (newData) => set({ mainLoad: newData }),

  importOpened: false,
  setImportOpened: (newData) => set({ importOpened: newData }),

  autoReloading: false,
  setAutoReloading: (newData) => set({ autoReloading: newData }),

  tabs: [
    {
      tabId: "./",
      label: useLanguage.getState().language.contact_lists.tabs_title.user,
      permanent: true,
    },
  ],

  setTabs: (updater) => set((prev) => ({ tabs: updater(prev.tabs) })),

  activeTab: "./",
  setActiveTab: (newData) => set({ activeTab: newData }),

  isChild: false,
  setIsChild: (newData) => set({ isChild: newData }),
});

export const useListStore = create(listStore);
