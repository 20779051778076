import { useEffect, useMemo, useState } from "react";
import { useLanguage } from "../../../stores/LanguageStore";
import {
  ActionIcon,
  Center,
  Group,
  Loader,
  SegmentedControl,
  SimpleGrid,
  Stack,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import {
  IconClearAll,
  IconFileCode,
  IconFileInvoice,
  IconPhoto,
  IconRefresh,
  IconSearch,
} from "@tabler/icons-react";
import FilesItem from "./FilesItem";
import CreateFileModal from "./CreateFileModal";

const image_props = { size: 18 };

const FilesContent = ({
  files,
  loading,
  setLoading,
  handleInsertContent,
  isTemplate,
  handleClose,
  inModal,
  setRows,
  folder,
}) => {
  const lang = useLanguage((s) => s.language);
  const [filter, setFilter] = useState("all");

  const handleFilters = (newFilter) => {
    setFilter(newFilter);
  };
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState(files);

  useEffect(() => {
    const results = files.filter((file) => {
      return file.name.toLowerCase().includes(searchText);
    });
    setSearchResults(results);
  }, [searchText, files]);

  const filterData = useMemo(() => {
    return [
      {
        label: (
          <Center h={"100%"}>
            <IconClearAll {...image_props} />
            <Text ml={"xs"}>{lang.files.all_files}</Text>
          </Center>
        ),
        value: "all",
      },
      {
        label: (
          <Tooltip label={"JPG / JPEG / PNG / GIF"}>
            <Center h={"100%"}>
              <IconPhoto {...image_props} />
              <Text ml={"xs"}>{lang.files.grid.filters.images}</Text>
            </Center>
          </Tooltip>
        ),
        value: "image",
      },
      {
        label: (
          <Tooltip label={"PDF / DOC / DOCX"}>
            <Center h={"100%"}>
              <IconFileInvoice {...image_props} />
              <Text ml={"xs"}>{lang.files.grid.filters.docs}</Text>
            </Center>
          </Tooltip>
        ),
        value: "docs",
      },
      {
        label: (
          <Tooltip label={"HTML"}>
            <Center h={"100%"}>
              <IconFileCode {...image_props} />
              <Text ml={"xs"}>{lang.files.grid.filters.html}</Text>
            </Center>
          </Tooltip>
        ),
        value: "code",
      },
    ];
  }, [lang]);

  return (
    <Stack style={{ overflow: "auto" }} w={0} flex={"1 1 auto"}>
      <Group>
        <TextInput
          placeholder={lang.files.grid.search}
          onChange={(e) => setSearchText(e.target.value)}
          rightSection={<IconSearch size={18} />}
          rightSectionPointerEvents="none"
        />

        <SegmentedControl data={filterData} onChange={handleFilters} />

        {inModal && (
          <CreateFileModal
            onFinish={() => {
              setLoading(true);
            }}
            actualFolder={folder}
          />
        )}

        <ActionIcon
          variant="subtle"
          size={"lg"}
          ml={"auto"}
          onClick={() => setLoading(true)}
          color="tertiary.8"
        >
          <IconRefresh size={22} />
        </ActionIcon>
      </Group>

      {loading ? (
        <Center w={"100%"} h={"100%"} mih={200}>
          <Loader />
        </Center>
      ) : (
        <Stack>
          <Text size="md">{lang.files.grid.click}</Text>
          <SimpleGrid cols={5} w={"100%"} pos={"relative"} flex={1}>
            {searchResults.length > 0 ? (
              searchResults.map((file) => {
                if (filter !== "all" && file.type !== filter) {
                  return "";
                }
                return (
                  <FilesItem
                    key={file?.id}
                    item={file}
                    setLoading={setLoading}
                    handleInsertContent={handleInsertContent}
                    isTemplate={isTemplate}
                    handleClose={handleClose}
                    inModal={inModal}
                    setRows={setRows}
                  />
                );
              })
            ) : (
              <Center w={"100%"} h={"100%"} pos={"absolute"} top={162} left={0}>
                <Stack align="center" justify="center">
                  <Text c={"dimmed"} size="xl">
                    {lang.files.grid.no_file_found}
                  </Text>

                  {inModal && (
                    <CreateFileModal
                      onFinish={() => {
                        setLoading(true);
                      }}
                      actualFolder={folder}
                      size="xl"
                      ml={0}
                      variant="light"
                    />
                  )}
                </Stack>
              </Center>
            )}
          </SimpleGrid>
        </Stack>
      )}
    </Stack>
  );
};

export default FilesContent;
