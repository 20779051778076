import { ActionIcon, Tooltip } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { useLanguage } from "../../stores/LanguageStore";

const FilterItemAdder = ({ disabled, onClick, color }) => {
  const lang = useLanguage((s) => s.language);
  return (
    <Tooltip label={lang.table.toolbar.filter.conditions.add_condition_tooltip}>
      <ActionIcon
        size="sm"
        variant="outline"
        onClick={onClick}
        disabled={disabled}
        color={color}
      >
        <IconPlus size={18} />
      </ActionIcon>
    </Tooltip>
  );
};

export default FilterItemAdder;
