import { Anchor, Group, Stack, Stepper, Text } from "@mantine/core";
import ThingsToSetup from "./ThingsToSetup";
import {
  IconHelpHexagon,
  IconListDetails,
  IconSettings,
  IconSpeakerphone,
} from "@tabler/icons-react";
import { useLanguage } from "../../../../stores/LanguageStore";

const GettingStarted = () => {
  const lang = useLanguage((s) => s.language);

  return (
    <Group
      gap={0}
      align="stretch"
      justify="flex-start"
      h={"100%"}
      wrap="nowrap"
      style={{ overflow: "hidden" }}
    >
      <ThingsToSetup />

      <Stack className="animate__animated animate__fadeInRight">
        <Text fw={600} size="lg">
          {lang.home.right.title}
        </Text>

        <Stepper orientation="vertical" iconSize={28}>
          <Stepper.Step
            icon={<IconListDetails size={18} />}
            label={
              <Anchor href="/support/contact-lists" target="_blank">
                {lang.home.right.list}
              </Anchor>
            }
            description={lang.home.right.list_help}
          />
          <Stepper.Step
            icon={<IconSpeakerphone size={18} />}
            label={
              <Anchor href="/support/campaigns" target="_blank">
                {lang.home.right.campaign}
              </Anchor>
            }
            description={lang.home.right.campaign_help}
          />
          <Stepper.Step
            icon={<IconSettings size={18} />}
            label={
              <Anchor href="/support/configurations" target="_blank">
                {lang.home.right.config}
              </Anchor>
            }
            description={lang.home.right.config_help}
          />
          <Stepper.Step
            icon={<IconHelpHexagon size={18} />}
            label={
              <Anchor href="/support/faq-guides-questions" target="_blank">
                {lang.home.right.faq}
              </Anchor>
            }
            description={lang.home.right.faq_help}
          />
        </Stepper>
      </Stack>
    </Group>
  );
};

export default GettingStarted;
