import { mainAxios as axios } from "./axios";

export const extractLSearch = async (data) => {
  return axios.post(`/targeting/linkedin/extract-peoples-search`, data);
};
export const extractLLikers = async (data) => {
  return axios.post(`/targeting/linkedin/extract-post-likers`, data);
};
export const extractLCommenters = async (data) => {
  return axios.post(`/targeting/linkedin/extract-post-commenters`, data);
};
export const extractLCompanies = async (data) => {
  return axios.post(
    `/targeting/linkedin/extract-sales-navigator-companies-search`,
    data
  );
};
export const extractLCompaniesAlt = async (data) => {
  return axios.post(
    `/targeting/linkedin/extract-sales-navigator-companies-search-alternative`,
    data
  );
};
export const extractLSearchSN = async (data) => {
  return axios.post(
    `/targeting/linkedin/extract-sales-navigator-peoples-search`,
    data
  );
};
export const extractLSearchSNAlt = async (data) => {
  return axios.post(
    `/targeting/linkedin/extract-sales-navigator-peoples-search-alternative`,
    data
  );
};
export const extractLPublishers = async (data) => {
  return axios.post(`/targeting/linkedin/extract-publishers-job-offers`, data);
};
export const extractConnections = async (data) => {
  return axios.post(`/targeting/linkedin/extract-connections`, data);
};
export const refreshList = async (contact_list_id, linkedin_account_id) => {
  return axios.post(`/targeting/linkedin/refresh/${contact_list_id}`, {
    linkedin_account_id,
  });
};
export const relaunchLinkedinErrors = async (
  contact_list_id,
  linkedin_account_id
) => {
  return axios.post(`/targeting/linkedin/${contact_list_id}/relaunch-errors`, {
    linkedin_account_id,
  });
};
export const enrichList = async (contact_list_id, generate_email) => {
  return axios.post(`/contact-lists/${contact_list_id}/enrich`, {
    generate_email,
  });
};

export const generateSalesNavigatorPeopleSearchUrl = async ({
  current_titles,
  company_head_counts,
  locations,
  industries,
  seniority_levels,
  current_companies,
  company_headquarters,
}) => {
  return axios.post(
    "/targeting/linkedin/generate-sales-navigator-peoples-search-url",
    {
      current_titles,
      company_head_counts,
      locations,
      industries,
      seniority_levels,
      current_companies,
      company_headquarters,
    }
  );
};
export const generatePeopleSearchUrl = async ({
  current_title,
  locations,
  industries,
  current_company,
}) => {
  return axios.post("/targeting/linkedin/generate-peoples-search-url", {
    current_title,
    locations,
    industries,
    current_company,
  });
};

export const searchLocation = async (name) => {
  return axios.post("/targeting/linkedin/locations/search", { name });
};
