import { Group, Paper, Text, ThemeIcon } from "@mantine/core";
import {
  nodeWidthLinkedinVisitPreview,
  nodeWidthPreview,
} from "../../flowSettings";
import { useMemo } from "react";
import EmailPreviewer from "../../../../../../../components/View/EmailPreviewer";
import MessagePreviewer from "../../../../../../../components/View/MessagePreviewer";
import SenderVisual from "../SenderVisual";
import NodeId from "../NodeId";
import { useLanguage } from "../../../../../../../stores/LanguageStore";

const ActionNodePreview = ({ data, nodeDetail, id }) => {
  const lang = useLanguage((s) => s.language);
  const preview = useMemo(() => {
    const type = data?.actionType;
    if (type === "email") {
      return (
        <EmailPreviewer
          model_id={data?.model_id}
          subjectSize="sm"
          iframeHeight={200}
          paperShadow="none"
          signatureId={data?.signature_id}
        />
      );
    } else if (type !== "linkedin_visit" && type !== "call") {
      if (data?.model_id)
        return (
          <MessagePreviewer
            model_id={data?.model_id}
            type={type}
            senderSize="xs"
            iframeHeight={200}
            paperShadow="none"
            mah={200}
          />
        );
      else
        return (
          <Text ta={"center"} c={"dimmed"}>
            {lang.campaign.selectors.senders.no_message}
          </Text>
        );
    }
  }, [data]);

  return (
    <Paper
      w={
        data?.actionType === "linkedin_visit"
          ? nodeWidthLinkedinVisitPreview
          : nodeWidthPreview
      }
      withBorder
      className="action-node"
      p={"xs"}
      pt={6}
      pb={"sm"}
      shadow={"sm"}
      pos={"relative"}
      style={{
        borderColor: data?.error ? "red" : nodeDetail.color,
        borderWidth: 1,
      }}
      radius={"md"}
    >
      <Group className="head" mb={6}>
        <ThemeIcon variant="light" color={nodeDetail.color} size={"md"}>
          {nodeDetail.icon}
        </ThemeIcon>
        <Text fz={"sm"} ta={"center"}>
          {nodeDetail.label}
        </Text>

        <SenderVisual data={data} />
      </Group>
      {preview}

      <NodeId id={id} />
    </Paper>
  );
};

export default ActionNodePreview;
