import { Paper, Stack } from "@mantine/core";
import { LinearProgress } from "@mui/material";

const TableLoader = () => {
  return (
    <Paper component={Stack} gap={0}>
      <LinearProgress />
    </Paper>
  );
};

export default TableLoader;
