import { useThemeStore } from "../../../../../stores/ThemeStore";

const AIIllustration = () => {
  const theme = useThemeStore((s) => s.theme);
  const mainColor = theme.colors.secondary[6];
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 731.79921 556.20068"
    >
      <path
        id="fe64f6e9-4bc9-4ce0-8c86-aec21ef87072-541"
        data-name="Path 438"
        d="M287.871,680.01248a24.21461,24.21461,0,0,0,23.38269-4.11876c8.18977-6.87442,10.758-18.196,12.84671-28.68192l6.17973-31.01656-12.9377,8.90836c-9.30465,6.40641-18.81827,13.01866-25.26012,22.29785s-9.25222,21.94708-4.07792,31.988"
        transform="translate(-234.10039 -171.89966)"
        fill="#e6e6e6"
      />
      <path
        id="a765e893-01fd-4aa2-bcac-d55c1795a9aa-542"
        data-name="Path 439"
        d="M289.86876,719.723c-1.62839-11.86369-3.30382-23.88079-2.15885-35.87168,1.01467-10.64932,4.26374-21.0488,10.87832-29.57938a49.20586,49.20586,0,0,1,12.62465-11.44038c1.26216-.79648,2.4241,1.20354,1.16733,1.997a46.7794,46.7794,0,0,0-18.50445,22.32561c-4.02858,10.24608-4.67546,21.41583-3.98154,32.3003.41943,6.58218,1.31074,13.12121,2.20587,19.65251a1.19817,1.19817,0,0,1-.808,1.42251,1.16348,1.16348,0,0,1-1.42253-.808Z"
        transform="translate(-234.10039 -171.89966)"
        fill="#f2f2f2"
      />
      <path
        id="aa81d4a3-6213-409c-86b1-4f180b052463-543"
        data-name="Path 442"
        d="M301.58978,700.6866a17.82515,17.82515,0,0,0,15.53142,8.01861c7.8644-.37318,14.41806-5.85972,20.31713-11.07027l17.452-15.4088-11.54988-.5528c-8.30619-.39784-16.82672-.771-24.73813,1.79338s-15.20758,8.72639-16.654,16.9154"
        transform="translate(-234.10039 -171.89966)"
        fill="#e6e6e6"
      />
      <path
        id="e3f50e46-e5fd-43fd-8e49-9a0885b42528-544"
        data-name="Path 443"
        d="M285.26577,726.54273c7.83972-13.87142,16.93235-29.28794,33.1808-34.21551a37.02589,37.02589,0,0,1,13.95545-1.44105c1.4819.128,1.1118,2.41174-.367,2.28454a34.39826,34.39826,0,0,0-22.27165,5.89215c-6.27994,4.27453-11.16975,10.21755-15.30781,16.51907-2.5351,3.8605-4.80576,7.88445-7.07642,11.903C286.6536,728.76944,284.53177,727.8419,285.26577,726.54273Z"
        transform="translate(-234.10039 -171.89966)"
        fill="#f2f2f2"
      />
      <path
        d="M518.7383,445.42539a10.52675,10.52675,0,0,0-1.43008.838l-46.18973-18.0554-1.83089-11.90808-18.33255.41424L452.14759,438.11a8,8,0,0,0,6.17974,7.34784l54.962,12.75031a10.4971,10.4971,0,1,0,5.449-12.78277Z"
        transform="translate(-234.10039 -171.89966)"
        fill="#9f616a"
      />
      <polygon
        points="215.287 542.561 227.547 542.56 233.379 495.272 215.285 495.273 215.287 542.561"
        fill="#9f616a"
      />
      <path
        d="M446.25984,710.45747l24.144-.001h.001a15.38731,15.38731,0,0,1,15.38648,15.38623v.5l-39.53076.00146Z"
        transform="translate(-234.10039 -171.89966)"
        fill="#2f2e41"
      />
      <polygon
        points="156.287 542.561 168.547 542.56 174.379 495.272 156.285 495.273 156.287 542.561"
        fill="#9f616a"
      />
      <path
        d="M387.25984,710.45747l24.144-.001h.001a15.38731,15.38731,0,0,1,15.38648,15.38623v.5l-39.53076.00146Z"
        transform="translate(-234.10039 -171.89966)"
        fill="#2f2e41"
      />
      <path
        d="M397.496,353.92634c-3.49153-14.41631-6.44961-30.67639.13514-43.954,4.56882-9.21266,21.47052-12.31507,31.15931-8.99611s16.69383,12.32354,19.77465,22.14137,2.72281,20.39727,1.38007,30.60357C432.12619,353.37886,414.30718,353.03656,397.496,353.92634Z"
        transform="translate(-234.10039 -171.89966)"
        fill="#2f2e41"
      />
      <circle cx="193.46573" cy="161.24396" r="20.77882" fill="#9f616a" />
      <path
        d="M402.16422,328.86329a26.09893,26.09893,0,1,1,52.16648.6877C436.54559,329.58875,418.76048,329.62651,402.16422,328.86329Z"
        transform="translate(-234.10039 -171.89966)"
        fill="#2f2e41"
      />
      <path
        d="M410.32141,430.58235l-8.6901,14.48349s-23.799,44.63557-22.38359,81.63426l5.26673,46.08386-5.77995,11.30893L382.60339,603.99l-7.18508,14.25316,12.98841,68.81093.5527,14.92285H409.962l-1.1054-15.1992,8.84317-67.03584,14.92285-71.57442,7.73778,61.78521-2.76349,15.47555,3.59254,12.98841,4.97428,40.07062.5527,19.34444,19.06809,2.21079,7.18507-71.57442,4.14524-34.1503L454.89961,442.89966Z"
        transform="translate(-234.10039 -171.89966)"
        fill="#2f2e41"
      />
      <path
        d="M407.56511,360.64924l-12.93758,5.49469-7.26611,30.07222L402.66059,435.397l-5.71948,19.57652c17.313,29.61533,38.74219,33.91821,63.9585,14.92618-5.33692-14.984-4.56592-30.39139.75244-46.11389,0,0,13.2651-12.35223,3.99868-28.41144l-8.83912-23.99188-10.94367-5.47183Z"
        transform="translate(-234.10039 -171.89966)"
        fill="#cbcbcb"
      />
      <path
        d="M454.26273,376.61266l2.937-5.4544s7.74551,1.16493,10.90879,10.48922,3.98591,46.99171,3.98591,46.99171l-16.573.83913Z"
        transform="translate(-234.10039 -171.89966)"
        fill="#cbcbcb"
      />
      <path
        d="M389.33779,504.45813a10.74268,10.74268,0,0,0,3.84156-16.01842l8.033-78.54005H383.85225l-6.384,76.69032a10.8009,10.8009,0,0,0,11.86954,17.86815Z"
        transform="translate(-234.10039 -171.89966)"
        fill="#9f616a"
      />
      <path
        d="M405.20047,375.02668l-6.08375-8.60116s-10.699-7.25346-16.1534,9.4403-4.61525,47.62105-4.61525,47.62105l20.76865,8.60116Z"
        transform="translate(-234.10039 -171.89966)"
        fill="#cbcbcb"
      />
      <rect x="437.79921" y="75" width="96" height="54" fill="#f1f1f1" />
      <rect x="635.79921" y="33" width="96" height="54" fill="#f1f1f1" />
      <rect x="605.79921" y="173" width="96" height="54" fill="#f1f1f1" />
      <rect x="569.79921" width="96" height="54" fill="#e5e5e5" />
      <rect
        x="510.79921"
        y="99.5625"
        width="158"
        height="88.875"
        fill={mainColor}
      />
      <polygon
        points="349.472 243.559 515.194 243.559 515.194 172.35 517.784 172.35 517.784 246.149 349.472 246.149 349.472 243.559"
        fill="#3f3d56"
      />
      <circle cx="516.48926" cy="184.30738" r="14.24182" fill="#3f3d56" />
      <rect
        x="312.50107"
        y="269.07505"
        width="77.68259"
        height="2.58942"
        fill={mainColor}
      />
      <rect
        x="312.50107"
        y="276.8433"
        width="77.68259"
        height="2.58942"
        fill={mainColor}
      />
      <rect
        x="312.50107"
        y="284.61158"
        width="77.68259"
        height="2.58942"
        fill={mainColor}
      />
      <path
        d="M569.10039,727.89966h-334a1,1,0,0,1,0-2h334a1,1,0,1,1,0,2Z"
        transform="translate(-234.10039 -171.89966)"
        fill="#cbcbcb"
      />
      <path
        id="b85f007b-e7b6-4e13-b6fb-f155293c702e-545"
        data-name="Path 461"
        d="M545.09436,709.50429A10.95989,10.95989,0,0,1,534.51109,707.64c-3.70682-3.1113-4.87042-8.23679-5.81461-12.98329l-2.79686-14.03837,5.85559,4.03232c4.21122,2.89958,8.517,5.89224,11.43294,10.09238s4.18856,9.93371,1.84593,14.47815"
        transform="translate(-234.10039 -171.89966)"
        fill="#e6e6e6"
      />
      <path
        id="bf0c6bb3-9f06-4268-ab5b-3bc4101d86d1-546"
        data-name="Path 462"
        d="M544.59381,726.50059a56.66679,56.66679,0,0,0,.78651-13.05321,20.52933,20.52933,0,0,0-3.95854-10.76362,17.90525,17.90525,0,0,0-4.59411-4.163c-.45955-.28932-.882.43834-.42484.72672a17.02228,17.02228,0,0,1,6.73324,8.12394,27.2531,27.2531,0,0,1,1.44666,11.75363c-.15286,2.39518-.47644,4.774-.8029,7.15132a.434.434,0,0,0,.29415.5179.42339.42339,0,0,0,.5179-.29415Z"
        transform="translate(-234.10039 -171.89966)"
        fill="#f2f2f2"
      />
    </svg>
  );
};

export default AIIllustration;
