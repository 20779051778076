import {
  Button,
  Checkbox,
  CopyButton,
  Group,
  Menu,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { useLanguage } from "../../stores/LanguageStore";
import { useCallback, useState } from "react";
import { updateProfile, updateSelf } from "../../api/UserAPI";
import { notifications } from "@mantine/notifications";
import { Stack } from "@mui/material";
import { useConfigStore } from "../../stores/ConfigStore";
import { useProfileStore } from "../../stores/UserStore";
import { modals } from "@mantine/modals";
import BackButton from "./BackButton";
import { IconDeviceFloppy, IconSettings } from "@tabler/icons-react";

const Item = ({ item, value, disabled }) => {
  const lang = useLanguage((s) => s.language);
  const [replace, setReplace] = useState(!!value);

  const handleCheck = useCallback(
    (e) => {
      let value = e.target.checked;
      if (value)
        setTimeout(() => {
          document
            .getElementById(item.identifier_placeholder + "-input")
            .focus();
        }, 100);

      setReplace(value);
    },
    [item.identifier_placeholder]
  );

  return (
    <Group
      py={6}
      style={{ borderBottom: "1px solid #00000018" }}
      className="item"
    >
      <Stack maxWidth={"25%"} width="100%" pl={2}>
        {item?.name || "-"}
      </Stack>

      <Stack maxWidth={"25%"} width="100%">
        <CopyButton value={item.value}>
          {({ copied, copy }) => (
            <Tooltip
              label={copied ? lang.global.copied : lang.global.copy}
              color={copied ? "green" : "black"}
            >
              <Text truncate style={{ cursor: "pointer" }} onClick={copy}>
                {item?.identifier_placeholder || "-"}
              </Text>
            </Tooltip>
          )}
        </CopyButton>
      </Stack>
      <Group flex={1} direction="row" alignItems="center" pr={2} wrap="nowrap">
        <Tooltip
          label={lang.components.variable_manager.dialog.activate_replacement}
        >
          <Checkbox
            onChange={handleCheck}
            defaultChecked={!!value}
            disabled={disabled}
          />
        </Tooltip>
        <TextInput
          w={"100%"}
          defaultValue={value}
          name={item.id.toString()}
          placeholder={
            lang.components.variable_manager.dialog.replacement_label
          }
          id={item.identifier_placeholder + "-input"}
          disabled={disabled || !replace}
        />
      </Group>
    </Group>
  );
};

const Content = () => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(false);
  const fields = useConfigStore((s) => s.datafields);
  const values = useProfileStore(
    (s) => s.profile?.datafields_replacement || []
  );
  const handleClose = () => {
    modals.close("datafields");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    let fd = new FormData(e.target);
    let newData = [];

    let data = Object.fromEntries(fd);
    fields.forEach((field) => {
      if (data[field.id]) {
        newData.push({
          datafield_id: field.id,
          replacement: data[field.id],
        });
      } else {
        newData.push({
          datafield_id: field.id,
          replacement: "",
        });
      }
    });

    updateProfile(null, { datafields_replacement: newData })
      .then((response) => {
        updateSelf();
        handleClose();
        notifications.show({
          message: lang.components.variable_manager.edit_success,
          color: "green.8",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <form id="replacement-form" onSubmit={onSubmit}>
      <Stack
        sx={{
          "& .item:nth-child(even)": {
            background: "#00000006",
          },
        }}
      >
        {/* Headings */}
        <Group justify="space-between">
          <Group maw={"25%"} w="100%"></Group>
          <Group maw={"25%"} w="100%">
            <Text>{lang.components.variable_manager.dialog.textual}</Text>
          </Group>
          <Group flex={1} gap={8}>
            <Text>{lang.components.variable_manager.dialog.replacement}</Text>
            <Text fz={12} style={{ opacity: 0.75 }}>
              ({lang.components.variable_manager.dialog.replacement_text})
            </Text>
          </Group>
        </Group>

        {/* Main Content */}
        <Stack maxHeight={400} overflow="auto">
          <Item
            item={{
              id: "unsubscribe",
              name: lang.components.variable_manager.dialog
                .unsubscribe_variable_name,
              identifier_placeholder: "%unsubscribe%",
            }}
            value={null}
            disabled
          />
          <Item
            item={{
              id: "webversion",
              name: lang.components.variable_manager.dialog
                .webversion_variable_name,
              identifier_placeholder: "%webversion%",
            }}
            value={null}
            disabled
          />
          {fields?.map((field, index) => {
            return (
              <Item
                item={field}
                key={index}
                value={
                  values?.find((x) => x.datafield_id === field.id)?.replacement
                }
              />
            );
          })}
        </Stack>
      </Stack>

      <Group justify="end" mt={"md"}>
        <BackButton onClick={handleClose} />

        <Button
          type="submit"
          loading={loading}
          leftSection={<IconDeviceFloppy size={18} />}
        >
          {lang.global.save}
        </Button>
      </Group>
    </form>
  );
};

const ReplacementVariables = () => {
  const lang = useLanguage((s) => s.language);

  const handleClick = (e) => {
    e.stopPropagation();

    modals.open({
      modalId: "datafields",
      children: <Content />,
      title: lang.components.variable_manager.dialog.title,
      size: "xl",
    });
  };

  return (
    <Menu.Item onClick={handleClick}>
      <Group>
        <IconSettings size={18} />
        <Text size="sm">{lang.components.variable_manager.button_tooltip}</Text>
      </Group>
    </Menu.Item>
  );
};

export default ReplacementVariables;
