export const levelsColor = ["#666", "#0072c4", "#a707a4"];

export const filterNumberTypes = {
  "=": "equals",
  "!=": "not_equals",
  ">": "more_than",
  ">=": "more_or_equal_than",
  "<": "less_than",
  "<=": "less_or_equal_than",
};
