import { Link, useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { useContactHandler } from "../../../lists/components/useContactHandler";
import {
  downloadCampaignsContact,
  downloadCampaignsContactStep,
  getProgrammationContacts,
  getProgrammationUniqueContacts,
} from "../../../../../api/StatsAPI";
import TableFilterExport from "../../../../../components/TableComponents/TableFilterExport";
import TableLayoutBlock from "../../../../../layouts/Table/TableLayoutBlock";
import {
  Anchor,
  Button,
  Flex,
  Group,
  SegmentedControl,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import MainTable from "../../../../../layouts/Table/MainTable";
import { useListStore } from "../../../../../stores/ListStore";
import { booleanColumn } from "../../../../../layouts/Table/columns/booleanColumn";
import { IconExternalLink } from "@tabler/icons-react";
import {
  parseProspectDatafields,
  status_list,
} from "../../../leadsTracking/utils";
import dateColumn from "../../../../../layouts/Table/columns/dateColumn";
import Answers from "../../../leadsTracking/prospect/answers/Answers";
import numberColumn from "../../../../../layouts/Table/columns/numberColumn";
import ClickerLinks from "./ClickerLinks";
import { parseDate } from "../../../../../functions/dates";
import StatsPageTitle from "../../StatsPageTitle";
import { getStatsPathName } from "../../Stats";
import TableFilterBlacklistContacts from "./TableFilterBlacklistContacts";

function filterPrmById(id) {
  return "/leads-tracking?contact_id=" + id;
}

const canVisualize = ["contacted", "answerer", "clicker", "opener"];
function parseStatus(status, type) {
  if (status === "opener") {
    if (type === "linkedin_invitation") {
      return "last_linkedin_invitation_accepted";
    }
    if (type === "sms") {
      return "last_sms_got";
    }
    return "last_email_open";
  }
  if (status === "clicker") {
    return "last_link_click";
  }
  if (status === "answerer") {
    if (type === "linkedin_message") {
      return "last_linkedin_message_answered";
    }
    return "last_email_answered";
  }
  return null;
}

function handlePrmRedirectLink({ contact_type, campaignId, step_type }) {
  const params = new URLSearchParams(location.search);
  const createdOn = params.get("createdOn");
  if (contact_type === "contacted") {
    return `/leads-tracking?programmation_id=${campaignId}&created_on=${createdOn}`;
  }
  return `/leads-tracking?programmation_id=${campaignId}&created_on=${createdOn}&contact_type=${parseStatus(
    contact_type,
    step_type
  )}`;
}

const CampaignContacts = () => {
  const navigate = useNavigate();
  const { campaign, workflow, stepId, type, stepType } = useParams();
  const lang = useLanguage((s) => s.language);
  const apiRef = useGridApiRef();

  const tabs = useMemo(() => {
    let uniqueList = {
      contacted: lang.statistics.contacts_type.contacted,
      opener: lang.statistics.contacts_type.opener,
      clicker: lang.statistics.contacts_type.clicker,
      answerer: lang.statistics.contacts_type.answerer,
      bounced: lang.statistics.contacts_type.bounced,
      blacklisted: lang.statistics.contacts_type.blacklisted,
      unsubscriber: lang.statistics.contacts_type.unsubscriber,
      excluded_previous_programmation:
        lang.statistics.contacts_type.excluded_previous_programmation,
    };
    let list = {
      contacted: lang.statistics.contacts_type.contacted,
      sent_today: lang.statistics.contacts_type.sent_today,
      opener: lang.statistics.contacts_type.opener,
      clicker: lang.statistics.contacts_type.clicker,
      answerer: lang.statistics.contacts_type.answerer,
      unsubscriber: lang.statistics.contacts_type.unsubscriber,
      bounced: lang.statistics.contacts_type.bounced,
      to_contact: lang.statistics.contacts_type.to_contact,
      blacklisted: lang.statistics.contacts_type.blacklisted,
      excluded_previous_programmation:
        lang.statistics.contacts_type.excluded_previous_programmation,
      excluded_workflow: lang.statistics.contacts_type.excluded_workflow,
      to_contact_failed: lang.statistics.contacts_type.to_contact_failed,
    };

    const r = Object.keys(stepId ? list : uniqueList).map((key) => ({
      value: key,
      label: list[key],
    }));

    return r;
  }, [lang]);

  const lists = useListStore((s) => s.lists);

  const startingColumns = useMemo(() => {
    return [
      {
        field: "status",
        id: "status",
        headerName: lang.statistics.columns.contacted.columns.status,
        sortable: false,
        width: 120,
        filterable: false,
        renderCell: ({ value }) => {
          if (!value) {
            return "-";
          }
          if (value === "opener" && stepType === "linkedin_invitation") {
            return lang.workflows.nodes.events.linkedin_invitation_accepted;
          }
          return value
            ? status_list().find((x) => x.value === value).label
            : lang.workflows.nodes.sent;
        },
      },
      {
        field: "date_contacted",
        headerName: lang.statistics.columns.contacted.columns.date_contacted,
        width: 150,
        // sortable: false,
        id: "date_contacted",
        valueGetter: (params) => {
          return params?.value || "";
        },
        renderCell: (cell) => {
          if (cell.value)
            return (
              <Tooltip
                label={new Date(cell.row["date_contacted"]).toLocaleString()}
                withArrow
                openDelay={500}
              >
                <Text fz={13} truncate="end">
                  {parseDate(cell.value)}
                </Text>
              </Tooltip>
            );
          return lang.workflows.nodes.waiting;
        },
        filterable: stepId,
      },
      dateColumn(
        "status_changed_date",
        lang.statistics.columns.contacted.columns.status_changed_date,
        false,
        {
          filterable: true,
          sortable: true,
        }
      ),
      booleanColumn({
        field: "is_bounced",
        headerName: lang.statistics.contacts_type.bounced,
        falseLabel: lang.linkedin_accounts.columns.not_sn,
        trueLabel: lang.linkedin_accounts.columns.is_sn,
        inverse: true,
        filterable: false,
        sortable: true,
      }),
    ].filter((x) => (stepId ? true : x.field !== "date_contacted"));
  }, [lang, stepId, stepType]);

  const additionalColumns = useMemo(() => {
    const result = [
      numberColumn(),
      {
        field: "contact_list_id",
        id: "contact_list_id",
        headerName: lang.prm.details.campaigns_table.contact_list,
        filterable: false,
        sortable: false,
        width: 150,
        renderCell: ({ row, value }) => {
          return (
            <Anchor
              component={Link}
              to={`/contact-lists/${value}`}
              target="_blank"
            >
              {lists.find((x) => x.id === value)?.name || "Liste"} #{value}
            </Anchor>
          );
        },
      },
      {
        field: "contact_list_contacts_id",
        headerName: lang.prm.details.campaigns_table.id_in_lilst,
        id: "contact_list_contacts_id",
        filterable: false,
        sortable: false,
        width: 100,
        renderCell: ({ row, value }) => {
          let f = {
            mode: "and",
            values: [
              {
                field_name: "id",
                type: "equals",
                value: value,
              },
            ],
          };
          return (
            <Anchor
              component={Link}
              to={`/contact-lists/${
                row.contact_list_id
              }/?filter=${JSON.stringify(f)}`}
              target="_blank"
            >
              {value ? `#${value}` : "-"}
            </Anchor>
          );
        },
      },
      {
        field: "prm_contacts_id",
        headerName: lang.statistics.columns.contacted.columns.prm_contacts_id,
        sortable: false,
        id: "prm_contacts_id",
        filterable: false,
        width: 120,
        renderCell: ({ value }) =>
          value ? (
            <Anchor href={filterPrmById(value)} target="_blank">
              #{value}
            </Anchor>
          ) : (
            "-"
          ),
      },
    ];

    return result;
  }, [lang, lists]);

  const {
    loading,
    columns,
    rows,
    rowCount,
    serverInfo,
    handleLoading,
    onFilterChange,
    onPaginationChange,
    onSortChange,
  } = useContactHandler({
    apiRef,
    listId: parseInt(campaign),
    getFirstPage: (data) =>
      stepId
        ? getProgrammationContacts({
            programmation_id: parseInt(campaign),
            workflow_id: parseInt(workflow),
            step_id: parseInt(stepId),
            contact_type: type,
            ...data,
          })
        : getProgrammationUniqueContacts({
            programmation_id: parseInt(campaign),
            workflow_id: parseInt(workflow),
            contact_type: type,
            ...data,
          }),
    searchFunction: ({ query, filter, ...data }) =>
      stepId
        ? getProgrammationContacts({
            programmation_id: parseInt(campaign),
            workflow_id: parseInt(workflow),
            step_id: parseInt(stepId),
            contact_type: type,
            filter: {
              mode: "and",
              values: [
                ...(filter?.values || []),
                {
                  field_name: "any_datafield",
                  type: "contains",
                  value: query,
                },
              ],
            },
            ...data,
          })
        : getProgrammationUniqueContacts({
            programmation_id: parseInt(campaign),
            workflow_id: parseInt(workflow),
            contact_type: type,
            filter: {
              mode: "and",
              values: [
                ...(filter?.values || []),
                {
                  field_name: "any_datafield",
                  type: "contains",
                  value: query,
                },
              ],
            },
            ...data,
          }),
    disableEdit: true,
    startingColumns,
    additionalColumns,
  });

  const actionsCell = useCallback(
    ({ row }) => {
      const container = {
        items: rows
          .map((x) => ({ ...x, id: x.prm_contacts_id }))
          .map((x) => parseProspectDatafields(x)),
      };

      return (
        <Answers
          container={container}
          index={rows.findIndex(
            (x) => x.prm_contacts_id === row.prm_contacts_id
          )}
          inSheet
          disabled={row?.status !== "answerer"}
        />
      );
    },
    [rows]
  );

  const toolbarActions = useCallback(
    ({ selectedIds, filter }) => (
      <>
        <TableFilterExport
          id={parseInt(campaign)}
          selectedIds={selectedIds}
          filter={filter}
          handleExport={({ ids, excluded_ids, filter, file_type }) => {
            let main = stepId
              ? downloadCampaignsContactStep
              : downloadCampaignsContact;

            return main({
              contact_type: type,
              file_type,
              programmation_id: parseInt(campaign),
              step_id: parseInt(stepId),
              workflow_id: parseInt(workflow),
              contacts_selection: {
                contact_ids: ids,
                excluded_contact_ids: excluded_ids,
                filter,
              },
            });
          }}
          name={"export"}
          filterResultNumber={rowCount}
        />

        {stepId && (
          <TableFilterBlacklistContacts
            total={rowCount}
            selectedIds={selectedIds}
            filter={filter}
            filterResultNumber={rowCount}
          />
        )}
      </>
    ),
    [campaign, type, rowCount, stepId, workflow]
  );

  useEffect(() => {
    if (apiRef.current?.state) {
      handleLoading(true);
    }
  }, [type, apiRef]);

  if (!columns) {
    return;
  }

  return (
    <>
      <StatsPageTitle
        defaultTitle={getStatsPathName({
          campaign,
          workflow,
          stepId,
          type,
          stepType,
        })}
      />

      <TableLayoutBlock>
        <Stack flex={"1 1 auto"} w={0} miw={"100%"} gap={0}>
          <Group m={"xs"} justify="space-between" gap={"xl"}>
            <Group flex={"1 1 auto"} w={0}>
              <SegmentedControl
                value={type}
                onChange={(v) => {
                  const params = new URLSearchParams(location.search);
                  const createdOn = params.get("createdOn");

                  if (stepId) {
                    navigate(
                      `/statistics/campaigns/${campaign}/workflow/${workflow}/step/${stepId}/contacts/${v}/${stepType}?createdOn=${createdOn}`
                    );
                  } else {
                    navigate(
                      `/statistics/campaigns/${campaign}/workflow/${workflow}/contacts/${v}?createdOn=${createdOn}`
                    );
                  }
                }}
                size="sm"
                data={tabs}
                disabled={loading}
                // color="secondary"
                styles={{
                  root: {
                    overflow: "auto",
                  },
                }}
              />
            </Group>
            {!stepId && (
              <Button
                component={Link}
                to={handlePrmRedirectLink({
                  contact_type: type,
                  campaignId: campaign,
                  step_type: stepType,
                })}
                rightSection={<IconExternalLink size={18} />}
                variant="light"
                color="secondary"
                underline="never"
                radius={"sm"}
                disabled={!canVisualize.includes(type)}
              >
                {lang.statistics.columns.contacted.view_in_prm}
              </Button>
            )}
          </Group>
          <Flex flex={"1 1 auto"} h={0}>
            <Flex flex={"1 1 auto"} w={"50%"}>
              <MainTable
                isClient={false}
                apiRef={apiRef}
                columns={columns}
                rows={rows}
                rowCount={rowCount}
                loading={loading}
                setLoading={() => {
                  handleLoading();
                }}
                id={"list-table"}
                hasFolder
                pageName={"stats_fetch_contacts_order"}
                onPaginationChange={onPaginationChange}
                onFilterChange={onFilterChange}
                onSortChange={onSortChange}
                pageOrderName="stats_fetch_contacts_order"
                toolbarActions={toolbarActions}
                actionsCell={actionsCell}
                actionsCellWidth={50}
                withBorderTop
              />
            </Flex>
            {type === "clicker" && stepId && <ClickerLinks />}
          </Flex>
        </Stack>
      </TableLayoutBlock>
    </>
  );
};

export default CampaignContacts;
