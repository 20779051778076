import { useEffect, useState } from "react";
import { useLanguage } from "../../../stores/LanguageStore";
import {
  getOtherPrms,
  getStatusCustom,
  getStatusSystem,
} from "../../../api/PRMAPI";
import { Group, LoadingOverlay, Stack } from "@mantine/core";
import Controller from "./controller/Controller";
import Containers from "./containers/Containers";
import { useUIStore } from "../../../stores/UIStore";
import TitleSection from "./titleSection/TitleSection";
import "./PRM.scss";
import { useLeadsStore } from "../../../stores/LeadsStore";
import { setupFilters } from "./utils";
import { useConfigStore } from "../../../stores/ConfigStore";
import LeadsBaseSelector from "./titleSection/LeadsBaseSelector";
import LeadSheet from "./prospect/sheet/LeadSheet";
import { useNavigate, useParams } from "react-router-dom";
import PageTitle from "../../../components/View/PageTitle";
import { getFields, getLists } from "../../../api/ContactAPI";
import { getQuickCampaigns } from "../../../api/StatsAPI";
import RequiredActions from "../account/RequiredActions/RequiredActions";

async function init(userId, navigate) {
  const lang = useLanguage.getState().language;

  // Other requests need for filters
  getLists();
  getQuickCampaigns();
  getFields();

  // If on others PRM or self
  if (userId) {
    const setPrms = useLeadsStore.getState().setPrms;
    return getOtherPrms().then((res) => {
      let prms = res.data.prm;
      setPrms(prms);

      let actualPrm = prms?.find((x) => x.id === parseInt(userId));

      if (!actualPrm) {
        return navigate("/leads-tracking");
      }

      let statuses = actualPrm.status;
      let custom_statuses = actualPrm.custom_status;
      let datafields = actualPrm.datafields;

      useLeadsStore.getState().setCustomStatuses(custom_statuses);

      let map = {
        opener: lang.components.status_selector.statuses.opener,
        clicker: lang.components.status_selector.statuses.clicker,
        answerer: lang.components.status_selector.statuses.answerer,
        to_call: lang.components.status_selector.statuses.to_call,
      };

      useLeadsStore.getState().setSystemStatuses(
        statuses.map((x) => ({
          ...x,
          name: map[x.status],
          id: x.status,
        }))
      );

      setupFilters({
        datafields,
        custom_columns: custom_statuses.map((x) => ({
          label: x.name,
          value: x.id.toString(),
        })),
      });

      useLeadsStore.getState().setFields(datafields);
    });
  } else {
    await getStatusSystem().then((response) => {
      let map = {
        opener: lang.components.status_selector.statuses.opener,
        clicker: lang.components.status_selector.statuses.clicker,
        answerer: lang.components.status_selector.statuses.answerer,
        to_call: lang.components.status_selector.statuses.to_call,
      };

      useLeadsStore.getState().setSystemStatuses(
        response.data.status.map((x) => ({
          ...x,
          name: map[x.status],
          id: x.status,
        }))
      );
    });

    await getStatusCustom().then((response) => {
      useLeadsStore.getState().setCustomStatuses(response.data.status);

      let datafields = useConfigStore.getState().datafields;

      setupFilters({
        datafields,
        custom_columns: response.data.status.map((x) => ({
          label: x.name,
          value: x.id.toString(),
        })),
      });

      useLeadsStore.getState().setFields(datafields);
    });
  }
}

const LeadsTracking = () => {
  const { userId } = useParams();
  const lang = useLanguage((s) => s.language);
  const setPageTitle = useUIStore((s) => s.setPageTitle);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    init(userId, navigate).then(() => {
      setLoading(false);
    });
    setPageTitle(lang.prm.browser_tab_title);
  }, [userId]);

  return (
    <>
      <Group className="layout-block top">
        <PageTitle title={lang.prm.browser_tab_title} />
        <LeadsBaseSelector />

        <TitleSection />
      </Group>

      <Stack
        pos={"relative"}
        // h={"100%"}
        className="layout-block"
        flex={"1 1 auto"}
        px={"sm"}
        gap={"xs"}
        w={"calc(100% - 32px)"}
        mx={16}
      >
        {loading ? (
          <LoadingOverlay visible />
        ) : (
          <>
            <Controller />

            <Containers />

            <LeadSheet />
          </>
        )}
      </Stack>

      <RequiredActions />
    </>
  );
};

export default LeadsTracking;
