import { notifications } from "@mantine/notifications";
import { updateProspect } from "../../../../../api/PRMAPI";
import PRMStatusSelector from "../../../../../components/Selectors/PRMStatusSelector";
import { useLeadsContainerStore } from "../../../../../stores/LeadsStore";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { useState } from "react";

const StatusChanger = ({ current, setCurrent, container, index }) => {
  const lang = useLanguage((s) => s.language);
  const updateContainer = useLeadsContainerStore((s) => s.updateContainer);
  const containers = useLeadsContainerStore((s) => s.containers);
  const [loading, setLoading] = useState(false);

  const onStatusChange = (newStatus) => {
    setLoading(true);
    let newItem =
      newStatus === "to_call"
        ? { ...current, custom_status: null, status: "to_call" }
        : { ...current, custom_status: parseInt(newStatus) };
    updateProspect({
      id: current.id,
      custom_status: newItem.custom_status,
      status: newItem.status,
    })
      .then(() => {
        notifications.show({
          message: lang.prm.prospect_moved,
          color: "tertiary.8",
        });

        setCurrent(
          index <= container.items.length
            ? container.items[index + 1]
            : container.items[index - 1]
        );
        // Delete the item from its container
        let newItemsOnCurrentContainer = container.items.filter(
          (item) => item.id !== current.id
        );
        updateContainer(container.id, {
          items: newItemsOnCurrentContainer,
          total: container.total - 1,
          needCacheReset: true,
        });

        // Add the item to its next container
        let newContainer = containers.find(
          (x) => x.id.toString() === newStatus
        );
        let newItemsFuture = [...newContainer.items];
        newItemsFuture.unshift({
          ...newItem,
          status_changed_date: new Date(),
        });
        updateContainer(newContainer.id, {
          total: newContainer.total + 1,
          items: newItemsFuture,
          needCacheReset: true,
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <PRMStatusSelector
      value={current?.custom_status?.toString() || current?.status}
      onChange={onStatusChange}
      noLabel={true}
      w={150}
      disabled={loading || !current?.permission?.write_permission}
      required
      defaultOptions={containers.map((x) => ({
        value: x.id?.toString(),
        label: x.name,
        disabled: x.status && x.status !== "to_call",
      }))}
    />
  );
};

export default StatusChanger;
