import { useState } from "react";
import { useLeadsStore } from "../../../../../stores/LeadsStore";
import { notifications } from "@mantine/notifications";
import {
  Button,
  Group,
  Indicator,
  Paper,
  Select,
  Stack,
  Switch,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { useLanguage } from "../../../../../stores/LanguageStore";
import FilterContent from "../../../../../components/Filter/FilterContent";
import {
  IconClock,
  IconFilter,
  IconPhotoSensor2,
  IconSwitchHorizontal,
} from "@tabler/icons-react";
import PopOver from "../../../../../components/View/PopOver";
import {
  deleteZapierHook,
  editZapierHooks,
  sendZapierHook,
  testZapierHook,
} from "../../../../../api/ZapierApi";
import ItemDeleter from "../../../../../components/TableComponents/ItemDeleter";
import ZapierActivator from "./ZapierActivator";
import Jobs from "../../../lists/components/jobs/Jobs";
import { parseDate } from "../../../../../functions/dates";

const ZapierItem = ({ item, handleReload, type }) => {
  const lang = useLanguage((s) => s.language);
  const fields = useLeadsStore((s) => s.filters);
  const [filter, setFilter] = useState(item?.filter || null);
  const [ignoreSynced, setIgnoreSynced] = useState(
    !item.resend_synchronized_contacts
  );

  const [loading, setLoading] = useState(null);
  const onFilterChange = (newFilter, close) => {
    setLoading("filter");

    editZapierHooks({ id: item.id, filter: newFilter })
      .then((r) => {
        notifications.show({
          message: lang.sync_zapier.filter_saved,
          color: "tertiary.8",
        });
        setFilter(newFilter);
        close();
      })
      .finally(() => {
        setLoading(null);
      });
  };

  const [nameKey, setNameKey] = useState(0);
  const onNameChange = (e) => {
    let value = e.target.value;

    if (!value) {
      return setNameKey((k) => k + 1);
    }

    setLoading("name");
    editZapierHooks({ id: item.id, name: value })
      .then((r) => {
        notifications.show({
          message: lang.sync_zapier.renamed,
          color: "tertiary.8",
        });
      })
      .finally(() => {
        setLoading(null);
      });
  };

  const [urlKey, setUrlKey] = useState(0);
  const onUrlChange = (e) => {
    let value = e.target.value;

    if (!value) {
      return setNameKey((k) => k + 1);
    }

    setLoading("url");
    editZapierHooks({ id: item.id, url: value })
      .then((r) => {
        notifications.show({
          message: lang.sync_zapier.link_edited,
          color: "tertiary.8",
        });
      })
      .catch(() => {
        setUrlKey((x) => x + 1);
      })
      .finally(() => {
        setLoading(null);
      });
  };

  const onEventChange = (v) => {
    setLoading("event");
    editZapierHooks({ id: item.id, event: v })
      .then((r) => {
        // notifications.show({
        //   message: lang.sync_zapier.,
        //   color: "tertiary.8",
        // });
      })
      .finally(() => {
        setLoading(null);
      });
  };

  const handleTest = () => {
    setLoading("test");
    testZapierHook({ id: item?.id })
      .then(() => {
        notifications.show({
          message: lang.sync_zapier.test_success,
          color: "tertiary.8",
        });
        handleReload();
      })
      .finally(() => setLoading(false));
  };

  const handleSend = () => {
    setLoading("send");
    sendZapierHook({ id: item?.id })
      .then(() => {
        notifications.show({
          message: lang.sync_zapier.send_success,
          color: "tertiary.8",
        });
        handleReload();
      })
      .finally(() => setLoading(false));
  };

  const handleIgnore = () => {
    setLoading("ignore");
    editZapierHooks({
      id: item.id,
      resend_synchronized_contacts: ignoreSynced,
    })
      .then((r) => {
        notifications.show({
          message: lang.sync_zapier.link_edited,
          color: "tertiary.8",
        });
        setIgnoreSynced(!ignoreSynced);
      })
      .finally(() => {
        setLoading(null);
      });
  };

  return (
    <Paper component={Stack} withBorder p={"sm"}>
      <Group wrap="nowrap" gap={"xl"}>
        <TextInput
          placeholder={lang.sync_zapier.name}
          defaultValue={item.name}
          onBlur={onNameChange}
          key={nameKey}
          w={"100%"}
          disabled={loading === "name"}
        />
        <TextInput
          type="url"
          key={urlKey}
          placeholder={lang.sync_zapier.hook_url}
          defaultValue={item.url}
          onBlur={onUrlChange}
          w={"100%"}
          disabled={loading === "url"}
        />

        <ZapierActivator item={item} />

        <ItemDeleter
          deletedMessage={lang.sync_zapier.delete_success}
          title_bold={`${item.name} #${item.id}`}
          handleDelete={deleteZapierHook}
          id={item.id}
          onFinish={() => handleReload()}
        />
      </Group>

      <Group wrap="nowrap" gap={"xl"}>
        {type === "prm" && (
          <PopOver
            opener={(openFunction, ref) => {
              return (
                <Button
                  ref={ref}
                  variant="default"
                  onClick={openFunction}
                  id={"nurturing-filter-" + "creation"}
                  w={200}
                >
                  <Indicator
                    color="red"
                    offset={2}
                    disabled={filter?.items?.length === 0 || !filter}
                    label={filter?.items?.length}
                  >
                    <IconFilter size={18} />
                  </Indicator>
                  <span style={{ marginLeft: 6 }}>
                    {lang.table.toolbar.filter.main_button_label}
                  </span>
                </Button>
              );
            }}
            popoverChildren={(closePopover) => (
              <FilterContent
                fields={fields}
                id={"nurturing-item-" + item.id}
                isClient={false}
                pageName={"prm"}
                maxHeight={350}
                mainFilter={filter}
                buttonLoading={loading === "filter"}
                setMainFilter={(v) => {
                  onFilterChange(v, closePopover);
                }}
                defaultFiltersClose
                getFieldOptionName={(f) => f.option_name}
              />
            )}
            elevation={3}
          />
        )}

        <Select
          name="event"
          placeholder={lang.sync_zapier.event_label}
          defaultValue={item.event}
          required
          data={Object.keys(lang.sync_zapier.events).map((x) => ({
            value: x,
            label: lang.sync_zapier.events[x],
          }))}
          w={150}
          onChange={onEventChange}
          disabled={loading === "event"}
        />

        <Group mr={8}>
          <Tooltip label={lang.sync_zapier.last_run}>
            <IconClock size={18} />
          </Tooltip>
          <Text size="sm">
            {item?.last_run
              ? parseDate(item?.last_run)
              : lang.sync_zapier.never_used}
          </Text>
        </Group>

        <Jobs isZapier row={item} onDiff={handleReload} />
      </Group>

      <Group>
        <Switch
          label={lang.sync_zapier.ignore}
          checked={ignoreSynced}
          onClick={handleIgnore}
          disabled={loading === "ignore"}
        />
      </Group>

      <Group>
        <Button
          loading={loading === "test"}
          variant="light"
          loadingPosition="center"
          leftSection={<IconPhotoSensor2 size={18} />}
          onClick={handleTest}
        >
          {lang.sync_zapier.test}
        </Button>
        <Button
          loading={loading === "send"}
          variant="light"
          loadingPosition="center"
          color="tertiary.8"
          leftSection={<IconSwitchHorizontal size={18} />}
          onClick={handleSend}
        >
          {lang.sync_zapier.send}
        </Button>
      </Group>
    </Paper>
  );
};

export default ZapierItem;
