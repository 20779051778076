import { Badge, Divider, Stack, Stepper } from "@mantine/core";
import ActivityItem from "./ActivityItem";
import { getRelativeDate } from "../../../../../../../functions/dates";

const ActivityGroup = ({ group, date, outside }) => {
  return (
    <Stack mt={-14} gap={outside ? 16 : 6}>
      <Divider
        label={
          <Badge
            variant="white"
            size="lg"
            style={{
              // boxShadow: "#00000018 0 2px 3px",
              border: "1px solid #00000021",
            }}
            fw={600}
            fz={10}
            color="gray"
          >
            {getRelativeDate(date)}
          </Badge>
        }
      />

      <Stepper
        active={0}
        iconSize={28}
        size="sm"
        radius={"lg"}
        styles={{ stepIcon: { border: "none", background: "none" } }}
        orientation="vertical"
      >
        {group.map((item, index) => (
          <ActivityItem
            key={item?.id || index}
            item={item}
            isLast={index === group.length - 1}
            isOutside={!!outside}
          />
        ))}
      </Stepper>
    </Stack>
  );
};

export default ActivityGroup;
