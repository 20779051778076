import React, { useEffect, useState } from "react";
import { useLeadsStore } from "../../../../../stores/LeadsStore";
import { useListStore } from "../../../../../stores/ListStore";
import { deleteNurturing, editNurturing } from "../../../../../api/PRMAPI";
import { notifications } from "@mantine/notifications";
import {
  Box,
  Button,
  Group,
  Indicator,
  Paper,
  Stack,
  TextInput,
} from "@mantine/core";
import { useLanguage } from "../../../../../stores/LanguageStore";
import FilterContent from "../../../../../components/Filter/FilterContent";
import { IconFilter } from "@tabler/icons-react";
import PopOver from "../../../../../components/View/PopOver";
import ContactListSelector from "../../../../../components/Selectors/ContactListSelector";
import ItemDeleter from "../../../../../components/TableComponents/ItemDeleter";

const NurturingItem = ({ item, handleReload }) => {
  const lang = useLanguage((s) => s.language);
  const fields = useLeadsStore((s) => s.filters);
  const contactLists = useListStore((s) => s.lists);
  const [filter, setFilter] = useState(item?.filter || null);
  const [lists, setLists] = useState([]);

  useEffect(() => {
    setLists(
      item.contact_list_ids.map((x) => {
        let c = contactLists?.find((list) => list?.id === x);
        if (c) {
          return {
            ...c,
            option_name: c.name,
          };
        }
      })
    );
  }, [contactLists]);

  const [loading, setLoading] = useState(null);
  const onFilterChange = (newFilter, close) => {
    setLoading("filter");

    editNurturing({ id: item.id, filter: newFilter })
      .then((r) => {
        notifications.show({
          message: lang.prm.nurturing.filter_saved,
          color: "tertiary.8",
        });
        setFilter(newFilter);
        close();
      })
      .finally(() => {
        setLoading(null);
      });
  };

  const [nameKey, setNameKey] = useState(0);
  const onNameChange = (e) => {
    let value = e.target.value;

    if (!value) {
      return setNameKey((k) => k + 1);
    }

    setLoading("name");
    editNurturing({ id: item.id, name: value })
      .then((r) => {
        notifications.show({
          message: lang.prm.nurturing.renamed,
          color: "tertiary.8",
        });
      })
      .finally(() => {
        setLoading(null);
      });
  };
  const onListChange = (v) => {
    setLoading("list");
    editNurturing({ id: item.id, contact_list_ids: v.map((x) => x.id) })
      .then((r) => {
        // notifications.show({
        //   message: lang.prm.nurturing.,
        //   color: "tertiary.8",
        // });
        setLists(v);
      })
      .finally(() => {
        setLoading(null);
      });
  };

  return (
    <Paper component={Stack} withBorder p={"sm"}>
      <Group wrap="nowrap">
        <TextInput
          placeholder={lang.prm.nurturing.rename_name}
          defaultValue={item.name}
          onBlur={onNameChange}
          key={nameKey}
          w={"100%"}
          disabled={loading === "name"}
        />

        <ItemDeleter
          deletedMessage={lang.prm.nurturing.delete_success}
          title_bold={`${item.name} #${item.id}`}
          handleDelete={deleteNurturing}
          id={item.id}
          onFinish={() => handleReload()}
        />
      </Group>

      <PopOver
        opener={(openFunction, ref) => {
          return (
            <Button
              ref={ref}
              variant="default"
              onClick={openFunction}
              id={"nurturing-filter-" + "creation"}
            >
              <Indicator
                color="red"
                offset={2}
                disabled={filter?.items?.length === 0 || !filter}
                label={filter?.items?.length}
                size={16}
              >
                <IconFilter size={18} />
              </Indicator>
              <span style={{ marginLeft: 6 }}>
                {lang.table.toolbar.filter.main_button_label}
              </span>
            </Button>
          );
        }}
        popoverChildren={(closePopover) => (
          <FilterContent
            fields={fields}
            id={"nurturing-item-" + item.id}
            isClient={false}
            pageName={"prm"}
            maxHeight={350}
            mainFilter={filter}
            buttonLoading={loading === "filter"}
            setMainFilter={(v) => {
              onFilterChange(v, closePopover);
            }}
            defaultFiltersClose
            getFieldOptionName={(f) => f.option_name}
          />
        )}
        elevation={3}
      />

      <Box h={150}>
        <ContactListSelector
          onChange={onListChange}
          values={lists}
          disabled={loading === "list"}
        />
      </Box>
    </Paper>
  );
};

export default NurturingItem;
