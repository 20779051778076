import { Group, NumberFormatter, Text } from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";

const MappingStats = ({ fields, data }) => {
  const lang = useLanguage((s) => s.language);
  const columns = useMemo(() => {
    return Object.keys(data?.[0]) || [];
  }, [data]);
  const [stats, setStats] = useState({
    mapped: 0,
    notMapped: data[0]?.length || 0,
  });
  useEffect(() => {
    // Filter so that only File's column are counted
    let statFields = {};
    Object.keys(fields || {}).forEach((field) => {
      if (columns.includes(field)) {
        statFields = { ...statFields, [field]: fields[field] };
      }
    });

    let mapped = Object.values(statFields).filter((x) => !!x).length;
    let notMapped = columns.length - mapped;

    setStats({ mapped: mapped, notMapped: notMapped });
  }, [fields, columns]);

  return (
    <Group gap={"xl"}>
      <Text>
        <b>
          <NumberFormatter value={stats.mapped} thousandSeparator=" " />
        </b>{" "}
        {lang.contact_lists.import.step_mapping.stats.field}{" "}
        {lang.contact_lists.import.step_mapping.stats.to_import}
      </Text>
      <Text>
        <b>
          <NumberFormatter value={stats.notMapped} thousandSeparator=" " />
        </b>{" "}
        {lang.contact_lists.import.step_mapping.stats.field}{" "}
        {lang.contact_lists.import.step_mapping.stats.to_ignore}
      </Text>
    </Group>
  );
};

export default MappingStats;
