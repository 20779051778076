import { Button, Stack } from "@mantine/core";
import { useEffect, useMemo, useRef, useState } from "react";
import AnswersLeadItem from "./AnswersLeadItem";
import { requestNextPage } from "../../../../../api/PRMAPI";
import { useLeadsContainerStore } from "../../../../../stores/LeadsStore";
import { IconReload } from "@tabler/icons-react";

const AnswersLeft = ({ container, current, setCurrent, loading }) => {
  const updateContainer = useLeadsContainerStore((s) => s.updateContainer);
  const ref = useRef(null);
  const containerColor = useMemo(
    () => (container?.color ? `#${container?.color}` : null),
    [container]
  );

  const handleClick = (val) => {
    setCurrent(val);
  };

  const [loadingNext, setLoadingNext] = useState(false);
  const handleNextPage = () => {
    if (container?.nextPageUrl) {
      setLoadingNext(true);
      return requestNextPage(container.nextPageUrl)
        .then((response) => {
          updateContainer(container.id, {
            nextPageUrl: response.data?.next_page,
            items: [...container.items, ...response.data.results],
            loadingNext: false,
          });
        })
        .finally(() => setLoadingNext(false));
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (ref.current) {
        let activeElement = document.getElementById("prospect-" + current.id);
        ref.current.scrollTop = activeElement
          ? activeElement.offsetTop - 250
          : 0;
      }
    }, 50);
  }, [current.id]);

  return (
    <Stack
      w={"20%"}
      gap={0}
      style={{
        overflow: "auto",
        scrollBehavior: "smooth",
      }}
      h={"75vh"}
      pr={"sm"}
      ref={ref}
    >
      <Stack gap={0}>
        {container.items.map((item, i) => (
          <AnswersLeadItem
            key={item.id}
            index={i}
            active={current.id === item.id}
            color={containerColor}
            current={item}
            handleClick={() => handleClick(item)}
            disabled={loading}
          />
        ))}
        {container?.nextPageUrl && (
          <Button
            onClick={handleNextPage}
            variant="light"
            mt={"sm"}
            leftSection={<IconReload size={16} />}
            loading={loadingNext}
          >
            Charger Plus
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default AnswersLeft;
