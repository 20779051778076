import { useState } from "react";
import FilesContent from "./FilesContent";
import {
  Anchor,
  Button,
  Flex,
  Group,
  Modal,
  Text,
  Tooltip,
} from "@mantine/core";
import { useListingLoader } from "../../../components/hooks/ListingHooks";
import { editFile, getFiles } from "../../../api/FIleAPI";
import { IconFiles, IconPhoto } from "@tabler/icons-react";
import { useLanguage } from "../../../stores/LanguageStore";
import BackButton from "../../../components/View/BackButton";
import TableLayoutBlock from "../../../layouts/Table/TableLayoutBlock";

const imageDef = ["jpg", "jpeg", "png", "gif"];
const codeDef = ["html"];
const powerpointDef = ["pot", "potx", "ppt", "pptx"];
const excelDef = ["xls", "xlsx", "csv"];
const docsDef = ["pdf", "doc", "docx"];

const FilesModal = ({ isTemplate, editorRef, isCode, buttonProps }) => {
  const lang = useLanguage((s) => s.language);
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [folder, setFolder] = useState(null);

  const handleOpen = () => {
    setOpened(true);
    setLoading(true);
  };

  const handleClose = () => {
    setOpened(false);
  };

  const handleInsertContent = (content) => {
    if (isCode) {
      editorRef.current.editor.session.insert(
        editorRef.current.editor.getCursorPosition(),
        content
      );
      return handleClose();
    }
    editorRef.current.execCommand("mceInsertContent", false, content);
    handleClose();
  };

  useListingLoader({
    loading,
    setLoading,
    listGetter: getFiles,
    responseField: "files_list",
    getRows: (result) => {
      const filesWithType = result.map((file) => {
        const fileExtension = file.file_url;
        let type;
        if (imageDef.some((ext) => fileExtension.endsWith(ext))) {
          type = "image";
        }
        if (codeDef.some((ext) => fileExtension.endsWith(ext))) {
          type = "code";
        }
        if (powerpointDef.some((ext) => fileExtension.endsWith(ext))) {
          type = "powerpoint";
        }
        if (excelDef.some((ext) => fileExtension.endsWith(ext))) {
          type = "excel";
        }
        if (docsDef.some((ext) => fileExtension.endsWith(ext))) {
          type = "docs";
        }
        return { ...file, type };
      });

      return filesWithType;
    },
    setRows,
    folderId: folder?.id,
  });

  return (
    <>
      <Tooltip label={lang.files.grid.import.button_tooltip}>
        <Button
          size="sm"
          h={32}
          leftSection={<IconPhoto size={18} />}
          onClick={handleOpen}
          color="primary"
          {...buttonProps}
        >
          {lang.files.grid.import.button_label}
        </Button>
      </Tooltip>

      {opened && (
        <Modal
          opened={opened}
          onClose={handleClose}
          title={
            <Text>
              {isTemplate
                ? lang.files.grid.import.dialog.template_title
                : lang.files.grid.import.dialog.title}
              <Anchor
                href="/extra/files"
                target="_blank"
                title={lang.files.grid.import.dialog.goto_files}
              >
                {" "}
                {lang.files.grid.import.dialog.your_files}
              </Anchor>{" "}
              {lang.files.grid.import.dialog.uploaded}
            </Text>
          }
          size={"100%"}
        >
          <Group
            w={"100%"}
            h={"70vh"}
            style={{ overflow: "auto" }}
            align="start"
          >
            <TableLayoutBlock
              folderType={"File"}
              parentRoute={"/extra/files"}
              editFunction={editFile}
              setTableLoading={setLoading}
              wrapperProps={{
                h: "100%",
                mx: 0,
              }}
              folderId={folder?.id || null}
              onFolderItemClick={(item) => {
                setFolder(item || null);
                setLoading(true);
              }}
            >
              <Flex h={"100%"} p={"sm"} w={"100%"}>
                <FilesContent
                  loading={loading}
                  files={rows}
                  setLoading={setLoading}
                  handleInsertContent={handleInsertContent}
                  isTemplate={isTemplate}
                  handleClose={handleClose}
                  setRows={setRows}
                  isCode={isCode}
                  folder={folder}
                  inModal
                />
              </Flex>
            </TableLayoutBlock>
          </Group>
          <Group justify="end" mt={"md"}>
            <BackButton onClick={handleClose} />
          </Group>
        </Modal>
      )}
    </>
  );
};

export default FilesModal;
