import { Button, Select, Text } from "@mantine/core";
import { IconChevronLeft, IconFoldUp } from "@tabler/icons-react";
import { useMemo, useState } from "react";
import { importUnsub } from "../../../../api/UnsubscribersAPI";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useProfileStore } from "../../../../stores/UserStore";

const UnsubImportStep = ({ data, handlePrev, setImporting, onFinish }) => {
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);
  const [org, setOrg] = useState(null);
  const [error, setError] = useState(null);
  const handleUnsubImports = () => {
    setImporting(true);
    importUnsub(org ? parseInt(org) : null, data)
      .then((repsonse) => {
        onFinish?.();
      })
      .catch((err) => {
        let err_data = err.response.data;
        setError(err_data);
      })
      .finally(() => {
        setImporting(false);
      });
  };

  const main = useMemo(() => {
    if (error) {
      if (error?.state_message === "import_limit_exceeded") {
        return (
          <Text>
            {
              lang.unsubscribers.import.step_importing.error
                .import_limit_exceeded
            }{" "}
            ({data.length}{" "}
            {lang.unsubscribers.import.step_importing.error.rows_to_import})
          </Text>
        );
      }

      if (error?.errors?.length > 0) {
        return error.errors.map((err, index) => (
          <Text key={index}>
            {lang.unsubscribers.import.step_importing.error.error_on_line}{" "}
            {err?.line} :{" "}
            {err?.error.includes("format")
              ? lang.unsubscribers.import.step_importing.error.unknown_format
              : err?.error}
          </Text>
        ));
      }
    } else {
      return (
        <>
          <Text>
            <b>{data?.length}</b> données seront importées. <br /> <br />
            Tous contacts correspondants à ces données seront ignorées sur tous
            vos campagnes.
          </Text>

          <Select
            value={org}
            onChange={setOrg}
            placeholder={lang.unsubscribers.columns.org}
            label={"Vous pouvez désinscrire ces données pour une organisation"}
            description="Laisser vide pour désinscrire que pour vous"
            data={profile.organizations?.map((x) => ({
              label: x.name,
              value: x.id.toString(),
            }))}
            searchable
          />
        </>
      );
    }
  }, [
    data.length,
    error,
    lang,
    org,
    profile.organizations,
  ]);

  return (
    <>
      {main}

      <Button
        disabled={data?.length < 1}
        mt={"md"}
        variant="gradient"
        leftSection={<IconFoldUp />}
        onClick={handleUnsubImports}
      >
        Importer
      </Button>
      <Button
        variant="subtle"
        onClick={handlePrev}
        leftSection={<IconChevronLeft />}
      >
        {lang.global.back}
      </Button>
    </>
  );
};

export default UnsubImportStep;
