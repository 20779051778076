import { Flex, Stack } from "@mantine/core";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { useLanguage } from "../../../../../../../stores/LanguageStore";
import { useCallback, useMemo } from "react";
import SelectionDeleter from "../../../../../../../components/TableComponents/SelectionDeleter";
import { deleteReminder } from "../../../../../../../api/PRMAPI";
import { useLeadsSheetStore } from "../../../../../../../stores/LeadsStore";
import { nameColumn } from "../../../../../../../layouts/Table/columns/nameColumn";
import numberColumn from "../../../../../../../layouts/Table/columns/numberColumn";
import { reminderTypes } from "../../../../utils";
import MainTable from "../../../../../../../layouts/Table/MainTable";
import ItemDeleter from "../../../../../../../components/TableComponents/ItemDeleter";

const ProspectReminders = () => {
  const lang = useLanguage((s) => s.language);
  const current = useLeadsSheetStore((s) => s.current);
  const apiRef = useGridApiRef();
  const setLoading = useLeadsSheetStore((s) => s.setLoading);
  const setCurrent = useLeadsSheetStore((s) => s.setCurrent);
  const rows = useMemo(() => {
    return current?.calls || [];
  }, [current]);

  const columns = useMemo(
    () => [
      numberColumn(),
      nameColumn(),
      {
        field: "call_date",
        headerName: lang.prm.details.reminders.date,
        width: 175,
        valueGetter: ({ value }) => new Date(value).toLocaleString(),
      },
      {
        field: "type",
        headerName: lang.prm.details.reminders.type,
        width: 150,
        valueGetter: ({ value }) => reminderTypes()[value],
      },
      {
        field: "created_by",
        headerName: lang.statistics.columns.created_by.header_name,
        width: 175,
        valueGetter: ({ value }) => {
          return `${value?.first_name} ${value?.last_name} #${value?.id}`;
        },
      },
    ],
    [lang]
  );

  const toolbarActions = useCallback(
    ({ selectedIds }) => {
      return (
        <SelectionDeleter
          handleDelete={deleteReminder}
          setTableLoading={setLoading}
          ids={selectedIds}
          parentId={current.id}
        />
      );
    },
    [current.id, setLoading]
  );

  const actionsCell = useCallback(
    ({ row }) => {
      return (
        <ItemDeleter
          title_bold={`${row.name} #${row.id}`}
          handleDelete={deleteReminder}
          parentId={current.id}
          deletedMessage={lang.prm.details.reminders.delete_success}
          id={row.id}
          disabled={!current?.permission?.write_permission || false}
          apiRef={apiRef}
          onFinish={() =>
            setCurrent({
              ...current,
              calls: current.calls.filter((x) => x.id !== row.id),
            })
          }
        />
      );
    },
    [apiRef, current, lang.prm.details.reminders.delete_success, setCurrent]
  );

  return (
    <Stack gap={0} h={"100%"} style={{ overflow: "auto" }} w={"100%"}>
      <Flex flex={1} bg={"white"}>
        <MainTable
          apiRef={apiRef}
          columns={columns}
          rows={rows}
          id={"reminder-table"}
          toolbarActions={toolbarActions}
          actionsCell={actionsCell}
        />
      </Flex>
    </Stack>
  );
};

export default ProspectReminders;
