import { Anchor, Badge, Group, Text, Tooltip } from "@mantine/core";
import { IconMailForward, IconSend, IconUser } from "@tabler/icons-react";
import { useAccountsStore } from "../../../../../../stores/ConfigStore";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { useLanguage } from "../../../../../../stores/LanguageStore";

const SenderVisual = ({ data, inReview }) => {
  const lang = useLanguage((s) => s.language);
  const emails = useAccountsStore((s) => s.email_accounts);
  const linkedin_accounts = useAccountsStore((s) => s.linkedin_accounts);
  const pools = useAccountsStore((s) => [
    ...s.email_pools,
    ...s.linkedin_pools,
  ]);

  const account = useMemo(() => {
    if (data?.pool_id) {
      return pools.find((x) => x.id === data?.pool_id);
    }
    if (data?.actionType === "email") {
      if (data?.integration_id)
        return emails.find((x) => x.id === data?.integration_id);
    } else if (data?.actionType.includes("linkedin")) {
      if (data?.integration_id)
        return linkedin_accounts.find((x) => x.id === data?.integration_id);
    }
  }, [
    data?.actionType,
    data?.integration_id,
    data?.pool_id,
    emails,
    linkedin_accounts,
    pools,
  ]);

  return inReview ? (
    <Group w={"fit-content"} gap={"sm"}>
      <IconSend size={18} opacity={.5} />
      <Text>{lang.campaign.review.sender}: </Text>
      <Tooltip
        label={`${account?.name} #${account?.id} ${
          account?.email ? `(${account?.email})` : ""
        }`}
      >
        <Anchor
          target="_blank"
          component={Link}
          to={`/senders/${
            data?.pool_id
              ? "pool"
              : data?.actionType === "email"
              ? "email"
              : "linkedin"
          }/${account?.id}`}
          w={"fit-content"}
        >
          {account?.name || " - "} #{account?.id || " - "}
        </Anchor>
      </Tooltip>
    </Group>
  ) : account?.id ? (
    <Tooltip
      label={`${account?.name} #${account?.id} ${
        account?.email ? `(${account?.email})` : ""
      }`}
    >
      <Badge
        color={"tertiary.8"}
        leftSection={
          data?.actionType === "email" ? (
            <IconMailForward size={12} />
          ) : (
            <IconUser size={12} />
          )
        }
        fw={"normal"}
        size="md"
        variant="light"
        ml={"auto"}
        // px={0}
        tt={"none"}
        maw={150}
      >
        #{account.id} {account.name}{" "}
        {!data?.actionType.includes("linkedin") && (
          <>({data?.pool_id ? account?.number_of_accounts : account?.email})</>
        )}
      </Badge>
    </Tooltip>
  ) : null;
};

export default SenderVisual;
