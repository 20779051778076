import TagsSelector from "./TagsSelector";
import { useLanguage } from "../../stores/LanguageStore";
import { IconTags } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { createTag, getTags } from "../../api/TagsAPI";
import { useConfigStore } from "../../stores/ConfigStore";

const TagsRessourceSelector = ({ value, setValue, autoFocus }) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(true);
  const data = useConfigStore((s) => s.tags);

  useEffect(() => {
    if (loading) {
      getTags().finally(() => setLoading(false));
    }
  }, [loading]);

  return (
    <TagsSelector
      placeholder={lang.tags.enter_to_add}
      label={lang.tags.browser_tab_title}
      leftSection={<IconTags size={16} />}
      loading={loading}
      createLabel={lang.tags.create_tag}
      data={data}
      createFunction={createTag}
      value={value}
      setValue={setValue}
      onDropdownOpen={() => setLoading(true)}
      onNewValueCreated={() => setLoading(true)}
      created_field_id={"tag_id"}
      autoFocus={autoFocus}
    />
  );
};

export default TagsRessourceSelector;
