import { Flex, Group } from "@mantine/core";
import { useState } from "react";
import { useListingLoader } from "../../../components/hooks/ListingHooks";
import { editFile, getFiles } from "../../../api/FIleAPI";
import FilesContent from "./FilesContent";
import CreateFileButton from "./CreateFileButton";
import TableLayoutBlock from "../../../layouts/Table/TableLayoutBlock";
import PageTitle from "../../../components/View/PageTitle";
import { useLanguage } from "../../../stores/LanguageStore";

const imageDef = ["jpg", "jpeg", "png", "gif"];
const codeDef = ["html"];
const powerpointDef = ["pot", "potx", "ppt", "pptx"];
const excelDef = ["xls", "xlsx", "csv"];
const docsDef = ["pdf", "doc", "docx"];

const FilesListing = () => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);

  useListingLoader({
    loading,
    setLoading,
    listGetter: getFiles,
    responseField: "files_list",
    getRows: (result) => {
      const filesWithType = result.map((file) => {
        const fileExtension = file.file_url;
        let type;
        if (imageDef.some((ext) => fileExtension.endsWith(ext))) {
          type = "image";
        }
        if (codeDef.some((ext) => fileExtension.endsWith(ext))) {
          type = "code";
        }
        if (powerpointDef.some((ext) => fileExtension.endsWith(ext))) {
          type = "powerpoint";
        }
        if (excelDef.some((ext) => fileExtension.endsWith(ext))) {
          type = "excel";
        }
        if (docsDef.some((ext) => fileExtension.endsWith(ext))) {
          type = "docs";
        }
        return { ...file, type };
      });

      return filesWithType;
    },
    setRows,
    folderRouteId: "file-folder",
  });

  return (
    <>
      <Group
        className="layout-block top"
        w={"100%"}
        gap={0}
        justify="space-between"
      >
        <PageTitle title={lang.files.browser_tab_title} />

        <CreateFileButton />
      </Group>

      <TableLayoutBlock
        folderType={"File"}
        parentRoute={"/extra/files"}
        editFunction={editFile}
        setTableLoading={setLoading}
      >
        <Flex h={"100%"} p={"sm"} w={"100%"}>
          <FilesContent
            loading={loading}
            files={rows}
            setLoading={setLoading}
            handleInsertContent={() => {}}
            isTemplate={false}
            handleClose={() => {}}
            isListing
            setRows={setRows}
          />
        </Flex>
      </TableLayoutBlock>
    </>
  );
};

export default FilesListing;
