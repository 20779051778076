import { Button, Group, Paper, Stack, Text } from "@mantine/core";
import { useLanguage } from "../../../../stores/LanguageStore";
import { IconArrowBarUp } from "@tabler/icons-react";
import workflow1 from "../../../../assets/workflows/workflow1.png";
import workflow2 from "../../../../assets/workflows/workflow2.png";
import workflow3 from "../../../../assets/workflows/workflow3.png";
import workflow4 from "../../../../assets/workflows/workflow4.png";
import workflow5 from "../../../../assets/workflows/workflow5.png";

const TemplateItem = ({ item, index, handleClose, onCreated }) => {
  const lang = useLanguage((s) => s.language);
  const handleUse = () => {
    onCreated?.(item, handleClose);
  };

  const getImage = () => {
    if (index === 0) {
      return workflow2;
    }
    if (index === 1) {
      return workflow1;
    }
    if (index === 2) {
      return workflow3;
    }
    if (index === 3) {
      return workflow4;
    }
    if (index === 4) {
      return workflow5;
    }
  };

  return (
    <Paper
      // shadow="sm"
      p={12}
      pt={8}
      radius="lg"
      withBorder
      h={"100%"}
      w={"19%"}
    >
      <Text fw={600} ta={"center"} mb={16}>
        {" "}
        {item?.name}{" "}
      </Text>
      <Stack h={250} w={"100%"}>
        <img
          src={getImage()}
          width={"100%"}
          height={"100%"}
          style={{ objectFit: "contain" }}
        />
      </Stack>

      <Group justify="center">
        <Button
          mt={"xl"}
          variant="light"
          leftSection={<IconArrowBarUp size={18} />}
          color="tertiary.8"
          onClick={handleUse}
        >
          {lang.workflows.templates["use"]}
        </Button>
      </Group>
    </Paper>
  );
};

export default TemplateItem;
