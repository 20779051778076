import {
  Button,
  Group,
  Radio,
  Stack,
  TextInput,
  Timeline,
  TimelineItem,
  Title,
} from "@mantine/core";
import { useMemo, useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import {
  extractLSearchSN,
  generateSalesNavigatorPeopleSearchUrl,
} from "../../../../../api/TargetingLinkedInAPI";
import {
  IconArrowBarUp,
  IconListDetails,
  IconSparkles,
} from "@tabler/icons-react";
import TitlesSelector from "./TitlesSelector";
import CompanyCountsSelector from "./CompanyCountsSelector";
import LocationsSelector from "./LocationsSelector";
import IndustriesSelector from "./IndustriesSelector";
import SenioritySelector from "./SenioritySelector";
import CompaniesSelector from "./CompaniesSelector";
import { useRouteLoaderData } from "react-router-dom";
import { useProfileStore } from "../../../../../stores/UserStore";
import GlobalLanguageSelector from "../../../../../components/Selectors/GlobalLanguageSelector";
import CountrySelector from "../../../../../components/Selectors/CountrySelector";
import CreatableContactListSelector from "../../../../../components/Selectors/CreatableContactListSelector";
import { executeSequentially } from "../../../../../functions/utilFunctions";
import RemainingHoverCard from "../../components/RemainingHoverCard";
import HowItWorks from "../../pages/HowItWorks";

const companiesLimit = 50;

const QuickImport = ({ onFinish, setShouldWarn }) => {
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);

  const listDataLoader = useRouteLoaderData("contact-list");
  const listDataFolderLoader = useRouteLoaderData("folder-contact-list");
  const listProfile = useMemo(() => {
    return listDataLoader || listDataFolderLoader;
  }, [listDataLoader, listDataFolderLoader]);

  const [loading, setLoading] = useState(false);
  const [currentTitles, setCurrentTitles] = useState([]);
  const [companyCounts, setCompanyCounts] = useState([]);
  const [locations, setLocations] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [seniority, setSeniority] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [headQuarters, setHeadQuarters] = useState([]);

  const [list, setList] = useState(listProfile);
  const [listState, setListState] = useState(listProfile ? 2 : 1);

  const handleSubmit = async (e) => {
    let form = document.getElementById("form");

    if (form.reportValidity()) {
      let f = new FormData(form);
      let data = Object.fromEntries(f);

      data = {
        contact_list_name: listState === 1 ? data?.contact_list_name : null,
        contact_list_id: listState === 2 ? list?.id : null,
        max_results: 2500,
        generate_email: e?.generate_email || false,
        linkedin_account_id: null,
      };

      data.exclude_viewed_leads = false;
      data.exclude_crm_contacts = false;

      let requests = [];
      const numberOfUploads = Math.ceil(companies.length / companiesLimit);

      setLoading(e?.generate_email ? "yes" : "no");
      const importFunction = async (sliced_companies) => {
        try {
          const response = await generateSalesNavigatorPeopleSearchUrl({
            current_companies: sliced_companies,
            current_titles: currentTitles,
            current_title: data?.current_title,
            company_head_counts: companyCounts,
            locations: locations.map((x) => parseInt(x)),
            industries: industries.map((x_1) => parseInt(x_1)),
            seniority_levels: seniority.map((x_2) => x_2),
            current_company: data?.current_company,
            company_headquarters: headQuarters.map((x_3) => parseInt(x_3)),
          });
          data.linkedin_sales_navigator_search_url = response.data.linkedin_url;

          return extractLSearchSN(data);
        } catch (err_1) {
          return setLoading(false);
        }
      };

      for (let i = 0; i < numberOfUploads; i++) {
        const sliced_companies = companies.slice(
          i * companiesLimit,
          (i + 1) * companiesLimit
        );

        requests.push(() => importFunction(sliced_companies));
      }

      if (companies.length > 0) {
        return executeSequentially(requests)
          .catch((err) => {
            console.log(err);
          })
          .then(() => {
            onFinish?.(list?.id);
          })
          .finally(() => setLoading(false));
      }
      return importFunction([])
        .then(() => {
          onFinish?.(list?.id);
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <Stack
      component={"form"}
      onSubmit={handleSubmit}
      gap={"md"}
      onChange={() => setShouldWarn?.(true)}
      w={"100%"}
      pl={12}
      pb={16}
      id="form"
    >
      <Title order={5}>{lang.targeting_linkedin_google.url_generation}</Title>
      <Timeline
        styles={{
          item: {
            marginBottom: -12,
          },
          itemContent: {
            marginLeft: 8,
          },
          itemBullet: {
            top: 6,
          },
        }}
        lineWidth={2}
        mb={16}
        bulletSize={12}
        color="tertiary.8"
      >
        <TimelineItem mt={-8}>
          <TitlesSelector
            currentTitles={currentTitles}
            setCurrentTitles={setCurrentTitles}
          />
        </TimelineItem>

        <TimelineItem>
          <Group w={"100%"} wrap="nowrap" align="start" gap="xl">
            <CompanyCountsSelector
              values={companyCounts}
              setValues={setCompanyCounts}
            />
            <LocationsSelector values={locations} setValues={setLocations} />
          </Group>
        </TimelineItem>

        <TimelineItem>
          <Group wrap="nowrap" align="start" gap="xl">
            <IndustriesSelector values={industries} setValues={setIndustries} />
            <SenioritySelector values={seniority} setValues={setSeniority} />
          </Group>
        </TimelineItem>
        <TimelineItem>
          <Group wrap="nowrap" align="start" gap="xl">
            <CompaniesSelector values={companies} setValues={setCompanies} />
            <LocationsSelector
              values={headQuarters}
              setValues={setHeadQuarters}
              isHeadQuarters
            />
          </Group>
        </TimelineItem>

        <TimelineItem>
          <Group w={"100%"} wrap="nowrap" gap={"xl"}>
            <GlobalLanguageSelector
              key={"language-" + list?.id}
              defaultValue={list?.language || profile?.contact_list_language}
              name={"contact_list_language"}
              size="sm"
              w={"100%"}
            />
            <CountrySelector
              key={"country-" + list?.id}
              defaultValue={list?.country || profile?.contact_list_country}
              name={"contact_list_country"}
              required
              w={"100%"}
            />
          </Group>
        </TimelineItem>

        <TimelineItem>
          <Group wrap="nowrap" align="end" gap={"xl"}>
            <Stack w="100%" gap={8}>
              <Radio
                label={lang.targeting_linkedin_google.create_new_list}
                name="list_state"
                checked={listState === 1}
                size="sm"
                onChange={(e) => setListState(1)}
              />
              <TextInput
                id="new-list-name"
                name="contact_list_name"
                w={"100%"}
                disabled={listState !== 1}
                styles={{ label: { fontSize: 13 } }}
                label={lang.targeting_linkedin_google.list_name}
                description={lang.contact_lists.create.dialog.desc}
                placeholder={lang.targeting_linkedin_google.list_name}
                required={listState === 1}
              />
            </Stack>
            <Stack w="100%" gap={4}>
              <Radio
                label={lang.targeting_linkedin_google.import}
                name="list_state"
                size="sm"
                checked={listState === 2}
                onChange={(e) => setListState(2)}
              />
              <CreatableContactListSelector
                value={list}
                setValue={setList}
                label={lang.targeting_linkedin_google.select_list}
                placeholder={lang.contact_lists.create.dialog.desc}
                w={"100%"}
                disabled={listState !== 2}
                styles={{ label: { fontSize: 13 } }}
                required={listState === 2}
                leftSection={<IconListDetails size={16} />}
                disableCreate
                description={lang.contact_lists.create.dialog.desc}
              />
            </Stack>
          </Group>

          <Group justify="center" mt={"lg"} gap={"xl"} wrap="nowrap">
            <Button
              variant="light"
              leftSection={<IconArrowBarUp />}
              loading={loading === "no"}
              onClick={(e) => handleSubmit({ ...e, generate_email: false })}
              w={300}
            >
              {lang.targeting_linkedin_google.submit_button_add}
            </Button>

            {profile?.permissions?.find((x) => x.name === "generateEmail")
              ?.value && (
              <Group wrap="nowrap" w={300} gap={"md"}>
                <RemainingHoverCard>
                  <Button
                    leftSection={<IconSparkles />}
                    loading={loading === "yes"}
                    onClick={(e) =>
                      handleSubmit({ ...e, generate_email: true })
                    }
                    w={250}
                  >
                    {lang.targeting_linkedin_google.submit_button_add_email}
                  </Button>
                </RemainingHoverCard>

                <HowItWorks type={"linkedin"} inside />
              </Group>
            )}
          </Group>
        </TimelineItem>
      </Timeline>
    </Stack>
  );
};

export default QuickImport;
