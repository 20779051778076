import CreateGmail from "./smtp/CreateGmail";
import CreateOutlook from "./smtp/CreateOutlook";
import CreateSMTP from "./smtp/CreateSMTP";
import CreateMassMailing from "./smtp/CreateMassMailing";

const CreateDropdown = ({ setTableLoading }) => {
  return (
    <>
      <CreateGmail setTableLoading={setTableLoading} />
      <CreateOutlook setTableLoading={setTableLoading} />
      <CreateSMTP />
      <CreateMassMailing />
    </>
  );
};

export default CreateDropdown;
