import { ActionIcon, Tooltip } from "@mantine/core";
import { IconSettings } from "@tabler/icons-react";
import { useLanguage } from "../../../../../../stores/LanguageStore";

const NodeAdvancedSettingsButton = ({ handleClick }) => {
  const lang = useLanguage((s) => s.language);
  return (
    <Tooltip label={lang.campaign.nodes.advanced_settings.tooltip}>
      <ActionIcon
        size={"sm"}
        variant="subtle"
        pos={"absolute"}
        right={30}
        top={2}
        onClick={handleClick}
        className="on-hover-opacity"
      >
        <IconSettings size={16} />
      </ActionIcon>
    </Tooltip>
  );
};

export default NodeAdvancedSettingsButton;
