import {
  Button,
  Center,
  Flex,
  Group,
  LoadingOverlay,
  SegmentedControl,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import TextEditor from "../../../../components/ExternalEditors/TextEditor";
import { useMemo, useRef, useState } from "react";
import { useConfigStore } from "../../../../stores/ConfigStore";
import { useLanguage } from "../../../../stores/LanguageStore";
import { IconPlus, IconTemplate, IconTextSize } from "@tabler/icons-react";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import EmailModalPreview from "../../../../components/View/EmailModalPreview";
import TemplateEditor from "../../../../components/ExternalEditors/TemplateEditor";
import BackButton from "../../../../components/View/BackButton";
import useNavigationBlocker from "../../../../components/View/NavigationBlocker";
import { createSignature, getSignatures } from "../../../../api/ConfigsApi";
import FilesModal from "../../files/FilesModal";

const CreateSignature = ({ handleClose, defaultCreationType, onCreated }) => {
  const editorRef = useRef();
  const templateEditorRef = useRef();
  const lang = useLanguage((s) => s.language);
  const datafields = useConfigStore((s) => s.datafields);
  const [dirty, setDirty] = useState(false);
  const datafieldTokens = useMemo(() => {
    let df = datafields.map((x) => ({
      label: x.name,
      value: x.identifier_placeholder,
    }));

    df = [
      {
        label:
          lang.components.variable_manager.dialog.unsubscribe_variable_name,
        value: "%unsubscribe%",
        name: lang.components.variable_manager.dialog.unsubscribe_variable_name,
      },
      {
        label: lang.components.variable_manager.dialog.webversion_variable_name,
        value: "%webversion%",
        name: lang.components.variable_manager.dialog.webversion_variable_name,
      },
      {
        label: lang.components.variable_manager.dialog.b64_variable_name,
        value: "%email_b64%",
        name: lang.components.variable_manager.dialog.b64_variable_name,
      },
      ...df,
    ];

    return df;
  }, [datafields, lang]);

  const datafieldTokensTemplate = useMemo(() => {
    let mergeTags = {
      [`[ ${lang.components.variable_manager.dialog.unsubscribe_variable_name} ]`]:
        {
          name: lang.components.variable_manager.dialog
            .unsubscribe_variable_name,
          value: "%unsubscribe%",
          sample: `[ ${lang.components.variable_manager.dialog.unsubscribe_variable_name} ]`,
        },
      [`[ ${lang.components.variable_manager.dialog.webversion_variable_name} ]`]:
        {
          name: lang.components.variable_manager.dialog
            .webversion_variable_name,
          value: "%webversion%",
          sample: `[ ${lang.components.variable_manager.dialog.webversion_variable_name} ]`,
        },
      [`[ ${lang.components.variable_manager.dialog.b64_variable_name} ]`]: {
        name: lang.components.variable_manager.dialog.b64_variable_name,
        value: "%email_b64%",
        sample: `[ ${lang.components.variable_manager.dialog.b64_variable_name} ]`,
      },
    };
    datafields.forEach((x) => {
      mergeTags = {
        ...mergeTags,
        [`[ ${x.name.toUpperCase()} ]`]: {
          name: x.name,
          value: x.identifier_placeholder,
          sample: `[ ${x.name.toUpperCase()} ]`,
        },
      };
    });
    return mergeTags;
  }, [datafields, lang]);

  //#region Creation Type
  const [active, setActive] = useState(defaultCreationType || "text");
  const [shouldWarn, setShouldWarn] = useState(false);

  const onActiveChange = (v) => {
    if (shouldWarn)
      modals.openConfirmModal({
        title: lang.models.alert_editor.title,
        children: <Text size="sm">{lang.models.alert_editor.text}</Text>,
        labels: { confirm: lang.global.confirm, cancel: lang.global.cancel },
        onCancel: () => {},
        onConfirm: () => {
          setShouldWarn(false);
          setActive(v);
        },
        centered: true,
      });
    else setActive(v);
  };

  const handleWarn = () => {
    setShouldWarn?.(true);
    setDirty(true);
  };
  //#endregion

  const handlePreview = ({ setHtml, setSubject, open }) => {
    const fd = new FormData(document.getElementById("model-form"));
    const data = Object.fromEntries(fd);

    if (active === "text") {
      setHtml(editorRef.current.getContent());
      setSubject(data.subject);
      open();
    } else {
      templateEditorRef.current.exportHtml((ex) => {
        setHtml(ex.html);
        setSubject(data.subject);
        open();
      });
    }
  };

  const [submitting, setSubmitting] = useState(false);
  const onSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const fd = new FormData(e.target);
    const data = Object.fromEntries(fd);

    let html = "";
    let text = "";
    let design_json = "";

    if (active === "text") {
      html = editorRef.current.getContent();
      text = editorRef.current.getContent({ format: "text" });
    } else {
      html = await new Promise((resolve) =>
        templateEditorRef.current.exportHtml((ex) => {
          resolve(ex.html);
        })
      );
      text = await new Promise((resolve) =>
        templateEditorRef.current.exportPlainText((ex) => {
          resolve(ex.text);
        })
      );
      design_json = await new Promise((resolve) =>
        templateEditorRef.current.saveDesign((design) => {
          resolve(JSON.stringify(design));
        })
      );
    }

    return createSignature({
      name: data.name,
      html,
      text,
      subject: data.subject,
      is_template: active === "template",
      template: design_json,
    })
      .then((response) => {
        let newId = response.data.email_signature_id;
        setSubmitting(true);
        getSignatures()
          .then((res) => {
            setDirty(false);
            setShouldWarn(false);
            let newItemsList = res.data.email_signatures_list;
            let newItem = newItemsList.find((x) => x.id === newId);
            notifications.show({
              title: lang.emails_signature.success,
              message: lang.emails_signature.success_text,
              color: "tertiary.8",
            });
            setTimeout(() => {
              onCreated?.(newItem);
              handleClose?.();
            }, 20);
          })
          .finally(() => {
            setSubmitting(false);
          });
      })
      .catch(() => setSubmitting(false));
  };

  useNavigationBlocker(dirty || shouldWarn, "submitter");

  return (
    <>
      {handleClose && (
        <LoadingOverlay
          visible={submitting}
          overlayProps={{ blur: 1 }}
          zIndex={1001}
        />
      )}

      <Stack
        component={"form"}
        onSubmit={onSubmit}
        gap={0}
        id="model-form"
        h={"100%"}
        flex={1}
        w={"100%"}
        onChange={() => setDirty(true)}
      >
        <Group wrap="nowrap">
          <TextInput
            label={lang.emails_signature.create.dialog.name}
            placeholder={lang.emails_signature.create.dialog.name}
            name="name"
            w="60%"
            required
            data-autofocus
            autoFocus
          />
        </Group>

        <SegmentedControl
          value={active}
          onChange={onActiveChange}
          // color="primary"
          data={[
            {
              value: "text",
              label: (
                <Center style={{ gap: 10 }}>
                  <IconTextSize />
                  <span>{lang.models.text_editor}</span>
                </Center>
              ),
            },
            {
              value: "template",
              label: (
                <Center style={{ gap: 10 }}>
                  <IconTemplate />
                  <span>{lang.models.template_editor}</span>
                </Center>
              ),
              // disabled: true,
            },
          ]}
          size="sm"
          mt={"sm"}
          style={{
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
          }}
          className="segmented34"
        />

        {active === "text" ? (
          <TextEditor
            editorRef={editorRef}
            placeholder={lang.model_emails.create.dialog.placeholder}
            onKeyUp={handleWarn}
            tokens={datafieldTokens}
          >
            <Group
              wrap="nowrap"
              style={{
                position: "absolute",
                top: 8,
                left: 140,
                zIndex: 4,
              }}
              gap={"lg"}
            >
              <FilesModal editorRef={editorRef} />
              {/* <Generator onFinish={onEmailGenerated} disabled /> */}
            </Group>
          </TextEditor>
        ) : (
          <TemplateEditor
            onReady={(editor) => {
              templateEditorRef.current = editor;
              templateEditorRef.current.addEventListener(
                "design:updated",
                function (data) {
                  setShouldWarn?.(true);
                }
              );
              templateEditorRef.current.setMergeTags(datafieldTokensTemplate);
            }}
          />
        )}

        <Group justify="space-between" mt={"sm"}>
          <Flex>
            <EmailModalPreview
              getData={handlePreview}
              withoutSignature
              isSign
              noSign
            />
          </Flex>
          <Flex gap={"md"}>
            <BackButton onClick={handleClose} />
            <Button
              type="submit"
              leftSection={<IconPlus size={18} />}
              loading={submitting}
              id="submitter"
            >
              {lang.emails_signature.create.dialog.submit_button}
            </Button>
          </Flex>
        </Group>
      </Stack>
    </>
  );
};

export default CreateSignature;
