import { Anchor, Button, Group, Paper, Stack, Text } from "@mantine/core";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { parseDate } from "../../../../../functions/dates";
import { IconCheck, IconExternalLink } from "@tabler/icons-react";
import { useState } from "react";
import { handleCaptcha } from "../../../../../api/PRMAPI";
import { useAccountsStore } from "../../../../../stores/ConfigStore";

const CaptchaItem = ({ item, setItems }) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(false);

  const emails = useAccountsStore((s) => s.email_accounts);

  const handleCheck = () => {
    setLoading(true);
    handleCaptcha(item.id)
      .then((res) => {
        setItems((prev) => {
          let newItems = [...prev];
          let index = newItems.findIndex((x) => x.id === item.id);
          newItems[index] = {
            ...newItems[index],
            handled: true,
          };

          return newItems;
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Paper component={Stack} withBorder p={"xs"} gap={6}>
      <Text fw={600}>
        {lang.prm.captcha.from} {item?.from}
      </Text>
      <Text fw={600} size="sm">
        {lang.prm.captcha.sender}{" "}
        <Anchor
          href={
            item.integration_id ? `/senders/email/${item.integration_id}` : null
          }
        >
          {item?.integration_id
            ? `${emails?.find((x) => x.id === item.integration_id)?.name} #${
                item.integration_id
              }`
            : "?"}
        </Anchor>
      </Text>
      <Text size="sm">
        {lang.prm.captcha.subject} {item?.subject}
      </Text>

      <Text size="sm" fw={400}>
        {parseDate(item.date_received)}
      </Text>

      <Group wrap="nowrap" gap={"lg"}>
        <Button
          disabled={!item?.from}
          component="a"
          href={item?.link}
          target="_blank"
          size="xs"
          w={"100%"}
          leftSection={<IconExternalLink size={18} />}
          variant="light"
        >
          {lang.prm.captcha.link}
        </Button>

        <Button
          size="xs"
          w={"100%"}
          leftSection={<IconCheck size={18} />}
          variant="light"
          onClick={handleCheck}
          loading={loading}
        >
          {lang.prm.captcha.handle}
        </Button>
      </Group>
    </Paper>
  );
};

export default CaptchaItem;
