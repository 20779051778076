import { useState } from "react";
import { resetPwd } from "../../../api/AuthAPI";
import { useLanguage } from "../../../stores/LanguageStore";
import { Button, PinInput, Stack, Text, Title } from "@mantine/core";
import PasswordField from "../../../components/View/PasswordField";
import { IconKey } from "@tabler/icons-react";

const PR2 = ({ handleNext }) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(false);
  const [errorCode, setErrorCode] = useState(false);
  const [pwdError, setPwdError] = useState(true);
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorCode(false);
    let formData = new FormData(e.target);
    const data = Object.fromEntries(formData);

    if (pwdError) {
      setLoading(false);
      return document.getElementById("new-pwd").focus();
    }

    resetPwd(data)
      .then((response) => {
        if (response.data.state) {
          handleNext();
        }
      })
      .catch((error) => {
        if (error.response.data.state_message === "incorrect_code") {
          setErrorCode(true);
          document.getElementById("forget-code").focus();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Stack component="form" onSubmit={handleSubmit} gap={"xs"}>
      <Title order={5}>{lang.password_recovery.step_edit.title}</Title>
      <Text mt={8}>{lang.password_recovery.step_edit.text}</Text>

      <PinInput
        name="code"
        id="forget-code"
        label={lang.password_recovery.step_edit.code}
        error={errorCode}
        helperText={errorCode && lang.errors.helper_texts.incorrectCode}
        length={6}
      />

      <PasswordField
        error={pwdError}
        setError={setPwdError}
        id={"new-pwd"}
        size={"md"}
        leftSection={<IconKey size={18} />}
      />

      <Button type="submit" loading={loading} mt={16}>
        {lang.password_recovery.step_edit.button_label}
      </Button>
    </Stack>
  );
};

export default PR2;
