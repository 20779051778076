import {
  Anchor,
  Button,
  Group,
  Stack,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { useLanguage } from "../../../stores/LanguageStore";
import { Link } from "react-router-dom";
import ColumnEditor from "./ColumnEditor";
import { useState } from "react";
import { useUIStore } from "../../../stores/UIStore";

function onDragStart(ev, id, hasFolder) {
  if (hasFolder) useUIStore.getState().setDragging(true);
  ev.dataTransfer.setData("text/plain", id);
}
function onDragStop(ev, id) {
  useUIStore.getState().setDragging(false);
}

const defaultData = {
  component: null,
  onClick: null,
  parentPath: null,
  field: "name",
  headerName: null,
  width: 200,
  toEdit: false,
  draggable: false,
  getDraggable: null,
  editFunction: null,
  getDisabled: null,
  inputProps: {},
  apiRef: null,
  withoutId: false,
};

export const NameEditor = ({
  editFunction,
  cell,
  field,
  headerName,
  inputProps,
  closePopover,
  apiRef,
}) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    let fd = new FormData(e.target);
    let data = Object.fromEntries(fd);

    setLoading(true);
    editFunction({
      id: cell.row.id,
      [field]: data[field],
    })
      .then((response) => {
        closePopover();
        apiRef.current?.updateRows([{ id: cell.row.id, [field]: data[field] }]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Stack component={"form"} onSubmit={handleSubmit} gap={"xs"}>
      <TextInput
        autoFocus
        name={field}
        label={headerName}
        defaultValue={cell.value}
        required
        {...inputProps}
      />

      <Group justify="end">
        <Button
          type="submit"
          loading={loading}
          size="compact-sm"
          color="tertiary.8"
        >
          {lang.contact_lists.edit.dialog.submit_button}
        </Button>
      </Group>
    </Stack>
  );
};

export const nameColumn = (data = defaultData) => {
  let lang = useLanguage.getState().language;
  return {
    field: data?.field || defaultData.field,
    headerName: data?.headerName || lang.table.default_columns.name,
    cellClassName: "hover-source",
    width: data?.width || defaultData.width,
    renderCell: (cell) => {
      if (data?.component) {
        return data?.component(cell);
      }
      if (data?.parentPath || data?.handleClick) {
        const parentPath =
          typeof data?.parentPath === "function"
            ? data?.parentPath?.(cell.row)
            : data?.parentPath;

        const editFunction = data?.editFunction?.(cell.row);

        return (
          <Group wrap="nowrap" w={"100%"}>
            {data?.leftSection?.(cell.row)}
            <Tooltip
              label={cell.row[data?.field || defaultData?.field]}
              openDelay={500}
              multiline
              maw={300}
              withArrow
            >
              <Anchor
                component={data?.disableLink ? undefined : Link}
                to={
                  parentPath +
                  `${data?.withoutSlash ? "" : "/"}${
                    data?.toEdit ? "edit" : ""
                  }${data?.withoutId ? "" : cell.row.id}`
                }
                truncate="end"
                draggable={data?.draggable}
                onDragStart={(e) =>
                  onDragStart(
                    e,
                    JSON.stringify(cell.row),
                    !!cell.row?.folder || cell.row?.pools?.length > 0
                  )
                }
                onDragStop={onDragStop}
                onDragEnd={onDragStop}
                fz={13}
                onClick={
                  data?.handleClick
                    ? (e) => data.handleClick(e, cell.row)
                    : undefined
                }
                c={"secondary.7"}
                fw={600}
              >
                {cell.row[data?.field || defaultData?.field]}
              </Anchor>
            </Tooltip>

            {data?.editFunction && (
              <ColumnEditor
                disabled={
                  data?.getDisabled
                    ? data?.getDisabled?.(cell.row)
                    : !cell.row?.write_permission
                }
                dropDownComponent={(closePopover) => (
                  <NameEditor
                    editFunction={editFunction}
                    field={data?.field || defaultData.field}
                    headerName={
                      data?.headerName || lang.table.default_columns.name
                    }
                    cell={cell}
                    closePopover={closePopover}
                    inputProps={data?.inputProps}
                    apiRef={data?.apiRef}
                  />
                )}
              />
            )}

            {data?.rightSection?.(cell.row)}
          </Group>
        );
      }
      if (data?.link) {
        return (
          <Anchor
            href={data?.link(cell.row)}
            fz={13}
            truncate="end"
            target="_blank"
          >
            {cell.row[data?.field || defaultData?.field]}
          </Anchor>
        );
      }
      return (
        <Text
          onClick={() => data?.onClick(cell.row)}
          fz={13}
          truncate="end"
          style={{ cursor: data?.onClick ? "pointer" : "default" }}
        >
          {cell.row[data?.field || defaultData?.field]}
        </Text>
      );
    },
  };
};
