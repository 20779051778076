import {
  ActionIcon,
  Button,
  Popover,
  Stack,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import { useLanguage } from "../../../stores/LanguageStore";
import { useState } from "react";
import { deleteFolder } from "../../../api/FolderAPI";
import { useUIStore } from "../../../stores/UIStore";
import { deletePool } from "../../../api/EmailAPI";

const DeleteFolder = ({ item, isPool, setTableLoading }) => {
  const lang = useLanguage((s) => s.language);
  const setAsideLoading = useUIStore((s) => s.setAsideLoading);

  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    setLoading(true);
    const main = isPool ? deletePool : deleteFolder;
    main({ id: item.id })
      .then(() => {
        setOpened(false);
        setTimeout(() => {
          setAsideLoading(true);
        }, 100);
        setTableLoading?.(true)
      })
      .finally(() => setLoading(false));
  };

  return (
    <Popover trapFocus withArrow opened={opened} onChange={setOpened}>
      <Popover.Target>
        <Tooltip
          label={lang.components.folder_navigator.deletion_title}
          withArrow
        >
          <ActionIcon
            size={"xs"}
            variant="white"
            onClick={(e) => {
              e.preventDefault();
              setOpened(true);
            }}
            className="hover-target"
            style={{
              opacity: opened ? 1 : undefined,
              boxShadow: "#00000033 0 1px 3px",
            }}
            color="red"
          >
            <IconTrash size={14} />
          </ActionIcon>
        </Tooltip>
      </Popover.Target>

      <Popover.Dropdown onClick={(e) => e.preventDefault()} p={"xs"}>
        <Text size="sm" fw={500}>
          {isPool
            ? lang.pools.deletion_title
            : lang.components.folder_navigator.deletion_title}
        </Text>
        <Button
          loading={loading}
          color="red"
          variant="light"
          onClick={handleSubmit}
          w={"100%"}
          mt={"xs"}
        >
          {lang.components.folder_navigator.delete_label}
        </Button>
      </Popover.Dropdown>
    </Popover>
  );
};

export default DeleteFolder;
