import {
  ActionIcon,
  Button,
  Flex,
  Indicator,
  LoadingOverlay,
  ScrollArea,
  Stack,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import { useEffect } from "react";
import { getFolders } from "../../api/FolderAPI";
import {
  IconArrowAutofitLeft,
  IconArrowsRandom,
  IconFolder,
  IconFolderCancel,
  IconRefresh,
} from "@tabler/icons-react";
import { useUIStore } from "../../stores/UIStore";
import FolderItem from "./folder/FolderItem";
import CreateFolder from "./folder/CreateFolder";
import { Link, useParams } from "react-router-dom";
import { useFolderStore } from "../../stores/FolderStore";
import { getPools } from "../../api/EmailAPI";
import { useAccountsStore } from "../../stores/ConfigStore";
import { useLanguage } from "../../stores/LanguageStore";

const AsideFolder = ({
  type,
  parentRoute,
  isInMain,
  editFunction,
  getEditFunction,
  apiRef,
  isPool,
  setTableLoading,
  actualFolderId,
  onFolderItemClick,
}) => {
  const lang = useLanguage((s) => s.language);
  const { folderId, poolId } = useParams();
  const folders = useFolderStore((s) =>
    s.folders.filter((x) => x.type === type)
  );
  const pools = useAccountsStore(
    (s) => s[type === "Email" ? "email_pools" : "linkedin_pools"]
  );
  const asideLoading = useUIStore((s) => s.asideLoading);
  const setAsideLoading = useUIStore((s) => s.setAsideLoading);
  const folderOpened = useUIStore((s) => s.folderOpened);
  const setFolderOpened = useUIStore((s) => s.setFolderOpened);
  const layoutIsVertical = useUIStore((s) => s.layoutIsVertical);

  const handleRefresh = () => {
    setAsideLoading(true);
  };

  const toggleFolderOpened = () => {
    setFolderOpened(!folderOpened);
  };

  useEffect(() => {
    if (asideLoading) {
      if (isPool) {
        getPools().finally(() => setAsideLoading(false));
      } else {
        getFolders().finally(() => setAsideLoading(false));
      }
    }
  }, [asideLoading, isPool, setAsideLoading, type]);

  return (
    <Stack
      pos={"relative"}
      h={"100%"}
      gap={0}
      w={!isInMain ? "100%" : folderOpened ? 225 : 45}
      p={isInMain ? "xs" : 0}
      style={{
        transition: "all ease .3s",
        // boxShadow: isInMain ? "#00000016 -2px 0px 5px inset" : "none",
        borderRadius: 0,
        borderTopRightRadius: isInMain ? 0 : 2,
        borderBottomRightRadius: isInMain ? 0 : 2,
        borderTopLeftRadius: 6,
        borderBottomLeftRadius: 6,
        borderRight: "1px solid #00000012",
      }}
    >
      {folderOpened && (
        <LoadingOverlay
          visible={asideLoading}
          zIndex={1000}
          overlayProps={{ blur: 2 }}
        />
      )}
      <Flex align={"center"} gap={8}>
        {folderOpened && (
          <>
            <Title order={6} mr={"auto"}>
              {isPool ? "Pools" : lang.components.folder_navigator.folders}
            </Title>

            <CreateFolder type={type} isPool={isPool} />

            <Tooltip label={lang.components.folder_navigator.refresh} withArrow>
              <ActionIcon onClick={handleRefresh} size={"sm"} variant="subtle">
                <IconRefresh size={16} />
              </ActionIcon>
            </Tooltip>
          </>
        )}
        {layoutIsVertical && (
          <Tooltip
            position={folderOpened ? "top" : "right"}
            label={lang.components.folder_navigator.toggle}
            withArrow
          >
            <ActionIcon
              size={"sm"}
              variant="subtle"
              onClick={toggleFolderOpened}
              style={
                !folderOpened && {
                  position: "absolute",
                  height: "100%",
                  top: 0,
                  left: 0,
                  width: "100%",
                  alignItems: "flex-start",
                  paddingTop: 10,
                  zIndex: 10,
                }
              }
            >
              <Indicator
                color="red"
                styles={{
                  indicator: {
                    padding: 0,
                    width: 10,
                    height: 9,
                    fontSize: 9,
                  },
                }}
                disabled={
                  !(isPool ? pools : folders).find(
                    (x) =>
                      x[isPool ? "number_of_accounts" : "number_of_items"] > 0
                  )
                }
              >
                {folderOpened ? (
                  <IconArrowAutofitLeft size={16} />
                ) : isPool ? (
                  <IconArrowsRandom size={18} />
                ) : (
                  <IconFolder size={18} />
                )}
              </Indicator>
            </ActionIcon>
          </Tooltip>
        )}
        {!folderOpened && (
          <Text
            style={{
              transform: "rotate(-90deg)",
              width: 50,
              position: "absolute",
              top: "calc(50% - 60px)",
              left: -3,
              zIndex: 0,
              whiteSpace: "nowrap",
            }}
            c={"dimmed"}
          >
            {isPool
              ? lang.components.folder_navigator.pool
              : lang.components.folder_navigator.folder}
          </Text>
        )}
      </Flex>
      {folderOpened && (
        <Stack
          mt={"xs"}
          gap={"xs"}
          h={0}
          mih={isInMain ? "calc(100% - 32px)" : "calc(100% - 64px)"}
        >
          <ScrollArea>
            <Text size="xs">
              {lang.components.folder_navigator.dnd1}{" "}
              {isPool ? "Pool" : "Dossier"}{" "}
              {lang.components.folder_navigator.dnd2}
            </Text>
            <Button
              variant={
                (isPool && poolId) || folderId || actualFolderId
                  ? "default"
                  : "light"
              }
              color="secondary"
              ta={"left"}
              justify="space-between"
              w={"100%"}
              styles={{ label: { width: "100%" }, section: { marginRight: 6 } }}
              px={8}
              py={4}
              leftSection={<IconFolderCancel size={16} />}
              component={onFolderItemClick ? undefined : Link}
              mt={"xs"}
              to={parentRoute}
              onClick={() => onFolderItemClick?.(null)}
            >
              {lang.components.folder_navigator.no}{" "}
              {isPool ? "Pool" : lang.components.folder_navigator.folders}
            </Button>
            {(isPool ? pools : folders).length > 0 ? (
              (isPool ? pools : folders)
                .sort((a, b) => {
                  return a.name.localeCompare(b.name, undefined, {
                    numeric: true, // Ensures numbers are compared numerically
                    ignorePunctuation: false, // Keeps special characters in order
                  });
                })
                .map((folder) => (
                  <FolderItem
                    key={folder.id}
                    item={{
                      ...folder,
                      url: `${parentRoute}/${isPool ? "pool" : "folder"}/${
                        folder.id
                      }`,
                    }}
                    isPool={isPool}
                    editFunction={editFunction}
                    getEditFunction={getEditFunction}
                    apiRef={apiRef}
                    setTableLoading={setTableLoading}
                    actualFolderId={actualFolderId}
                    onFolderItemClick={onFolderItemClick}
                  />
                ))
            ) : (
              <Text opacity={0.25} mt={"xs"} ta={"center"}>
                - {lang.components.folder_navigator.not_found} -
              </Text>
            )}
          </ScrollArea>
        </Stack>
      )}
    </Stack>
  );
};

export default AsideFolder;
