import { useEffect, useState } from "react";
import CreatableSelector from "./CreatableSelector";
import { useLanguage } from "../../stores/LanguageStore";
import { useListStore } from "../../stores/ListStore";
import { createList, getLists } from "../../api/ContactAPI";

const CreatableContactListSelector = ({
  value,
  setValue,
  required,
  noLabel,
  disabled,
  autoFocus,
  id,
  disableCreate,
  description,
  placeholder,
  noDesc,
  ...props
}) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(false);
  const data = useListStore((s) => s.lists);

  useEffect(() => {
    if (loading) {
      getLists().finally(() => setLoading(false));
    }
  }, [loading]);

  return (
    <CreatableSelector
      id={id}
      label={
        noLabel ? null : lang.contact_lists.content.copy_selection.dialog.list
      }
      placeholder={
        placeholder || lang.contact_lists.content.copy_selection.dialog.list
      }
      loading={loading}
      createLabel="Créer la liste :"
      description={
        noDesc
          ? null
          : description
          ? description
          : lang.contact_lists.to_other_list_text
      }
      data={data}
      createFunction={({ name }) => createList({ name })}
      value={value}
      setValue={setValue}
      onDropdownOpen={() => setLoading(true)}
      onNewValueCreated={() => setLoading(true)}
      created_field_id={"contact_list_id"}
      required={required}
      disabled={disabled}
      autoFocus={autoFocus}
      disableCreate={disableCreate}
      {...props}
    />
  );
};

export default CreatableContactListSelector;
