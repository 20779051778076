import {
  Combobox,
  Group,
  Highlight,
  InputBase,
  Loader,
  useCombobox,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconPlus } from "@tabler/icons-react";
import { useState } from "react";
import { useLanguage } from "../../stores/LanguageStore";

// Value:
const CreatableSelector = ({
  data,
  value,
  setValue,
  label,
  placeholder,
  leftSection,
  createFunction,
  createLabel,
  onDropdownOpen,
  onNewValueCreated,
  loading,
  created_field_id,
  required,
  disabled,
  autoFocus,
  id,
  description,
  disableCreate,
  withinPortal = false,
  ...props
}) => {
  const lang = useLanguage((s) => s.language);
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen,
  });

  const [search, setSearch] = useState(value?.name || "");

  const [creating, setCreating] = useState(false);
  const handleNewValue = () => {
    setCreating(true);

    createFunction({ name: search })
      .then((response) => {
        onNewValueCreated?.();
        notifications.show({
          message: lang.components.inputs.new_element_created,
          color: "tertiary.8",
        });
        setValue({ id: response.data[created_field_id], name: search });
        setSearch(search);
        combobox.closeDropdown();
      })
      .finally(() => setCreating(false));
  };

  const exactOptionMatch = data.some((item) => item.name === search);
  const filteredOptions = exactOptionMatch
    ? data
    : data.filter((item) =>
        `${item.name} #${item.id}`
          .toLowerCase()
          .includes(search.toLowerCase().trim())
      );

  const options = filteredOptions.map((item) => (
    <Combobox.Option
      value={item}
      key={item.id}
      bg={value?.id === item.id ? "var(--mantine-primary-color-0)" : undefined}
    >
      <Group justify="space-between">
        <Highlight highlight={search} size="sm">
          {`${item.name}`}
        </Highlight>

        <Highlight highlight={search} opacity={0.5} size="xs">
          {`#${item.id}`}
        </Highlight>
      </Group>
    </Combobox.Option>
  ));

  const handleClear = () => {
    setValue(null);
    setSearch("");
  };

  return (
    <Combobox
      store={combobox}
      withinPortal={withinPortal}
      shadow="sm"
      onOptionSubmit={(val) => {
        if (val === "$create") {
          handleNewValue();
        } else {
          setValue(val);
          setSearch(val.name);

          combobox.closeDropdown();
        }
      }}
    >
      <Combobox.Target>
        <InputBase
          id={id}
          description={description}
          value={search}
          onChange={(event) => {
            combobox.openDropdown();
            combobox.updateSelectedOptionIndex();
            setSearch(event.currentTarget.value);
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => {
            combobox.closeDropdown();
            setSearch(value?.name || "");
          }}
          label={label}
          placeholder={placeholder}
          leftSection={leftSection}
          rightSection={
            loading ? (
              <Loader size={"xs"} type="dots" />
            ) : value ? (
              <Combobox.ClearButton onClear={handleClear} />
            ) : (
              <Combobox.Chevron />
            )
          }
          rightSectionPointerEvents={value ? "all" : "none"}
          required={required}
          disabled={disabled}
          autoFocus={autoFocus}
          {...props}
        />
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options mah={128} style={{ overflowY: "auto" }}>
          {!disableCreate && (
            <Combobox.Option
              value="$create"
              component={Group}
              gap={6}
              disabled={creating || !search}
              wrap="nowrap"
            >
              {creating ? (
                <Loader size={14} type="dots" />
              ) : (
                <IconPlus size={14} />
              )}
              <span>
                {createLabel}{" "}
                <b> {search}</b>
              </span>
            </Combobox.Option>
          )}
          {options}
          {loading && (
            <Combobox.Option value="loading" component={Group} gap={6} disabled>
              <Loader size={14} type="dots" />
              <span>{lang.components.inputs.loading}</span>
            </Combobox.Option>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};

export default CreatableSelector;
