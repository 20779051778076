import { mainAxios as axios } from "./axios";
// Lists
export const getUnsubInOrg = async ({ id, per_page, sort, filter, query }) => {
  return axios.get(
    `/unsubscribers/organization/${id}`,
    {
      query: query,
    },
    {
      params: {
        options: JSON.stringify({
          per_page,
          filter,
          sort,
        }),
      },
    }
  );
};

export const searchAllUnsub = async ({ per_page, sort, filter, query }) => {
  return axios.post(
    `/unsubscribers/search`,
    {
      query: query,
    },
    {
      params: {
        options: JSON.stringify({
          per_page,
          filter,
          sort,
        }),
      },
    }
  );
};

export const getFirstPageUnsub = async ({ per_page, sort, filter }) => {
  return axios.get(`/unsubscribers`, {
    params: {
      options: JSON.stringify({
        per_page,
        filter,
        sort,
      }),
    },
  });
};

export const deleteUnsub = async ({ id }) => {
  return axios.delete(`/unsubscribers/${id}`);
};

export const importUnsub = async (org_id, data) => {
  return axios.post(`/unsubscribers/`, {
    organization_id: org_id,
    data,
  });
};

export const getFirstPageUnsubAdmin = async ({ per_page, sort, filter }) => {
  return axios.get(`/admin/unsubscribers`, {
    params: {
      options: JSON.stringify({
        per_page,
        filter,
        sort,
      }),
    },
  });
};

export const importGlobalUnsubs = async (org_id, data) => {
  return axios.post(`/admin/unsubscribers`, {
    organization_id: org_id,
    data,
  });
};

export const downloadUnsubscribers = async ({
  file_type = "csv",
  name,
  filter,
  ids,
  excluded_ids,
  export_type,
}) => {
  let org = filter?.values?.find((x) => x.field_name === "organization_id");
  if (export_type === "filter" && org) {
    return axios
      .get(
        `/unsubscribers/organization/${org?.value}/download/${file_type}`,
        // {
        //   filter,
        //   data_ids: ids,
        //   excluded_data_ids: excluded_ids,
        // },
        {
          responseType: "blob",
        }
      )
      .then((res) => {
        const url = window.URL.createObjectURL(res.data);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${name}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      });
  }
  return axios
    .get(
      `/unsubscribers/download/${file_type}`,
      // {
      //   filter,
      //   data_ids: ids,
      //   excluded_data_ids: excluded_ids,
      // },
      {
        responseType: "blob",
      }
    )
    .then((res) => {
      const url = window.URL.createObjectURL(res.data);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${name}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
};
