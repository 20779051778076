import { Group, Skeleton, Stack, Text, Tooltip } from "@mantine/core";
import { useLeadsSheetStore } from "../../../../../../stores/LeadsStore";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getFirstPagePrm,
  getFirstPagePrmUser,
} from "../../../../../../api/PRMAPI";
import { IconBuildingCommunity } from "@tabler/icons-react";
import { useConfigStore } from "../../../../../../stores/ConfigStore";
import { useLanguage } from "../../../../../../stores/LanguageStore";
import SameProspect from "./SameProspect";
import { parseProspectDatafields } from "../../../utils";

const SameOrg = () => {
  const { userId } = useParams();
  const opened = useLeadsSheetStore((s) => s.openedOrg);
  const current = useLeadsSheetStore((s) => s.current);
  const setOrgProspected = useLeadsSheetStore((s) => s.setOrgProspected);
  const [loading, setLoading] = useState(false);
  const [sameProspects, setSameProspects] = useState([]);
  const lang = useLanguage((s) => s.language);

  const company = useMemo(() => {
    return current.properties.find(
      (x) => x.identifier_placeholder === "%company%"
    )?.value;
  }, [current.properties]);
  const siren = useMemo(() => {
    return current.properties.find(
      (x) => x.identifier_placeholder === "%siren%"
    )?.value;
  }, [current.properties]);

  useEffect(() => {
    if (opened) {
      setLoading(true);
      let func = userId ? getFirstPagePrmUser : getFirstPagePrm;
      const datafields = useConfigStore.getState().datafields;

      let companyId = datafields?.find(
        (x) => x.identifier_placeholder === "%company%"
      ).id;
      let sirenId = datafields?.find(
        (x) => x.identifier_placeholder === "%siren%"
      ).id;

      let filterValues = [];
      if (company) {
        filterValues.push({
          field_name: companyId.toString(),
          type: "equals",
          value: company,
        });
      }

      if (siren) {
        filterValues.push({
          field_name: sirenId.toString(),
          type: "equals",
          value: siren.toString(),
        });
      }

      func({
        id: parseInt(userId),
        per_page: 100,
        filter: {
          mode: "or",
          values: filterValues,
        },
      })
        .then((response) => {
          let results = response.data.results;
          setSameProspects(results.map((x) => parseProspectDatafields(x)));

          if (
            results?.find((x) => x.person_in_charge && x.id !== current?.id)
          ) {
            setOrgProspected(true);
          } else setOrgProspected(false);
        })
        .finally(() => setLoading(false));
    }
  }, [
    opened,
    userId,
    setOrgProspected,
    current?.id,
    current.properties,
    company,
    siren,
  ]);

  return (
    <Stack
      w={opened ? "20%" : 0}
      gap={0}
      h={"100%"}
      py={opened ? "sm" : 0}
      px={opened ? "sm" : 0}
      style={{ borderRight: "1px solid #00000016", transition: "all ease .3s" }}
    >
      {loading || !opened ? (
        <Stack h={"100%"} gap="xs" w={"100%"}>
          <Skeleton height={65} w={"100%"} />
          <Skeleton height={"100%"} w={"100%"} />
        </Stack>
      ) : (
        <Stack gap={"xs"} w={"100%"} h={"100%"}>
          <Group wrap="nowrap" w={"100%"} gap={"xs"}>
            <IconBuildingCommunity size={18} />
            <Stack flex={"1 1 auto"} w={0} gap={0}>
              <Tooltip label={company} disabled={!company}>
                <Text truncate="end" size="sm">
                  {company || " - "}
                </Text>
              </Tooltip>
              <Text size="xs" c={"dimmed"}>
                #{siren || " - "}
              </Text>
            </Stack>
          </Group>
          <Text c={"dimmed"} size="sm">
            {lang.prm.details.in_same_org}
          </Text>
          <Stack flex={1} style={{ overflow: "auto" }} w={"100%"} gap={"xs"}>
            {sameProspects.length > 0 ? (
              sameProspects.map((item) => (
                <SameProspect key={item.id} item={item} />
              ))
            ) : (
              <Text c={"dimmed"}> Aucun prospect trouvé </Text>
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default SameOrg;
