import { useLoaderData } from "react-router-dom";
import EditMassMailing from "./massmailing/EditMassMailing";
import { Button, Group } from "@mantine/core";
import CreateEmailSender from "./CreateEmailSender";
import EmailSmtp from "./smtp/EmailSmtp";
import PageTitle from "../../../../components/View/PageTitle";
import { IconDeviceFloppy } from "@tabler/icons-react";
import { useLanguage } from "../../../../stores/LanguageStore";

const EmailEditPage = () => {
  const lang = useLanguage((s) => s.language);
  const emailProfile = useLoaderData();

  const getComponent = () => {
    if (emailProfile?.account_type === "MassMailing") {
      return <EditMassMailing emailProfile={emailProfile} />;
    } else {
      return <EmailSmtp emailProfile={emailProfile} />;
    }
  };

  return (
    <>
      <Group className="layout-block top" w={"100%"} justify="space-between">
        <PageTitle title={emailProfile?.name} />

        <CreateEmailSender inEdit />

        <Button
          type="submit"
          color="primary"
          form="edit-form"
          leftSection={<IconDeviceFloppy size={18} />}
        >
          {lang.model_emails.edit.dialog.submit_button}
        </Button>
      </Group>

      {getComponent()}
    </>
  );
};

export default EmailEditPage;
