import { create } from "zustand"
import { persist } from "zustand/middleware"

const sessionStore = (set, get) => ({
  session: null,
  setSession: (tokens) => set({ session: tokens }),

  user_switch: null,
  setUserSwitch: (newSwitch) => set({ user_switch: newSwitch }),

  usersOptions: [],
  setUsersOptions: (newUsers) => set({ usersOptions: newUsers }),

  api_url: "",
  setApiUrl: (newUrl) => set({ api_url: newUrl }),

  app_url: "",
  setAppUrl: (newUrl) => set({ app_url: newUrl }),

  reseller: null,
  setReseller: (newUrl) => set({ reseller: newUrl }),
})

const tempSessionStore = (set, get) => ({
  isReloaded: true,
  setIsReloaded: (newData) => set({ isReloaded: newData }),
})

const profileStore = (set) => ({
  profile: null,
  setProfile: (newProfile) => set({ profile: newProfile }),

  unswitchedProfile: null,
  setUnswitchedProfile: (newProfile) => set({ unswitchedProfile: newProfile }),

  openedPrmSidebar: true,
  setOpenedPrmSidebar: (newData) => set({ openedPrmSidebar: newData }),
  openedSidebar: true,
  setOpenedSidebar: (newData) => set({ openedSidebar: newData }),
  openedWorkflowSidebar: true,
  setOpenedWorkflowSidebar: (newData) => set({ openedWorkflowSidebar: newData }),
  openedFooter: true,
  setOpenedFooter: (newData) => set({ openedFooter: newData }),
  drawerExpanded: [0, 1],
  setDrawerExpanded: (newData) => set({ drawerExpanded: newData }),

  needTour: false,
  setNeedTour: (newData) => set({ needTour: newData }),

  needTourEmails: false,
  setNeedTourEmails: (newData) => set({ needTourEmails: newData }),

  needWorkflowTour: false,
  setNeedWorkflowTour: (newData) => set({ needWorkflowTour: newData }),

  needTableTour: false,
  setNeedTableTour: (newData) => set({ needTableTour: newData }),

  needCreationTour: false,
  setNeedCreationTour: (newData) => set({ needCreationTour: newData }),

  setupManagerOpened: false,
  setSetupManagerOpened: (newData) => set({ setupManagerOpened: newData }),
})

export const useSessionStore = create(persist(sessionStore, { name: "session" }))
export const useProfileStore = create(persist(profileStore, { name: "profile" }))
export const useTempSessionStore = create(tempSessionStore)