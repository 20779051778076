import { useCallback, useEffect, useState } from "react";
import { useLeadsContainerStore } from "../../../../../stores/LeadsStore";
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  editStatus,
  getStatusCustom,
  getStatusSystem,
} from "../../../../../api/PRMAPI";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { Button, Group, LoadingOverlay, Stack } from "@mantine/core";
import { DndContext, DragOverlay } from "@dnd-kit/core";
import StatusItem from "./StatusItem";
import { createPortal } from "react-dom";
import { IconCircleCheck } from "@tabler/icons-react";
import CreateStatus from "./CreateStatus";
import { notifications } from "@mantine/notifications";

function handleSystemStatuses(systemStatus) {
  const lang = useLanguage.getState().language;

  let map = {
    opener: lang.components.status_selector.statuses.opener,
    clicker: lang.components.status_selector.statuses.clicker,
    answerer: lang.components.status_selector.statuses.answerer,
    to_call: lang.components.status_selector.statuses.to_call,
  };

  let result = systemStatus?.map((status) => ({
    ...status,
    name: map[status.status],
    id: status.status,
  }));

  return systemStatus ? result : [];
}

const StatusContent = ({ handleClose }) => {
  const lang = useLanguage((s) => s.language);
  const containers = useLeadsContainerStore((s) => s.containers);
  const setContainers = useLeadsContainerStore((s) => s.setContainers);
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([...containers]);
  const [activeDrag, setActiveDrag] = useState(null);

  function handleDragStart(event) {
    setActiveDrag(items.find((x) => x.id === event.active.id));
  }

  function handleDragEnd(event) {
    const { active, over } = event;

    if (over) {
      if (active.id !== over.id) {
        setItems((items) => {
          const oldIndex = items.findIndex((x) => x.id === active.id);
          const newIndex = items.findIndex((x) => x.id === over.id);

          editStatus({
            id: active.id,
            sorting: newIndex + (oldIndex < newIndex ? 1 : 0),
            isSystem: !parseInt(active?.id),
          }).then(() => {
            notifications.show({
              message: lang.prm.status.edited,
              color: "tertiary.8",
            });
          });

          return arrayMove(items, oldIndex, newIndex);
        });
      }
    }
    setActiveDrag(null);
  }

  const handleDone = useCallback(() => {
    let diff = [];
    items
      .filter((x) => x.visible)
      .forEach((item) => {
        if (
          containers.filter((x) => !x.visible).find((x) => x.id === item.id) || // If status is hidden before
          !containers.find((x) => x.id === item.id) // If status didn't exist before
        ) {
          diff.push(item);
        }
      });
    setContainers(
      items.map((x) => ({
        ...x,
        loading: !!diff.find((d) => d.id === x.id),
      }))
    );
    handleClose();
  }, [setContainers, items, handleClose, containers]);

  const handleNewStatus = (id, name) => {
    setItems((prev) => [...prev, { name, id, visible: true }]);
  };

  useEffect(() => {
    if (loading) {
      getStatusSystem().then((system_data) => {
        getStatusCustom()
          .then((response) => {
            let customStatuses = response.data.status;
            const newItems = [
              ...handleSystemStatuses(system_data.data.status),
              ...customStatuses,
            ].map((x) => {
              let sameContainer = containers.find((con) => con.id === x.id);
              return {
                ...(sameContainer || {}),
                ...x,
              };
            });
            setItems(newItems.sort((a, b) => a?.sorting - b?.sorting));
          })
          .finally(() => {
            setLoading(false);
          });
      });
    }
  }, [loading, containers]);

  return (
    <>
      <Stack pos={"relative"} gap={"xs"}>
        <LoadingOverlay visible={loading} />
        <DndContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
          <SortableContext items={items} strategy={verticalListSortingStrategy}>
            {items.map((item, index) => {
              return (
                <StatusItem
                  key={item.id}
                  id={item.id}
                  visible={item?.visible}
                  name={item.name}
                  color={item.color}
                  deletable={!item.status}
                  isCustomDefault={item.type_default_status}
                  setItems={setItems}
                  statuses={items}
                  stat={item.total}
                />
              );
            })}
          </SortableContext>
          {createPortal(
            <DragOverlay>
              {activeDrag ? (
                <StatusItem
                  name={activeDrag.name}
                  visible={activeDrag?.visible}
                  color={activeDrag.color}
                  stat={activeDrag?.total || 0}
                  placeholder={activeDrag.name}
                  isOverlay
                />
              ) : null}
            </DragOverlay>,
            document.body
          )}
        </DndContext>
      </Stack>

      <Group justify="end" mt={"md"}>
        <CreateStatus handleDone={handleNewStatus} />

        <Button
          onClick={handleDone}
          leftSection={<IconCircleCheck size={22} />}
          color="tertiary.8"
        >
          {lang.prm.status.apply_label}
        </Button>
      </Group>
    </>
  );
};

export default StatusContent;
