import {
  Button,
  Flex,
  Group,
  LoadingOverlay,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import TextEditor from "../../../../components/ExternalEditors/TextEditor";
import { useEffect, useMemo, useRef, useState } from "react";
import { useConfigStore } from "../../../../stores/ConfigStore";
import { getFields } from "../../../../api/ContactAPI";
import { useLanguage } from "../../../../stores/LanguageStore";
import {
  editLinkedinInvitation,
  editLinkedinMessage,
  editSMS,
  findLinkedinInvitation,
  findLinkedinMessage,
  findSMS,
  getLinkedinInvitations,
  getLinkedinMessages,
  getSMS,
} from "../../../../api/ModelsApi";
import { notifications } from "@mantine/notifications";
import MessageModalPreview from "../../../../components/View/MessageModalPreview";
import useNavigationBlocker from "../../../../components/View/NavigationBlocker";
import BackButton from "../../../../components/View/BackButton";

const EditModelLinkedin = ({
  handleClose,
  type,
  onEdited,
  model,
  maxChars = 280,
}) => {
  const editorRef = useRef();
  const lang = useLanguage((s) => s.language);
  const datafields = useConfigStore((s) => s.datafields);
  const [loading, setLoading] = useState(model?.text ? false : true);
  const [dirty, setDirty] = useState(false);
  const datafieldTokens = useMemo(() => {
    let df = datafields.map((x) => ({
      label: x.name,
      value: x.identifier_placeholder,
    }));
    df.push(
      {
        label:
          lang.components.variable_manager.dialog.unsubscribe_variable_name,
        value: "%unsubscribe%",
        name: lang.components.variable_manager.dialog.unsubscribe_variable_name,
      },
      {
        label: lang.components.variable_manager.dialog.webversion_variable_name,
        value: "%webversion%",
        name: lang.components.variable_manager.dialog.webversion_variable_name,
      }
    );
    return df;
  }, [datafields, lang]);

  const [modelProfile, setModelProfile] = useState(model);

  useEffect(() => {
    getFields();

    if (!model?.text) {
      setLoading(true);

      let func;
      if (type === "linkedin_message") {
        func = findLinkedinMessage;
      }
      if (type === "linkedin_invitation") {
        func = findLinkedinInvitation;
      }
      if (type === "sms") {
        func = findSMS;
      }

      func(model?.id)
        .then((response) => {
          setModelProfile(response.data.model_profile);
          editorRef.current?.setContent(
            response.data.model_profile?.text?.replaceAll("\n", "<br />")
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [model, type]);

  const handlePreview = ({ setText, setSender, open }) => {
    const fd = new FormData(document.getElementById("model-form"));
    const data = Object.fromEntries(fd);

    setText(editorRef.current.getContent({ format: "text" }));
    setSender(data?.sender);
    open();
  };

  const [submitting, setSubmitting] = useState(false);
  const onSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const fd = new FormData(e.target);
    const data = Object.fromEntries(fd);

    let text = editorRef.current.getContent({ format: "text" });

    let func;
    if (type === "linkedin_message") {
      func = editLinkedinMessage;
    }
    if (type === "linkedin_invitation") {
      func = editLinkedinInvitation;
    }
    if (type === "sms") {
      func = editSMS;
    }
    return func({
      id: model?.id,
      name: data.name,
      text,
      sender: data?.sender,
    })
      .then((response) => {
        let newId = response.data.model_id;
        setSubmitting(true);

        let funcGetter;
        if (type === "linkedin_message") {
          funcGetter = getLinkedinMessages;
        }
        if (type === "linkedin_invitation") {
          funcGetter = getLinkedinInvitations;
        }
        if (type === "sms") {
          funcGetter = getSMS;
        }

        funcGetter()
          .then((res) => {
            let newItemsList = res.data.models_list;
            let newItem = newItemsList.find((x) => x.id === newId);
            notifications.show({
              title: lang.models.edit_success,
              message: lang.models.edit_success_text,
              color: "tertiary.8",
            });
            setDirty(false);

            setTimeout(() => {
              if (onEdited) {
                onEdited(newItem);
              } else handleClose?.();
            }, 50);
          })
          .finally(() => {
            setSubmitting(false);
          });
      })
      .catch(() => setSubmitting(false));
  };

  useNavigationBlocker(dirty);

  return (
    <>
      <LoadingOverlay
        visible={loading || submitting}
        overlayProps={{ blur: 1 }}
        zIndex={1001}
      />

      <Stack
        component={"form"}
        onSubmit={onSubmit}
        gap={0}
        id="model-form"
        flex={1}
      >
        <Group wrap="nowrap" mb={"sm"}>
          <TextInput
            key={modelProfile?.id}
            label={lang.model_emails.create.dialog.name}
            placeholder={lang.model_emails.create.dialog.name}
            defaultValue={modelProfile?.name}
            name="name"
            w="50%"
            required
            data-autoFocus
            autoFocus
            onChange={() => setDirty(true)}
          />
          {type === "sms" && (
            <TextInput
              key={modelProfile?.id}
              label={lang.components.email_preview.sender}
              placeholder={lang.components.email_preview.sender}
              name="sender"
              defaultValue={modelProfile?.sender}
              w="50%"
              required
              maxLength={11}
              minLength={4}
            />
          )}
        </Group>

        {loading ? (
          <Stack h={300}></Stack>
        ) : (
          <TextEditor
            editorRef={editorRef}
            placeholder={lang.model_emails.create.dialog.placeholder}
            tokens={datafieldTokens}
            // noTop
            toolbar={"undo redo | tokens |"}
            onKeyUp={() => setDirty(true)}
            maxChars={maxChars}
            defaultText={modelProfile?.text}
            onInit={(editor) =>
              editor.setContent(modelProfile?.text?.replaceAll("\n", "<br />"))
            }
          >
            {/* <Group
              wrap="nowrap"
              style={{
                position: "absolute",
                top: 7,
                right: 7,
                zIndex: 4,
              }}
            >
              <Generator disabled />
            </Group> */}
          </TextEditor>
        )}

        {type === "linkedin_invitation" && (
          <Text mt={"xs"} c={"red"} size="sm">
            {lang.model_invitation_linkedin.create.dialog.info_limit}
          </Text>
        )}

        <Group justify="space-between" mt={"sm"}>
          <Flex>
            <MessageModalPreview getData={handlePreview} type={type} />
          </Flex>
          <Flex gap={"md"}>
            <BackButton onClick={handleClose} label={lang.global.back} />
            <Button type="submit">
              {lang.model_emails.edit.dialog.submit_button}
            </Button>
          </Flex>
        </Group>
      </Stack>
    </>
  );
};

export default EditModelLinkedin;
