import { useConfigStore } from "../stores/ConfigStore";
import { useFlowStore } from "../stores/WorkflowStore";
import { mainAxios as axios } from "./axios";

export const getWorkflows = async () => {
  let response = await axios.get(`/workflows`);

  if (response.data.state) {
    let newLists = response.data.workflows.sort((a, b) => b.id - a.id);
    useFlowStore.getState().setWorkflows(newLists);
    return response;
  }
};

export const createWorkflow = async ({
  name,
  steps,
  sharing,
  tags_ids,
  folder_id,
  auto_remove_responders,
}) => {
  return axios.post(`/workflows`, {
    name: name,
    steps: steps,
    tags_ids: tags_ids,
    folder_id: folder_id,
    sharing: sharing,
    auto_remove_responders,
  });
};

export const editWorkflow = async ({
  id,
  name,
  steps,
  sharing,
  tags_ids,
  folder_id,
  archived,
  auto_remove_responders,
}) => {
  return axios.put(`/workflows/${id}`, {
    name: name,
    steps: steps,
    tags_ids: tags_ids,
    folder_id: folder_id,
    sharing: sharing,
    archived: archived,
    auto_remove_responders,
  });
};

export const findWorkflow = async (id) => {
  return axios.get(`/workflows/${id}`);
};

export const deleteWorkflow = async ({ id }) => {
  return axios.delete(`/workflows/${id}`);
};

export const copyWorkflow = async ({ id }) => {
  return axios.post(`/workflows/${id}/copy`);
};

export const programWorkflow = async ({ workflow_id, data }) => {
  return axios.post(`/workflows/${workflow_id}/program`, { ...data });
};

export const editProgrammation = async (
  workflow_id,
  workflow_programmation_id,
  data
) => {
  return axios.put(
    `/workflows/${workflow_id}/programmation/${workflow_programmation_id}`,
    { ...data }
  );
};

export const findProgrammation = async (
  workflow_id,
  workflow_programmation_id
) => {
  return axios.get(
    `/workflows/${workflow_id}/programmation/${workflow_programmation_id}`
  );
};

export const getProgrammations = async (data) => {
  const response = await axios.get("/workflows/programmations", {
    params: {
      options: JSON.stringify(data),
    },
  });
  if (response.data) {
    useConfigStore.getState().setProgrammations(response.data.results);
  }
  return response;
};

export const getWorkflowProgrammations = async (workflow_id) => {
  return axios.get(`/workflows/${workflow_id}/programmations`);
};

export const sendEmail = async ({
  email_account_id,
  recipients,
  subject,
  html,
  text,
  email_signature_id,
  contact_list_id,
  contact_id,
  contact_id_prm,
  reply_id,
}) => {
  return axios.post(`/workflows/send/email`, {
    email_account_id,
    recipients,
    subject,
    html,
    text,
    email_signature_id,
    contact_list_id,
    contact_id,
    contact_id_prm,
    reply_id,
  });
};
