import { Box, Paper, Stack } from "@mantine/core";
import ContainerHeader from "./header/ContainerHeader";
import ContainerBody from "./ContainerBody";
import {
  useLeadsContainerStore,
  useLeadsFrontStore,
  useLeadsStore,
} from "../../../../stores/LeadsStore";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  findProspect,
  getFirstPagePrm,
  getFirstPagePrmShared,
  getFirstPagePrmUser,
  updateProspect,
} from "../../../../api/PRMAPI";
import DragOverlay from "./body/DragOverlay";
import { notifications } from "@mantine/notifications";
import { useLanguage } from "../../../../stores/LanguageStore";
import { parseProspectDatafields } from "../utils";
import { Resizable } from "react-resizable";

const defaultPerPage = 20;

const Container = ({ container }) => {
  const lang = useLanguage((s) => s.language);
  const { userId } = useParams();
  const filter = useLeadsStore((s) => s.filter);
  const query = useLeadsStore((s) => s.query);
  const sharedConditions = useLeadsStore((s) => s.sharedConditions);
  const updateContainer = useLeadsContainerStore((s) => s.updateContainer);
  const updateContainerItem = useLeadsContainerStore(
    (s) => s.updateContainerItem
  );
  const containerFilter = useMemo(() => {
    if (container?.status) {
      if (query) {
        return {
          mode: "and",
          values: [
            {
              field_name: "status",
              type: "equals",
              value: container?.status,
            },
            {
              field_name: "custom_status",
              type: "equals",
              value: "null",
            },
            {
              field_name: "any_datafield",
              type: "contains",
              value: query,
            },
          ],
        };
      }
      return {
        mode: "and",
        values: [
          {
            field_name: "status",
            type: "equals",
            value: container?.status,
          },
          {
            field_name: "custom_status",
            type: "equals",
            value: "null",
          },
        ],
      };
    }
    if (query) {
      return {
        mode: "and",
        values: [
          {
            field_name: container.id === "to_call" ? "status" : "custom_status",
            type: "equals",
            value: container.id.toString(),
          },
          {
            field_name: "any_datafield",
            type: "contains",
            value: query,
          },
        ],
      };
    }
    return {
      mode: "and",
      values: [
        {
          field_name: container.id === "to_call" ? "status" : "custom_status",
          type: "equals",
          value: container.id.toString(),
        },
      ],
    };
  }, [container.status, container.id, query]);

  const fetchInitial = useCallback(() => {
    updateContainer(container?.id || container?.status, { loaded: false });
    const getFunction = userId
      ? getFirstPagePrmUser
      : sharedConditions
      ? getFirstPagePrmShared
      : getFirstPagePrm;

    let mainFilter = {};

    // Filter
    if (filter?.values?.length > 0) {
      mainFilter = {
        ...containerFilter,
        values: [...containerFilter.values, filter],
      };
    } else {
      mainFilter = containerFilter;
    }

    getFunction({
      id: userId,
      per_page: defaultPerPage,
      sort: container?.sort || {
        field_name:
          container?.status === "to_call" ? "last_call" : "status_changed_date",
        sort_direction: "desc",
      },
      filter: mainFilter,
      conditions: sharedConditions?.map((x) => {
        return {
          ...x,
          ids_list:
            x?.ids_list?.map((x) => x.id) ||
            x?.sharing_targets?.map((x) => x.id),
        };
      }),
    })
      .then((response) => {
        updateContainer(container?.id || container?.status, {
          loading: false,
          loaded: true,
          nextPageUrl: response.data?.next_page,
          total: response.data.number_of_results,
          items: response.data.results.map((item) =>
            parseProspectDatafields(item)
          ),
        });
      })
      .catch((error) => {
        updateContainer(container?.id || container?.status, {
          loading: false,
          loaded: true,
        });
      });
  }, [
    updateContainer,
    container?.id,
    container?.status,
    container?.sort,
    userId,
    sharedConditions,
    filter,
    containerFilter,
  ]);

  //#region DnD
  const onDragOver = useCallback(
    (event) => {
      if (container?.status && container?.status !== "to_call") {
        return;
      }
      event.preventDefault();
      event.dataTransfer.dropEffect = "move";
    },
    [container?.status]
  );

  const onDrop = (event) => {
    let newItem = JSON.parse(event.dataTransfer.getData("application/prm"));
    newItem.new = true;
    let target = event.target.closest(".prm-container");
    if (target) {
      const dropContainer = target.getAttribute("data-id");

      if (
        (container?.status && container?.status !== "to_call") ||
        newItem?.custom_status === container.id
      ) {
        return;
      } else {
        let newItems = [...container.items];
        newItems.unshift({
          ...newItem,
          custom_status: container.id,
          status_changed_date: new Date(),
        });
        updateContainer(container.id, {
          total: container.total + 1,
          items: newItems,
          needCacheReset: true,
        });

        updateProspect({
          id: newItem.id,
          custom_status: container.id === "to_call" ? null : container.id,
          status: container.id === "to_call" ? "to_call" : null,
        })
          .then((response) => {
            findProspect(newItem.id).then((res) => {
              notifications.show({
                message: lang.prm.prospect_moved,
                color: "tertiary.8",
              });
              updateContainerItem(
                container.status === "to_call"
                  ? "to_call"
                  : parseInt(dropContainer),
                newItem.id,
                {
                  ...parseProspectDatafields(res.data.contact_profile),
                  new: false,
                }
              );
            });
          })
          .catch((err) => {
            newItem.new = false;
            // Remove from this container
            let newItems = [...container.items].filter(
              (x) => x.id !== newItem.id
            );
            updateContainer(container.id, {
              total: container.total - 1,
              items: newItems,
              needCacheReset: true,
            });

            // Redo to the previous container
            const previousContainer = useLeadsContainerStore
              .getState()
              .containers.find(
                (x) => x.id === newItem.custom_status || x.id === newItem.status
              );
            let newItemsPrevious = [...previousContainer.items];
            newItemsPrevious.unshift(newItem);
            updateContainer(previousContainer.id, {
              total: previousContainer.total + 1,
              items: newItemsPrevious,
              needCacheReset: true,
            });
          });
      }

      // To remove the prospect from where it was
      document.getElementById("main-prm").classList.add("dropped");
    }

    document.querySelectorAll(".container-overlay").forEach((element) => {
      element.style.display = "none";
    });
    document.querySelectorAll(".main-overlay").forEach((element) => {
      element.style.background = "none";
    });
  };
  //#endregion

  useEffect(() => {
    if (container.loading) {
      fetchInitial();
    }
  }, [container.loading]);

  const [actualSize, setActualSize] = useState(container?.size || 250);
  const handleResize = (e, { node, size, handle }) => {
    setActualSize(size.width);
  };
  const handleResizeStop = (e, { node, size, handle }) => {
    const setSizes = useLeadsFrontStore.getState().setSizes;
    const sizes = useLeadsFrontStore.getState().sizes;
    setSizes({ ...sizes, [container.id]: size.width });
  };

  return (
    <Resizable
      minConstraints={[200, "100%"]}
      width={actualSize}
      onResize={handleResize}
      onResizeStop={handleResizeStop}
      handle={<Box className="column-resizer" />}
    >
      <Paper
        component={Stack}
        withBorder
        h={"100%"}
        w={actualSize}
        pos={"relative"}
        bg={"#00000006"}
        gap={0}
        className="prm-container"
        onDrop={onDrop}
        onDragOver={onDragOver}
        data-id={container.id}
      >
        <DragOverlay
          container={container}
          disabled={!!container.status && container.status !== "to_call"}
        />
        <ContainerHeader container={container} />

        <Stack flex={"1 1 auto"}>
          <ContainerBody container={container} />
        </Stack>
      </Paper>
    </Resizable>
  );
};

export default Container;
