import { Text, Tooltip } from "@mantine/core";
import { parseDate } from "../../../functions/dates";
import { useLanguage } from "../../../stores/LanguageStore";

const dateColumn = (
  field = "created_on",
  headerName,
  preferEmpty,
  others,
  emptyCondition
) => {
  return {
    field: field,
    headerName:
      headerName ||
      useLanguage.getState().language.table.default_columns.created_on_name,
    width: 150,
    filterable: false,
    id: field,
    valueGetter: (params) => {
      return params?.value || "";
    },
    renderCell: (cell) => {
      if (emptyCondition) {
        if (emptyCondition(cell.row)) {
          return "-";
        }
      }
      if (cell.value)
        return (
          <Tooltip
            label={new Date(cell.row[field]).toLocaleString()}
            withArrow
            openDelay={500}
          >
            <Text fz={13} truncate="end">
              {parseDate(cell.value)}
            </Text>
          </Tooltip>
        );
      else return preferEmpty ? "" : "-";
    },
    ...others,
  };
};

export default dateColumn;
