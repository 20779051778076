import { useMemo } from "react";
import {
  useLeadsSheetStore,
  useLeadsStore,
} from "../../../../../../stores/LeadsStore";
import { Avatar, Divider, Group, Stack, Text, Tooltip } from "@mantine/core";
import LeadLeftBio from "./LeadLeftBio";
import LeftSheetBottom from "./LeftSheetBottom";
import { getFieldIcon } from "../../../../community/util";

const defaultFieldsSorting = [
  "%company%",
  "%civility%",
  "%first_name%",
  "%last_name%",
  "%job_title%",
  "%email%",
  "%phone%",
  "%linkedin_url%",
  "%address%",
  "%zip_code%",
  "%city%",
  "%company_size%",
  "%gmaps_link%",
  "%activity%",
  "%website%",
  "%siret%",
  "%siren%",
];

const handleSort = (a, b) => {
  const indexA = defaultFieldsSorting.indexOf(a.identifier_placeholder);
  const indexB = defaultFieldsSorting.indexOf(b.identifier_placeholder);

  // If both names are in orderArray
  if (indexA !== -1 && indexB !== -1) {
    return indexA - indexB;
  }

  // If only a.name is in orderArray
  if (indexA !== -1) {
    return -1;
  }

  // If only b.name is in orderArray
  if (indexB !== -1) {
    return 1;
  }

  // If neither name is in orderArray, maintain original order
  return 0;
};

const LeftSheet = () => {
  const leadsField = useLeadsStore((s) => s.fields);
  const current = useLeadsSheetStore((s) => s.current);

  const fields = useMemo(() => {
    if (!current) {
      return { defaultFields: [], customFields: [] };
    }

    let defaultResults = leadsField.filter((x) => x.created_by === null);

    let company = current.properties.find(
      (x) => x.identifier_placeholder === "%company%"
    )?.value;
    let website = current.properties.find(
      (x) => x.identifier_placeholder === "%website%"
    )?.value;
    const hasGmaps = !!company;
    if (hasGmaps) {
      let link =
        "https://www.google.com/maps/search/" +
        (company?.replaceAll(" ", "+") || "") +
        (website ? `+${website}` : "");
      defaultResults.push({
        identifier_placeholder: "%gmaps_link%",
        id: 99999,
        name: "GMaps",
        value: link,
        readonly: true,
      });
    }

    defaultResults = defaultResults.sort(handleSort).map((element) => {
      let value =
        element?.value ||
        current.properties.find((x) => x.id === element.id)?.value;
      return {
        field: element.identifier_placeholder,
        field_id: element.id,
        name: element.name,
        defaultValue: value,
        readonly: element?.readonly,
      };
    });

    const customResults = leadsField
      .filter((x) => x.created_by !== null)
      .map((element) => {
        let value = current.properties.find((x) => x.id === element.id)?.value;
        return {
          field: element.identifier_placeholder,
          field_id: element.id,
          name: element.name,
          defaultValue: value,
          readonly: element?.readonly,
        };
      });

    return {
      defaultFields: defaultResults,
      customFields: customResults,
    };
  }, [current, leadsField]);

  return (
    <Stack
      w={"20%"}
      gap={0}
      h={"100%"}
      py={"sm"}
      px={"sm"}
      style={{ borderRight: "1px solid #00000016", transition: "all ease .3s" }}
    >
      <Group gap={"xs"}>
        <Avatar size={36} />
        <Stack gap={0}>
          <Tooltip
            label={
              (fields.defaultFields.find((x) => x.field === "%first_name%")
                ?.defaultValue || " - ") +
              " " +
              (fields.defaultFields.find((x) => x.field === "%last_name%")
                ?.defaultValue || " - ")
            }
          >
            <Text tt="uppercase" fw={500} maw={150} truncate="end">
              {fields.defaultFields.find((x) => x.field === "%first_name%")
                ?.defaultValue || " - "}
              {"  "}
              {fields.defaultFields.find((x) => x.field === "%last_name%")
                ?.defaultValue || " - "}
            </Text>
          </Tooltip>
          <Text size="xs" opacity={0.5}>
            #{current?.id}
          </Text>
        </Stack>
      </Group>
      <Divider my={6} />
      <Stack
        gap={0}
        h={"calc(100% - 80px)"}
        style={{ overflow: "auto" }}
        w={"100%"}
      >
        {fields.defaultFields.map((item, index) => (
          <LeadLeftBio
            key={item.field_id}
            defaultValue={item.defaultValue}
            label={item.name}
            field={item.field}
            fieldId={item.field_id}
            icon={getFieldIcon(item.field)}
            isLink={item?.defaultValue?.includes("http")}
            isTel={item?.field.includes("phone")}
            isMail={item?.field.includes("mail") && item?.field.includes("@")}
            isOrg={item?.field === "%company%"}
            companyInCharge={current?.company_has_person_in_charge}
            phoneInCharge={current?.phone_has_person_in_charge}
            disableEdit={
              !current?.permission?.write_permission || item?.readonly
            }
            company={current.properties.find((x) => x.id === 12)?.value}
          />
        ))}

        {fields?.customFields?.map((item, index) => (
          <LeadLeftBio
            key={item.field_id}
            defaultValue={item.defaultValue}
            label={item.name}
            field={item.field}
            fieldId={item.field_id}
            icon={getFieldIcon(item.field)}
            isLink={item?.defaultValue?.includes("http")}
            isTel={item?.field.includes("phone")}
            isMail={item?.field.includes("mail") && item?.field.includes("@")}
            isOrg={item?.field === "%company%"}
            disableEdit={
              !current?.permission?.write_permission || item?.readonly
            }
          />
        ))}
      </Stack>

      <LeftSheetBottom current={current} />
    </Stack>
  );
};

export default LeftSheet;
