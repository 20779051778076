import { Button, Group, Modal, PinInput, Stack, Text } from "@mantine/core";
import { IconPlus, IconPointerUp } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useDisclosure } from "@mantine/hooks";
import { useState } from "react";
import { joinOrg } from "../../../../api/UserAPI";
import { notifications } from "@mantine/notifications";
import BackButton from "../../../../components/View/BackButton";

const CreateOrgButton = ({ setTableLoading }) => {
  const lang = useLanguage((s) => s.language);
  const route = `/community/organizations/create`;

  const [opened, { open, close }] = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [codeError, setCodeError] = useState(null);

  const handleJoin = (e) => {
    e.preventDefault();
    const fd = new FormData(e.target);
    const data = Object.fromEntries(fd);

    setLoading(true);
    joinOrg(data.pin)
      .then((response) => {
        setTableLoading(true);
        notifications.show({
          message: lang.organizations.row_highlight.duplicated,
          color: "tertiary.8",
        });
        close();
      })
      .catch((err) => {
        let message = err.response.data.state_message;
        document.getElementById("code-input").focus();
        if (message === "already_in_the_organization") {
          setCodeError(lang.errors.helper_texts.alreadyInOrg);
        } else if (
          message === "empty_organization_code" ||
          message === "organization_code_invalid" ||
          message === "organization_does_not_exist"
        ) {
          setCodeError(lang.errors.helper_texts.incorrectCode);
        } else if (message === "too_many_attempts") {
          setCodeError(lang.errors.helper_texts.tooManyAttempts);
        } else {
          document.getElementById("code-input").focus();
          setCodeError(lang.errors.helper_texts.incorrectCode);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Group>
        <Button
          leftSection={<IconPointerUp size={18} />}
          radius={"sm"}
          variant="light"
          color="primary"
          onClick={open}
        >
          {lang.organizations.join.button_tooltip}
        </Button>

        <Button
          leftSection={<IconPlus size={18} />}
          radius={"sm"}
          color="primary"
          component={Link}
          to={route}
        >
          {lang.organizations.create.button_tooltip}
        </Button>
      </Group>

      <Modal
        opened={opened}
        title={lang.organizations.join.dialog.title}
        onClose={close}
        size={"sm"}
      >
        <Stack component={"form"} onSubmit={handleJoin}>
          <Text size="sm">{lang.organizations.join_text}</Text>
          <PinInput
            length={6}
            name="pin"
            error={codeError}
            id="code-input"
            data-autofocus
            fw={600}
            autoFocus
          />

          <Group justify="end" mt={"sm"}>
            <BackButton onClick={close} />

            <Button
              leftSection={<IconPointerUp size={18} />}
              color="tertiary.8"
              type="submit"
              loading={loading}
            >
              {lang.organizations.join.dialog.submit_button}
            </Button>
          </Group>
        </Stack>
      </Modal>
    </>
  );
};

export default CreateOrgButton;
