import { Button, Group, Stack } from "@mantine/core";
import { useRef, useState } from "react";
import TextEditor from "../../../../../../../../components/ExternalEditors/TextEditor";
import { useLanguage } from "../../../../../../../../stores/LanguageStore";
import { createNote } from "../../../../../../../../api/PRMAPI";
import { useLeadsSheetStore } from "../../../../../../../../stores/LeadsStore";
import { notifications } from "@mantine/notifications";
import BackButton from "../../../../../../../../components/View/BackButton";
import { IconDeviceFloppy } from "@tabler/icons-react";

const AddNoteContent = () => {
  const editorRef = useRef();
  const lang = useLanguage((s) => s.language);
  const current = useLeadsSheetStore((s) => s.current);
  const setCurrent = useLeadsSheetStore((s) => s.setCurrent);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setCurrent({
      ...current,
      history: current.history.filter((x) => !x?.isCreate),
    });
  };

  const handleSave = () => {
    setLoading(true);
    const html = editorRef.current.getContent();
    createNote({ prospect_id: current?.id, note: html })
      .then((response) => {
        let newNote = {
          date: new Date().toISOString(),
          id: response.data.note_id,
          date_updated: null,
          note: html,
          history_type: "note",
        };
        notifications.show({
          message: lang.prm.details.notes.created,
          color: "tertiary.8",
        });
        setCurrent({
          ...current,
          history: [
            newNote,
            ...(current?.history?.filter((x) => !x?.isCreate) || []),
          ],
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <Stack w={"100%"} p={4} gap={8}>
      <TextEditor
        editorRef={editorRef}
        id={"note-creator"}
        placeholder={lang.prm.details.notes.placeholder}
        isText
        height={275}
        noTop
        toolbar="bold italic underline numlist bullist link |"
        autoFocus
        data-autoFocus
      />

      <Group justify="end">
        <BackButton onClick={handleClose} />
        <Button
          loading={loading}
          onClick={handleSave}
          leftSection={<IconDeviceFloppy size={18} />}
        >
          {lang.prm.details.notes.create}
        </Button>
      </Group>
    </Stack>
  );
};

export default AddNoteContent;
