import { useState } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useDisclosure } from "@mantine/hooks";
import { Button, Group, Modal, Stack, Switch, Text } from "@mantine/core";
import BackButton from "../../../../components/View/BackButton";
import { IconUserSearch } from "@tabler/icons-react";
import { enrichList } from "../../../../api/ContactAPI";
import { notifications } from "@mantine/notifications";

const ListEnrich = ({ current, onFinish, closeMenu }) => {
  const lang = useLanguage((s) => s.language);
  const [opened, { open, close }] = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    let fd = new FormData(e.target);
    let data = Object.fromEntries(fd);

    setLoading(true);
    enrichList({
      contact_list_id: current.id,
      generate_email: data.generate_email === "on" ? true : false,
    })
      .then((res) => {
        notifications.show({
          message: lang.contact_lists.enrich.success,
          color: "tertiary.8",
        });
        setDone(true);
      })
      .finally(() => setLoading(false));
  };

  const handleClose = () => {
    close();
    closeMenu?.();
    setDone(false);
  };

  return (
    <>
      <Button
        onClick={open}
        leftSection={<IconUserSearch size={18} />}
        fw={600}
        color="primary"
        variant="subtle"
        justify="start"
      >
        {lang.contact_lists.enrich.label}
      </Button>

      {opened && (
        <Modal
          opened={opened}
          size={"md"}
          onClose={handleClose}
          title={
            <>
              {lang.contact_lists.enrich.dialog.title}{" "}
              <b>
                {current?.name} #{current.id}
              </b>
            </>
          }
        >
          {done ? (
            <Stack>
              <Text ta={"center"}>
                {
                  "L'enrichissement de votre liste a commencé, vous pouvez suivre l'avancement de l'enrichissement dans vos listes"
                }
              </Text>
              <Group justify="center" mt={"md"}>
                <Button
                  onClick={() => {
                    handleClose();
                    onFinish?.();
                  }}
                >
                  {lang.global.finish}
                </Button>
              </Group>
            </Stack>
          ) : (
            <Stack component={"form"} onSubmit={handleSubmit} gap={"xs"}>
              <Text c={"dimmed"}>{lang.contact_lists.enrich.dialog.text}</Text>

              <Switch
                label={lang.contact_lists.enrich.dialog.generate_email}
                name="generate_email"
              />

              <Group justify="end" mt={"md"}>
                <BackButton onClick={handleClose} />

                <Button
                  leftSection={<IconUserSearch size={18} />}
                  loading={loading}
                  type="submit"
                >
                  {lang.contact_lists.enrich.dialog.submit_button}
                </Button>
              </Group>
            </Stack>
          )}
        </Modal>
      )}
    </>
  );
};

export default ListEnrich;
