import { useState } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { unlockUserAccount } from "../../../../api/UserAPI";
import { Button, Tooltip } from "@mantine/core";
import { IconLockOpen } from "@tabler/icons-react";

const UnlockAccount = ({ profile, onSuccess }) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(false);
  const onClick = () => {
    setLoading(true);
    unlockUserAccount(profile.id)
      .then((response) => {
        onSuccess();
      })
      .finally(() => setLoading(false));
  };
  return (
    <Tooltip label={lang.administration.center.unlock_account}>
      <Button
        color="green.8"
        variant="light"
        onClick={onClick}
        loading={loading}
        disabled={!profile}
        px={10}
        w={"100%"}
      >
        <IconLockOpen size={20} />
      </Button>
    </Tooltip>
  );
};
export default UnlockAccount;
