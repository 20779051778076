import { useLanguage } from "../../../../../../stores/LanguageStore";
import { Button, Group } from "@mantine/core";

const LeftBioSaver = ({ handleClose, handleSave, saveLabel, loading }) => {
  const lang = useLanguage((s) => s.language);
  return (
    <Group justify="end">
      {handleClose && (
        <Button variant="subtle" size="compact-xs" onClick={handleClose}>
          {lang.prm.details.cancel}
        </Button>
      )}
      <Button size="compact-xs" onClick={handleSave} loading={loading} px={12} color="primary">
        {saveLabel || lang.prm.details.save}
      </Button>
    </Group>
  );
};

export default LeftBioSaver;
