import { useLanguage } from "../../../stores/LanguageStore";

const actionCellWidth = 110;

export const actionColumn = (actionsCell, width) => {
  const lang = useLanguage.getState().language;
  return {
    field: "actions",
    type: "actions",
    headerName: lang.table.action_column_name,
    width: width || actionCellWidth,
    disableExport: true,
    sortable: false,
    renderHeader: () => "",
    renderCell: (params) => {
      return actionsCell(params);
    },
  };
};
