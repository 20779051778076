import { useGridApiRef } from "@mui/x-data-grid-pro";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useCallback, useMemo, useState } from "react";
import numberColumn from "../../../../layouts/Table/columns/numberColumn";
import { nameColumn } from "../../../../layouts/Table/columns/nameColumn";
import {
  deleteBlacklist,
  downloadBlacklist,
  editBlacklist,
  getBlacklists,
} from "../../../../api/BlacklistAPI";
import { Button, Group } from "@mantine/core";
import { sharingColumn } from "../../../../layouts/Table/columns/sharingColumn";
import dateColumn from "../../../../layouts/Table/columns/dateColumn";
import SearchListsButton from "../../lists/components/SearchListsButton";
import SelectionDeleter from "../../../../components/TableComponents/SelectionDeleter";
import { IconArrowBarToUp } from "@tabler/icons-react";
import ItemDeleter from "../../../../components/TableComponents/ItemDeleter";
import { useListingLoader } from "../../../../components/hooks/ListingHooks";
import BlacklistImportButton from "./BlacklistImportButton";
import TableLayoutBlock from "../../../../layouts/Table/TableLayoutBlock";
import MainTable from "../../../../layouts/Table/MainTable";
import { Link } from "react-router-dom";
import PageTitle from "../../../../components/View/PageTitle";
import ListDownload from "../../lists/actions/ListDownload";

const BlacklistListing = () => {
  const lang = useLanguage((s) => s.language);
  const apiRef = useGridApiRef();

  const columns = useMemo(
    () => [
      numberColumn(),
      nameColumn({
        parentPath: "/exclusions/blacklists",
        editFunction: () => editBlacklist,
        apiRef,
      }),
      numberColumn(
        "number_of_data",
        lang.blacklists.columns.number_of_data,
        120
      ),
      sharingColumn({ apiRef, editFunction: () => editBlacklist }),
      dateColumn(),
    ],
    [lang, apiRef]
  );
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);

  const toolbarActions = useCallback(
    ({ selectedIds }) => {
      return (
        <>
          <SearchListsButton isBlacklist />
          <SelectionDeleter
            handleDelete={deleteBlacklist}
            apiRef={apiRef}
            setTableLoading={setLoading}
            ids={selectedIds}
          />
        </>
      );
    },
    [apiRef]
  );

  const actionsCell = useCallback(
    ({ row }) => {
      return (
        <Group wrap="nowrap" gap={"xs"}>
          <ListDownload
            current={row}
            handler={downloadBlacklist}
            isIcon
            closeMenu={() => {}}
          />
          <ItemDeleter
            title_bold={`${row.name} #${row.id}`}
            handleDelete={deleteBlacklist}
            deletedMessage={lang.blacklists.delete_success}
            id={row.id}
            disabled={!row?.delete_permission}
            apiRef={apiRef}
          />
        </Group>
      );
    },
    [apiRef, lang.blacklists.delete_success]
  );

  useListingLoader({
    loading,
    setLoading,
    listGetter: getBlacklists,
    responseField: "blacklists_list",
    setRows,
  });

  return (
    <>
      <Group className="layout-block top" w={"100%"} justify="space-between">
        <PageTitle title={lang.blacklists.browser_tab_title} />

        <BlacklistImportButton />
      </Group>

      <TableLayoutBlock>
        <MainTable
          apiRef={apiRef}
          columns={columns}
          rows={rows}
          loading={loading}
          setLoading={setLoading}
          id={"list-table"}
          toolbarActions={toolbarActions}
          actionsCell={actionsCell}
          actionsCellWidth={75}
          noRowsDisplay={
            <Button
              leftSection={<IconArrowBarToUp size={32} />}
              radius={"sm"}
              variant="light"
              size="xl"
              color="tertiary.8"
              component={Link}
              to={"import"}
            >
              Importer une Blacklist
            </Button>
          }
        />
      </TableLayoutBlock>
    </>
  );
};

export default BlacklistListing;
