import { useMemo } from "react";
import { useSessionStore } from "../../../../../../stores/UserStore";
import { NavLink, Text } from "@mantine/core";
import { useLeadsSheetStore } from "../../../../../../stores/LeadsStore";

const SameProspect = ({ item }) => {
  const usersOptions = useSessionStore((s) => s.usersOptions);
  const current = useLeadsSheetStore((s) => s.current);
  const setCurrent = useLeadsSheetStore((s) => s.setCurrent);
  const setLoading = useLeadsSheetStore((s) => s.setLoading);

  const properties = useMemo(() => {
    let first_name =
      item?.properties.find((x) => x.identifier_placeholder === "%first_name%")
        ?.value || " - ";
    let last_name =
      item?.properties.find((x) => x.identifier_placeholder === "%last_name%")
        ?.value || " - ";
    let job_title =
      item?.properties.find((x) => x.identifier_placeholder === "%job_title%")
        ?.value || " - ";

    let user_in_charge = usersOptions.find(
      (x) => x.id === item.person_in_charge
    );

    return {
      first_name,
      last_name,
      job_title,
      user_in_charge,
    };
  }, [item.person_in_charge, item?.properties, usersOptions]);

  const switchToSameProspect = () => {
    setCurrent(item);
    setLoading(true)
  };

  return (
    <NavLink
      active={current?.id === item.id}
      miw={"100%"}
      style={{
        border: "1px solid #00000018",
        borderRadius: 6,
      }}
      pt={4}
      w={0}
      label={`${properties?.first_name || " - "} ${
        properties?.last_name || " - "
      }`}
      description={
        <Text size="xs" truncate="end" w={"100%"}>
          {properties?.job_title || " - "}
        </Text>
      }
      onClick={switchToSameProspect}
    />
  );
};

export default SameProspect;
