import { lazy, useEffect, useState } from "react";
import { Stack } from "@mantine/core";
import ReportingTables from "./tables/ReportingTables";
import { getGlobalReporting } from "../../../../api/StatsAPI";
import "@mantine/charts/styles.css";
import StatsPageTitle from "../StatsPageTitle";
import { useLanguage } from "../../../../stores/LanguageStore";

const ReportingReaches = lazy(() => import("./reaches/ReportingReaches"));
const ReportingTotal = lazy(() => import("./total/ReportingTotal"));
const ReportingRates = lazy(() => import("./rates/ReportingRates"));
const PieCharts = lazy(() => import("./pies/PieCharts"));

const Reporting = () => {
  const lang = useLanguage((s) => s.language);
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getGlobalReporting()
      .then((response) => {
        setStats(response.data.statistics);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <StatsPageTitle defaultTitle={lang.reporting.browser_tab_title} />

      <Stack w={"100%"} px={16} pos={"relative"}>
        <ReportingTotal stats={stats} loading={loading} />

        <ReportingReaches stats={stats} />

        <ReportingRates stats={stats} loading={loading} />

        <PieCharts stats={stats} loading={loading} />

        <ReportingTables stats={stats} loading={loading} />
      </Stack>
    </>
  );
};

export default Reporting;
