import { useLeadsStore } from "../stores/LeadsStore";
import { mainAxios as axios } from "./axios";
//#region Pagination
export const getFirstPagePrm = async ({ per_page, sort, filter }) => {
  return axios.get(`/prm/contacts`, {
    params: {
      options: JSON.stringify({
        per_page: per_page,
        filter: filter,
        sort: sort,
      }),
    },
  });
};

export const getFirstPagePrmUser = async ({ id, per_page, sort, filter }) => {
  return axios.get(`/prm/contacts/user/${id}`, {
    params: {
      options: JSON.stringify({
        per_page: per_page,
        filter: filter,
        sort: sort,
      }),
    },
  });
};

export const getFirstPagePrmShared = async ({
  per_page,
  sort,
  filter,
  conditions,
}) => {
  return axios.get(`/prm/contacts/shared`, {
    params: {
      options: JSON.stringify({
        per_page: per_page,
        filter: filter,
        sort: sort,
        conditions: conditions,
      }),
    },
  });
};

export const requestNextPage = async (next_url) => {
  return axios.get(next_url);
};
//#endregion

//#region Sharing
export const getOtherPrms = async () => {
  const res = await axios.get(`/prm/list`);
  if (res) {
    useLeadsStore.getState().setPrms(res.data.prm);
  }

  return res;
};

export const getSharings = async () => {
  const res = await axios.get(`/prm/sharings`);
  if (res) {
    useLeadsStore.getState().setSharings(res.data.sharings);
  }

  return res;
};

export const createSharing = async ({
  name,
  sharing_type_to,
  read_permission,
  write_permission,
  delete_permission,
  ids_list,
  filter,
  contact_ids,
}) => {
  return axios.post(`/prm/sharings`, {
    sharing: {
      name,
      sharing_type_to,
      read_permission,
      write_permission,
      delete_permission,
      ids_list,
      filter,
      contact_ids,
    },
  });
};

export const editSharing = async ({
  id,
  name,
  sharing_type_to,
  read_permission,
  write_permission,
  delete_permission,
  ids_list,
  filter,
  contact_ids,
}) => {
  return axios.put(`/prm/sharings/${id}`, {
    sharing: {
      name,
      sharing_type_to,
      read_permission,
      write_permission,
      delete_permission,
      ids_list,
      filter,
      contact_ids,
    },
  });
};
export const deleteSharing = async ({ id }) => {
  return axios.delete(`/prm/sharings/${id}`);
};
//#endregion

//#region Status
export const getStatusSystem = async () => {
  return axios.get(`/prm/status`);
};
export const getStatusCustom = async () => {
  return axios.get(`/prm/status/custom`);
};
export const createStatus = async ({ name }) => {
  return axios.post(`/prm/status/custom`, { name });
};
export const editStatus = async ({
  id,
  name,
  visible,
  color,
  sorting,
  isSystem,
}) => {
  return axios.put(`/prm/status/${isSystem ? "" : "custom/"}${id}`, {
    name,
    visible,
    color,
    sorting,
  });
};
export const deleteStatusCustom = async ({ id }) => {
  return axios.delete(`/prm/status/custom/${id}`);
};
//#endregion

//#region Calls
export const createReminder = async ({
  prospect_id,
  name,
  call_date,
  type,
}) => {
  return axios.post(`/prm/contact/${prospect_id}/call`, {
    name,
    call_date,
    type,
  });
};
export const deleteReminder = async ({ parent_id, id }) => {
  return axios.delete(`/prm/contact/${parent_id}/call/${id}`);
};
//#endregion

//#region Prospect
export const createProspect = async ({ properties, status, custom_status }) => {
  return axios.post(`/prm/contacts`, { properties, status, custom_status });
};
export const createProspectUser = async ({
  user_id,
  properties,
  status,
  custom_status,
}) => {
  return axios.post(`/prm/contacts/user/${parseInt(user_id)}`, {
    properties,
    status,
    custom_status,
  });
};
export const findProspect = async (id) => {
  return axios.get(`/prm/contact/${id}`);
};
export const updateProspect = async ({
  id,
  properties,
  status,
  custom_status,
  is_positive,
  score,
  amount,
  probability,
  closing_date,
  person_in_charge,
}) => {
  return axios.put(`/prm/contact/${id}`, {
    properties,
    status,
    custom_status,
    is_positive,
    score,
    amount,
    probability,
    closing_date,
    person_in_charge,
  });
};
export const updateProspectsStatus = async ({
  contact_ids,
  status,
  custom_status,
}) => {
  return axios.put(`/prm/contacts/status`, {
    contact_ids,
    status,
    custom_status,
  });
};
export const deleteProspects = async ({
  filter,
  contact_ids,
  excluded_contact_ids,
}) => {
  return axios.delete(`/prm/contacts/`, {
    data: {
      filter,
      contact_ids,
      excluded_contact_ids,
    },
  });
};
export const deleteProspectsUser = async ({
  user_id,
  filter,
  contact_ids,
  excluded_contact_ids,
}) => {
  return axios.delete(`/prm/contacts/user/${user_id}`, {
    data: {
      filter,
      contact_ids,
      excluded_contact_ids,
    },
  });
};
export const excludeProspectFromProgrammation = async ({
  contact_id,
  programmation_id,
}) => {
  return axios.post(
    `/prm/contact/${contact_id}/exclude/programmation/${programmation_id}`
  );
};
export const excludeProspectFromWorkflow = async ({
  contact_id,
  workflow_id,
}) => {
  return axios.post(`/prm/contact/${contact_id}/exclude/${workflow_id}`);
};
export const progressProspectToStep = async ({
  contact_id,
  programmation_id,
  step_id,
}) => {
  return axios.post(
    `/prm/contact/${contact_id}/programmation/${programmation_id}/step/${step_id}`
  );
};
export const markReply = async ({
  contact_id,
  history_type_reply,
  reply_id,
  is_positive,
}) => {
  return axios.put(
    `/prm/contact/${contact_id}/reply/${history_type_reply}/${reply_id}`,
    { is_positive }
  );
};
//#endregion

//#region Notes
export const createNote = async ({ prospect_id, note }) => {
  return axios.post(`/prm/contact/${prospect_id}/note`, { note });
};
export const editNote = async ({ prospect_id, note_id, note }) => {
  return axios.put(`/prm/contact/${prospect_id}/note/${note_id}`, { note });
};
export const deleteNote = async ({ prospect_id, note_id }) => {
  return axios.delete(`/prm/contact/${prospect_id}/note/${note_id}`);
};
//#endregion

//#region Export
export const exportPrm = async ({
  file_type,
  filter,
  contact_ids,
  excluded_contact_ids,
  name,
}) => {
  let response = await axios.post(
    `/prm/contacts/export/${file_type}`,
    {
      filter,
      contact_ids,
      excluded_contact_ids,
    },
    {
      responseType: "blob",
    }
  );
  if (response) {
    const url = window.URL.createObjectURL(response.data);
    const a = document.createElement("a");
    a.href = url;
    a.download = name || `PRM_export`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  return response;
};
export const exportUserPrm = async ({
  user_id,
  file_type,
  filter,
  contact_ids,
  excluded_contact_ids,
}) => {
  let response = await axios.post(
    `/prm/contacts/user/${user_id}/export/${file_type}`,
    {
      filter,
      contact_ids,
      excluded_contact_ids,
    },
    {
      responseType: "blob",
    }
  );
  if (response) {
    const url = window.URL.createObjectURL(response.data);
    const a = document.createElement("a");
    a.href = url;
    a.download = `PRM_export`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  return response;
};

export const prmAddToList = async ({ contact_list_id, contact_ids }) => {
  return axios.post(`/prm/contacts/contact-list/${contact_list_id}/add`, {
    contact_ids,
  });
};

export const downloadEML = async ({ reply_id }) => {
  return axios
    .get(`/workflows/reply/email/${reply_id}/download/eml`, {
      responseType: "blob",
    })
    .then((res) => {
      const url = window.URL.createObjectURL(res.data);
      const a = document.createElement("a");
      a.href = url;
      a.download = `Answer #${reply_id}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
};

//#endregion

//#region Nuturing
export const getNurturings = async () => {
  let res = await axios.get(`/prm/nurturings`);
  // if (res) {
  //   localStorage.setItem("custom_status", JSON.stringify(res.data.status))
  // }
  return res;
};
export const createNurturing = async ({ name, filter, contact_list_ids }) => {
  return axios.post(`/prm/nurturing`, { name, filter, contact_list_ids });
};
export const editNurturing = async ({ id, name, filter, contact_list_ids }) => {
  return axios.put(`/prm/nurturing/${id}`, { name, filter, contact_list_ids });
};
export const deleteNurturing = async ({ id }) => {
  return axios.delete(`/prm/nurturing/${id}`);
};
//#endregion

export const excludeFromProgrammation = async ({
  contact_id,
  programmation_id,
}) => {
  return axios.post(
    `/prm/contact/${contact_id}/exclude/programmation/${programmation_id}`
  );
};

//#region Captcha
export const getCaptchas = async () => {
  return axios.get(`/integrations/email/protections`);
};
export const handleCaptcha = async (protection_id) => {
  return axios.put(`/integrations/email/protection/${protection_id}`, {
    handled: true,
  });
};
//#endregion
