import {
  Accordion,
  Badge,
  Box,
  Button,
  Group,
  Text,
  Tooltip,
} from "@mantine/core";
import { IconShare } from "@tabler/icons-react";
import { useLanguage } from "../../stores/LanguageStore";
import SharingItem from "./SharingItem";
import { useFieldArray, useWatch } from "react-hook-form";

const newShare = {
  name: "",
  sharing_type_to: "User",
  read_permission: true,
  write_permission: false,
  delete_permission: false,
  sharing_targets: [],
};

export const SharingController = ({ form, readOnly, max = 100, disablePermissions, disableDelete, ...props }) => {
  const lang = useLanguage((s) => s.language);
  const { register, control, getValues, setValue } = form;

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "sharing",
  });

  return (
    <Box>
      {fields.length === 0 && (
        <Text c="dimmed" ta="center">
          {lang.components.sharing.no_element}
        </Text>
      )}

      {fields.map((item, index) => (
        <SharingItem
          key={item.id}
          index={index}
          register={register}
          update={update} // With rerender
          setValue={setValue} // Without Rerender
          getValues={getValues}
          handleDelete={() => remove(index)}
          control={control}
          readOnly={readOnly}
          disablePermissions={disablePermissions}
          disableDelete={disableDelete}
        />
      ))}

      {fields.length < max && (
        <Group justify="center" mt="md">
          <Button variant="outline" onClick={() => append(newShare)}>
            {lang.components.sharing.add_tooltip}
          </Button>
        </Group>
      )}
    </Box>
  );
};

export const AccordionControl = ({ form }) => {
  const lang = useLanguage((s) => s.language);

  const sharing = useWatch({
    control: form.control,
    name: "sharing",
  });

  return (
    <Accordion.Control
      icon={<IconShare size={16} />}
      px={"xs"}
      bg={"var(--mantine-color-gray-1)"}
    >
      {lang.components.sharing.title}{" "}
      {sharing?.length > 0 && (
        <Tooltip label={`${sharing.length} partages`}>
          <Badge ml={"sm"} color="red">
            {sharing?.length}
          </Badge>
        </Tooltip>
      )}
    </Accordion.Control>
  );
};

export const SharingInAccordion = ({ form, max, ...props }) => {
  return (
    <Accordion variant="contained">
      <Accordion.Item value={"sharing"}>
        <AccordionControl form={form} bg={"var(--mantine-color-gray-1)"} />
        <Accordion.Panel>
          <SharingController form={form} max={max} {...props} />
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

export default SharingController;
