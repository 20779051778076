import { useCallback, useEffect, useMemo, useState } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { useListingLoader } from "../../../../components/hooks/ListingHooks";
import SelectionDeleter from "../../../../components/TableComponents/SelectionDeleter";
import { deleteTeam, editTeam, getProfile } from "../../../../api/UserAPI";
import ItemDeleter from "../../../../components/TableComponents/ItemDeleter";
import {
  ActionIcon,
  Center,
  Group,
  SegmentedControl,
  Tooltip,
} from "@mantine/core";
import dateColumn from "../../../../layouts/Table/columns/dateColumn";
import numberColumn from "../../../../layouts/Table/columns/numberColumn";
import { nameColumn } from "../../../../layouts/Table/columns/nameColumn";
import MainTable from "../../../../layouts/Table/MainTable";
import TableLayoutBlock from "../../../../layouts/Table/TableLayoutBlock";
import { IconDoorExit, IconUser, IconWorld } from "@tabler/icons-react";
import { CommCode } from "../CommCode";
import CreateTeamButton from "./CreateTeamButton";
import { useProfileStore } from "../../../../stores/UserStore";
import { getTeams } from "../../../../api/AdminAPI";
import InviteToCommunity from "../InviteToCommunity";
import PageTitle from "../../../../components/View/PageTitle";
import { sortDescId } from "../../../../functions/utilFunctions";

const TeamListing = () => {
  const apiRef = useGridApiRef();
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);

  const columns = useMemo(
    () => [
      nameColumn({
        parentPath: (row) => `/community/teams/${row.id}/users`,
        withoutId: true,
        editFunction: () => editTeam,
        getDisabled: () => false,
        apiRef,
      }),
      numberColumn(
        "number_of_users",
        lang.resellers.columns.number_of_users,
        100
      ),
      {
        field: "user_level",
        headerName: lang.resellers.columns.user_level,
        width: 120,
        valueGetter: ({ value }) => {
          if (value === "user") {
            return lang.resellers.columns.user_level_value_user;
          }
          if (value === "admin") {
            return lang.resellers.columns.user_level_value_admin;
          }
          if (value === "super_admin") {
            return lang.resellers.columns.user_level_value_super_admin;
          }
        },
      },
      {
        field: "team_code",
        headerName: lang.teams.columns.team_code,
        width: 150,
        renderCell: ({ value }) => {
          return <CommCode token={value} />;
        },
      },
      dateColumn(),
    ],
    [lang, apiRef]
  );

  const actionsCell = useCallback(
    ({ row }) => {
      return (
        <Group wrap="nowrap" gap={4}>
          <Tooltip label="Quitter">
            <ActionIcon variant="subtle" disabled>
              <IconDoorExit size={18} />
            </ActionIcon>
          </Tooltip>
          <InviteToCommunity current={row} isAction />
          <ItemDeleter
            title_bold={`${row.name} #${row.id}`}
            handleDelete={deleteTeam}
            deletedMessage={lang.teams.delete_success}
            id={row.id}
            apiRef={apiRef}
          />
        </Group>
      );
    },
    [apiRef, lang.teams.delete_success]
  );

  const toolbarActions = useCallback(
    ({ selectedIds }) => (
      <SelectionDeleter
        handleDelete={deleteTeam}
        apiRef={apiRef}
        setTableLoading={setLoading}
        ids={selectedIds}
      />
    ),
    [apiRef]
  );

  const [type, setType] = useState(
    profile?.level === "admin" ? "admin" : "user"
  );

  useListingLoader({
    loading,
    setLoading,
    listGetter: type === "admin" ? getTeams : getProfile,
    responseField: type === "admin" ? "teams_list" : "user_profile",
    setRows,
    getRows: (data) =>
      type === "admin" ? sortDescId(data) : sortDescId(data.teams),
  });

  useEffect(() => {
    setLoading(true);
  }, [type]);

  return (
    <>
      <Group
        className="layout-block top"
        w={"100%"}
        gap={0}
        justify="space-between"
      >
        <PageTitle title={lang.teams.browser_tab_title} />

        {profile?.level === "admin" && (
          <SegmentedControl
            ml={"auto"}
            mr={16}
            data={[
              {
                label: (
                  <Center>
                    <IconWorld size={16} style={{ marginRight: 8 }} />
                    {lang.teams.admin_team_list_main_help}
                  </Center>
                ),
                value: "admin",
              },
              {
                label: (
                  <Center>
                    <IconUser size={16} style={{ marginRight: 8 }} />
                    {lang.teams.user_team_list_title}
                  </Center>
                ),
                value: "user",
              },
            ]}
            onChange={setType}
          />
        )}

        <CreateTeamButton setTableLoading={setLoading} />
      </Group>

      <TableLayoutBlock>
        <MainTable
          apiRef={apiRef}
          columns={columns}
          rows={rows}
          loading={loading}
          setLoading={setLoading}
          id={"list-table"}
          toolbarActions={toolbarActions}
          actionsCell={actionsCell}
          pageOrderName={"teams_order"}
        />
      </TableLayoutBlock>
    </>
  );
};

export default TeamListing;
