import { useCallback, useEffect, useState } from "react";
import { levelsColor } from "./filterSettings";
import { useLanguage } from "../../stores/LanguageStore";
import FilterItem from "./FilterItem";
import FilterItemAdder from "./FilterItemAdder";
import FilterGroupAdder from "./FilterGroupAdder";
import { ActionIcon, Box, Group, Stack, Tooltip } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import FilterModeSelector from "./FilterModeSelector";

export const conditionWidth = 360;

let newId = 0;

const FilterBlockChild = ({
  values,
  mode,
  parentMode,
  isLast,
  handleDeleteGroup,
  handleChange,
  itemNumbers,
  fields,
  maxItems,
  getFieldOptionName,
  level = 1,
  disableEdit,
}) => {
  const lang = useLanguage((s) => s.language);

  const [_mode, _setMode] = useState(mode || "and");

  const onModeChange = (v) => {
    _setMode(v);
  };

  const [items, setItems] = useState(
    values.map((x) => ({ ...x, id: newId++ })) || []
  );

  const addItem = () => {
    const newItem = {
      field_name: fields[0].options[0].id.toString(),
      type: fields[0].options[0]?.type === "number" ? "=" : "contains",
      value: "",
      id: newId++,
    };
    setItems((prev) => [...prev, newItem]);
  };

  const addGroup = () => {
    const newItem = {
      mode: "and",
      values: [
        {
          field_name: fields[0].options[0].id.toString(),
          type: fields[0].options[0]?.type === "number" ? "=" : "contains",
          value: "",
        },
      ],
    };
    setItems((prev) => [...prev, newItem]);
  };

  const deleteItem = (id) => {
    setItems((prev) => {
      let newItems = prev.filter((x) => x.id !== id);

      return newItems;
    });
  };

  const deleteGroup = (id) => {
    setItems((prev) => {
      let newItems = prev.filter((x) => x.id !== id);

      return newItems;
    });
  };

  const handleItemChange = useCallback(({ id, field_name, type, value }) => {
    setItems((prev) => {
      let newItems = [...prev];
      let index = newItems.findIndex((x) => x.id === id);

      newItems[index] = {
        field_name: field_name,
        type: type,
        value: value,
        id: id,
      };

      return newItems;
    });
  }, []);

  const handleGroupChange = useCallback((id, newValues) => {
    setItems((prev) => {
      let newItems = [...prev];
      let index = newItems.findIndex((x) => x.id === id);
      newItems[index] = { ...newValues, id: id };

      return newItems;
    });
  }, []);

  useEffect(() => {
    if (handleChange) {
      handleChange({
        mode: _mode,
        values: items,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, _mode]);

  return (
    <Stack pos="relative" pl={36}>
      {!isLast && (
        <Box
          style={{
            position: "absolute",
            top: "calc(50% + 29px)",
            left: 7,
            transform: "translate(-50%, -50%)",
            zIndex: 5,
            background: levelsColor[0],
            borderRadius: 2,
            fontWeight: 500,
            color: "#fff",
            fontSize: 8,
          }}
          px={2}
          py={1}
        >
          {parentMode === "and" ? "ET" : "OU"}
        </Box>
      )}

      <Box
        style={{
          position: "absolute",
          top: 17,
          left: 12,
          width: 32,
          height: 0,
          borderTop: "2px solid",
          borderColor: levelsColor[level - 1],
        }}
      />

      <Group>
        <FilterModeSelector
          onChange={onModeChange}
          value={_mode}
          borderColor={levelsColor[level]}
          disabled={disableEdit}
        />

        <Tooltip
          label={
            lang.table.toolbar.filter.conditions.delete_condition_group_tooltip
          }
        >
          <ActionIcon color="red" variant="subtle" onClick={handleDeleteGroup}>
            <IconTrash size={18} />
          </ActionIcon>
        </Tooltip>
      </Group>

      <Stack pos="relative" pl={2}>
        <div
          style={{
            position: "absolute",
            top: -16,
            left: 12,
            width: 0,
            height: "calc(100% + 4px)",
            borderRight: "2px solid",
            borderColor: levelsColor[level],
          }}
        />
        {/* Children goes here */}
        {items.map((x, i) =>
          x?.values ? (
            <FilterBlockChild
              key={x.id}
              fields={fields}
              getFieldOptionName={getFieldOptionName}
              handleChange={(newItems) => handleGroupChange(x.id, newItems)}
              handleDeleteGroup={() => deleteGroup(x.id)}
              values={x.values}
              mode={x.mode}
              isLast={i === items.length - 1}
              parentMode={_mode}
              itemNumbers={itemNumbers}
              maxItems={maxItems}
              level={2}
            />
          ) : (
            <FilterItem
              item={x}
              key={x.id}
              lineColor={levelsColor[level]}
              isLast={i === items.length - 1}
              parentMode={_mode}
              handleDelete={() => deleteItem(x.id)}
              handleChange={(newFilter) =>
                handleItemChange({
                  ...newFilter,
                  id: x.id,
                })
              }
              fields={fields}
              getFieldOptionName={getFieldOptionName}
            />
          )
        )}

        <Stack ml={"sm"} pl={25} pos="relative">
          <div
            style={{
              position: "absolute",
              top: "calc(50% - 2px)",
              left: 0,
              width: 23,
              border: 0,
              borderTop: "2px dashed",
              borderColor: levelsColor[level],
            }}
          />
          <Group>
            {level === 1 && (
              <FilterGroupAdder
                disabled={itemNumbers >= maxItems || disableEdit}
                onClick={addGroup}
                color={levelsColor[level]}
              />
            )}
            <FilterItemAdder
              disabled={itemNumbers >= maxItems || disableEdit}
              onClick={addItem}
              color={levelsColor[level]}
            />
          </Group>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default FilterBlockChild;
