import { useState } from "react";
import { useLANGUAGE, useLanguage } from "../../stores/LanguageStore";
import { updateProfile } from "../../api/UserAPI";
import { Button, Group, Menu } from "@mantine/core";

const LanguageChanger = ({ is2 }) => {
  const [opened, setOpened] = useState(false);
  const lang = useLANGUAGE((s) => s.language);
  const language = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(false);

  const handleSwitch = () => {
    let newLang = language.id === "ENG" ? "FRA" : "ENG";
    setLoading(true);
    setOpened(false);

    updateProfile(null, { language: newLang })
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Menu shadow="md" opened={opened} onChange={setOpened} offset={0}>
      <Menu.Target>
        <Button
          variant="subtle"
          id="language-selector"
          loading={loading}
          px={12}
          bg={opened ? "var(--mantine-primary-color-light-hover)" : undefined}
          ml="auto"
          color={is2 ? "#000" : "white"}
          fw={600}
          size="lg"
        >
          {lang === "ENG" ? <span>EN</span> : <span>FR</span>}
        </Button>
      </Menu.Target>

      <Menu.Dropdown p={0}>
        <Button
          variant="subtle"
          disabled={loading}
          px={"sm"}
          onClick={handleSwitch}
          h={52}
        >
          {lang === "ENG" ? (
            <Group wrap="nowrap">
              <span>Français</span>
            </Group>
          ) : (
            <Group wrap="nowrap">
              <span>English</span>
            </Group>
          )}
        </Button>
      </Menu.Dropdown>
    </Menu>
  );
};

export default LanguageChanger;
