import { Button, Group, Radio, Stack, Text, TextInput } from "@mantine/core";
import { IconChevronLeft, IconFoldUp } from "@tabler/icons-react";
import { useCallback, useMemo, useState } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { createBlacklist, importBlacklist } from "../../../../api/BlacklistAPI";
import CreatableBlacklistSelector from "../../../../components/Selectors/CreatableBlacklistSelector";
import { useConfigStore } from "../../../../stores/ConfigStore";
import { useRouteLoaderData } from "react-router-dom";

const BlacklistImportStep = ({
  data,
  handlePrev,
  fields,
  setImporting,
  onFinish,
  file,
}) => {
  const blacklistProfile = useRouteLoaderData("blacklist");
  const lang = useLanguage((s) => s.language);
  const [importStats, setImportStats] = useState(null);
  const [error, setError] = useState(null);
  const [name, setName] = useState(file?.name);
  const [list, setList] = useState(blacklistProfile);
  const [listState, setListState] = useState(blacklistProfile ? 2 : 1);

  const finalData = useMemo(() => {
    const datafields = useConfigStore.getState().datafields;
    const parsedFields = {};
    Object.keys(data[0]).forEach((key) => {
      parsedFields[key] = datafields.find(
        (x) => x.identifier_placeholder === fields[key]
      )?.id;
    });

    let contacts = [];
    data.forEach((contact) => {
      Object.keys(contact).forEach((column) => {
        if (parsedFields[column] && contact[column]) {
          contacts.push({
            data_field_id: parsedFields[column],
            value: contact[column]?.toString(),
          });
        }
      });
    });

    return contacts;
  }, [data, fields]);

  const handleImport = useCallback(
    async (e) => {
      e.preventDefault();
      setImporting(true);

      async function mainFunction(id) {
        return importBlacklist(id, finalData, true)
          .then((response) => {
            setImportStats({
              ignored: response.data.data_ignored,
            });
          })
          .catch((err) => {
            let err_data = err.response.data;
            setError(err_data);
          })
          .finally(() => {
            setImporting(false);
          });
      }

      if (!list?.id) {
        return createBlacklist({ name })
          .catch((createError) => {
            let state_message = createError.response.data.state_message;
            // if (state_message === "empty_name") {
            //   return handleErrors(state_message);
            // }
          })
          .then((response) => {
            setList({ id: response.data.blacklist_id });
            mainFunction(response.data.blacklist_id);
          });
      }
      return mainFunction(list?.id);
    },
    [finalData, list?.id, name, setImporting]
  );

  const main = useMemo(() => {
    if (importStats) {
      return (
        <>
          <Text fontSize={15} ta={"center"}>
            {importStats.ignored}{" "}
            {lang.blacklists.import.step_importing.success.data}{" "}
            {lang.blacklists.import.step_importing.success.ignored}
          </Text>

          <Button
            variant="outline"
            onClick={() => onFinish(list.id)}
            w={300}
            size="md"
            mx={"auto"}
          >
            {lang.global.finish}
          </Button>
        </>
      );
    }
    if (error) {
      if (error?.state_message === "import_limit_exceeded") {
        return (
          <Text>
            {
              lang.unsubscribers.import.step_importing.error
                .import_limit_exceeded
            }{" "}
            ({data.length}{" "}
            {lang.unsubscribers.import.step_importing.error.rows_to_import})
          </Text>
        );
      }

      if (error?.errors?.length > 0) {
        return error.errors.map((err, index) => (
          <Text key={index}>
            {lang.unsubscribers.import.step_importing.error.error_on_line}{" "}
            {err?.line} :{" "}
            {err?.error.includes("format")
              ? lang.unsubscribers.import.step_importing.error.unknown_format
              : err?.error}
          </Text>
        ));
      }
    } else {
      return (
        <form onSubmit={handleImport}>
          <Text>
            <b>{finalData?.length}</b> données seront importées. <br /> <br />
          </Text>

          <Group wrap="nowrap" align="end" gap={"xl"}>
            <Stack w="100%" gap={4}>
              <Radio
                label={lang.targeting_linkedin_google.create_new_list}
                name="list_state"
                checked={listState === 1}
                size="sm"
                onChange={(e) => setListState(1)}
              />
              <TextInput
                id="new-list-name"
                name="blacklist_name"
                value={name}
                w={"100%"}
                disabled={listState !== 1}
                styles={{ label: { fontSize: 13 } }}
                label={lang.targeting_linkedin_google.blacklist_name}
                placeholder={lang.targeting_linkedin_google.blacklist_name}
                onChange={(e) => setName(e.target.value)}
                required={listState === 1}
              />
            </Stack>
            <Stack w="100%" gap={4}>
              <Radio
                label={lang.targeting_linkedin_google.blacklist_import}
                name="list_state"
                size="sm"
                checked={listState === 2}
                onChange={(e) => setListState(2)}
              />
              <CreatableBlacklistSelector
                value={list}
                setValue={setList}
                label={lang.targeting_linkedin_google.select_blacklist}
                placeholder={lang.contact_lists.create.dialog.desc}
                w={"100%"}
                disabled={listState !== 2}
                styles={{ label: { fontSize: 13 } }}
                required={listState === 2}
                disableCreate
                description={" "}
              />
            </Stack>
          </Group>

          <Group justify="center" mt={"lg"}>
            <Button
              disabled={listState === 1 ? !name : !list}
              mt={"md"}
              variant="gradient"
              leftSection={<IconFoldUp />}
              type="submit"
              size="md"
              w={300}
            >
              {lang.contact_lists.import.step_importing.import}
            </Button>
          </Group>
        </form>
      );
    }
  }, [
    data.length,
    error,
    finalData?.length,
    handleImport,
    importStats,
    lang,
    list,
    listState,
    name,
    onFinish,
  ]);

  return (
    <>
      {main}
      {!importStats && (
        <Group justify="center">
          <Button
            variant="subtle"
            onClick={handlePrev}
            leftSection={<IconChevronLeft />}
            size="md"
            w={300}
          >
            {lang.global.back}
          </Button>
        </Group>
      )}
    </>
  );
};

export default BlacklistImportStep;
