import { Flex, Group, Paper, SegmentedControl, Stack } from "@mantine/core";
import { IconCalendarRepeat, IconMailbox } from "@tabler/icons-react";
import { useMemo, useState } from "react";
import Delivers from "./Delivers";
import Senders from "./Senders";

const ReportingTables = ({ stats, loading }) => {
  const [tab, setTab] = useState("delivers");
  const tabs = useMemo(() => {
    return [
      {
        label: (
          <Group justify="center" wrap="nowrap">
            <IconMailbox size={18} />
            <span>Rapport de délivrabilité</span>
          </Group>
        ),
        value: "delivers",
      },
      {
        label: (
          <Group justify="center" wrap="nowrap">
            <IconCalendarRepeat size={18} />
            <span>Rapport des expéditeurs</span>
          </Group>
        ),
        value: "senders",
      },
    ];
  }, []);

  return (
    <Paper
      component={Stack}
      radius={"sm"}
      w={"100%"}
      bg={"white"}
      style={{
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
      }}
      gap={0}
      withBorder
      wrap="nowrap"
    >
      <SegmentedControl
        value={tab}
        onChange={setTab}
        data={tabs}
        w={"100%"}
        size="md"
        style={{
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
        }}
      />

      <Flex h={500}>
        {tab === "delivers" ? (
          <Delivers stats={stats} loading={loading} />
        ) : (
          <Senders />
        )}
      </Flex>
    </Paper>
  );
};

export default ReportingTables;
