import TagsSelector from "./TagsSelector";
import { useLanguage } from "../../stores/LanguageStore";
import { IconArrowsRandom } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useAccountsStore } from "../../stores/ConfigStore";
import { createPool, getPools } from "./../../api/EmailAPI";

const PoolsSelector = ({ value, setValue, type, autoFocus }) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(true);
  const data = useAccountsStore((s) =>
    type === "Email" ? s.email_pools : s.linkedin_pools
  );

  useEffect(() => {
    if (loading) {
      getPools().finally(() => setLoading(false));
    }
  }, [loading]);

  return (
    <TagsSelector
      placeholder={lang.global.enter_key_to_add}
      label={lang.pools.browser_tab_title}
      leftSection={<IconArrowsRandom size={16} />}
      loading={loading}
      createLabel={lang.tags.create_pool}
      data={data}
      createFunction={({ name }) => createPool({ name, type })}
      value={value}
      setValue={setValue}
      onDropdownOpen={() => setLoading(true)}
      onNewValueCreated={() => setLoading(true)}
      created_field_id={"pool_id"}
      autoFocus={autoFocus}
    />
  );
};

export default PoolsSelector;
