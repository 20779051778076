import {
  Box,
  Button,
  Collapse,
  Group,
  Menu,
  MenuItem,
  Stack,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import { Link } from "react-router-dom";

const HeaderTools = ({ item }) => {
  const [opened, { toggle }] = useDisclosure(false);

  return (
    <Box w={"100%"}>
      <MenuItem
        leftSection={item.icon}
        rightSection={
          opened ? <IconChevronUp size={16} /> : <IconChevronDown size={16} />
        }
        fz={13}
        w={"100%"}
        component={"div"}
        onClick={toggle}
        closeMenuOnClick={false}
      >
        <Group ml={8}>{item.name}</Group>
      </MenuItem>

      <Collapse in={opened}>
        <Stack ml={22} gap={0} style={{ borderLeft: "1px solid #00000012" }} >
          {item.children.map((x, i) => (
            <Menu.Item
              leftSection={x.icon}
              component={Link}
              to={x.url}
              key={x.url}
              fz={13}
            >
              <Group ml={8}>{x.name}</Group>
            </Menu.Item>
          ))}
        </Stack>
      </Collapse>
    </Box>
  );
};

export default HeaderTools;
