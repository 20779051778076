import { Group, Stack } from "@mantine/core";
import Path from "../../../../layouts/Path";
import ManualImport from "../../lists/import/manualImport/ManualImport";
import { useNavigate } from "react-router-dom";

const UnsubsImportPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <Group className="layout-block top" w={"100%"} justify="space-between">
        <Path />
      </Group>

      <Stack className="layout-block" pl={8} flex={1}>
        <ManualImport onFinish={() => navigate("..")} isUnsub />
      </Stack>
    </>
  );
};

export default UnsubsImportPage;
