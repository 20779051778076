import { useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { notifications } from "@mantine/notifications";
import { editStatus } from "../../../../../api/PRMAPI";
import { ActionIcon, Group, Popover, TextInput, Tooltip } from "@mantine/core";
import { IconDeviceFloppy, IconEdit } from "@tabler/icons-react";

const EditStatus = ({ id, statusName, handleRefresh, disabled }) => {
  const lang = useLanguage((s) => s.language);
  const [name, setName] = useState(statusName);
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setOpened(false);
  };

  const handleEdit = () => {
    setLoading(true);
    editStatus({ id, name, isSystem: typeof id === "string" })
      .then((r) => {
        notifications.show({ message: lang.prm.status.edited, color: "tertiary.8" });
        handleClose();
        handleRefresh("name", name);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Popover trapFocus withArrow opened={opened} onChange={setOpened} offset={0} zIndex={900}>
      <Popover.Target>
        <Tooltip label={lang.prm.status.rename} disabled={disabled}>
          <ActionIcon
            onClick={() => setOpened(true)}
            disabled={disabled}
            size={"sm"}
            variant="light"
          >
            <IconEdit size={16} />
          </ActionIcon>
        </Tooltip>
      </Popover.Target>

      <Popover.Dropdown>
        <Group align="end">
          <TextInput
            label={lang.prm.status.rename_name}
            placeholder={lang.prm.status.rename_name}
            autoFocus
            value={name}
            onChange={(e) => setName(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleEdit();
              }
            }}
          />
          <ActionIcon
            onClick={handleEdit}
            disabled={!name}
            loading={loading}
            size={"lg"}
          >
            <IconDeviceFloppy size={18} />
          </ActionIcon>
        </Group>
      </Popover.Dropdown>
    </Popover>
  );
};

export default EditStatus;
