import { useCallback, useState } from "react";
import { getFirstPageUsers, searchUser } from "../../api/UserAPI";
import MultiSelector from "../Selectors/MultiSelector";

function getOptionName(item) {
  return item?.name || `${item.first_name} ${item.last_name}`;
}

const UsersSelector = ({
  label,
  placeholder,
  size,
  values,
  onChange,
  disabled,
  leftSection,
  inputProps,
  inputStyles,
}) => {
  const [lists, setLists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState(
    lists.map((user) => ({
      ...user,
      option_name: getOptionName(user),
    }))
  );

  const handleLoad = () => {
    setLoading(true);
    getFirstPageUsers({
      sort: {
        field_name: "id",
        sort_direction: "desc",
      },
    })
      .then((res) => {
        if (res.data.state) {
          let newOptions = res.data.results.map((user) => ({
            ...user,
            id: user.id,
            option_name: user.first_name + " " + user.last_name,
          }));

          setLists(newOptions);
          setOptions(newOptions);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onDebouncedChange = useCallback(
    (debounced) => {
      if (debounced.length > 1) {
        setLoading(true);
        searchUser({ query: debounced + "" })
          .then((res) => {
            let newOptions = res.data.results.map((user) => ({
              ...user,
              id: user.id,
            }));
            setOptions(
              newOptions.map((user) => ({
                ...user,
                option_name: getOptionName(user),
              }))
            );
          })
          .finally(() => setLoading(false));
      } else {
        setOptions(lists);
        setLoading(false);
      }
    },
    [lists]
  );

  return (
    <MultiSelector
      options={options}
      label={label}
      onDropdownOpen={handleLoad}
      loading={loading}
      values={values}
      onChange={onChange}
      placeholder={placeholder}
      leftSection={leftSection}
      rightOptionSection={(item) => {
        return (
          <span style={{ fontSize: 10, opacity: 0.35, width: 30 }}>
            #{item?.id}
          </span>
        );
      }}
      size={size}
      disabled={disabled}
      w={"100%"}
      searchable
      checkIconPosition="left"
      withDropdown
      getValidOption={(o) => o?.activated}
      inputProps={inputProps}
      inputStyles={inputStyles}
      option_name_props={{
        w: "70%",
      }}
      maxInputHeight={"none"}
      onDebouncedChange={onDebouncedChange}
    />
  );
};

export default UsersSelector;
