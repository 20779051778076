import { useState } from "react";
import { useLanguage } from "../../../stores/LanguageStore";
import { ActionIcon, Anchor, CopyButton, Group, Tooltip } from "@mantine/core";
import { IconEye, IconEyeOff } from "@tabler/icons-react";

export const CommCode = ({ token }) => {
  const lang = useLanguage((s) => s.language);
  const [visible, setVisible] = useState(false);

  return (
    <CopyButton value={token}>
      {({ copied, copy }) => (
        <Group pos="relative" w={"100%"} wrap="nowrap">
          {visible ? (
            <>
              <Tooltip
                label={copied ? lang.global.copied : lang.global.copy}
                color={copied ? "green" : "black"}
              >
                <Anchor size="sm" onClick={copy} truncate="end" >
                  {token}
                </Anchor>
              </Tooltip>

              <Tooltip label={lang.teams.code.hide}>
                <ActionIcon onClick={() => setVisible(false)} variant="light">
                  <IconEyeOff size={18} />
                </ActionIcon>
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip
                label={copied ? lang.global.copied : lang.global.copy}
                color={copied ? "green" : "black"}
              >
                <Anchor size="sm" onClick={copy} truncate="end">
                  ----------
                </Anchor>
              </Tooltip>

              <Tooltip label={lang.teams.code.show}>
                <ActionIcon onClick={() => setVisible(true)} variant="light">
                  <IconEye size={18} />
                </ActionIcon>
              </Tooltip>
            </>
          )}
        </Group>
      )}
    </CopyButton>
  );
};
