import { useEffect } from "react";
import { useSessionStore } from "./stores/UserStore";

const CookieHandler = () => {
  const session = useSessionStore((s) => s.session);
  useEffect(() => {
    function setCookie(name, value) {
      // Convert the object to a JSON string
      var cookieValue = JSON.stringify(value);

      // Set the cookie with the JSON string
      var cookieString =
        name + "=" + encodeURIComponent(cookieValue) + "; path=/";
      document.cookie = cookieString;
    }

    setCookie("session", session);
  }, [session]);
  return "";
};

export default CookieHandler;
