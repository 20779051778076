import { Link, useMatches } from "react-router-dom";
import { Anchor, Flex, Breadcrumbs as Main, Tooltip } from "@mantine/core";
import {
  IconArrowsRandom,
  IconChevronRight,
  IconFolder,
  IconHome2,
} from "@tabler/icons-react";

const Path = () => {
  let matches = useMatches();
  let crumbs = matches
    .filter((match) => Boolean(match?.handle))
    .map((match) => ({
      name: match?.handle?.name || match?.handle?.getName(match?.data),
      url: match?.pathname,
      isFolder: match?.handle?.isFolder,
    }));

  return (
    <Main separator={<IconChevronRight size={16} />}>
      <Anchor component={Link} className={"crumb"} to={"/"} underline="never">
        <IconHome2 size={14} />
      </Anchor>
      {crumbs.map((crumb, index) => (
        <Flex key={crumb.url} className={"crumb"} align={"center"} gap={4}>
          {crumb.isFolder && <IconFolder size={14} />}
          {crumb.isPool && <IconArrowsRandom size={14} />}
          <Tooltip label={crumb?.name} withArrow openDelay={300}>
            <Anchor
              component={Link}
              to={crumb.url}
              size="xs"
              underline="never"
              fw={650}
              truncate="end"
              maw={200}
            >
              {crumb?.name}
            </Anchor>
          </Tooltip>
        </Flex>
      ))}
    </Main>
  );
};

export default Path;
