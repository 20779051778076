import { useEffect, useMemo, useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import {
  IconBrandGoogleMaps,
  IconBriefcase,
  IconBuildingCommunity,
  IconFriends,
  IconMessageCircle,
  IconSparkles,
  IconThumbUp,
  IconUsersGroup,
} from "@tabler/icons-react";
import { Stack } from "@mantine/core";
import LinkedinContacts from "./LinkedinContacts";
import LinkedinComments from "./LinkedinComments";
import LinkedinLikes from "./LinkedinLikes";
import LinkedinJobs from "./LinkedinJobs";
import LinkedinConnections from "./LinkedinConnections";
import QuickImport from "../quickImport/QuickImport";
import TargetingGoogleMaps from "../googleMaps/TargetingGoogleMaps";
import LinkedinCompany from "./LinkedinCompany";

const TargetingLinkedin = ({ listId, onFinish, defaultMethod }) => {
  const lang = useLanguage((s) => s.language);

  const tabs = useMemo(
    () => [
      {
        value: "quick",
        label: "Import Rapide",
        icon: <IconSparkles size={21} />,
        component: <QuickImport listId={listId} onFinish={onFinish} />,
      },
      {
        value: "linkedin",
        label: lang.targeting_linkedin_google.tabs_title.contacts,
        icon: <IconUsersGroup size={21} />,
        component: <LinkedinContacts listId={listId} onFinish={onFinish} />,
      },
      {
        value: "linkedin-alt",
        label: lang.targeting_linkedin_google.tabs_title.contacts,
        icon: <IconUsersGroup size={21} />,
        component: <LinkedinContacts isAlt listId={listId} onFinish={onFinish} />,
      },
      {
        value: "likers",
        label: lang.targeting_linkedin_google.tabs_title.likes,
        icon: <IconThumbUp size={21} />,
        component: <LinkedinLikes listId={listId} onFinish={onFinish} />,
      },
      {
        value: "commenters",
        label: lang.targeting_linkedin_google.tabs_title.comments,
        icon: <IconMessageCircle size={21} />,
        component: <LinkedinComments listId={listId} onFinish={onFinish} />,
      },
      {
        value: "jobs",
        label: lang.targeting_linkedin_google.tabs_title.works,
        icon: <IconBriefcase size={21} />,
        component: <LinkedinJobs listId={listId} onFinish={onFinish} />,
      },
      {
        value: "connections",
        label: lang.targeting_linkedin_google.tabs_title.connection,
        icon: <IconFriends size={21} />,
        component: <LinkedinConnections listId={listId} onFinish={onFinish} />,
      },
      {
        value: "companies",
        label: lang.targeting_linkedin_google.tabs_title.companies,
        icon: <IconBuildingCommunity size={21} />,
        component: <LinkedinCompany listId={listId} onFinish={onFinish} />,
      },
      {
        value: "google",
        label: "Google Maps",
        icon: <IconBrandGoogleMaps size={21} />,
        component: <TargetingGoogleMaps listId={listId} onFinish={onFinish} />,
      },
    ],
    [lang, listId, onFinish]
  );
  const [active, setActive] = useState(defaultMethod || tabs[0]?.value);

  useEffect(() => {
    setActive(defaultMethod);
  }, [defaultMethod]);

  return (
    <Stack w={"100%"} h={525}>
      {tabs.find((x) => x.value === active)?.component}
    </Stack>
  );
};

export default TargetingLinkedin;
