import { ActionIcon } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { EdgeLabelRenderer, getBezierPath } from "reactflow";

const TrailEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  target,
  source,
  sourcePosition,
  targetPosition,
  data,
}) => {
  const edgeId = `e-${source}-${target}`;
  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <path
      id={edgeId}
      className={"react-flow__edge-path"}
      d={edgePath}
      style={{
        stroke: data?.color,
        strokeWidth: 1.2,
        strokeDasharray: 6,
      }}
    />
  );
};
export default TrailEdge;
