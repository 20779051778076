import { useLeadsSheetStore } from "../../../../../stores/LeadsStore";
import { ActionIcon, Indicator, Paper, Stack } from "@mantine/core";
import {
  IconArrowDown,
  IconArrowUp,
  IconBuilding,
  IconBuildingCommunity,
  IconX,
} from "@tabler/icons-react";

const LeadSheetController = () => {
  const prevProspect = useLeadsSheetStore((s) => s.prevProspect);
  const nextProspect = useLeadsSheetStore((s) => s.nextProspect);
  const handleClose = useLeadsSheetStore((s) => s.handleClose);
  const index = useLeadsSheetStore((s) => s.index);
  const currentContainer = useLeadsSheetStore((s) => s.currentContainer);
  const loading = useLeadsSheetStore((s) => s.loading);
  const orgProspected = useLeadsSheetStore((s) => s.orgProspected);
  const current = useLeadsSheetStore((s) => s.current);
  const openedOrg = useLeadsSheetStore((s) => s.openedOrg);
  const setOpenedOrg = useLeadsSheetStore((s) => s.setOpenedOrg);

  return (
    <Stack pos={"absolute"} top={9} left={-64}>
      <Paper radius={"md"}>
        <ActionIcon.Group orientation="vertical">
          <ActionIcon
            variant="subtle"
            size={"xl"}
            color="gray"
            radius={"md"}
            onClick={handleClose}
          >
            <IconX />
          </ActionIcon>

          <ActionIcon
            variant="subtle"
            size={"xl"}
            color="gray"
            radius={"md"}
            disabled={loading || index <= 0}
            onClick={prevProspect}
          >
            <IconArrowUp />
          </ActionIcon>

          <ActionIcon
            variant="subtle"
            size={"xl"}
            color="gray"
            radius={"md"}
            disabled={loading || index >= currentContainer?.items?.length - 1}
            onClick={nextProspect}
          >
            <IconArrowDown />
          </ActionIcon>
        </ActionIcon.Group>
      </Paper>
      <Paper radius={"md"}>
        <Indicator
          offset={12}
          color="red"
          withBorder
          styles={{
            indicator: {
              width: 12,
              height: 12,
            },
          }}
          disabled={!orgProspected}
          size={16}
        >
          <ActionIcon
            variant="subtle"
            size={"xl"}
            color="gray"
            radius={"md"}
            disabled={
              loading ||
              !current?.properties?.find(
                (x) => x.identifier_placeholder === "%company%"
              )
            }
            onClick={() => setOpenedOrg(!openedOrg)}
          >
            <IconBuildingCommunity />
          </ActionIcon>
        </Indicator>
      </Paper>
    </Stack>
  );
};

export default LeadSheetController;
