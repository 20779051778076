import {
  editGmail,
  editMassMailing,
  editOutlook,
  editSMTP,
} from "../../../api/EmailAPI";

export function getEmailTypeProps(type) {
  if (type === "MassMailing") {
    return {
      editFunction: editMassMailing,
      name: "Mass Mailing",
      path: "massmailing",
    };
  }
  if (type === "SMTP") {
    return {
      editFunction: editSMTP,
      name: "SMTP",
      path: "smtp",
    };
  }
  if (type === "Outlook") {
    return {
      editFunction: editOutlook,
      name: "Outlook",
      path: "outlook",
    };
  }
  if (type === "Gmail") {
    return {
      editFunction: editGmail,
      name: "Gmail",
      path: "gmail",
    };
  }
}
