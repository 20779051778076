import { useMemo, useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { extractGSearch } from "../../../../../api/TargetingGoogleAPI";
import { notifications } from "@mantine/notifications";
import {
  Button,
  Center,
  Divider,
  Group,
  Input,
  NumberInput,
  Radio,
  Stack,
  Text,
  TextInput,
  Timeline,
  TimelineItem,
  Title,
} from "@mantine/core";
import { IconListDetails, IconPlus, IconSparkles } from "@tabler/icons-react";
import TargetingGoogleUrlItem from "./TargetingGoogleUrlItem";
import { useRouteLoaderData } from "react-router-dom";
import CreatableContactListSelector from "../../../../../components/Selectors/CreatableContactListSelector";
import ImportWelcome from "../ImportWelcome";
import { useSessionStore } from "../../../../../stores/UserStore";

const TargetingGoogleMaps = ({ onFinish, setShouldWarn }) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  //#region Items
  const [links, setLinks] = useState([]);
  const addLink = (value) => {
    const regex = /^https:\/\/www\.google\.[a-z]+\/maps\/search\//;
    if (!regex.test(value)) {
      return notifications.show({
        message: lang.targeting_linkedin_google.bad_link,
        color: "red",
      });
    }
    if (links.includes(value)) {
      return notifications.show({
        message: lang.targeting_linkedin_google.link_already_exist,
        color: "red",
      });
    }
    setLinks((prev) => [...prev, value]);
    document.getElementById("url-search").value = "";
    document.getElementById("url-search").focus();
  };

  const removeLink = (value) => {
    setLinks((prev) => prev.filter((x) => x !== value));
  };
  //#endregion

  const listDataLoader = useRouteLoaderData("contact-list");
  const listDataFolderLoader = useRouteLoaderData("folder-contact-list");
  const listProfile = useMemo(() => {
    return listDataLoader || listDataFolderLoader;
  }, [listDataLoader, listDataFolderLoader]);
  const [list, setList] = useState(listProfile);
  const [listState, setListState] = useState(listProfile ? 2 : 1);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    let f = new FormData(e.target);
    let data = Object.fromEntries(f);

    data = {
      ...data,
      contact_list_name: listState === 1 ? data?.contact_list_name : null,
      contact_list_id: listState === 2 ? list?.id : null,
      max_results: parseInt(data?.max_results),
      google_maps_search_urls:
        links.length < 2 ? [data.google_map_search_url] : links,
    };

    extractGSearch(data)
      .then((response) => {
        onFinish?.(response.data.contact_list_id);
      })
      .catch((err) => {
        if (err.response.data.state_message === "empty_name") {
          document.getElementById("new-list-name").focus();
        }
        if (err.response.data.state_message === "invalid_linkedin_post_url ") {
          document.getElementById("url-search").focus();
        }
        setError(err.response.data.state_message);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Group
      px={"sm"}
      justify="space-between"
      h={"100%"}
      align="start"
      wrap="nowrap"
      w={"100%"}
      gap={48}
    >
      <ImportWelcome
        title={lang.targeting_linkedin_google.google_maps}
        type={"linkedin_peoples_welcome"}
        video_url={
          "https://www.youtube.com/embed/xbWqnjxl6kY?si=sMdI8L_lURbXq95B"
        }
      />
      <Stack
        component={"form"}
        onSubmit={handleSubmit}
        h={"100%"}
        onChange={() => setShouldWarn?.(true)}
        w={"50%"}
      >
        <Title order={5}>{lang.targeting_linkedin_google.google_maps}</Title>

        <Timeline
          styles={{
            item: {
              marginBottom: -12,
            },
            itemContent: {
              marginLeft: 8,
            },
            itemBullet: {
              top: 6,
            },
          }}
          lineWidth={2}
          mb={16}
          bulletSize={12}
          color="tertiary.8"
        >
          <TimelineItem mt={-8}>
            {links.length === 0 ? (
              <TextInput
                label={lang.targeting_linkedin_google.search_url}
                id="url-search"
                name="google_map_search_url"
                autoFocus
                placeholder="https://www.google.com/maps/search/..."
                error={error === "invalid_google_map_search_url"}
                type="url"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    addLink(document.getElementById("url-search").value);
                  }
                }}
                rightSection={
                  <Button
                    leftSection={<IconPlus />}
                    variant="subtle"
                    onClick={() =>
                      addLink(document.getElementById("url-search").value)
                    }
                    ml={"auto"}
                    disabled={links.length > 9}
                  >
                    {lang.targeting_linkedin_google.add}
                  </Button>
                }
                rightSectionWidth={110}
              />
            ) : (
              <Stack>
                <TextInput
                  label={lang.targeting_linkedin_google.search_url}
                  id="url-search"
                  autoFocus
                name="google_map_search_url"
                  placeholder="https://www.google.com/maps/search/..."
                  error={error === "invalid_google_map_search_url"}
                  type="url"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      addLink(document.getElementById("url-search").value);
                    }
                  }}
                  rightSection={
                    <Button
                      leftSection={<IconPlus />}
                      variant="subtle"
                      onClick={() =>
                        addLink(document.getElementById("url-search").value)
                      }
                      ml={"auto"}
                      disabled={links.length > 9}
                    >
                      {lang.targeting_linkedin_google.add}
                    </Button>
                  }
                  rightSectionWidth={110}
                />

                <Stack gap={0}>
                  <Group w={"100%"}>
                    <Input.Label>
                      {links.length}{" "}
                      {lang.targeting_linkedin_google.added_links}{" "}
                      <span style={{ opacity: 0.45, fontSize: 10 }}>
                        {lang.targeting_linkedin_google.max_10}
                      </span>
                    </Input.Label>
                  </Group>

                  <Stack
                    style={{
                      border: "1px solid #00000021",
                      overflow: "auto",
                      borderRadius: 4,
                    }}
                    h={128}
                    p={"xs"}
                    gap={"xs"}
                    mt={6}
                  >
                    {links.length > 0 ? (
                      links.map((link) => (
                        <TargetingGoogleUrlItem
                          value={link}
                          key={link}
                          handleDelete={() => removeLink(link)}
                        />
                      ))
                    ) : (
                      <Text opacity={0.5} size="sm">
                        {lang.targeting_linkedin_google.no_links}
                      </Text>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            )}
          </TimelineItem>

          <TimelineItem>
            <NumberInput
              name="max_results"
              label={lang.targeting_linkedin_google.max_results}
              required
              min={1}
              max={200}
              clampBehavior="strict"
              description="Max: 200"
              defaultValue={200}
              w={"100%"}
              allowNegative={false}
              allowDecimal={false}
            />
          </TimelineItem>

          <TimelineItem>
            <Group wrap="nowrap" align="end" gap={"xl"}>
              <Stack w="100%" gap={8}>
                <Radio
                  label={lang.targeting_linkedin_google.create_new_list}
                  name="list_state"
                  checked={listState === 1}
                  size="sm"
                  onChange={(e) => setListState(1)}
                />
                <TextInput
                  id="new-list-name"
                  name="contact_list_name"
                  w={"100%"}
                  disabled={listState !== 1}
                  styles={{ label: { fontSize: 13 } }}
                  label={lang.targeting_linkedin_google.list_name}
                  description={lang.contact_lists.create.dialog.desc}
                  placeholder={lang.targeting_linkedin_google.list_name}
                  required={listState === 1}
                />
              </Stack>
              <Stack w="100%" gap={8}>
                <Radio
                  label={lang.targeting_linkedin_google.import}
                  name="list_state"
                  size="sm"
                  checked={listState === 2}
                  onChange={(e) => setListState(2)}
                />
                <CreatableContactListSelector
                  value={list}
                  setValue={setList}
                  label={lang.targeting_linkedin_google.select_list}
                  placeholder={lang.contact_lists.create.dialog.desc}
                  w={"100%"}
                  disabled={listState !== 2}
                  styles={{ label: { fontSize: 13 } }}
                  required={listState === 2}
                  leftSection={<IconListDetails size={16} />}
                  disableCreate
                  description={lang.contact_lists.create.dialog.desc}
                />
              </Stack>
            </Group>

            <Group justify="center" mt={"lg"}>
              <Button
                leftSection={<IconSparkles />}
                loading={loading}
                type="submit"
                w={250}
              >
                {lang.targeting_linkedin_google.submit_button_google}
              </Button>
            </Group>
          </TimelineItem>
        </Timeline>
      </Stack>

      {useSessionStore.getState().reseller?.name === "Magileads" && (
        <>
          <Divider orientation="vertical" />

          <Center h={"100%"} pr={"lg"}>
            <iframe
              width="480"
              height="400"
              src="https://www.youtube.com/embed/xbWqnjxl6kY?si=sMdI8L_lURbXq95B"
              title="YouTube video player"
              allowfullscreen=""
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              style={{
                borderRadius: 12,
                overflow: "hidden",
                border: "1px solid #00000033",
              }}
            />
          </Center>
        </>
      )}
    </Group>
  );
};

export default TargetingGoogleMaps;
