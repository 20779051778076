import { useState } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useDisclosure } from "@mantine/hooks";
import {
  ActionIcon,
  Button,
  Group,
  Modal,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import BackButton from "../../../../components/View/BackButton";
import { IconRefreshDot } from "@tabler/icons-react";
import { refreshTargetingGoogle } from "../../../../api/ContactAPI";
import { notifications } from "@mantine/notifications";
import { IconGmaps } from "../../../../components/Icons/IconGmaps";

const ListRefreshTargetingGoogle = ({
  current,
  onFinish,
  closeMenu,
  inJob,
}) => {
  const lang = useLanguage((s) => s.language);
  const [opened, { open, close }] = useDisclosure();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    refreshTargetingGoogle({
      contact_list_id: current.id,
    })
      .then((res) => {
        notifications.show({
          message: lang.contact_lists.refresh_targeting.success,
          color: "tertiary.8",
        });
        close();
        onFinish?.();
      })
      .finally(() => setLoading(false));
  };

  const handleClose = () => {
    close();
    closeMenu?.();
  };

  if (!current.state_details.find((x) => x.type.includes("oog"))) {
    return "";
  }
  return (
    <>
      {inJob ? (
        <Tooltip label={lang.contact_lists.refresh_targeting.redo_google}>
          <ActionIcon variant="subtle" onClick={open}>
            <IconRefreshDot size={18} />
          </ActionIcon>
        </Tooltip>
      ) : (
        <Button
          onClick={open}
          leftSection={<IconGmaps size={18} />}
          fw={600}
          color="primary"
          variant="subtle"
          justify="start"
        >
          {lang.contact_lists.refresh_targeting.label_google}
        </Button>
      )}

      {opened && (
        <Modal
          opened={opened}
          size={"lg"}
          onClose={handleClose}
          title={
            <>
              {lang.contact_lists.refresh_targeting.dialog.title_google}{" "}
              <b>
                {current?.name} #{current.id}
              </b>
            </>
          }
        >
          <Stack component={"form"} onSubmit={handleSubmit} gap={"xs"}>
            <Text c={"dimmed"}>
              {lang.contact_lists.refresh_targeting.dialog.text_google}
            </Text>

            <Group justify="end" mt={"md"}>
              <BackButton onClick={handleClose} />

              <Button
                leftSection={<IconRefreshDot size={18} />}
                loading={loading}
                color="primary"
                type="submit"
              >
                {lang.contact_lists.refresh_targeting.dialog.submit_button}
              </Button>
            </Group>
          </Stack>
        </Modal>
      )}
    </>
  );
};

export default ListRefreshTargetingGoogle;
