import React, { useState } from "react";
import { createStatus } from "../../../../../api/PRMAPI";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { notifications } from "@mantine/notifications";
import {
  ActionIcon,
  Button,
  Group,
  Popover,
  Stack,
  TextInput,
} from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";

const CreateStatus = ({ handleDone }) => {
  const lang = useLanguage((s) => s.language);
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setOpened(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    let formData = new FormData(e.target);
    let data = Object.fromEntries(formData);

    createStatus({ name: data.name })
      .then((response) => {
        handleClose();
        handleDone(response.data.status_id, data.name);
        notifications.show({
          message: lang.prm.status.created,
          color: "tertiary.8",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Popover opened={opened} onChange={setOpened} withArrow trapFocus zIndex={1600}>
      <Popover.Target>
        <Button
          onClick={() => setOpened(true)}
          color="tertiary.8"
          leftSection={<IconPlus size={18} />}
          variant="light"
          mr={"auto"}
        >
          {lang.prm.status.create_label}
        </Button>
      </Popover.Target>

      <Popover.Dropdown p={"xs"} pt={4}>
        <Group component="form" onSubmit={onSubmit} align="end">
          <TextInput
            data-autoFocus
            name="name"
            label={lang.prm.status.create_name}
            required
          />

          <ActionIcon type="submit" loading={loading} color="tertiary.8" size={"lg"}>
            <IconPlus size={21} />
          </ActionIcon>
        </Group>
      </Popover.Dropdown>
    </Popover>
  );
};

export default CreateStatus;
