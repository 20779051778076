import { Anchor, Button, Group, Popover, Stack, Text } from "@mantine/core";
import { Link, useMatches } from "react-router-dom";
import classes from "./NavbarLinksGroup/NavbarLinksGroup.module.css";
import { IconChevronRight } from "@tabler/icons-react";
import { useState } from "react";

const NavItemMenu = ({ item, menuPosition }) => {
  const matches = useMatches();
  const [opened, setOpened] = useState(false);

  if (item.children) {
    return (
      <Popover
        position={menuPosition || "right"}
        withArrow
        offset={0}
        opened={opened}
        onChange={setOpened}
      >
        <Popover.Target>
          <Anchor
            className={`${classes.link} nav-link`}
            style={{
              color: matches.find((x) => x.pathname.includes(item.path))
                ? "var(--mantine-primary-color-filled)"
                : "var(--mantine-color-dark-3)",
              background: matches.find((x) => x.pathname.includes(item.path))
                ? "var(--mantine-primary-color-0)"
                : undefined,
              alignItems: "center",
            }}
            underline="never"
            display={"flex"}
            pos={"relative"}
            onClick={() => setOpened(!opened)}
          >
            <Group
              wrap="nowrap"
              w={"100%"}
              id={item.id}
              pl={64}
              pr={"sm"}
              py={"xs"}
            >
              <Text
                size="sm"
                w={"80%"}
                display={"flex"}
                style={{ alignItems: "center" }}
                fw={
                  matches.find((x) => x.pathname.includes(item.path))
                    ? 650
                    : 550
                }
              >
                {item.name}
              </Text>
              <IconChevronRight
                color="gray"
                size={14}
                style={{
                  position: "absolute",
                  right: 8,
                  top: "50%",
                  transform: "translateY(-50%)",
                  zIndex: 9,
                }}
              />
            </Group>
          </Anchor>
        </Popover.Target>

        <Popover.Dropdown p={4} bg={"#fff"}>
          <Stack gap={0}>
            {item.children.map((child) => (
              <Anchor
                key={child.path}
                component={Link}
                to={item.path + child.path}
                underline="never"
                onClick={() => setOpened(false)}
              >
                <Button
                  w={"100%"}
                  variant="subtle"
                  leftSection={child.icon}
                  justify="start"
                >
                  {child.name}
                </Button>
              </Anchor>
            ))}
          </Stack>
        </Popover.Dropdown>
      </Popover>
    );
  }

  return (
    <Anchor
      className={`${classes.link} nav-link`}
      component={Link}
      to={item?.path || undefined}
      style={{
        color: matches.find((x) => x.pathname.includes(item.path))
          ? "var(--mantine-primary-color-filled)"
          : "var(--mantine-color-dark-3)",
        // borderLeft: matches.find((x) => x.pathname.includes(item.path))
        //   ? "6px solid var(--mantine-primary-color-filled)"
        //   : undefined,
        background: matches.find((x) => x.pathname.includes(item.path))
          ? "var(--mantine-color-primary-0)"
          : undefined,
      }}
      underline="never"
      fw={matches.find((x) => x.pathname.includes(item.path)) ? 650 : 550}
      display={"flex"}
    >
      <Group pl={64} id={item.id} w={"100%"} pr={"sm"} py={"xs"}>
        {item.name}
      </Group>
    </Anchor>
  );
};

export default NavItemMenu;
