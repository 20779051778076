import { Stack, darken, lighten } from "@mui/material";
import { useLanguage } from "../../stores/LanguageStore";
import { Editor } from "@tinymce/tinymce-react";
import { useEffect, useState } from "react";
import { Group, Loader, Skeleton, Text } from "@mantine/core";
// TinyMCE so the global var exists
import "tinymce/tinymce";
// DOM model
import "tinymce/models/dom/model";
// Theme
import "tinymce/themes/silver";
// Toolbar icons
import "tinymce/icons/default";
// Editor styles
import "tinymce/skins/ui/oxide/skin";

// importing the plugin js.
// if you use a plugin that is not listed here the editor will fail to load
import "tinymce/plugins/autolink";
import "tinymce/plugins/autoresize";
import "tinymce/plugins/code";
import "tinymce/plugins/importcss";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
import "tinymce/plugins/media";
import "tinymce/plugins/preview";
import "tinymce/plugins/quickbars";
import "tinymce/plugins/table";
import "tinymce/plugins/lists";

// importing plugin resources
// import "tinymce/plugins/emoticons/js/emojis";

// Content styles, including inline UI like fake cursors
import "tinymce/skins/content/default/content";
import "tinymce/skins/ui/oxide/content";
import AddTokens from "../Adder/AddTokens";
import { useSessionStore } from "../../stores/UserStore";
import { wrapDatafieldValue } from "../../functions/utilFunctions";

function getCharacterCount(editor, defaultText) {
  let encoder = new TextEncoder("utf-8");
  let text = editor ? editor.getContent({ format: "text" }) : defaultText;

  return encoder.encode(text)?.length || 0;
}

function handleCount(event, editor) {
  let element = document.getElementById("char-count");

  if (element) element.innerHTML = getCharacterCount(editor);
}

const TextEditor = ({
  id,
  height = 300,
  initialValue,
  placeholder,
  tokens,
  toolbar,
  defaultText,
  noTop,
  onInit = () => {},
  autoFocus,
  editorRef,
  onKeyUp,
  maxChars,
  children,
  autoSize,
  disableDecor,
}) => {
  const lang = useLanguage((s) => s.language);
  const [initialized, setInitialized] = useState(false);

  return (
    <Stack
      sx={{
        "& .tox-tbtn--enabled": {
          backgroundColor: (theme) =>
            lighten(theme.palette.primary.light, 0.2) + "!important",
        },
        "& .tox-tbtn[title='Variables']": {
          backgroundColor: (theme) =>
            darken(theme.palette.primary.light, 0.1) + "!important",
          color: "white",
          fontWeight: 600,
          width: "100px !important",
          svg: {
            fill: "white",
          },
        },
        "& .tox-tbtn--enabled:hover": {
          backgroundColor: (theme) =>
            lighten(theme.palette.primary.light, 0.5) + "!important",
        },
        "& .tox.tox-edit-focus .tox-edit-area::before": {
          opacity: "0 !important",
        },
        "& .tox-tbtn:hover": {
          backgroundColor: (theme) =>
            lighten(theme.palette.primary.light, 0.5) + "!important",
        },
        "& .tox-tbtn:focus": {
          backgroundColor: (theme) =>
            lighten(theme.palette.primary.light, 0.5) + "!important",
        },
        "& .tox-split-button:focus": {
          backgroundColor: (theme) =>
            lighten(theme.palette.primary.light, 0.5) + "!important",
        },
        "& .tox-split-button:hover": {
          boxShadow: (theme) =>
            `0 0 0 1px ${theme.palette.primary.light} inset !important`,
        },
        "& .tox-editor-header": {
          boxShadow: autoSize
            ? "#00000006 2px 0 6px !important"
            : "#00000018 2px 0 6px !important",
          borderBottom: "1px solid #00000018 !important",
        },
        "& .tox-edit-area": {
          cursor: "text",
        },
        "& .tox .tox-statusbar": {
          borderTop: 0,
        },
        "& .tox .tox-toolbar__group": {
          px: 0.5,
        },
        // "& .tox .tox-toolbar__primary": {
        //   "& .tox-toolbar__group:first-child .tox-tbtn:nth-child(2)": {
        //     mr: 14,
        //   },
        // },
        "& .tox .tox-menubar button": {
          visibility: "hidden",
        },
        "& .tox .tox-statusbar__help-text": {
          display: "none",
        },
        "& .tox:not(.tox-tinymce-inline) .tox-editor-header": {
          p: 0,
        },
        "& .tox-promotion": {
          display: "none",
        },
        "& .tox-tinymce": {
          border: disableDecor ? "none" : "1px solid #00000018",
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        },
        position: "relative",
      }}
      height={autoSize ? undefined : height}
      flex={"1 1 auto"}
    >
      {!initialized && (
        <>
          <Stack
            pos={"absolute"}
            top={0}
            left={0}
            right={0}
            bottom={0}
            style={{
              zIndex: 9,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
            radius={8}
          >
            <Stack pos={"relative"} w={"100%"} h={"100%"}>
              <Skeleton w={"100%"} h={"100%"} pos={"absolute"} />
              <Loader
                type="dots"
                pos={"absolute"}
                top={"50%"}
                left={"50%"}
                style={{ transform: "translate(-50%)" }}
              />
            </Stack>
          </Stack>
        </>
      )}
      <Editor
        apiKey="44hm9i6dkxdciqkfw8sk62401gixfwp7lgqfadkd5qa4u8en"
        id={id}
        onInit={(evt, editor) => {
          editorRef.current = editor;
          editor.on("keyup", () => {
            onKeyUp?.();
          });
          editor.execCommand("fontName", false, "Arial");
          setInitialized(true);
          if (autoFocus) {
            setTimeout(() => {
              editor.focus();
            }, 50);
          }
        }}
        initialValue={initialValue}
        init={{
          height: "100%",
          skin_url: `https://application.magileads.io/tinymce`,
          min_height: 0,
          elementpath: false,
          language: lang.id === "FRA" ? "fr_FR" : null,
          language_url: lang.id === "FRA" ? "/tinymce_fr.js" : null,
          inline_styles: false,
          plugins:
            "preview importcss autolink code link media table insertdatetime lists quickbars " +
            (autoSize ? "autoresize" : ""),
          menubar: noTop ? "" : "tools",
          // menubar: !noTop && "edit view insert format tools table",
          toolbar:
            toolbar ||
            "undo redo | bold italic underline | forecolor backcolor link | fontfamily | fontsize | blocks | alignleft aligncenter alignright alignjustify | numlist bullist | code table",
          toolbar_sticky: true,
          toolbar_mode: "floating",
          font_size_formats: "8px 10px 12px 14px 16px 18px 24px 36px",
          relative_urls: false,
          remove_script_host: false,
          convert_urls: false,
          autoresize_bottom_margin: 0,
          // table_sizing_mode: 'fixed',
          font_family_formats: `
      Arial=arial,helvetica,sans-serif;
      Andale Mono=andale mono,times;
      Arial Black=arial black,avant garde;
      Book Antiqua=book antiqua,palatino;
      Comic Sans MS=comic sans ms,sans-serif;
      Courier New=courier new,courier;
      Dancing Script = dancing script;
      Georgia=georgia,palatino;
      Helvetica=helvetica;
      Impact=impact,chicago;
      Indie Flower=indie flower;
      Libre Barcode 39 Extended=Libre Barcode 39 Extended;
      Libre Barcode 128=Libre Barcode 128;
      Open Sans=open sans;
      Poppings=poppins;
      Pacifico=pacifico;
      Rubik=rubik;
      Roboto=roboto;
      Symbol=symbol;
      Tahoma=tahoma,arial,helvetica,sans-serif;
      Terminal=terminal,monaco;
      Times New Roman=times new roman,times;
      Trebuchet MS=trebuchet ms,geneva;
      Ubuntu=ubuntu;
      Verdana=verdana,geneva
      `,
          content_style: `
      @import url('https://fonts.googleapis.com/css2?family=Roboto');
      @import url('https://fonts.googleapis.com/css2?family=Open+Sans');
      @import url('https://fonts.googleapis.com/css2?family=Poppins');
      @import url('https://fonts.googleapis.com/css2?family=Ubuntu');
      @import url('https://fonts.googleapis.com/css2?family=Rubik');

      @import url('https://fonts.googleapis.com/css2?family=Dancing+Script');
      @import url('https://fonts.googleapis.com/css2?family=Pacifico');
      @import url('https://fonts.googleapis.com/css2?family=Indie+Flower');

      @import url('https://fonts.googleapis.com/css2?family=Libre+Barcode+39+Extended');
      @import url('https://fonts.googleapis.com/css2?family=Libre+Barcode+128');
      body {
        font - family: Helvetica,Arial,sans-serif;
      font-size:14px;
            }
      .var {
          background: #${useSessionStore.getState().reseller.color_primary}33;
      }
      `,
          placeholder,
          newline_behavior: "default",
          quickbars_insert_toolbar: "",
          quickbars_selection_toolbar: "",
          forced_root_block: "div",
          entity_encoding: "raw",
          contextmenu: "",
          automatic_uploads: true,
          file_picker_types: "image",
          allow_html_in_named_anchor: true,
          statusbar: !autoSize,
          resize: false,
          branding: false,
          // paste_as_text: true,
          // paste_data_images: !isText,
          // Tokens
          init_instance_callback: (editor) => {
            onInit(editor);
          },
          setup: (editor) => {
            editor.on("init", function (e) {
              if (autoFocus) {
                editor.execCommand("mceFocus");
              }
            });

            // Onchange
            editor.on("input", (e) => handleCount(e, editor));

            if (tokens) {
              // Variables Dropdown
              // editor.ui.registry.addMenuButton("tokens", {
              //   tooltip: "Variables",
              //   text: "Variables",
              //   fetch: (callback) => {
              //     var items = tokens.map((token) => {
              //       return {
              //         type: "menuitem",
              //         text: token.label,
              //         onAction: () => {
              //           editor.insertContent(wrapValue(token.value));
              //         },
              //       };
              //     });
              //     callback(items);
              //   },
              // });

              // Autocomplete on {}
              var onAction = function (autocompleteApi, rng, value) {
                editor.selection.setRng(rng);
                editor.insertContent(wrapDatafieldValue(value));
                autocompleteApi.hide();
              };

              var getMatchedChars = function (pattern) {
                return tokens.filter(function (char) {
                  return (
                    char.label.toLowerCase().indexOf(pattern) !== -1 ||
                    char.value.toLowerCase().indexOf(pattern) !== -1
                  );
                });
              };

              editor.ui.registry.addAutocompleter(
                "specialchars_cardmenuitems",
                {
                  ch: "{",
                  minChars: 0,
                  columns: 1,
                  highlightOn: ["char_name", "char_value"],
                  onAction: onAction,
                  fetch: function (pattern) {
                    return new Promise(function (resolve) {
                      var results = getMatchedChars(pattern).map(function (
                        char
                      ) {
                        return {
                          type: "cardmenuitem",
                          value: char.value,
                          label: char.label,
                          items: [
                            {
                              type: "cardcontainer",
                              direction: "vertical",
                              items: [
                                {
                                  type: "cardtext",
                                  text: char.label,
                                  name: "char_name",
                                },
                                {
                                  type: "cardtext",
                                  text: wrapDatafieldValue(char.value),
                                  name: "char_value",
                                },
                              ],
                            },
                          ],
                        };
                      });
                      resolve(results);
                    });
                  },
                }
              );
            }

            if (maxChars) {
              editor.on("beforeinput", function (event) {
                var numChars = getCharacterCount(editor);
                if (numChars >= maxChars && event?.data) {
                  event.preventDefault();
                  return false;
                }
              });
            }

            // if (importer) {
            //   var inp = document.createElement("input");
            //   inp.type = "file";
            //   inp.style.display = "none";
            //   inp.setAttribute("accept", ".html");
            //   inp.addEventListener("change", (event) => {
            //     importFileAction(event, editor);
            //   });

            //   editor.getElement().parentElement.appendChild(inp);
            //   editor.ui.registry.addMenuButton("imports", {
            //     text: "Importer",
            //     fetch: (callback) => {
            //       const items = [
            //         {
            //           type: "menuitem",
            //           text: "Importer depuis un fichier HTML",
            //           onAction: () => {
            //             inp.click();
            //           },
            //         },
            //         {
            //           type: "menuitem",
            //           text: "Importer depuis un lien",
            //           onAction: () => {},
            //         },
            //       ];
            //       callback(items);
            //     },
            //   });
            // }
          },
        }}
      />

      {tokens && initialized && (
        <AddTokens
          items={tokens}
          long
          buttonProps={{
            style: {
              position: "absolute",
              top: 8,
              left: 8,
              zIndex: 10,
            },
          }}
          handleAdd={(item) => {
            editorRef.current.execCommand(
              "mceInsertContent",
              false,
              wrapDatafieldValue(item.value)
            );
          }}
        />
      )}

      {initialized && maxChars && (
        <Group
          pos={"absolute"}
          gap={"xs"}
          top={8}
          right={16}
          style={{ zIndex: 10 }}
        >
          <Text id="char-count" fw={600}>
            {getCharacterCount(null, defaultText)}
          </Text>
          <Text>{lang.model_emails.char}</Text>
        </Group>
      )}

      {initialized && children}
    </Stack>
  );
};

export default TextEditor;
