import { Button } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconFolderX } from "@tabler/icons-react";
import { useState } from "react";
import { useUIStore } from "../../stores/UIStore";
import { useParams } from "react-router-dom";
import { useLanguage } from "../../stores/LanguageStore";

const FolderDeleteDrag = ({ editFunction, getEditFunction, apiRef }) => {
  const lang = useLanguage((s) => s.language);
  const dragging = useUIStore((s) => s.dragging);
  const setAsideLoading = useUIStore((s) => s.setAsideLoading);
  const [inside, setInside] = useState(false);
  const { poolId, folderId } = useParams();

  const [loading, setLoading] = useState(false);
  const handleMove = (dragged) => {
    setLoading(true);
    const main = editFunction || getEditFunction?.(dragged);
    main({
      id: dragged.id,
      [poolId ? "pools_ids" : "folder_id"]: poolId
        ? dragged?.pools
            .filter((x) => x.id !== parseInt(poolId))
            .map((x) => x.id)
        : 0,
    })
      .then((response) => {
        notifications.show({
          message: poolId
            ? lang.components.folder_navigator.remove.success_pool
            : lang.components.folder_navigator.remove.success,
        });

        apiRef?.current?.updateRows([{ id: dragged.id, _action: "delete" }]);
        setAsideLoading(true);
      })
      .finally(() => setLoading(false));
  };

  const onDragOver = (e) => {
    e.preventDefault();
    setInside(true);
  };

  const onDragLeave = (e) => {
    e.preventDefault();
    setInside(false);
  };

  const onDrop = (ev) => {
    ev.preventDefault();
    var data = ev.dataTransfer.getData("text");

    handleMove(JSON.parse(data));
    setInside(false);
  };

  if ((!dragging && !loading) || (!poolId && !folderId)) {
    return "";
  }

  return (
    <Button
      leftSection={<IconFolderX />}
      size="xl"
      color="red"
      variant={"filled"}
      pos={"fixed"}
      bottom={66}
      left={"50%"}
      style={{
        transform: "translateX(-50%)",
        boxShadow: inside ? "#ff0000 0 0 10px" : "none",
      }}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      loading={loading}
    >
      {lang.components.folder_navigator.remove.text}{" "}
      {poolId ? "Pool" : lang.components.folder_navigator.column_name}
    </Button>
  );
};

export default FolderDeleteDrag;
