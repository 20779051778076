import { Button, Group, Modal } from "@mantine/core";
import { useLanguage } from "../../stores/LanguageStore";
import { useDisclosure } from "@mantine/hooks";
import { useState } from "react";
import MessagePreviewer from "./MessagePreviewer";
import { getFields } from "../../api/ContactAPI";
import BackButton from "./BackButton";
import { Link } from "react-router-dom";
import { IconEdit } from "@tabler/icons-react";

const MessageModalPreview = ({
  getData,
  type,
  opener,
  standalone,
  model,
  disableEdit,
}) => {
  const lang = useLanguage((s) => s.language);
  const [opened, { open, close }] = useDisclosure();
  const [sender, setSender] = useState("");
  const [text, setText] = useState("");

  const handleOpen = () => {
    if (standalone) {
      open();
      getFields();
    } else getData({ setText, setSender, open });
  };

  return (
    <>
      {opener ? (
        opener(handleOpen)
      ) : (
        <Button variant="light" onClick={handleOpen}>
          {lang.components.email_preview.button_label}
        </Button>
      )}
      <Modal
        opened={opened}
        onClose={close}
        title={"Prévisuel"}
        size={"40%"}
        centered
      >
        <MessagePreviewer
          model_id={model?.id}
          text={text}
          sender={sender}
          iframeHeight={400}
          paperShadow="none"
          type={type}
        />

        <Group justify="end" mt={"md"}>
          <BackButton onClick={close} />
          {!disableEdit && (
            <Button
              component={Link}
              leftSection={<IconEdit size={18} />}
              to={`/models/${
                type === "linkedin_invitation"
                  ? "linkedin-invitation"
                  : type === "linkedin_message"
                  ? "linkedin-message"
                  : "sms"
              }/${model?.id}/edit`}
            >
              {lang.api_keys.edit.button_label}
            </Button>
          )}
        </Group>
      </Modal>
    </>
  );
};

export default MessageModalPreview;
