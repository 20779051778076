import {
  IconBrandLinkedin,
  IconCircleMinus,
  IconDeviceMobileMessage,
  IconMail,
  IconMicrophone,
} from "@tabler/icons-react";
import { useMemo } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { Anchor, Divider, Group, SimpleGrid, Stack } from "@mantine/core";
import LimitField from "./LimitField";

const icon_props = {
  size: 18,
};

const UserLimits = ({ profile, setProfile }) => {
  const lang = useLanguage((s) => s.language);

  const limits = useMemo(
    () => ({
      limit_emails: [
        {
          label:
            lang.administration.right.limits.list
              .maxIntegrationsEmailMassMailing,
          description:
            lang.administration.right.limits.list
              .maxIntegrationsEmailMassMailing_tooltip,
          value: "maxIntegrationsEmailMassMailing",
        },
        {
          label: lang.administration.right.limits.list.maxIntegrationsEmailSMTP,
          description:
            lang.administration.right.limits.list
              .maxIntegrationsEmailSMTP_tooltip,
          value: "maxIntegrationsEmailSMTP",
        },
        {
          label: lang.administration.right.limits.list.maxEmailsSentPerDay,
          description:
            lang.administration.right.limits.list.maxEmailsSentPerDay_tooltip,
          value: "maxEmailsSentPerDay",
        },
        {
          label:
            lang.administration.right.limits.list.maxEmailsGeneratedPerMonth,
          description:
            lang.administration.right.limits.list
              .maxEmailsGeneratedPerMonth_tooltip,
          value: "maxEmailsGeneratedPerMonth",
        },
      ],
      limit_linkedin: [
        {
          label: lang.administration.right.limits.list.maxIntegrationsLinkedin,
          description:
            lang.administration.right.limits.list
              .maxIntegrationsLinkedin_tooltip,
          value: "maxIntegrationsLinkedin",
        },
        {
          label:
            lang.administration.right.limits.list.maxLinkedinInteractionsPerDay,
          description:
            lang.administration.right.limits.list
              .maxLinkedinInteractionsPerDay_tooltip,
          value: "maxLinkedinInteractionsPerDay",
        },
        {
          label:
            lang.administration.right.limits.list
              .maxLinkedinInteractionsPerMonth,
          description:
            lang.administration.right.limits.list
              .maxLinkedinInteractionsPerMonth_tooltip,
          value: "maxLinkedinInteractionsPerMonth",
        },
        {
          label:
            lang.administration.right.limits.list
              .maxLinkedinConnectionsExtractor,
          description:
            lang.administration.right.limits.list
              .maxLinkedinConnectionsExtractor_tooltip,
          value: "maxLinkedinConnectionsExtractor",
        },
        {
          label:
            lang.administration.right.limits.list
              .maxLinkedinConversationsExtractor,
          description:
            lang.administration.right.limits.list
              .maxLinkedinConversationsExtractor_tooltip,
          value: "maxLinkedinConversationsExtractor",
        },
      ],
      limit_sms: [
        {
          label: lang.administration.right.limits.list.maxSmsSentPerDay,
          description:
            lang.administration.right.limits.list.maxSmsSentPerDay_tooltip,
          value: "maxSmsSentPerDay",
        },
      ],
      limit_smv: [
        {
          label: lang.administration.right.limits.list.maxSmvSentPerDay,
          description:
            lang.administration.right.limits.list.maxSmvSentPerDay_tooltip,
          value: "maxSmvSentPerDay",
        },
      ],
      other_limit: [
        {
          label: lang.administration.right.limits.list.maxIceBreakerPerMonth,
          description:
            lang.administration.right.limits.list.maxIceBreakerPerMonth_tooltip,
          value: "maxIceBreakerPerMonth",
        },
        {
          label: lang.administration.right.limits.list.maxDatafieldAiPerMonth,
          description:
            lang.administration.right.limits.list.maxDatafieldAiPerMonth_tooltip,
          value: "maxDatafieldAiPerMonth",
        },
      ],
    }),
    [lang]
  );

  const rightsTitles = useMemo(
    () => ({
      limit_emails: {
        title: lang.administration.right.titles.limit_emails,
        icon: <IconMail {...icon_props} />,
        link: "/senders/email",
      },
      limit_linkedin: {
        title: lang.administration.right.titles.limit_linkedin,
        icon: <IconBrandLinkedin {...icon_props} />,
        link: "/senders/linkedin",
      },
      limit_sms: {
        title: lang.administration.right.titles.limit_sms,
        icon: <IconDeviceMobileMessage {...icon_props} />,
        link: "/models/sms",
      },
      limit_smv: {
        title: lang.administration.right.titles.limit_smv,
        icon: <IconMicrophone {...icon_props} />,
        link: "/models/smv",
      },
      other_limit: {
        title: lang.administration.right.titles.other_limit,
        icon: <IconCircleMinus {...icon_props} />,
        link: "",
      },
    }),
    [lang]
  );

  return (
    <Stack style={{ overflow: "auto" }} flex={"1 1 auto"} h={0} w={"100%"}>
      {Object.keys(limits).map((key) => (
        <Stack key={key}>
          <Group>
            {rightsTitles[key]?.icon}
            <Anchor
              fw={500}
              href={rightsTitles[key]?.link}
              c="black"
              target="_blank"
            >
              {rightsTitles[key]?.title}
            </Anchor>
          </Group>

          <SimpleGrid cols={3}>
            {limits[key].map((item) => (
              <LimitField
                key={item?.value}
                item={item}
                profile={profile}
                setProfile={setProfile}
              />
            ))}
          </SimpleGrid>

          <Divider />
        </Stack>
      ))}
    </Stack>
  );
};

export default UserLimits;
