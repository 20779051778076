import { create } from "zustand";
import { persist } from "zustand/middleware";

const configStore = (set) => ({
  users_list: [],
  setUsersList: (newData) =>
    set({ users_list: newData }, false, "setUsersList"),

  tags: [],
  setTags: (newData) => set({ tags: newData }, false, "setTags"),

  datafields: [],
  setDatafields: (newData) =>
    set({ datafields: newData }, false, "setDatafields"),

  blacklists: [],
  setBlacklists: (newData) =>
    set({ blacklists: newData }, false, "setBlacklists"),

  email_signatures: [],
  setEmailSignatures: (newData) =>
    set({ email_signatures: newData }, false, "setEmailSignatures"),

  programmations: [],
  setProgrammations: (newData) => set({ programmations: newData }),

  folders: [],
  setFolders: (newData) => set({ folders: newData }, false, "setFolders"),

  pinned_filters: [],
  setPinnedFilters: (newData) =>
    set({ pinned_filters: newData }, false, "setPinnedFilters"),
});

const accountsStore = (set) => ({
  email_accounts: [],
  setEmailAccounts: (newData) =>
    set({ email_accounts: newData }, false, "setEmailAccounts"),

  email_pools: [],
  setEmailPools: (newData) =>
    set({ email_pools: newData }, false, "setEmailPools"),

  linkedin_accounts: [],
  setLinkedinAccounts: (newData) =>
    set({ linkedin_accounts: newData }, false, "setLinkedinAccounts"),

  linkedin_pools: [],
  setLinkedinPools: (newData) =>
    set({ linkedin_pools: newData }, false, "setLinkedinPools"),
});

const modelsStore = (set) => ({
  model_emails: [],
  setModelEmails: (newData) =>
    set({ model_emails: newData }, false, "setModelEmails"),

  model_linkedin_invitations: [],
  setModelLinkedinInvitations: (newData) =>
    set(
      { model_linkedin_invitations: newData },
      false,
      "setModelLinkedinInvitations"
    ),

  model_linkedin_messages: [],
  setModelLinkedinMessages: (newData) =>
    set(
      { model_linkedin_messages: newData },
      false,
      "setModelLinkedinMessages"
    ),

  model_sms: [],
  setModelSms: (newData) => set({ model_sms: newData }, false, "setModelSms"),

  model_smv: [],
  setModelSmv: (newData) => set({ model_smv: newData }, false, "setModelSmv"),
});

const filterStore = (set) => ({
  datafieldsFilter: [],
  setDatafieldsFilter: (newData) => set({ datafieldsFilter: newData }),
});

const notificationsStore = (set) => ({
  notifications: { emails: [], linkedins: [] },
  setNotifications: (newData) => set({ notifications: newData }),
  deleted: { emails: [], linkedins: [] },
  setDeleted: (newData) => set({ deleted: newData }),
});

export const useConfigStore = create(configStore);
export const useAccountsStore = create(accountsStore);
export const useModelsStore = create(modelsStore);
export const useFilterStore = create(filterStore);
export const useNotificationStore = create(
  persist(notificationsStore, { name: "notifications" })
);
