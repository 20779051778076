import { useThemeStore } from "../stores/ThemeStore";

export const LoadingIllustration = () => {
  const theme = useThemeStore((s) => s.theme);
  const mainColor = theme.colors.primary[6];
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 450 632.26705"
    >
      <path
        d="M259.41093,737.88172a3.61323,3.61323,0,0,1-2.61865-6.26262c.09111-.36213.15647-.62217.24758-.9843a9.70742,9.70742,0,0,0-17.99686-.16973c-4.28226,10.0234-9.82453,20.4-7.06826,31.44011-18.206-38.58538-12.01458-86.582,14.89139-119.57967,7.43714-4.09684,13.46508-11.37558,15.03439-19.82719-3.6807,1.24714-8.26984-1.74675-5.50237-5.62382,1.19955-1.48179,2.41075-2.95192,3.61019-4.4338-13.98171-14.89038-30.64069,8.512-15.25086,28.60947A115.84683,115.84683,0,0,0,231.74614,661.402a48.70929,48.70929,0,0,0-2.908-22.62447c-2.78346-6.71479-8.00064-12.37-12.595-18.17495-5.58608-7.07308-17.083-3.80993-17.8347,5.16593,5.15526,1.69443,5.825,9.14116-.072,10.16036,1.54455,16.69795,12.45345,32.04321,27.47828,39.36635a116.2989,116.2989,0,0,0-6.35539,48.6114c-2.65207-19.25-23.31569-23.92147-37.75859-31.57247a5.626,5.626,0,0,0-8.58011,5.69851,32.49481,32.49481,0,0,1,5.69584,3.00488,4.91123,4.91123,0,0,1-2.28162,8.96546c7.28014,18.01805,26.10227,30.11728,45.43371,30.04916a119.56278,119.56278,0,0,0,8.03167,23.447H258.692c.10291-.3188.19542-.64792.288-.96672a32.59875,32.59875,0,0,1-7.93916-.473c7.02637-8.31908,12.46031-12.56336,8.371-24.17721Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#f2f2f2"
      />
      <polygon
        points="253.397 607.84 265.228 607.84 270.856 562.209 253.395 562.21 253.397 607.84"
        fill="#ffb6b6"
      />
      <path
        d="M427.27,742.23v-8.02l-3.11005.19-12.66.75-2.93.18-2.24,26.86-.12,1.44h8.4l.27-1.43,1.22-6.5,3.15,6.5.69,1.43h22.27a5.03191,5.03191,0,0,0,5-5.01C448.15,754.23,429.63,744.74,427.27,742.23Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#2f2e41"
      />
      <polygon
        points="114.802 594.963 125.091 600.801 152.505 563.891 137.318 555.275 114.802 594.963"
        fill="#ffb6b6"
      />
      <path
        d="M286.1,736.99l3.95-6.97-2.78-1.37-11.39-5.59-2.64-1.3-12.97,18.99-3.05,4.46,3.45,1.96,3.85,2.19,5.22-6.16-.58,8.79,18.19,10.33h.01l1.17.66a5.00819,5.00819,0,0,0,6.82-1.89C298.33,757.73,286.91,740.35,286.1,736.99Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#2f2e41"
      />
      <path
        d="M455.23153,362.72312a10.54869,10.54869,0,0,0-8.44464,13.66695l-18.1028,15.66875,6.83873,13.32338,25.25388-22.48636a10.5339,10.5339,0,0,0-5.54517-20.17272Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#ffb6b6"
      />
      <path
        d="M436.17,404.8l-6.8-13.33-.7-1.37c-7.28,2.56-21.57995,10.92-28.1,15.19-2.39-3.33-15.68-17.82-27.17-23.38,0-.01-.01,0-.01,0q-1.185-.57-2.34-1.02a16.16149,16.16149,0,0,0-5.59-1.16,8.60607,8.60607,0,0,0-2.51.36,7.0571,7.0571,0,0,0-2.36,1.21c-1.87006,1.44-3.29,3.95-4.09,7.83a15.31294,15.31294,0,0,0,.71,5.44c3.2,10.61,15.84,22.46,20.31,30.1.44,1.18,4.83,12.8,11.76,16.92a11.17252,11.17252,0,0,0,1.12994.6c.43006.06.86005.11,1.31.13h.01a18.40077,18.40077,0,0,0,2.11005.03c.36-.01.73-.04,1.1-.07.08-.01.15-.01.23-.02a33.3416,33.3416,0,0,0,6.79-1.48c.25-.08.5-.16.75-.25a55.869,55.869,0,0,0,5.57-2.24c1.05-.48,2.09-1,3.12994-1.54,1.26-.66,2.52-1.36,3.75-2.09,1.06-.61,2.1-1.25,3.12006-1.91v-.01c.24-.14.48-.3.72-.47h.01c9.12-5.93,16.56-12.82,17.99-15.67a23.15456,23.15456,0,0,0,2.58-5.11Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#2f2e41"
      />
      <path
        d="M394.7816,451.69324s25.07055,28.20826,18.76869,34.51012S397.4419,494.16,397.4419,494.16l-11.989-.92411Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#2f2e41"
      />
      <path
        d="M364.02652,376.02124c32.33536,18.91875,43.20835,36.9921,28.25419,73.361Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#2f2e41"
      />
      <path
        d="M335.03794,527.266c-2.62328-1.2203-9.13947,97.21867-9.67074,99.69217L288.00186,696.594,341.186,720.43428l34.96954-121.422,5.82826,44.6833-7.771,70.91045,34.37738,8.31358,23.778-1.69843-16.514-201.86784-31.33684-56.82089Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#2f2e41"
      />
      <path
        d="M361.23844,378.80994c43.90571,32.68844,33.18493,33.27049,33.54245,83.72334l20.92092,55.9101s-73.75317,28.564-81.31541,9.65845S361.23844,378.80994,361.23844,378.80994Z"
        transform="translate(-158.10584 -133.32498)"
        fill={mainColor}
      />
      <path
        d="M395.35,447.16c-.09-.32-.2-.69-.32-1.08v-.01c-.19-.63-.41-1.32-.67-2.11005-.16-.5-.33-1.04-.52-1.61-.12006-.36-.24-.73-.37006-1.12-3.52-10.63-10.62-30.45-19.24-52.36v-.01q-1.56-3.945-3.18-7.97c-.1-.24-.19-.49-.29-.73-.43-1.07-.87-2.15-1.31-3.23L356.82,364.3l-6.81-4.44-15,13.85s.01.24.03.71c.21,4.27,1.24,27.33,1.37,54.59.03,6.87.01,14.01-.11,21.18v.19c-.03,1.88,0,3.34.09,4.52a19.69382,19.69382,0,0,0,.29,2.5c.56,3.08,1.59,3.8,2.14,10.21,0,0-11.8,15.21-4.95,28.23a9.38872,9.38872,0,0,0,1.64.1c2.42.04,8.06-.11,23.57-.12,2.69-.02,5.67-.02,8.98-.01,21.69,0,10-12.55,21.59-23.98.25-.25.5-.49.77-.73a26.25022,26.25022,0,0,1,2.6-2.07l1.94-6.02.62-1.93.01-.25.06-2.67.13-5.32.09-3.54A13.14559,13.14559,0,0,0,395.35,447.16Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#2f2e41"
      />
      <polygon
        points="183.259 274.171 179.814 295.671 203.133 305.289 183.259 274.171"
        opacity="0.2"
      />
      <path
        d="M375.80569,312.96806c26.08656,14.8443,4.67079,54.011-21.90207,40.064C327.81789,338.1883,349.23366,299.02164,375.80569,312.96806Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#ffb6b6"
      />
      <path
        d="M326.60136,448.837c13.83259,16.30165,37.40515,18.65536,55.3076,7.71571Z"
        transform="translate(-158.10584 -133.32498)"
        opacity="0.2"
      />
      <path
        d="M389.75483,321.19875c-1.82784,2.37733-5.64667,1.26262-13.12517,2.959a31.17581,31.17581,0,0,0-.87207-7.52626,21.18548,21.18548,0,0,1-3.57209,8.4701c-6.49375.72019,1.46671,20.44782-1.85819,34.9522-4.28394-30.59958-13.29439-22.58275-8.74238-16.51339,2.91413,3.8855.97138,15.542-7.771,9.71376-14.42458,6.85539-34.96953-27.19853-12.19938-43.16932-.40687-8.63264,9.19856-9.99636,15.11351-7.34224C371.08279,292.04922,387.48292,305.75107,389.75483,321.19875Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#2f2e41"
      />
      <path
        d="M404.4871,432.63234c7.41429-.14769,47.23975-1.29075,53.77054-1.00965.36943-1.2933,12.74985,14.57255,13.05093,14.67089a1.80834,1.80834,0,0,1-.60481,2.90759c-.72777.44213-64.93166-.74561-65.70956-.59064a1.7859,1.7859,0,0,1-1.59378-1.03833l-.42914-.96115c-.04458-1.64432-.58291-10.048-.09965-11.48484A1.77815,1.77815,0,0,1,404.4871,432.63234Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#3f3d56"
      />
      <path
        d="M402.97138,446.60953c.37681.90549.85882,2.04315,2.02291,2.00138l65.03159.7298a1.7939,1.7939,0,0,0,1.56527-2.63308Z"
        transform="translate(-158.10584 -133.32498)"
        opacity="0.17"
      />
      <path
        d="M457.95379,431.428c.7-1.21052,18.08616-87.2383,19.53727-87.95769l11.87032-14.52105,1.06094-.79779c1.47535-.81045,5.01435-.26247,4.69564,1.99087h0c-2.7887,13.37007-20.7256,89.51848-21.11867,101.39039L471.84015,446.657a1.86252,1.86252,0,0,1-2.29966,1.52983,3.58265,3.58265,0,0,1-2.87739-2.07911C466.50124,445.574,457.141,431.73787,457.95379,431.428Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#3f3d56"
      />
      <path
        d="M489.36425,328.94986,466.66087,446.11078c.75925,1.92173,4.312,3.26805,5.13947.74718.01446-.00347,23.29115-116.64351,23.31482-116.71324C495.36316,327.33759,490.72469,327.24855,489.36425,328.94986Z"
        transform="translate(-158.10584 -133.32498)"
        opacity="0.17"
      />
      <polygon
        points="330.574 200.416 319.044 216.487 306.102 277.953 312.118 283.201 330.574 200.416"
        fill={mainColor}
      />
      <path
        d="M411.41,436.75c-.24-1.86-.7-3.57-2.05-3.44-1.99-.01-1.56,2.7-1.08,4.98a12.53882,12.53882,0,0,1,.46,2.76l3.19-.13A26.31891,26.31891,0,0,1,411.41,436.75Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#2f2e41"
      />
      <path
        d="M430.21,436.31c-.02-.53.01-1.21-.06-1.85-.11-1.06-.47-1.98-1.68-1.91a1.60032,1.60032,0,0,0-.65.13c-1.29.58-.76,2.93-.78,4.07l1.04-.04,2.15-.08C430.22,436.53,430.21,436.42,430.21,436.31Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#2f2e41"
      />
      <path
        d="M436.58,436.05c-.05-1.4.2-3.87-1.74-3.76-2.1.04-1.41,2.9-1.43006,4.21l1.03-.04,2.16-.09C436.59,436.27,436.58,436.16,436.58,436.05Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#2f2e41"
      />
      <path
        d="M442.95,435.8c-.05-1.41.2-3.88-1.74-3.76-2.1.04-1.41,2.9-1.43,4.2l1.04-.04,2.15-.08C442.96,436.02,442.95,435.91,442.95,435.8Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#2f2e41"
      />
      <path
        d="M449.32,435.54c-.05-1.4.2-3.87-1.74-3.76-2.1.05-1.41,2.9-1.43,4.21l1.03-.04,2.16-.09C449.33,435.76,449.32,435.65,449.32,435.54Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#2f2e41"
      />
      <path
        d="M455.69,435.29c-.04-1.26.15-3.36-1.19-3.71h-.01a1.50566,1.50566,0,0,0-.54-.05,1.46387,1.46387,0,0,0-.44.06h-.01c-1.54.43-.96,2.94-.98,4.14l1.04-.04,2.15-.08C455.7,435.51,455.69,435.4,455.69,435.29Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#2f2e41"
      />
      <path
        d="M418.28,432.75v-.01a3.11478,3.11478,0,0,0-.08-.44c-.16-.85-.57-1.51-1.6-1.45-1.01.02-1.37.68-1.48,1.52a13.3303,13.3303,0,0,0,.04,2.29c.01.15.01.28.01.4l3.19-.13C418.31,434.37,418.36,433.53,418.28,432.75Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#2f2e41"
      />
      <path
        d="M424.6,432.14c-.16-.89-.57-1.61-1.63-1.54-1.05.02-1.4.74-1.49,1.62a23.27806,23.27806,0,0,0,.06,2.58l3.19-.13C424.67,434.03,424.75,433,424.6,432.14Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#2f2e41"
      />
      <path
        d="M430.98,431.99c-.13995-.94-.53-1.71-1.63995-1.65-1.09.02-1.42005.79-1.5,1.72a4.23306,4.23306,0,0,0-.02.62c.01.69.1,1.39.09,1.87l2.24-.09.95-.04C431.04,433.8,431.12,432.83,430.98,431.99Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#2f2e41"
      />
      <path
        d="M416.54,436.59c-2.1.04-1.42,2.9-1.43,4.2l3.19-.12C418.18,439.36,418.64,436.46,416.54,436.59Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#2f2e41"
      />
      <path
        d="M422.91,436.33c-2.1.04-1.42,2.9-1.43,4.21l3.19-.13C424.55,439.11,425.01,436.21,422.91,436.33Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#2f2e41"
      />
      <path
        d="M430.21,436.31a1.40545,1.40545,0,0,0-.93-.23,1.31958,1.31958,0,0,0-1.2.63c-.57.92-.22,2.64-.23,3.57l3.19-.12C430.94,439.1,431.23,436.98,430.21,436.31Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#2f2e41"
      />
      <path
        d="M436.58,436.05a1.3893,1.3893,0,0,0-.93-.23,1.32344,1.32344,0,0,0-1.21.63995c-.55.93-.21,2.64-.22,3.57l3.19-.13C437.31,438.85,437.6,436.74,436.58,436.05Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#2f2e41"
      />
      <path
        d="M442.95,435.8a1.4051,1.4051,0,0,0-.93-.23,1.31934,1.31934,0,0,0-1.2.63c-.57.92-.22,2.64-.23,3.57l3.19-.12C443.68,438.59,443.97,436.47,442.95,435.8Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#2f2e41"
      />
      <path
        d="M449.32,435.54a1.38926,1.38926,0,0,0-.93-.23,1.31241,1.31241,0,0,0-1.21.64c-.55.93-.21,2.64-.22,3.57l3.19-.13C450.05,438.34,450.34,436.23,449.32,435.54Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#2f2e41"
      />
      <path
        d="M455.69,435.29a1.405,1.405,0,0,0-.93-.23,1.30821,1.30821,0,0,0-1.2.63c-.56.93-.22,2.64-.23,3.57l3.19-.12C456.42,438.08,456.71,435.97,455.69,435.29Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#2f2e41"
      />
      <path
        d="M322.82252,298.87066c-5.8078,35.7085-36.93733-6.56715-51.57425,28.57359-4.602,12.726,3.29388,21.82962,8.28839,32.39269,1.1399,8.68094-10.15328,13.0852-11.33618,21.076-.32923,18.31967,29.22628,17.19863,37.48876,5.64056,8.48971-12.09931-1.64822-28.33252,3.45472-41.31951,7.17216-18.03062,36.33983-18.16457,38.88084-39.14163C343.10367,299.08445,329.87088,291.05822,322.82252,298.87066Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#2f2e41"
      />
      <path
        d="M597.77486,763.88684c-.76734,2.27271-438.84115,2.27263-439.669.00551C158.87218,761.61414,596.94593,761.6142,597.77486,763.88684Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#ccc"
      />
      <path
        d="M346.35,302.36c-.19.08-.38.16-.55.25h-.01c-.16.08-.32.16-.48.25-.02.01-.03.02-.05.03-.21-.2-.42-.4-.64-.6a2.3127,2.3127,0,0,0,.04.98l.03.12c.2.21.41.41.63.62.2-.15.39-.31.59-.46.12006-.1.24-.2.37006-.3.02-.26.05-.56.08-.89Zm6.02-11.12c-4.46-1.02-5.9,4.81-6.33,7.62-1.62-10.2-8.36-6.99-6.03-.03a15.5831,15.5831,0,0,0,3.43,5.52c.21.24.44.48.68.72-.27,1.4,1.09,1.04,2.93-.25a24.50822,24.50822,0,0,0,3.75-3.29C354.47,297.68,357.33,292.53,352.37,291.24Zm.14,5.74a34.946,34.946,0,0,1-4.61,4.89q-.795.72-1.62,1.38c-.01.09-.01.17-.02.24a1.83179,1.83179,0,0,1-.14.28c-.56,1.01-1.19.46-1.43-.38-.02-.03-.05-.06-.07-.09a14.09043,14.09043,0,0,1-2.07-2.74c-4.13-7.16,2.52-12.03,2.19,1.34a1.26639,1.26639,0,0,0-.12.39,2.3127,2.3127,0,0,0,.04.98l.03.12c.2.21.41.41.63.62.2-.15.39-.31.59-.46.12006-.1.24-.2.37006-.3.02-.26.05-.56.08-.89v-.01c.26-2.68.97-7.5,2.28-8.81C351.52,291.33,355.25,293.72,352.51,296.98Zm-6.16,5.38c-.19.08-.38.16-.55.25h-.01c-.16.08-.32.16-.48.25-.02.01-.03.02-.05.03-.21-.2-.42-.4-.64-.6a2.3127,2.3127,0,0,0,.04.98l.03.12c.2.21.41.41.63.62.2-.15.39-.31.59-.46.12006-.1.24-.2.37006-.3.02-.26.05-.56.08-.89Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#2f2e41"
      />
      <path
        d="M497.16031,388.45536a5.5,5.5,0,0,0-5.5,5.49994v53.20618h-105.5a5.5,5.5,0,0,0,0,11h105.5v306h11V393.9553A5.5,5.5,0,0,0,497.16031,388.45536Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#e6e6e6"
      />
      <path
        d="M406.04419,443.58162a10.54869,10.54869,0,0,0-16.05809.48494l-23.05092-6.47118-7.349,13.04884,32.68071,8.68137a10.5339,10.5339,0,0,0,13.77734-15.744Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#ffb6b6"
      />
      <path
        d="M354.62,428.25c-1.67-.87-3.34-1.72-4.97-2.51-3.54-1.75-6.83-3.28-9.25-4.32a83.63647,83.63647,0,0,0,2.82-11.02c2.84-14.32,4.77-36.63995-8.18-35.98a16.8975,16.8975,0,0,0-5.44,1.32c-10.09,5.61-13.55,27.92-18.07,37.13-.79,1.07-8.81006,12.19-7.52,20.42,3.66,8,15.3,14.84,26.21,19.29l.01.01c2.1.86,4.18,1.63,6.16,2.31,2.26.77,4.41,1.41,6.34,1.91,3.85,1,6.86005,1.45,8.31,1.25a23.42955,23.42955,0,0,0,5.69-.66l2.29-4.04,1.41-2.5,7.37-13.02.76-1.34A110.02578,110.02578,0,0,0,354.62,428.25Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#2f2e41"
      />
      <path
        d="M855.93,497.94c-22.86,9.33-48.7,16.44-77.78,20.82a460.56246,460.56246,0,0,1-57.06,4.84q-25.08.63-52.82-1.13V156.38a6.92021,6.92021,0,0,1,6.92-6.91l2.76.01,11.07.03h.19l297.18.74h1.35l10.74.03q2.31006,7.44,4.30005,15.33C1029.54,271.9,1009.78,435.15,855.93,497.94Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#f2f2f2"
      />
      <path
        d="M734.55788,303.54343c.17684,12.112-18.84861,12.11208-18.67021-.00083C715.71078,291.43147,734.73623,291.43136,734.55788,303.54343Z"
        transform="translate(-158.10584 -133.32498)"
        fill={mainColor}
      />
      <path
        d="M898.86,318.66H718.67a2.775,2.775,0,1,0,0,5.55H898.86C902.51,324.27,902.42,318.6,898.86,318.66Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#ccc"
      />
      <path
        d="M834.8,329.64H718.67a2.78,2.78,0,0,0,0,5.56H834.8C838.43,335.25,838.37,329.59,834.8,329.64Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#ccc"
      />
      <path
        d="M898.86,236.16H718.67a2.78,2.78,0,0,0,0,5.56H898.86C902.51,241.77,902.42,236.11,898.86,236.16Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#ccc"
      />
      <path
        d="M834.8,247.15H718.67a2.78,2.78,0,0,0,0,5.56H834.8C838.43,252.76,838.37,247.1,834.8,247.15Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#ccc"
      />
      <path
        d="M834.8,258.66H718.67a2.78,2.78,0,0,0,0,5.56H834.8C838.43,264.27,838.37,258.61,834.8,258.66Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#ccc"
      />
      <path
        d="M734.55788,375.48311c.17684,12.112-18.84861,12.11208-18.67021-.00083C715.71078,363.37115,734.73623,363.371,734.55788,375.48311Z"
        transform="translate(-158.10584 -133.32498)"
        fill={mainColor}
      />
      <path
        d="M898.86,390.59H718.67a2.78,2.78,0,0,0,0,5.56H898.86C902.51,396.2,902.42,390.54,898.86,390.59Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#ccc"
      />
      <path
        d="M834.8,401.58H718.67a2.78,2.78,0,0,0,0,5.56H834.8C838.43,407.19,838.37,401.53,834.8,401.58Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#ccc"
      />
      <path
        d="M731.59008,221.12364c.17552,12.02153-18.70789,12.02164-18.53082-.00083C712.88368,209.10211,731.7671,209.102,731.59008,221.12364Z"
        transform="translate(-158.10584 -133.32498)"
        fill={mainColor}
      />
      <path
        d="M991.125,151.59385c-1.43425-1.43851-314.46386,2.76685-314.10771-1.45485C675.47656,148.547,999.708,144.90561,991.125,151.59385Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#cacaca"
      />
      <circle cx="503.70166" cy="16.14719" r="16.14719" fill="#cacaca" />
      <circle cx="844.40729" cy="16.14719" r="16.14719" fill="#cacaca" />
      <path
        d="M855.93,497.94c-22.86,9.33-48.7,16.44-77.78,20.82a460.56246,460.56246,0,0,1-57.06,4.84q-25.8-.3-52.82-1.13V509.71C692.52,489.36,796.42,486.11,855.93,497.94Z"
        transform="translate(-158.10584 -133.32498)"
        fill="#cacaca"
      />
    </svg>
  );
};
