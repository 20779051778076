import { MultiSelect } from "@mantine/core";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { IconUsersGroup } from "@tabler/icons-react";

const CompanyCountsSelector = ({ values, setValues }) => {
  const lang = useLanguage((s) => s.language);
  const options = [
    "independant",
    "1-10",
    "11-50",
    "51-200",
    "201-500",
    "501-1000",
    "1001-5000",
    "5001-10000",
    "10001-above",
  ];

  const onChange = (v) => {
    setValues(v);
  };
  return (
    <MultiSelect
      label={lang.targeting_linkedin_google.company_head_count}
      placeholder={lang.tags.enter_to_add}
      value={values}
      onChange={onChange}
      w={"100%"}
      size="sm"
      styles={{ label: { fontSize: 13 } }}
      data={options}
      leftSection={<IconUsersGroup size={18} />}
      clearable
    />
  );
};

export default CompanyCountsSelector;
