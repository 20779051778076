import { useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import {
  sendTestEmail,
  sendTestEmailFromModel,
} from "../../../../api/ModelsApi";
import { notifications } from "@mantine/notifications";
import {
  ActionIcon,
  Button,
  Group,
  Modal,
  Stack,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import {
  IconMailForward,
  IconPlus,
  IconSend,
  IconTrash,
} from "@tabler/icons-react";
import SignatureSelector from "../../../../components/Selectors/SignatureSelector";
import CreatableContactListSelector from "../../../../components/Selectors/CreatableContactListSelector";
import BackButton from "../../../../components/View/BackButton";
import { useProfileStore } from "../../../../stores/UserStore";

const SendBAT = ({
  model,
  getModel,
  disabled,
  isIcon,
  inMoreActions,
  defaultSignature,
  ...props
}) => {
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);
  const [opened, { open, close }] = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [sign, setSign] = useState(null);
  const [list, setList] = useState(null);

  const onSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const fd = new FormData(e.target);
    let data = Object.fromEntries(fd);

    let emails = fd.getAll("emails").filter((x) => x.length > 0);

    const main =
      model?.html || getModel ? sendTestEmail : sendTestEmailFromModel;

    let m = getModel ? await getModel() : model;

    setLoading(true);
    main({
      subject: m?.subject,
      text: m?.text,
      html: m?.html,
      sender: data.sender,
      emails,
      email_signature_id: sign?.id,
      contact_list_id: list?.id,
      model_id: m?.id,
    })
      .then((response) => {
        notifications.show({
          message: lang.model_emails.bat.success,
          color: "tertiary.8",
        });
        close();
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (defaultSignature) {
      if (defaultSignature?.id) {
        setSign(defaultSignature);
      } else {
        setSign({ id: defaultSignature });
      }
    }
  }, [defaultSignature]);

  return (
    <>
      <Tooltip label={lang.model_emails.bat.button_tooltip}>
        {inMoreActions ? (
          <Button
            onClick={open}
            leftSection={<IconMailForward size={18} />}
            fw={600}
            color="primary"
            variant="subtle"
            justify="start"
            {...props}
          >
            {lang.model_emails.bat.button_label}
          </Button>
        ) : isIcon ? (
          <Button
            variant="subtle"
            px={"xs"}
            onClick={open}
            disabled={disabled}
            {...props}
          >
            <IconMailForward size={21} />
          </Button>
        ) : (
          <Button
            variant="light"
            onClick={open}
            leftSection={<IconMailForward size={18} />}
            disabled={disabled}
            {...props}
          >
            {lang.model_emails.bat.button_label}
          </Button>
        )}
      </Tooltip>

      {opened && (
        <Modal
          opened={opened}
          onClose={close}
          size={"40%"}
          title={lang.model_emails.bat.dialog.title}
        >
          <Text size="sm" mb={"sm"}>
            {lang.model_emails.bat.dialog.warning_text}{" "}
            <b>{lang.model_emails.bat.dialog.wont_change}</b>
          </Text>
          <Stack component={"form"} onSubmit={onSubmit} gap={"xs"}>
            <TextInput
              label={lang.model_emails.bat.dialog.sender}
              placeholder="John Doe"
              name="sender"
              defaultValue={`${profile.first_name} ${profile?.last_name}`}
              required
            />

            <Content isRequired={true} />

            {(!profile?.subscriptions || profile?.subscriptions?.trial) && (
              <>
                <Text size="sm" fw={600} c={"red"}>
                  {lang.model_emails.bat.dialog.free_warning}
                </Text>
                <Text size="sm" mb={"sm"} fw={600}>
                  {lang.model_emails.bat.dialog.free_warning2}
                </Text>
              </>
            )}
            <SignatureSelector value={sign?.id || null} onChange={setSign} />

            <CreatableContactListSelector
              label={lang.model_emails.bat.dialog.list_label}
              value={list}
              setValue={setList}
              disableCreate
              description={lang.model_emails.bat.dialog.list_helper}
            />

            <Group justify="end" mt={"md"}>
              <BackButton onClick={close} />
              <Button
                type="submit"
                loading={loading}
                color="primary"
                rightSection={<IconSend size={18} />}
              >
                {lang.model_emails.bat.dialog.submit_button}
              </Button>
            </Group>
          </Stack>
        </Modal>
      )}
    </>
  );
};

const Content = ({ isRequired }) => {
  const profile = useProfileStore((s) => s.profile);
  const lang = useLanguage((s) => s.language);
  const [emails, setEmails] = useState([""]);

  const addEmail = () => {
    setEmails((prev) => [...prev, ""]);
  };

  const removeEmail = (index) => {
    setEmails((prev) => {
      let newEmails = [...prev];
      newEmails.splice(index, 1);

      return newEmails;
    });
  };

  return (
    <Stack>
      {emails.map((email, index) => (
        <Group key={index} align="end" w={"100%"} wrap="nowrap">
          <TextInput
            label={index === 0 ? "Emails" : null}
            placeholder="email@example.com"
            type="email"
            name={"emails"}
            w={"100%"}
            required={isRequired}
            defaultValue={index === 0 ? profile.email : null}
            // disabled={!profile?.subscriptions || profile?.subscriptions?.trial}
            readOnly={!profile?.subscriptions || profile?.subscriptions?.trial}
          />
          {emails.length > 1 && (
            <ActionIcon
              size={"lg"}
              color="red"
              variant="light"
              onClick={removeEmail}
            >
              <IconTrash size={18} />
            </ActionIcon>
          )}
          <ActionIcon
            size={"lg"}
            color="tertiary.8"
            variant="light"
            disabled={
              index < emails.length - 1 ||
              !profile?.subscriptions ||
              profile?.subscriptions?.trial
            }
            onClick={addEmail}
          >
            <IconPlus size={18} />
          </ActionIcon>
        </Group>
      ))}
    </Stack>
  );
};

export default SendBAT;
