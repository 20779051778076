import { useConfigStore, useFilterStore } from "../stores/ConfigStore";
import { useLanguage } from "../stores/LanguageStore";
import { useListStore } from "../stores/ListStore";
import { mainAxios as axios } from "./axios";
import { useProfileStore } from "../stores/UserStore";
import { useLeadsStore } from "../stores/LeadsStore";
//#region Fields
export const getFields = async () => {
  let res = await axios.get(`/data-fields`);

  if (res) {
    const lang = useLanguage.getState().language;
    let datafields = [...res.data.data_fields_list]
    const sortedDataFields = datafields.sort((a, b) => a.sorting - b.sorting)
    let filterFields = [
      {
        label: lang.datafields.my_datafields.toUpperCase(),
        options: [{ name: "ID", id: "id", option_name: "ID" }],
      },
      {
        label: lang.components.sharing.row.from_sharing.toUpperCase(),
        options: [],
      },
    ];
    sortedDataFields?.forEach((item) => {
      let from_sharing = item?.from_sharing && item?.created_by !== null;
      filterFields[from_sharing ? 1 : 0].options.push({
        ...item,
        option_name: item.name,
      });
    });

    console.log({ sortedDataFields });


    useConfigStore.getState().setDatafields(sortedDataFields);
    useFilterStore.getState().setDatafieldsFilter(filterFields);

  }
  return res;
};

export const createField = async ({ name, possible_values, sharing }) => {
  let a = axios.post(`/data-fields`, {
    name,
    sharing,
    possible_values,
  });

  return a;
};

export const editField = async ({
  id,
  name,
  identifier,
  possible_values,
  sharing,
}) => {
  let a = axios.put(`/data-fields/${id}`, {
    name,
    identifier,
    sharing,
    possible_values,
  });

  return a;
};

export const findField = async (id) => {
  let a = axios.get(`/data-fields/${id}`);

  return a;
};

export const deleteField = async ({ id }) => {
  let a = axios.delete(`/data-fields/${id}`);

  return a;
};
//#endregion

// Lists
export const getLists = async () => {
  let response = await axios.get(`/contact-lists`);

  if (response.data.state) {
    let newLists = response.data.contact_lists.sort((a, b) => b.id - a.id);
    useListStore.getState().setLists(newLists);

    let setPrmOptions = useLeadsStore.getState().setListOptions;
    setPrmOptions([
      {
        label: useLanguage.getState().language.prm.filter_labels.contact_list,
        options:
          response.data["contact_lists"]
            ?.sort((a, b) => b.id - a.id)
            ?.map((x) => ({ ...x, option_name: x["name"] })) || [],
      },
    ]);
    return response;
  }
};

export const findList = async (id) => {
  let a = axios.get(`/contact-lists/${id}`);

  return a;
};

export const createList = async ({ name, sharing, tags_ids, folder_id }) => {
  let a = axios.post(`/contact-lists`, {
    name: name,
    sharing: sharing,
    tags_ids: tags_ids,
    folder_id: folder_id,
  });

  return a;
};

export const mergeLists = async ({
  name,
  sharing,
  tags_ids,
  folder_id,
  list_ids,
}) => {
  let a = axios.post(`/contact-lists/merge`, {
    name: name,
    sharing: sharing,
    tags_ids: tags_ids,
    folder_id: folder_id,
    contact_list_ids: list_ids,
  });

  return a;
};

export const editList = async ({
  id,
  name,
  sharing,
  tags_ids,
  folder_id,
  language,
  country,
}) => {
  let request = axios.put(`/contact-lists/${id}`, {
    name,
    sharing,
    tags_ids,
    folder_id,
    language,
    country,
  });

  return request;
};

export const deleteList = async ({ id }) => {
  let a = axios.delete(`/contact-lists/${id}`);

  return a;
};

export const downloadList = async ({ id, file_type, name }) => {
  return axios
    .get(`/contact-lists/${id}/download/${file_type}`, { responseType: "blob" })
    .then((res) => {
      const url = window.URL.createObjectURL(res.data);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${name} #${id}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
};

export const downloadFilterList = async ({
  id,
  file_type = "csv",
  name,
  filter,
  ids,
  excluded_ids,
}) => {
  return axios
    .post(
      `/contact-lists/${id}/download/${file_type}`,
      {
        filter,
        contact_ids: ids,
        excluded_contact_ids: excluded_ids,
      },
      {
        responseType: "blob",
      }
    )
    .then((res) => {
      const url = window.URL.createObjectURL(res.data);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${name}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
};

export const downloadFilterListAll = async ({
  file_type = "csv",
  name,
  filter,
  ids,
  excluded_ids,
}) => {
  return axios
    .post(
      `/contact-lists/download/${file_type}`,
      {
        filter,
        contact_ids: ids,
        excluded_contact_ids: excluded_ids,
      },
      {
        responseType: "blob",
      }
    )
    .then((res) => {
      const url = window.URL.createObjectURL(res.data);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${name}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
};

export const enrichListByExternalApi = async ({
  contact_list_id,
  filter,
  enrich_external_api = "kaspr",
  external_api_key_id,
}) => {
  return axios.post(
    `/contact-lists/${contact_list_id}/enrich/external/${enrich_external_api}/${external_api_key_id}`,
    {
      filter,
    }
  );
};

export const splitList = async ({ contact_list_id, number }) => {
  return axios.post(`/contact-lists/${contact_list_id}/split`, {
    number_lists: number,
  });
};

// Contacts
export const getContacts = async (id) => {
  return axios.get(`/contact-lists/${id}/contacts`);
};

export const searchInContactlist = async ({
  id,
  per_page,
  sort,
  filter,
  query,
}) => {
  return axios.post(
    `/contact-lists/${id}/contacts/search`,
    {
      query: query,
    },
    {
      params: {
        options: JSON.stringify({
          per_page,
          filter,
          sort,
        }),
      },
    }
  );
};

export const searchAllContactlist = async ({
  per_page,
  sort,
  filter,
  query,
}) => {
  let filterWithSearch = query
    ? {
      mode: "and",
      values: [
        { field_name: "any_datafield", type: "contains", value: query },
      ],
    }
    : { mode: "and", values: [] };

  if (filter) {
    filterWithSearch.values.push(filter);
  }

  return axios.post(`/contact-lists/contacts/search`, {
    options: {
      per_page: per_page,
      filter: filterWithSearch,
      sort: sort,
    },
  });
};

export const getFirstPageContacts = async ({ id, per_page, sort, filter }) => {
  return axios.get(`/contact-lists/${id}/contacts`, {
    params: {
      options: JSON.stringify({
        per_page,
        filter,
        sort,
      }),
    },
  });
};

export const requestPage = async (url, page) => {
  const mainUrl = url + page;

  return axios.get(mainUrl);
};

export const importContacts = async (id, formData) => {
  return axios.post(`/contact-lists/${id}/contacts`, formData, {
    headers: {
      accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteContact = async ({ id, parent_id }) => {
  return axios.delete(`/contact-lists/${parent_id}/contacts/${id}`);
};

export const deleteContacts = async (ids) => {
  return axios.delete(`/contact-lists/contacts`, {
    data: {
      contact_ids: ids,
    },
  });
};

export const editContact = async ({ list_id, contact_id, properties }) => {
  return axios.put(`/contact-lists/${list_id}/contacts/${contact_id}`, {
    properties: properties,
  });
};
export const findContact = async (list_id, contact_id) => {
  return axios.get(`/contact-lists/${list_id}/contacts/${contact_id}`);
};

export const copyList = async ({ id, destination_id, contacts_selection }) => {
  return axios.post(
    `/contact-lists/${id}/copy`,
    contacts_selection
      ? {
        contacts_selection,
        contact_list_id_destination: destination_id,
      }
      : undefined
  );
};

export const copyListGlobal = async ({
  destination_id,
  contacts_selection,
}) => {
  return axios.post(`/contact-lists/contacts/copy/${destination_id}`, {
    contacts_selection,
  });
};

export const copyListToUser = async ({ id, user_id, contacts_selection }) => {
  return axios.post(
    `/contact-lists/${id}/copy/to/${user_id}`,
    contacts_selection ? { contacts_selection } : undefined,
    {
      headers: {
        "Is-Main":
          useProfileStore.getState()?.unswitchedProfile?.level === "admin",
      },
    }
  );
};

export const copyContactsToPrm = async ({ ids, status, custom_status }) => {
  return axios.post(`/contact-lists/contacts/copy/prm`, {
    contact_ids: ids,
    status,
    custom_status,
  });
};

export const copyContactListToPrm = async ({
  contact_list_id,
  status,
  custom_status,
  contacts_selection,
}) => {
  return axios.post(`/contact-lists/${contact_list_id}/copy/prm`, {
    status,
    custom_status,
    contacts_selection,
  });
};

export const convertLinkedinLinks = async ({
  contact_list_id,
  linkedin_account_id,
}) => {
  return axios.post(
    `/contact-lists/${contact_list_id}/convert/linkedin/links`,
    { linkedin_account_id }
  );
};

export const deleteFilterContacts = async ({
  id,
  filter,
  ids,
  excluded_ids,
}) => {
  if (id)
    return axios.delete(`/contact-lists/${id}/contacts`, {
      data: {
        filter,
        contact_ids: ids,
        excluded_contact_ids: excluded_ids,
      },
    });
  return axios.delete(`/contact-lists/contacts`, {
    data: {
      contact_ids: ids,
    },
  });
};

export const generateIcebreaker = async ({
  contact_list_id,
  subject,
  language,
  version,
  temperature,
}) => {
  return axios.post(`/contact-lists/${contact_list_id}/icebreaker`, {
    subject,
    language,
    version,
    temperature,
  });
};
export const generateDatafieldContent = async ({
  contact_list_id,
  datafield_id,
  prompt,
  language,
  temperature,
  external_api_key_id,
}) => {
  return axios.post(
    `/contact-lists/${contact_list_id}/datafield-ai/${datafield_id}`,
    {
      prompt,
      language,
      temperature,
      external_api_key_id,
    }
  );
};

export const enrichDatafieldContent = async ({
  contact_list_id,
  datafield_id,
  language,
}) => {
  return axios.post(
    `/contact-lists/${contact_list_id}/datafield-enrich/${datafield_id}`,
    {
      language,
    }
  );
};

export const refreshList = async ({ contact_list_id, linkedin_account_id }) => {
  return axios.post(`/targeting/linkedin/refresh/${contact_list_id}`, {
    linkedin_account_id,
  });
};
export const refreshTargetingLinkedin = async ({
  contact_list_id,
  linkedin_account_id,
}) => {
  return axios.post(`/targeting/linkedin/${contact_list_id}/relaunch-errors`, {
    linkedin_account_id,
  });
};

export const refreshTargetingGoogle = async ({ contact_list_id }) => {
  return axios.post(
    `/targeting/google/extract-maps-search/${contact_list_id}/relaunch-errors`
  );
};

export const enrichList = async ({ contact_list_id, generate_email }) => {
  return axios.post(`/contact-lists/${contact_list_id}/enrich`, {
    generate_email,
  });
};
export const translateList = async ({ contact_list_id, language }) => {
  return axios.post(`/contact-lists/${contact_list_id}/translate`, {
    language,
  });
};

export const deleteToKeepContacts = async ({
  contact_list_id,
  keep_number,
}) => {
  return axios.delete(
    `/contact-lists/${contact_list_id}/delete/company/${keep_number}`
  );
};

export const verifyEmails = async ({ contact_list_id }) => {
  return axios.post(`/contact-lists/${contact_list_id}/email-verifier`);
};
