import { Group } from "@mantine/core";
import { useLanguage } from "../../../../../../stores/LanguageStore";
import UnsubscribeProspect from "../../actions/UnsubscribeProspect";
import ZapierSyncer from "../../../titleSection/zapier/ZapierSyncer";
import DeleteProspect from "../../actions/DeleteProspect";
import {
  useLeadsContainerStore,
  useLeadsSheetStore,
} from "../../../../../../stores/LeadsStore";
import ExcludeProspect from "./../../actions/exclude/ExcludeProspect";

const LeftSheetBottom = ({ current }) => {
  const lang = useLanguage((s) => s.language);
  const currentContainer = useLeadsSheetStore((s) => s.currentContainer);
  const setCurrentContainer = useLeadsSheetStore((s) => s.setCurrentContainer);
  const index = useLeadsSheetStore((s) => s.index);
  const setCurrent = useLeadsSheetStore((s) => s.setCurrent);
  const prevProspect = useLeadsSheetStore((s) => s.prevProspect);
  const setOpened = useLeadsSheetStore((s) => s.setOpened);
  const updateContainer = useLeadsContainerStore((s) => s.updateContainer);

  const onDeleteDone = () => {
    if (currentContainer.id !== 0) {
      updateContainer(currentContainer.id, {
        items: currentContainer.items.filter((x) => x.id !== current?.id),
        total: currentContainer.total - 1,
        needCacheReset: true,
      });
      setCurrentContainer({
        ...currentContainer,
        items: currentContainer.items.filter((x) => x.id !== current?.id),
        total: currentContainer.total - 1,
      });
    }
    if (
      index === currentContainer.items.length - 1 &&
      currentContainer.items.length === 1
    ) {
      return setOpened(false);
    }
    if (index >= currentContainer.items.length - 1) {
      return prevProspect();
    }
    setCurrent(currentContainer.items[index + 1]);
  };

  return (
    <Group pt={8} wrap="nowrap">
      <ZapierSyncer current={current} inSheet />

      <UnsubscribeProspect current={current} inSheet inPrm />

      <ExcludeProspect current={current} inSheet />

      <DeleteProspect
        container={currentContainer}
        current={current}
        inSheet
        onDone={onDeleteDone}
      />
    </Group>
  );
};

export default LeftSheetBottom;
