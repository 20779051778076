import { useConfigStore } from "../stores/ConfigStore";
import { mainAxios as axios } from "./axios";
// Lists
export const getBlacklists = async () => {
  let response = await axios.get(`/blacklists`);

  if (response.data.state) {
    useConfigStore.getState().setBlacklists(response.data.blacklists_list);
  }

  return response;
};

export const findBlacklist = async (id) => {
  let a = axios.get(`/blacklists/${id}`);

  return a;
};

export const createBlacklist = async ({ name, sharing }) => {
  let a = axios.post(`/blacklists`, {
    name: name,
    sharing: sharing,
  });

  return a;
};

export const editBlacklist = async ({ id, name, sharing }) => {
  let a = axios.put(`/blacklists/${id}`, {
    name: name,
    sharing: sharing,
  });

  return a;
};

export const deleteBlacklist = async ({ id }) => {
  let a = axios.delete(`/blacklists/${id}`);

  return a;
};

export const searchInBlacklist = async ({
  id,
  per_page,
  sort,
  filter,
  query,
}) => {
  return axios.post(
    `/blacklists/${id}/data/search`,
    {
      query: query,
    },
    {
      params: {
        options: JSON.stringify({
          per_page,
          filter,
          sort,
        }),
      },
    }
  );
};

export const searchAllBlacklist = async ({ per_page, sort, filter, query }) => {
  return axios.post(
    `/blacklists/data/search`,
    {
      query: query,
    },
    {
      params: {
        options: JSON.stringify({
          per_page: per_page,
          filter: filter,
          sort: sort,
        }),
      },
    }
  );
};

export const getFirstPageBlacklist = async ({ id, per_page, sort, filter }) => {
  return axios.get(`/blacklists/${id}/data`, {
    params: {
      options: JSON.stringify({
        per_page: per_page,
        filter: filter,
        sort: sort,
      }),
    },
  });
};

export const deleteBlacklistData = async ({ id, parent_id }) => {
  return axios.delete(`/blacklists/${parent_id}/data/${id}`);
};

export const deleteBlacklistDatas = async ({ ids }) => {
  return axios.delete(`/blacklists/datas`, {
    data: {
      data_ids: ids,
    },
  });
};

export const importBlacklist = async (id, data, ignore_errors) => {
  return axios.post(`/blacklists/${id}/data`, {
    data: data,
    ignore_errors: ignore_errors,
  });
};
export const importBlacklistFromStepStat = async ({
  workflow_id,
  workflow_programmation_id,
  step_id,
  contact_type,
  blacklist_id,
  per_page,
  filter,
  sort
}) => {
  return axios.post(`/workflows/${workflow_id}/programmation/${workflow_programmation_id}/step/${step_id}/${contact_type}/blacklist/${blacklist_id}`, undefined, {

    params: {
      options: JSON.stringify({
        per_page,
        filter,
        sort,
      }),
    },
  });
};

export const downloadBlacklist = async ({ id, file_type, name }) => {
  return axios
    .get(`/blacklists/${id}/download/${file_type}`, { responseType: "blob" })
    .then((res) => {
      const url = window.URL.createObjectURL(res.data);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${name} #${id}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
};

export const downloadFilterList = async ({
  id,
  file_type = "csv",
  name,
  filter,
  ids,
  excluded_ids,
}) => {
  return axios
    .post(
      `/blacklists/${id}/download/${file_type}`,
      {
        filter,
        data_ids: ids,
        excluded_data_ids: excluded_ids,
      },
      {
        responseType: "blob",
      }
    )
    .then((res) => {
      const url = window.URL.createObjectURL(res.data);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${name}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
};
