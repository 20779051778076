import { useMemo } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { useListStore } from "../../../../../stores/ListStore";
import { Group, Paper, Text, Tooltip } from "@mantine/core";
import { types } from "./jobTypes";
import {
  getDuration,
  parseDate,
} from "../../../../../functions/dates";
import { handleJobItem } from "./JobItemArguments";

const JobItemCompleted = ({ item }) => {
  const lang = useLanguage((s) => s.language);
  const lists = useListStore((s) => s.lists);
  const startDate = new Date(Date.parse(item.start_date));
  const endDate = new Date(Date.parse(item.end_date));
  const queuedDate = new Date(Date.parse(item.queued_date));
  const fromLists = useMemo(() => {
    if (item.type === "Merge") {
      return item.arguments.contactListIds.map((id) => {
        return lists?.find((x) => x.id === id) || { id };
      });
    }
    if (item.type === "Split") {
      return (
        lists?.find((x) => x.id === item.arguments.fromContactListId) || {
          id: item.arguments.fromContactListId,
        }
      );
    }
    return null;
  }, [lists, item]);

  return (
    <Paper withBorder p={"sm"} bg={"#00000006"}>
      <Group>
        <Group direction="row" gap={4} mr={1}>
          {types(item.type)?.icon}

          <Text fw={500} fz={14}>
            {types(item.type, false, item?.arguments?.generateEmail)?.label}
          </Text>
        </Group>
        <Text fz={10} style={{ opacity: 0.75 }}>
          <b>{lang.contact_lists.jobs.queued_date}</b>{" "}
          <Tooltip label={queuedDate.toLocaleString()}>
            <span style={{ marginRight: 8 }}>{parseDate(queuedDate)}</span>
          </Tooltip>
          <b>{lang.contact_lists.jobs.start_date}</b>{" "}
          <Tooltip label={startDate.toLocaleString()}>
            <span style={{ marginRight: 8 }}>{parseDate(startDate)}</span>
          </Tooltip>
          <b>{lang.contact_lists.jobs.end_date}</b>{" "}
          <Tooltip label={endDate.toLocaleString()}>
            <span style={{ marginRight: 8 }}>{parseDate(endDate)}</span>
          </Tooltip>{" "}
          <b>{lang.contact_lists.jobs.duration}</b>{" "}
          <span>{getDuration(endDate, startDate)}</span>
        </Text>
      </Group>
      {handleJobItem({ item: item, fromLists })}
    </Paper>
  );
};

export default JobItemCompleted;
