import { Stack, Tooltip } from "@mantine/core";
import ModelEmailSelector from "../../../../../../components/Selectors/ModelEmailSelector";
import { useFlowStore } from "../../../../../../stores/WorkflowStore";
import EmailAccountSelector from "../../../../../../components/Selectors/EmailAccountSelector";
import {
  IconMailForward,
  IconSignature,
  IconTemplate,
} from "@tabler/icons-react";
import EmailSignatureSelector from "../../../../../../components/Selectors/EmailSignatureSelector";
import { useLanguage } from "../../../../../../stores/LanguageStore";
import { useReactFlow } from "reactflow";
import { getTypeIcon } from "../flowSettings";

const EmailNodeBody = ({ node_id, data, xPos, yPos }) => {
  const lang = useLanguage((s) => s.language);
  const updateNodeData = useFlowStore((s) => s.updateNodeData);

  const handleModelChange = (v) => {
    const newValue = v?.id ? parseInt(v?.id) : null;
    updateNodeData(node_id, {
      model_id: newValue,
    });
  };
  const handleSenderChange = (v) => {
    let isPool = v?.number_of_accounts !== undefined;
    const newValue = v?.id ? parseInt(v?.id) : null;
    updateNodeData(node_id, {
      [isPool ? "pool_id" : "integration_id"]: newValue,
      [isPool ? "integration_id" : "pool_id"]: null,
      signature_id: v?.email_signatures_id || data?.signature_id || null,
    });
  };
  const handleSignatureChange = (v) => {
    const newValue = v?.id ? parseInt(v?.id) : null;
    updateNodeData(node_id, {
      signature_id: newValue,
    });
  };

  const setSelectedNode = useFlowStore((s) => s.setSelectedNode);
  const reactflow = useReactFlow();
  const handleNoValueClick = (toCheck) => {
    if (!toCheck) {
      setSelectedNode({ data, id: node_id, toAdvanced: false });
      setTimeout(() => {
        reactflow?.setCenter(xPos + 142, yPos + 132, {
          duration: 500,
          zoom: 1,
        });
      }, 201);
    }
  };

  return (
    <Stack w={"100%"} pb={6} gap={"xs"}>
      <ModelEmailSelector
        value={data?.model_id}
        onChange={handleModelChange}
        onClick={() => handleNoValueClick(data?.model_id)}
        required
        size={"xs"}
        leftSection={
          <Tooltip label={lang.campaign.selectors.email.tooltip}>
            <IconTemplate size={16} strokeWidth={1.5} />
          </Tooltip>
        }
      />
      <EmailAccountSelector
        value={data?.pool_id || data?.integration_id}
        onChange={handleSenderChange}
        onClick={() =>
          handleNoValueClick(data?.pool_id || data?.integration_id)
        }
        required
        size={"xs"}
      />
      <EmailSignatureSelector
        value={data?.signature_id}
        accountIsPool={!!data?.pool_id}
        onChange={handleSignatureChange}
        required
        size={"xs"}
        leftSection={
          <Tooltip label={lang.campaign.selectors.signature.tooltip}>
            <IconSignature size={16} strokeWidth={1.5} />
          </Tooltip>
        }
      />
    </Stack>
  );
};

export default EmailNodeBody;
