import PopOver from "../../../../components/View/PopOver";
import { Button, Stack, Switch, Text, Tooltip } from "@mantine/core";
import { IconSettings } from "@tabler/icons-react";
import { useLeadsStore } from "../../../../stores/LeadsStore";
import { useLanguage } from "../../../../stores/LanguageStore";

const LeadsConfig = () => {
  const lang = useLanguage((s) => s.language);
  const setShowPersonInCharge = useLeadsStore((s) => s.setShowPersonInCharge);
  const showPersonInCharge = useLeadsStore((s) => s.showPersonInCharge);
  return (
    <PopOver
      opener={(openFunction, ref) => (
        <Tooltip label={lang.prm.leads_config.tooltip}>
          <Button
            ref={ref}
            onClick={openFunction}
            radius={0}
            variant={"outline"}
            style={{
              borderLeft: 0,
              borderBottomRightRadius: 4,
              borderTopRightRadius: 4,
              borderTopWidth: 1,
            }}
            className="toolbar-action-button"
            px={8}
          >
            <IconSettings size={18} />
          </Button>
        </Tooltip>
      )}
      popoverChildren={(closePopover) => (
        <Stack gap={"xs"} p={6}>
          <Text fw={600} size="sm">
            {lang.table.more_settings}
          </Text>
          <Switch
            checked={showPersonInCharge}
            onChange={(e) => setShowPersonInCharge(e.target.checked)}
            label={lang.prm.leads_config.show_prospect}
          />
        </Stack>
      )}
    />
  );
};

export default LeadsConfig;
