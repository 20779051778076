import CreateResellerButton from "./CreateResellerButton";
import {
  Accordion,
  Anchor,
  Button,
  ColorInput,
  CopyButton,
  Divider,
  Group,
  InputLabel,
  LoadingOverlay,
  Stack,
  TagsInput,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import {
  Link,
  useNavigate,
  useParams,
  useRouteLoaderData,
} from "react-router-dom";
import { useLanguage } from "../../../stores/LanguageStore";
import { useEffect, useState } from "react";
import ImageGetter from "./ImageGetter";
import BackButton from "../../../components/View/BackButton";
import EmailAccountSelector from "../../../components/Selectors/EmailAccountSelector";
import { createReseller, editReseller } from "../../../api/UserAPI";
import { notifications } from "@mantine/notifications";
import { getEmails } from "../../../api/EmailAPI";
import PageTitle from "../../../components/View/PageTitle";
import StepLoad from "./onboarding/StepLoad";
import ResellerExtension from "./howto/ResellerExtension";
import { getStatusIcon } from "./DomainsCell";
import CreateSMTP from "../senders/emails/smtp/CreateSMTP";

const ResellerPage = () => {
  const lang = useLanguage((s) => s.language);
  const { resellerId } = useParams();
  const resellerProfile = useRouteLoaderData("reseller");
  const [integrationSmtp, setIntegrationSmtp] = useState(
    resellerProfile?.integration_id || null
  );
  const [logo, setLogo] = useState(null);
  const [logoMenu, setLogoMenu] = useState(null);
  const [favicon, setFavicon] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData);

    formData.set("color_primary", data.color_primary.substring(1));
    formData.set("color_secondary", data.color_secondary.substring(1));
    formData.set("color_muted", data.color_muted.substring(1));

    const emailNotificationsEmail = data.emails_notification_email.split(",");
    const emailNotificationsLinkedin =
      data.emails_notification_linkedin.split(",");
    const emailNotificationsGlobal = data.emails_notification_global.split(",");

    emailNotificationsEmail.forEach((value) => {
      formData.append("emails_notification_email[]", value);
    });
    emailNotificationsLinkedin.forEach((value) => {
      formData.append("emails_notification_linkedin[]", value);
    });
    emailNotificationsGlobal.forEach((value) => {
      formData.append("emails_notification_global[]", value);
    });

    formData.set("logo", logo);
    formData.set("logo_menu", logoMenu);
    formData.set("favicon", favicon);

    if (integrationSmtp) {
      formData.append("integration_id", integrationSmtp);
    }

    setLoading(true);

    const main = resellerId ? editReseller : createReseller;
    main({ id: parseInt(resellerId), formData })
      .then((response) => {
        notifications.show({
          message: resellerId
            ? lang.resellers.row_highlight.updated
            : lang.resellers.row_highlight.created,
          color: "tertiary.8",
        });
        navigate(
          "../?highlight=" +
            (response?.data?.reseller_id || resellerProfile?.id)
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getEmails();
  }, []);

  return (
    <>
      <Group
        className="layout-block top"
        w={"100%"}
        gap={0}
        justify="space-between"
      >
        <PageTitle
          title={
            resellerProfile
              ? resellerProfile?.name
              : lang.resellers.browser_tab_title_create
          }
        />

        {resellerId && <CreateResellerButton />}
      </Group>

      <Stack
        className="layout-block"
        component={"form"}
        onSubmit={handleSubmit}
        pos={"relative"}
      >
        <LoadingOverlay
          visible={loading}
          w={"100%"}
          h={"100%"}
          overlayProps={{
            blur: 15,
          }}
          loaderProps={{
            children: <StepLoad mt={64} />,
          }}
        />
        <Group w={"100%"}>
          <TextInput
            name="name"
            label={lang.resellers.create.dialog.name}
            placeholder={lang.resellers.create.dialog.name}
            defaultValue={resellerProfile?.name}
            required
            autoFocus
            w={300}
          />
        </Group>
        <Divider />
        <Text fw={600} mb={-8}>
          {lang.resellers.domain_settings}
        </Text>
        <Text size="sm">{lang.resellers.domain_text}</Text>
        <Group mt={-8}>
          <Text size="sm">{lang.resellers.domain_text2}</Text>
          <CopyButton value={"resellers-pool1.magileads.net"}>
            {({ copied, copy }) => (
              <Tooltip
                label={copied ? lang.global.copied : lang.global.copy}
                color={copied ? "green" : "black"}
              >
                <Anchor c={"black"} onClick={copy} truncate="end" fw={600}>
                  {"resellers-pool1.magileads.net"}
                </Anchor>
              </Tooltip>
            )}
          </CopyButton>
        </Group>
        <Group w={"100%"} justify="space-between">
          <TextInput
            name="domain_saas2"
            label={lang.resellers.create.dialog.domain_saas}
            placeholder={"ex: app.example.com"}
            description={lang.resellers.domain_saas_desc}
            defaultValue={resellerProfile?.domain_saas2}
            w={"23%"}
            rightSection={
              resellerProfile?.domain_saas2
                ? getStatusIcon(resellerProfile?.domain_saas2_status)
                : null
            }
          />
          <TextInput
            name="domain_files"
            label={lang.resellers.create.dialog.domain_files}
            placeholder={"ex: file.example.com"}
            defaultValue={resellerProfile?.domain_files}
            description={lang.resellers.domain_files_desc}
            w={"23%"}
            rightSection={
              resellerProfile?.domain_files
                ? getStatusIcon(resellerProfile?.domain_files_status)
                : null
            }
          />
          <TextInput
            name="domain_redirect"
            label={lang.resellers.create.dialog.domain_redirect}
            defaultValue={resellerProfile?.domain_redirect}
            placeholder={"ex: redirect.example.com"}
            description={lang.resellers.domain_redirect_desc}
            w={"23%"}
            rightSection={
              resellerProfile?.domain_redirect
                ? getStatusIcon(resellerProfile?.domain_redirect_status)
                : null
            }
          />
          <TextInput
            name="domain_api"
            label={lang.resellers.create.dialog.domain_api}
            placeholder={"ex: api.example.com"}
            defaultValue={resellerProfile?.domain_api}
            description={lang.resellers.domain_api_desc}
            w={"23%"}
            rightSection={
              resellerProfile?.domain_api
                ? getStatusIcon(resellerProfile?.domain_api_status)
                : null
            }
          />
        </Group>
        <Anchor
          href={
            lang.id === "FRA"
              ? "https://support.magileads.net/article/espace-revendeur"
              : "https://support.magileads.net/en-us/article/cname-configuration"
          }
          target="_blank"
        >
          {lang.resellers.need_help}
        </Anchor>

        <Divider />
        <Text fw={600} mb={-8}>
          {lang.resellers.branding}
        </Text>
        <Text size="sm">{lang.resellers.branding_text}</Text>
        <Group justify="space-between" align="start">
          <ImageGetter
            tip_src={lang.resellers.onboarding.step2.logo}
            title={
              <Group gap={"xs"}>
                <InputLabel fw={500} fz={15} required>
                  Logo{" "}
                </InputLabel>
                <Text
                  component="span"
                  style={{ opacity: 0.5, fontSize: 12, marginLeft: 8 }}
                >
                  (.png / .svg / .jpg) max: 2mb
                </Text>
              </Group>
            }
            name={"logo"}
            types={["JPG", "JPEG", "PNG", "SVG"]}
            defaultPreview={resellerProfile?.logo_url}
            setFile={setLogo}
            required
            w={"23%"}
          />

          <ImageGetter
            tip_src={lang.resellers.onboarding.step2.logo_menu}
            title={
              <Text fw={500}>
                Logo Menu{" "}
                <Text
                  component="span"
                  style={{ opacity: 0.5, fontSize: 12, marginLeft: 8 }}
                >
                  (.png / .svg / .jpg) max: 2mb
                </Text>
              </Text>
            }
            name={"logo_menu"}
            types={["JPG", "JPEG", "PNG", "SVG"]}
            defaultPreview={resellerProfile?.logo_menu_url}
            setFile={setLogoMenu}
            w={"23%"}
          />

          <ImageGetter
            tip_src={lang.resellers.onboarding.step2.favicon}
            title={
              <Text fw={500}>
                Favicon{" "}
                <Text
                  component="span"
                  style={{ opacity: 0.5, fontSize: 12, marginLeft: 8 }}
                >
                  (.ico) max: 2mb
                </Text>
              </Text>
            }
            name={"favicon"}
            defaultPreview={resellerProfile?.favicon_url}
            types={["ICO"]}
            isIco
            setFile={setFavicon}
            w={"23%"}
          />

          <Stack w={"23%"} gap="xs">
            <ColorInput
              name="color_primary"
              label={lang.resellers.create.dialog.primary_color}
              defaultValue={
                resellerProfile?.color_primary
                  ? `#${resellerProfile.color_primary}`
                  : "#FDB713"
              }
              format="hex"
              swatches={[
                "#2e2e2e",
                "#868e96",
                "#fa5252",
                "#e64980",
                "#be4bdb",
                "#7950f2",
                "#4c6ef5",
                "#228be6",
                "#15aabf",
                "#12b886",
                "#40c057",
                "#82c91e",
                "#fab005",
                "#fd7e14",
              ]}
            />
            <ColorInput
              name="color_secondary"
              label={lang.resellers.create.dialog.secondary_color}
              defaultValue={
                resellerProfile?.color_secondary
                  ? `#${resellerProfile.color_secondary}`
                  : "#3f51b5"
              }
              format="hex"
              swatches={[
                "#2e2e2e",
                "#868e96",
                "#fa5252",
                "#e64980",
                "#be4bdb",
                "#7950f2",
                "#4c6ef5",
                "#228be6",
                "#15aabf",
                "#12b886",
                "#40c057",
                "#82c91e",
                "#fab005",
                "#fd7e14",
              ]}
            />
            <ColorInput
              name="color_muted"
              label={lang.resellers.create.dialog.header_color}
              defaultValue={
                resellerProfile?.color_muted
                  ? `#${resellerProfile.color_muted}`
                  : "#1a2130"
              }
              format="hex"
              swatches={[
                "#2e2e2e",
                "#868e96",
                "#fa5252",
                "#e64980",
                "#be4bdb",
                "#7950f2",
                "#4c6ef5",
                "#228be6",
                "#15aabf",
                "#12b886",
                "#40c057",
                "#82c91e",
                "#fab005",
                "#fd7e14",
              ]}
            />
          </Stack>
        </Group>

        <Text fw={600} mb={-8}>
          {lang.resellers.create.dialog.integration_smtp}
        </Text>
        <Text size="sm">{lang.resellers.integration_smtp_text}</Text>
        <Group w={610}>
          <EmailAccountSelector
            label={lang.resellers.integration_smtp_helper}
            size={"sm"}
            value={integrationSmtp}
            onChange={(v) => setIntegrationSmtp(v?.id || null)}
            smtpOnly="semi"
          />
        </Group>
        <Anchor my={-8} size="sm" href="/senders/email" target="_blank">
          {lang.resellers.integration_smtp_add}
        </Anchor>

        <Accordion variant="contained" mt={"md"}>
          <Accordion.Item value="advanced">
            <Accordion.Control bg={"var(--mantine-color-gray-0)"}>
              <Text fw={600}>{lang.resellers.notifications}</Text>
            </Accordion.Control>
            <Accordion.Panel>
              <Stack>
                <Group justify="space-between" align="start">
                  <TagsInput
                    label={
                      lang.resellers.create.dialog.email_notification_email
                    }
                    description={lang.resellers.notif_email_desc}
                    name="emails_notification_email"
                    placeholder={lang.global.enter_key_to_add}
                    defaultValue={resellerProfile?.emails_notification_email}
                    w={"32%"}
                  />
                  <TagsInput
                    label={
                      lang.resellers.create.dialog.email_notification_linkedin
                    }
                    description={lang.resellers.notif_linkedin_desc}
                    name="emails_notification_linkedin"
                    placeholder={lang.global.enter_key_to_add}
                    defaultValue={resellerProfile?.emails_notification_linkedin}
                    w={"32%"}
                  />
                  <TagsInput
                    label={
                      lang.resellers.create.dialog.email_notification_global
                    }
                    description={lang.resellers.notif_all_desc}
                    name="emails_notification_global"
                    defaultValue={resellerProfile?.emails_notification_global}
                    placeholder={lang.global.enter_key_to_add}
                    w={"32%"}
                  />
                </Group>
              </Stack>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>

        <Accordion variant="contained" mt={"md"}>
          <Accordion.Item value="advanced">
            <Accordion.Control bg={"var(--mantine-color-gray-0)"}>
              <Text fw={600}>{lang.resellers.advanced}</Text>
            </Accordion.Control>
            <Accordion.Panel>
              <Stack>
                <Group>
                  {resellerProfile && (
                    <ResellerExtension
                      id={resellerProfile.id}
                      name={resellerProfile.name}
                    />
                  )}
                </Group>
                <TextInput
                  name="link_chrome_extension"
                  label={lang.resellers.link_extension}
                  type="url"
                  placeholder="https://chromewebstore.google.com/..."
                  defaultValue={resellerProfile?.link_chrome_extension}
                />
              </Stack>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>

        <Group justify="end" gap={"xl"}>
          <BackButton />
          <Button type="submit" color="primary">
            {resellerProfile
              ? lang.global.save
              : lang.resellers.create_reseller}
          </Button>
        </Group>
      </Stack>
    </>
  );
};

export default ResellerPage;
