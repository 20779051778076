import { TextInput } from "@mantine/core";
import { useState } from "react";
import { useFlowStore } from "../../../../../../../stores/WorkflowStore";
import { useLanguage } from "../../../../../../../stores/LanguageStore";

const EventName = ({ id, goodEvent, event_name }) => {
  const [value, setValue] = useState(event_name);
  const updateNodeData = useFlowStore((s) => s.updateNodeData);
  const lang = useLanguage((s) => s.language);

  const handleEventNameChange = () => {
    updateNodeData(id, { event_type: "call_custom", name: value });
  };

  return (
    <TextInput
      label={lang.workflows.nodes.event_name}
      value={goodEvent ? value : null}
      onBlur={handleEventNameChange}
      onChange={(e) => setValue(e.target.value)}
      placeholder={lang.campaign.selectors.event.name_event}
      mt={-3}
      required
      w={"100%"}
      size="xs"
      error={!value}
    />
  );
};

export default EventName;
