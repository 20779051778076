import { useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { mainAxios } from "../../../../../api/axios";
import { Button, Tooltip } from "@mantine/core";
import { IconX } from "@tabler/icons-react";
import { getLists } from "../../../../../api/ContactAPI";
const stopJob = async (job_uniqid) => {
  return mainAxios.delete(`/jobs/${job_uniqid}`);
};

const StopJob = ({ id, setStopped }) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(false);
  const handleStop = () => {
    setLoading(true);
    stopJob(id)
      .then((response) => {
        getLists()
        setStopped(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Tooltip label={lang.contact_lists.jobs.stop_tooltip}>
      <Button
        variant="subtle"
        color="red"
        ml={"auto"}
        startIcon={<IconX size={16} />}
        loading={loading}
        onClick={handleStop}
        size="compact-sm"
      >
        {lang.contact_lists.jobs.stop_label}
      </Button>
    </Tooltip>
  );
};

export default StopJob;
