import { Select } from "@mantine/core";
import { useLanguage } from "../../stores/LanguageStore";

const FilterModeSelector = ({ value, onChange, borderColor, disabled }) => {
  const lang = useLanguage((s) => s.language);
  return (
    <Select
      value={value}
      onChange={onChange}
      data={[
        {
          value: "and",
          label:
            lang.table.toolbar.filter.conditions.and +
            " : " +
            lang.table.toolbar.filter.conditions.and_text,
        },
        {
          value: "or",
          label:
            lang.table.toolbar.filter.conditions.or +
            " : " +
            lang.table.toolbar.filter.conditions.or_text,
        },
      ]}
      comboboxProps={{ zIndex: 1400, offset: 2, shadow: "sm" }}
      w={320}
      size="xs"
      styles={{
        input: {
          borderColor,
        },
      }}
      readOnly={disabled}
    />
  );
};

export default FilterModeSelector;
