import { useCallback, useState } from "react";
import { Group, Loader, Stack, Switch, Tooltip } from "@mantine/core";
import {
  resumeCampaign,
  resumeStepCampaign,
  stopCampaign,
  stopStepCampaign,
} from "../../../../../api/StatsAPI";
import { useLanguage } from "../../../../../stores/LanguageStore";
import {
  IconCircleOff,
  IconPlayerPlay,
  IconPlayerStopFilled,
} from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";
import CampaignColumnChildCell from "./CampaignColumnChildCell";

const ChildCell = ({ row, step, apiRef }) => {
  const lang = useLanguage((s) => s.language);
  const [switching, setSwitching] = useState(false);
  const handleSwitch = useCallback(
    (workflow_id, prog_id, value, stepId, isInitial) => {
      setSwitching(true);

      let func = value ? resumeStepCampaign : stopStepCampaign;

      func(workflow_id, prog_id, stepId)
        .then((response) => {
          notifications.show({
            message:
              (isInitial
                ? lang.statistics.columns.status.success_initial
                : lang.statistics.columns.status.success_followup) +
              (value
                ? lang.statistics.columns.status.success_activated
                : lang.statistics.columns.status.success_deactivated),
            color: "tertiary.8",
          });

          let newSteps = [...row.steps].map((item) => {
            if (item.id === step.id) {
              return {
                ...item,
                is_stopped: !value,
              };
            }
            return { ...item };
          });
          apiRef.current.updateRows([{ ...row, steps: newSteps }]);
        })
        .finally(() => setSwitching(false));
    },
    [
      apiRef,
      lang.statistics.columns.status.success_activated,
      lang.statistics.columns.status.success_deactivated,
      lang.statistics.columns.status.success_followup,
      lang.statistics.columns.status.success_initial,
      row,
      step.id,
    ]
  );

  return (
    <CampaignColumnChildCell>
      <Tooltip
        label={
          lang.statistics.columns.status[row.stopped ? "paused" : "running"]
        }
        refProp="rootRef"
      >
        <Switch
          size="sm"
          color={"tertiary.8"}
          defaultChecked={!step.is_stopped}
          disabled={!row.write_permission ? true : switching}
          onChange={(e) =>
            handleSwitch(
              row?.workflow?.id,
              row?.prog_id,
              e.target.checked,
              step.id,
              step?.is_initial
            )
          }
          thumbIcon={
            !row.write_permission ? (
              <IconCircleOff size={12} color="red" />
            ) : switching ? (
              <Loader type="oval" size={9} />
            ) : step.is_stopped ? (
              <IconPlayerStopFilled size={11} color="red" />
            ) : (
              <IconPlayerPlay size={11} color="tertiary.8" />
            )
          }
        />
      </Tooltip>
    </CampaignColumnChildCell>
  );
};

const ParentCell = ({ row, apiRef }) => {
  const lang = useLanguage.getState().language;
  const [switching, setSwitching] = useState(false);

  const handleSwitch = useCallback(
    (workflow_id, prog_id, value, stepId) => {
      setSwitching(true);

      let func = value ? resumeCampaign : stopCampaign;

      func(workflow_id, prog_id, stepId)
        .then((response) => {
          apiRef.current.updateRows([{ ...row, stopped: !value }]);
          notifications.show({
            message: value
              ? lang.statistics.row_highlight.created
              : lang.statistics.row_highlight.updated,

            color: "tertiary.8",
          });
        })
        .finally(() => setSwitching(false));
    },
    [
      apiRef,
      lang.statistics.row_highlight.created,
      lang.statistics.row_highlight.updated,
      row,
    ]
  );
  return (
    <Group>
      <Tooltip
        label={
          lang.statistics.columns.status[row.stopped ? "paused" : "running"]
        }
        refProp="rootRef"
      >
        <Switch
          size="sm"
          color={"tertiary.8"}
          defaultChecked={!row.stopped}
          disabled={!row.write_permission ? true : switching}
          onChange={(e) =>
            handleSwitch(row.workflow_id, row.id, e.target.checked)
          }
          thumbIcon={
            !row.write_permission ? (
              <IconCircleOff size={14} color="red" />
            ) : switching ? (
              <Loader type="oval" size={11} />
            ) : row.stopped ? (
              <IconPlayerStopFilled size={12} color="red" />
            ) : (
              <IconPlayerPlay size={12} color="tertiary.8" />
            )
          }
        />
      </Tooltip>
    </Group>
  );
};

export const CampaignColumnStatus = ({ apiRef }) => {
  const lang = useLanguage.getState().language;
  return {
    field: "stopped",
    headerName: lang.statistics.columns.status.header_name,
    width: 64,
    renderCell: ({ row }) => {
      if (!row) {
        return;
      }

      if (row.isChild) {
        return (
          <Stack w="100%">
            {row.steps.map((step, index) => (
              <ChildCell key={step.id} row={row} step={step} apiRef={apiRef} />
            ))}
          </Stack>
        );
      }

      return <ParentCell row={row} apiRef={apiRef} />;
    },
  };
};
