import { Button, Group, Stack, Tooltip } from "@mantine/core";
import {
  IconDeviceFloppy,
  IconRefreshDot,
  IconTrash,
} from "@tabler/icons-react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useState } from "react";
import { updateProfile } from "../../../../api/UserAPI";
import { notifications } from "@mantine/notifications";
import { useProfileStore } from "../../../../stores/UserStore";

const ColumnsSaver = ({ columns, columnVisibility, pageOrderName }) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleSave = () => {
    const allColumns = [...columns];

    let newOrder = allColumns.map((column) => column.field);

    let visibleColumns = [];
    allColumns.forEach((column) => {
      if (Object.keys(columnVisibility).includes(column.field)) {
        if (columnVisibility?.[column.field]) visibleColumns.push(column.field);
      } else visibleColumns.push(column.field);
    });

    let columnsSize = {};
    columns.forEach((column) => {
      columnsSize[column.field] = column.width;
    });

    const newVisibleColumns = visibleColumns;

    const profile = useProfileStore.getState().profile;
    const setProfile = useProfileStore.getState().setProfile;

    const newData = {
      saved_order: {
        ...(profile?.saved_order || {}),

        [pageOrderName]: {
          hidden_columns: columns
            .filter((x) => !newVisibleColumns.includes(x.field))
            .map((x) => x.field),
          order_columns: newOrder.filter((x) => x !== "actions"),
          size_columns: columnsSize,
        },
      },
    };

    setLoading(true);
    updateProfile(null, newData)
      .then((response) => {
        notifications.show({
          message: lang.table.toolbar.columns.save_success,
          color: "tertiary.8",
        });
        setProfile({ ...profile, ...newData });
      })
      .finally(() => setLoading(false));
  };

  const handleDelete = () => {
    const allColumns = [...columns];

    let visibleColumns = [];
    allColumns.forEach((column) => {
      if (Object.keys(columnVisibility).includes(column.field)) {
        if (columnVisibility?.[column.field]) visibleColumns.push(column.field);
      } else visibleColumns.push(column.field);
    });

    const newVisibleColumns = visibleColumns;

    const profile = useProfileStore.getState().profile;
    const setProfile = useProfileStore.getState().setProfile;

    const newData = {
      saved_order: {
        ...(profile?.saved_order || {}),

        [pageOrderName]: {
          hidden_columns: columns
            .filter((x) => !newVisibleColumns.includes(x.field))
            .map((x) => x.field),
          order_columns: null,
          size_columns: null,
        },
      },
    };

    setDeleting(true);
    updateProfile(null, newData)
      .then((response) => {
        notifications.show({
          message: lang.table.toolbar.columns.save_success,
          color: "tertiary.8",
        });
        setProfile({ ...profile, ...newData });
      })
      .finally(() => setDeleting(false));
  };

  return (
    <Stack gap={0}>
      <Tooltip label={lang.table.toolbar.columns.save_tooltip}>
        <Button
          size="compact-xs"
          onClick={handleSave}
          variant="light"
          leftSection={<IconDeviceFloppy size={16} />}
          color="tertiary.8"
          mt={"xs"}
          loading={loading}
          disabled={!pageOrderName}
        >
          {lang.table.toolbar.columns.save_order_columns}
        </Button>
      </Tooltip>

      <Tooltip label={lang.table.toolbar.columns.save_tooltip}>
        <Button
          size="compact-xs"
          onClick={handleDelete}
          variant="light"
          color="red"
          mt={"xs"}
          loading={deleting}
          disabled={!pageOrderName}
          leftSection={<IconRefreshDot size={16} />}
        >
          {lang.table.toolbar.columns.reset_order_columns}
        </Button>
      </Tooltip>
    </Stack>
  );
};

export default ColumnsSaver;
