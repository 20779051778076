import {
  ActionIcon,
  Anchor,
  Divider,
  Group,
  LoadingOverlay,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import { getProfile, updateProfile, updateSelf } from "../../api/UserAPI";
import { useProfileStore } from "../../stores/UserStore";
import { IconPin, IconTrash, IconUpload } from "@tabler/icons-react";
import { useLanguage } from "../../stores/LanguageStore";
import { filterNumberTypes } from "./filterSettings";
import { LinearProgress } from "@mui/material";
import { notifications } from "@mantine/notifications";

const FilterList = ({
  pageName,
  handleFilter,
  loading,
  setLoading,
  fields,
}) => {
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);

  const filters = useMemo(() => {
    return profile?.saved_filters?.[pageName]?.map((item, index) => ({
      ...item,
      id: index,
    }));
  }, [pageName, profile]);

  const handleFilterImport = (f) => {
    let newFilter = {
      mode: f?.mode,
      values: f?.values?.map((item) => {
        let allFieldsOptions = fields.reduce((acc, v) => [...v.options], []);
        let isNumber =
          allFieldsOptions.find((x) => x.id === item.field_name)?.type ===
          "number";

        return {
          ...item,
          type: isNumber
            ? Object.keys(filterNumberTypes).find(
                (key) => filterNumberTypes[key] === item.type
              )
            : item?.type,
        };
      }),
    };

    handleFilter(newFilter);
  };

  const [deleting, setDeleting] = useState(false);
  const [pinning, setPinning] = useState(false);
  const handleDeleteItem = (index) => {
    setDeleting(index);
    getProfile().then((res) => {
      let profile = res.data.user_profile;
      let filterSector = [...profile.saved_filters[pageName]];
      filterSector.splice(index, 1);
      const newData = {
        saved_filters: {
          ...profile.saved_filters,
          [pageName]: filterSector,
        },
      };

      updateProfile(null, newData).then((response) => {
        updateSelf()
          .then(() => {
            notifications.show({
              message: lang.table.toolbar.filter.filter_pinned_deleted,
              color: "tertiary.8",
            });
          })
          .finally(() => setDeleting(false));
      });
    });
  };

  const handlePin = (index) => {
    setPinning(index);
    getProfile().then((res) => {
      let profile = res.data.user_profile;
      let filterSector = [...profile.saved_filters[pageName]];

      let isPinned = false;

      let newFilterSector = filterSector.map((f, i) => {
        if (i === index) {
          if (f?.pinned) {
            isPinned = true;
          }
          return {
            ...f,
            pinned: !f?.pinned,
          };
        }
        return {
          ...f,
          pinned: false,
        };
      });
      const newData = {
        saved_filters: {
          ...profile.saved_filters,
          [pageName]: newFilterSector,
        },
      };

      updateProfile(null, newData).then((response) => {
        updateSelf()
          .then(() => {
            notifications.show({
              message: isPinned
                ? lang.table.toolbar.filter.unpinned
                : lang.table.toolbar.filter.pinned,
              color: "tertiary.8",
            });
          })
          .finally(() => setPinning(false));
      });
    });
  };

  useEffect(() => {
    if (loading) updateSelf().finally(() => setLoading(false));
  }, [loading]);

  return (
    <>
      <Divider orientation="vertical" />
      <Stack
        pt={3}
        pos={"relative"}
        w={250}
        mah={400}
        style={{ overflow: "auto" }}
      >
        {filters?.length > 0 ? (
          <>
            {loading && (
              <LinearProgress
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  height: 3,
                }}
              />
            )}
            {filters?.map((filterItem, index) => (
              <Group key={filterItem?.id} wrap="nowrap" gap={0}>
                <Anchor
                  fw={600}
                  truncate="end"
                  mr={4}
                  size="sm"
                  onClick={() => handleFilterImport(filterItem?.filter)}
                >
                  {filterItem?.name}
                </Anchor>

                <Tooltip label={lang.table.toolbar.filter.import}>
                  <ActionIcon
                    variant="light"
                    onClick={() => handleFilterImport(filterItem?.filter)}
                    ml={"auto"}
                    size={"sm"}
                    color="tertiary.8"
                    disabled={deleting !== false || pinning !== false}
                  >
                    <IconUpload size={14} />
                  </ActionIcon>
                </Tooltip>

                <Tooltip label={lang.table.toolbar.filter.delete_pinned}>
                  <ActionIcon
                    variant="subtle"
                    onClick={() => handleDeleteItem(index)}
                    ml={8}
                    size={"sm"}
                    color="red"
                    loading={deleting === index}
                    disabled={deleting !== false || pinning !== false}
                  >
                    <IconTrash size={14} />
                  </ActionIcon>
                </Tooltip>

                <Tooltip
                  label={
                    filterItem?.pinned
                      ? lang.table.toolbar.filter.unpin
                      : lang.table.toolbar.filter.pin
                  }
                >
                  <ActionIcon
                    variant={filterItem?.pinned ? "filled" : "subtle"}
                    onClick={() => handlePin(index)}
                    ml={8}
                    size={"sm"}
                    loading={pinning === index}
                    disabled={deleting !== false || pinning !== false}
                  >
                    <IconPin size={18} />
                  </ActionIcon>
                </Tooltip>
              </Group>
            ))}
          </>
        ) : loading ? (
          <LoadingOverlay visible loaderProps={{ size: 24 }} />
        ) : (
          <Text size="sm" opacity={0.5}>
            {lang.table.toolbar.filter.no_pinned_filters}
          </Text>
        )}
      </Stack>
    </>
  );
};

export default FilterList;
