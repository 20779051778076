import { ActionIcon, Stack, Text } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";

const TargetingGoogleUrlItem = ({ value, handleDelete }) => {
  return (
    <Stack
      style={{
        border: "#00000021 solid 1px",
        padding: 8,
        paddingRight: 8,
        position: "relative",
        marginBottom: 1,
        borderRadius: 4,
      }}
    >
      <Text flex={1} truncate="end" maw={"90%"}>
        {value}
      </Text>

      <ActionIcon
        variant="subtle"
        color="red"
        className="on-hover-opacity"
        style={{
          position: "absolute",
          right: 8,
          top: 4,
          minWidth: 0,
        }}
        onClick={handleDelete}
      >
        <IconTrash size={16} />
      </ActionIcon>
    </Stack>
  );
};

export default TargetingGoogleUrlItem;
