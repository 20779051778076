import { Group, Modal, Stack, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { useProfileStore, useSessionStore } from "../../../../stores/UserStore";
import DontShowAgain from "../../../../components/View/DontShowAgain";
import BackButton from "../../../../components/View/BackButton";
import { useLanguage } from "../../../../stores/LanguageStore";

const ImportWelcome = ({ type, title, video_url }) => {
  const lang = useLanguage((s) => s.language);
  const [opened, setOpened] = useState(false);
  const profile = useProfileStore((s) => s.profile);
  const reseller = useSessionStore((s) => s.reseller);

  useEffect(() => {
    if (!profile?.dont_show_values?.[type] && reseller?.name === "Magileads") {
      setTimeout(() => {
        setOpened(true);
      }, 500);
    }
  }, [profile?.dont_show_values, reseller?.name, type]);

  return (
    <Modal
      opened={opened}
      size={800}
      onClose={() => setOpened(false)}
      title={title}
    >
      <Stack>
        <Text fw={600} size="lg">
          {lang.contact_lists.import.how.title}
        </Text>

        <iframe
          width="100%"
          height="400"
          src={video_url}
          title="YouTube video player"
          allowfullscreen=""
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          style={{
            borderRadius: 12,
            overflow: "hidden",
            border: "1px solid #00000033",
          }}
        />

        <Group justify="space-between" mt={"sm"}>
          <DontShowAgain type={type} />
          <BackButton ml={"auto"} onClick={() => setOpened(false)} />
        </Group>
      </Stack>
    </Modal>
  );
};

export default ImportWelcome;
