import { lazy } from "react";
import successAnim from "../../../assets/lotties/success.json";
import { useLanguage } from "../../../stores/LanguageStore";
import { Button, Stack, Text } from "@mantine/core";
import { useNavigate } from "react-router-dom";

const Lottie = lazy(() => import("react-lottie"));

const PRFinish = () => {
  const lang = useLanguage((s) => s.language);
  const navigate = useNavigate();
  return (
    <Stack>
      <Lottie
        options={{
          animationData: successAnim,
          autoplay: true,
          loop: true,
        }}
        height={120}
      />
      <Text fz={24} ta={"center"}>
        Votre mot de passe a été modifié avec succès <br />{" "}
        <span style={{ fontSize: 22 }}>
          Vous pouvez maintenant vous connecter.
        </span>
      </Text>

      <Button
        size="md"
        mt={"md"}
        onClick={() => navigate("/login")}
        w={150}
        mx="auto"
      >
        {lang.password_recovery.step_finish.button_label}
      </Button>
    </Stack>
  );
};

export default PRFinish;
