import {
  ActionIcon,
  Anchor,
  Badge,
  Checkbox,
  Group,
  HoverCard,
  Indicator,
  Paper,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import { useCallback, useMemo } from "react";
import {
  useLeadsContainerStore,
  useLeadsSheetStore,
  useLeadsStore,
} from "../../../../stores/LeadsStore";
import { useLanguage } from "../../../../stores/LanguageStore";
import {
  IconBell,
  IconBriefcase,
  IconBuildingCommunity,
  IconSpeakerphone,
} from "@tabler/icons-react";
import { getDifference, reminderTypes } from "../utils";
import { parseDate, parseDateFuture } from "../../../../functions/dates";
import Answers from "./answers/Answers";
import MoreActions from "./actions/MoreActions";
import PersonInCharge from "./responsable/PersonInCharge";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import LeadHover from "./hoverContent/LeadHover";
import PositiveChanger from "./actions/PositiveChanger";
import ResumeWorkflow from "./actions/ResumeWorkflow";

const noResponses = ["opener", "clicker"];

const LeadItem = ({ current, container, index }) => {
  const lang = useLanguage((s) => s.language);
  const updateContainer = useLeadsContainerStore((s) => s.updateContainer);
  const selectedIds = useLeadsContainerStore((s) => s.selectedIds);
  const setSelectedIds = useLeadsContainerStore((s) => s.setSelectedIds);
  const selectedColumns = useLeadsContainerStore((s) => s.selectedColumns);
  const unselectedIds = useLeadsContainerStore((s) => s.unselectedIds);
  const setUnselectedIds = useLeadsContainerStore((s) => s.setUnselectedIds);

  const containerColor = useMemo(
    () => `#${container?.color || "333333"}`,
    [container]
  );

  const handleShowDetail = (tab) => {
    const setOpened = useLeadsSheetStore.getState().setOpened;
    const setIndex = useLeadsSheetStore.getState().setIndex;
    const setCurrent = useLeadsSheetStore.getState().setCurrent;
    const setCurrentContainer =
      useLeadsSheetStore.getState().setCurrentContainer;
    const setActiveTab = useLeadsSheetStore.getState().setActiveTab;
    setOpened(true);
    setIndex(index);
    setCurrent(current);
    setCurrentContainer(container);
    if (tab) {
      setActiveTab(tab);
    }
  };

  //#region About
  const about = useMemo(() => {
    const aboutList = [
      { key: "%last_name%", value: "lastName" },
      { key: "%first_name%", value: "firstName" },
      { key: "%job_title%", value: "jobTitle" },
      { key: "%company%", value: "company" },
    ];
    let result = {};

    aboutList.forEach((item) => {
      result[item.value] =
        current?.properties?.find((x) => x.identifier_placeholder === item.key)
          ?.value || " - ";
    });

    return result;
  }, [current?.properties]);

  const difference = useMemo(() => {
    return getDifference(current?.calls[0]?.call_date);
  }, [current?.calls]);
  //#endregion

  //#region Selection
  const handleCheck = useCallback(
    (e) => {
      let newValue = e.target.checked;

      // Checking
      if (newValue === true) {
        if (selectedColumns.includes(container.id)) {
          let newSelection = { ...unselectedIds };
          newSelection[container.id] = newSelection[container.id]?.filter(
            (x) => x !== current.id
          );
          setUnselectedIds(newSelection);
        } else {
          let newSelection = { ...selectedIds };
          newSelection[container.id] = [
            ...(newSelection[container.id] || []),
            current.id,
          ];
          setSelectedIds(newSelection);
        }
      }
      // Unchecking
      else {
        if (selectedColumns.includes(container.id)) {
          let newSelection = { ...unselectedIds };
          newSelection[container.id] = [
            ...(newSelection[container.id] || []),
            current.id,
          ];
          setUnselectedIds(newSelection);
        } else {
          let newSelection = { ...selectedIds };
          newSelection[container.id] = newSelection[container.id]?.filter(
            (x) => x !== current.id
          );
          setSelectedIds(newSelection);
        }
      }
    },
    [
      selectedIds,
      unselectedIds,
      selectedColumns,
      setSelectedIds,
      setUnselectedIds,
      container.id,
      current.id,
    ]
  );

  const getChecked = useCallback(() => {
    const selected = Object.values(selectedIds).flatMap((x) => x);
    const notSelected = Object.values(unselectedIds).flatMap((x) => x);
    if (selectedColumns.includes(container.id)) {
      if (notSelected.includes(current.id)) {
        return false;
      }
      return true;
    }
    if (selected.includes(current.id)) {
      return true;
    }
    return false;
  }, [selectedIds, unselectedIds, selectedColumns, container.id, current.id]);
  //#endregion

  //#region Drag
  const onDragStart = (event) => {
    event.dataTransfer.setData("application/prm", JSON.stringify(current));
    event.dataTransfer.effectAllowed = "move";
    // setTimeout for chrome bug
    setTimeout(() => {
      document.querySelectorAll(".container-overlay").forEach((element) => {
        element.style.display = "block";
      });
    }, 10);
  };

  const onDragEnd = (event) => {
    setTimeout(() => {
      let dropped = document
        .getElementById("main-prm")
        .classList.contains("dropped");

      document.querySelectorAll(".container-overlay").forEach((element) => {
        element.style.display = "none";
      });
      document.querySelectorAll(".main-overlay").forEach((element) => {
        element.style.background = "none";
      });

      if (dropped) {
        document.getElementById("main-prm").classList.remove("dropped");
        let newItems = container.items.filter((item) => item.id !== current.id);
        updateContainer(container.id, {
          items: newItems,
          total: container.total - 1,
          needCacheReset: true,
        });
      }
    }, 50);
  };
  //#endregion

  const prms = useLeadsStore((s) => s.prms);
  const { userId } = useParams();
  const isNew = useMemo(() => {
    let actualPrm = prms?.find((x) => x.id === parseInt(userId)) || prms[0];

    const date_view = actualPrm?.date_view;
    return (
      container.id === "answerer" &&
      dayjs(date_view).diff(dayjs(current.status_changed_date)) < 0
    );
  }, [container.id, current.status_changed_date, prms, userId]);

  return (
    <HoverCard
      openDelay={800}
      position="left"
      keepMounted={false}
      offset={2}
      withArrow
    >
      <HoverCard.Target>
        <Paper
          withBorder
          h={"calc(100% - 16px)"}
          style={{
            boxShadow: getChecked()
              ? `var(--mantine-primary-color-3) 0px 0px 4px`
              : `#00000009 1px 2px 4px`,
            opacity: current?.new ? 0.4 : 1,
            borderColor: getChecked()
              ? "var(--mantine-primary-color-5)"
              : difference.result < 0
              ? "red"
              : containerColor + 44,
            cursor: "grab",
            position: "relative",
          }}
          px={"xs"}
          py={6}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          draggable
          component={Stack}
          gap={0}
        >
          <Group gap={"xs"} wrap="nowrap" pr={18}>
            <Checkbox
              checked={getChecked()}
              onChange={handleCheck}
              size="xs"
              color={"primary"}
              radius={"xs"}
            />
            <Tooltip label={about.firstName + " " + about.lastName}>
              <Anchor
                truncate
                fw={600}
                c={"#333"}
                w={"80%"}
                size="sm"
                onClick={() => handleShowDetail("activity")}
              >
                {about.firstName} {about.lastName}
              </Anchor>
            </Tooltip>

            <MoreActions current={current} container={container} />
          </Group>

          <Group wrap="nowrap" mt={"xs"} gap={"xs"} w={"100%"} opacity={0.75}>
            <Tooltip label={lang.prm.job_title}>
              <Group>
                <IconBriefcase size={12} strokeWidth={1.3} />
              </Group>
            </Tooltip>

            <Tooltip label={about.jobTitle} disabled={about.jobTitle === " - "}>
              <Text size="xs" truncate="end" w={"fit-content"}>
                {about.jobTitle}
              </Text>
            </Tooltip>
          </Group>

          <Group wrap="nowrap" mt={"xs"} gap={"xs"} w={"100%"} opacity={0.75}>
            <Tooltip label={lang.prm.company}>
              <Group>
                <IconBuildingCommunity size={12} strokeWidth={1.3} />
              </Group>
            </Tooltip>

            <Tooltip label={about.company} disabled={about.company === " - "}>
              <Text size="xs" truncate="end" w={"fit-content"}>
                {about.company}
              </Text>
            </Tooltip>
          </Group>

          <Group
            wrap="nowrap"
            mt={6}
            gap={"md"}
            w={"100%"}
            pt={4}
            style={{
              borderTop: "1px solid #00000018",
            }}
          >
            <Tooltip
              label={`${current?.programmations.length} ${lang.prm.campaign}${
                current?.programmations.length > 1 ? "s" : ""
              }`}
            >
              <Indicator
                color="red"
                inline
                offset={2}
                disabled
                styles={{
                  indicator: {
                    width: 8,
                    height: 8,
                    minWidth: 0,
                  },
                }}
                size={16}
              >
                <ActionIcon
                  size={"xs"}
                  variant="subtle"
                  color="pink"
                  className="lead-item-button"
                  onClick={() => handleShowDetail("campaign")}
                >
                  <IconSpeakerphone size={14} />
                </ActionIcon>
              </Indicator>
            </Tooltip>

            <Tooltip
              label={`${current?.calls.length} ${lang.prm.reminder}${
                current?.calls.length > 1 ? "s" : ""
              }`}
            >
              <Indicator
                color="red"
                inline
                offset={2}
                disabled={current?.calls.length === 0}
                styles={{
                  indicator: {
                    width: 8,
                    height: 8,
                    minWidth: 0,
                  },
                }}
                size={16}
              >
                <ActionIcon
                  size={"xs"}
                  variant="subtle"
                  color="red"
                  className="lead-item-button"
                  opacity={current?.calls.length === 0 ? undefined : 1}
                  onClick={() => handleShowDetail("reminder")}
                >
                  <IconBell size={14} />
                </ActionIcon>
              </Indicator>
            </Tooltip>

            <Answers
              container={container}
              current={current}
              index={index}
              disabled={noResponses.includes(container.id)}
              inPrm
            />

            <PositiveChanger
              containerId={container.id}
              is_positive={current?.is_positive}
              itemId={current.id}
            />

            {current.workflow_waiting_steps.length > 0 && (
              <ResumeWorkflow current={current} />
            )}

            {isNew && (
              <Badge size="xs" color="secondary.5" fz={8} h={16} w={36} mt={-4}>
                new
              </Badge>
            )}

            <PersonInCharge current={current} />
          </Group>

          {current.calls.length > 0 && (
            <Group mt={"auto"} pt={2}>
              <Tooltip label={current.calls[0]?.name}>
                <Text c={"red"} size="xs" fw={500}>
                  {reminderTypes()[current.calls[0]?.type]}:{" "}
                  {difference.result < 0
                    ? parseDate(current.calls[0]?.call_date)
                    : parseDateFuture(current.calls[0]?.call_date)}
                </Text>
              </Tooltip>
            </Group>
          )}
        </Paper>
      </HoverCard.Target>

      <HoverCard.Dropdown>
        <LeadHover container={container} current={current} />
      </HoverCard.Dropdown>
    </HoverCard>
  );
};

export default LeadItem;
