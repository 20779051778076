import { Text } from "@mantine/core";

export const booleanColumn = ({
  field,
  headerName,
  width = 100,
  trueLabel = "Oui",
  falseLabel = "Non",
  noColor,
  filterable = false,
  inverse,
  sortable,
}) => {
  return {
    field: field,
    headerName: headerName,
    width: width,
    filterable: filterable,
    id: field,
    sortable: sortable,
    valueGetter: ({ value }) => {
      return value ? trueLabel : falseLabel;
    },
    renderCell: ({ value }) => {
      if (value === trueLabel) {
        return (
          <Text
            c={noColor?.success ? undefined : inverse ? "red" : "tertiary.8"}
            fw={600}
            fz={12}
          >
            {trueLabel}
          </Text>
        );
      }
      return (
        <Text
          c={noColor?.error ? "#00000033" : inverse ? "primary" : "red"}
          fw={600}
          fz={12}
        >
          {falseLabel}
        </Text>
      );
    },
  };
};
