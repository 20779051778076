import { Button, Group, Menu, ThemeIcon, Tooltip } from "@mantine/core";
import {
  IconChevronDown,
  IconShare2,
  IconShare3,
  IconUser,
} from "@tabler/icons-react";
import { useEffect, useMemo, useState } from "react";
import { getOtherPrms } from "../../../../api/PRMAPI";
import { useNavigate, useParams } from "react-router-dom";
import { useLeadsStore } from "../../../../stores/LeadsStore";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useProfileStore } from "../../../../stores/UserStore";

const LeadsBaseSelector = () => {
  const lang = useLanguage((s) => s.language);
  const { userId } = useParams();
  const [loading, setLoading] = useState(true);
  const prms = useLeadsStore((s) => s.prms);
  const profile = useProfileStore((s) => s.profile);

  const navigate = useNavigate();
  const handlePrm = (id) => {
    navigate("/leads-tracking/" + id);
  };

  useEffect(() => {
    getOtherPrms().finally(() => setLoading(false));
  }, []);

  const actualPrmName = useMemo(() => {
    const x = userId
      ? prms?.find((x) => x.id === parseInt(userId))
      : prms.find((x) => x.id === profile?.id);

    return `${x?.first_name || "-"} ${x?.last_name || "-"} #${x?.id || "-"}`;
  }, [prms, profile?.id, userId]);

  return (
    <>
      <Group ml={"auto"}>
        <Menu>
          <Menu.Target>
            <Button
              leftSection={
                userId && parseInt(userId) !== profile.id ? (
                  <Tooltip label={lang.prm.from_sharing}>
                    <ThemeIcon variant="subtle" size={"sm"} color="gray" px={0}>
                      <IconShare3 size={18} />{" "}
                    </ThemeIcon>
                  </Tooltip>
                ) : (
                  <Tooltip label={lang.prm.your_prm}>
                    <ThemeIcon variant="subtle" size={"sm"} color="gray" px={0}>
                      <IconUser size={18} />{" "}
                    </ThemeIcon>
                  </Tooltip>
                )
              }
              rightSection={<IconChevronDown size={16} />}
              size="sm"
              variant="outline"
              loading={loading}
              radius={"sm"}
            >
              {actualPrmName}
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            {prms.map((item) => (
              <Menu.Item
                key={item.id}
                onClick={() => handlePrm(item.id)}
                pos={"relative"}
                leftSection={
                  item.id !== profile.id ? (
                    <Tooltip label={lang.prm.from_sharing}>
                      <ThemeIcon
                        variant="subtle"
                        size={"sm"}
                        color="gray"
                        px={0}
                      >
                        <IconShare3 size={18} />{" "}
                      </ThemeIcon>
                    </Tooltip>
                  ) : (
                    <Tooltip label={lang.prm.your_prm}>
                      <ThemeIcon
                        variant="subtle"
                        size={"sm"}
                        color="gray"
                        px={0}
                      >
                        <IconUser size={18} />{" "}
                      </ThemeIcon>
                    </Tooltip>
                  )
                }
              >
                <span>
                  {lang.prm.other_title} {item.first_name} {item.last_name}
                </span>

                {/* <Badge pos={"absolute"} right={0}>
                  {item.}
                </Badge> */}
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        </Menu>
      </Group>
    </>
  );
};

export default LeadsBaseSelector;
