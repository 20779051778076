import {
  Button,
  Flex,
  Group,
  LoadingOverlay,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import TextEditor from "../../../../components/ExternalEditors/TextEditor";
import { useEffect, useMemo, useRef, useState } from "react";
import { useConfigStore } from "../../../../stores/ConfigStore";
import { getFields } from "../../../../api/ContactAPI";
import { useLanguage } from "../../../../stores/LanguageStore";
import {
  createLinkedinInvitation,
  createLinkedinMessage,
  createSMS,
  getLinkedinInvitations,
  getLinkedinMessages,
  getSMS,
} from "../../../../api/ModelsApi";
import { notifications } from "@mantine/notifications";
import MessageModalPreview from "../../../../components/View/MessageModalPreview";
import FolderSelector from "../../../../components/Selectors/FolderSelector";
import { useRouteLoaderData } from "react-router-dom";
import useNavigationBlocker from "../../../../components/View/NavigationBlocker";
import { useUIStore } from "../../../../stores/UIStore";
import BackButton from "../../../../components/View/BackButton";

const CreateModelLinkedin = ({
  handleClose,
  type,
  onCreated,
  maxChars = 280,
}) => {
  const editorRef = useRef();
  const lang = useLanguage((s) => s.language);
  const datafields = useConfigStore((s) => s.datafields);
  const datafieldTokens = useMemo(() => {
    let df = datafields.map((x) => ({
      label: x.name,
      value: x.identifier_placeholder,
    }));
    df.push(
      {
        label:
          lang.components.variable_manager.dialog.unsubscribe_variable_name,
        value: "%unsubscribe%",
        name: lang.components.variable_manager.dialog.unsubscribe_variable_name,
      },
      {
        label: lang.components.variable_manager.dialog.webversion_variable_name,
        value: "%webversion%",
        name: lang.components.variable_manager.dialog.webversion_variable_name,
      }
    );
    return df;
  }, [datafields, lang]);
  const [dirty, setDirty] = useState(false);
  const folderProfile = useRouteLoaderData("model-folder");
  const [folder, setFolder] = useState(folderProfile || null);

  useEffect(() => {
    getFields();
  }, []);

  const handlePreview = ({ setText, setSender, open }) => {
    const fd = new FormData(document.getElementById("model-form"));
    const data = Object.fromEntries(fd);

    setText(editorRef.current.getContent({ format: "text" }));
    setSender(data?.sender);
    open();
  };

  const [submitting, setSubmitting] = useState(false);
  const onSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const fd = new FormData(e.target);
    const data = Object.fromEntries(fd);

    let text = editorRef.current.getContent({ format: "text" });

    let func;
    if (type === "linkedin_message") {
      func = createLinkedinMessage;
    }
    if (type === "linkedin_invitation") {
      func = createLinkedinInvitation;
    }
    if (type === "sms") {
      func = createSMS;
    }
    return func({
      name: data.name,
      text,
      sender: data?.sender,
    })
      .then((response) => {
        let newId = response.data.model_id;
        setSubmitting(true);

        let funcGetter;
        if (type === "linkedin_message") {
          funcGetter = getLinkedinMessages;
        }
        if (type === "linkedin_invitation") {
          funcGetter = getLinkedinInvitations;
        }
        if (type === "sms") {
          funcGetter = getSMS;
        }

        funcGetter()
          .then((res) => {
            let newItemsList = res.data.models_list;
            let newItem = newItemsList.find((x) => x.id === newId);
            setDirty(false);
            notifications.show({
              title: lang.models.created,
              message: lang.models.created_text,
              color: "tertiary.8",
            });
            setTimeout(() => {
              onCreated?.(newItem);
              handleClose?.();
            }, 100);
          })
          .finally(() => {
            setSubmitting(false);
          });
        if (folderProfile) {
          useUIStore.getState().setAsideLoading(true);
        }
      })
      .catch(() => setSubmitting(false));
  };

  useNavigationBlocker(dirty);

  return (
    <>
      <LoadingOverlay
        visible={submitting}
        overlayProps={{ blur: 1 }}
        zIndex={1001}
      />

      <Stack
        component={"form"}
        onSubmit={onSubmit}
        gap={0}
        id="model-form"
        onChange={() => setDirty(true)}
        h={"100%"}
        flex={1}
      >
        <Group wrap="nowrap" mb={"sm"}>
          <TextInput
            label={lang.model_emails.create.dialog.name}
            placeholder={lang.model_emails.create.dialog.name}
            name="name"
            w="50%"
            required
            data-autoFocus
            autoFocus
          />
          {type === "sms" && (
            <TextInput
              label={lang.components.email_preview.sender}
              placeholder={lang.components.email_preview.sender}
              name="sender"
              w="50%"
              required
              maxLength={11}
              minLength={4}
            />
          )}
          {folderProfile && (
            <Group w={400}>
              <FolderSelector
                value={folder}
                setValue={setFolder}
                type={"Model"}
              />
            </Group>
          )}
        </Group>

        <TextEditor
          editorRef={editorRef}
          placeholder={lang.model_emails.create.dialog.placeholder}
          tokens={datafieldTokens}
          // noTop
          toolbar={"undo redo | tokens |"}
          maxChars={maxChars}
          onKeyUp={() => setDirty(true)}
        >
          {/* <Group
            wrap="nowrap"
            style={{
              position: "absolute",
              top: 7,
              right: 7,
              zIndex: 4,
            }}
          >
            <Generator disabled />
          </Group> */}
        </TextEditor>
        {type === "linkedin_invitation" && (
          <Text mt={"xs"} c={"red"} size="sm">
            {lang.model_invitation_linkedin.create.dialog.info_limit}
          </Text>
        )}

        <Group justify="space-between" mt={"sm"}>
          <Flex>
            <MessageModalPreview getData={handlePreview} type={type} />
          </Flex>
          <Flex gap={"md"}>
            <BackButton onClick={handleClose} />
            <Button type="submit" color="tertiary.8">
              {lang.model_emails.create.dialog.submit_button}
            </Button>
          </Flex>
        </Group>
      </Stack>
    </>
  );
};

export default CreateModelLinkedin;
