import { IconCheck, IconX } from "@tabler/icons-react";
import {
  Box,
  Center,
  Group,
  PasswordInput,
  Popover,
  Progress,
  Text,
} from "@mantine/core";
import { useInputState } from "@mantine/hooks";
import { useEffect, useMemo, useState } from "react";
import { useLanguage } from "../../stores/LanguageStore";

function PasswordRequirement({ meets, label }) {
  return (
    <Text component="div" c={meets ? "teal" : "red"} mt={5} size="sm">
      <Center inline>
        {meets ? (
          <IconCheck size="0.9rem" stroke={1.5} />
        ) : (
          <IconX size="0.9rem" stroke={1.5} />
        )}
        <Box ml={7}>{label}</Box>
      </Center>
    </Text>
  );
}

const getRequirements = () => {
  const lang = useLanguage.getState().language;

  return [
    { re: /[0-9]/, label: lang.components.inputs.password_helper.min_1_number },
    {
      re: /[a-z]/,
      label: lang.components.inputs.password_helper.min_1_lowercase_char,
    },
    {
      re: /[A-Z]/,
      label: lang.components.inputs.password_helper.min_1_uppercase_char,
    },
    {
      re: /[$&+,:;=?@#|'<>.^*()%!-]/,
      label: lang.components.inputs.password_helper.min_1_special_char,
    },
  ];
};

function getStrength(password) {
  let requirements = getRequirements();
  let multiplier = password.length > 5 ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 0);
}

const PasswordField = ({
  error,
  setError,
  id,
  size,
  leftSection,
  ...props
}) => {
  const lang = useLanguage((s) => s.language);
  const [value, setValue] = useInputState("");
  const [focused, setFocused] = useState(false);
  const strength = useMemo(() => {
    return getStrength(value);
  }, [value]);

  let requirements = getRequirements();
  const checks = requirements.map((requirement, index) => (
    <PasswordRequirement
      key={index}
      label={requirement.label}
      meets={requirement.re.test(value)}
    />
  ));
  const bars = Array(4)
    .fill(0)
    .map((_, index) => (
      <Progress
        styles={{ section: { transitionDuration: "0ms" } }}
        value={
          value.length > 0 && index === 0
            ? 100
            : strength >= ((index + 1) / 4) * 100
            ? 100
            : 0
        }
        color={strength > 80 ? "teal" : strength > 50 ? "yellow" : "red"}
        key={index}
        size={4}
      />
    ));

  useEffect(() => {
    if (strength >= 100) {
      setError(false);
    } else if (value.length > 0) {
      setError(true);
    }
  }, [strength, value.length]);

  return (
    <Popover
      position="left-start"
      withArrow
      opened={focused}
      onChange={setFocused}
    >
      <Popover.Target>
        <Box w={"100%"}>
          <PasswordInput
            error={value ? error : false}
            id={id}
            value={value}
            onChange={setValue}
            placeholder={lang.signup.password}
            label={lang.signup.password}
            leftSection={leftSection}
            name="password"
            required
            w={"100%"}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            size={size}
            {...props}
          />
        </Box>
      </Popover.Target>

      <Popover.Dropdown p={"xs"}>
        <Group gap={5} grow mt="xs" mb="md">
          {bars}
        </Group>

        <PasswordRequirement
          label={lang.components.inputs.password_helper.min_8_chars}
          meets={value.length > 7}
        />
        {checks}
      </Popover.Dropdown>
    </Popover>
  );
};

export default PasswordField;
