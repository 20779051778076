import { useEffect, useMemo, useState } from "react";
import { useProfileStore } from "../../stores/UserStore";
import { useLanguage } from "../../stores/LanguageStore";
import { updateSelf } from "../../api/UserAPI";
import {
  Group,
  LoadingOverlay,
  Slider,
  Stack,
  Text,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import { IconMail, IconUserSearch } from "@tabler/icons-react";
import { IconLinkedin } from "../../components/Icons/IconLinkedin";

const HeaderStats = () => {
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);

  const [loading, setLoading] = useState(false);

  const linkedinAccounts = useMemo(() => {
    return profile?.permissions?.find(
      (x) => x.name === "maxIntegrationsLinkedin"
    );
  }, [profile]);
  const emails = useMemo(() => {
    return profile?.permissions?.find(
      (x) => x.name === "maxIntegrationsEmailSMTP"
    );
  }, [profile]);
  const enrichments = useMemo(() => {
    return profile?.permissions?.find(
      (x) => x.name === "maxEmailsGeneratedPerMonth"
    );
  }, [profile]);

  useEffect(() => {
    setLoading(true);
    updateSelf(true).finally(() => setLoading(false));
  }, []);

  return (
    <Stack
      px={"sm"}
      gap={"sm"}
      pb={8}
      mb={4}
      mt={2}
      pos={"relative"}
      style={{ borderBottom: "1px solid #00000012" }}
    >
      <LoadingOverlay visible={loading} loaderProps={{ size: 24 }} />
      <Tooltip
        label={lang.header.stats.email
          .replace("#", emails?.value - emails?.remaining)
          .replace("$", emails?.value)}
      >
        <Group w={"100%"} wrap="nowrap">
          <ThemeIcon size={"sm"} variant="subtle" color="orange">
            <IconMail size={18} />
          </ThemeIcon>
          <Text size="sm" w={36}>
            {emails?.value - emails?.remaining || 0}
          </Text>
          <Slider
            value={emails?.value - emails?.remaining}
            max={emails?.value || 0}
            flex={1}
            thumbSize={12}
            label={null}
            color={emails?.remaining <= 0 ? "red" : "primary.4"}
            styles={{
              thumb: {
                background:
                  emails?.remaining <= 0
                    ? "red"
                    : "var(--mantine-color-primary-6)",
                border: "none",
                height: "100%",
              },
            }}
          />
          <Text size="sm" w={36}>
            {emails?.value || 0}
          </Text>
        </Group>
      </Tooltip>

      <Tooltip
        label={lang.header.stats.linkedin
          .replace("#", linkedinAccounts?.value - linkedinAccounts?.remaining)
          .replace("$", linkedinAccounts?.value)}
      >
        <Group w={"100%"} wrap="nowrap">
          <ThemeIcon size={"sm"} variant="subtle">
            <IconLinkedin size={18} />
          </ThemeIcon>
          <Text size="sm" w={36}>
            {linkedinAccounts?.value - linkedinAccounts?.remaining || 0}
          </Text>
          <Slider
            value={linkedinAccounts?.value - linkedinAccounts?.remaining}
            max={linkedinAccounts?.value || 0}
            styles={{
              thumb: {
                background:
                  linkedinAccounts?.remaining <= 0
                    ? "red"
                    : "var(--mantine-color-primary-6)",
                border: "none",
                height: "100%",
              },
            }}
            thumbSize={12}
            label={null}
            flex={1}
            color={linkedinAccounts?.remaining <= 0 ? "red" : "primary.4"}
          />
          <Text size="sm" w={36}>
            {linkedinAccounts?.value || 0}
          </Text>
        </Group>
      </Tooltip>

      <Tooltip
        label={lang.header.stats.enrich
          .replace("#", enrichments?.value - enrichments?.remaining)
          .replace("$", enrichments?.value)}
      >
        <Group w={"100%"} wrap="nowrap">
          <ThemeIcon size={"sm"} variant="subtle">
            <IconUserSearch size={18} />
          </ThemeIcon>
          <Text size="sm" w={36}>
            {enrichments?.value - enrichments?.remaining || 0}
          </Text>
          <Slider
            value={enrichments?.value - enrichments?.remaining}
            max={enrichments?.value || 0}
            thumbSize={12}
            label={null}
            flex={1}
            color={enrichments?.remaining <= 0 ? "red" : "primary.4"}
            styles={{
              thumb: {
                background:
                  enrichments?.remaining <= 0
                    ? "red"
                    : "var(--mantine-color-primary-6)",
                border: "none",
                height: "100%",
              },
            }}
          />
          <Text size="sm" w={36}>
            {enrichments?.value || 0}
          </Text>
        </Group>
      </Tooltip>
    </Stack>
  );
};

export default HeaderStats;
