import {
  Accordion,
  Box,
  Group,
  Paper,
  Stack,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { useLanguage } from "../../../stores/LanguageStore";

const faqFontWeight = 400;

const FeaturesTable = () => {
  const lang = useLanguage((s) => s.language);
  const features = [
    {
      title: lang.pricing.features.lists.title,
      items: [
        lang.pricing.features.lists.item1,
        lang.pricing.features.lists.item2,
        lang.pricing.features.lists.item3,
        lang.pricing.features.lists.item4,
        lang.pricing.features.lists.item5,
        lang.pricing.features.lists.item6,
        lang.pricing.features.lists.item7,
        lang.pricing.features.lists.item8,
        lang.pricing.features.lists.item9,
        lang.pricing.features.lists.item10,
        lang.pricing.features.lists.item11,
        lang.pricing.features.lists.item12,
        lang.pricing.features.lists.item13,
      ],
    },
    {
      title: lang.pricing.features.email_campaign.title,
      items: [
        lang.pricing.features.email_campaign.item1,
        lang.pricing.features.email_campaign.item2,
        lang.pricing.features.email_campaign.item3,
        lang.pricing.features.email_campaign.item4,
        lang.pricing.features.email_campaign.item5,
        lang.pricing.features.email_campaign.item6,
        lang.pricing.features.email_campaign.item7,
      ],
    },
    {
      title: lang.pricing.features.linkedin_automation.title,
      items: [
        lang.pricing.features.linkedin_automation.item1,
        lang.pricing.features.linkedin_automation.item2,
      ],
    },
    {
      title: lang.pricing.features.stats.title,
      items: [lang.pricing.features.stats.item1],
    },
    {
      title: lang.pricing.features.prm.title,
      items: [lang.pricing.features.prm.item1, lang.pricing.features.prm.item2],
    },
    {
      title: lang.pricing.features.white_label.title,
      items: [
        lang.pricing.features.white_label.item1,
        lang.pricing.features.white_label.item2,
      ],
    },
    {
      title: lang.pricing.features.integrations.title,
      items: [
        lang.pricing.features.integrations.item1,
        lang.pricing.features.integrations.item2,
      ],
    },
    {
      title: lang.pricing.features.teams.title,
      items: [
        lang.pricing.features.teams.item1,
        lang.pricing.features.teams.item2,
      ],
    },
  ];

  const faq = [
    {
      label: lang.pricing.faq.section1.label,
      content: (
        <>
          <Text fw={faqFontWeight}>{lang.pricing.faq.section1.text1}</Text>

          <Text fw={faqFontWeight}>{lang.pricing.faq.section1.text2}</Text>
        </>
      ),
    },
    {
      label: lang.pricing.faq.section2.label,
      content: (
        <Text fw={faqFontWeight}>{lang.pricing.faq.section2.text1}</Text>
      ),
    },
    {
      label: lang.pricing.faq.section3.label,
      content: (
        <Text fw={faqFontWeight}>{lang.pricing.faq.section3.text1}</Text>
      ),
    },
    {
      label: lang.pricing.faq.section4.label,
      content: (
        <Text fw={faqFontWeight}>{lang.pricing.faq.section4.text1}</Text>
      ),
    },
    {
      label: lang.pricing.faq.section5.label,
      content: (
        <Text fw={faqFontWeight}>{lang.pricing.faq.section5.text1}</Text>
      ),
    },
    {
      label: lang.pricing.faq.section6.label,
      content: (
        <Text fw={faqFontWeight}>
          {lang.pricing.faq.section6.text1}
          <ul style={{ paddingLeft: 16 }}>
            <li style={{ marginBottom: 8 }}>
              {lang.pricing.faq.section6.text2}
            </li>
            <li>{lang.pricing.faq.section6.text3}</li>
          </ul>
        </Text>
      ),
    },
    {
      label: lang.pricing.faq.section7.label,
      content: (
        <Text fw={faqFontWeight}>{lang.pricing.faq.section7.text1}</Text>
      ),
    },
  ];

  return (
    <Group wrap="nowrap" align="start">
      <Paper
        withBorder
        component={Stack}
        radius={"lg"}
        mt={"lg"}
        w={"100%"}
        gap={0}
      >
        <Title order={5} mb={"sm"} px={"sm"} pt={"sm"}>
          {lang.pricing.features.title}
        </Title>

        {features.map((group, index) => (
          <Stack key={index} w={"100%"} gap={0}>
            <Group w={"100%"} bg={"var(--mantine-color-gray-0)"} p={"sm"}>
              <Text fw={700} size="md">
                {group.title}
              </Text>
            </Group>
            <Stack w={"100%"} gap={0}>
              {group.items.map((item, i) => (
                <Group
                  key={i}
                  w={"100%"}
                  style={{ borderBottom: "1px solid #00000016", borderTop: 0 }}
                  wrap="nowrap"
                  gap={0}
                >
                  <Group
                    p={"xs"}
                    w={"100%"}
                    style={{ borderRight: "1px solid #00000018" }}
                  >
                    <Text fw={500}>{item}</Text>
                  </Group>
                  <Box bg={"var(--mantine-color-green-0)"} px={10}>
                    <ThemeIcon variant="subtle" color="tertiary.8" mt={"xs"}>
                      <IconCheck size={24} />
                    </ThemeIcon>
                  </Box>
                </Group>
              ))}
            </Stack>
          </Stack>
        ))}
      </Paper>

      <Paper
        withBorder
        component={Stack}
        radius={"lg"}
        mt={"lg"}
        w={"75%"}
        gap={0}
        style={{ overflow: "hidden", position: "sticky", top: 5 }}
      >
        <Accordion variant="filled">
          {faq.map((item, index) => (
            <Accordion.Item key={index} value={item.label}>
              <Accordion.Control>
                <Text fw={600}>{item.label}</Text>
              </Accordion.Control>
              <Accordion.Panel>
                <Stack>{item.content}</Stack>
              </Accordion.Panel>
            </Accordion.Item>
          ))}
        </Accordion>
      </Paper>
    </Group>
  );
};

export default FeaturesTable;
