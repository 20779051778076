import PopOver from "../../../../../components/View/PopOver";
import { ActionIcon, Stack, Tooltip } from "@mantine/core";
import { IconDots } from "@tabler/icons-react";
import UnsubscribeProspect from "./UnsubscribeProspect";
import ZapierSyncer from "../../titleSection/zapier/ZapierSyncer";
import DeleteProspect from "./DeleteProspect";
import ExcludeProspect from "./exclude/ExcludeProspect";

const MoreActions = ({ inside, current, container }) => {
  return (
    <PopOver
      opener={(handleOpen, ref) =>
        inside ? (
          <ActionIcon
            ref={ref}
            variant="light"
            color="primary"
            onClick={handleOpen}
            ml={"auto"}
          >
            <IconDots size={18} />
          </ActionIcon>
        ) : (
          <Tooltip label="Plus d'actions">
            <ActionIcon
              variant="subtle"
              ref={ref}
              color="primary"
              onClick={handleOpen}
              pos={"absolute"}
              right={0}
              top={0}
              style={{
                borderTopLeftRadius: 0,
                borderBottomRightRadius: 0,
              }}
            >
              <IconDots size={18} />
            </ActionIcon>
          </Tooltip>
        )
      }
      popoverChildren={(handleClose) => (
        <Stack gap={0}>
          <ZapierSyncer closeMenu={handleClose} current={current} />

          <ExcludeProspect current={current} closeMenu={handleClose} />

          <UnsubscribeProspect
            current={current}
            closeMenu={handleClose}
            inPrm
          />

          <DeleteProspect
            closeMenu={handleClose}
            current={current}
            container={container}
          />
        </Stack>
      )}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      sx={{ zIndex: 200 }}
    />
  );
};

export default MoreActions;
