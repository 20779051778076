import { Button, Popover, Stack, Tooltip } from "@mantine/core";
import PopOver from "../../../../../components/View/PopOver";
import {
  IconChevronDown,
  IconChevronLeft,
  IconCopy,
} from "@tabler/icons-react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import CopyDestination from "./CopyDestination";

const TableFilterCopyContacts = ({
  isGlobal,
  id,
  onFinish,
  selectedIds,
  filter,
  filterResultNumber,
  total,
}) => {
  const lang = useLanguage((s) => s.language);

  return (
    <PopOver
      opener={(handleOpen, ref) => (
        <Tooltip label={lang.contact_lists.content.copy_selection.tooltip}>
          <Button
            className="toolbar-action-button"
            variant="light"
            color="tertiary.8"
            justify="start"
            ref={ref}
            rightSection={<IconChevronDown size={16} />}
            onClick={handleOpen}
          >
            <IconCopy size={18} />
          </Button>
        </Tooltip>
      )}
      popoverChildren={(handleClose) => (
        <Stack gap={"xs"}>
          <Popover
            zIndex={1600}
            position="left"
            offset={0}
            shadow="md"
            withArrow
          >
            <Popover.Target>
              <Tooltip
                label={lang.contact_lists.delete_contacts.no_filter}
                disabled={
                  filter?.items?.length > 0 ||
                  filter.quickFilterValues.filter(Boolean).length > 0
                }
              >
                <Button
                  disabled={
                    !filter ||
                    (filter?.items?.length < 1 &&
                      filter.quickFilterValues.filter(Boolean).length < 1)
                  }
                  variant="subtle"
                  size="sm"
                  color="tertiary.8"
                  justify="start"
                  leftSection={<IconChevronLeft size={16} />}
                >
                  {isGlobal
                    ? "Copier les résultats de la recherche"
                    : lang.contact_lists.content.copy_selection
                        .copy_filter}{" "}
                  {filter?.items?.length > 0 ||
                  filter.quickFilterValues.filter(Boolean).length > 0
                    ? `( ${filterResultNumber} )`
                    : ""}
                </Button>
              </Tooltip>
            </Popover.Target>
            <Popover.Dropdown>
              <CopyDestination
                isGlobal={isGlobal}
                handleClose={handleClose}
                filter={filter}
                id={id}
                onFinish={onFinish}
                selectedIds={selectedIds}
                type={"filter"}
                label={lang.contact_lists.content.copy_selection.success}
              />
            </Popover.Dropdown>
          </Popover>
          <Popover
            zIndex={1600}
            position="left"
            offset={0}
            shadow="md"
            withArrow
          >
            <Popover.Target>
              <Tooltip
                label={lang.contact_lists.delete_contacts.no_selection}
                disabled={selectedIds?.length > 0}
              >
                <Button
                  leftSection={<IconChevronLeft size={16} />}
                  disabled={!selectedIds || selectedIds?.length < 1}
                  variant="subtle"
                  size="sm"
                  color="tertiary.8"
                  justify="start"
                >
                  {lang.contact_lists.content.copy_selection.copy_selection}{" "}
                  {selectedIds?.length > 0 ? `( ${selectedIds.length} )` : ""}
                </Button>
              </Tooltip>
            </Popover.Target>
            <Popover.Dropdown>
              <CopyDestination
                isGlobal={isGlobal}
                handleClose={handleClose}
                filter={filter}
                id={id}
                onFinish={onFinish}
                selectedIds={selectedIds}
                type={"selection"}
                label={lang.contact_lists.content.copy_selection.copy_selection}
              />
            </Popover.Dropdown>
          </Popover>

          <Popover
            zIndex={1600}
            position="left"
            offset={0}
            shadow="md"
            withArrow
          >
            <Popover.Target>
              <Tooltip
                label={lang.contact_lists.delete_contacts.no_selection}
                disabled={selectedIds?.length > 0}
              >
                <Button
                  leftSection={<IconChevronLeft size={16} />}
                  disabled={!selectedIds || selectedIds?.length < 1}
                  variant="subtle"
                  size="sm"
                  color="tertiary.8"
                  justify="start"
                >
                  {
                    lang.contact_lists.content.copy_selection
                      .copy_all_but_selection
                  }{" "}
                  {selectedIds?.length > 0
                    ? `( ${filterResultNumber - selectedIds.length} )`
                    : ""}
                </Button>
              </Tooltip>
            </Popover.Target>
            <Popover.Dropdown>
              <CopyDestination
                isGlobal={isGlobal}
                handleClose={handleClose}
                filter={filter}
                id={id}
                onFinish={onFinish}
                selectedIds={selectedIds}
                type={"selection_inverse"}
                label={
                  lang.contact_lists.content.copy_selection
                    .copy_all_but_selection
                }
              />
            </Popover.Dropdown>
          </Popover>

          {!isGlobal && (
            <Popover
              zIndex={1600}
              position="left"
              offset={0}
              shadow="md"
              withArrow
            >
              <Popover.Target>
                <Tooltip
                  label={lang.contact_lists.content.copy_selection.copy_all}
                >
                  <Button
                    leftSection={<IconChevronLeft size={16} />}
                    variant="subtle"
                    size="sm"
                    color="tertiary.8"
                    justify="start"
                  >
                    {lang.contact_lists.content.copy_selection.copy_all} (
                    {total})
                  </Button>
                </Tooltip>
              </Popover.Target>
              <Popover.Dropdown>
                <CopyDestination
                  isGlobal={isGlobal}
                  handleClose={handleClose}
                  filter={filter}
                  id={id}
                  onFinish={onFinish}
                  selectedIds={selectedIds}
                  type={"all"}
                  label={lang.contact_lists.content.copy_selection.copy_all}
                />
              </Popover.Dropdown>
            </Popover>
          )}
        </Stack>
      )}
    />
  );
};

export default TableFilterCopyContacts;
