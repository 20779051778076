import { Button, Group, Stack, Text, TextInput, Tooltip } from "@mantine/core";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useState } from "react";
import { getHtmlFromUrl } from "../../../../api/ModelsApi";
import { notifications } from "@mantine/notifications";
import PopOver from "../../../../components/View/PopOver";
import BackButton from "../../../../components/View/BackButton";

const HtmlImporter = ({ onFinish }) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(false);

  const onSubmit = (e, closePopover) => {
    e.preventDefault();
    e.stopPropagation();

    let fd = new FormData(e.target);
    let data = Object.fromEntries(fd);

    setLoading(true);
    getHtmlFromUrl(data.link)
      .then((response) => {
        let newHtml = response.data.html;
        notifications.show({
          message: lang.model_emails.get_from_url.success,
          color: "green",
        });
        onFinish(newHtml);
        closePopover();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <PopOver
      opener={(handleOpen, ref) => (
        <Tooltip label={lang.model_emails.get_from_url.button_tooltip}>
          <Button
            ref={ref}
            onClick={handleOpen}
            // leftSection={<IconHtml size={18} />}
            variant="light"
            ml={"auto"}
            size="sm"
            h={32}
          >
            {lang.model_emails.get_from_url.button_label}
          </Button>
        </Tooltip>
      )}
      popoverChildren={(closePopover) => (
        <Stack
          w={350}
          component={"form"}
          onSubmit={(e) => onSubmit(e, closePopover)}
          gap={"xs"}
        >
          <Text fw={600} size="sm">
            {lang.models.html.text} <br />
            {lang.models.html.text2}
          </Text>

          <TextInput
            label={lang.model_emails.get_from_url.dialog.link}
            type="url"
            name="link"
            required
          />

          <Group justify="end" mt={"md"}>
            <BackButton onClick={closePopover} size="xs" />
            <Button type="submit" loading={loading} size="xs">
              {lang.model_emails.get_from_url.dialog.submit_button}
            </Button>
          </Group>
        </Stack>
      )}
    />
  );
};

export default HtmlImporter;
