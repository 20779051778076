import {
  ActionIcon,
  CopyButton,
  Group,
  NumberInput,
  rem,
  Stack,
  Switch,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { Divider } from "@mui/material";
import { IconCopy, IconPercentage, IconTag } from "@tabler/icons-react";
import { useMemo } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { IconCheck } from "@tabler/icons-react";

const WarmupSettings = ({ row, handleSubmit }) => {
  const lang = useLanguage((s) => s.language);
  const settings = useMemo(() => row?.warmup_settings, [row]);

  return (
    <Stack component={"form"} onSubmit={handleSubmit} id="warmup-settings">
      <Switch
        defaultChecked={row.warmup_settings?.warmup_enabled}
        name="warmup_enabled"
        color="green.8"
        label="Activer le Warm-up"
        labelPosition="left"
        size="md"
      />
      <Divider />
      <Group>
        <Stack gap={0}>
          <Text>Nombre total de Warm-up par jour</Text>
          <Text size="sm" c={"dimmed"}>
            Nombre maximum d'emails de Warm-up pouvant être envoyés à partir de
            cet e-mail par jour
          </Text>
        </Stack>
        <NumberInput
          min={1}
          name="total_warmup_per_day"
          w={75}
          ml={"auto"}
          defaultValue={settings?.total_warmup_per_day || 5}
          required
        />
      </Group>
      <Divider />
      <Group>
        <Stack gap={0}>
          <Text>Rampup par jour</Text>
          <Text size="sm" c={"dimmed"}>
            Valeur d'incrément de montée d'envoi par jour{" "}
            <b>( recommandé: 5 par jours )</b>
          </Text>
        </Stack>
        <NumberInput
          min={1}
          name="daily_rampup"
          w={75}
          ml={"auto"}
          defaultValue={settings?.daily_rampup || 5}
          required
        />
      </Group>
      <Divider />
      <Group>
        <Stack gap={0}>
          <Text>Taux de réponse</Text>
          <Text size="sm" c={"dimmed"}>
            <b>( recommandé: 20% )</b>
          </Text>
        </Stack>
        <NumberInput
          min={1}
          name="reply_rate_percentage"
          w={75}
          ml={"auto"}
          defaultValue={settings?.reply_rate_percentage || 20}
          rightSection={<IconPercentage size={18} />}
          required
        />
      </Group>
      <Divider />
      <Group>
        <Stack gap={0}>
          <Text>Mots-Clé de classification</Text>
          <Text size="sm" c={"dimmed"}>
            Utilisez ce mot-clé pour filtrer tous les e-mails de warmup dans
            votre boîte de réception
          </Text>
        </Stack>
        <TextInput
          ml={"auto"}
          value={settings?.keyword}
          leftSection={<IconTag size={18} />}
          readOnly
          rightSection={
            <CopyButton value={settings?.keyword}>
              {({ copied, copy }) => (
                <Tooltip
                  label={copied ? lang.global.copied : lang.global.copy}
                  color={copied ? "teal" : "black"}
                >
                  <ActionIcon
                    color={copied ? "teal" : "primary"}
                    radius="sm"
                    variant="light"
                    onClick={copy}
                  >
                    {copied ? (
                      <IconCheck
                        style={{ width: rem(18), height: rem(18) }}
                        stroke={1.5}
                      />
                    ) : (
                      <IconCopy
                        style={{ width: rem(18), height: rem(18) }}
                        stroke={1.5}
                      />
                    )}
                  </ActionIcon>
                </Tooltip>
              )}
            </CopyButton>
          }
        />
      </Group>
    </Stack>
  );
};

export default WarmupSettings;
