import { Group, HoverCard, Stack, Text, ThemeIcon } from "@mantine/core";
import { useLanguage } from "../../../../../stores/LanguageStore";
import CampaignColumnChildCell from "./CampaignColumnChildCell";
import { useMemo } from "react";
import { IconMoodEmpty, IconMoodSmile } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { IconMoodHappy } from "@tabler/icons-react";

function handleConclusion(type, rate, account_type) {
  let lang = useLanguage.getState().language;
  let good = {
    smiley: (
      <ThemeIcon
        color="tertiary.8"
        opacity={0.75}
        variant="subtle"
        size={"sm"}
      >
        <IconMoodHappy />
      </ThemeIcon>
    ),
    color: "tertiary.8",
    text: lang.workflows.types.good_text,
  };
  let medium = {
    smiley: (
      <ThemeIcon color="orange" opacity={0.5} variant="subtle" size={"sm"}>
        <IconMoodSmile />
      </ThemeIcon>
    ),
    color: "orange",
    text: lang.workflows.types.mid_text,
  };
  let bad = {
    smiley: (
      <ThemeIcon color="red" opacity={0.5} variant="subtle" size={"sm"}>
        <IconMoodEmpty />
      </ThemeIcon>
    ),
    color: "red",
    text: lang.workflows.types.bad_text,
  };

  if (type === "contacts_opened") {
    // LinkedIn
    if (account_type === "MassMailing") {
      if (rate >= 12) {
        return good;
      } else if (rate >= 7) {
        return medium;
      }
      return bad;
    }
    // SMTP & LinkedIn
    if (rate >= 18) {
      return good;
    } else if (rate >= 10) {
      return medium;
    }
    return bad;
  }

  if (type === "contacts_clicked") {
    if (rate >= 2) {
      return good;
    } else if (rate >= 1) {
      return medium;
    }
    return bad;
  }

  if (type === "contacts_answered") {
    if (account_type === "MassMailing") {
      if (rate > 1) {
        return good;
      } else if (rate > 0.5) {
        return medium;
      }
      return bad;
    }
    if (rate >= 5) {
      return good;
    } else if (rate >= 2) {
      return medium;
    }
    return bad;
  }
}

function parseIdentifier(identifier) {
  if (identifier === "contacts_opened") {
    return "opener";
  }
  if (identifier === "contacts_clicked") {
    return "clicker";
  }
  if (identifier === "contacts_answered") {
    return "answerer";
  }
}

const ChildCell = ({ row, step, identifier }) => {
  const lang = useLanguage((s) => s.language);

  //#region Maths
  const rate_opener =
    step?.contacted !== null && step["contacts_opened"] !== null
      ? step.contacted > 0
        ? parseFloat(
            ((step["contacts_opened"] / step.contacted) * 100).toFixed(2)
          )
        : 0
      : null;
  const getRate = () => {
    let diviser = step?.["contacts_opened"] || step?.contacted;
    if (step?.[identifier] === null) {
      return null;
    }
    if (diviser > 0) {
      return parseFloat(((step?.[identifier] / diviser) * 100).toFixed(2));
    }
    return 0;
  };

  const getNoTracking = () => {
    if (step?.integration?.enable_tracking === false) {
      return 1;
    }
    if (step?.pool?.accounts_list?.some((x) => x.enable_tracking === false)) {
      return 2;
    }
    return false;
  };
  //#endregion

  return (
    <CampaignColumnChildCell>
      <HoverCard
        withArrow
        offset={2}
        position="top"
        disabled={getNoTracking() === false}
      >
        <HoverCard.Target>
          <Group
            justify="space-between"
            fz={13}
            component={Link}
            w="100%"
            h={"100%"}
            gap={"xs"}
            wrap="nowrap"
            className="statistics-button"
            px={12}
            to={`/statistics/campaigns/${row.prog_id}/workflow/${
              row.workflow.id
            }/step/${step.id}/contacts/${parseIdentifier(identifier)}/${
              step.action_type
            }`}
          >
            <Text size="sm" fw={600}>
              {(step?.[identifier] || 0)
                ?.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
            </Text>

            <Text
              fw={600}
              size={"sm"}
              ml="auto"
              c={
                handleConclusion(
                  identifier,
                  identifier === "contacts_opened" ? rate_opener : getRate()
                ).color
              }
            >
              {identifier === "contacts_opened"
                ? `${(rate_opener || 0)
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}%`
                : `${(getRate() || 0)
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}%`}
            </Text>
          </Group>
        </HoverCard.Target>

        <HoverCard.Dropdown>
          <Text size="sm">
            {getNoTracking() === 1
              ? lang.statistics.columns.rates.no_tracking_step_integration
              : getNoTracking() === 2
              ? lang.statistics.columns.rates.no_tracking_step_pool
              : ""}
          </Text>
        </HoverCard.Dropdown>
      </HoverCard>
    </CampaignColumnChildCell>
  );
};

const ParentCell = ({ row, identifier, headerName }) => {
  const lang = useLanguage((s) => s.language);

  //#region Maths
  const contacts = row?.[identifier] === null ? " - " : row?.[identifier];
  const rate_opener =
    row?.initial?.contacted !== null && row?.["contacts_opened"] !== null
      ? row?.initial?.contacted > 0
        ? parseFloat(
            (
              (row?.["contacts_opened"] / row?.initial?.contacted) *
              100
            ).toFixed(2)
          )
        : 0
      : null;

  let rate = useMemo(() => {
    let diviser = row?.["contacts_opened"] || row?.initial?.contacted;
    if (row?.[identifier] === null) {
      return null;
    }
    if (diviser > 0) {
      return parseFloat(((row[identifier] / diviser) * 100).toFixed(2));
    }
    return 0;
  }, [row, identifier]);

  const noTrackingProg = useMemo(() => {
    if (row?.initial?.integration?.enable_tracking === false) {
      return 1;
    }
    if (
      row?.initial?.pool?.accounts_list?.some(
        (x) => x.enable_tracking === false
      )
    ) {
      return 2;
    }
    return false;
  }, [row]);
  //#endregion

  return (
    <HoverCard zIndex={200} openDelay={300} withArrow offset={2} position="top">
      <HoverCard.Target>
        <Group
          component={Link}
          w="100%"
          h={"100%"}
          gap={"xs"}
          wrap="nowrap"
          className="statistics-button"
          px={6}
          to={`/statistics/campaigns/${row.id}/workflow/${
            row.workflow_id
          }/contacts/${parseIdentifier(identifier)}?createdOn=${new Date(
            row?.created_on
          ).toISOString()}`}
        >
          {
            handleConclusion(
              identifier,
              identifier === "contacts_opened" ? rate_opener : rate
            ).smiley
          }
          <Text
            fw={500}
            c={
              handleConclusion(
                identifier,
                identifier === "contacts_opened" ? rate_opener : rate
              ).color
            }
          >
            {identifier === "contacts_opened"
              ? `${(rate_opener || 0)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}%`
              : `${
                  rate?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, " ") ||
                  " - "
                }%`}
          </Text>
        </Group>
      </HoverCard.Target>

      <HoverCard.Dropdown>
        <Text size="sm" maw={200}>
          {headerName} uniques :{" "}
          <b>{contacts?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</b>{" "}
          <br />
          <span
            style={{
              opacity: 0.5,
              fontSize: 11,
              marginTop: 8,
              display: "block",
            }}
          >
            {noTrackingProg === 1
              ? lang.statistics.columns.rates.no_tracking_prog_integration
              : noTrackingProg === 2
              ? lang.statistics.columns.rates.no_tracking_prog_pool
              : ""}
          </span>
          {/* {noTrackingProg > 0 && <br />}
          <span style={{ fontSize: 10 }}>
            {lang.statistics.columns.rates.click_to_show}
          </span> */}
        </Text>
      </HoverCard.Dropdown>
    </HoverCard>
  );
};

export function CampaignColumnRates({ field, headerName, identifier }) {
  return {
    field: field,
    headerName: headerName,
    cellClassName: "statistics-button-parent",
    width: 120,
    renderCell: ({ row, value }) => {
      if (!row) {
        return;
      }
      if (row.isChild) {
        return (
          <Stack py={"xs"} w={"100%"}>
            {row.steps.map((step, index) => {
              return (
                <ChildCell
                  row={row}
                  step={step}
                  key={step.id}
                  identifier={identifier}
                />
              );
            })}
          </Stack>
        );
      }

      return (
        <ParentCell
          row={row}
          value={value}
          headerName={headerName}
          identifier={identifier}
        />
      );
    },
  };
}
