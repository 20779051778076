import { useDisclosure } from "@mantine/hooks";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { useEffect, useState } from "react";
import { getZapierHooks, sendZapierHook } from "../../../../../api/ZapierApi";
import { notifications } from "@mantine/notifications";
import { Button, Group, Modal, Stack, Text, Tooltip } from "@mantine/core";
import { IconBrandZapier } from "@tabler/icons-react";
import BackButton from "../../../../../components/View/BackButton";

const ZapierSyncer = ({ current, closeMenu, inSheet }) => {
  const lang = useLanguage((s) => s.language);
  const [opened, { open, close }] = useDisclosure();

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading) {
      getZapierHooks()
        .then((response) => {
          setItems(
            response.data.zapier_hooks_list
              .sort((a, b) => b.id - a.id)
              .filter((x) => x.source === "prm")
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [loading]);

  const handleClick = () => {
    if (items?.length === 1) {
      setLoading(true);
      return sendZapierHook({ id: items[0]?.id, contact_id: current?.id })
        .then(() => {
          notifications.show({
            message: "Synchronisation de la fiche lancée",
            color: "tertiary.8",
          });
          setLoading(false);
        })
        .finally(() => setLoading(false));
    }
    return open();
  };

  const handleClose = () => {
    closeMenu?.();
    close();
  };

  const [syncing, setSyncing] = useState(null);
  const handleSync = (id) => {
    setSyncing(id);
    sendZapierHook({ id: id, contact_id: current?.id })
      .then(() => {
        notifications.show({
          message: "Synchronisation de la fiche lancée",
          color: "tertiary.8",
        });
        handleClose();
      })
      .finally(() => setSyncing(null));
  };

  return (
    <>
      {inSheet ? (
        <Tooltip label={lang.sync_zapier.item_sync_label}>
          <Button
            variant="light"
            color="primary"
            px={"sm"}
            onClick={handleClick}
            disabled={items.length === 0}
            loading={loading}
          >
            <IconBrandZapier size={18} />
          </Button>
        </Tooltip>
      ) : (
        <Button
          onClick={handleClick}
          variant="subtle"
          disabled={items.length === 0}
          loading={loading}
          leftSection={<IconBrandZapier size={18} />}
        >
          {lang.sync_zapier.item_sync_label}
        </Button>
      )}

      <Modal
        opened={opened}
        onClose={handleClose}
        title={lang.sync_zapier.item_sync_label}
        centered
        zIndex={1300}
        pos={"relative"}
      >
        <Text c={"dimmed"} mb={"sm"}>
          Séléctionner quel lien utiliser
        </Text>
        <Stack gap={"md"}>
          {items.map((item, index) => {
            return (
              <Button
                variant="light"
                key={item?.id}
                onClick={() => handleSync(item.id)}
                loading={syncing === item.id}
                h={64}
              >
                <Stack gap={4}>
                  <Text fw={600}>{item.name}</Text>
                  <Text size="sm">{item.url}</Text>
                </Stack>
              </Button>
            );
          })}
        </Stack>

        <Group justify="end" mt={"md"}>
          <BackButton onClick={handleClose} />
        </Group>
      </Modal>
    </>
  );
};

export default ZapierSyncer;
