import {
  ColorInput,
  Group,
  NumberInput,
  Paper,
  Stack,
  Text,
} from "@mantine/core";
import { useMemo } from "react";
import { IconZoomIn } from "@tabler/icons-react";
import { useLanguage } from "../../../stores/LanguageStore";
import DontShowAgain from "../../../components/View/DontShowAgain";

const Interface = () => {
  const lang = useLanguage((s) => s.language);
  const currentTextSize = useMemo(
    () =>
      (parseInt(document.documentElement.style.fontSize.substring(0, 3)) %
        100) /
        10 || 1,
    []
  );

  return (
    <Group>
      <Paper
        withBorder
        component={Stack}
        w={"100%"}
        p={"md"}
        px={"xl"}
        radius={"md"}
      >
        <Text fw={600} size="md" mb={-8}>
          {lang.profile.personalize_interface}
        </Text>

        <Group gap={32}>
          <NumberInput
            label="Zoom"
            onChange={(value) => {
              document.documentElement.style.fontSize = `${90 + value * 10}%`;
            }}
            min={1}
            max={3}
            w={100}
            defaultValue={currentTextSize}
            leftSection={<IconZoomIn size={18} />}
          />

          <ColorInput
            label={lang.resellers.create.dialog.primary_color}
            format="hex"
            defaultValue="#3F51B5"
            w={125}
            disabled
            swatches={[
              "#2e2e2e",
              "#868e96",
              "#fa5252",
              "#e64980",
              "#be4bdb",
              "#7950f2",
              "#4c6ef5",
              "#228be6",
              "#15aabf",
              "#12b886",
              "#40c057",
              "#82c91e",
              "#fab005",
              "#fd7e14",
            ]}
          />
          <ColorInput
            label={lang.resellers.create.dialog.secondary_color}
            format="hex"
            defaultValue="#FDB713"
            w={125}
            disabled
            swatches={[
              "#2e2e2e",
              "#868e96",
              "#fa5252",
              "#e64980",
              "#be4bdb",
              "#7950f2",
              "#4c6ef5",
              "#228be6",
              "#15aabf",
              "#12b886",
              "#40c057",
              "#82c91e",
              "#fab005",
              "#fd7e14",
            ]}
          />
        </Group>
      </Paper>

      <Paper
        withBorder
        component={Stack}
        p={"md"}
        w={"100%"}
        px={"xl"}
        radius={"md"}
      >
        <Text fw={600} size="md" mb={-8}>
          {lang.profile.dont_show}
        </Text>

        <DontShowAgain
          type="step_delete"
          label={lang.profile.dont_show_step_delete}
        />

        <DontShowAgain
          type="library_auto_show"
          label={lang.profile.dont_show_library_workflow}
        />
      </Paper>
    </Group>
  );
};

export default Interface;
