import { Button, Tooltip } from "@mantine/core";
import { IconUserCheck } from "@tabler/icons-react";
import { useLanguage } from "../../../../../../stores/LanguageStore";

const ContactNumber = ({ number }) => {
  const lang = useLanguage((s) => s.language);
  if (!number) {
    return;
  }
  return (
    <Tooltip label={`${number} ${lang.campaign.nodes.passed_contacts}`}>
      <Button
        leftSection={<IconUserCheck size={14} />}
        variant="light"
        pos={"absolute"}
        right={2}
        top={-26}
        size="compact-xs"
        opacity={0.8}
      >
        {number}
      </Button>
    </Tooltip>
  );
};

export default ContactNumber;
