import { useLeadsStore } from "../stores/LeadsStore";
import { mainAxios as axios } from "./axios";

export const getZapierHooks = async () => {
  const res = await axios.get(`/zapier`);
  if (res) {
    useLeadsStore.getState().setZapierHooks(res.data.zapier_hooks_list);
  }
  return res;
};
export const createZapierHooks = async ({
  name,
  source,
  event,
  url,
  active,
  filter,
  resend_synchronized_contacts = true,
}) => {
  return axios.post(`/zapier`, {
    name,
    source,
    event,
    url,
    active,
    filter,
    resend_synchronized_contacts,
  });
};
export const editZapierHooks = async ({
  name,
  id,
  source,
  event,
  url,
  active,
  filter,
  resend_synchronized_contacts,
}) => {
  return axios.put(`/zapier/${id}`, {
    name,
    source,
    event,
    url,
    active,
    filter,
    resend_synchronized_contacts,
  });
};
export const deleteZapierHook = async ({ id }) => {
  return axios.delete(`/zapier/${id}`);
};
export const activateZapierHook = async ({ id }) => {
  return axios.put(`/zapier/${id}/activate`);
};
export const deactivateZapierHook = async ({ id }) => {
  return axios.put(`/zapier/${id}/deactivate`);
};

export const testZapierHook = async ({ id }) => {
  return axios.post(`/zapier/${id}/test`);
};
export const sendZapierHook = async ({ id, contact_id }) => {
  return axios.post(`/zapier/${id}/send`, { contact_id: contact_id || null });
};
