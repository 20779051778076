import { Stack, Text } from "@mantine/core";
import { Handle, Position } from "reactflow";
import { nodeWidthPreview } from "../../flowSettings";
import { useLanguage } from "../../../../../../../stores/LanguageStore";

const StartNodePreview = () => {
  const lang = useLanguage((s) => s.language);
  return (
    <>
      <Handle
        type="source"
        position={Position.Bottom}
        style={{ visibility: "hidden" }}
      />
      <Stack w={nodeWidthPreview}>
        <Text fz={"lg"} ta={"center"} fw={500}>
          🚀 {lang.campaign.nodes.preview_node}
        </Text>
      </Stack>
    </>
  );
};

export default StartNodePreview;
