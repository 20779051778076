import { Button, Group, NumberFormatter, Text, Tooltip } from "@mantine/core";
import { useCallback, useMemo } from "react";
import { useLeadsContainerStore } from "../../../../../stores/LeadsStore";
import { useLanguage } from "../../../../../stores/LanguageStore";
import HeaderMenu from "./HeaderMenu";

const ContainerHeader = ({ container }) => {
  const lang = useLanguage((s) => s.language);
  const containerColor = useMemo(
    () => `#${container?.color || "333333"}`,
    [container]
  );
  const selectedIds = useLeadsContainerStore((s) => s.selectedIds);
  const setSelectedIds = useLeadsContainerStore((s) => s.setSelectedIds);
  const selectedColumns = useLeadsContainerStore((s) => s.selectedColumns);
  const setSelectedColumns = useLeadsContainerStore(
    (s) => s.setSelectedColumns
  );
  const unselectedIds = useLeadsContainerStore((s) => s.unselectedIds);
  const setUnselectedIds = useLeadsContainerStore((s) => s.setUnselectedIds);

  const toggleSelectAll = useCallback(() => {
    // Toggle true
    if (!selectedColumns.includes(container.id)) {
      setSelectedColumns([...selectedColumns, container.id]);
      setSelectedIds({ ...selectedIds, [container.id]: [] });
      setUnselectedIds({ ...unselectedIds, [container.id]: [] });
    }
    // Toggle false
    else {
      setSelectedColumns(selectedColumns.filter((x) => x !== container.id));
      setSelectedIds({ ...selectedIds, [container.id]: [] });
      setUnselectedIds({ ...unselectedIds, [container.id]: [] });
    }
  }, [
    selectedIds,
    container.id,
    setSelectedColumns,
    setSelectedIds,
    setUnselectedIds,
    unselectedIds,
    selectedColumns,
  ]);

  return (
    <Group
      w={"100%"}
      py={"xs"}
      px={"sm"}
      style={{
        borderBottom: "1px solid #00000018",
        background: containerColor + "0a",
        boxShadow: "#0000000a 0 1px 3px",
      }}
      wrap="nowrap"
      className="prm-header"
    >
      <Tooltip label={container.name}>
        <Text
          fw={500}
          fz={14}
          truncate="end"
          //   {...attributes}
          //   {...listeners}
          c={containerColor}
          pos={"relative"}
          style={{ zIndex: 65 }}
        >
          {container.name}
        </Text>
      </Tooltip>

      <Group wrap="nowrap" ml={"auto"} gap={"xs"}>
        <Tooltip
          label={`${
            selectedColumns.includes(container.id)
              ? lang.prm.container.deselect
              : lang.prm.container.select
          } ${lang.prm.container.all_prospect_in} ${container.name}`}
        >
          <Button
            color={
              selectedColumns.includes(container.id)
                ? "primary"
                : containerColor
            }
            disabled={!container?.loaded}
            onClick={toggleSelectAll}
            variant={
              selectedColumns.includes(container.id) ? "filled" : "light"
            }
            size="compact-sm"
            px={6}
            py={4}
            miw={32}
          >
            <NumberFormatter value={container?.total} thousandSeparator=" " />
          </Button>
        </Tooltip>
        <HeaderMenu container={container} />
      </Group>
    </Group>
  );
};

export default ContainerHeader;
