import { Anchor, Group } from "@mantine/core";
import { useLanguage } from "../../stores/LanguageStore";

const LanguageChanger = () => {
  const lang = useLanguage((s) => s.language);
  const changeLanguage = useLanguage((s) => s.changeLanguage);

  return (
    <Group pos={"absolute"} bottom={16} left={"50%"} style={{ transform: "translateX(-50%)" }} >
      <Anchor
        size="sm"
        onClick={() => changeLanguage("FRA")}
        c={lang.id === "FRA" ? "tertiary" : "primary"}
        fw={600}
      >
        <Group gap={"xs"}>
          {/* <Flag code="fr" height={16} /> */}

          <span>Français</span>
        </Group>
      </Anchor>
      <span>-</span>
      <Anchor
        size="sm"
        onClick={() => changeLanguage("ENG")}
        c={lang.id !== "FRA" ? "tertiary" : "primary"}
        fw={600}
      >
        <Group gap={"xs"}>
          {/* <Flag code="us" height={16} /> */}

          <span>English</span>
        </Group>
      </Anchor>
    </Group>
  );
};

export default LanguageChanger;
