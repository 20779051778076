import { Group } from "@mantine/core";
import TableSearch from "./TableSearch";
import TableColumns from "./columns/TableColumns";
import TableReload from "./TableReload";
import {
  gridFilterModelSelector,
  gridRowSelectionStateSelector,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import TableFilter from "./TableFilter";
import TableConfigs from "./configs/TableConfigs";
import DisplayFoldertems from "./configs/DisplayFoldertems";

const TableToolbar = () => {
  const apiRef = useGridApiContext();
  const selectedIds = useGridSelector(apiRef, gridRowSelectionStateSelector);
  const filter = useGridSelector(apiRef, gridFilterModelSelector);

  const {
    tableId,
    pageName,
    noRadiusLeft,
    toolbarActions,
    setLoading,
    isClient,
    pageOrderName,
    defaultSearch,
    disableSearch,
    disableFilter,
    disableVisibleColumns,
    setHasQuery,
    hasQuery,
    additionalFilter
  } = useGridRootProps();

  return (
    <Group gap={0}>
      <TableSearch
        noRadiusLeft={noRadiusLeft}
        defaultSearch={defaultSearch}
        disabled={disableSearch}
      />

      {setHasQuery && (
        <DisplayFoldertems hasQuery={hasQuery} setHasQuery={setHasQuery} />
      )}
      
      {!disableFilter && (
        <TableFilter
          tableId={tableId}
          pageName={pageName}
          isClient={isClient}
          additionalFilter={additionalFilter}
        />
      )}

      {!disableVisibleColumns && (
        <TableColumns pageName={pageName} pageOrderName={pageOrderName} />
      )}

      <TableConfigs />

      {toolbarActions && (
        <Group ml={"auto"} gap={0}>
          {toolbarActions({ selectedIds, filter })}
        </Group>
      )}

      <Group ml={"auto"}>
        {setLoading && <TableReload setLoading={setLoading} />}
      </Group>
    </Group>
  );
};

export default TableToolbar;
