import { useCallback, useMemo } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useLeadsContainerStore } from "../../../../stores/LeadsStore";
import { Anchor, Group, Text } from "@mantine/core";

const ContainersFooter = () => {
  const lang = useLanguage((s) => s.language);
  const selectedIds = useLeadsContainerStore((s) => s.selectedIds);
  const setSelectedIds = useLeadsContainerStore((s) => s.setSelectedIds);
  const selectedColumns = useLeadsContainerStore((s) => s.selectedColumns);
  const setSelectedColumns = useLeadsContainerStore(
    (s) => s.setSelectedColumns
  );
  const unselectedIds = useLeadsContainerStore((s) => s.unselectedIds);
  const setUnselectedIds = useLeadsContainerStore((s) => s.setUnselectedIds);
  const containers = useLeadsContainerStore((s) => s.containers);

  const total = useMemo(() => {
    let result = 0;
    containers
      .filter((x) => x.visible)
      .forEach((c) => {
        result += c?.total || 0;
      });

    return result;
  }, [containers]);

  const handleDeselect = () => {
    setSelectedIds({});
    setUnselectedIds({});
    setSelectedColumns([]);
  };

  const getSelected = useCallback(() => {
    let final = 0;
    containers.forEach((container) => {
      if (selectedColumns.includes(container.id)) {
        let total_col = container.total;
        let total_unselected = unselectedIds[container.id]?.length || 0;

        final += total_col - total_unselected;
      } else {
        final += selectedIds[container.id]?.length || 0;
      }
    });

    return final;
  }, [containers, selectedColumns, unselectedIds, selectedIds]);

  return (
    <Group>
      {getSelected() > 0 && (
        <Text size="sm" fw={400}>
          <b>
            {getSelected()
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
            {lang.prm.footer.selected_prospect}
          </b>
          .
          <Anchor
            ml={8}
            fw={500}
            style={{ cursor: "pointer" }}
            onClick={handleDeselect}
          >
            {lang.prm.footer.deselect}
          </Anchor>
        </Text>
      )}
      <Text ml={"auto"} size="sm" fw={400}>
        <b>
          {total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
          {lang.prm.footer.visible_prospect}
        </b>
      </Text>
    </Group>
  );
};

export default ContainersFooter;
