import { Button, Divider, Group, Modal, SimpleGrid, Text } from "@mantine/core";
import { IconChevronRight, IconSparkles } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import ContactListSelector, {
  getListOptionName,
} from "../../../../../components/Selectors/ContactListSelector";
import { useListStore } from "../../../../../stores/ListStore";
import Sparkles from "../../../../../components/Sparkles/Sparkles";
import { getLists } from "../../../../../api/ContactAPI";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { useDisclosure } from "@mantine/hooks";
import ListImportItem from "../../../lists/pages/ListImportItem";
import { getListMethod } from "../../../lists/listUtils";
import BackButton from "../../../../../components/View/BackButton";

const iconStyles = {
  size: 24,
};
const Contacts = ({ programData, setProgramData, isPast }) => {
  const lang = useLanguage((s) => s.language);
  const listsStore = useListStore((s) => s.lists);
  const [loading, setLoading] = useState(false);
  const [opened, { open, close }] = useDisclosure();

  const [lists, setLists] = useState(
    programData?.contactlist_ids?.map((listId) => {
      let list = listsStore?.find((x) => x.id === listId);
      if (list) {
        return {
          ...list,
          option_name: getListOptionName(list),
        };
      }
      return;
    }) || []
  );

  const [newItems, setNewItems] = useState([]);

  const onImportFinish = (list) => {
    setProgramData({
      ...programData,
      contactlist_ids: [...programData.contactlist_ids, list.id],
    });
    setNewItems((prev) => [...prev, list.id]);
  };

  const handleButtonClick = ({ method }) => {
    window.name = "campaign-parent";
    const childWindow = window.open(
      `/contact-lists/import/${method}?isChild=true`,
      "_blank"
    );

    // Listen for messages from the child window
    window.addEventListener("message", (event) => {
      // Check if the message is coming from the child window
      if (event.source === childWindow) {
        let newListId = event.data;
        setLoading(true);
        close();
        getLists()
          .then((response) => {
            let newList = response.data.contact_lists.find(
              (x) => x.id === newListId
            );

            onImportFinish(newList);
          })
          .finally(() => setLoading(false));
      }
    });
  };

  useEffect(() => {
    setLists(
      programData?.contactlist_ids
        ?.map((listId) => {
          let list = listsStore?.find((x) => x.id === listId);
          if (list) {
            return {
              ...list,
              option_name: getListOptionName(list),
            };
          }
          return;
        })
        ?.filter(Boolean) || []
    );
  }, [listsStore, programData]);

  return (
    <>
      <Text fz={27} fw={500} ta={"center"} mt={"sm"}>
        {lang.campaign.list_title}
      </Text>
      {isPast && (
        <Text c={"red"} ta={"center"} w={"100%"}>
          {lang.campaign.already_programmed}
        </Text>
      )}
      <Group w={"85%"} mx={"auto"}>
        <ContactListSelector
          size={"sm"}
          values={lists}
          onChange={(v) =>
            setProgramData({
              ...programData,
              contactlist_ids: v.map((x) => x.id),
            })
          }
          newItems={newItems}
          dropdownHeight={isPast ? 175 : 200}
          disabled={isPast}
          withCounts
        />
      </Group>

      <Divider
        mt={16}
        label={lang.campaign.or}
        w={"50%"}
        mx={"auto"}
        mb={12}
        styles={{
          label: {
            fontWeight: 600,
            fontSize: 14,
          },
        }}
      />

      <Group w={"100%"} justify="center" gap={"xl"}>
        <Button
          variant="gradient"
          size="md"
          leftSection={<IconSparkles {...iconStyles} />}
          onClick={open}
          loading={loading}
          radius={"sm"}
          disabled={isPast}
        >
          {lang.campaign.create_list}
        </Button>

        <Button
          variant="light"
          size="md"
          rightSection={<IconChevronRight />}
          onClick={() => document.getElementById("next-button").click()}
          disabled={programData?.contactlist_ids?.length === 0}
          w={lang.id === "FRA" ? 130 : 120}
          justify="flex-end"
          radius={"sm"}
        >
          {lang.global.next}
        </Button>
      </Group>

      <Modal
        opened={opened}
        onClose={close}
        size={"100%"}
        title={lang.contact_lists.import.methods.title}
      >
        <SimpleGrid cols={4} pt={10}>
          {getListMethod().map((item) => (
            <ListImportItem
              key={item.value}
              item={item}
              onClick={handleButtonClick}
            />
          ))}
        </SimpleGrid>
        <Group justify="end" mt={"md"}>
          <BackButton onClick={close} />
        </Group>
      </Modal>
    </>
  );
};

export default Contacts;
