import {
  Checkbox,
  Divider,
  Group,
  NumberInput,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { useMemo, useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { useProfileStore } from "../../../../../stores/UserStore";
import { DatePickerInput, TimeInput } from "@mantine/dates";
import { defaultProgramData } from "../flow/flowSettings";
import { useFlowStore } from "../../../../../stores/WorkflowStore";
import { useModelsStore } from "../../../../../stores/ConfigStore";
import { IconHelp } from "@tabler/icons-react";

const CampaignAdvancedSettings = ({ programData, isPast }) => {
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);
  const [useAB, setUseAB] = useState(!!programData?.ab_subject_2);

  const nodes = useFlowStore((s) => s.nodes);

  const subject1 = useMemo(() => {
    let first_node = nodes.find((x) => x.type === "action");
    if (
      first_node?.data?.actionType === "email" &&
      first_node?.data?.model_id
    ) {
      let model = useModelsStore
        .getState()
        ?.model_emails?.find((x) => x.id === first_node?.data?.model_id);

      return model?.subject || " - ";
    }
    return null;
  }, [nodes]);

  const days = useMemo(
    () => [
      {
        value: "allowed_monday",
        label: lang.workflows.program.step_config.more.days.monday,
      },
      {
        value: "allowed_tuesday",
        label: lang.workflows.program.step_config.more.days.tuesday,
      },
      {
        value: "allowed_wednesday",
        label: lang.workflows.program.step_config.more.days.wednesday,
      },
      {
        value: "allowed_thursday",
        label: lang.workflows.program.step_config.more.days.thursday,
      },
      {
        value: "allowed_friday",
        label: lang.workflows.program.step_config.more.days.friday,
      },
      {
        value: "allowed_saturday",
        label: lang.workflows.program.step_config.more.days.saturday,
      },
      {
        value: "allowed_sunday",
        label: lang.workflows.program.step_config.more.days.sunday,
      },
    ],
    [lang]
  );

  return (
    <>
      {profile?.permissions?.find((x) => x.name === "priorityCampaign")
        ?.value === true && (
        <Checkbox
          mb={"xs"}
          label={lang.workflows.program.step_config.more.is_prior}
          name="priority_email"
          defaultChecked={
            programData?.priority_email
              ? programData.priority_email
              : defaultProgramData.priority_email
          }
        />
      )}
      <Text mb={"xs"} size="sm">
        {lang.workflows.program.step_config.more.allowed_days}
      </Text>
      <Group>
        {days.map((x) => (
          <Checkbox
            key={x.value}
            label={x.label}
            name={x.value}
            type="checkbox"
            defaultChecked={
              programData?.[x.value] !== undefined
                ? programData[x.value]
                : defaultProgramData[x.value]
            }
          />
        ))}
      </Group>

      <Divider my={"md"} />

      <TimeInput
        label={lang.workflows.program.step_config.time_stop_sending}
        name="time_stop_sending"
        required
        w={"100%"}
        defaultValue={
          programData?.time_stop_sending || defaultProgramData.time_stop_sending
        }
        mb={"xs"}
        withSeconds
      />

      <DatePickerInput
        label={lang.workflows.program.step_config.more.date_stop}
        placeholder={lang.workflows.program.step_config.more.date_stop}
        name="date_stop"
        defaultValue={
          programData?.date_stop
            ? new Date(programData?.date_stop || null)
            : undefined
        }
      />
      <Checkbox
        label={lang.workflows.program.step_config.more.stop_steps_at_end_date}
        name="stop_steps_at_end_date"
        mt={"xs"}
        defaultChecked={
          programData?.stop_steps_at_end_date
            ? programData.stop_steps_at_end_date
            : defaultProgramData.stop_steps_at_end_date
        }
        //   disabled={isStopDisabled}
      />

      <Divider my={"md"} />

      <NumberInput
        label={lang.workflows.program.step_config.more.limit_total_sending}
        description={
          lang.workflows.program.step_config.more.limit_total_sending_0
        }
        styles={{
          description: {
            fontSize: 11,
          },
        }}
        min={0}
        name="limit_total_sending"
        defaultValue={
          programData?.limit_total_sending ||
          defaultProgramData.limit_total_sending
        }
      />

      {/* Disable if we find an IA model */}
      {subject1 && !location.href.includes("quick") && (
        <>
          <Divider my={"md"} />

          <Group justify="space-between">
            <Tooltip
              label={lang.campaign.already_programmed}
              disabled={!isPast}
              refProp="rootRef"
            >
              <Checkbox
                label={lang.workflows.program.step_config.more.use_ab}
                checked={useAB}
                onClick={() => setUseAB(!useAB)}
                styles={{
                  label: {
                    fontWeight: 500,
                  },
                }}
                mb={8}
                disabled={isPast}
              />
            </Tooltip>

            <Tooltip
              label={lang.workflows.program.step_config.more.ab_desc}
              withArrow
            >
              <IconHelp size={21} opacity={0.75} stroke={1} />
            </Tooltip>
          </Group>
          <NumberInput
            label={lang.workflows.program.step_config.more.proportion}
            min={1}
            max={99}
            name="ab_proportion"
            disabled={isPast || !useAB}
            defaultValue={programData?.ab_proportion || 10}
            required
          />
          <TextInput
            label={lang.workflows.program.step_config.more.subject1}
            placeholder={lang.workflows.program.step_config.more.subject1}
            value={subject1}
            disabled={isPast || !useAB}
            readOnly
          />
          <TextInput
            label={lang.workflows.program.step_config.more.subject2}
            placeholder={lang.workflows.program.step_config.more.subject2}
            name="ab_subject_2"
            disabled={isPast || !useAB}
            defaultValue={programData?.ab_subject_2}
            required
          />
          <TextInput
            label={lang.workflows.program.step_config.more.subject3}
            placeholder={lang.workflows.program.step_config.more.subject3}
            name="ab_subject_3"
            disabled={isPast || !useAB}
            defaultValue={programData?.ab_subject_3}
            required
          />
        </>
      )}
    </>
  );
};

export default CampaignAdvancedSettings;
