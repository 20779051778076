import { Button, Group, Stack } from "@mantine/core";
import { Link, useParams, useRouteLoaderData } from "react-router-dom";
import Path from "../../../layouts/Path";
import EmailPreviewer from "../../../components/View/EmailPreviewer";
import CreateEmail from "./CreateModel";
import { IconEdit } from "@tabler/icons-react";
import BackButton from "../../../components/View/BackButton";
import SendBAT from "./emails/SendBAT";
import { useCallback } from "react";
import MessagePreviewer from "../../../components/View/MessagePreviewer";
import { useLanguage } from "../../../stores/LanguageStore";
import PageTitle from "../../../components/View/PageTitle";

const ModelPage = ({ loaderId }) => {
  const lang = useLanguage((s) => s.language)
  const { modelType } = useParams();
  const modelProfile = useRouteLoaderData(loaderId);

  const getComponent = useCallback(() => {
    if (modelType === "email") {
      return (
        <EmailPreviewer
          html={modelProfile?.html}
          subject={modelProfile?.subject}
          iframeStyle={{ flex: "1" }}
          paperShadow="none"
        />
      );
    }
    if (modelType === "linkedin-invitation") {
      return (
        <MessagePreviewer
          text={modelProfile?.text}
          sender={modelProfile?.sender}
          iframeStyle={{ flex: "1 1 auto", height: "100%" }}
          paperShadow="none"
          type={"linkedin_invitation"}
        />
      );
    }
    if (modelType === "linkedin-message") {
      return (
        <MessagePreviewer
          text={modelProfile?.text}
          sender={modelProfile?.sender}
          iframeStyle={{ flex: "1 1 auto", height: "100%" }}
          paperShadow="none"
          type={"linkedin_message"}
          withExample
        />
      );
    }
    if (modelType === "sms") {
      return (
        <MessagePreviewer
          text={modelProfile?.text}
          sender={modelProfile?.sender}
          iframeStyle={{ flex: "1 1 auto", height: "100%" }}
          paperShadow="none"
          type={"sms"}
          withExample
        />
      );
    }
  }, [modelProfile, modelType]);
  return (
    <>
      <Group className="layout-block top" justify="space-between">
        <PageTitle title={modelProfile?.name} />
        <CreateEmail />
      </Group>

      <Stack
        w={"60%"}
        mx={"auto"}
        h={modelType === "email" ? "100%" : "auto"}
        flex={1}
        className="layout-block"
        px={"sm"}
        pt={"sm"}
        gap={"xs"}
      >
        {getComponent()}
        <Group justify="right">
          {modelType === "email" && (
            <Group mr={"auto"}>
              <SendBAT model={modelProfile} />
            </Group>
          )}
          <BackButton />
          <Button
            component={Link}
            to={"edit"}
            leftSection={<IconEdit size={18} />}
          >
            {lang.global.edit}
          </Button>
        </Group>
      </Stack>
    </>
  );
};

export default ModelPage;
