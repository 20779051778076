import { Button, Group, Stack, Text, TextInput, Tooltip } from "@mantine/core";
import PopOver from "../../../../../components/View/PopOver";
import { IconArrowMerge, IconChevronDown } from "@tabler/icons-react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { useState } from "react";
import { mergeLists } from "../../../../../api/ContactAPI";

const MergeLists = ({ onFinish, selectedIds }) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(null);

  const main = (e, handleClose) => {
    e.preventDefault();
    const fd = new FormData(e.target);
    const data = Object.fromEntries(fd);

    setLoading(true);
    mergeLists({
      name: data.name,
      list_ids: selectedIds,
    })
      .then((res) => {
        onFinish?.(res.data.contact_list_id);
        handleClose();
      })
      .finally(() => {
        setLoading(null);
      });
  };

  return (
    <PopOver
      opener={(handleOpen, ref) => (
        <Tooltip label={lang.contact_lists.merge.tooltip}>
          <Button
            className="toolbar-action-button"
            variant="light"
            color="tertiary.8"
            justify="start"
            ref={ref}
            rightSection={<IconChevronDown size={16} />}
            onClick={handleOpen}
            disabled={selectedIds?.length < 2}
          >
            <IconArrowMerge size={18} />
          </Button>
        </Tooltip>
      )}
      popoverChildren={(handleClose) => (
        <Stack
          component={"form"}
          onSubmit={(e) => main(e, handleClose)}
          gap={"xs"}
          px={"xs"}
        >
          <Text size="sm">
            {lang.contact_lists.merge.dialog.text1}{" "}
            <b>
              {selectedIds.length} {lang.contact_lists.merge.dialog.text_lists}
            </b>{" "}
            {lang.contact_lists.merge.dialog.text2}
          </Text>
          <TextInput
            label={lang.contact_lists.merge.dialog.new_list_name}
            placeholder={lang.contact_lists.merge.dialog.new_list_name}
            required
            autoFocus
            name="name"
          />
          <Group justify="end">
            <Button
              onClick={handleClose}
              size="compact-sm"
              variant="subtle"
              disabled={loading}
            >
              {lang.components.closer.submit}
            </Button>
            <Button
              type="submit"
              loading={loading}
              size="compact-sm"
              color="tertiary.8"
            >
              {lang.contact_lists.merge.dialog.submit_button}
            </Button>
          </Group>
        </Stack>
      )}
    />
  );
};

export default MergeLists;
