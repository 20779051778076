import {
  Accordion,
  Button,
  Group,
  Radio,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import Path from "../../../../layouts/Path";
import { useState } from "react";
import { useProfileStore, useSessionStore } from "../../../../stores/UserStore";
import { useLanguage } from "../../../../stores/LanguageStore";
import { signup } from "../../../../api/AuthAPI";
import { useRouteLoaderData } from "react-router-dom";
import PhoneNumberInput from "../../../../components/Selectors/PhoneNumberInput";
import PasswordField from "../../../../components/View/PasswordField";
import CommunitySelector from "../../../../components/Sharing/CommunitySelector";
import BackButton from "../../../../components/View/BackButton";
import { IconUserPlus } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

const CreateUserPage = () => {
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);
  const reseller = useSessionStore((s) => s.reseller);
  const current = useRouteLoaderData("reseller");

  //#region Signup
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    email: false,
    password: false,
    phone: false,
    fName: false,
    lName: false,
    unknown: false,
  });
  const [pwdError, setPwdError] = useState(true);
  const [teams, setTeams] = useState(
    profile.teams.map((x) => ({ ...x, option_name: x.name }))
  );
  const [orgs, setOrgs] = useState(
    profile.organizations.map((x) => ({ ...x, option_name: x.name }))
  );

  const handlePhoneError = () => {
    setErrors({ phone: true });
    document.getElementById("phone").focus();
    setLoading(false);
  };
  const handlePwdError = () => {
    setErrors({ password: true });
    document.getElementById("pwd").focus();
    setLoading(false);
  };
  const handleEmailError = () => {
    setErrors({ email: true });
    document.getElementById("email").focus();
    setLoading(false);
  };
  const handleFNameError = () => {
    setErrors({ fName: true });
    document.getElementById("first_name").focus();
    setLoading(false);
  };
  const handleLNameError = () => {
    setErrors({ lName: true });
    document.getElementById("last_name").focus();
    setLoading(false);
  };
  const handleUnknownError = () => {
    setErrors({ unknown: true });
    setLoading(false);
  };

  const navigate = useNavigate();
  const handleSignup = (e) => {
    e.preventDefault();

    setErrors({});
    let formData = new FormData(e.target);
    const data = Object.fromEntries(formData);

    data.domain_saas = current
      ? current?.domain_saas2 || current?.domain_saas
      : reseller?.domain_saas === "application.magileads.net"
      ? null
      : reseller?.domain_saas || null;
    data.email_validation_url_success = `https://${
      reseller?.domain_saas2 || reseller?.domain_saas
    }/email-validated`;
    data.email_validation_url_error = `https://${
      reseller?.domain_saas2 || reseller?.domain_saas
    }/email-not-validated`;
    data.language = profile?.language;
    data.team_codes = teams?.length > 0 ? teams.map((x) => x.team_code) : null;
    data.organization_codes =
      orgs?.length > 0 ? orgs.map((x) => x.organization_code) : null;
    data.show_onboarding = false;

    if (pwdError) {
      return handlePwdError();
    } else {
      setLoading(true);
      signup(data)
        .then((response) => {
          navigate("..");
        })
        .catch((error) => {
          let data = error.response.data;
          if (data.state_message === "empty_last_name") {
            return handleLNameError();
          }
          if (data.state_message === "empty_first_name") {
            return handleFNameError();
          }
          if (data.state_message === "incorrect_email") {
            return handleEmailError();
          }
          if (data.state_message === "mail_error") {
            return handleEmailError();
          }
          if (data.state_message === "email_used") {
            return handleEmailError();
          }
          if (data.state_message === "incorrect_mobile_phone") {
            return handlePhoneError();
          }
          if (data.state_message === "mobile_phone_used") {
            return handlePhoneError();
          } else {
            handleUnknownError();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  //#endregion

  return (
    <>
      <Group
        className="layout-block top"
        w={"100%"}
        gap={0}
        justify="space-between"
      >
        <Path />
      </Group>

      <Stack
        className="layout-block"
        flex={"1 1 auto"}
        component={"form"}
        onSubmit={handleSignup}
        w={"60%"}
        mx={"auto"}
        pr={24}
        pos={"relative"}
      >
        <Text size="lg">
          {lang.users_list.create.dialog.title}{" "}
          <b>
            {current?.name} #{current?.id}
          </b>
        </Text>
        <Group wrap="nowrap" gap={"lg"}>
          <TextInput
            name="first_name"
            id="first_name"
            label={lang.signup.first_name}
            placeholder={lang.signup.first_name}
            required
            error={errors.fName}
            w={"100%"}
            autoFocus
          />
          <TextInput
            name="last_name"
            id="last_name"
            label={lang.signup.last_name}
            placeholder={lang.signup.last_name}
            required
            error={errors.lName}
            w={"100%"}
          />
        </Group>

        <Group wrap="nowrap" gap={"lg"}>
          <TextInput
            name="email"
            id="email"
            label={lang.signup.mail}
            placeholder={lang.signup.mail}
            required
            error={errors.email}
            w={"100%"}
            type="email"
          />

          <PhoneNumberInput
            defaultCountry={profile?.mobile_phone_country || "FR"}
            defaultValue={null}
            name={"mobile_phone"}
            required
            label={lang.profile.mobile_phone}
            w={"100%"}
            id="phone"
            error={errors.phone}
          />
        </Group>

        <Group wrap="nowrap" gap={"lg"} align="start">
          <PasswordField error={pwdError} setError={setPwdError} id={"pwd"} />

          <Radio.Group
            name="sex"
            label={lang.signup.civility}
            required
            w={"100%"}
          >
            <Group mt="xs">
              <Radio value="male" label={lang.signup.civility_male} required />
              <Radio
                value="female"
                label={lang.signup.civility_female}
                required
              />
            </Group>
          </Radio.Group>
        </Group>

        <Accordion variant="contained" mt={"xs"} defaultValue={"community"}>
          <Accordion.Item value={"community"}>
            <Accordion.Control bg={"var(--mantine-color-gray-0)"}>
              {lang.users_list.create.advanced}
            </Accordion.Control>
            <Accordion.Panel>
              <Stack>
                <CommunitySelector
                  values={teams}
                  onChange={setTeams}
                  label={lang.teams.browser_tab_title}
                  placeholder={lang.components.sharing.values.team_placeholder}
                  type={"Team"}
                />
                <CommunitySelector
                  values={orgs}
                  onChange={setOrgs}
                  label={lang.organizations.browser_tab_title}
                  placeholder={lang.components.sharing.values.org_placeholder}
                  type={"Organization"}
                />
              </Stack>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>

        <Group justify="end" mt={"auto"}>
          <BackButton />

          <Button
            type="submit"
            leftSection={<IconUserPlus size={18} />}
            loading={loading}
            variant="gradient"
          >
            {lang.users_list.create.dialog.submit_button}
          </Button>
        </Group>
      </Stack>
    </>
  );
};

export default CreateUserPage;
