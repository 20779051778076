import { useLanguage } from "../../../../../stores/LanguageStore";
import { useDisclosure } from "@mantine/hooks";
import { Box, Button, Group, Modal, Timeline } from "@mantine/core";
import {
  IconFilter,
  IconHelp,
  IconTransitionRight,
  IconUserPlus,
} from "@tabler/icons-react";
import BackButton from "../../../../../components/View/BackButton";

const icon_props = { size: 18 };

function getTexts() {
  const lang = useLanguage.getState().language;
  return [
    {
      label: lang.contact_lists.import.how.nurturing.step1,
      icon: <IconFilter {...icon_props} />,
    },
    {
      label: lang.contact_lists.import.how.nurturing.step2,
      icon: <IconTransitionRight {...icon_props} />,
    },
    {
      label: lang.contact_lists.import.how.nurturing.step3,
      icon: <IconUserPlus {...icon_props} />,
    },
  ];
}

const NurturingHowItWorks = () => {
  const lang = useLanguage((s) => s.language);
  const [opened, { open, close }] = useDisclosure();
  return (
    <>
      <Button
        variant="light"
        onClick={open}
        mr={"auto"}
        radius={"sm"}
        leftSection={<IconHelp size={18} />}
      >
        {lang.contact_lists.import.how.title}
      </Button>

      <Modal
        opened={opened}
        onClose={close}
        title={lang.contact_lists.import.how.title}
        size={"40%"}
      >
        <Timeline
          bulletSize={32}
          lineWidth={2}
          color="primary"
          active={0}
          reverseActive
        >
          {getTexts()?.map((step, index) => (
            <Timeline.Item
              key={index}
              bullet={step.icon}
              title={step.label}
              styles={{
                itemTitle: {
                  fontWeight: 400,
                  marginTop: step?.noMarginTop ? 0 : 8,
                  lineHeight: 1.25,
                },
                item: {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                },
              }}
            >
              <Box h={8} />
            </Timeline.Item>
          ))}
        </Timeline>

        <Group justify="end" mt={"sm"}>
          <BackButton onClick={close} />
        </Group>
      </Modal>
    </>
  );
};

export default NurturingHowItWorks;
