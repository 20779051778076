import {
  Button,
  Divider,
  Flex,
  Group,
  InputLabel,
  Paper,
  Select,
  Stack,
  Tooltip,
} from "@mantine/core";
import { IconEdit, IconEye, IconTrash, IconX } from "@tabler/icons-react";
import { useLanguage } from "../../stores/LanguageStore";
import UsersSelector from "../UserSelector/UsersSelector";
import CommunitySelector from "./CommunitySelector";
import { Controller, useWatch } from "react-hook-form";
import { useCallback } from "react";

const SharingItem = ({
  index,
  setValue,
  update,
  getValues,
  control,
  handleDelete,
  readOnly,
  disablePermissions,
  disableDelete,
}) => {
  const lang = useLanguage((s) => s.language);

  const values = getValues(`sharing.${index}`);
  useWatch({
    control,
    name: [
      `sharing.${index}.write_permission`,
      `sharing.${index}.delete_permission`,
    ],
  });

  const handleTypeChange = (v) => {
    update(index, { ...values, sharing_type_to: v, sharing_targets: [] });
  };

  const handleTargetsChange = useCallback(
    (change) => {
      update(index, {
        ...values,
        sharing_targets: change.map((x) => ({
          id: x.id,
          option_name:
            x?.option_name || x?.name || `${x.first_name} ${x.last_name}`,
        })),
      });
    },
    [index, update, values]
  );

  const getTargetsComponents = useCallback(() => {
    let sharing_type_to = values.sharing_type_to;
    let sharing_targets = values.sharing_targets;

    if (sharing_type_to === "User") {
      return (
        <UsersSelector
          values={sharing_targets}
          onChange={handleTargetsChange}
          placeholder={lang.components.sharing.values.user_placeholder}
          inputStyles={{
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0,
          }}
        />
      );
    } else {
      return (
        <CommunitySelector
          values={sharing_targets}
          onChange={handleTargetsChange}
          placeholder={
            lang.components.sharing.values[
              sharing_type_to === "Team"
                ? "team_placeholder"
                : "org_placeholder"
            ]
          }
          type={sharing_type_to}
          inputStyles={{
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0,
          }}
        />
      );
    }
  }, [
    handleTargetsChange,
    lang.components.sharing.values,
    values.sharing_targets,
    values.sharing_type_to,
  ]);

  return (
    <>
      <Paper bg={"none"}>
        <Group align="start" wrap="nowrap">
          <Stack gap={0} w={"100%"}>
            <InputLabel size="sm" required mb={2}>
              {lang.components.sharing.values.label}
            </InputLabel>
            <Group wrap="nowrap" gap={0} align="stretch">
              <Controller
                render={({ field }) => (
                  <Select
                    data={[
                      {
                        value: "User",
                        label: lang.components.sharing.types.user,
                      },
                      {
                        value: "Team",
                        label: lang.components.sharing.types.team,
                      },
                      {
                        value: "Organization",
                        label: lang.components.sharing.types.org,
                      },
                    ]}
                    placeholder={lang.components.sharing.types.label}
                    w={120}
                    styles={{
                      wrapper: {
                        height: "100%",
                      },
                      input: {
                        borderRight: 0,
                        borderBottomRightRadius: 0,
                        borderTopRightRadius: 0,
                        height: "100%",
                      },
                    }}
                    {...field}
                    onChange={handleTypeChange}
                  />
                )}
                name={`sharing.${index}.sharing_type_to`}
                control={control}
              />
              <Flex flex={"1 1 auto"} w={0}>
                {getTargetsComponents()}
              </Flex>
            </Group>
          </Stack>
          {!disablePermissions && (
            <Stack gap={0} miw={100}>
              <InputLabel size="sm" required mb={2}>
                {lang.components.sharing.permissions.label}
              </InputLabel>
              <Group wrap="nowrap" gap={"xs"} mt={4}>
                <Tooltip label={lang.components.sharing.permissions.read}>
                  <Button variant="default" px={6} size="xs" disabled>
                    <IconEye size={16} />
                  </Button>
                </Tooltip>

                {!readOnly && (
                  <Tooltip label={lang.components.sharing.permissions.write}>
                    <Button
                      color="tertiary.8"
                      variant={values.write_permission ? "filled" : "outline"}
                      px={6}
                      size="xs"
                      onClick={() =>
                        setValue(
                          `sharing.${index}.write_permission`,
                          !values.write_permission
                        )
                      }
                    >
                      <IconEdit size={16} />
                    </Button>
                  </Tooltip>
                )}

                {!readOnly && (
                  <Tooltip label={lang.components.sharing.permissions.delete}>
                    <Button
                      color="tertiary.8"
                      variant={values.delete_permission ? "filled" : "outline"}
                      px={6}
                      size="xs"
                      onClick={() =>
                        setValue(
                          `sharing.${index}.delete_permission`,
                          !values.delete_permission
                        )
                      }
                    >
                      <IconTrash size={16} />
                    </Button>
                  </Tooltip>
                )}
              </Group>
            </Stack>
          )}
        </Group>
        {!disableDelete && (
          <Group justify="center">
            <Button
              color="red"
              variant="light"
              onClick={handleDelete}
              size={"compact-xs"}
              leftSection={<IconX size="1rem" />}
              mt={8}
            >
              {lang.global.delete}
            </Button>
          </Group>
        )}
      </Paper>

      <Divider my={8} />
    </>
  );
};

export default SharingItem;
