import {
  ActionIcon,
  Anchor,
  Group,
  Paper,
  Stack,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import {
  IconArrowBarLeft,
  IconArrowBarRight,
  IconFileDescription,
} from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useUIStore } from "../../../stores/UIStore";

const ContentPage = ({ group, title, content }) => {
  const openedSupportLeft = useUIStore((s) => s.openedSupportLeft);
  const setOpenedSupportLeft = useUIStore((s) => s.setOpenedSupportLeft);
  const [closed, setClosed] = useState(true);

  useEffect(() => {
    if (openedSupportLeft) {
      setTimeout(() => {
        setClosed(false);
      }, 150);
    } else {
      setTimeout(() => {
        setClosed(true);
      }, 50);
    }
  }, [openedSupportLeft]);

  const GroupIcon = group.icon;

  return (
    <Group wrap="nowrap" align="start" gap={"md"}>
      <Paper
        component={Stack}
        withBorder
        p={"md"}
        radius={"md"}
        w={openedSupportLeft ? "30%" : 48}
        style={{ transition: "all ease .3s" }}
        pos={"sticky"}
        top={16}
        pb={openedSupportLeft ? "md" : 30}
      >
        {openedSupportLeft ? (
          <ActionIcon
            variant="subtle"
            onClick={() => setOpenedSupportLeft(false)}
            pos={"absolute"}
            right={6}
            top={6}
            size={"lg"}
          >
            <IconArrowBarLeft size={22} />
          </ActionIcon>
        ) : (
          <ActionIcon
            ml={"auto"}
            variant={"subtle"}
            onClick={() => setOpenedSupportLeft(true)}
            pos={"absolute"}
            right={6}
            top={6}
            size={"lg"}
            style={{ zIndex: 8 }}
          >
            <IconArrowBarRight size={22} />
          </ActionIcon>
        )}

        <Group opacity={closed ? 0 : 1} display={closed ? "none" : "flex"}>
          <GroupIcon />

          <Title order={4}>{group.title}</Title>
        </Group>

        <Stack
          mt={"xs"}
          gap={"sm"}
          opacity={closed ? 0 : 1}
          display={closed ? "none" : "flex"}
        >
          {group.items.map((item) => (
            <Tooltip label={item.title} key={item.url}>
              <Anchor
                key={item.url}
                component={NavLink}
                to={`/support/${group.url}/${item.url}`}
                truncate="end"
                size="lg"
              >
                <Group wrap="nowrap">
                  <IconFileDescription size={18} style={{ minWidth: 18 }} />

                  <Text truncate="end">{item.title}</Text>
                </Group>
              </Anchor>
            </Tooltip>
          ))}
        </Stack>
      </Paper>

      <Paper
        component={Stack}
        radius={"md"}
        withBorder
        bg={"white"}
        px={48}
        pt={"md"}
        pb={"xl"}
        flex={1}
      >
        <Title order={2}> {title} </Title>

        <div className="support-content">{content}</div>
      </Paper>
    </Group>
  );
};

export default ContentPage;
