import { useEffect, useState } from "react";
import { useLanguage } from "../../../stores/LanguageStore";
import {
  ActionIcon,
  Button,
  Checkbox,
  Group,
  Loader,
  Modal,
  NumberInput,
  Stack,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { IconFileImport, IconLink, IconLinkOff } from "@tabler/icons-react";
import BackButton from "../../../components/View/BackButton";

const AddFile = ({ item, onAdd, isOpened, setIsOpened }) => {
  const lang = useLanguage((s) => s.language);
  const [isLinked, setIsLinked] = useState(true);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setIsOpened?.(false);
  };

  useEffect(() => {
    if (isOpened) {
      handleClick();
    }
  }, [isOpened]);

  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [aspectRatio, setAspectRatio] = useState(1);
  function handleImageLoad(e) {
    const _width = e.target.naturalWidth;
    const _height = e.target.naturalHeight;
    setWidth(_width);
    setHeight(_height);
    setAspectRatio(_width / _height);
    setLoading(false);
  }

  const [linkText, setLinkText] = useState(
    lang.files.grid.adder.download + " " + item.name
  );
  const [toNew, setToNew] = useState(true);

  const handleAdd = () => {
    if (item.type === "image") {
      onAdd({ isImage: true, width: width, height: height });
    } else {
      onAdd({ label: linkText, toNew: toNew });
    }
  };

  const handleWidthChange = (v) => {
    let newValue = parseInt(v);
    setWidth(newValue);
    if (isLinked) {
      setHeight(Math.floor(newValue / aspectRatio));
    }
  };

  const handleHeightChange = (v) => {
    let newValue = parseInt(v);
    setHeight(newValue);
    if (isLinked) {
      setWidth(Math.floor(newValue * aspectRatio));
    }
  };

  return (
    <>
      <Tooltip label={"Ajouter dans l'éditeur"}>
        <ActionIcon
          variant="subtle"
          size={"md"}
          color="tertiary.8"
          onClick={handleClick}
        >
          <IconFileImport size={18} />
        </ActionIcon>
      </Tooltip>

      {open && (
        <Modal
          opened={open}
          title={item.name}
          size={item.type === "image" ? "60%" : "md"}
        >
          {item.type === "image" && (
            <Group mb={16}>
              <NumberInput
                label={lang.files.grid.adder.width}
                value={width}
                onChange={handleWidthChange}
                w={120}
                rightSection={<Text>px</Text>}
              />
              <Tooltip label={lang.files.grid.adder.preserve}>
                <ActionIcon
                  onClick={() => setIsLinked(!isLinked)}
                  mt={21}
                  variant="default"
                >
                  {isLinked ? (
                    <IconLink size={18} />
                  ) : (
                    <IconLinkOff size={18} />
                  )}
                </ActionIcon>
              </Tooltip>
              <NumberInput
                label={lang.files.grid.adder.height}
                value={height}
                onChange={handleHeightChange}
                w={120}
                rightSection={<Text>px</Text>}
              />
            </Group>
          )}

          <Stack
            h={item.type === "image" ? "calc(60vh - 18px)" : "auto"}
            style={{ overflow: "auto" }}
            align="center"
            justify="center"
          >
            {item.type === "image" ? (
              <>
                {loading && <Loader type="dots" size={48} />}
                <img
                  src={item.file_url}
                  alt={item.name}
                  style={{
                    // objectFit: "scale-down",
                    width,
                    height,
                    margin: "0 auto",
                  }}
                  onLoad={handleImageLoad}
                />
              </>
            ) : (
              <Stack w="100%" mb={"auto"}>
                <TextInput
                  label={lang.files.grid.adder.link_text}
                  placeholder={lang.files.grid.adder.link_text}
                  value={linkText}
                  w="100%"
                  onChange={(e) => setLinkText(e.target.value)}
                  required
                />
                <TextInput
                  label={lang.files.grid.adder.link}
                  value={item.file_url}
                  w="100%"
                  readOnly
                />
                <Checkbox
                  checked={toNew}
                  onChange={(e) => setToNew(e.target.checked)}
                  label={lang.files.grid.adder.open_new_tab}
                />
              </Stack>
            )}
          </Stack>
          <Group justify="end" mt={"md"}>
            <BackButton onClick={handleClose} />
            <Button
              onClick={handleAdd}
              disabled={item.type === "image" ? !width : false}
            >
              {lang.files.grid.adder.submit_button}
            </Button>
          </Group>
        </Modal>
      )}
    </>
  );
};

export default AddFile;
