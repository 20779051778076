import { TagsInput } from "@mantine/core";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { IconBuildingCommunity } from "@tabler/icons-react";
import { lazy, useState } from "react";
const FileImport = lazy(() => import("./FileImport"));

const CompaniesSelector = ({ values, setValues }) => {
  const lang = useLanguage((s) => s.language);
  const [searchValue, setSearchValue] = useState("");
  const onChange = (v) => {
    setValues(v);
  };
  return (
    <TagsInput
      label={lang.targeting_linkedin_google.current_companies}
      placeholder={lang.tags.enter_to_add}
      value={values}
      onChange={onChange}
      onBlur={(e) => {
        let v = e.target.value;
        if (v) {
          onChange([...values, v]);
          setSearchValue("");
        }
      }}
      searchValue={searchValue}
      onSearchChange={setSearchValue}
      w={"100%"}
      size="sm"
      styles={{ label: { fontSize: 13 } }}
      leftSection={<IconBuildingCommunity size={18} />}
      clearable
      rightSection={<FileImport setValues={setValues} />}
    />
  );
};

export default CompaniesSelector;
