import { ActionIcon, Popover, Stack, TextInput, Tooltip } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import { useLanguage } from "../../../stores/LanguageStore";
import FormButtons from "../../../components/Adder/FormButtons";
import { useState } from "react";
import { editFolder } from "../../../api/FolderAPI";
import { useUIStore } from "../../../stores/UIStore";
import { editPool } from "../../../api/EmailAPI";

const RenameFolder = ({ item, isPool, setTableLoading }) => {
  const lang = useLanguage((s) => s.language);
  const setAsideLoading = useUIStore((s) => s.setAsideLoading);

  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    let form = document.getElementById("rename-form");

    if (form.reportValidity()) {
      const fd = new FormData(form);
      const data = Object.fromEntries(fd);

      setLoading(true);
      const main = isPool ? editPool : editFolder;
      main({ name: data.name, id: item.id })
        .then(() => {
          setOpened(false);
          setTimeout(() => {
            setAsideLoading(true);
          }, 100);
          setTableLoading?.(true)
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <Popover trapFocus withArrow opened={opened} onChange={setOpened}>
      <Popover.Target>
        <Tooltip
          label={
            isPool
              ? lang.pools.edit.button_tooltip
              : lang.components.folder_navigator.edit_title
          }
          withArrow
        >
          <ActionIcon
            size={"xs"}
            variant="white"
            onClick={(e) => {
              e.preventDefault();
              setOpened(true);
            }}
            className="hover-target"
            style={{
              opacity: opened ? 1 : undefined,
              boxShadow: "#00000033 0 1px 3px",
            }}
          >
            <IconEdit size={14} />
          </ActionIcon>
        </Tooltip>
      </Popover.Target>

      <Popover.Dropdown onClick={(e) => e.preventDefault()} p={"xs"}>
        <Stack component={"form"} w={200} id="rename-form">
          <TextInput
            label={
              isPool
                ? lang.pools.create.dialog.name
                : lang.components.folder_navigator.create.name
            }
            placeholder={
              isPool
                ? lang.pools.create.dialog.name
                : lang.components.folder_navigator.create.name
            }
            defaultValue={item.name}
            name="name"
            data-autoFocus
            required
          />
          <FormButtons
            loading={loading}
            onCancel={() => setOpened(false)}
            onSubmit={handleSubmit}
          />
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
};

export default RenameFolder;
