import { ActionIcon, Stack, Tooltip } from "@mantine/core";
import ListDownload from "../../../lists/actions/ListDownload";
import { downloadCampaign } from "../../../../../api/StatsAPI";
import PopOver from "../../../../../components/View/PopOver";
import { IconDotsVertical } from "@tabler/icons-react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import UnarchiveCampaign from "./UnarchiveCampaign";
import ArchiveCampaign from "./ArchiveCampaign";

const CampaignActions = ({ apiRef, current, onFinish, campaignFilter }) => {
  const lang = useLanguage((s) => s.language);
  const handleFinish = (closeMenu) => {
    closeMenu?.();

    setTimeout(() => {
      onFinish?.();
    }, 100);
  };

  return (
    <>
      <PopOver
        opener={(handleOpen, ref) => (
          <Tooltip label={lang.table.more_actions}>
            <ActionIcon
              variant="subtle"
              ref={ref}
              color="primary"
              onClick={handleOpen}
            >
              <IconDotsVertical size={18} />
            </ActionIcon>
          </Tooltip>
        )}
        popoverChildren={(handleClose) => (
          <Stack gap={0}>
            <ListDownload
              current={current}
              closeMenu={handleClose}
              handler={downloadCampaign}
            />

            {campaignFilter === "archived" ? (
              <UnarchiveCampaign
                apiRef={apiRef}
                current={current}
                onFinish={handleFinish}
                disabled={!current.write_permission}
              />
            ) : (
              <ArchiveCampaign
                apiRef={apiRef}
                current={current}
                onFinish={handleFinish}
                disabled={!current.write_permission}
              />
            )}
          </Stack>
        )}
        anchorOrigin={{ horizontal: "left", vertical: "center" }}
        transformOrigin={{ horizontal: "right", vertical: "center" }}
        sx={{ zIndex: 200 }}
      />
    </>
  );
};

export default CampaignActions;
