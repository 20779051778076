import {
  Button,
  Group,
  Modal,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { IconPlus, IconUpload } from "@tabler/icons-react";
import { useLanguage } from "../../../stores/LanguageStore";
import { useEffect, useMemo, useState } from "react";
import BackButton from "../../../components/View/BackButton";
import FolderSelector from "../../../components/Selectors/FolderSelector";
import { FileUploader } from "react-drag-drop-files";
import { createFile } from "../../../api/FIleAPI";
import { notifications } from "@mantine/notifications";

const supportedTypes = [
  "JPG",
  "JPEG",
  "PNG",
  "GIF",
  "PDF",
  "POT",
  "POTX",
  "PPT",
  "PPTX",
  "XLS",
  "XLSX",
  "HTML",
  "DOC",
  "DOCX",
];

const CreateFileModal = ({ onFinish, actualFolder, ...props }) => {
  const lang = useLanguage((s) => s.language);
  const [folder, setFolder] = useState(actualFolder);
  const [opened, setOpened] = useState(false);
  const handleOpen = () => {
    setOpened(true);
  };
  const handleClose = () => {
    setOpened(false);
  };

  useEffect(() => {
    setFolder(actualFolder);
  }, [actualFolder]);

  const [incorrectLength, setIncorrectLength] = useState(false);
  const [invalidFile, setInvalidFile] = useState(false);
  const [fileKey, setFileKey] = useState(false);

  const [file, setFile] = useState(null);
  const [dragging, setDragging] = useState(false);

  const [name, setName] = useState("");

  const handleGetFile = (file) => {
    setFile(file);
    setFileKey(!fileKey);
    setIncorrectLength(false);
    setInvalidFile(false);
    if (!name && file?.name) {
      let newName = file.name.split(".");
      newName.pop();

      setName(newName.join("."));
    }
  };

  const filePickerStyles = useMemo(() => {
    if (incorrectLength || invalidFile) {
      return {
        border: "2px dashed",
        boxShadow: "#c00 0 0 8px",
        borderColor: "#cc0000",
        borderRadius: 1,
        padding: 8,
        textAlign: "center",
        cursor: "pointer",
      };
    } else
      return {
        border: "1px dashed",
        borderColor: "#00000066",
        borderRadius: 1,
        padding: 2,
        textAlign: "center",
        cursor: "pointer",
      };
  }, [incorrectLength, invalidFile]);

  const [loading, setLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let fd = new FormData(e.target);
    let data = Object.fromEntries(fd);

    setLoading(true);
    const main = createFile;
    main({
      name: data.name,
      file,
      folder_id: folder?.id || null,
    })
      .then((response) => {
        setTimeout(() => {
          notifications.show({
            message: lang.files.row_highlight["created"],
            color: "tertiary.8",
          });
        }, 300);
        handleClose();
        onFinish();
      })
      .catch((err) => {
        if (err.response.data.state_message === "voice_file_incorrect_length") {
          return setIncorrectLength(true);
        } else if (err.response.data.state_message === "invalid_voice_file") {
          return setInvalidFile(true);
        }
        return setInvalidFile(true);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Button
        leftSection={<IconPlus size={18} />}
        radius={"sm"}
        color="primary"
        ml={"auto"}
        onClick={handleOpen}
        {...props}
      >
        {lang.files.create.button_tooltip}
      </Button>

      <Modal
        opened={opened}
        onClose={handleClose}
        title={lang.files.create.dialog.title}
        size={"lg"}
      >
        <Stack component={"form"} onSubmit={handleSubmit}>
          <FileUploader
            handleChange={handleGetFile}
            key={fileKey}
            name="file"
            types={supportedTypes}
            hoverTitle={lang.components.files.drop_here}
            onDraggingStateChange={(d) => {
              setDragging(d);
            }}
            required={!file}
          >
            <Stack
              align="center"
              gap={"xs"}
              h={200}
              justify="center"
              style={filePickerStyles}
            >
              <IconUpload size={32} />
              <Text style={{ opacity: dragging ? 0.1 : 1 }}>
                {lang.components.files.drag_drop_here}
              </Text>
              {file ? (
                <Text fw={600} c={"tertiary.8"}>
                  {lang.files.create.dialog.selected}: {file?.name}
                </Text>
              ) : (
                <Text fw={600} c={"red"}>
                  {lang.files.create.dialog.no_file}
                </Text>
              )}
            </Stack>
          </FileUploader>
          <Text ta="center" size="sm" c={"dimmed"}>
            (JPG / JPEG / PNG / GIF / PDF / POT / POTX / PPT / PPTX / XLS / XLSX
            / HTML / DOC / DOCX)
          </Text>

          <TextInput
            name="name"
            label={lang.files.create.dialog.name}
            placeholder={lang.files.create.dialog.name}
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
            mt={"sm"}
          />

          <FolderSelector value={folder} setValue={setFolder} type={"File"} />

          <Group justify="flex-end" mt={"sm"}>
            <BackButton onClick={handleClose} />
            <Button type="submit" loading={loading} color="tertiary.8">
              {lang.files.create.dialog.submit_button}
            </Button>
          </Group>
        </Stack>
      </Modal>
    </>
  );
};

export default CreateFileModal;
