import { useEffect, useMemo, useState } from "react";
import MultiSelector from "../../../../../components/Selectors/MultiSelector";
import {
  Anchor,
  Button,
  Group,
  HoverCard,
  Stack,
  Text,
  ThemeIcon,
  Title,
  Tooltip,
} from "@mantine/core";
import { useLanguage } from "../../../../../stores/LanguageStore";
import {
  IconArchive,
  IconBrandLinkedin,
  IconCircleFilled,
  IconInfoCircle,
  IconListDetails,
  IconMail,
  IconMessageCircle,
  IconMicrophone,
} from "@tabler/icons-react";
import { parseDate } from "../../../../../functions/dates";
import { getProgrammations } from "../../../../../api/WorkflowAPI";
import { useConfigStore } from "../../../../../stores/ConfigStore";
import { useThemeStore } from "../../../../../stores/ThemeStore";
import { IconLinkedin } from "../../../../../components/Icons/IconLinkedin";

function getOptionName(option) {
  return `${option.workflow_name}`;
}

const ListDisplay = ({ lists }) => {
  const lang = useLanguage((s) => s.language);

  return (
    <HoverCard openDelay={300} position="left" offset={2} withArrow>
      <HoverCard.Target>
        <ThemeIcon size={"xs"} variant="light" color="secondary" ml={"auto"}>
          <IconListDetails size={14} />
        </ThemeIcon>
      </HoverCard.Target>

      <HoverCard.Dropdown>
        <Stack>
          {lists.length > 0 ? (
            lists?.map((list) => (
              <Group key={list.contact_list_id} gap={"xs"}>
                <IconCircleFilled size={6} color="black" />
                <Anchor
                  size="sm"
                  target="_blank"
                  fw={600}
                  href={`/contact-lists/${list.contact_list_id}`}
                  c={"secondary"}
                >
                  {list.name} #{list.contact_list_id}
                </Anchor>
              </Group>
            ))
          ) : (
            <Text c={"dimmed"}>{lang.prm.nurturing.no_list}</Text>
          )}
        </Stack>
      </HoverCard.Dropdown>
    </HoverCard>
  );
};

const SentCampaignExclusion = ({ programData, setProgramData }) => {
  const lang = useLanguage((s) => s.language);
  const listStore = useConfigStore((s) => s.programmations);
  const [campaigns, setCampaigns] = useState(
    programData?.exclude_programmation_ids?.map((listId) => {
      let list = listStore.find((x) => x.id === listId);
      if (list) {
        return {
          ...list,
          option_name: getOptionName(list),
        };
      }
      return;
    }) || []
  );

  const [loading, setLoading] = useState(false);

  const options = useMemo(() => {
    return listStore
      .sort((a, b) => Date.parse(b.created_on) - Date.parse(a.created_on))
      .map((list) => ({
        ...list,
        option_name: getOptionName(list),
      }));
  }, [listStore]);

  const selectAll = (type) => {
    if (type) {
      return setProgramData({
        ...programData,
        exclude_programmation_ids: options
          .filter((x) => x[`has_${type}_step`])
          .map((x) => x.id),
      });
    }
    return setProgramData({
      ...programData,
      exclude_programmation_ids: options.map((x) => x.id),
    });
  };

  useEffect(() => {
    setLoading(true);
    getProgrammations({
      per_page: 100,
      sort: {
        field_name: "id",
        sort_direction: "desc",
      },
    }).finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setCampaigns(
      programData?.exclude_programmation_ids
        ?.map((listId) => {
          let list = listStore?.find((x) => x.id === listId);
          if (list) {
            return {
              ...list,
              option_name: getOptionName(list),
            };
          }
          return;
        })
        ?.filter(Boolean) || []
    );
  }, [listStore, programData]);

  return (
    <Stack h={"100%"} w={"45%"}>
      <Title order={5}>{lang.campaign.exclusions.sent_title}</Title>

      <Group gap={6} wrap="nowrap">
        <ThemeIcon
          variant="subtle"
          opacity={0.5}
          color="black"
          p={0}
          size={"md"}
        >
          <IconInfoCircle />
        </ThemeIcon>
        <Text size="sm">{lang.campaign.exclusions.sent_text}</Text>
      </Group>
      <MultiSelector
        options={options}
        loading={loading}
        values={campaigns}
        onChange={(v) =>
          setProgramData({
            ...programData,
            exclude_programmation_ids: v.map((x) => x.id),
          })
        }
        placeholder={lang.campaign.exclusions.search_campaign}
        rightOptionSection={(item) => {
          return (
            <>
              {item?.contact_lists && (
                <ListDisplay lists={item?.contact_lists || []} />
              )}
              {item?.archived && (
                <Tooltip
                  label={
                    lang.workflows.program.step_exclusion.columns.archived.true
                  }
                >
                  <IconArchive size={16} strokeWidth={1.2} color="red" />
                </Tooltip>
              )}
              <Tooltip label={"Date prog"}>
                <span style={{ fontSize: 10, whiteSpace: "nowrap" }}>
                  {parseDate(item?.date_start)}
                </span>
              </Tooltip>
              {item?.id && (
                <Anchor
                  href={`/statistics/campaigns?highlight=${item.id}`}
                  fz={10}
                  target="_blank"
                  truncate="end"
                  onClick={(e) => e.stopPropagation()}
                >
                  #{item.id}
                </Anchor>
              )}
            </>
          );
        }}
        leftOptionSection={(item) => {
          if (item[`has_email_step`]) {
            return (
              <ThemeIcon
              color={useThemeStore.getState().actionColors.email}
                size={"sm"}
                variant="subtle"
                p={0}
                mx={-4}
              >
                <IconMail size={16} />
              </ThemeIcon>
            );
          }
          if (item[`has_sms_step`]) {
            return (
              <ThemeIcon
                color={useThemeStore.getState().actionColors.sms}
                size={"sm"}
                variant="subtle"
                p={0}
                mx={-4}
              >
                <IconMessageCircle size={16} />
              </ThemeIcon>
            );
          }
          if (
            item[`has_linkedin_invitation_step`] ||
            item[`has_linkedin_message_step`] ||
            item[`has_linkedin_visit_step`]
          ) {
            return (
              <ThemeIcon
                color={useThemeStore.getState().actionColors.linkedin_message}
                size={"sm"}
                variant="subtle"
                p={0}
                mx={-4}
              >
                <IconLinkedin size={16} />
              </ThemeIcon>
            );
          }
        }}
        size={"sm"}
        w={"100%"}
        searchable
        checkIconPosition="left"
        footer={
          <>
            <Button
              variant="subtle"
              size="compact-sm"
              onClick={() => selectAll()}
            >
              {lang.global.select_all}
            </Button>

            <Button.Group ml={"auto"}>
              <Button
                leftSection={<IconMail size={16} />}
                size="xs"
                variant="outline"
                onClick={() => selectAll("email")}
                style={{
                  borderRight: 0,
                }}
                color={useThemeStore.getState().actionColors.email}
              >
                Emails
              </Button>
              <Button
                leftSection={<IconLinkedin size={16} />}
                size="xs"
                variant="outline"
                onClick={() => selectAll("linkedin")}
                style={{
                  borderRight: 0,
                }}
                color={useThemeStore.getState().actionColors.linkedin_message}
              >
                LinkedIn
              </Button>
              <Button
                leftSection={<IconMessageCircle size={16} />}
                size="xs"
                variant="outline"
                onClick={() => selectAll("sms")}
                color={useThemeStore.getState().actionColors.sms}
              >
                SMS
              </Button>
              {/* <Button
                leftSection={<IconMicrophone size={16} />}
                size="xs"
                variant="outline"
                onClick={() => selectAll("smv")}
                color={useThemeStore.getState().actionColors.smv}
              >
                VMS
              </Button> */}
            </Button.Group>
          </>
        }
      />
    </Stack>
  );
};

export default SentCampaignExclusion;
