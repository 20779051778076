import { create } from "zustand";
// import { persist } from "zustand/middleware"

import lang_fr from "../_locales/fr.json";
import lang_en from "../_locales/en.json";
import { persist } from "zustand/middleware";

const LANGUAGEStore = (set) => ({
  language: null,
  changeLanguage: (newLanguage) => set({ language: newLanguage || "ENG" }),
});

const languageStore = (set) => ({
  language: navigator.language !== "fr-FR" ? lang_en : lang_fr,
  changeLanguage: (newLanguage) =>
    set({ language: newLanguage === "FRA" ? lang_fr : lang_en }),
});

export const useLANGUAGE = create(persist(LANGUAGEStore, { name: "lang" }));
export const useLanguage = create(languageStore);
