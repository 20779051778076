import { useEffect, useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { Group, LoadingOverlay, Stack, Text } from "@mantine/core";
import BackButton from "../../../../../components/View/BackButton";
import { getZapierHooks } from "../../../../../api/ZapierApi";
import ZapierItem from "./ZapierItem";
import CreateZapier from "./CreateZapier";
import { useSessionStore } from "../../../../../stores/UserStore";

const ZapierContent = ({ handleClose, type }) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (loading) {
      getZapierHooks()
        .then((response) => {
          setItems(
            response.data.zapier_hooks_list
              .sort((a, b) => b.id - a.id)
              .filter((x) => x.source === type)
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [loading]);

  return (
    <>
      <Group gap={"xl"} h={"100%"} align="start" wrap="nowrap">
        <Stack pos={"relative"} gap={"xs"} h={"100%"} flex={1}>
          <LoadingOverlay visible={loading} />

          <Text c={"dimmed"}>
            {lang.sync_zapier[type === "prm" ? "main_text" : "main_text_stats"]}
          </Text>

          {items.length > 0 ? (
            <Stack gap={"lg"} h={"64vh"} style={{ overflow: "auto" }}>
              {items.map((item, index) => {
                return (
                  <ZapierItem
                    key={item.id}
                    item={item}
                    type={type}
                    handleReload={() => setLoading(true)}
                  />
                );
              })}
            </Stack>
          ) : (
            <Text c={"dimmed"} size="lg" ta={"center"} mt={64}>
              {lang.sync_zapier.no_elements}
            </Text>
          )}
        </Stack>
        {useSessionStore.getState().reseller?.name === "Magileads" && (
          <Stack ml={"auto"} mt={32}>
            <iframe
              width="480"
              height="400"
              src="https://www.youtube.com/embed/rHP3vhX160U?si=fz1yzSd_7s85KwZa"
              title="YouTube video player"
              allowfullscreen=""
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              style={{
                borderRadius: 12,
                overflow: "hidden",
                border: "1px solid #00000033",
              }}
            />
          </Stack>
        )}
      </Group>

      <Group justify="end" mt={"lg"}>
        <BackButton onClick={handleClose} />
        <CreateZapier handleDone={() => setLoading(true)} type={type} />
      </Group>
    </>
  );
};

export default ZapierContent;
