import { useState } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useDisclosure } from "@mantine/hooks";
import {
  ActionIcon,
  Button,
  Group,
  Modal,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import BackButton from "../../../../components/View/BackButton";
import { IconWorldSearch } from "@tabler/icons-react";
import { useProfileStore } from "../../../../stores/UserStore";
import { enrichDatafieldContent } from "../../../../api/ContactAPI";
import { notifications } from "@mantine/notifications";
import GlobalLanguageSelector from "../../../../components/Selectors/GlobalLanguageSelector";
import { useParams } from "react-router-dom";

const EnrichDatafield = ({ current, onFinish }) => {
  const { contactListId } = useParams();
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);
  const [opened, { open, close }] = useDisclosure();
  const [loading, setLoading] = useState(false);

  const handleOpen = (e) => {
    open();
    e.stopPropagation();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let fd = new FormData(e.target);
    let data = Object.fromEntries(fd);

    setLoading(true);
    enrichDatafieldContent({
      contact_list_id: parseInt(contactListId),
      datafield_id: current.id,
      language: data.language,
    })
      .then((res) => {
        notifications.show({
          message: lang.contact_lists.datafield_content.success_enrich,
          color: "tertiary.8",
        });
        close();
        onFinish?.();
      })
      .finally(() => setLoading(false));
  };

  const handleClose = () => {
    close();
  };

  if (
    !profile.permissions?.find((x) => x.name === "generateDatafieldAi")
      ?.value === true
  ) {
    return "";
  }

  return (
    <>
      <Tooltip label={lang.contact_lists.datafield_content.label_enrich}>
        <ActionIcon
          variant="subtle"
          color="tertiary.8"
          onClick={handleOpen}
          id={`header-${current.id}-button`}
        >
          <IconWorldSearch size={18} />
        </ActionIcon>
      </Tooltip>

      {opened && (
        <Modal
          opened={opened}
          size={"md"}
          onClose={handleClose}
          title={
            <>
              {lang.contact_lists.datafield_content.dialog.title_enrich}{" "}
              <b>
                {current?.name} #{current.id}
              </b>
            </>
          }
          styles={{
            title: {
              lineHeight: 1.5,
            },
          }}
        >
          <Stack
            component={"form"}
            onSubmit={handleSubmit}
            gap={"xs"}
            pos={"relative"}
          >
            <Text c={"dimmed"}>
              {lang.contact_lists.datafield_content.dialog.text_enrich}
            </Text>

            <GlobalLanguageSelector name={"language"} w="100%" size="sm" />

            <Group justify="end" mt={"md"}>
              <BackButton onClick={handleClose} />

              <Button
                leftSection={<IconWorldSearch size={18} />}
                loading={loading}
                color="primary"
                type="submit"
              >
                {
                  lang.contact_lists.datafield_content.dialog
                    .submit_button_enrich
                }
              </Button>
            </Group>
          </Stack>
        </Modal>
      )}
    </>
  );
};

export default EnrichDatafield;
