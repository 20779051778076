import {
  IconArrowDown,
  IconArrowUp,
  IconDotsVertical,
  IconEyeOff,
  IconMessageCheck,
  IconRefresh,
} from "@tabler/icons-react";
import { useLeadsContainerStore } from "../../../../../stores/LeadsStore";
import { ActionIcon, Group, Menu } from "@mantine/core";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { editStatus } from "../../../../../api/PRMAPI";

const HeaderMenu = ({ container }) => {
  const lang = useLanguage((s) => s.language);
  const updateContainer = useLeadsContainerStore((s) => s.updateContainer);
  const selectedIds = useLeadsContainerStore((s) => s.selectedIds);
  const setSelectedIds = useLeadsContainerStore((s) => s.setSelectedIds);

  const handleSort = (newSort) => {
    updateContainer(container.id, { loading: true, sort: newSort });
  };

  const applySorting = (nameField) => {
    if (container.sort?.field_name === nameField) {
      if (container.sort?.sort_direction === "asc") {
        return handleSort({
          field_name: nameField,
          sort_direction: "desc",
        });
      }
      if (container.sort?.sort_direction === "desc") {
        return handleSort(null);
      }
      return handleSort({
        field_name: nameField,
        sort_direction: "asc",
      });
    }
    return handleSort({
      field_name: nameField,
      sort_direction: "asc",
    });
  };

  const handleIcon = (field) => {
    if (field === "reload") {
      return <IconRefresh size={16} />;
    }
    return container.sort?.field_name === field ? (
      container.sort?.sort_direction === "asc" ? (
        <IconArrowUp size={16} />
      ) : (
        <IconArrowDown size={16} />
      )
    ) : (
      <IconArrowUp size={16} style={{ opacity: 0.25 }} />
    );
  };

  const handleHide = () => {
    editStatus({
      id: container.id,
      visible: false,
      isSystem: !!container?.status,
    });
    updateContainer(container.id, { visible: false });
  };

  const handleRefresh = () => {
    updateContainer(container.id, { loading: true });
    setSelectedIds({ ...selectedIds, [container.id]: [] });
  };

  return (
    <Menu>
      <Menu.Target>
        <ActionIcon
          variant="subtle"
          disabled={container?.loading}
          mih={0}
          h={26}
          color="tertiary.8"
        >
          <IconDotsVertical size={16} />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item onClick={handleRefresh}>
          <Group gap={"xs"}>
            {handleIcon("reload")}
            <span>{lang.prm.container.refresh}</span>
          </Group>
        </Menu.Item>
        <Menu.Item onClick={() => applySorting("status_changed_date")}>
          <Group gap={"xs"}>
            {handleIcon("status_changed_date")}
            <span>{lang.prm.container.status_changed_date}</span>
          </Group>
        </Menu.Item>
        {(container.id === "to_call" || typeof container.id === "number") && (
          <Menu.Item onClick={() => applySorting("last_call")}>
            <Group gap={"xs"}>
              {handleIcon("last_call")}
              <span>{lang.prm.container.last_call}</span>
            </Group>
          </Menu.Item>
        )}
        {typeof container.id === "number" && (
          <Menu.Item onClick={() => applySorting("last_reply")}>
            <Group gap={"xs"}>
              {handleIcon("last_reply")}
              <span>{lang.prm.container.last_reply}</span>
            </Group>
          </Menu.Item>
        )}
        <Menu.Item onClick={handleHide}>
          <Group gap={"xs"}>
            <IconEyeOff size={16} />
            <span>{lang.prm.container.hide}</span>
          </Group>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default HeaderMenu;
