import {
  gridFilteredTopLevelRowCountSelector,
  gridPageCountSelector,
  gridPageSizeSelector,
  useGridApiContext,
  useGridRootProps,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import MuiPagination from "@mui/material/Pagination";
import { Box, Group, NumberInput, Tooltip } from "@mantine/core";
import { IconArrowRight } from "@tabler/icons-react";
import { useLanguage } from "../../../stores/LanguageStore";

const getPageCount = (rowCount, pageSize) => {
  if (pageSize > 0 && rowCount > 0) {
    return Math.ceil(rowCount / pageSize);
  }

  return 0;
};

const TablePagination = ({ page, onPageChange, className }) => {
  const lang = useLanguage((s) => s.language);
  const apiRef = useGridApiContext();
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
  const { rowCount, paginationMode, loading, disableGotoPage } =
    useGridRootProps();
  const clientPageCount = useGridSelector(apiRef, gridPageCountSelector);
  const visibleTopLevelRowCount = useGridSelector(
    apiRef,
    gridFilteredTopLevelRowCountSelector
  );
  const serverPageCount = getPageCount(
    rowCount ?? visibleTopLevelRowCount,
    pageSize
  );

  return (
    <Group>
      <MuiPagination
        color="primary"
        className={className}
        count={paginationMode === "client" ? clientPageCount : serverPageCount}
        sx={{
          position: "absolute",
          left: "50%",
          transform: "translate(-50%)",
          ml: "0 !important",
        }}
        disabled={loading}
        page={page + 1}
        onChange={(event, newPage) => {
          onPageChange(event, newPage - 1);
        }}
      />

      {!disableGotoPage && (
        <Tooltip label={lang.table.pagination.goto_page}>
          <Box
            component="form"
            className="goto-page"
            onSubmit={(e) => {
              e.preventDefault();
              const fd = new FormData(e.target);
              const data = Object.fromEntries(fd);

              onPageChange(e, parseInt(data.page) - 1);
            }}
          >
            <NumberInput
              key={page}
              size="sm"
              min={1}
              name="page"
              max={2}
              rightSection={<IconArrowRight size={12} />}
              placeholder={lang.table.pagination.goto_page_placeholder}
              w={75}
            />
          </Box>
        </Tooltip>
      )}
    </Group>
  );
};

export default TablePagination;
