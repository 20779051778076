import { useCallback, useEffect, useMemo, useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { useForm } from "react-hook-form";
import { createSMTP } from "../../../../../api/EmailAPI";
import { notifications } from "@mantine/notifications";
import useNavigationBlocker from "../../../../../components/View/NavigationBlocker";
import {
  Accordion,
  Anchor,
  Button,
  Checkbox,
  Divider,
  Group,
  LoadingOverlay,
  PasswordInput,
  Stack,
  Switch,
  TagsInput,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import BackButton from "../../../../../components/View/BackButton";
import {
  IconDeviceFloppy,
  IconPlaylistAdd,
  IconPlus,
  IconSend,
  IconSignature,
} from "@tabler/icons-react";
import { SharingInAccordion } from "../../../../../components/Sharing/SharingController";
import { Link, useNavigate, useRouteLoaderData } from "react-router-dom";
import Autodiscover from "./Autodiscover";
import EmailSending from "./EmailSending";
import CreateEmailSender from "../CreateEmailSender";
import { getEmailTypeProps } from "../../sendersUtil";
import SignatureSelector from "../../../../../components/Selectors/SignatureSelector";
import { getSignatures } from "../../../../../api/ConfigsApi";
import PoolsSelector from "../../../../../components/Selectors/PoolsSelector";
import PageTitle from "../../../../../components/View/PageTitle";
import { IconRefreshDot } from "@tabler/icons-react";
import CustomDomain from "./CustomDomain";
import { useSessionStore } from "../../../../../stores/UserStore";

const EmailSmtp = ({ emailProfile }) => {
  const lang = useLanguage((s) => s.language);
  const navigate = useNavigate();
  const actualPool = useRouteLoaderData("email-pool");
  const [dirty, setDirty] = useState(false);
  const [replyTos, setReplyTos] = useState(emailProfile?.reply_to || []);
  const [pools, setPools] = useState(
    emailProfile?.pools ? emailProfile.pools : actualPool ? [actualPool] : []
  );
  const [loading, setLoading] = useState(false);
  const sharingForm = useForm({
    defaultValues: {
      sharing:
        emailProfile?.sharing?.map((x) => ({
          ...x,
          sharing_targets: x.sharing_targets.map((sharing_target) => ({
            ...sharing_target,
            option_name: sharing_target.name,
          })),
        })) || [],
    },
  });
  const [sign, setSign] = useState(emailProfile?.email_signatures_id || null);

  const [imapRequired, setImapRequired] = useState(
    emailProfile?.imap_host === "" ? false : true
  );
  const [smtpError, setSmtpError] = useState(false);
  const [imapError, setImapError] = useState(false);

  const handleErrors = ({ state, details }) => {
    setLoading(false);
    if (
      details ===
        "SMTP Error: Could not connect to SMTP host. Failed to connect to server" ||
      details?.includes("such host")
    ) {
      setSmtpError("server");
      return document.getElementById("smtp_host-input").focus();
    }
    if (details.includes("authenticate") && state.includes("smtp")) {
      setSmtpError("auth");
      return document.getElementById("smtp_username-input").focus();
    }
    if (state.includes("smtp") || details.includes("SMTP")) {
      setSmtpError(true);
      document.getElementById("smtp_username-input").focus();
    }
    if (
      details ===
        "IMAP Error: Could not connect to IMAP host. Failed to connect to server" ||
      details?.includes("such host")
    ) {
      setImapError("server");
      return document.getElementById("imap_host-input").focus();
    }
    if (details.includes("authenticate") && state.includes("imap")) {
      setImapError("auth");
      return document.getElementById("imap_username-input").focus();
    }
    if (state.includes("imap") || details.includes("IMAP")) {
      setImapError(true);
      document.getElementById("imap_username-input").focus();
    }
  };

  const handleDiscovery = (settings) => {
    Object.keys(settings).forEach((key) => {
      document.querySelector(`[name="${key}"]`).value = settings[key];
    });
    document.querySelector(`[name="smtp_secure"]`).checked =
      settings.smtp_secure === "ssl";
    document.querySelector(`[name="imap_secure"]`).checked =
      settings.imap_secure === "ssl";

    document.querySelector(`[name="smtp_password"]`).focus();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let fd = new FormData(e.target);
    let data = Object.fromEntries(fd);

    setSmtpError(false);
    setImapError(false);

    const sharing = sharingForm
      .getValues()
      .sharing.filter((x) => x.sharing_targets.length > 0)
      .map((share) => ({
        ...share,
        ids_list: share.sharing_targets.map((x) => x.id),
        sharing_targets: undefined,
      }));
    data.name = data?.sender;
    data.smtp_port = parseInt(data?.smtp_port);
    data.smtp_secure = data?.smtp_secure ? "ssl" : "tls";
    data.imap_host = imapRequired ? data?.imap_host : undefined;
    data.imap_password = imapRequired ? data?.imap_password || null : undefined;
    data.imap_port = imapRequired ? parseInt(data?.imap_port) : undefined;
    data.imap_username = imapRequired ? data?.imap_username : undefined;
    data.imap_secure = data?.imap_secure
      ? "ssl"
      : !imapRequired
      ? undefined
      : "tls";
    data.connection_test = !!data?.smtp_password;
    data.smtp_password = data?.smtp_password || null;
    data.email_signatures_id = sign;
    data.reply_to = replyTos || [];
    data.pools_ids = pools?.map((x) => x.id) || [];

    let final_data = {
      ...data,
      enable_unsubscribe_link: data.enable_unsubscribe_link ? true : false,
      enable_tracking: data.enable_tracking ? true : false,
      sending_frequency_start: parseInt(data.sending_frequency_start),
      sending_frequency_end: parseInt(data.sending_frequency_end),
      warm_up: {
        day_1: data.day1 ? parseInt(data.day1) : null,
        day_2: data.day2 ? parseInt(data.day2) : null,
        day_3: data.day3 ? parseInt(data.day3) : null,
        day_4: data.day4 ? parseInt(data.day4) : null,
        day_5: data.day5 ? parseInt(data.day5) : null,
        day_6: data.day6 ? parseInt(data.day6) : null,
        day_7: data.day7 ? parseInt(data.day7) : null,
        day_8: data.day8 ? parseInt(data.day8) : null,
        day_9: data.day9 ? parseInt(data.day9) : null,
        day_10: data.day10 ? parseInt(data.day10) : null,
        day_11: data.day11 ? parseInt(data.day11) : null,
        day_12: data.day12 ? parseInt(data.day12) : null,
        day_13: data.day13 ? parseInt(data.day13) : null,
        day_14: data.day14 ? parseInt(data.day14) : null,
        day_15: data.day15 ? parseInt(data.day15) : null,
      },
    };

    setLoading(true);
    const main = emailProfile
      ? () =>
          getEmailTypeProps(emailProfile?.account_type).editFunction({
            id: emailProfile.id,
            data: final_data,
            sharing,
          })
      : () => createSMTP(final_data, sharing);
    main()
      .then((response) => {
        setDirty(false);
        notifications.show({
          message:
            lang.emails_account.row_highlight[
              emailProfile ? "updated" : "created"
            ],
          color: "tertiary.8",
        });
        setTimeout(() => {
          navigate(
            "../?highlight=" +
              (emailProfile?.id || response.data.email_account_id)
          );
        }, 200);
      })
      .catch((err) =>
        handleErrors({
          details: err.response.data?.error_details,
          state: err.response.data?.state_message,
        })
      )
      .finally(() => {
        setLoading(false);
      });
  };
  const handleEmailBlur = (e) => {
    let v = e.target.value;

    if (v && replyTos.length <= 1 && !emailProfile) {
      setReplyTos([v]);
    }
  };

  const parameterValues = useMemo(() => {
    let params = new URLSearchParams(location.search);

    return {
      smtp_host: params.get("smtp_host"),
      smtp_port: params.get("smtp_port"),
      smtp_secure: params.get("smtp_secure"),
      imap_host: params.get("imap_host"),
      imap_password: params.get("imap_password"),
      imap_port: params.get("imap_port"),
      imap_username: params.get("imap_username"),
      imap_secure: params.get("imap_secure"),
    };
  }, []);

  const getSmtpError = useCallback(() => {
    if (smtpError === "server") {
      return lang.senders.emails.smtp_server_error;
    }
    if (smtpError === "auth") {
      return lang.senders.emails.smtp_auth_error;
    }
    return lang.senders.emails.smtp_error;
  }, [lang, smtpError]);

  const getImapError = useCallback(() => {
    if (imapError === "server") {
      return lang.senders.emails.imap_server_error;
    }
    if (imapError === "auth") {
      return lang.senders.emails.imap_auth_error;
    }
    return lang.senders.emails.imap_error;
  }, [lang, imapError]);

  useEffect(() => {
    if (emailProfile) {
      getSignatures();
    }
  }, [emailProfile]);

  useNavigationBlocker(dirty);

  return (
    <>
      {!emailProfile && (
        <Group className="layout-block top" justify="space-between">
          <PageTitle title={lang.emails_account.browser_tab_title} />

          <CreateEmailSender />
        </Group>
      )}

      <Stack
        className="layout-block"
        w={"60%"}
        mx={"auto"}
        gap={"xs"}
        pr={"lg"}
        id="edit-form"
        component={"form"}
        onSubmit={handleSubmit}
        onChange={() => setDirty(true)}
      >
        {emailProfile ? (
          <Title order={5}>
            {lang.emails_account.smtp.edit.dialog.title} : {emailProfile?.name}{" "}
            #{emailProfile?.id}
          </Title>
        ) : (
          <Group justify="space-between">
            <Title order={5}>{lang.emails_account.smtp.dialog.title}</Title>

            <Button
              variant="subtle"
              component={Link}
              to={"/senders/email/import/smtp"}
              leftSection={<IconPlaylistAdd size={18} />}
            >
              Importer plusieurs SMTP
            </Button>
          </Group>
        )}

        <LoadingOverlay visible={loading} />

        <Group wrap="nowrap">
          <TextInput
            defaultValue={emailProfile?.sender}
            placeholder={"John Doe"}
            name="sender"
            label={lang.emails_account.smtp.step_base.sender}
            type="text"
            w={"100%"}
            required
            autoFocus
          />
          <TextInput
            id="smtp-email"
            defaultValue={emailProfile?.email}
            placeholder={"email@example.com"}
            onBlur={handleEmailBlur}
            name="email"
            label={lang.emails_account.smtp.step_base.email}
            type="email"
            w={"100%"}
            required
            rightSectionWidth={110}
            rightSection={
              (emailProfile?.account_type === "SMTP" || !emailProfile) &&
              !["pro1.mail.ovh.net", "ssl0.ovh.net"].includes(
                parameterValues.smtp_host
              ) && (
                <Autodiscover
                  inputId={"smtp-email"}
                  onFinish={handleDiscovery}
                />
              )
            }
          />
        </Group>

        <Divider my={12} mx={"30%"} />

        {((emailProfile && emailProfile?.account_type === "SMTP") ||
          !emailProfile) && (
          <>
            <Text size="md">
              {lang.emails_account.smtp.step_base.smtp_title}
            </Text>
            <Text size="sm" mt={-4} c="dimmed">
              {lang.senders.emails.smtp_text}
            </Text>
            {parameterValues.smtp_host === "pro1.mail.ovh.net" && (
              <Text size="sm" c={"secondary.7"} fw={600}>
                {lang.senders.emails.smtp_text_ovh_pro}
              </Text>
            )}
            {smtpError && (
              <Text c={"red"} size="sm" mt={-6} fw={500}>
                {getSmtpError()}
              </Text>
            )}
            <Group wrap="nowrap">
              <TextInput
                id="smtp_username-input"
                defaultValue={emailProfile?.smtp_username}
                placeholder={lang.emails_account.smtp.step_base.smtp_username}
                name="smtp_username"
                label={lang.emails_account.smtp.step_base.smtp_username}
                required
                type="text"
                w={"100%"}
              />
              <TextInput
                id="smtp_host-input"
                defaultValue={
                  emailProfile?.smtp_host || parameterValues?.smtp_host
                }
                placeholder={lang.emails_account.smtp.step_base.smtp_host}
                name="smtp_host"
                label={lang.emails_account.smtp.step_base.smtp_host}
                type="text"
                w={"100%"}
                required
              />
            </Group>
            <Group wrap="nowrap">
              <TextInput
                type="number"
                defaultValue={
                  emailProfile?.smtp_port || parameterValues?.smtp_port
                }
                placeholder={"465"}
                name="smtp_port"
                label={lang.emails_account.smtp.step_base.smtp_port}
                required
                w={"100%"}
              />
              <PasswordInput
                defaultValue={emailProfile?.smtp_password}
                placeholder={
                  emailProfile
                    ? "*****"
                    : lang.emails_account.smtp.step_base.smtp_password
                }
                name="smtp_password"
                label={lang.emails_account.smtp.step_base.smtp_password}
                w={"100%"}
                required={!emailProfile}
                autoComplete="new-password"
              />
            </Group>
            <Checkbox
              label={lang.emails_account.smtp.step_base.smtp_ssl}
              defaultChecked={
                emailProfile?.smtp_secure === "ssl" ||
                parameterValues?.smtp_secure === "ssl"
              }
              name="smtp_secure"
              mt={4}
            />

            <Divider my={12} />

            <Group justify="space-between">
              <Text size="md">
                {lang.emails_account.smtp.step_base.imap_title}
              </Text>
              <Switch
                label={lang.senders.emails.imap_ignore}
                checked={!imapRequired}
                onChange={(e) => setImapRequired(!e.target.checked)}
              />
            </Group>
            <Text size="sm" mt={-4} c="dimmed">
              {lang.senders.emails.imap_text}
            </Text>
            {imapError && (
              <Text c={"red"} size="sm" mt={-6} fw={500}>
                {getImapError()}
              </Text>
            )}
            <Group wrap="nowrap">
              <TextInput
                id="imap_username-input"
                defaultValue={emailProfile?.imap_username}
                placeholder={lang.emails_account.smtp.step_base.imap_username}
                name="imap_username"
                label={lang.emails_account.smtp.step_base.imap_username}
                required={imapRequired}
                type="text"
                w={"100%"}
              />
              <TextInput
                id="imap_host-input"
                defaultValue={
                  emailProfile?.imap_host || parameterValues?.imap_host
                }
                placeholder={lang.emails_account.smtp.step_base.imap_host}
                name="imap_host"
                label={lang.emails_account.smtp.step_base.imap_host}
                w={"100%"}
                required={imapRequired}
              />
            </Group>
            <Group wrap="nowrap">
              <TextInput
                type="number"
                defaultValue={
                  emailProfile?.imap_port || parameterValues?.imap_port
                }
                placeholder={"993"}
                name="imap_port"
                label={lang.emails_account.smtp.step_base.imap_port}
                required={imapRequired}
                w={"100%"}
              />
              <PasswordInput
                defaultValue={emailProfile?.imap_password}
                placeholder={
                  emailProfile
                    ? "*****"
                    : lang.emails_account.smtp.step_base.imap_password
                }
                name="imap_password"
                label={lang.emails_account.smtp.step_base.imap_password}
                w={"100%"}
                required={!emailProfile ? imapRequired : false}
                autoComplete="new-password"
              />
            </Group>
            <Checkbox
              label={lang.emails_account.smtp.step_base.imap_ssl}
              defaultChecked={
                emailProfile?.imap_secure === "ssl" ||
                parameterValues?.imap_secure === "ssl"
              }
              name="imap_secure"
              mt={4}
              mb={"md"}
            />
            <Divider />
          </>
        )}

        <PoolsSelector type={"Email"} value={pools} setValue={setPools} />

        <TagsInput
          name="reply_to"
          label={lang.emails_account.mass_mailing.reply_to.title}
          placeholder={lang.global.enter_key_to_add}
          description={lang.global.enter_key_to_add}
          value={replyTos}
          onChange={setReplyTos}
          mb={"sm"}
        />

        <Accordion variant="contained" mb={"sm"}>
          <Accordion.Item value={"send"}>
            <Accordion.Control
              icon={<IconSignature size={16} />}
              px={"xs"}
              bg={"var(--mantine-color-gray-0)"}
            >
              {lang.emails_account.smtp.step_pools_signs.signature}
            </Accordion.Control>
            <Accordion.Panel>
              <SignatureSelector
                value={sign}
                onChange={(v) => setSign(v?.id || null)}
              />
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>

        {useSessionStore.getState().reseller?.name === "Magileads" && (
          <CustomDomain emailProfile={emailProfile} />
        )}

        <Accordion variant="contained" mb={"sm"} defaultValue={"send"}>
          <Accordion.Item value={"send"}>
            <Accordion.Control
              icon={<IconSend size={16} />}
              px={"xs"}
              bg={"var(--mantine-color-gray-0)"}
            >
              {lang.emails_account.smtp.step_suppl.title}
            </Accordion.Control>
            <Accordion.Panel>
              <EmailSending configs={emailProfile} />
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>

        <SharingInAccordion form={sharingForm} />

        <Group justify="right" mt={"sm"}>
          <BackButton />

          {emailProfile?.auth_url && (
            <Anchor target="_self" href={emailProfile.auth_url}>
              <Button
                variant="light"
                leftSection={<IconRefreshDot size={18} />}
              >
                {lang.emails_account.reconnect}
              </Button>
            </Anchor>
          )}

          <Button
            type="submit"
            color="primary"
            leftSection={
              emailProfile ? (
                <IconDeviceFloppy size={18} />
              ) : (
                <IconPlus size={18} />
              )
            }
            loading={loading}
          >
            {emailProfile
              ? lang.model_emails.edit.dialog.submit_button
              : lang.emails_account.create.dialog.submit_button}
          </Button>
        </Group>
      </Stack>
    </>
  );
};

export default EmailSmtp;
