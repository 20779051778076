export const workflow1 = {
  id: 2309,
  name: "Email + Linkedin",
  steps: [
    {
      step_id: {
        type: "existing",
        id: 9278,
      },
      step_type: "action",
      position_x: 200,
      position_y: 168,
      parent_ids: [],
      action_type: "email",
      model_id: null,
      model_name: null,
      integration_id: null,
      integration_name: null,
      pool_id: null,
      pool_name: null,
      limit_send_per_day: 0,
      sending_weekends_allowed: false,
      allow_massmailing: false,
      time_start_sending: "07:00:00",
      time_stop_sending: "18:00:00",
      blacklist_ids: [],
      email_linkedin_exclude: [],
      is_initial: true,
      show_reply_as_conversation: false,
      use_smtp_fallback_server: false,
      signature_id: null,
      signature_name: null,
      number_contacts: 0,
      is_attached_to_initial: true,
    },
    {
      step_id: {
        type: "existing",
        id: 9279,
      },
      step_type: "event",
      position_x: 40,
      position_y: 432,
      parent_ids: [
        {
          type: "existing",
          id: 9278,
        },
      ],
      event_type: "email_open",
      which_links_clicked: [],
      number_contacts: 0,
      when_minutes: 10080,
      name: null,
      filter: null,
      is_attached_to_initial: true,
    },
    {
      step_id: {
        type: "existing",
        id: 9280,
      },
      step_type: "action",
      position_x: 40,
      position_y: 686,
      parent_ids: [
        {
          type: "existing",
          id: 9279,
        },
      ],
      action_type: "email",
      model_id: null,
      model_name: null,
      integration_id: null,
      integration_name: null,
      pool_id: null,
      pool_name: null,
      limit_send_per_day: 0,
      sending_weekends_allowed: false,
      allow_massmailing: false,
      time_start_sending: "07:00:00",
      time_stop_sending: "18:00:00",
      blacklist_ids: [],
      email_linkedin_exclude: [],
      is_initial: false,
      show_reply_as_conversation: false,
      use_smtp_fallback_server: false,
      signature_id: null,
      signature_name: null,
      number_contacts: 0,
      is_attached_to_initial: true,
    },
    {
      step_id: {
        type: "existing",
        id: 9281,
      },
      step_type: "event",
      position_x: 360,
      position_y: 432,
      parent_ids: [
        {
          type: "existing",
          id: 9278,
        },
      ],
      event_type: "not_active",
      which_links_clicked: [],
      number_contacts: 0,
      when_minutes: 2880,
      name: null,
      filter: null,
      is_attached_to_initial: true,
    },
    {
      step_id: {
        type: "existing",
        id: 9282,
      },
      step_type: "action",
      position_x: 360,
      position_y: 706,
      parent_ids: [
        {
          type: "existing",
          id: 9281,
        },
      ],
      action_type: "linkedin_invitation",
      model_id: null,
      model_name: null,
      integration_id: null,
      integration_name: null,
      pool_id: null,
      pool_name: null,
      limit_send_per_day: 0,
      sending_weekends_allowed: false,
      allow_massmailing: false,
      time_start_sending: "07:00:00",
      time_stop_sending: "18:00:00",
      blacklist_ids: [],
      email_linkedin_exclude: [],
      is_initial: false,
      show_reply_as_conversation: false,
      use_smtp_fallback_server: false,
      signature_id: null,
      signature_name: null,
      number_contacts: 0,
      is_attached_to_initial: true,
    },
    {
      step_id: {
        type: "existing",
        id: 9283,
      },
      step_type: "event",
      position_x: 360,
      position_y: 950,
      parent_ids: [
        {
          type: "existing",
          id: 9282,
        },
      ],
      event_type: "linkedin_invitation_accepted",
      which_links_clicked: [],
      number_contacts: 0,
      when_minutes: 2880,
      name: null,
      filter: null,
      is_attached_to_initial: true,
    },
    {
      step_id: {
        type: "existing",
        id: 9284,
      },
      step_type: "action",
      position_x: 360,
      position_y: 1204,
      parent_ids: [
        {
          type: "existing",
          id: 9283,
        },
      ],
      action_type: "linkedin_message",
      model_id: null,
      model_name: null,
      integration_id: null,
      integration_name: null,
      pool_id: null,
      pool_name: null,
      limit_send_per_day: 0,
      sending_weekends_allowed: false,
      allow_massmailing: false,
      time_start_sending: "07:00:00",
      time_stop_sending: "18:00:00",
      blacklist_ids: [],
      email_linkedin_exclude: [],
      is_initial: false,
      show_reply_as_conversation: false,
      use_smtp_fallback_server: false,
      signature_id: null,
      signature_name: null,
      number_contacts: 0,
      is_attached_to_initial: true,
    },
  ],
  from_sharing: true,
  sharing: [
    {
      sharing_type: "Workflow",
      sharing_type_to: "Team",
      read_permission: true,
      write_permission: false,
      delete_permission: false,
      ids_list: [7],
      created_on: "2024-04-17T07:20:59+00:00",
      name: "",
      sharing_targets: [
        {
          id: 7,
          name: "Magileads",
        },
      ],
    },
  ],
  created_by: 803,
  tags: [],
  permission: {
    read_permission: true,
    write_permission: true,
    delete_permission: true,
    is_admin: true,
  },
  created_on: "2024-04-15T17:41:46+00:00",
  updated_on: "2024-04-17T07:53:30+00:00",
  folder: null,
  archived: false,
  programmed: false,
  auto_remove_responders: true,
};

export const workflow2 = {
  id: 2310,
  name: "Email avec relance",
  steps: [
    {
      step_id: {
        type: "existing",
        id: 9285,
      },
      step_type: "action",
      position_x: 40,
      position_y: 168,
      parent_ids: [],
      action_type: "email",
      model_id: null,
      model_name: null,
      integration_id: null,
      integration_name: null,
      pool_id: null,
      pool_name: null,
      limit_send_per_day: 0,
      sending_weekends_allowed: false,
      allow_massmailing: false,
      time_start_sending: "07:00:00",
      time_stop_sending: "18:00:00",
      blacklist_ids: [],
      email_linkedin_exclude: [],
      is_initial: true,
      show_reply_as_conversation: false,
      use_smtp_fallback_server: false,
      signature_id: null,
      signature_name: null,
      number_contacts: 0,
      is_attached_to_initial: true,
    },
    {
      step_id: {
        type: "existing",
        id: 9286,
      },
      step_type: "event",
      position_x: 40,
      position_y: 432,
      parent_ids: [
        {
          type: "existing",
          id: 9285,
        },
      ],
      event_type: "all_contacts",
      which_links_clicked: [],
      number_contacts: 0,
      when_minutes: 10080,
      name: null,
      filter: null,
      is_attached_to_initial: true,
    },
    {
      step_id: {
        type: "existing",
        id: 9287,
      },
      step_type: "action",
      position_x: 40,
      position_y: 686,
      parent_ids: [
        {
          type: "existing",
          id: 9286,
        },
      ],
      action_type: "email",
      model_id: null,
      model_name: null,
      integration_id: null,
      integration_name: null,
      pool_id: null,
      pool_name: null,
      limit_send_per_day: 0,
      sending_weekends_allowed: false,
      allow_massmailing: false,
      time_start_sending: "07:00:00",
      time_stop_sending: "18:00:00",
      blacklist_ids: [],
      email_linkedin_exclude: [],
      is_initial: false,
      show_reply_as_conversation: false,
      use_smtp_fallback_server: false,
      signature_id: null,
      signature_name: null,
      number_contacts: 0,
      is_attached_to_initial: true,
    },
  ],
  from_sharing: true,
  sharing: [
    {
      sharing_type: "Workflow",
      sharing_type_to: "Team",
      read_permission: true,
      write_permission: false,
      delete_permission: false,
      ids_list: [7],
      created_on: "2024-04-17T07:21:07+00:00",
      name: "",
      sharing_targets: [
        {
          id: 7,
          name: "Magileads",
        },
      ],
    },
  ],
  created_by: 803,
  tags: [],
  permission: {
    read_permission: true,
    write_permission: true,
    delete_permission: true,
    is_admin: true,
  },
  created_on: "2024-04-15T17:44:11+00:00",
  updated_on: "2024-04-17T07:53:26+00:00",
  folder: null,
  archived: false,
  programmed: false,
  auto_remove_responders: true,
};

export const workflow3 = {
  id: 2311,
  name: "Email + Téléphone",
  steps: [
    {
      step_id: {
        type: "existing",
        id: 9288,
      },
      step_type: "action",
      position_x: 40,
      position_y: 168,
      parent_ids: [],
      action_type: "email",
      model_id: null,
      model_name: null,
      integration_id: null,
      integration_name: null,
      pool_id: null,
      pool_name: null,
      limit_send_per_day: 0,
      sending_weekends_allowed: false,
      allow_massmailing: false,
      time_start_sending: "07:00:00",
      time_stop_sending: "18:00:00",
      blacklist_ids: [],
      email_linkedin_exclude: [],
      is_initial: true,
      show_reply_as_conversation: false,
      use_smtp_fallback_server: false,
      signature_id: null,
      signature_name: null,
      number_contacts: 0,
      is_attached_to_initial: true,
    },
    {
      step_id: {
        type: "existing",
        id: 9289,
      },
      step_type: "event",
      position_x: 40,
      position_y: 432,
      parent_ids: [
        {
          type: "existing",
          id: 9288,
        },
      ],
      event_type: "all_contacts",
      which_links_clicked: [],
      number_contacts: 0,
      when_minutes: 10080,
      name: null,
      filter: null,
      is_attached_to_initial: true,
    },
    {
      step_id: {
        type: "existing",
        id: 9290,
      },
      step_type: "action",
      position_x: 40,
      position_y: 686,
      parent_ids: [
        {
          type: "existing",
          id: 9289,
        },
      ],
      action_type: "email",
      model_id: null,
      model_name: null,
      integration_id: null,
      integration_name: null,
      pool_id: null,
      pool_name: null,
      limit_send_per_day: 0,
      sending_weekends_allowed: false,
      allow_massmailing: false,
      time_start_sending: "07:00:00",
      time_stop_sending: "18:00:00",
      blacklist_ids: [],
      email_linkedin_exclude: [],
      is_initial: false,
      show_reply_as_conversation: false,
      use_smtp_fallback_server: false,
      signature_id: null,
      signature_name: null,
      number_contacts: 0,
      is_attached_to_initial: true,
    },
    {
      step_id: {
        type: "existing",
        id: 9291,
      },
      step_type: "event",
      position_x: 40,
      position_y: 950,
      parent_ids: [
        {
          type: "existing",
          id: 9290,
        },
      ],
      event_type: "email_open",
      which_links_clicked: [],
      number_contacts: 0,
      when_minutes: 1440,
      name: null,
      filter: null,
      is_attached_to_initial: true,
    },
    {
      step_id: {
        type: "existing",
        id: 9292,
      },
      step_type: "action",
      position_x: 40,
      position_y: 1204,
      parent_ids: [
        {
          type: "existing",
          id: 9291,
        },
      ],
      action_type: "call",
      model_id: null,
      model_name: null,
      integration_id: null,
      integration_name: null,
      pool_id: null,
      pool_name: null,
      limit_send_per_day: 0,
      sending_weekends_allowed: false,
      allow_massmailing: false,
      time_start_sending: "07:00:00",
      time_stop_sending: "18:00:00",
      blacklist_ids: [],
      email_linkedin_exclude: [],
      is_initial: false,
      show_reply_as_conversation: false,
      use_smtp_fallback_server: false,
      signature_id: null,
      signature_name: null,
      number_contacts: 0,
      is_attached_to_initial: true,
    },
  ],
  from_sharing: true,
  sharing: [
    {
      sharing_type: "Workflow",
      sharing_type_to: "Team",
      read_permission: true,
      write_permission: false,
      delete_permission: false,
      ids_list: [7],
      created_on: "2024-04-17T07:21:13+00:00",
      name: "",
      sharing_targets: [
        {
          id: 7,
          name: "Magileads",
        },
      ],
    },
  ],
  created_by: 803,
  tags: [],
  permission: {
    read_permission: true,
    write_permission: true,
    delete_permission: true,
    is_admin: true,
  },
  created_on: "2024-04-15T17:44:52+00:00",
  updated_on: "2024-04-17T07:21:13+00:00",
  folder: null,
  archived: false,
  programmed: false,
  auto_remove_responders: true,
};

export const workflow4 = {
  id: 2312,
  name: "Linkedin + Téléphone + Email",
  steps: [
    {
      step_id: {
        type: "existing",
        id: 9293,
      },
      step_type: "action",
      position_x: 200,
      position_y: 168,
      parent_ids: [],
      action_type: "linkedin_invitation",
      model_id: null,
      model_name: null,
      integration_id: null,
      integration_name: null,
      pool_id: null,
      pool_name: null,
      limit_send_per_day: 0,
      sending_weekends_allowed: false,
      allow_massmailing: false,
      time_start_sending: "07:00:00",
      time_stop_sending: "18:00:00",
      blacklist_ids: [],
      email_linkedin_exclude: [],
      is_initial: true,
      show_reply_as_conversation: false,
      use_smtp_fallback_server: false,
      signature_id: null,
      signature_name: null,
      number_contacts: 0,
      is_attached_to_initial: true,
    },
    {
      step_id: {
        type: "existing",
        id: 9294,
      },
      step_type: "event",
      position_x: 200,
      position_y: 392,
      parent_ids: [
        {
          type: "existing",
          id: 9293,
        },
      ],
      event_type: "linkedin_invitation_accepted",
      which_links_clicked: [],
      number_contacts: 0,
      when_minutes: 2880,
      name: null,
      filter: null,
      is_attached_to_initial: true,
    },
    {
      step_id: {
        type: "existing",
        id: 9295,
      },
      step_type: "action",
      position_x: 200,
      position_y: 646,
      parent_ids: [
        {
          type: "existing",
          id: 9294,
        },
      ],
      action_type: "linkedin_message",
      model_id: null,
      model_name: null,
      integration_id: null,
      integration_name: null,
      pool_id: null,
      pool_name: null,
      limit_send_per_day: 0,
      sending_weekends_allowed: false,
      allow_massmailing: false,
      time_start_sending: "07:00:00",
      time_stop_sending: "18:00:00",
      blacklist_ids: [],
      email_linkedin_exclude: [],
      is_initial: false,
      show_reply_as_conversation: false,
      use_smtp_fallback_server: false,
      signature_id: null,
      signature_name: null,
      number_contacts: 0,
      is_attached_to_initial: true,
    },
    {
      step_id: {
        type: "existing",
        id: 9296,
      },
      step_type: "event",
      position_x: 40,
      position_y: 870,
      parent_ids: [
        {
          type: "existing",
          id: 9295,
        },
      ],
      event_type: "all_contacts",
      which_links_clicked: [],
      number_contacts: 0,
      when_minutes: 1440,
      name: null,
      filter: null,
      is_attached_to_initial: true,
    },
    {
      step_id: {
        type: "existing",
        id: 9297,
      },
      step_type: "action",
      position_x: 40,
      position_y: 1144,
      parent_ids: [
        {
          type: "existing",
          id: 9296,
        },
      ],
      action_type: "call",
      model_id: null,
      model_name: null,
      integration_id: null,
      integration_name: null,
      pool_id: null,
      pool_name: null,
      limit_send_per_day: 0,
      sending_weekends_allowed: false,
      allow_massmailing: false,
      time_start_sending: "07:00:00",
      time_stop_sending: "18:00:00",
      blacklist_ids: [],
      email_linkedin_exclude: [],
      is_initial: false,
      show_reply_as_conversation: false,
      use_smtp_fallback_server: false,
      signature_id: null,
      signature_name: null,
      number_contacts: 0,
      is_attached_to_initial: true,
    },
    {
      step_id: {
        type: "existing",
        id: 9298,
      },
      step_type: "event",
      position_x: 360,
      position_y: 870,
      parent_ids: [
        {
          type: "existing",
          id: 9295,
        },
      ],
      event_type: "not_active",
      which_links_clicked: [],
      number_contacts: 0,
      when_minutes: 2880,
      name: null,
      filter: null,
      is_attached_to_initial: true,
    },
    {
      step_id: {
        type: "existing",
        id: 9299,
      },
      step_type: "action",
      position_x: 360,
      position_y: 1124,
      parent_ids: [
        {
          type: "existing",
          id: 9298,
        },
      ],
      action_type: "email",
      model_id: null,
      model_name: null,
      integration_id: null,
      integration_name: null,
      pool_id: null,
      pool_name: null,
      limit_send_per_day: 0,
      sending_weekends_allowed: false,
      allow_massmailing: false,
      time_start_sending: "07:00:00",
      time_stop_sending: "18:00:00",
      blacklist_ids: [],
      email_linkedin_exclude: [],
      is_initial: false,
      show_reply_as_conversation: false,
      use_smtp_fallback_server: false,
      signature_id: null,
      signature_name: null,
      number_contacts: 0,
      is_attached_to_initial: true,
    },
  ],
  from_sharing: true,
  sharing: [
    {
      sharing_type: "Workflow",
      sharing_type_to: "Team",
      read_permission: true,
      write_permission: false,
      delete_permission: false,
      ids_list: [7],
      created_on: "2024-04-17T07:21:19+00:00",
      name: "",
      sharing_targets: [
        {
          id: 7,
          name: "Magileads",
        },
      ],
    },
  ],
  created_by: 803,
  tags: [],
  permission: {
    read_permission: true,
    write_permission: true,
    delete_permission: true,
    is_admin: true,
  },
  created_on: "2024-04-15T17:46:36+00:00",
  updated_on: "2024-04-17T07:21:19+00:00",
  folder: null,
  archived: false,
  programmed: false,
  auto_remove_responders: true,
};

export const workflow5 = {
  id: 2313,
  name: "SMS + Email + Linkedin",
  steps: [
    {
      step_id: {
        type: "existing",
        id: 9604,
      },
      step_type: "action",
      position_x: 40,
      position_y: 168,
      parent_ids: [],
      action_type: "linkedin_invitation",
      model_id: 1672,
      model_name: "Copie de B2B AGENCES",
      integration_id: 2142,
      integration_name: "CHRISTIAN Martineau",
      pool_id: null,
      pool_name: null,
      limit_send_per_day: 0,
      sending_weekends_allowed: false,
      allow_massmailing: false,
      time_start_sending: "07:00:00",
      time_stop_sending: "18:00:00",
      blacklist_ids: [],
      email_linkedin_exclude: [],
      is_initial: true,
      show_reply_as_conversation: false,
      use_smtp_fallback_server: false,
      signature_id: null,
      signature_name: null,
      number_contacts: 0,
      is_attached_to_initial: true,
    },
  ],
  from_sharing: true,
  sharing: [
    {
      sharing_type: "Workflow",
      sharing_type_to: "Team",
      read_permission: true,
      write_permission: false,
      delete_permission: false,
      ids_list: [7],
      created_on: "2024-04-17T07:21:26+00:00",
      name: "",
      sharing_targets: [
        {
          id: 7,
          name: "Magileads",
        },
      ],
    },
  ],
  created_by: 803,
  tags: [],
  permission: {
    read_permission: true,
    write_permission: true,
    delete_permission: true,
    is_admin: true,
  },
  created_on: "2024-04-15T17:49:51+00:00",
  updated_on: "2024-04-17T14:00:53+00:00",
  folder: null,
  archived: false,
  programmed: false,
  auto_remove_responders: true,
};
