import {
  ActionIcon,
  Button,
  CopyButton,
  Group,
  Popover,
  Text,
  TextInput,
  Tooltip,
  rem,
} from "@mantine/core";
import {
  IconBraces,
  IconCheck,
  IconCopy,
  IconTextPlus,
} from "@tabler/icons-react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useState } from "react";

const Spintax = ({ disabled, editorRef, isCode }) => {
  const [opened, setOpened] = useState(false);
  const lang = useLanguage((s) => s.language);

  const handleAdd = () => {
    if (isCode) {
      editorRef.current.editor.session.insert(
        editorRef.current.editor.getCursorPosition(),
        lang.models.spintax.example
      );
    } else
      editorRef.current.execCommand(
        "mceInsertContent",
        false,
        lang.models.spintax.example
      );
    setOpened(false);
  };

  return (
    <Popover withinPortal={true} opened={opened} onChange={setOpened}>
      <Popover.Target>
        <Tooltip label={lang.models.spintax.tooltip}>
          <Button
            leftSection={<IconBraces size={16} />}
            variant="light"
            size="sm"
            h={32}
            disabled={disabled}
            onClick={() => setOpened(true)}
          >
            Spintax
          </Button>
        </Tooltip>
      </Popover.Target>

      <Popover.Dropdown w={500}>
        <Text size="sm" mb={"md"}>
          {lang.models.spintax.text1} <b>{lang.models.spintax.text2}</b>
          {lang.models.spintax.text3}
        </Text>
        <TextInput
          readOnly
          value={lang.models.spintax.example}
          rightSectionWidth={64}
          rightSection={
            <Group wrap="nowrap" gap={0}>
              <CopyButton value={lang.models.spintax.example}>
                {({ copied, copy }) => (
                  <Tooltip
                    label={copied ? lang.global.copied : lang.global.copy}
                    color={copied ? "teal" : "black"}
                  >
                    <ActionIcon
                      color={copied ? "teal" : "primary"}
                      size={32}
                      variant="subtle"
                      onClick={copy}
                    >
                      {copied ? (
                        <IconCheck
                          style={{ width: rem(18), height: rem(18) }}
                          stroke={1.5}
                        />
                      ) : (
                        <IconCopy
                          style={{ width: rem(18), height: rem(18) }}
                          stroke={1.5}
                        />
                      )}
                    </ActionIcon>
                  </Tooltip>
                )}
              </CopyButton>

              <Tooltip label={lang.models.spintax.insert}>
                <ActionIcon size={32} variant="subtle" onClick={handleAdd}>
                  <IconTextPlus
                    style={{ width: rem(18), height: rem(18) }}
                    stroke={1.5}
                  />
                </ActionIcon>
              </Tooltip>
            </Group>
          }
        />
      </Popover.Dropdown>
    </Popover>
  );
};

export default Spintax;
