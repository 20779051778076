import ELK from "elkjs/lib/elk.bundled";
import { useFlowStore } from "../../../../../stores/WorkflowStore";
import {
  maxNormalChars,
  maxSalesNavigatorChars,
  nodeEmailHeight,
  nodeEventHeight,
  nodeHeight,
  nodeHeightPreview,
  nodeLinkedinMessageHeight,
  nodeSpacingHorizontal,
  nodeSpacingVertical,
  nodeWidth,
  nodeWidthLinkedinVisitPreview,
  nodeWidthPreview,
} from "./flowSettings";
import { getOutgoers } from "reactflow";
import {
  useAccountsStore,
  useModelsStore,
} from "../../../../../stores/ConfigStore";
import { getEvents } from "./nodes/event/events";

export const getLayoutedElements = async ({
  nodes,
  edges,
  isPreview,
  isTemplate,
}) => {
  const elk = new ELK();
  let flowDirection = useFlowStore.getState().direction;
  const options =
    flowDirection === "vertical"
      ? {
          "elk.algorithm": "mrtree",
          "elk.direction": "DOWN",
          "elk.spacing.nodeNode": nodeSpacingHorizontal,
        }
      : {
          "elk.algorithm": "layered",
          "elk.direction": "RIGHT",
          "org.eclipse.elk.layered.nodePlacement.strategy": "NETWORK_SIMPLEX",
        };
  const layoutOptions = options;
  const getNodeHeight = (node) => {
    if (isTemplate) {
      if (node.type === "start") {
        return 8;
      }
      return 72;
    }
    if (node.type === "start") {
      return 64;
    }
    if (node?.data?.actionType === "event") {
      if (isPreview) return 96;
      return nodeEventHeight;
    }
    if (node?.data?.actionType === "email" && !isPreview) {
      return nodeEmailHeight;
    }
    if (node?.data?.actionType === "linkedin_message" && !isPreview) {
      return nodeLinkedinMessageHeight;
    }
    if (
      node?.data?.actionType === "linkedin_invitation" &&
      isPreview &&
      !node?.data?.model_id
    ) {
      return nodeEventHeight;
    }
    if (
      node?.data?.actionType === "linkedin_message" &&
      isPreview &&
      !node?.data?.model_id
    ) {
      return nodeEventHeight;
    }
    if (node?.data?.actionType === "call" && isPreview) {
      return 100;
    }
    // if (node.type === "trail") {
    //   return 12;
    // }
    return isPreview ? nodeHeightPreview : nodeHeight;
  };
  const graph = {
    id: "root",
    layoutOptions: layoutOptions,
    children: nodes.map((node) => ({
      ...node,
      width: isTemplate
        ? 256
        : isPreview
        ? node?.data?.actionType === "linkedin_visit"
          ? nodeWidthLinkedinVisitPreview
          : nodeWidthPreview
        : nodeWidth,
      height: getNodeHeight(node),
    })),
    edges: edges,
  };

  const layoutedGraph = await elk.layout(graph);

  const newNodes = layoutedGraph.children.map((node) => ({
    ...node,
    position: { x: node.x, y: node.y },
  }));
  const newEdges = layoutedGraph.edges;
  return {
    nodes: newNodes,
    edges: newEdges,
  };
};

export const handleLayout = async () => {
  console.log("handling layout...");
  let edges = useFlowStore.getState().edges;
  let nodes = useFlowStore.getState().nodes;

  if (!nodes.find((x) => x.type === "start")) {
    nodes.push({
      id: "0",
      type: "start",
      position: { x: 0, y: 0 },
      data: { source: null },
    });
  }

  let finalNodes = [];
  let finalEdges = [...edges].filter((e) =>
    nodes.find((n) => n.id === e.target)
  );

  let nodesToDelete = [];
  nodes.forEach((node) => {
    const outgoers = getOutgoers(node, nodes, edges);
    finalNodes.push(node);

    // Add trail for every nodes without outgoers
    if (
      outgoers.length < 1 &&
      node.type !== "trail" &&
      node.data?.actionType !== "remove_prospect"
    ) {
      finalNodes.push({
        id: `${node.id}-trail`,
        type: "trail",
        data: {
          source: node.id.toString(),
          shouldBeEvent:
            node.type === "start" ? false : node?.data?.actionType !== "event",
          fromCall: node?.data?.actionType === "call",
          error: node?.data?.error,
        },
        position: {
          x: node.position.x,
          y: node.position.y + nodeSpacingVertical,
        },
      });
      finalEdges.push({
        id: `e-${node.id}-trail`,
        source: node.id.toString(),
        target: `${node.id}-trail`,
        type: "trail",
        data: { error: node?.data?.error },
      });
    }

    // If a trail node is found, delete it if its parent has a real child
    if (node.type === "trail") {
      let trail_source = node.data.source;
      let source_child = nodes.find(
        (x) => x.data.source === trail_source && x.type !== "trail"
      );
      if (source_child) {
        nodesToDelete.push(node.id);
      }
    }
  });

  console.log("before layouting", { finalEdges, finalNodes });
  const response = await getLayoutedElements({
    nodes: finalNodes.filter((x_2) => !nodesToDelete.includes(x_2.id)),
    edges: finalEdges.filter(
      (x_3) =>
        !nodesToDelete.includes(x_3.source) &&
        !nodesToDelete.includes(x_3.target)
    ),
  });
  // Place trails
  let newNodes = response.nodes.map((n_1) => {
    if (n_1.id?.includes("-trail")) {
      return {
        ...n_1,
        position: {
          x: n_1.position.x,
          y: n_1.position.y - 48,
        },
      };
    }
    return n_1;
  });
  let setEdges = useFlowStore.getState().setEdges;
  let setNodes = useFlowStore.getState().setNodes;
  setNodes(newNodes);
  setEdges(response.edges);
  console.log("handling layout done");
};

export const handleLayoutPreview = () => {
  console.log("handling layout...");
  let edges = useFlowStore.getState().edges;
  let nodes = useFlowStore.getState().nodes;

  if (!nodes.find((x) => x.type === "start")) {
    nodes.push({
      id: "0",
      type: "start",
      position: { x: 0, y: 0 },
      data: { source: null },
    });
  }

  let finalNodes = [...nodes];
  let finalEdges = [...edges];

  console.log("before layouting", { finalEdges, finalNodes });
  getLayoutedElements({
    nodes: finalNodes.filter((x) => !x.id.includes("trail")),
    edges: finalEdges.filter((x) => !x.id.includes("trail")),
    isPreview: true,
  }).then((response) => {
    let setEdges = useFlowStore.getState().setEdges;
    let setNodes = useFlowStore.getState().setNodes;
    setNodes(response.nodes);
    setEdges(response.edges);
    console.log("handling layout done");
  });
};

export const handleLayoutTemplatePreview = ({
  nodes,
  edges,
  setNodes,
  setEdges,
}) => {
  console.log("handling layout...");

  if (!nodes.find((x) => x.type === "start")) {
    nodes.push({
      id: "0",
      type: "start",
      position: { x: 0, y: 0 },
      data: { source: null },
    });
  }

  let finalNodes = [...nodes];
  let finalEdges = [...edges];

  console.log("before layouting", { finalEdges, finalNodes });
  getLayoutedElements({
    nodes: finalNodes.filter((x) => !x.id.includes("trail")),
    edges: finalEdges.filter((x) => !x.id.includes("trail")),
    isTemplate: true,
  }).then((response) => {
    setNodes(response.nodes);
    setEdges(response.edges);
    console.log("handling layout done");
  });
};

export function getNodesError({ nodes, exclude_responders }) {
  let errors = nodes.map((x) => ({
    id: x.id,
    type: x.data?.actionType,
    errors: [],
  }));

  nodes.forEach((node) => {
    let index = errors.findIndex((x) => x.id === node.id);
    let data = node?.data;
    if (data?.actionType !== "event") {
      if (
        !data?.model_id &&
        ![
          "linkedin_invitation",
          "call",
          "remove_prospect",
          "linkedin_visit",
        ].includes(data?.actionType)
      ) {
        errors[index].errors.push("no_model");
      }
      if (
        !data?.integration_id &&
        !data?.pool_id &&
        !["sms", "smv", "call", "remove_prospect"].includes(data?.actionType)
      ) {
        errors[index].errors.push("no_integration");
      }

      // Error for message length
      if (data?.actionType === "linkedin_invitation") {
        let isSales = true;
        let pools = useAccountsStore.getState().linkedin_pools;
        let accounts = useAccountsStore.getState().linkedin_accounts;
        if (data?.pool_id) {
          let pool = pools.find((x) => x.id === data?.pool_id);
          isSales = pool?.accounts_list?.find(
            (x) => x?.is_sales_navigator_account === true
          );
        }
        if (data?.integration_id) {
          let account = accounts.find((x) => x.id === data?.integration_id);
          isSales = account?.is_sales_navigator_account === true;
        }

        let maxLength = isSales ? maxSalesNavigatorChars : maxNormalChars;
        let encoder = new TextEncoder("utf-8");
        let length = encoder.encode(data?.invitation_text)?.length;
        if (length > maxLength) {
          errors[index].errors.push("message_too_long");
        }
      }
    } else {
      // If the event correspond to its parent action
      const nodeSource = nodes.find((x) => x.id === data?.source);
      const models = useModelsStore.getState().model_emails;
      const sourceModel = models.find(
        (x) => x.id === nodeSource?.data?.model_id
      );
      const correctEvents = getEvents(nodeSource?.data?.actionType);

      if (
        node?.data?.event_type === "link_click" &&
        sourceModel?.links?.length === 0
      ) {
        errors[index].errors.push("model_has_no_links");
      }
      if (
        !correctEvents.find((x) => x.value === data?.event_type) &&
        data?.event_type &&
        nodeSource?.data?.actionType !== "call"
      ) {
        errors[index].errors.push("incorrect_event");
      }

      // No event type or name
      if (!data?.event_type && nodeSource?.data?.actionType !== "call") {
        errors[index].errors.push("no_event");
      }

      if (nodeSource?.data?.actionType === "call" && !data?.name) {
        errors[index].errors.push("no_event_name");
      }

      // Error on no_tracking and no_imap
      if (nodeSource?.data?.actionType === "email") {
        const emails = useAccountsStore.getState().email_accounts;
        const email_pools = useAccountsStore.getState().email_pools;
        const integration = emails.find(
          (x) => x.id === nodeSource?.data?.integration_id
        );
        const pool = email_pools.find(
          (x) => x.id === nodeSource?.data?.pool_id
        );

        if (integration) {
          if (
            !integration?.enable_tracking &&
            ["link_click", "email_open", "email_open_no_click"].includes(
              data?.event_type
            )
          ) {
            errors[index].errors.push("parent_email_has_no_tracking");
          }
          if (
            !integration?.can_read_responses &&
            data.event_type === "email_answered"
          ) {
            errors[index].errors.push("parent_email_has_no_imap");
          }
        }

        if (pool) {
          if (
            !pool.accounts_list.find((x) => x?.enable_tracking) &&
            ["link_click", "email_open", "email_open_no_click"].includes(
              data?.event_type
            )
          ) {
            errors[index].errors.push("parent_email_has_no_tracking");
          }
          if (
            !pool.accounts_list.find((x) => x?.can_read_responses) &&
            data.event_type === "email_answered"
          ) {
            errors[index].errors.push("parent_email_has_no_imap");
          }
        }
      }

      if (exclude_responders && data?.event_type === "email_answered") {
        errors[index].errors.push("email_answered_but_answerers_excluded");
      }
    }
  });

  return errors;
}
