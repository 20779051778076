import { Badge, Button, Group, Stack, Tooltip } from "@mantine/core";
import { useLanguage } from "../../../stores/LanguageStore";
import { useState } from "react";
import PoolsSelector from "../../../components/Selectors/PoolsSelector";
import ColumnEditor from "./ColumnEditor";
import { useUIStore } from "../../../stores/UIStore";

const PoolEditor = ({ editFunction, cell, closePopover, apiRef, type }) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(false);
  const [pools, setPools] = useState(cell.row.pools);
  const setAsideLoading = useUIStore((s) => s.setAsideLoading);

  const handleSubmit = (e) => {
    e.preventDefault();

    let fd = new FormData(e.target);
    let data = Object.fromEntries(fd);

    setLoading(true);
    editFunction({
      id: cell.row.id,
      pools_ids: pools?.map((x) => x.id),
      connection_test: false,
    })
      .then((response) => {
        closePopover();
        apiRef.current?.updateRows([{ id: cell.row.id, pools: pools }]);
        setAsideLoading(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Stack component={"form"} onSubmit={handleSubmit} gap={"xs"} w={400}>
      <PoolsSelector type={type} value={pools} setValue={setPools} autoFocus />
      <Group wrap="nowrap" justify="end">
        <Button
          onClick={closePopover}
          size="compact-sm"
          variant="subtle"
          disabled={loading}
        >
          {lang.components.closer.submit}
        </Button>
        <Button
          type="submit"
          loading={loading}
          size="compact-sm"
          color="tertiary.8"
        >
          {lang.contact_lists.edit.dialog.submit_button}
        </Button>
      </Group>
    </Stack>
  );
};

export const poolsColumn = ({ apiRef, editFunction, type }) => {
  const lang = useLanguage.getState().language;
  return {
    field: "pools",
    headerName: "Pools",
    width: 150,
    cellClassName: "hover-source",
    valueGetter: (params) => {
      if (params.value) return params.value.map((e) => e.name).join(", ");
      return null;
    },
    renderCell: (cell) => {
      const mainEdit = editFunction(cell.row);
      return (
        <Group wrap="nowrap" w={"100%"}>
          {cell.value ? (
            <Group flex={"1 1 auto"} wrap="nowrap">
              {cell.value?.split(", ")?.map((pool) => (
                <Tooltip label={`${lang.tags.filter_pool} ${pool}`} key={pool}>
                  <Badge
                    variant="gradient"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      apiRef.current.setFilterModel({
                        items: [
                          {
                            field: "pools",
                            operator: "contains",
                            value: pool,
                          },
                        ],
                        logicOperator: "and",
                        quickFilterLogicOperator: "and",
                        quickFilterValues: [],
                      });
                    }}
                    size="xs"
                    radius={"xs"}
                  >
                    {pool}
                  </Badge>
                </Tooltip>
              ))}
            </Group>
          ) : (
            <Group flex={"1 1 auto"}>-</Group>
          )}

          <ColumnEditor
            disabled={
              (!cell.row?.write_permission &&
                !cell?.row?.permission?.write_permission) ||
              cell.row.account_type === "MassMailing"
            }
            isAdd={cell.row?.pools?.length === 0}
            dropDownComponent={(closePopover) => (
              <PoolEditor
                editFunction={mainEdit}
                cell={cell}
                closePopover={closePopover}
                apiRef={apiRef}
                type={type}
              />
            )}
          />
        </Group>
      );
    },
  };
};
