import { Anchor, Box, Button, Group, Stack, Text } from "@mantine/core";
import { Link, useRouteError } from "react-router-dom";
import { IconHome2 } from "@tabler/icons-react";
import ErrorImage404 from "./ErrorImage404";
import ErrorImage401 from "./ErrorImage401";
import ErrorImage500 from "./ErrorImage500";
import ErrorImageBug from "./ErrorImageBug";
import NetworkError from "./NetworkError";
import { useLanguage } from "../../../stores/LanguageStore";
import PageTitle from "../../../components/View/PageTitle";
import ErrorImage503 from "./ErrorImage503";

const ErrorPage = ({ status }) => {
  const lang = useLanguage((s) => s.language);
  const error = useRouteError() || {};

  const handleError = () => {
    if (error?.status === 404 || status === 404) {
      return {
        component: <ErrorImage404 />,
        text: lang.errors.page.not_exist,
      };
    }

    if (error?.status === 401 || error.status === 403 || status === 403) {
      return {
        component: <ErrorImage401 />,
        text: lang.errors.page.not_authorized,
      };
    }

    if (error?.status === 503 || status === 503) {
      return {
        component: <ErrorImage503 />,
        text: lang.errors.page.maintenance,
        text_additional: lang.errors.page.maintenance_text,
        isMaintenance: true,
      };
    }

    if (error?.status?.toString().startsWith("5")) {
      return {
        component: <ErrorImage500 />,
        text: lang.errors.page.server,
      };
    }

    if (error?.status === 418) {
      return {
        component: <NetworkError />,
        text: lang.errors.page.network,
      };
    }

    return {
      component: <ErrorImageBug />,
      text: lang.errors.page.bug,
      isBug: true,
    };
  };

  const handleClick = () => {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((registration) => {
        registration.unregister();
      });
    });
    caches.keys().then((keyList) => {
      return Promise.all(
        keyList.map((key) => {
          return caches.delete(key);
        })
      );
    });
    setTimeout(() => {
      window.location.href = "/";
    }, 200);
  };

  return (
    <>
      <Group className="layout-block top">
        <PageTitle title={lang.errors.page.title} />
      </Group>

      <Stack w={"100%"} h={"80%"} align="center" justify="center" pt={32}>
        <Box
          w={
            handleError()?.isBug
              ? 400
              : handleError()?.text_additional
              ? 450
              : 300
          }
        >
          {handleError().component}
        </Box>
        <Text fz={28} fw={500}>
          {handleError().text}
        </Text>

        {handleError()?.text_additional && (
          <Text fz={18} fw={500} w={"50%"} ta={"center"} mb={16}>
            {handleError().text_additional}
          </Text>
        )}

        {handleError()?.isBug && (
          <Text mb={"lg"}>
            {lang.errors.page.text1}{" "}
            <Anchor
              href={`https://support.google.com/accounts/answer/32050?hl=${
                lang.id === "FRA" ? "fr" : "en"
              }&co=GENIE.Platform%3DDesktop`}
            >
              {lang.errors.page.text2}
            </Anchor>{" "}
            {lang.errors.page.text3}
          </Text>
        )}

        <Button
          component={Link}
          to={"/"}
          leftSection={<IconHome2 />}
          onClick={handleError()?.isBug ? handleClick : undefined}
        >
          {lang.errors.page.back}
        </Button>
      </Stack>
    </>
  );
};

export default ErrorPage;
