import { Button, Group, Select, Stack, TextInput, Title } from "@mantine/core";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { notifications } from "@mantine/notifications";
import BackButton from "../../../../components/View/BackButton";
import PageTitle from "../../../../components/View/PageTitle";
import { createAIKey } from "../../../../api/ConfigsApi";
import { updateProfile } from "../../../../api/UserAPI";
import { useProfileStore } from "../../../../stores/UserStore";
import { useConfigStore } from "../../../../stores/ConfigStore";

const CreateAiKeyPage = () => {
  const lang = useLanguage((s) => s.language);
  const datafields = useConfigStore((s) => s.datafields);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    let fd = new FormData(e.target);
    let data = Object.fromEntries(fd);

    setLoading(true);
    setError(null);
    createAIKey({ name: data?.name, api_key: data?.api_key, type: data?.type })
      .then((response) => {
        setTimeout(() => {
          notifications.show({
            message: lang.integrations.row_highlight.created,
            color: "tertiary.8",
          });
        }, 300);
        navigate(`../?highlight=${response.data.data_field_id}`);
      })
      .catch((err) => {
        let message = err.response?.data?.state_message;
        setLoading(false);
        if (message === "invalid_api_key") {
          setError("api_key");
          return document.getElementById("api_key-field").focus();
        }
      });
  };
  return (
    <>
      <Group
        className="layout-block top"
        w={"100%"}
        gap={0}
        justify="space-between"
      >
        <PageTitle title={lang.integrations.browser_tab_title} />
      </Group>
      <Stack
        className="layout-block"
        w={"40%"}
        mx={"auto"}
        gap={"xs"}
        component={"form"}
        onSubmit={handleSubmit}
      >
        <Title order={5}>{lang.integrations.create.dialog.title}</Title>
        <TextInput
          name="name"
          label={lang.integrations.create.dialog.name}
          placeholder={lang.integrations.create.dialog.name}
          autoFocus
          required
        />

        <Select
          label={lang.api_keys.columns.type}
          name="type"
          defaultValue={"openai"}
          required
          data={[
            {
              label: "OpenAI",
              value: "openai",
            },
            // {
            //   label: "Datagma",
            //   value: "datagma",
            // },
            // {
            //   label: "FullEnrich",
            //   value: "fullenrich",
            // },
            {
              label: "Kaspr",
              value: "kaspr",
            },
          ]}
        />

        <TextInput
          id="api_key-field"
          name="api_key"
          label={lang.integrations.create.dialog.key}
          placeholder={
            "ex: sk-OpSB4rsdS6xmWNVZaau4T3BlbkBJNvY4JES7WUdn76XWJKZX"
          }
          required
          error={error === "api_key" ? lang.errors.main.invalid_api_key : null}
        />

        <Group justify="flex-end" mt={"md"}>
          <BackButton />
          <Button type="submit" loading={loading}>
            {lang.integrations.create.dialog.submit_button}
          </Button>
        </Group>
      </Stack>
    </>
  );
};

export default CreateAiKeyPage;
