import {
  CheckIcon,
  Combobox,
  Group,
  Loader,
  Pill,
  PillsInput,
  useCombobox,
} from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { useState } from "react";
import { useLanguage } from "../../stores/LanguageStore";

const TagsSelector = ({
  data,
  value,
  setValue,
  label,
  placeholder,
  leftSection,
  createFunction,
  createLabel,
  onDropdownOpen,
  onNewValueCreated,
  loading,
  created_field_id,
  autoFocus
}) => {
  const lang = useLanguage((s) => s.language)
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => {
      combobox.updateSelectedOptionIndex("active");
      onDropdownOpen?.();
    },
  });

  const [search, setSearch] = useState("");

  const exactOptionMatch = data.some((item) => item === search);

  const [creating, setCreating] = useState(false);
  const handleNewValue = () => {
    setCreating(true);

    createFunction({ name: search })
      .then((response) => {
        onNewValueCreated?.();
        setValue((current) => [
          ...current,
          { id: response.data[created_field_id], name: search },
        ]);
        setSearch("");
      })
      .finally(() => setCreating(false));
  };

  const handleValueSelect = (val) => {
    if (val === "$create") {
      handleNewValue();
    } else {
      setValue((current) =>
        current.find((x) => x.id === val.id)
          ? current.filter((v) => v.id !== val.id)
          : [...current, val]
      );
      setSearch("");
    }
    combobox.closeDropdown();
  };

  const handleValueRemove = (val) =>
    setValue((current) => current.filter((v) => v.id !== val.id));

  const values = value.map((item) => (
    <Pill
      key={item.id}
      withRemoveButton
      onRemove={() => handleValueRemove(item)}
    >
      {item.name}
    </Pill>
  ));

  const options = data
    .filter((item) =>
      item.name.toLowerCase().includes(search.trim().toLowerCase())
    )
    .map((item) => (
      <Combobox.Option
        value={item}
        key={item.id}
        active={value.find((x) => x.id === item.id)}
      >
        <Group gap="sm">
          {value.find((x) => x.id === item.id) ? <CheckIcon size={12} /> : null}
          <span>{item.name}</span>
        </Group>
      </Combobox.Option>
    ));

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={handleValueSelect}
      withinPortal={true}
      zIndex={1400}
      shadow="sm"
    >
      <Combobox.DropdownTarget>
        <PillsInput
          onClick={() => combobox.openDropdown()}
          label={label}
          leftSection={leftSection}
          rightSection={
            loading ? <Loader size={"xs"} type="dots" /> : <Combobox.Chevron />
          }
          rightSectionPointerEvents="none"
        >
          <Pill.Group>
            {values}

            <Combobox.EventsTarget>
              <PillsInput.Field
                onFocus={() => combobox.openDropdown()}
                autoFocus={autoFocus}
                value={search}
                placeholder={placeholder}
                onChange={(event) => {
                  combobox.updateSelectedOptionIndex();
                  setSearch(event.currentTarget.value);
                }}
                onKeyDown={(event) => {
                  if (event.key === "Backspace" && search.length === 0) {
                    event.preventDefault();
                    handleValueRemove(value[value.length - 1]);
                  }
                }}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>

      <Combobox.Dropdown>
        <Combobox.Options mah={128} style={{ overflowY: "auto" }}>
          {options}

          {!exactOptionMatch && search.trim().length > 0 && (
            <Combobox.Option
              value="$create"
              component={Group}
              gap={6}
              disabled={creating}
            >
              {creating ? (
                <Loader size={14} type="dots" />
              ) : (
                <IconPlus size={14} />
              )}
              <span>
                {createLabel} <b> {search}</b>
              </span>
            </Combobox.Option>
          )}

          {exactOptionMatch &&
            search.trim().length > 0 &&
            options.length === 0 && (
              <Combobox.Empty>{lang.global.no_element_found}</Combobox.Empty>
            )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};

export default TagsSelector;
