import { Button, Tooltip } from "@mantine/core";
import { IconDeviceFloppy } from "@tabler/icons-react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useState } from "react";
import { updateProfile } from "../../../../api/UserAPI";
import { notifications } from "@mantine/notifications";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import { useProfileStore } from "../../../../stores/UserStore";

const TableConfigSaver = () => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(false);

  const apiRef = useGridApiContext();

  const handleSave = () => {
    const density = apiRef.current.state.density.value;
    setLoading(true);

    updateProfile(null, { table_density: density })
      .then((response) => {
        notifications.show({
          message: lang.table.toolbar.columns.save_success,
          color: "tertiary.8",
        });
        const profile = useProfileStore.getState().profile;
        const setProfile = useProfileStore.getState().setProfile;
        setProfile({ ...profile, table_density: density });
      })
      .finally(() => setLoading(false));
  };

  return (
    <Tooltip label={lang.table.toolbar.density.save_tooltip}>
      <Button
        size="compact-xs"
        onClick={handleSave}
        variant="light"
        leftSection={<IconDeviceFloppy size={16} />}
        color="tertiary.8"
        mt={"xs"}
        loading={loading}
      >
        {lang.table.toolbar.density.save_label}
      </Button>
    </Tooltip>
  );
};

export default TableConfigSaver;
