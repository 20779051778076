import { Anchor, Button } from "@mantine/core";
import { useLanguage } from "../../../../../stores/LanguageStore";

const LinkedinCookie = () => {
  const lang = useLanguage((s) => s.language);
  return (
    <Anchor
      href="https://support.magileads.net/article/connecter-mon-compte-linkedin"
      target="_blank"
    >
      <Button size="compact-sm" variant="light" w={220} mt={-10}>
        {lang.senders.linkedin.how_to_cookie}
      </Button>
    </Anchor>
  );
};

export default LinkedinCookie;
