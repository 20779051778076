import { Button, Group, Input, Stack, Tooltip } from "@mantine/core";
import EmailAccountSelector from "../../../../../../../components/Selectors/EmailAccountSelector";
import { useFlowStore } from "../../../../../../../stores/WorkflowStore";
import LinkedinAccountSelector from "../../../../../../../components/Selectors/LinkedinAccountSelector";
import AddEmailSender from "../../../../../../../components/Adder/AddEmailSender";
import AddLinkedinAccount from "../../../../../../../components/Adder/AddLinkedinAccount";
import { useLanguage } from "../../../../../../../stores/LanguageStore";
import { IconEdit } from "@tabler/icons-react";
import { useMemo } from "react";

const PanelSender = ({ node }) => {
  const lang = useLanguage((s) => s.language);
  const updateNodeData = useFlowStore((s) => s.updateNodeData);

  const handleSenderChange = (v) => {
    let isPool = v?.number_of_accounts !== undefined;
    const newValue = v?.id ? parseInt(v?.id) : null;
    updateNodeData(node.id, {
      [isPool ? "pool_id" : "integration_id"]: newValue,
      [isPool ? "integration_id" : "pool_id"]: null,
    });
  };

  let parentLink = useMemo(() => {
    if (node?.data?.pool_id) {
      if (node?.data?.actionType === "email") {
        return "email/pool";
      } else {
        return "linkedin/pool";
      }
    } else if (node?.data?.actionType === "email") {
      return "email";
    } else if (node?.data?.actionType.includes("inkedin")) {
      return "linkedin";
    }
  }, [node?.data?.actionType, node?.data?.pool_id]);

  return (
    <>
      <Group mb={"xs"}>
        <Input.Label size="md" required fw={"normal"}>
          {lang.campaign.panel.sender_title}
        </Input.Label>
        {node?.data?.actionType === "email" ? (
          <AddEmailSender onCreated={handleSenderChange} />
        ) : (
          <AddLinkedinAccount onCreated={handleSenderChange} />
        )}
      </Group>
      <Group w={"100%"} mb={"sm"} wrap="nowrap">
        {node?.data?.actionType === "email" ? (
          <EmailAccountSelector
            value={node?.data?.pool_id || node?.data?.integration_id}
            onChange={handleSenderChange}
            required
            size={"sm"}
          />
        ) : (
          <LinkedinAccountSelector
            value={node?.data?.pool_id || node?.data?.integration_id}
            onChange={handleSenderChange}
            required
            size={"sm"}
          />
        )}
        <Tooltip label={lang.global.edit}>
          <Button
            variant="subtle"
            px={"xs"}
            component="a"
            href={`/senders/${parentLink}/${
              node?.data?.pool_id || node?.data?.integration_id
            }`}
            target="_blank"
            disabled={!node?.data?.integration_id && !node?.data?.pool_id}
          >
            <IconEdit size={21} />
          </Button>
        </Tooltip>
      </Group>
    </>
  );
};

export default PanelSender;
