import {
  ActionIcon,
  Badge,
  Button,
  Group,
  HoverCard,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import { useLanguage } from "../../../stores/LanguageStore";
import { useForm } from "react-hook-form";
import { useState } from "react";
import SharingController from "../../../components/Sharing/SharingController";
import { IconEdit, IconPlus } from "@tabler/icons-react";
import PopOver from "../../../components/View/PopOver";

export const SharingEditor = ({
  editFunction,
  row,
  apiRef,
  field,
  readOnly,
  closePopover,
  onFinish,
}) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(false);
  const sharingForm = useForm({
    defaultValues: {
      sharing: row[field]?.map((x) => ({
        ...x,
        sharing_targets: x?.sharing_targets?.map((sharing_target) => ({
          ...sharing_target,
          option_name: sharing_target.name,
        })),
      })),
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation()

    let fd = new FormData(e.target);
    let data = Object.fromEntries(fd);

    const sharing = sharingForm
      .getValues()
      .sharing.filter((x) => x.sharing_targets.length > 0)
      .map((share) => ({
        ...share,
        ids_list: share.sharing_targets.map((x) => x.id),
        sharing_targets: share.sharing_targets.map((x) => ({
          ...x,
          name: x.option_name,
        })),
      }));

    setLoading(true);
    editFunction({
      row,
      id: row.id,
      sharing,
    })
      .then((response) => {
        closePopover?.();
        onFinish?.(sharing);
        apiRef?.current?.updateRows([{ id: row.id, [field]: sharing }]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Stack
      component={"form"}
      onSubmit={handleSubmit}
      gap={"xs"}
      w={600}
      pt={16}
      px={8}
    >
      <SharingController form={sharingForm} readOnly={readOnly} />

      <Group wrap="nowrap" justify="end" mt="xs">
        <Button
          onClick={closePopover}
          size="xs"
          variant="subtle"
          disabled={loading}
        >
          {lang.components.closer.submit}
        </Button>
        <Button type="submit" loading={loading} size="xs" color="tertiary.8">
          {lang.contact_lists.edit.dialog.submit_button}
        </Button>
      </Group>
    </Stack>
  );
};

export const sharingColumn = ({
  apiRef,
  editFunction,
  field = "sharing",
  readOnly,
}) => {
  const lang = useLanguage.getState().language;
  return {
    field: field,
    headerName: lang.components.sharing.button_tooltip,
    cellClassName: "hover-source",
    width: 170,
    tabIndex: null,
    valueGetter: ({ row }) => {
      let shared = false;
      row[field]?.forEach((x) => {
        if (x?.sharing_targets?.length > 0) shared = true;
      });

      return (
        (row?.from_sharing
          ? lang.components.sharing?.row?.from_sharing
          : lang.components.sharing?.row?.source) +
        (shared ? lang.components.sharing.row?.shared : "")
      ).slice(0, -2);
    },
    renderCell: ({ row }) => {
      if (row?.isChild) {
        return "";
      }
      let shared = false;
      row[field]?.forEach((x) => {
        if (x?.sharing_targets?.length > 0 || row[field]?.length > 0)
          shared = true;
      });
      const sharedToUser = row[field]?.filter(
        (x) => x.sharing_type_to === "User"
      );
      const sharedToTeam = row[field]?.filter(
        (x) => x.sharing_type_to === "Team"
      );
      const sharedToOrg = row[field]?.filter(
        (x) => x.sharing_type_to === "Organization"
      );

      const getValues = (type) => {
        let values = "";
        if (type === "user") {
          sharedToUser?.forEach((share) => {
            share?.sharing_targets?.forEach((target) => {
              values += target.name + " #" + target.id + " | ";
            });
          });
        } else if (type === "team") {
          sharedToTeam?.forEach((share) => {
            share?.sharing_targets?.forEach((target) => {
              values += target.name + " #" + target.id + " | ";
            });
          });
        } else if (type === "org") {
          sharedToOrg?.forEach((share) => {
            share?.sharing_targets?.forEach((target) => {
              values += target.name + " #" + target.id + " | ";
            });
          });
        }
        return Array.from(new Set(values.split(" | ")))
          .join(", ")
          .toString()
          .slice(0, -2);
      };

      // const showModal = () => {
      //   const mainEdit = editFunction(row);

      //   modals.open({
      //     title: (
      //       <Text>
      //         {lang.components.sharing.title}: <b> {row.name} </b>
      //       </Text>
      //     ),
      //     children: (
      //       <SharingEditor
      //         apiRef={apiRef}
      //         field={field}
      //         editFunction={mainEdit}
      //         row={row}
      //         readOnly={readOnly}
      //       />
      //     ),
      //     size: "auto",
      //   });
      // };
      const mainEdit = editFunction(row);

      let createdBy = row?.created_by?.first_name
        ? `${row?.created_by?.first_name} #${row?.created_by?.id}`
        : `${lang.components.sharing.row.shared_by_user_id} #${row?.created_by}`;

      return (
        <Group gap={"xs"}>
          {row?.from_sharing &&
            (row.created_by ? (
              <Tooltip label={createdBy}>
                <Badge color="yellow" size="xs" radius={"xl"} py={9}>
                  {lang.components.sharing.row.from_sharing}
                </Badge>
              </Tooltip>
            ) : (
              <Tooltip label={lang.components.sharing.row.default_shared}>
                <Badge color="gray" size="xs" radius={"xl"} py={9}>
                  {lang.components.sharing.row.default}
                </Badge>
              </Tooltip>
            ))}
          {shared && (
            <HoverCard>
              <HoverCard.Target>
                <Badge color="orange" size="xs" radius={"xl"} py={9}>
                  {lang.components.sharing.row.shared_chip}
                </Badge>
              </HoverCard.Target>

              <HoverCard.Dropdown>
                <Text fz={12}>
                  {lang.components.sharing.row.shared_with} <br />
                  {getValues("user").length > 0 ? (
                    <>
                      <b>{lang.components.sharing.row.users} : </b>{" "}
                      {getValues("user") || " - "} <br />
                    </>
                  ) : (
                    ""
                  )}
                  {getValues("team").length > 0 ? (
                    <>
                      <b>{lang.components.sharing.row.teams} : </b>{" "}
                      {getValues("team") || " - "} <br />
                    </>
                  ) : (
                    ""
                  )}
                  {getValues("org").length > 0 ? (
                    <>
                      <b>{lang.components.sharing.row.organizations} : </b>{" "}
                      {getValues("org") || " - "} <br />
                    </>
                  ) : (
                    ""
                  )}
                </Text>
              </HoverCard.Dropdown>
            </HoverCard>
          )}

          {!row?.from_sharing && !shared ? " - " : ""}

          {editFunction && (
            <PopOver
              opener={(openFunction, ref, opened) => (
                <Tooltip
                  label={
                    shared
                      ? lang.tags.edit.button_label
                      : lang.components.sharing.add
                  }
                >
                  <ActionIcon
                    variant="subtle"
                    size={"sm"}
                    color="secondary"
                    className="hover-target"
                    onClick={openFunction}
                    ref={ref}
                    style={{
                      position: "absolute",
                      right: 8,
                      background: "white",
                      boxShadow: "#00000033 0 2px 5px",
                      opacity: opened ? 1 : undefined,
                    }}
                    disabled={
                      !row?.write_permission &&
                      !row?.permission?.write_permission
                    }
                  >
                    {shared ? <IconEdit size={16} /> : <IconPlus />}
                  </ActionIcon>
                </Tooltip>
              )}
              popoverChildren={(closePopover) => (
                <SharingEditor
                  apiRef={apiRef}
                  editFunction={mainEdit}
                  field={field}
                  readOnly={readOnly}
                  row={row}
                  closePopover={closePopover}
                />
              )}
              elevation={3}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
            />
          )}
        </Group>
      );
    },
  };
};
