import {
  Button,
  Group,
  Indicator,
  Stack,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import {
  IconFilter,
  IconSearch,
  IconX,
  IconZoomInFilled,
} from "@tabler/icons-react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useNavigate } from "react-router-dom";
import PopOver from "../../../../components/View/PopOver";
import { useCallback, useMemo, useState } from "react";
import FilterContent from "../../../../components/Filter/FilterContent";
import { useConfigStore } from "../../../../stores/ConfigStore";

const SearchListsButton = ({ isBlacklist }) => {
  const lang = useLanguage((s) => s.language);
  const [currentFilter, setCurrentFilter] = useState(null);
  const datafields = useConfigStore((s) => s.datafields);

  const fields = useMemo(() => {
    return [
      {
        label: "Champs",
        options: datafields.map((x) => ({ ...x, option_name: x.name })),
      },
    ];
  }, [datafields]);

  const handleFilterChange = useCallback((newFilter) => {
    setCurrentFilter(newFilter);
  }, []);

  const navigate = useNavigate();
  const main = useCallback(
    (e) => {
      e.preventDefault();
      const fd = new FormData(e.target);
      const data = Object.fromEntries(fd);

      navigate(
        (isBlacklist
          ? "/exclusions/blacklists/search?query="
          : "/contact-lists/search?query=") +
          data.search +
          (currentFilter?.values?.length > 0
            ? `&filter=${JSON.stringify(currentFilter)}`
            : "")
      );
    },
    [currentFilter, isBlacklist, navigate]
  );

  return (
    <PopOver
      opener={(handleOpen, ref) => (
        <Tooltip label={lang.contact_lists.search_button_tooltip}>
          <Button
            className="toolbar-action-button"
            variant="light"
            color="secondary"
            justify="start"
            ref={ref}
            onClick={handleOpen}
          >
            <IconZoomInFilled size={18} />
          </Button>
        </Tooltip>
      )}
      popoverChildren={(handleClose) => (
        <Stack component={"form"} onSubmit={main} gap={"xs"} px={"xs"}>
          <Text size="sm">{lang.contact_lists.search_button_tooltip}</Text>
          <TextInput
            placeholder={lang.table.toolbar.search_placeholder}
            required={currentFilter?.values?.length === 0 || !currentFilter}
            autoFocus
            name="search"
            minLength={2}
            rightSection={<IconSearch size={16} />}
            w={300}
          />

          <PopOver
            opener={(openFunction, ref) => {
              return (
                <Group gap={0}>
                  <Tooltip
                    label={lang.table.toolbar.filter.main_button_tooltip}
                  >
                    <Button
                      ref={ref}
                      onClick={openFunction}
                      radius={0}
                      variant={"light"}
                      style={{
                        borderRight:
                          currentFilter?.items?.length > 0 ? 0 : undefined,
                      }}
                      w={"100%"}
                    >
                      <Indicator
                        color="red"
                        offset={2}
                        disabled={
                          currentFilter?.values?.length === 0 || !currentFilter
                        }
                        label={currentFilter?.values?.length}
                      >
                        <IconFilter size={18} />
                      </Indicator>
                      <span style={{ marginLeft: 6 }}>
                        {lang.table.toolbar.filter.main_button_label}
                      </span>
                    </Button>
                  </Tooltip>
                  {currentFilter?.items?.length > 0 && (
                    <Tooltip
                      label={lang.table.toolbar.filter.cancel_button_tooltip}
                    >
                      <Button
                        variant="light"
                        color="red"
                        px={4}
                        radius={0}
                        style={{
                          borderRight: "1px solid #00000016",
                          borderBottom: "1px solid #00000016",
                        }}
                        onClick={() => handleFilterChange(null)}
                      >
                        <IconX size={18} />
                      </Button>
                    </Tooltip>
                  )}
                </Group>
              );
            }}
            popoverChildren={(closePopover) => (
              <FilterContent
                id={"search-filter"}
                isClient={false}
                fields={fields}
                mainFilter={currentFilter}
                setMainFilter={(v) => {
                  handleFilterChange(v);
                  closePopover();
                }}
                getFieldOptionName={(f) => f.name}
                pageName={"contact_lists_contacts"}
              />
            )}
            elevation={3}
            paperSx={{
              ml: -12,
            }}
          />

          <Group justify="end">
            <Button onClick={handleClose} size="compact-sm" variant="subtle">
              {lang.components.closer.submit}
            </Button>
            <Button
              type="submit"
              size="compact-sm"
              color="tertiary.8"
            >
              Rechercher
            </Button>
          </Group>
        </Stack>
      )}
    />
  );
};

export default SearchListsButton;
