import {
  Button,
  Group,
  Indicator,
  LoadingOverlay,
  NumberFormatter,
  Popover,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import { useLanguage } from "../../stores/LanguageStore";
import { IconCalendarStats } from "@tabler/icons-react";
import { useProfileStore } from "../../stores/UserStore";
import { updateSelf } from "../../api/UserAPI";
import { getLimit } from "../../functions/utilFunctions";

const SignageItem = ({ label, used, limit }) => {
  return (
    <Group>
      <Text w={250}>{label}</Text>
      <Group c={limit < used ? "red" : undefined} ml={"auto"}>
        <Text miw={50} fw={600}>
          <NumberFormatter value={used} thousandSeparator={" "} />
        </Text>
        <Text component={"span"}>/</Text>
        <Text miw={50} ta={"right"} fw={600}>
          <NumberFormatter value={limit} thousandSeparator={" "} />
        </Text>
      </Group>
    </Group>
  );
};

const Signage = () => {
  const lang = useLanguage((s) => s.language);
  const [opened, setOpened] = useState(false);
  const profile = useProfileStore((s) => s.profile);
  const [loading, setLoading] = useState(false);

  const usedEmails = useMemo(() => {
    return {
      used:
        getLimit("maxEmailsSentPerDay", profile, "value") -
        getLimit("maxEmailsSentPerDay", profile, "remaining"),
      limit: getLimit("maxEmailsSentPerDay", profile, "value"),
    };
  }, [profile]);

  const usedLinkedIn = useMemo(() => {
    return {
      used:
        getLimit("maxLinkedinInteractionsPerDay", profile, "value") -
        getLimit("maxLinkedinInteractionsPerDay", profile, "remaining"),
      limit: getLimit("maxLinkedinInteractionsPerDay", profile, "value"),
    };
  }, [profile]);

  const usedSms = useMemo(() => {
    return {
      used:
        getLimit("maxSmsSentPerDay", profile, "value") -
        getLimit("maxSmsSentPerDay", profile, "remaining"),
      limit: getLimit("maxSmsSentPerDay", profile, "value"),
    };
  }, [profile]);

  const usedSmv = useMemo(() => {
    return {
      used:
        getLimit("maxSmvSentPerDay", profile, "value") -
        getLimit("maxSmvSentPerDay", profile, "remaining"),
      limit: getLimit("maxSmvSentPerDay", profile, "value"),
    };
  }, [profile]);

  const limitReached = useMemo(() => {
    if (
      (usedEmails.limit <= usedEmails.used && usedEmails.used !== 0) ||
      (usedLinkedIn.limit <= usedLinkedIn.used && usedLinkedIn.used !== 0) ||
      (usedSms.limit <= usedSms.used && usedSms.used !== 0) ||
      (usedSmv.limit <= usedSmv.used && usedSmv.used !== 0)
    ) {
      return true;
    }
    return false;
  }, [usedEmails, usedLinkedIn, usedSms, usedSmv]);

  useEffect(() => {
    if (opened) {
      setLoading(true);
      updateSelf(true).finally(() => setLoading(false));
    }
  }, [opened]);

  return (
    <Popover
      shadow="md"
      opened={opened}
      onChange={setOpened}
      offset={5}
      position={"bottom"}
      zIndex={1600}
      withArrow
    >
      <Popover.Target>
        <Tooltip
          label={lang.footer.signage.button_label}
          disabled={opened}
          zIndex={400}
        >
          <Indicator
            processing
            color="red.7"
            withBorder
            disabled={!limitReached}
            offset={11}
            styles={{
              indicator: {
                width: 12,
                height: 12,
              },
            }}
            size={16}
          >
            <Button
              variant="light"
              px={"xs"}
              radius={"sm"}
              w={"100%"}
              onClick={() => setOpened((o) => !o)}
              color={limitReached ? "red" : "primary"}
            >
              <IconCalendarStats />
            </Button>
          </Indicator>
        </Tooltip>
      </Popover.Target>

      <Popover.Dropdown>
        <Stack pos={"relative"} gap={8}>
          <LoadingOverlay visible={loading} overlayProps={{ blur: 2 }} />
          <Text fw={"bold"}>{lang.footer.signage.button_label}</Text>
          <SignageItem
            label={lang.footer.signage.email_daily_limit}
            limit={usedEmails.limit}
            used={usedEmails.used}
          />
          <SignageItem
            label={lang.footer.signage.linkedin_daily_limit}
            limit={usedLinkedIn.limit}
            used={usedLinkedIn.used}
          />
          <SignageItem
            label={lang.footer.signage.sms_daily_limit}
            limit={usedSms.limit}
            used={usedSms.used}
          />
          <SignageItem
            label={lang.footer.signage.smv_daily_limit}
            limit={usedSmv.limit}
            used={usedSmv.used}
          />
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
};

export default Signage;
