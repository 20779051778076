import { Button, Indicator, Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconUserScan } from "@tabler/icons-react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import NurturingContent from "./CaptchaContent";
import { useEffect, useState } from "react";
import { getCaptchas } from "../../../../../api/PRMAPI";
import { useParams } from "react-router-dom";
import { useProfileStore } from "../../../../../stores/UserStore";

const CaptchaContacts = () => {
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);
  const { userId } = useParams();
  const [opened, { open, close }] = useDisclosure();
  const [loading, setLoading] = useState(true);

  const [items, setItems] = useState([]);

  useEffect(() => {
    if (loading) {
      getCaptchas()
        .then((response) => {
          setItems(response.data.protections);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [loading]);

  return (
    <>
      <Indicator
        label={items.filter((x) => !x.handled).length}
        offset={6}
        color="red"
        disabled={items.filter((x) => !x.handled).length === 0}
        size={16}
      >
        <Button
          variant="light"
          radius={"sm"}
          leftSection={<IconUserScan size={20} />}
          onClick={open}
          disabled={userId ? profile.id !== parseInt(userId) : false}
          loading={loading}
          ml={32}
        >
          {lang.prm.captcha.button_label}
        </Button>
      </Indicator>

      <Modal
        opened={opened}
        onClose={close}
        title={lang.prm.captcha.dialog_title}
        size={"60%"}
      >
        <NurturingContent
          handleClose={close}
          items={items}
          setItems={setItems}
        />
      </Modal>
    </>
  );
};

export default CaptchaContacts;
