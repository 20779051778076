import { create } from "zustand";
import { useLanguage } from "./LanguageStore";

export const defaultStatuses = () => {
  const lang = useLanguage.getState().language;
  return [
    {
      id: "opener",
      name: lang.components.status_selector.statuses.opener,
      visible: true,
      disableDrop: true,
    },
    {
      id: "clicker",
      name: lang.components.status_selector.statuses.clicker,
      visible: true,
      disableDrop: true,
      color: "0fa1a4",
    },
    {
      id: "answerer",
      name: lang.components.status_selector.statuses.answerer,
      visible: true,
      disableDrop: true,
      color: "0078c4",
    },
    {
      id: "to_call",
      name: lang.components.status_selector.statuses.to_call,
      visible: true,
      disableDrop: false,
      color: "5900cd",
    },
  ];
};

const prmStore = (set, get) => ({
  view: "pipeline",
  setView: (newData) => set({ view: newData }, false, "setView"),

  filters: [],
  setFilters: (newData) => set({ filters: newData }, false, "setFilters"),

  fields: [],
  setFields: (newData) => set({ fields: newData }, false, "setFields"),

  systemStatuses: [],
  setSystemStatuses: (newData) => set({ systemStatuses: newData }),
  customStatuses: [],
  setCustomStatuses: (newData) => set({ customStatuses: newData }),

  stats: [],
  setStats: (newData) => set({ stats: newData }, false, "setStats"),

  activeUser: null,
  setActiveUser: (newData) =>
    set({ activeUser: newData }, false, "setActiveUser"),

  activeFilter: null,
  setActiveFilter: (newData) =>
    set({ activeFilter: newData }, false, "setActiveFilter"),

  filter: null,
  setFilter: (newData) => set({ filter: newData }, false, "setFilter"),

  query: null,
  setQuery: (newData) => set({ query: newData }, false, "setQuery"),

  sharedConditions: null,
  setSharedConditions: (newData) =>
    set({ sharedConditions: newData }, false, "setSharedConditions"),

  campaignOptions: [],
  setCampaignOptions: (newData) => set({ campaignOptions: newData }),

  listOptions: [],
  setListOptions: (newData) => set({ listOptions: newData }),

  integrationOptions: [],
  setIntegrationOptions: (newData) => set({ integrationOptions: newData }),

  usersOptions: [],
  setUsersOptions: (newData) => set({ usersOptions: newData }),

  zapierHooks: [],
  setZapierHooks: (newData) => set({ zapierHooks: newData }),
});

const columnStore = (set, get) => ({
  selectedIds: {},
  setSelectedIds: (newData) =>
    set({ selectedIds: newData }, false, "setSelectedIds"),

  unselectedIds: {},
  setUnselectedIds: (newData) =>
    set({ unselectedIds: newData }, false, "setUnselectedIds"),

  selectedColumns: [],
  setSelectedColumns: (newData) =>
    set({ selectedColumns: newData }, false, "setSelectedColumns"),

  reloadAll: false,
  setReloadAll: (newData) => set({ reloadAll: newData }, false, "setReloadAll"),

  currentLoad: [],
  setCurrentLoad: (newData) =>
    set({ currentLoad: newData }, false, "setCurrentLoad"),

  containers: defaultStatuses().map((x) => ({
    ...x,
    items: [],
    total: null,
    loading: false,
    nextPageUrl: null,
    sort: null,
    loadingNext: false,
  })),
  setContainers: (newData) =>
    set({ containers: newData }, false, "setContainers"),
  setContainer: (
    id,
    {
      name,
      color,
      visible,
      items,
      total,
      loading,
      nextPageUrl,
      sort,
      loadingNext,
      loaded,
      needCacheReset,
    }
  ) =>
    set((s) => {
      let newContainers = [...s.containers];
      let index = newContainers.findIndex((x) => x.id === id);
      newContainers[index] = {
        ...newContainers[index],
        name,
        color,
        visible,
        items,
        total,
        loading,
        nextPageUrl,
        sort,
        loadingNext,
        loaded,
        needCacheReset,
      };

      return {
        containers: newContainers,
      };
    }),

  setContainerItem: (containerId, itemId, newData) =>
    set((s) => {
      let newContainers = [...s.containers];
      let index = newContainers.findIndex((x) => x.id === containerId);

      let itemIndex = newContainers[index].items.findIndex(
        (x) => x.id === itemId
      );
      newContainers[index].items[itemIndex] = {
        ...newContainers[index].items[itemIndex],
        ...newData,
      };

      return {
        containers: newContainers,
      };
    }),
});

const prospectDetailStore = (set, get) => ({
  opened: false,
  setOpened: (newData) => set({ opened: newData }, false, "setOpened"),
  handleClose: () =>
    set(
      { opened: false, loading: false, activeTab: null },
      false,
      "handleClose"
    ),

  loading: false,
  setLoading: (newData) => set({ loading: newData }, false, "setLoading"),

  currentContainer: null,
  setCurrentContainer: (newData) =>
    set({ currentContainer: newData }, false, "setCurrentContainer"),

  current: null,
  setCurrent: (newData) => set({ current: newData }, false, "setCurrent"),

  index: null,
  setIndex: (newData) => set({ index: newData }, false, "setIndex"),
  nextProspect: () =>
    set(
      (store) => ({
        index: store.index + 1,
        current: store.currentContainer.items[store.index + 1],
      }),
      false,
      "nextProspect"
    ),
  prevProspect: () =>
    set(
      (store) => ({
        index: store.index - 1,
        current: store.currentContainer.items[store.index - 1],
      }),
      false,
      "prevProspect"
    ),

  activeTab: null,
  setActiveTab: (newData) => set({ activeTab: newData }, false, "setActiveTab"),
});

const tableStore = (set, get) => ({
  newRow: null,
  setNewRow: (newData) => set({ newRow: newData }, false, "setNewRow"),
});

export const usePrmStore = create(prmStore);
export const usePrmColumnStore = create(columnStore);
export const usePrmDetailStore = create(prospectDetailStore);
export const usePrmTableStore = create(tableStore);
