import { ActionIcon, Button, Stack, Tooltip } from "@mantine/core";
import { IconDotsVertical } from "@tabler/icons-react";
import PopOver from "../../../../components/View/PopOver";
import DuplicateRessource from "../../../../components/View/DuplicateRessource";
import CopyToUser from "../../../../components/View/CopyToUser";
import { useLanguage } from "../../../../stores/LanguageStore";
import { getModelTypeProps, parseModelType } from "../modelUtils";
import { useParams } from "react-router-dom";
import SendBAT from "../emails/SendBAT";

const ModelActions = ({ current, inside = false, setLoading }) => {
  const lang = useLanguage((s) => s.language);
  const { modelType } = useParams();

  return (
    <PopOver
      opener={(handleOpen, ref) =>
        inside ? (
          <Button
            ref={ref}
            variant="light"
            color="primary"
            leftSection={<IconDotsVertical size={18} />}
            radius={"sm"}
            ml={"auto"}
            onClick={handleOpen}
          >
            {lang.table.more_actions}
          </Button>
        ) : (
          <Tooltip label={lang.table.more_actions}>
            <ActionIcon
              variant="subtle"
              ref={ref}
              color="primary"
              onClick={handleOpen}
            >
              <IconDotsVertical size={18} />
            </ActionIcon>
          </Tooltip>
        )
      }
      popoverChildren={(handleClose) => (
        <Stack gap={0}>
          <SendBAT inMoreActions model={current} />

          <DuplicateRessource
            current={current}
            onFinish={() => setLoading(true)}
            closeMenu={handleClose}
            handler={
              getModelTypeProps(modelType || parseModelType(current.model_type))
                ?.copyFunction
            }
            responseField={"model_id"}
          />

          <CopyToUser
            current={current}
            onFinish={() => handleClose()}
            closeMenu={handleClose}
            handler={
              getModelTypeProps(modelType || parseModelType(current.model_type))
                ?.copyFunction
            }
            responseField={"model_id"}
          />
        </Stack>
      )}
      anchorOrigin={
        inside
          ? { horizontal: "right", vertical: "bottom" }
          : { horizontal: "left", vertical: "center" }
      }
      transformOrigin={
        inside
          ? { horizontal: "right", vertical: "top" }
          : { horizontal: "right", vertical: "center" }
      }
      sx={{ zIndex: 100 }}
    />
  );
};

export default ModelActions;
