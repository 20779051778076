import { Stack } from "@mantine/core";
import { useFlowStore } from "../../../../../../../stores/WorkflowStore";
import { useMemo } from "react";
import PanelSender from "./PanelSender";
import PanelModel from "./PanelModel";

const NodePanelMain = () => {
  const nodes = useFlowStore((s) => s.nodes);
  const selectedNode = useFlowStore((s) => s.selectedNode);

  const node = useMemo(() => {
    return nodes.find((x) => x.id === selectedNode?.id);
  }, [nodes, selectedNode]);

  return (
    <Stack gap={0} key={node?.id}>
      {!["sms", "smv"].includes(node?.data?.actionType) && (
        <PanelSender node={node} />
      )}

      {node?.data?.actionType !== "linkedin_visit" && (
        <PanelModel node={node} />
      )}
    </Stack>
  );
};

export default NodePanelMain;
