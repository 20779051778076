import {
  gridDensityValueSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { Select } from "@mantine/core";

const TableDensity = () => {
  const lang = useLanguage((s) => s.language);
  const apiRef = useGridApiContext();

  const options = useMemo(() => {
    return [
      { label: lang.table.toolbar.density.compact, value: "compact" },
      { label: lang.table.toolbar.density.standard, value: "standard" },
      { label: lang.table.toolbar.density.comfortable, value: "comfortable" },
    ];
  }, [lang]);

  const onChange = (newValue) => {
    apiRef.current.setDensity(newValue);
  };

  const density = useGridSelector(apiRef, gridDensityValueSelector);
  return (
    <Select
      onChange={(newValue) => {
        onChange(newValue);
      }}
      required
      defaultValue={density}
      data={options}
      label={lang.table.toolbar.density.button_label}
      comboboxProps={{ zIndex: 1600, offset: 1 }}
    />
  );
};

export default TableDensity;
