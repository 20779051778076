import { Group, Stack } from "@mantine/core";
import Path from "../../../../layouts/Path";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import CreateSignature from "./CreateSignature";
import PageTitle from "../../../../components/View/PageTitle";
import { useLanguage } from "../../../../stores/LanguageStore";

const SignaturePageCreate = () => {
  const lang = useLanguage((s) => s.language);
  const navigate = useNavigate();

  const onCreate = useCallback(
    (newItem) => navigate(`/models/email-signatures/?highlight=${newItem.id}`),
    [navigate]
  );

  return (
    <>
      <Group className="layout-block top" justify="space-between">
        <PageTitle title={lang.emails_signature.browser_tab_title} />
      </Group>

      <Stack flex={1} className="layout-block" px={"sm"} pt={"sm"} gap={"xs"}>
        <CreateSignature onCreated={onCreate} />
      </Stack>
    </>
  );
};

export default SignaturePageCreate;
