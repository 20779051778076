import { Group, Select, Text } from "@mantine/core";
import { useProfileStore } from "../../stores/UserStore";
import en from "react-phone-number-input/locale/en.json";
import fr from "react-phone-number-input/locale/fr.json";
import { useMemo } from "react";
import { getCountries } from "react-phone-number-input";
import { useLanguage } from "../../stores/LanguageStore";

const CountrySelector = ({ name, label, defaultValue, noLabel, ...props }) => {
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);
  const labels = profile.language === "FRA" ? fr : en;

  const countryOptions = useMemo(() => {
    return getCountries().map((c) => ({
      value: c,
      label: `${labels[c]} (${c})`,
    }));
  }, [labels]);

  return (
    <Select
      data={countryOptions}
      name={name}
      styles={{
        option: {
          padding: 0,
        },
      }}
      renderOption={(item) => (
        <Group
          bg={item.checked ? "var(--mantine-primary-color-0)" : undefined}
          flex={"1 1 auto"}
          w={0}
          h={"100%"}
          p={8}
          wrap="nowrap"
          gap={8}
        >
          {/* <Flag code={item.option.value} width={20} /> */}
          <Text truncate="end" size="sm">
            {item.option.label}
          </Text>
        </Group>
      )}
      comboboxProps={{ width: 250 }}
      searchable
      label={noLabel ? null : label || lang.profile.country}
      defaultValue={defaultValue}
      {...props}
    />
  );
};

export default CountrySelector;
