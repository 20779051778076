import { Button, Group, Modal, Text, Tooltip } from "@mantine/core";
import { useLanguage } from "../../stores/LanguageStore";
import { IconTrash } from "@tabler/icons-react";
import { useState } from "react";
import { notifications } from "@mantine/notifications";
import { useDisclosure } from "@mantine/hooks";

const SelectionDeleter = ({
  handleMultipleDelete,
  handleDelete,
  ids,
  parentId,
  apiRef,
  onFinish,
  setTableLoading,
  icon,
  buttonTooltip,
  title,
  submitButtonText,
  warningText,
  getParentId,
  setRows,
}) => {
  const lang = useLanguage((s) => s.language);
  const [opened, { open, close }] = useDisclosure();
  const [loading, setLoading] = useState(false);

  const main = () => {
    if (handleMultipleDelete) {
      setLoading(true);
      return handleMultipleDelete({ ids, parentId })
        .then((res) => {
          close();
          notifications.show({
            message: lang.components.deletion_action.dialog.multiple_success,
            color: "tertiary.8",
          });

          if (apiRef) {
            apiRef.current.setRowSelectionModel([]);

            ids.forEach((id) => {
              document.querySelectorAll(`[data-id='${id}']`).forEach((el) => {
                el.style.animation = `forwards 1.5s deletedAnim ease`;
              });
            });
            setTimeout(() => {
              apiRef.current.updateRows(
                ids.map((id) => ({ id, _action: "delete" }))
              );
            }, 750);
          }
          setRows?.((prev) => prev.filter((x) => !ids.includes(x.id)));
          onFinish?.();
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setTableLoading?.(true);
      let requests = [];
      ids?.forEach((id) => {
        requests.push(
          new Promise((resolve, reject) => {
            handleDelete({ id, parent_id: getParentId?.(id) || parentId }).then(
              (res) => {
                if (apiRef) {
                  document
                    .querySelectorAll(`[data-id='${id}']`)
                    .forEach((el) => {
                      el.style.animation = `forwards 1.5s deletedAnim ease`;
                    });
                  setTimeout(() => {
                    apiRef.current.updateRows([
                      { id, _action: "delete" },
                      { id: `#${id}`, _action: "delete" },
                    ]);
                  }, 750);
                }
                setRows?.((prev) => prev.filter((x) => !ids.includes(x.id)));
                onFinish?.();
                resolve();
              }
            );
          })
        );
      });

      Promise.all(requests).finally(() => {
        if (apiRef) {
          apiRef.current.setRowSelectionModel([]);
        }
        setTableLoading?.(false);
      });
      close();
    }
  };

  return (
    <>
      <Tooltip
        label={buttonTooltip || lang.table.toolbar.bulk_deletion.button_tooltip}
      >
        <Button
          className="toolbar-action-button"
          variant="light"
          color="red"
          disabled={ids.length === 0}
          onClick={open}
        >
          {icon || <IconTrash size={18} />}
        </Button>
      </Tooltip>

      <Modal
        opened={opened}
        onClose={close}
        title={
          title || (
            <Text size="md" truncate="end">
              {lang.components.deletion_action.title}{" "}
              <b>
                {" "}
                {ids.length} {lang.components.deletion_action.elements}{" "}
              </b>
            </Text>
          )
        }
        centered
        styles={{
          content: {
            boxShadow: "red 0 0 6px",
            border: "1px solid #a00",
          },
          title: {
            maxWidth: "92%",
          },
        }}
      >
        {warningText || (
          <Text size="sm">
            {lang.components.deletion_action.dialog.multiple_text} <br />
            <b>{lang.components.deletion_action.dialog.warning_text}</b>
          </Text>
        )}

        <Group justify="end" mt={"sm"}>
          <Button variant="subtle" onClick={close} disabled={loading}>
            {lang.components.deletion_action.dialog.cancel_button}
          </Button>
          <Button color="red" onClick={main} loading={loading}>
            {submitButtonText ||
              lang.components.deletion_action.dialog.submit_button}
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export default SelectionDeleter;
