import {
  Button,
  Group,
  Paper,
  Pill,
  PillsInput,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { IconEdit, IconEye, IconTrash } from "@tabler/icons-react";
import ItemDeleter from "../../../../../components/TableComponents/ItemDeleter";
import { deleteSharing } from "../../../../../api/PRMAPI";
import LeadsSharingEdit from "./LeadsSharingEdit";

const LeadsSharingItem = ({ share, handleReload }) => {
  const lang = useLanguage((s) => s.language);

  // const [loading, setLoading] = useState(false);

  const types = [
    {
      value: "User",
      label: lang.components.sharing.types.user,
    },
    {
      value: "Team",
      label: lang.components.sharing.types.team,
    },
    {
      value: "Organization",
      label: lang.components.sharing.types.org,
    },
  ];

  // const handleFilterChange = ({ filter, closePopover }) => {
  //   setLoading(true);

  //   closePopover();
  //   editSharing({
  //     id: share.id,
  //     filter: filter === null ? { mode: "and", values: [] } : filter,
  //     ids_list: share?.sharing_targets?.map((x) => x.id) || [],
  //     ...share,
  //   })
  //     .then((response) => {
  //       setItems((prev) => {
  //         let newItems = [...prev];
  //         let i = newItems.findIndex((x) => x.id === share.id);

  //         newItems[i] = {
  //           ...newItems[i],
  //           filter: filter === null ? { mode: "and", values: [] } : filter,
  //         };

  //         return newItems;
  //       });
  //       notifications.show({
  //         message: "Filter changed",
  //         color: "tertiary.8",
  //       });
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  return (
    <Paper withBorder p={"xs"} component={Stack}>
      <Group wrap="nowrap">
        <Text fw={600} mr={"auto"}>
          {" "}
          {share.name}{" "}
        </Text>

        <LeadsSharingEdit share={share} handleDone={handleReload} />

        <ItemDeleter
          deletedMessage={lang.prm.sharing.deleted}
          title_bold={`${share.name} #${share.id}`}
          handleDelete={deleteSharing}
          id={share.id}
          onFinish={handleReload}
        />
      </Group>
      <Group wrap="nowrap">
        <Text w={100}>
          {types.find((x) => x.value === share.sharing_type_to)?.label}s :
        </Text>
        <PillsInput>
          <Pill.Group>
            {share.sharing_targets?.map((item) => (
              <Pill key={item.id}>
                {item?.name || item?.first_name} #{item.id}
              </Pill>
            ))}
          </Pill.Group>
        </PillsInput>
      </Group>
      <Group>
        <Text w={100}>{lang.components.sharing.permissions.label}</Text>

        <Group wrap="nowrap" gap={"xs"} mt={4}>
          <Tooltip label={lang.components.sharing.permissions.read}>
            <Button variant="default" px={6} size="xs" disabled>
              <IconEye size={16} />
            </Button>
          </Tooltip>

          <Tooltip label={lang.components.sharing.permissions.write}>
            <Button
              color="tertiary.8"
              variant={share?.write_permission ? "filled" : "outline"}
              px={6}
              size="xs"
            >
              <IconEdit size={16} />
            </Button>
          </Tooltip>

          <Tooltip label={lang.components.sharing.permissions.delete}>
            <Button
              color="tertiary.8"
              variant={share?.delete_permission ? "filled" : "outline"}
              px={6}
              size="xs"
            >
              <IconTrash size={16} />
            </Button>
          </Tooltip>
        </Group>
      </Group>

      {/* <PopOver
        opener={(openFunction, ref) => {
          return (
            <Button
              ref={ref}
              variant="default"
              onClick={openFunction}
              id={"sharing-filter-" + "creation"}
              w={"100%"}
              loading={loading}
            >
              <Indicator
                color="red"
                offset={2}
                disabled={share?.filter?.values?.length === 0 || !share?.filter}
                label={share?.filter?.values?.length}
                size={16}
              >
                <IconFilter size={18} />
              </Indicator>
              <span style={{ marginLeft: 6 }}>
                {lang.table.toolbar.filter.main_button_label}
              </span>
            </Button>
          );
        }}
        popoverChildren={(closePopover) => (
          <FilterContent
            fields={filters}
            id={"sharing-" + share.id}
            isClient={false}
            pageName={"prm"}
            maxHeight={350}
            mainFilter={share.filter}
            defaultFiltersClose
            getFieldOptionName={(f) => f.option_name}
            setMainFilter={(f) =>
              handleFilterChange({ filter: f, closePopover })
            }
          />
        )}
        elevation={3}
      /> */}
    </Paper>
  );
};

export default LeadsSharingItem;
