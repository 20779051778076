import { Button, Group, Stack, Text, TextInput } from "@mantine/core";
import { IconArrowRight } from "@tabler/icons-react";
import { useLanguage } from "../../../../stores/LanguageStore";

const Step1 = ({ setStep, setName, name }) => {
  const lang = useLanguage((s) => s.language);
  const handleSubmit = (e) => {
    e.preventDefault();

    setStep(2);
  };

  return (
    <Stack component={"form"} onSubmit={handleSubmit} w={"50%"}>
      <Text fz={24} className={`animate__animated animate__fadeIn`}>
        {lang.resellers.onboarding.step1.title}
      </Text>

      <TextInput
        size="xl"
        placeholder={lang.resellers.onboarding.step1.name}
        value={name}
        onChange={(e) => setName(e.target.value)}
        className={`animate__animated animate__fadeIn`}
        style={{ animationDelay: ".1s" }}
        autoFocus
        required
      />

      <Group justify="space-between" w={"100%"} mt={"sm"}>
        <Button variant="subtle" onClick={() => setStep(0)}>
          {lang.global.cancel}
        </Button>
        <Button
          rightSection={<IconArrowRight />}
          className={`animate__animated animate__fadeIn`}
          style={{ animationDelay: ".3s" }}
          variant="gradient"
          type="submit"
        >
          {lang.global.next}
        </Button>
      </Group>
    </Stack>
  );
};

export default Step1;
