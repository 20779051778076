import { useState } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useDisclosure } from "@mantine/hooks";
import {
  ActionIcon,
  Button,
  Group,
  Modal,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import BackButton from "../../../../components/View/BackButton";
import { IconRefreshDot } from "@tabler/icons-react";
import {
  refreshList,
  refreshTargetingLinkedin,
} from "../../../../api/ContactAPI";
import { notifications } from "@mantine/notifications";
import { IconLinkedin } from "../../../../components/Icons/IconLinkedin";
import LinkedinAccountSelector from "../../../../components/Selectors/LinkedinAccountSelector";

const ListRefreshTargeting = ({ current, onFinish, closeMenu, inJob }) => {
  const lang = useLanguage((s) => s.language);
  const [opened, { open, close }] = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    refreshTargetingLinkedin({
      contact_list_id: current.id,
      linkedin_account_id: account,
    })
      .then((res) => {
        notifications.show({
          message: lang.contact_lists.refresh_targeting.success,
          color: "tertiary.8",
        });
        close();
        onFinish?.();
      })
      .finally(() => setLoading(false));
  };

  const handleClose = () => {
    close();
    closeMenu?.();
  };

  if (!current.state_details.find((x) => x.type.includes("inked"))) {
    return "";
  }
  return (
    <>
      {inJob ? (
        <Tooltip label={lang.contact_lists.refresh_targeting.redo}>
          <ActionIcon variant="subtle" onClick={open}>
            <IconRefreshDot size={18} />
          </ActionIcon>
        </Tooltip>
      ) : (
        <Button
          onClick={open}
          leftSection={<IconLinkedin size={18} />}
          fw={600}
          color="primary"
          variant="subtle"
          justify="start"
        >
          {lang.contact_lists.refresh_targeting.label}
        </Button>
      )}

      {opened && (
        <Modal
          opened={opened}
          size={"lg"}
          onClose={handleClose}
          title={
            <>
              {lang.contact_lists.refresh_targeting.dialog.title}{" "}
              <b>
                {current?.name} #{current.id}
              </b>
            </>
          }
        >
          <Stack component={"form"} onSubmit={handleSubmit} gap={"xs"}>
            <Text c={"dimmed"}>
              {lang.contact_lists.refresh_targeting.dialog.text}
            </Text>

            <LinkedinAccountSelector
              label={
                lang.contact_lists.refresh_targeting.dialog.linkedin_account
              }
              required
              value={account}
              onChange={(v) => setAccount(v?.id || null)}
              size={"sm"}
            />

            <Group justify="end" mt={"md"}>
              <BackButton onClick={handleClose} />

              <Button
                leftSection={<IconRefreshDot size={18} />}
                loading={loading}
                color="primary"
                type="submit"
              >
                {lang.contact_lists.refresh_targeting.dialog.submit_button}
              </Button>
            </Group>
          </Stack>
        </Modal>
      )}
    </>
  );
};

export default ListRefreshTargeting;
