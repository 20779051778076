import { ActionIcon, Button, Tooltip } from "@mantine/core";
import {
  IconArrowBarToUp,
  IconArrowBarUp,
  IconPlus,
} from "@tabler/icons-react";
import { Link, useParams } from "react-router-dom";
import { useLanguage } from "../../../../stores/LanguageStore";

const CreateListMenu = ({ current, isSearch }) => {
  const lang = useLanguage((s) => s.language);
  const { contactListId } = useParams();

  current = current || contactListId ? { id: parseInt(contactListId) } : null;

  return contactListId ? (
    <Button
      leftSection={<IconArrowBarToUp size={18} />}
      radius={"sm"}
      color="primary"
      component={Link}
      to={"import"}
    >
      {current
        ? lang.contact_lists.import.button_label_import
        : lang.contact_lists.import.button_label}
    </Button>
  ) : current ? (
    <Tooltip label={lang.contact_lists.import.button_label}>
      <ActionIcon variant="subtle" component={Link} to={"import"}>
        <IconArrowBarUp size={16} />
      </ActionIcon>
    </Tooltip>
  ) : (
    <Button
      component={Link}
      to={isSearch ? "/contact-lists/import" : "import"}
      leftSection={<IconPlus />}
      radius={"sm"}
      // size="md"
      // fz={13}
      // h={40}
      // variant="light"
      color="primary"
    >
      {lang.contact_lists.import.button_label}
    </Button>
  );
};

export default CreateListMenu;
