import { Group, Indicator, Text, ThemeIcon, Tooltip } from "@mantine/core";
import { useMemo } from "react";
import { useProfileStore } from "../../../../../../../stores/UserStore";
import { useLanguage } from "../../../../../../../stores/LanguageStore";
import {
  IconClick,
  IconEyeCheck,
  IconMessage,
  IconMessageCancel,
  IconMessageCheck,
  IconUserPlus,
} from "@tabler/icons-react";

const ScoreCell = ({ row }) => {
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);
  const defaultScore = useMemo(() => {
    if (profile?.prm_scoring) return profile.prm_scoring;
    return {
      link_click: 10,
      open: 10,
      answer: 10,
      positive_answer: 50,
      negative_answer: -10,
      invitation_accepted: 30,
    };
  }, [profile]);

  const icons = {
    link_click: <IconClick size={18} />,
    open: <IconEyeCheck size={18} />,
    answer: <IconMessage size={18} />,
    positive_answer: <IconMessageCheck size={18} />,
    negative_answer: <IconMessageCancel size={18} />,
    invitation_accepted: <IconUserPlus size={18} />,
  };

  return (
    <Group gap={"sm"}>
      {Object.keys(defaultScore).map((key) => {
        let field = Object.keys(row).find((x) => x.includes(key));
        let count = row[field];

        return (
          <Tooltip
            key={key}
            label={`${lang.prm.details.campaigns_table.scores_count[key]} : ${count}`}
          >
            <Indicator
              label={count}
              disabled={!count}
              size={"xs"}
              offset={4}
              color="red"
            >
              <ThemeIcon
                variant="light"
                color={count > 0 ? "primary" : "gray"}
                size={"md"}
              >
                {icons[key]}
              </ThemeIcon>
            </Indicator>
          </Tooltip>
        );
      })}

      <Text>=</Text>
      <Text fw={600}>{row.score}</Text>
    </Group>
  );
};

export default ScoreCell;
