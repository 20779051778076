import FlowEdge from "./edges/FlowEdge";
import { useFlowStore } from "../../../../../stores/WorkflowStore";
import "reactflow/dist/style.css";
import ReactFlow, {
  Background,
  Controls,
  Panel,
  ReactFlowProvider,
} from "reactflow";
import StartNode from "./nodes/StartNode";
import { useEffect } from "react";
import TrailNode from "./nodes/TrailNode";
import TrailEdge from "./edges/TrailEdge";
import { handleLayout } from "./layouter";
import { nodeWidth } from "./flowSettings";
import FlowConfig from "./components/FlowConfig";
import { Flex } from "@mantine/core";
import NodeActionPanel from "./panel/NodeActionPanel/NodeActionPanel";
import BaseNode from "./nodes/BaseNode";
import "./flowStyles.scss";

const edgeTypes = {
  main: FlowEdge,
  trail: TrailEdge,
};

const nodeTypes = {
  start: StartNode,
  trail: TrailNode,
  action: BaseNode,
};

const Flow = ({
  handleSubmit,
  setLoadingPage,
  reactFlowInstance,
  setReactFlowInstance,
  setShouldWarn,
}) => {
  const nodes = useFlowStore((s) => s.nodes);
  const edges = useFlowStore((s) => s.edges);

  const handleNodesChange = (change) => {
    setShouldWarn?.(true);
  };

  const handleNodeClick = (e, node) => {
    setShouldWarn?.(true);
    return false;
  };

  useEffect(() => {
    handleLayout();
    setTimeout(() => {
      let nodes = useFlowStore.getState().nodes;
      let n = nodes.find((x) => x.data?.source === "0");
      reactFlowInstance?.setCenter(
        n?.x + 200,
        n?.y + window.innerHeight / 2 - n?.height - 40,
        { duration: 500, zoom: 1 }
      );
    }, 100);
  }, [reactFlowInstance]);

  return (
    <Flex h={"100%"} style={{ overflow: "hidden" }}>
      <ReactFlowProvider>
        <ReactFlow
          id="workflow"
          nodes={nodes}
          nodeTypes={nodeTypes}
          edges={edges}
          onInit={setReactFlowInstance}
          onNodesChange={handleNodesChange}
          connectOnClick={false}
          panOnScroll
          zoomOnScroll={false}
          preventScrolling
          // fitView
          defaultEdgeOptions={{
            type: "main",
            deletable: false,
            focusable: false,
            updatable: false,
          }}
          edgeTypes={edgeTypes}
          disableKeyboardA11y
          translateExtent={[
            [-Infinity, 32],
            [Infinity, Infinity],
          ]}
          nodeExtent={[
            [-Infinity, 32],
            [Infinity, Infinity],
          ]}
          defaultViewport={{
            x: window.innerWidth / 2 - nodeWidth - 40,
            y: 180,
            zoom: 1,
          }}
          nodesDraggable={false}
          // nodesFocusable={false}
          proOptions={{
            hideAttribution: true,
          }}
          maxZoom={1}
          edgesUpdatable={false}
          edgesFocusable={false}
          onNodeClick={handleNodeClick}
        >
          <Panel position="top-left">
            <FlowConfig
              handleSubmit={handleSubmit}
              setLoadingPage={setLoadingPage}
            />
          </Panel>

          <Background />
          <Controls position="bottom-right" showInteractive={false} />
        </ReactFlow>
      </ReactFlowProvider>

      <NodeActionPanel reactFlowInstance={reactFlowInstance} />
    </Flex>
  );
};

export default Flow;
