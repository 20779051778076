import { Button, Group, Loader, Text, ThemeIcon, Tooltip } from "@mantine/core";
import { IconLinkedin } from "../../../../components/Icons/IconLinkedin";
import { IconMail, IconPlus, IconUserSearch } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useProfileStore } from "../../../../stores/UserStore";
import { useEffect, useMemo, useState } from "react";
import { updateSelf } from "../../../../api/UserAPI";

const ResellerInfo = ({ reseller }) => {
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);

  const [loading, setLoading] = useState(false);

  const linkedinAccounts = useMemo(() => {
    return profile?.permissions?.find(
      (x) => x.name === "maxIntegrationsLinkedin"
    );
  }, [profile]);
  const emails = useMemo(() => {
    return profile?.permissions?.find(
      (x) => x.name === "maxIntegrationsEmailSMTP"
    );
  }, [profile]);
  const enrichments = useMemo(() => {
    return profile?.permissions?.find(
      (x) => x.name === "maxEmailsGeneratedPerMonth"
    );
  }, [profile]);

  useEffect(() => {
    setLoading(true);
    updateSelf(true).finally(() => setLoading(false));
  }, []);

  if (profile?.level !== "admin" && profile.id !== reseller?.created_by) {
    return "";
  }

  return (
    <Group gap={"lg"}>
      <Text mt={4} size="sm">
        {lang.resellers.info.dispose}
      </Text>
      <Tooltip
        label={`${lang.resellers.info.assign} ${linkedinAccounts?.value || 0} ${
          lang.resellers.info.linkedin
        }`}
      >
        <Group
          gap={"xs"}
          style={{ border: "1px solid #00000016", borderRadius: 6 }}
          px={"sm"}
          mt={4}
        >
          <Text fw={600}>{linkedinAccounts?.value || 0}</Text>
          <span>/</span>
          <Text fw={600}>{linkedinAccounts?.default_value || 0}</Text>
          {loading ? (
            <Loader size={14} />
          ) : (
            <ThemeIcon variant="subtle">
              <IconLinkedin size={18} />
            </ThemeIcon>
          )}
        </Group>
      </Tooltip>

      <Tooltip
        label={`${lang.resellers.info.assign} ${emails?.value || 0} ${
          lang.resellers.info.emails
        }`}
      >
        <Group
          gap={"xs"}
          style={{ border: "1px solid #00000016", borderRadius: 6 }}
          px={"sm"}
          mt={4}
        >
          <Text fw={600}>{emails?.value || 0}</Text>
          <span>/</span>
          <Text fw={600}>{emails?.default_value || 0}</Text>

          {loading ? (
            <Loader size={14} />
          ) : (
            <ThemeIcon variant="subtle">
              <IconMail size={20} />
            </ThemeIcon>
          )}
        </Group>
      </Tooltip>

      <Tooltip
        label={`${lang.resellers.info.assign} ${enrichments?.value || 0} ${
          lang.resellers.info.credits
        }`}
      >
        <Group
          gap={"xs"}
          style={{ border: "1px solid #00000016", borderRadius: 6 }}
          px={"sm"}
          mt={4}
        >
          <Text fw={600}>{enrichments?.value || 0}</Text>
          <span>/</span>
          <Text fw={600}>{enrichments?.default_value || 0}</Text>
          {loading ? (
            <Loader size={14} />
          ) : (
            <ThemeIcon variant="subtle">
              <IconUserSearch size={20} />
            </ThemeIcon>
          )}
        </Group>
      </Tooltip>

      <Button
        size="compact-xs"
        component={Link}
        to={"/account-settings/subscription"}
        mt={4}
        ml={8}
        mr={8}
        leftSection={<IconPlus size={16} />}
        variant="light"
        color="primary"
      >
        {lang.resellers.info.add_more}
      </Button>
    </Group>
  );
};

export default ResellerInfo;
