import { ActionIcon, Avatar, Loader, Popover, Tooltip } from "@mantine/core";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { useMemo, useState } from "react";
import UserSelector from "../../../../../components/UserSelector/UserSelector";
import {
  useLeadsContainerStore,
  useLeadsSheetStore,
  useLeadsStore,
} from "../../../../../stores/LeadsStore";
import { updateProspect } from "../../../../../api/PRMAPI";
import { notifications } from "@mantine/notifications";

const PersonInCharge = ({ current, inside }) => {
  const lang = useLanguage((s) => s.language);
  const showPersonInCharge = useLeadsStore((s) => s.showPersonInCharge);
  const currentSheet = useLeadsSheetStore((s) => s.current);
  const setCurrentSheet = useLeadsSheetStore((s) => s.setCurrent);
  const updateContainerItem = useLeadsContainerStore(
    (s) => s.updateContainerItem
  );
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const person = useMemo(() => current?.person_in_charge, [current]);

  const handleChange = (v) => {
    setLoading(true);

    updateProspect({ id: current.id, person_in_charge: v?.id || null })
      .then((response) => {
        notifications.show({
          message: lang.prm.person_in_charge_saved,
          color: "tertiary.8",
        });
        setOpened(false);

        if (inside) {
          setCurrentSheet({
            ...currentSheet,
            person_in_charge: v?.id
              ? {
                  first_name: v?.first_name,
                  last_name: v?.last_name,
                  name: v?.first_name + " " + v?.last_name,
                  id: v?.id,
                }
              : null,
          });
        }
        updateContainerItem(
          current?.custom_status || current?.status,
          current.id,
          {
            person_in_charge: v?.id
              ? {
                  first_name: v?.first_name,
                  last_name: v?.last_name,
                  name: v?.first_name + " " + v?.last_name,
                  id: v?.id,
                }
              : null,
          }
        );
      })
      .finally(() => setLoading(false));
  };

  if (!showPersonInCharge && !inside) {
    return;
  }

  return (
    <Popover
      opened={opened}
      onChange={setOpened}
      zIndex={inside ? 1500 : 100}
      withinPortal={true}
      withArrow
      offset={2}
    >
      <Popover.Target>
        <Tooltip
          label={
            lang.prm.filter_labels.person_in_charge +
            (current?.person_in_charge
              ? `: ${current?.person_in_charge?.first_name} ${current?.person_in_charge?.last_name} #${current?.person_in_charge?.id}`
              : ": -")
          }
        >
          <ActionIcon
            size={"xs"}
            variant="subtle"
            className="lead-item-button"
            ml={"auto"}
            radius={"sm"}
            h={inside ? 32 : 20}
            w={inside ? 32 : 20}
            mt={inside ? 0 : -4}
            onClick={() => setOpened(true)}
            mr={inside ? "auto" : 0}
          >
            <Avatar
              radius={"sm"}
              size={inside ? "md" : "sm"}
              styles={{
                placeholder: {
                  color: person ? "white" : undefined,
                  fontSize: 10,
                  marginTop: inside ? 0.5 : -0.5,
                },
              }}
              {...stringAvatar(
                person
                  ? (person?.first_name || "-") + " " + (person?.last_name || "-")
                  : null
              )}
            />
          </ActionIcon>
        </Tooltip>
      </Popover.Target>

      <Popover.Dropdown p={"xs"} pt={4}>
        <UserSelector
          label={lang.prm.filter_labels.person_in_charge}
          placeholder={lang.prm.filter_labels.person_in_charge}
          value={
            person
              ? { ...person, name: person.first_name + " " + person.last_name }
              : null
          }
          onChange={handleChange}
          withinPortal={false}
          withSelf
          leftSection={loading ? <Loader size={12} /> : null}
          autoFocus={!person}
          clearable
        />
      </Popover.Dropdown>
    </Popover>
  );
};

function stringToColor(string = "") {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

function stringAvatar(name = "") {
  if (!name) {
    return {};
  }
  return {
    style: {
      background: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

export default PersonInCharge;
