import { Badge, Flex, Group, Paper, Text, Tooltip } from "@mantine/core";
import { useLanguage } from "../../../../stores/LanguageStore";
import { IconCalendar, IconMail } from "@tabler/icons-react";
import { parseDate, parseDateFuture } from "../../../../functions/dates";

const ReferredUser = ({ item }) => {
  const lang = useLanguage((s) => s.language);
  return (
    <Paper p={12} withBorder w={"48%"}>
      <Group gap={"xs"}>
        <Text fw={600} mr="auto" truncate flex={1}>
          {item.last_name} {item.first_name}
        </Text>
        <span style={{ opacity: 0.5, ml: "auto" }}>#{item.id}</span>
      </Group>
      <Group mt={6} justify={"space-between"}>
        <Group flex={1} gap={"xs"}>
          <Tooltip label={lang.referral.account_email}>
            <IconMail size={14} stroke={1} />
          </Tooltip>
          <Text truncate="end" flex={1} size="sm">
            {item.email}
          </Text>
        </Group>
        {item.subscriptions.active && !item.subscriptions.trial ? (
          <Tooltip
            label={`${lang.referral.end_date} ${parseDateFuture(
              item.subscriptions.end_date
            )}`}
          >
            <Badge color={"tertiary.8"} size="xs" fz={8}>
              {lang.referral.active}
            </Badge>
          </Tooltip>
        ) : (
          <Tooltip
            label={
              item.subscriptions.trial
                ? `${lang.referral.trial} ${parseDateFuture(
                    item.subscriptions.end_date
                  )}`
                : lang.referral.free_version
            }
          >
            <Badge color={"gray.4"} size="xs" fz={8}>
              {lang.referral.not_active}
            </Badge>
          </Tooltip>
        )}
      </Group>
      <Flex align={"center"} mt={6} justify={"space-between"}>
        <Group maw={"70%"} gap={"xs"}>
          <Tooltip label={lang.referral.signup_date}>
            <IconCalendar stroke={1} size={14} />
          </Tooltip>
          <Tooltip label={new Date(item.created_on).toLocaleString()}>
            <Text width={"fit-content"} fz={12} style={{ opacity: 0.5 }}>
              {parseDate(item.created_on)}
            </Text>
          </Tooltip>
        </Group>

        <Text style={{ opacity: 0.5 }}>
          {item.subscriptions.monthly_amount || 0}
          {lang.referral.per_month}
        </Text>
      </Flex>
    </Paper>
  );
};

export default ReferredUser;
