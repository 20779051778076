import {
  Group,
  InputDescription,
  InputLabel,
  Select,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { useMemo, useRef } from "react";
import { getCountries } from "react-phone-number-input";
import Input from "react-phone-number-input/input";
import { getCountryCallingCode } from "react-phone-number-input";
import { useLanguage } from "../../stores/LanguageStore";

const PhoneNumberInputControlled = ({
  name,
  label,
  required,
  id,
  error,
  placeholder,
  size,
  value,
  setValue,
  country,
  setCountry,
  withCountrySelector,
  ...props
}) => {
  const lang = useLanguage((s) => s.language);

  const ref = useRef();

  const countryOptions = useMemo(() => {
    return getCountries().map((c) => ({
      value: c,
      label: `${c} +${getCountryCallingCode(c)}`,
    }));
  }, []);

  const onCountryChange = (c) => {
    setCountry(c);
    setValue(null);
    ref.current.focus();
  };

  return (
    <Stack gap={0} {...props}>
      <InputLabel required={required} size={size}>
        {" "}
        {label}{" "}
      </InputLabel>
      <InputDescription mb={4} mt={2}>
        {lang.profile.with_zero}
      </InputDescription>
      <Group gap={0} wrap="nowrap">
        {withCountrySelector && (
          <Select
            data={countryOptions}
            value={country}
            name={name + "_country"}
            autoComplete="off"
            onChange={onCountryChange}
            rightSectionWidth={0}
            miw={75}
            w={75}
            styles={{
              input: {
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              },
              option: {
                padding: 0,
              },
            }}
            renderOption={(item) => (
              <Group
                bg={item.checked ? "var(--mantine-primary-color-0)" : undefined}
                flex={"1 1 auto"}
                w={0}
                h={"100%"}
                p={8}
                wrap="nowrap"
                gap={8}
              >
                <Text truncate="end" size="sm">
                  {item.option.value} +
                  {getCountryCallingCode(item.option.value)}
                </Text>
              </Group>
            )}
            comboboxProps={{ width: 100 }}
            searchable
            error={error}
            size={size}
          />
        )}
        <Input
          ref={ref}
          country={country}
          inputComponent={TextInput}
          autoComplete="mobile_phone phone"
          onChange={(v) => setValue(v)}
          value={value}
          required={required}
          placeholder={placeholder || label}
          size={size}
          w={"100%"}
          styles={{
            input: {
              borderTopLeftRadius: withCountrySelector ? 0 : 4,
              borderBottomLeftRadius: withCountrySelector ? 0 : 4,
              borderLeftWidth: withCountrySelector ? 0 : 1,
            },
          }}
          name={name}
          error={error}
          id={id}
        />
      </Group>
    </Stack>
  );
};

export default PhoneNumberInputControlled;
