import { Anchor, Group, Paper, Stack, Text, Title } from "@mantine/core";
import { IconFileDescription } from "@tabler/icons-react";
import { Link } from "react-router-dom";

const GroupPage = ({ icon, title, desc, items }) => {
  const GroupIcon = icon;

  return (
    <>
      <Paper component={Stack} withBorder radius={"md"} p={"md"} pb={"lg"}>
        <Group w={"50%"} mx={"auto"}>
          <GroupIcon size={32} />
          <Title order={3} ta={"center"}>
            {title}
          </Title>
        </Group>

        <Text c={"dimmed"} w={"50%"} mx={"auto"}>
          {desc}
        </Text>
        {items.map((item) => (
          <Anchor
            component={Link}
            ta={"center"}
            key={item.url}
            to={item.url}
            fz={18}
            underline="never"
          >
            <Paper
              component={Group}
              justify="start"
              withBorder
              p={"md"}
              mx={"auto"}
              className="group-item"
              w={"50%"}
            >
              <IconFileDescription />
              <span>{item.title}</span>
            </Paper>
          </Anchor>
        ))}
      </Paper>
    </>
  );
};

export default GroupPage;
