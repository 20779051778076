import { useEffect, useState } from "react";
import { useLanguage } from "../../../stores/LanguageStore";
import { useDebouncedValue } from "@mantine/hooks";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import { ActionIcon, TextInput } from "@mantine/core";
import { IconSearch, IconX } from "@tabler/icons-react";

const TableSearch = ({
  noRadiusLeft,
  defaultSearch,
  disabled,
}) => {
  const lang = useLanguage((s) => s.language);
  const [value, setValue] = useState(defaultSearch || "");
  const [debounced] = useDebouncedValue(value, 1000);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleClear = () => {
    setValue("");
  };

  const apiRef = useGridApiContext();

  useEffect(() => {
    if (debounced.length > 2) {
      apiRef.current.setQuickFilterValues(debounced.split(" "));
    }
    if (debounced.length === 0) {
      apiRef.current.setQuickFilterValues([]);
    }
  }, [apiRef, debounced, value]);

  return (
    <TextInput
      placeholder={lang.table.toolbar.search_placeholder}
      w={180}
      value={value}
      radius={0}
      style={{
        borderTop: 0,
      }}
      styles={{
        input: {
          borderTopLeftRadius: noRadiusLeft ? 0 : 4,
          paddingLeft: 14,
          borderTop: 0,
          borderLeft: 0,
        },
      }}
      onChange={handleChange}
      rightSection={
        debounced.length > 0 ? (
          <ActionIcon onClick={handleClear} variant="subtle">
            <IconX size={18} />
          </ActionIcon>
        ) : (
          <IconSearch size={18} />
        )
      }
      disabled={disabled}
    />
  );
};

export default TableSearch;
