import {
  Button,
  Checkbox,
  Group,
  Paper,
  Radio,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import {
  IconChevronLeft,
  IconFoldUp,
  IconListDetails,
} from "@tabler/icons-react";
import { useMemo, useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import UnsubImportStep from "../../../exclusions/unsubs/UnsubImportStep";
import BlacklistImportStep from "../../../exclusions/blacklists/BlacklistImportStep";
import { useRouteLoaderData } from "react-router-dom";
import CreatableContactListSelector from "../../../../../components/Selectors/CreatableContactListSelector";

const StepImportSettings = ({
  file,
  data,
  fields,
  handlePrev,
  handleImport,
  isUnsub,
  setImporting,
  onFinish,
  isBlacklist,
}) => {
  const lang = useLanguage((s) => s.language);
  const [name, setName] = useState(
    file?.name?.substring(0, file?.name.lastIndexOf(".")) || ""
  );
  const [ignoreContactErrors, setIgnoreContactErrors] = useState(true);
  const [enrich, setEnrich] = useState(false);
  const listDataLoader = useRouteLoaderData("contact-list");
  const listDataFolderLoader = useRouteLoaderData("folder-contact-list");
  const listProfile = useMemo(() => {
    return listDataLoader || listDataFolderLoader;
  }, [listDataLoader, listDataFolderLoader]);
  const [list, setList] = useState(listProfile);
  const [listState, setListState] = useState(listProfile ? 2 : 1);

  const mappedColumns = useMemo(() => {
    let statFields = {};
    Object.keys(fields || {}).forEach((field) => {
      if (Object.keys(data[0])?.includes(field)) {
        statFields = { ...statFields, [field]: fields[field] };
      }
    });

    return Object.values(statFields).filter((x) => !!x).length;
  }, [data, fields]);

  return (
    <Paper
      component={Stack}
      justify="center"
      w={800}
      radius={"lg"}
      mx={"auto"}
      p={"xl"}
      mt={32}
    >
      {isUnsub ? (
        <UnsubImportStep
          data={data}
          handlePrev={handlePrev}
          setImporting={setImporting}
          onFinish={onFinish}
        />
      ) : isBlacklist ? (
        <BlacklistImportStep
          data={data}
          handlePrev={handlePrev}
          setImporting={setImporting}
          onFinish={onFinish}
          fields={fields}
          file={file}
        />
      ) : (
        <>
          <Text size="lg">
            <b>{data?.length}</b>{" "}
            {lang.contact_lists.import.step_importing.text1}{" "}
            <b>
              {mappedColumns} {lang.contact_lists.import.step_importing.text2}
            </b>
          </Text>

          <Checkbox
            label={lang.contact_lists.import.step_importing.enrich}
            description={lang.contact_lists.import.step_importing.enrich_desc}
            checked={enrich}
            onChange={(e) => setEnrich(e.target.checked)}
          />
          <Checkbox
            label={lang.contact_lists.import.step_importing.ignore_error}
            defaultChecked
            description={lang.contact_lists.import.step_importing.ignore_desc}
            checked={ignoreContactErrors}
            onChange={(e) => setIgnoreContactErrors(e.target.checked)}
          />
          <Group wrap="nowrap" align="end" gap={"xl"}>
            <Stack w="100%" gap={4}>
              <Radio
                label={lang.targeting_linkedin_google.create_new_list}
                name="list_state"
                checked={listState === 1}
                size="sm"
                onChange={(e) => setListState(1)}
              />
              <TextInput
                id="new-list-name"
                name="contact_list_name"
                value={name}
                w={"100%"}
                disabled={listState !== 1}
                styles={{ label: { fontSize: 13 } }}
                label={lang.targeting_linkedin_google.list_name}
                placeholder={lang.targeting_linkedin_google.list_name}
                onChange={(e) => setName(e.target.value)}
                required={listState === 1}
              />
            </Stack>
            <Stack w="100%" gap={4}>
              <Radio
                label={lang.targeting_linkedin_google.import}
                name="list_state"
                size="sm"
                checked={listState === 2}
                onChange={(e) => setListState(2)}
              />
              <CreatableContactListSelector
                value={list}
                setValue={setList}
                label={lang.targeting_linkedin_google.select_list}
                placeholder={lang.contact_lists.create.dialog.desc}
                w={"100%"}
                disabled={listState !== 2}
                styles={{ label: { fontSize: 13 } }}
                required={listState === 2}
                leftSection={<IconListDetails size={16} />}
                disableCreate
                description={" "}
              />
            </Stack>
          </Group>

          <Group justify="center" mt={"lg"}>
            <Button
              variant="subtle"
              onClick={handlePrev}
              leftSection={<IconChevronLeft />}
              size="md"
              w={250}
            >
              {lang.global.back}
            </Button>
            <Button
              disabled={listState === 1 ? !name : !list}
              variant="gradient"
              leftSection={<IconFoldUp />}
              onClick={() =>
                handleImport({ name, ignoreContactErrors, enrich: false, list })
              }
              size="md"
              w={250}
            >
              {lang.contact_lists.import.step_importing.import}
            </Button>
          </Group>
        </>
      )}
    </Paper>
  );
};

export default StepImportSettings;
