import {
  Anchor,
  Badge,
  Button,
  Divider,
  Group,
  Paper,
  Stack,
  Text,
} from "@mantine/core";
import { IconFlame, IconVersions } from "@tabler/icons-react";
import HowItWorks from "./HowItWorks";
import { Link } from "react-router-dom";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useProfileStore } from "../../../../stores/UserStore";

const ListImportItem = ({ item, onClick }) => {
  const profile = useProfileStore((s) => s.profile);
  const lang = useLanguage((s) => s.language);

  if (
    item?.permission &&
    profile.permissions?.find((x) => x.name === item?.permission)?.value ===
      false
  ) {
    return "";
  }

  return (
    <Paper
      component={Stack}
      gap={0}
      withBorder
      // shadow="sm"
      radius={"md"}
      p={"md"}
      pb={"xs"}
      pos={"relative"}
      style={{
        borderColor: item?.isPopular
          ? "var(--mantine-color-orange-2)"
          : "#00000021",
      }}
    >
      <Anchor
        component={Link}
        to={item.value}
        onClick={
          onClick
            ? (e) => {
                e.preventDefault();
                onClick({ method: item.value });
              }
            : undefined
        }
        underline={"hover"}
      >
        <Group wrap="nowrap" mb={"xs"} style={{ textDecoration: "none" }}>
          {item.icon}
          <Text fw={500} c={"var(--mantine-color-gray-7)"}>
            {item.title}
          </Text>
        </Group>
      </Anchor>
      <Text size="sm" mb={"sm"} fw={400}>
        {item.text}
      </Text>

      <Divider mt={"auto"} />

      <Group wrap="nowrap" mt={"xs"}>
        <Button
          variant="outline"
          w={"100%"}
          component={Link}
          disabled={item.value === "magileads"}
          to={item.value}
          onClick={
            onClick
              ? (e) => {
                  e.preventDefault();
                  onClick({ method: item.value });
                }
              : item.value === "magileads"
              ? (e) => e.preventDefault()
              : undefined
          }
        >
          {lang.contact_lists.import.methods.select}
        </Button>
        {item.value !== "empty" && (
          <HowItWorks
            type={
              item?.value === "companies"
                ? "companies"
                : item?.isLinkedin
                ? "linkedin"
                : item.value
            }
          />
        )}
      </Group>

      {item?.isPopular && (
        <Badge
          size="xs"
          variant="light"
          pb={10}
          pt={3}
          h={20}
          leftSection={<IconFlame size={16} />}
          pos={"absolute"}
          top={-10}
          left={64}
          color="var(--mantine-color-orange-8)"
          fz={8}
          style={{
            border: "1px solid #00000033",
            background: "var(--mantine-color-orange-0)",
          }}
        >
          {lang.contact_lists.import.popular}
        </Badge>
      )}

      {item?.isBeta && (
        <Badge
          size="xs"
          variant="light"
          pb={10}
          pt={3}
          h={20}
          px={16}
          leftSection={<IconVersions size={16} />}
          pos={"absolute"}
          top={-10}
          left={64}
          color="var(--mantine-color-primary-8)"
          fz={8}
          style={{
            border: "1px solid #00000033",
            background: "var(--mantine-color-primary-0)",
          }}
        >
          {"bêta"}
        </Badge>
      )}
    </Paper>
  );
};

export default ListImportItem;
