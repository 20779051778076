import { Group, Stack, Text, Tooltip } from "@mantine/core";
import { parseDate } from "../../../../../functions/dates";
import { useLanguage } from "../../../../../stores/LanguageStore";
import CampaignColumnChildCell from "./CampaignColumnChildCell";

const ChildCell = ({ step }) => {
  const last_contacted = step?.last_contacted
    ? parseDate(step?.last_contacted)
    : "-";
  return (
    <CampaignColumnChildCell>
      <Tooltip
        label={new Date(step?.last_contacted)?.toLocaleString()}
        disabled={!step?.last_contacted}
      >
        <Text size={"sm"} truncate="end">
          {last_contacted}
        </Text>
      </Tooltip>
    </CampaignColumnChildCell>
  );
};

const ParentCell = ({ row }) => {
  const last_contacted = row?.initial?.last_contacted
    ? parseDate(row?.initial?.last_contacted)
    : "-";
  return (
    row?.initial?.last_contacted && (
      <Tooltip label={new Date(row?.initial?.last_contacted)?.toLocaleString()}>
        <Text>{last_contacted}</Text>
      </Tooltip>
    )
  );
};

export const CampaignLastSent = () => {
  const lang = useLanguage.getState().language;
  return {
    field: "last_contacted",
    headerName: lang.statistics.columns.last_contacted,
    width: 175,
    renderCell: ({ row, value }) => {
      if (!row) {
        return;
      }
      if (row.isChild) {
        return (
          <Stack py={"xs"} w={"100%"}>
            {row.steps.map((step, index) => (
              <ChildCell key={step.id} step={step} />
            ))}
          </Stack>
        );
      }
      return <ParentCell row={row} />;
    },
  };
};
