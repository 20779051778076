import { Group, Paper, Stack } from "@mantine/core";
import BackButton from "../../../../../components/View/BackButton";
import UnsubscribeProspect from "../actions/UnsubscribeProspect";
import StatusChanger from "./StatusChanger";
import AnswerDirectLink from "./AnswerDirectLink";

const AnswerResponder = ({
  container,
  current,
  setCurrent,
  handleClose,
  inPrm,
  index,
  items,
}) => {
  const linkedinItem = items?.find(
    (x) => x.type === "linkedin" && x.history_type === "reply"
  );
  return (
    <Paper component={Stack} pt={10} gap={"xs"} radius={0}>
      <Group justify="end">
        <StatusChanger
          container={container}
          current={current}
          setCurrent={setCurrent}
          index={index}
        />

        {linkedinItem && (
          <AnswerDirectLink link={linkedinItem.direct_link} isLinkedin={true} />
        )}

        <UnsubscribeProspect current={current} inPrm={inPrm} />

        <BackButton onClick={handleClose} />
      </Group>
    </Paper>
  );
};

export default AnswerResponder;
