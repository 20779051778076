export const model1 = {
  id: 3493,
  name: "Email Prise de rendez vous",
  subject: "Rendez vous %company%",
  text: "Bonjour %civilite% %lastname%\n\nJe vous contacte car nous accompagnons de nombreuses entreprises dans le secteur XXXX mais il me semble que nous ne nous connaissons pas encore,\n\nJ'imagine que vous connaissez déjà les bénéfices d'automatiser votre prospection commerciale,\n\nMais avez-vous déjà franchi le pas ? Peut-être qu'un point vous semble difficile à entreprendre ? \n\nNous sommes nous-mêmes des spécialistes de l'acquisition client via des campagnes automatisées, \n\nJe peux peut-être vous montrer les dernières astuces sur le sujet ? Vous pouvez directement prendre rendez vous dans mon agenda.\n\nBonne journée",
  html: "<p>Bonjour %civilite% %lastname%<br><br>Je vous contacte car nous accompagnons de nombreuses entreprises dans le secteur XXXX mais il me semble que nous ne nous connaissons pas encore,<br><br>J'imagine que vous connaissez d&eacute;j&agrave; les b&eacute;n&eacute;fices d'automatiser votre prospection commerciale,<br><br>Mais avez-vous d&eacute;j&agrave; franchi le pas ? Peut-&ecirc;tre qu'un point vous semble difficile &agrave; entreprendre ?&nbsp;<br><br>Nous sommes nous-m&ecirc;mes des sp&eacute;cialistes de l'acquisition client via des campagnes automatis&eacute;es,&nbsp;<br><br>Je peux peut-&ecirc;tre vous montrer les derni&egrave;res astuces sur le sujet ? Vous pouvez directement prendre rendez vous dans mon agenda.<br><br>Bonne journ&eacute;e</p>",
  json_template: "",
  is_template: false,
  tags: [],
  folder: null,
};

export const model2 = {
  id: 3716,
  model_type: "Email",
  name: "Partenariat Distribution",
  subject: "Proposition partenariat",
  text: "Bonjour %civilite% %lastname%\n\nJe me permets de vous contacter, je suis productrice de XXX, une nouvelle référence produite localement en XXX. J'ai pu voir que vous êtiez également situé dans la même zone mais il me semble que nous ne nous connaissons pas encore.\n\nNous recherchons actuellement des points de vente partenaires avec qui nous pourrions avoir une relation forte et de long terme,\n\nAinsi, pourrions-nous évoquer ensemble la possibilité de proposer nos produits dans votre boutique ? \n\nMerci pour votre retour,\n\nBien à vous",
  html: "<p>Bonjour %civilite% %lastname%<br><br>Je me permets de vous contacter, je suis productrice de XXX, une nouvelle r&eacute;f&eacute;rence produite localement en XXX. J'ai pu voir que vous &ecirc;tiez &eacute;galement situ&eacute; dans la m&ecirc;me zone mais il me semble que nous ne nous connaissons pas encore.</p>\n<p>Nous recherchons actuellement des points de vente partenaires avec qui nous pourrions avoir une relation forte et de long terme,</p>\n<p>Ainsi, pourrions-nous &eacute;voquer ensemble la possibilit&eacute; de proposer nos produits dans votre boutique ?&nbsp;</p>\n<p>Merci pour votre retour,</p>\n<p>Bien &agrave; vous</p>",
  links: [],
  json_template: "",
  is_template: false,
  sharing: [],
  tags: [],
};

export const model3 = {
  id: 3717,
  model_type: "Email",
  name: "Site Web",
  subject: "Site web",
  text: "Bonjour %civilite% %lastname%\n\nVous avez un site web et souhaiteriez l’améliorer ? Vous n’en avez pas encore et c’est le bon moment ?\n\nNous sommes votre interlocuteur unique pour ces sujets et intervenons localement dans votre zone :\n\n=> Création du site ou amélioration du design de l’existant\n\n=> Maintenance du site pour que tout se déroule bien au quotidien\n\n=> Mise en place de la stratégie de SEO et référencement naturel pour que vous apparaissiez aux yeux des personnes qui vous cherchent\n\nJe vous montre quelques exemples de site pour que vous puissiez vous projeter ? \n\nMerci pour votre retour,\n\nBien à vous",
  html: "<p>Bonjour %civilite% %lastname%<br><br>Vous avez un site web et souhaiteriez l&rsquo;am&eacute;liorer ? Vous n&rsquo;en avez pas encore et c&rsquo;est le bon moment ?</p>\n<p>Nous sommes votre interlocuteur unique pour ces sujets et intervenons localement dans votre zone :</p>\n<p>=&gt; Cr&eacute;ation du site ou am&eacute;lioration du design de l&rsquo;existant</p>\n<p>=&gt; Maintenance du site pour que tout se d&eacute;roule bien au quotidien</p>\n<p>=&gt; Mise en place de la strat&eacute;gie de SEO et r&eacute;f&eacute;rencement naturel pour que vous apparaissiez aux yeux des personnes qui vous cherchent</p>\n<p>Je vous montre quelques exemples de site pour que vous puissiez vous projeter ?&nbsp;</p>\n<p>Merci pour votre retour,</p>\n<p>Bien &agrave; vous</p>",
  links: [],
  json_template: "",
  is_template: false,
  from_sharing: false,
  sharing: [],
  created_by: 803,
  tags: [],
  permission: {
    read_permission: true,
    write_permission: true,
    delete_permission: true,
    is_admin: true,
  },
  created_on: "2024-04-22T07:29:37+00:00",
  folder: null,
  is_generated: false,
  prompt: null,
};

export const model4 = {
  id: 3718,
  model_type: "Email",
  name: "Webinar",
  subject: "Webinar : Thème Webinar",
  text: "Bonjour %civilite% %lastname%\n\n(Problématique personae correspondant au thème du webinar).\n\nMais ce n’est pas une fin en soi : en effet, XXXXX\n\nC’est pourquoi nous organisons un webinar sur le sujet le XXXX à 9h30  : (Thème Webinar)\n\nNous aurons l’occasion de donner des clés concrètes pour XXXX,\n\nJ’ai le plaisir de vous proposer d’y participer, vous pouvez tout simplement cliquer sur le lien suivant pour accéder au webinar : XXXXXX\n\nAu plaisir de nous retrouver,\n\nBonne journée",
  html: "<p>Bonjour %civilite% %lastname%<br><br>(Probl&eacute;matique personae correspondant au th&egrave;me du webinar).</p>\n<p>Mais ce n&rsquo;est pas une fin en soi : en effet, XXXXX</p>\n<p>C&rsquo;est pourquoi nous organisons un webinar sur le sujet le XXXX &agrave; 9h30 &nbsp;: (Th&egrave;me Webinar)</p>\n<p>Nous aurons l&rsquo;occasion de donner des cl&eacute;s concr&egrave;tes pour XXXX,</p>\n<p>J&rsquo;ai le plaisir de vous proposer d&rsquo;y participer, vous pouvez tout simplement cliquer sur le lien suivant pour acc&eacute;der au webinar : XXXXXX</p>\n<p>Au plaisir de nous retrouver,</p>\n<p>Bonne journ&eacute;e</p>",
  links: [],
  json_template: "",
  is_template: false,
  from_sharing: false,
  sharing: [],
  created_by: 803,
  tags: [],
  permission: {
    read_permission: true,
    write_permission: true,
    delete_permission: true,
    is_admin: true,
  },
  created_on: "2024-04-22T07:31:48+00:00",
  folder: null,
  is_generated: false,
  prompt: null,
};

export const model5 = {
  id: 3719,
  model_type: "Email",
  name: "Evenementiel",
  subject: "Votre évènement à XXXX",
  text: "Bonjour %civilite% %lastname%\n\n\nPeut-être avez-vous déjà entendu parler des évènements XXXX que nous organisons régulièrement ?  \n\nEn effet, nous pouvons privatiser l’un de nos lieux afin de proposer des expériences conviviales et peu communes pour tous vos événements d’entreprise.\n\nAinsi, que vous souhaitiez organiser un team building, faire plaisir à vos équipes en organisant un anniversaire d’entreprise ou encore un séminaire de formation, vous pouvez accéder aux nombreux espaces privés partout en France.\n\nSi vous souhaitez davantage de renseignements, ou que vous souhaitez m’interroger sur la faisabilité de votre événement, n’hésitez pas à revenir vers moi.\n\nJe pourrais par exemple vous proposer une visite sur le lieu le plus proche de vous.\n\nMerci pour votre retour et très bonne journée,",
  html: "<p>Bonjour %civilite% %lastname%</p>\n<p>Peut-&ecirc;tre avez-vous d&eacute;j&agrave; entendu parler des &eacute;v&egrave;nements XXXX que nous organisons r&eacute;guli&egrave;rement ?&nbsp;&nbsp;</p>\n<p>En effet, nous pouvons privatiser l&rsquo;un de nos lieux afin de proposer des exp&eacute;riences conviviales et peu communes pour tous vos &eacute;v&eacute;nements d&rsquo;entreprise.</p>\n<p>Ainsi, que vous souhaitiez organiser un team building, faire plaisir &agrave; vos &eacute;quipes en organisant un anniversaire d&rsquo;entreprise ou encore un s&eacute;minaire de formation, vous pouvez acc&eacute;der aux nombreux espaces priv&eacute;s partout en France.</p>\n<p>Si vous souhaitez davantage de renseignements, ou que vous souhaitez m&rsquo;interroger sur la faisabilit&eacute; de votre &eacute;v&eacute;nement, n&rsquo;h&eacute;sitez pas &agrave; revenir vers moi.</p>\n<p>Je pourrais par exemple vous proposer une visite sur le lieu le plus proche de vous.</p>\n<p>Merci pour votre retour et tr&egrave;s bonne journ&eacute;e,</p>",
  links: [],
  json_template: "",
  is_template: false,
  from_sharing: false,
  sharing: [],
  created_by: 803,
  tags: [],
  permission: {
    read_permission: true,
    write_permission: true,
    delete_permission: true,
    is_admin: true,
  },
  created_on: "2024-04-22T07:35:43+00:00",
  folder: null,
  is_generated: false,
  prompt: null,
};

export const model6 = {
  id: 3720,
  model_type: "Email",
  name: "Produits",
  subject: "Nouveau XXXX",
  text: "Bonjour %civilite% %lastname%\n\nPeut-être avez-vous déjà pu tester nos produits ? \n\nNous équipons les professionnels du batiment grâce à notre gamme complète de produits robustes, fiables et accessibles,\n\nPeut-être que vous cherchez actuellement une référence précise ? \n\nJe peux tout aussi bien, vous retournez notre catalogue complet ou réaliser un devis si vous le souhaitez,\n\nMerci pour votre retour",
  html: "<p>Bonjour %civilite% %lastname%</p>\n<p>Peut-&ecirc;tre avez-vous d&eacute;j&agrave; pu tester nos produits ?&nbsp;<br><br>Nous &eacute;quipons les professionnels du batiment gr&acirc;ce &agrave; notre gamme compl&egrave;te de produits robustes, fiables et accessibles,<br><br>Peut-&ecirc;tre que vous cherchez actuellement une r&eacute;f&eacute;rence pr&eacute;cise ?&nbsp;<br><br>Je peux tout aussi bien, vous retournez notre catalogue complet ou r&eacute;aliser un devis si vous le souhaitez,<br><br>Merci pour votre retour</p>",
  links: [],
  json_template: "",
  is_template: false,
  from_sharing: false,
  sharing: [],
  created_by: 803,
  tags: [],
  permission: {
    read_permission: true,
    write_permission: true,
    delete_permission: true,
    is_admin: true,
  },
  created_on: "2024-04-22T07:38:09+00:00",
  folder: null,
  is_generated: false,
  prompt: null,
};

export const model7 = {
  id: 3721,
  model_type: "Email",
  name: "Editeur SaaS",
  subject: "Gestion des congés automatique",
  text: "Bonjour %civilite% %lastname%\n\nJ'imagine que vous le vivez tout comme moi, tous les ans, au moment de faire le bilan de l'année, on se retrouve avec un décalage entre les jours congé enregistrés et les jours de congé réellement pris par les collaborateurs,\n\nEt générallement, c'est tout simplement car ils n'ont pas à leur disposition, une plateforme simple pour poser leurs jours,\n\nAvez vous déjà vécu cette situation ? \n\nPour remédier à ça, nous avons donc créer un outil simple et professionnel qui permet à tous les collaborateurs de poser leurs congés en ligne : le manager est ensuite averti et garde la main pour valider.\n\nC'est simple et sécurisant pour tout le monde,\n\nJe peux peut-être vous montrer comment ça fonctionne ? \n\nBonne journée",
  html: "<p>Bonjour %civilite% %lastname%</p>\n<p>J'imagine que vous le vivez tout comme moi, tous les ans, au moment de faire le bilan de l'ann&eacute;e, on se retrouve avec un d&eacute;calage entre les jours cong&eacute; enregistr&eacute;s et les jours de cong&eacute; r&eacute;ellement pris par les collaborateurs,<br><br>Et g&eacute;n&eacute;rallement, c'est tout simplement car ils n'ont pas &agrave; leur disposition, une plateforme simple pour poser leurs jours,<br><br>Avez vous d&eacute;j&agrave; v&eacute;cu cette situation ?&nbsp;<br><br>Pour rem&eacute;dier &agrave; &ccedil;a, nous avons donc cr&eacute;er un outil simple et professionnel qui permet &agrave; tous les collaborateurs de poser leurs cong&eacute;s en ligne : le manager est ensuite averti et garde la main pour valider.<br><br>C'est simple et s&eacute;curisant pour tout le monde,<br><br>Je peux peut-&ecirc;tre vous montrer comment &ccedil;a fonctionne ?&nbsp;<br><br>Bonne journ&eacute;e</p>",
  links: [],
  json_template: "",
  is_template: false,
  from_sharing: false,
  sharing: [],
  created_by: 803,
  tags: [],
  permission: {
    read_permission: true,
    write_permission: true,
    delete_permission: true,
    is_admin: true,
  },
  created_on: "2024-04-22T07:41:26+00:00",
  folder: null,
  is_generated: false,
  prompt: null,
};
