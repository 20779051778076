import { useMemo, useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { useLeadsContainerStore } from "../../../../../stores/LeadsStore";
import { useParams } from "react-router-dom";
import {
  deleteProspects,
  deleteProspectsUser,
} from "../../../../../api/PRMAPI";
import { notifications } from "@mantine/notifications";
import { Button, Group, Modal, Stack, Text, Tooltip } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import BackButton from "../../../../../components/View/BackButton";

const DeleteProspect = ({ current, container, inSheet, closeMenu, onDone }) => {
  const { userId } = useParams();
  const lang = useLanguage((s) => s.language);
  const updateContainer = useLeadsContainerStore((s) => s.updateContainer);
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);

  const firstName = useMemo(() => {
    return (
      current?.properties?.find(
        (x) => x.identifier_placeholder === "%first_name%"
      )?.value || " - "
    );
  }, [current]);

  const lastName = useMemo(() => {
    current?.properties?.find((x) => x.identifier_placeholder === "%last_name%")
      ?.value || " - ";
  }, [current]);

  const handleOpen = () => {
    setOpened(true);
  };

  const handleClose = () => {
    setOpened(false);
    closeMenu?.();
  };

  const handleSubmit = () => {
    setLoading(true);

    const main = userId ? deleteProspectsUser : deleteProspects;

    main({
      contact_ids: [current.id],
      filter: { mode: "and", values: [] },
      excluded_contact_ids: [],
      user_id: parseInt(userId),
    })
      .then((res) => {
        if (res.data.state) {
          notifications.show({
            message: lang.prm.deletion.deleted,
            color: "tertiary.8",
          });
          handleClose();
          if (onDone) {
            return onDone();
          }
          updateContainer(container.id, {
            items: container.items.filter((x) => x.id !== current.id),
            total: container.total - 1,
            needCacheReset: true,
          });
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Tooltip label={lang.prm.deletion.button_tooltip}>
        <Button
          onClick={handleOpen}
          leftSection={inSheet ? null : <IconTrash size={18} />}
          fw={600}
          color="red"
          variant={inSheet ? "light" : "subtle"}
          justify="start"
          px={inSheet ? "sm" : undefined}
        >
          {inSheet ? <IconTrash size={18} /> : lang.prm.deletion.button_label}
        </Button>
      </Tooltip>

      {opened && (
        <Modal
          opened={opened}
          size={"md"}
          onClose={handleClose}
          title={
            <>
              {lang.prm.deletion.dialog_title}
              <b>
                {firstName} {lastName} #{current?.id}
              </b>
            </>
          }
        >
          <Stack gap={"xs"}>
            <Text size="sm">
              {lang.prm.deletion.dialog_text}{" "}
              <b>
                {firstName} {lastName} #{current?.id}
              </b>
              <br />
              <b>{lang.prm.deletion.dialog_text_warning}</b>
            </Text>

            <Group justify="end" mt={"md"}>
              <BackButton onClick={handleClose} />

              <Button
                onClick={handleSubmit}
                leftSection={<IconTrash size={18} />}
                loading={loading}
                color="red"
                type="submit"
              >
                {lang.prm.deletion.delete}
              </Button>
            </Group>
          </Stack>
        </Modal>
      )}
    </>
  );
};

export default DeleteProspect;
