import { Stack, Text, Tooltip } from "@mantine/core";
import { useFlowStore } from "../../../../../../stores/WorkflowStore";
import { IconMessage, IconUserCircle } from "@tabler/icons-react";
import ModelLinkedinSelector from "../../../../../../components/Selectors/ModelLinkedinSelector";
import LinkedinAccountSelector from "../../../../../../components/Selectors/LinkedinAccountSelector";
import { useLanguage } from "../../../../../../stores/LanguageStore";
import { useReactFlow } from "reactflow";

const LinkedinNodeBody = ({ node_id, data, xPos, yPos }) => {
  const lang = useLanguage((s) => s.language);
  const updateNodeData = useFlowStore((s) => s.updateNodeData);
  const handleModelChange = (v) => {
    const newValue = v?.id ? parseInt(v?.id) : null;
    updateNodeData(node_id, {
      model_id: newValue,
    });
  };
  const handleSenderChange = (v) => {
    let isPool = v?.number_of_accounts !== undefined;
    const newValue = v?.id ? parseInt(v?.id) : null;
    updateNodeData(node_id, {
      [isPool ? "pool_id" : "integration_id"]: newValue,
      [isPool ? "integration_id" : "pool_id"]: null,
    });
  };

  const setSelectedNode = useFlowStore((s) => s.setSelectedNode);
  const reactflow = useReactFlow();
  const handleNoValueClick = (toCheck) => {
    if (!toCheck) {
      setSelectedNode({ data, id: node_id, toAdvanced: false });
      setTimeout(() => {
        reactflow?.setCenter(xPos + 142, yPos + 132, {
          duration: 500,
          zoom: 1,
        });
      }, 201);
    }
  };

  return (
    <Stack w={"100%"} pb={6} gap={"xs"}>
      {data?.actionType !== "linkedin_visit" && (
        <ModelLinkedinSelector
          value={data?.model_id}
          onChange={handleModelChange}
          onClick={() => handleNoValueClick(data?.model_id)}
          required={data?.actionType !== "linkedin_invitation"}
          placeholder={
            data?.actionType === "linkedin_invitation"
              ? "Pas de modèle"
              : undefined
          }
          size={"xs"}
          type={data?.actionType}
          leftSection={
            <Tooltip label={lang.campaign.selectors.email.tooltip}>
              <IconMessage size={16} strokeWidth={1.5} />
            </Tooltip>
          }
        />
      )}
      {data?.actionType !== "sms" && (
        <LinkedinAccountSelector
          value={data?.pool_id || data?.integration_id}
          onChange={handleSenderChange}
          onClick={() =>
            handleNoValueClick(data?.pool_id || data?.integration_id)
          }
          required
          size={"xs"}
          leftSection={
            <Tooltip label={lang.campaign.selectors.senders.tooltip_linkedin}>
              <IconUserCircle size={16} strokeWidth={1.5} />
            </Tooltip>
          }
        />
      )}
      {data?.actionType === "linkedin_message" && (
        <Text size="xs" c={"dimmed"}>
          {lang.campaign.selectors.senders.linkedin_message_info}
        </Text>
      )}
    </Stack>
  );
};

export default LinkedinNodeBody;
