import PageTitle from "../../../components/View/PageTitle";
import { Card, Grid, Group, Stack, Text, ThemeIcon } from "@mantine/core";
import { useLanguage } from "../../../stores/LanguageStore";
import { useThemeStore } from "../../../stores/ThemeStore";
import { useMemo } from "react";
import {
  IconBrandLinkedin,
  IconMail,
  IconMessage,
  IconMessageCircle,
  IconSignature,
} from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { getNodeDetails } from "../workflows/CreateCampaign/flow/flowSettings";

const Illustration = () => {
  const theme = useThemeStore((s) => s.theme);
  const mainColor = theme.colors.primary[6];
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 652.05514 613.4514"
    >
      <path
        d="M339.30366,701.79714l-.99775-22.43416a72.45556,72.45556,0,0,0-33.79563-8.555c16.23147,13.27042,14.203,38.85123,25.20758,56.69681a43.58213,43.58213,0,0,0,31.95921,20.13989l13.58306,8.31648a73.02982,73.02982,0,0,0-15.39283-59.17883,70.543,70.543,0,0,0-12.96441-12.04606C343.64971,693.31426,339.30366,701.79714,339.30366,701.79714Z"
        transform="translate(-273.97243 -143.2743)"
        fill="#f2f2f2"
      />
      <rect
        x="223.55549"
        y="146.49993"
        width="224"
        height="130"
        rx="14.81496"
        fill="#fff"
      />
      <path
        d="M706.71307,420.77438H512.343A15.83308,15.83308,0,0,1,496.528,404.95944V304.58932A15.83308,15.83308,0,0,1,512.343,288.77438H706.71307a15.833,15.833,0,0,1,15.81445,15.81494V404.95944A15.833,15.833,0,0,1,706.71307,420.77438Zm-194.37012-130A13.83047,13.83047,0,0,0,498.528,304.58932V404.95944A13.83046,13.83046,0,0,0,512.343,418.77438H706.71307a13.83036,13.83036,0,0,0,13.81445-13.81494V304.58932a13.83037,13.83037,0,0,0-13.81445-13.81494Z"
        transform="translate(-273.97243 -143.2743)"
        fill="#3f3d56"
      />
      <path
        d="M910.71312,274.2743H716.343a15.33222,15.33222,0,0,1-15.31543-15.31494V158.58924A15.33222,15.33222,0,0,1,716.343,143.2743H910.71312a15.332,15.332,0,0,1,15.31445,15.31494V258.95936A15.332,15.332,0,0,1,910.71312,274.2743Z"
        transform="translate(-273.97243 -143.2743)"
        fill="#f2f2f2"
      />
      <path
        d="M910.71312,566.2743H716.343a15.33232,15.33232,0,0,1-15.31543-15.31543V450.58924A15.33222,15.33222,0,0,1,716.343,435.2743H910.71312a15.332,15.332,0,0,1,15.31445,15.31494V550.95887A15.33211,15.33211,0,0,1,910.71312,566.2743Z"
        transform="translate(-273.97243 -143.2743)"
        fill="#f2f2f2"
      />
      <path
        d="M665.43042,340.44314a24.88949,24.88949,0,0,0-20.69929-18.10187c-.10736-.01593-.2093.00512-.31621-.00214a2.95592,2.95592,0,0,0-.422-.10227c-22.60919-3.07477-48.70774,8.30377-58.13046,30.85855-4.66514-6.50225-12.10337-10.70319-20.05874-10.28555-10.14893.53288-19.10559,8.30674-21.8493,18.53164a3.08643,3.08643,0,0,0,1.27636,3.30048,12.30963,12.30963,0,0,0,5.16533,2.75892q6.15893,1.373,12.36171,2.5155,12.10359,2.22883,24.33353,3.604a343.98754,343.98754,0,0,0,49.841,1.98238c5.54121-.18171,10.88487-.37519,15.96183-2.9486a24.162,24.162,0,0,0,9.15669-8.40829A29.60815,29.60815,0,0,0,665.43042,340.44314Z"
        transform="translate(-273.97243 -143.2743)"
        fill="#3f3d56"
      />
      <path
        d="M662.938,341.63786a21.934,21.934,0,0,0-7.0129-10.69918,24.10082,24.10082,0,0,0-12.14414-5.22758h-.26976l-.25043-.045a1.74,1.74,0,0,0-.244-.05782A59.19458,59.19458,0,0,0,620.7513,327.201c-13.94232,3.667-27.14612,12.34965-33.31134,26.35621l-.87982,2.01012-1.31652-1.75322a22.18534,22.18534,0,0,0-17.54514-9.08084c-.33392,0-.66788.00643-1.00827.02568a22.52636,22.52636,0,0,0-20.21671,16.30568,1.57914,1.57914,0,0,0,.75781,1.67615l.10277.07706a10.92436,10.92436,0,0,0,4.47617,2.27987c3.98813.84768,8.04689,1.6312,12.06069,2.33122,7.78357,1.36148,15.77264,2.48535,23.736,3.33945A351.15038,351.15038,0,0,0,630.0184,372.74q3.11148-.03852,6.21014-.13484c5.31747-.16055,10.346-.3211,15.11114-2.61379a22.01013,22.01013,0,0,0,8.46434-7.39825A25.09175,25.09175,0,0,0,662.938,341.63786Z"
        transform="translate(-273.97243 -143.2743)"
        fill="#fff"
      />
      <path
        d="M630.0184,372.74a351.15038,351.15038,0,0,1-42.41146-1.97159c-7.96336-.8541-15.95243-1.978-23.736-3.33945-4.0138-.7-8.07256-1.48354-12.06069-2.33122a10.92436,10.92436,0,0,1-4.47617-2.27987l-.10277-.07706a1.57914,1.57914,0,0,1-.75781-1.67615A22.52636,22.52636,0,0,1,566.69021,344.759c.34039-.01925.67435-.02568,1.00827-.02568a22.18534,22.18534,0,0,1,17.54514,9.08084l1.31652,1.75322.87982-2.01012c6.16522-14.00656,19.369-22.68923,33.31134-26.35621,2.81287,2.54312,4.62391,6.44132,3.59639,10.057-1.23946,4.38629-6.14594,7.12211-6.968,11.60471-.75137,4.09086,2.1771,7.89272,5.18906,10.757,3.012,2.85783,6.49914,5.72848,7.37258,9.78725A8.96979,8.96979,0,0,1,630.0184,372.74Z"
        transform="translate(-273.97243 -143.2743)"
        fill={mainColor}
      />
      <circle cx="368.15158" cy="205.4407" r="11.55975" fill="#3f3d56" />
      <path
        d="M646.96973,347.4817l-4.3349-4.33491a.72222.72222,0,0,0-1.02164,0l-4.33491,4.33491a.72241.72241,0,1,0,1.02164,1.02164l3.1016-3.1016v8.37065a.72249.72249,0,1,0,1.445,0v-8.37065l3.10161,3.1016a.72241.72241,0,0,0,1.02163-1.02164Z"
        transform="translate(-273.97243 -143.2743)"
        fill="#fff"
      />
      <path
        d="M851.52183,238.33521H782.55176a5.304,5.304,0,0,1-5.29794-5.29794V170.072a5.30383,5.30383,0,0,1,5.29794-5.29775h68.97007a5.30383,5.30383,0,0,1,5.29794,5.29775v62.96529A5.304,5.304,0,0,1,851.52183,238.33521Z"
        transform="translate(-273.97243 -143.2743)"
        fill="#fff"
      />
      <path
        d="M851.52206,239.33527H782.55185a6.305,6.305,0,0,1-6.29785-6.29785V170.07208a6.305,6.305,0,0,1,6.29785-6.29785h68.97021a6.305,6.305,0,0,1,6.29786,6.29785v62.96534A6.305,6.305,0,0,1,851.52206,239.33527Zm-68.97021-73.561a4.303,4.303,0,0,0-4.29785,4.29785v62.96534a4.303,4.303,0,0,0,4.29785,4.29785h68.97021a4.303,4.303,0,0,0,4.29786-4.29785V170.07208a4.303,4.303,0,0,0-4.29786-4.29785Z"
        transform="translate(-273.97243 -143.2743)"
        fill="#ccc"
      />
      <circle cx="528.89971" cy="42.74811" r="8.59703" fill="#ccc" />
      <path
        d="M837.23738,207.68639H796.83526a2.56,2.56,0,1,1,0-5.12h40.40212a2.56,2.56,0,1,1,0,5.12Z"
        transform="translate(-273.97243 -143.2743)"
        fill="#ccc"
      />
      <path
        d="M816.33132,217.8023H796.83526a2.56014,2.56014,0,1,1,0-5.12029h19.49606a2.56015,2.56015,0,1,1,0,5.12029Z"
        transform="translate(-273.97243 -143.2743)"
        fill="#ccc"
      />
      <path
        d="M781.11388,256.815a18.15246,18.15246,0,1,1,18.15246-18.15246A18.15246,18.15246,0,0,1,781.11388,256.815Z"
        transform="translate(-273.97243 -143.2743)"
        fill="#ccc"
      />
      <path
        d="M788.27635,236.8719h-5.37182V231.5a1.79066,1.79066,0,0,0-3.58131,0v5.3719h-5.37189a1.79062,1.79062,0,0,0,0,3.58124h5.37189V245.825a1.79065,1.79065,0,1,0,3.58131,0v-5.37189h5.37182a1.79062,1.79062,0,1,0,0-3.58124Z"
        transform="translate(-273.97243 -143.2743)"
        fill="#fff"
      />
      <path
        d="M779.90822,524.19052c-.03624,0-.07247-.00071-.10906-.00214a2.65125,2.65125,0,0,1-2.132-1.21252,29.9508,29.9508,0,0,0-6.30518-6.83c-1.47973-1.18271-3.026-2.28242-4.5213-3.34608-3.85765-2.74294-7.49058-5.32631-9.50722-8.89587-4.39689-7.78367.7811-18.62515,8.17832-23.48719,8.297-5.453,19.21043-6.3084,29.76792-6.4205q1.64582-.0174,3.29361-.01731h0a314.02871,314.02871,0,0,1,56.71293,5.18566c6.22094,1.14773,12.73264,2.55374,17.96542,5.96586,5.20173,3.392,8.36342,8.73345,8.055,13.60852-.45034,7.11681-7.99465,12.43456-14.86441,13.82486-6.54794,1.32408-13.35024.42928-20.55165-.51835-1.58986-.209-3.23363-.42536-4.85919-.61331-17.66645-2.042-50.854,4.25195-52.258,4.52094l-1.393.26667-5.40485,6.95527A2.59378,2.59378,0,0,1,779.90822,524.19052Z"
        transform="translate(-273.97243 -143.2743)"
        fill="#fff"
      />
      <path
        d="M779.90843,525.19077l-.13477-.002a3.64984,3.64984,0,0,1-2.94238-1.66406,28.9536,28.9536,0,0,0-6.09326-6.59766c-1.46241-1.16895-2.99512-2.25781-4.477-3.31152-3.94678-2.80664-7.67237-5.45606-9.79786-9.21973-4.668-8.26367.73291-19.71,8.49952-24.81543,8.51074-5.59375,19.5957-6.46973,30.30615-6.584q1.65161-.01758,3.30469-.01758a314.98765,314.98765,0,0,1,56.894,5.20215c6.32031,1.166,12.94189,2.59765,18.33057,6.11132,5.498,3.58594,8.8374,9.28125,8.50683,14.50977-.48193,7.61328-8.43066,13.27734-15.66455,14.74219-6.71338,1.35742-13.5957.45215-20.87988-.50782-1.58545-.208-3.22364-.42285-4.84424-.61132-17.561-2.02539-50.55811,4.24218-51.95459,4.51074l-1.02295.19531-5.17383,6.65723A3.59312,3.59312,0,0,1,779.90843,525.19077Zm18.665-50.21191q-1.64283,0-3.28321.01758c-10.40625.11035-21.14941.94531-29.229,6.25585-7.02783,4.61915-11.98291,14.85547-7.85693,22.15918,1.90673,3.376,5.45605,5.90039,9.21337,8.57227,1.50684,1.07129,3.0625,2.17773,4.56836,3.38086a30.95994,30.95994,0,0,1,6.51709,7.0625,1.66536,1.66536,0,0,0,1.33643.76269l.06885.001a1.58975,1.58975,0,0,0,1.27734-.62891l5.63672-7.25292,1.76269-.3379c1.40772-.2705,34.69825-6.60058,52.561-4.53222,1.63086.18847,3.27978.40527,4.875.61523,7.11475.9375,13.835,1.82227,20.22266.5293,6.50537-1.31641,13.646-6.28809,14.06494-12.9082.28564-4.52051-2.69873-9.50879-7.60352-12.707-5.07861-3.31152-11.47949-4.6914-17.60058-5.82031A313.0181,313.0181,0,0,0,798.57347,474.97886Z"
        transform="translate(-273.97243 -143.2743)"
        fill="#ccc"
      />
      <path
        d="M858.57885,489.47227h-74.67a1.62466,1.62466,0,0,1,0-3.24931h74.67a1.62466,1.62466,0,0,1,0,3.24931Z"
        transform="translate(-273.97243 -143.2743)"
        fill="#ccc"
      />
      <path
        d="M858.57885,499.44459h-74.67a1.62466,1.62466,0,1,1-.00339-3.24931h74.67342a1.62466,1.62466,0,0,1,0,3.24931Z"
        transform="translate(-273.97243 -143.2743)"
        fill="#ccc"
      />
      <path
        d="M792.61051,509.41668h-8.70169a1.62466,1.62466,0,0,1,0-3.24931h8.70169a1.62466,1.62466,0,0,1,0,3.24931Z"
        transform="translate(-273.97243 -143.2743)"
        fill="#ccc"
      />
      <circle cx="505.85792" cy="388.34558" r="10.81761" fill="#fff" />
      <path
        d="M779.83036,542.56961A10.94968,10.94968,0,1,1,790.78,531.61987,10.96216,10.96216,0,0,1,779.83036,542.56961Z"
        transform="translate(-273.97243 -143.2743)"
        fill="#ccc"
      />
      <path
        d="M779.02044,535.94323c-.02307,0-.04613-.00075-.06919-.00188a1.41466,1.41466,0,0,1-1.04865-.54485l-1.80781-2.32417a1.4152,1.4152,0,0,1,.24805-1.98631l.06506-.05062a1.41507,1.41507,0,0,1,1.98668.24824,1.03227,1.03227,0,0,0,1.56332.07612l3.67056-3.87511a1.41732,1.41732,0,0,1,2.00112-.05437l.06018.05737a1.415,1.415,0,0,1,.054,2.00093l-5.69624,6.01254A1.41374,1.41374,0,0,1,779.02044,535.94323Z"
        transform="translate(-273.97243 -143.2743)"
        fill="#fff"
      />
      <path
        d="M438.8077,477.69409a7.22487,7.22487,0,0,0-1.29031.1217l-7.08172-44.83143,7.0946-43.159a10.87013,10.87013,0,0,0-21.1015-5.00554v0a85.75783,85.75783,0,0,0-3.90085,26.29975l.24744,29.43344,18.87931,45.20373a7.2062,7.2062,0,1,0,7.153-8.0627Z"
        transform="translate(-273.97243 -143.2743)"
        fill="#ffb8b8"
      />
      <path
        d="M408.74306,391.56791a17.09523,17.09523,0,1,1,34.19045,0l-11.112,39.197-19.29664-.95881Z"
        transform="translate(-273.97243 -143.2743)"
        fill="#2f2e41"
      />
      <polygon
        points="175.317 592.125 186.35 592.124 191.599 549.567 175.315 549.568 175.317 592.125"
        fill="#ffb8b8"
      />
      <path
        d="M479.57266,747.83512l-16.57959-11.45v-7.47l-1.88037.11-11.81983.71-3.74023.22-2.12988,25.54-.06983.85H451.183l.15967-.85,1.24023-6.54,3.16992,6.54.41016.85h20.75977a4.67909,4.67909,0,0,0,4.66992-4.66A4.67073,4.67073,0,0,0,479.57266,747.83512Z"
        transform="translate(-273.97243 -143.2743)"
        fill="#2f2e41"
      />
      <polygon
        points="109.146 566.692 117.913 573.391 147.924 542.764 134.985 532.877 109.146 566.692"
        fill="#ffb8b8"
      />
      <path
        d="M399.63017,738.23554,393.40885,719.071l4.53562-5.93537-1.56085-1.05434-9.82272-6.61261-3.10542-2.09621-17.1997,18.99992-.57161.633,6.2215,4.75427.643-.57846,4.95639-4.44338-1.45224,7.12114-.19023.92445,16.495,12.60493a4.67907,4.67907,0,0,0,6.54-.86721A4.67074,4.67074,0,0,0,399.63017,738.23554Z"
        transform="translate(-273.97243 -143.2743)"
        fill="#2f2e41"
      />
      <path
        d="M427.8779,526.12421l11.03,33.85s3.92,36.12,8.11,44.12a18.39489,18.39489,0,0,1,1.6,5.13995c.12.6.21,1.23005.29,1.86005.16,1.25.27,2.54.34,3.84a102.51024,102.51024,0,0,1-.71,16.54c-1.98,1.72-3.43,17.23-4.44,36.36q-.165,2.985-.31,6.07c-.2,4.15-.38,8.41-.54,12.69-1.15,29.74-.50994,23.38-.50994,23.38,27.96,5.12994,26.81-4.12006,26.81-4.12006s9.6-42.88995,12.08-62.43c.3-2.28.48-3.74.52-4.16a23.7356,23.7356,0,0,1,1.13-6.08,19.23621,19.23621,0,0,0,1.45-2.43.0098.0098,0,0,0,.01-.01,36.08013,36.08013,0,0,0,3.28-13.66,46.74165,46.74165,0,0,0,.02-6.15,183.91841,183.91841,0,0,1,3.03-35.75c1.75-8.05.61005-23.61-.94-36.94-.63-5.5-1.34-10.62-1.93-14.67-.03-.17-.05-.34-.07-.51-.76-5.09-1.32-8.37-1.32-8.37s2.55-8.74.66-11.97c-.87-1.45-.75-4.87006-.38-8.25.46-4.12006,1.32-7.96,1.32-7.96l-51.93-9.15c-1.09,1.81-2.06,3.56-3.01,5.25C416.29794,514.34424,427.8779,526.12421,427.8779,526.12421Z"
        transform="translate(-273.97243 -143.2743)"
        fill="#2f2e41"
      />
      <path
        d="M413.03623,381.32164a10.10206,10.10206,0,0,1,7.27766-6.41168,22.67719,22.67719,0,0,1,7.54555-.82471c7.44627.29332,12.69432-13.71665,20.06761-12.63651,0,0,6.93987-2.4767,12.36985,2.06165,4.93284,4.12283,8.33337,15.33606,9.59931,14.69815a4.12273,4.12273,0,0,1,1.81932.00109,20.42424,20.42424,0,0,1,17.54018,21.51924l-.5367,9.095s3.79335,5.71066-1.7112,15.79211l3.19552,35.56332s5.32365,49.54415-2.67541,56.59493-37.46469-23.69108-37.46469-23.69108-22.63691-3.608-21.29679-7.79307S437.1367,469.498,437.1367,469.498a3.60807,3.60807,0,0,0-1.13388-3.60787c-1.7112-1.09273.20612-3.56673.35045-3.79348-.165.16484-1.46382,1.5256-2.45336-1.34011s2.06164-6.47359,2.06164-6.47359l-8.61765-18.55477-6.78275-14.65824-.82471-1.69057-5.71079-11.79253-.13238-.2739a29.20629,29.20629,0,0,1-1.88106-20.17607A22.51151,22.51151,0,0,0,413.03623,381.32164Z"
        transform="translate(-273.97243 -143.2743)"
        fill={mainColor}
      />
      <path
        d="M395.10794,684.57422c20.51,19.68,24.64,11.31,24.64,11.31s11.09-12.77,24.35-28.05c4.76-5.48,9.79-11.28,14.69-16.93,6.77-7.81,13.28-15.33,18.46-21.31,5.73005-6.63,9.81006-11.37,10.77-12.51a46.74165,46.74165,0,0,0,.02-6.15,183.91841,183.91841,0,0,1,3.03-35.75c1.75-8.05.61005-23.61-.94-36.94-2.32,4.83-5,10.56-7.22,15.85-2.48,5.88995-4.41,11.22-4.71,14.12-.95,8.93-13.8,23.69-13.8,23.69l-.15.03c-2.11.56-8.06,7.48-15.62994,17.3C427.9379,636.0542,395.10794,684.57422,395.10794,684.57422Z"
        transform="translate(-273.97243 -143.2743)"
        fill="#2f2e41"
      />
      <path
        d="M563.195,371.88968a7.22664,7.22664,0,0,0,.26759,1.26811l-43.73571,12.13239L476.04112,383.148a10.87013,10.87013,0,0,0-2.5742,21.53374h0a85.75777,85.75777,0,0,0,26.57271.88571l29.2145-3.59192,42.76441-23.89583a7.2062,7.2062,0,1,0-8.82361-6.19006Z"
        transform="translate(-273.97243 -143.2743)"
        fill="#ffb8b8"
      />
      <path
        d="M481.045,411.55053a17.09523,17.09523,0,1,1-3.88689-33.9688l40.20608,6.5839,1.24111,19.28054Z"
        transform="translate(-273.97243 -143.2743)"
        fill="#2f2e41"
      />
      <path
        d="M474.03838,346.45821a22.75479,22.75479,0,1,1,1.15624-19.38543c.10414.26042.19792.52084.29163.79168A22.72373,22.72373,0,0,1,474.03838,346.45821Z"
        transform="translate(-273.97243 -143.2743)"
        fill="#ffb6b6"
      />
      <path
        d="M477.30917,327.86446c-.97917-2.73959-.55205-1.31251-1.54165-4.04167a6.811,6.811,0,0,1-.28127,4.04167H448.39246v-15.75c7.93757-3.14586,15.69794-5.82295,20.38545,0a15.75068,15.75068,0,0,1,15.75,15.75Z"
        transform="translate(-273.97243 -143.2743)"
        fill="#2f2e41"
      />
      <path
        d="M467.705,371.64573c-.02086-.29163-.05208-.59376-.09378-.88539a30.67565,30.67565,0,0,0-1.76042-6.25c-.78126-2.1979-1.57293-4.4063-2.36455-6.6042a18.908,18.908,0,0,1-.88545,12c-4.57294-3.56248-1.68744-11.70833-7.66666-11.70833-14.375,0-29.16671-11.65627-29.16671-26.0417,0-14.37507,6.16666-25.125,20.54167-25.125,14.38549,0,26.05218,6.58329,26.33339,20.95836C454.93417,332.15613,471.04874,370.18743,467.705,371.64573Z"
        transform="translate(-273.97243 -143.2743)"
        fill="#2f2e41"
      />
      <circle cx="153.5913" cy="170.01183" r="16.66669" fill="#2f2e41" />
      <path
        d="M273.97243,755.5357a1.18647,1.18647,0,0,0,1.19006,1.19h280.29a1.19,1.19,0,0,0,0-2.38h-280.29A1.18651,1.18651,0,0,0,273.97243,755.5357Z"
        transform="translate(-273.97243 -143.2743)"
        fill="#ccc"
      />
    </svg>
  );
};

const iconSize = 64;

const ModelGroupPage = () => {
  const lang = useLanguage((s) => s.language);

  const items = useMemo(() => {
    return [
      {
        path: "email",
        name: lang.header.navigations.models.emails,
        icon: <IconMail size={iconSize} />,
        text: "Tous vos modèles d'Emails",
        color: getNodeDetails("email").color,
      },
      {
        path: "email-signatures",
        icon: <IconSignature size={iconSize} />,
        name: lang.header.menus.link_groups.email_signatures,
        text: "Tous vos modèles de Signatures Emails",
        color: getNodeDetails("email").color,
      },
      {
        path: "linkedin-invitation",
        name: lang.header.navigations.models.linkedin_invitations,
        icon: <IconBrandLinkedin size={iconSize} />,
        text: "Tous vos modèles d'invitations LinkedIn",
        color: getNodeDetails("linkedin_invitation").color,
      },
      {
        path: "linkedin-message",
        name: lang.header.navigations.models.linkedin_messages,
        icon: <IconMessage size={iconSize} />,
        text: "Tous vos modèles de message LinkedIn",
        color: getNodeDetails("linkedin_message").color,
      },
      {
        path: "sms",
        name: lang.header.navigations.models.sms,
        icon: <IconMessageCircle size={iconSize} />,
        text: "Tous vos modèles d'SMS",
        color: getNodeDetails("sms").color,
      },
    ];
  }, [lang]);

  return (
    <>
      <Group
        className="layout-block top"
        w={"100%"}
        gap={0}
        justify="space-between"
      >
        <PageTitle title={lang.routes.models.models} />
      </Group>

      <Group
        wrap="nowrap"
        className="layout-block"
        w={"calc(100% - 32px)"}
        align="center"
        py={"lg"}
        gap={"xl"}
      >
        <Stack w={600}>
          <Illustration />
        </Stack>
        <Stack>
          <Text size="lg">
            Les modèles sont à utiliser dans vos campagnes, tous les modèles
            listés peuvent être choisis dans vos actions en scénario. Ce sont
            les messages à envoyer aux contacts dans vos listes, en utilisant
            les variables vous pouvez les adapter pour chaque contact.
          </Text>

          <Grid gutter={"lg"}>
            {items.map((item) => (
              <Grid.Col key={item.path} span={4}>
                <Card
                  withBorder
                  padding="md"
                  component={Link}
                  to={item.path}
                  className="group-page-item"
                  style={{
                    borderColor: item.color,
                  }}
                  radius={"md"}
                >
                  <Group>
                    <ThemeIcon variant="subtle" size={"xl"} color={item.color}>
                      {item.icon}
                    </ThemeIcon>

                    <Text fw={500} size="lg">
                      {item.name}
                    </Text>
                  </Group>

                  <Text mt="xs" c="dimmed" size="sm">
                    {item.text}
                  </Text>
                </Card>
              </Grid.Col>
            ))}
          </Grid>
        </Stack>
      </Group>
    </>
  );
};

export default ModelGroupPage;
