import { useEffect, useState } from "react";
import { useLanguage } from "../../stores/LanguageStore";
import { defaultStatuses, usePrmStore } from "../../stores/PrmStore";
import { Select } from "@mantine/core";
import { getStatusCustom } from "../../api/PRMAPI";

const PRMStatusSelector = ({
  value,
  onChange,
  noLabel,
  label,
  required,
  disabled,
  isUser,
  id,
  w,
  defaultOptions,
  name,
  withinPortal = false,
}) => {
  const lang = useLanguage((s) => s.language);

  const [options, setOptions] = useState(
    defaultOptions ||
      defaultStatuses().map((x) => ({ label: x.name, value: x.id }))
  );

  useEffect(() => {
    if (isUser) {
      setOptions([
        ...usePrmStore.getState().statuses.map((status) => ({
          value: status.id,
          label: status.name,
          isCustom: !status.disableDrop,
        })),
      ]);
    } else {
      if (!defaultOptions)
        getStatusCustom().then((response) => {
          setOptions([
            ...defaultStatuses().map((x) => ({ label: x.name, value: x.id })),
            ...response.data.status.map((status) => ({
              value: status.id?.toString(),
              label: status.name,
              isCustom: true,
            })),
          ]);
        });
    }
  }, [defaultOptions, isUser]);

  return (
    <Select
      id={id}
      label={
        noLabel ? null : label || lang.components.status_selector.default_label
      }
      placeholder={lang.components.status_selector.default_label}
      required={required}
      onChange={onChange}
      disabled={disabled}
      value={value}
      w={w}
      name={name}
      clearable={!required}
      data={options
        .filter((x) => !x.disabled)
        .map((x) => ({
          ...x,
        }))}
      comboboxProps={{
        zIndex: 1600,
        withinPortal,
      }}
      aria-required
    />
  );
};

export default PRMStatusSelector;
