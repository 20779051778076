import {
  ActionIcon,
  Anchor,
  Button,
  Group,
  Modal,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import { IconDownload, IconFileZip } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import BackButton from "../../../../components/View/BackButton";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useState } from "react";
import { downloadExtension } from "../../../../api/ResellerAPI";
import { Link } from "react-router-dom";

const ResellerExtension = ({ id, name, inTable }) => {
  const lang = useLanguage((s) => s.language);
  const [opened, { open, close }] = useDisclosure();
  const [loading, setLoading] = useState(false);

  const handleDownload = () => {
    setLoading(true);
    downloadExtension({ id, name }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <>
      {inTable ? (
        <Tooltip label={lang.resellers.plugin.name}>
          <ActionIcon
            radius={"sm"}
            variant="subtle"
            color="primary"
            onClick={open}
          >
            <IconFileZip size={18} />
          </ActionIcon>
        </Tooltip>
      ) : (
        <Button
          leftSection={<IconFileZip size={18} />}
          radius={"sm"}
          variant="light"
          color="primary"
          onClick={open}
        >
          {lang.resellers.plugin.name}
        </Button>
      )}

      <Modal
        onClose={close}
        title={lang.resellers.plugin.title}
        opened={opened}
        size={"lg"}
      >
        <Stack>
          <Text fw={600} size="lg">
            {lang.resellers.plugin.text}
          </Text>
          <Text>{lang.resellers.plugin.text1}</Text>
          <Text>
            <Anchor
              href={
                "https://files.magileads.net/file/6670101BCD2B2737686486/Proc%C3%A9dure%20pour%20uploader%20votre%20extension%20sur%20le%20Google%20Chrome%20Store.pdf"
              }
              target="_blank"
            >
              {lang.resellers.plugin.link}
            </Anchor>
            {lang.resellers.plugin.text2}
          </Text>
          <Text>
            {lang.resellers.plugin.text3}{" "}
            <Anchor component={Link} to={`/resellers/white-labels/${id}`}>
              {lang.resellers.plugin.page}
            </Anchor>
          </Text>

          <Button
            leftSection={<IconDownload size={18} />}
            loading={loading}
            onClick={handleDownload}
            size="md"
          >
            {lang.resellers.plugin.download} {name}
          </Button>
        </Stack>

        <Group justify="end" mt={"md"}>
          <BackButton onClick={close} />
        </Group>
      </Modal>
    </>
  );
};

export default ResellerExtension;
