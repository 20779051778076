import { IconRefresh } from "@tabler/icons-react";
import { useLanguage } from "../../../stores/LanguageStore";
import { Box, Button, Stack, Text } from "@mantine/core";
import ErrorImage503 from "./ErrorImage503";

const MaintenancePage = () => {
  const lang = useLanguage((s) => s.language);
  return (
    <Stack w={"100%"} h={"90vh"} align="center" justify="center">
      <Box w={450}>
        <ErrorImage503 />
      </Box>
      <Text fz={28} fw={500}>
        {lang.errors.page.maintenance}
      </Text>

      <Text fz={16} fw={500} w={"50%"} ta={"center"}>
        {lang.errors.page.maintenance_text}
      </Text>

      <Text fz={16} fw={400} w={"50%"} ta={"center"} mb={16}>
        {lang.errors.page.thanks}.
      </Text>

      <Button
        leftSection={<IconRefresh />}
        onClick={() => window.location.reload()}
      >
        {lang.errors.page.refresh}
      </Button>
    </Stack>
  );
};

export default MaintenancePage;
