import { ActionIcon, Indicator, Menu, Text } from "@mantine/core";
import { IconCheck, IconLink } from "@tabler/icons-react";
import { useFlowStore } from "../../../../../../../stores/WorkflowStore";
import { useEffect } from "react";

const LinksSelector = ({ which_links_clicked, id, sourceModel }) => {
  const updateNodeData = useFlowStore((s) => s.updateNodeData);

  useEffect(() => {
    if (sourceModel?.length === 0) {
      updateNodeData(id, { which_links_clicked: sourceModel?.links });
    }
  }, [id, sourceModel, updateNodeData]);

  const handleItemClick = (link) => {
    let newValues = [];
    if (which_links_clicked.includes(link)) {
      newValues = which_links_clicked?.filter((x) => x !== link);
    } else {
      newValues = [...which_links_clicked, link];
    }
    updateNodeData(id, { which_links_clicked: newValues });
  };

  return (
    <Menu shadow="md" closeOnItemClick={false} openDelay={200} trigger="hover" closeDelay={200}>
      <Menu.Target>
        <Indicator
          color="red"
          inline
          size={16}
          label={which_links_clicked?.length}
          withBorder
          disabled={
            which_links_clicked ? which_links_clicked?.length === 0 : true
          }
          mt={"auto"}
        >
          <ActionIcon size={"sm"} variant="light" >
            <IconLink size={16} />
          </ActionIcon>
        </Indicator>
      </Menu.Target>

      <Menu.Dropdown>
        {sourceModel?.links?.map((link) => (
          <Menu.Item
            key={link}
            onClick={() => handleItemClick(link)}
            leftSection={
              which_links_clicked?.includes(link) ? (
                <IconCheck size={12} />
              ) : null
            }
          >
            <Text truncate size="sm" maw={500}>{link}</Text>
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};

export default LinksSelector;
