import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Group, Text } from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";

const FilterDateRangeSelector = ({ disabled, setValue, value }) => {
  const [dates, setDates] = useState(
    value ? value?.split(";") : [null, null]
  );

  useEffect(() => {
    let d1 = dayjs(dates[0]);
    let d2 = dayjs(dates[1]);
    setValue([d1?.toISOString(), d2?.toISOString()].join(";"));
  }, [dates]);

  return (
    <Group wrap="nowrap">
      <DateTimePicker
        placeholder={"Date 1"}
        w={110}
        value={new Date(dates[0])}
        disabled={disabled}
        onChange={(d) => {
          setDates((prev) => [d, prev[1]]);
        }}
        valueFormat={"DD / MM / YYYY"}
        popoverProps={{
          zIndex: 1500,
        }}
        timeInputProps={{
          disabled: true,
        }}
      />

      <Text>à</Text>

      <DateTimePicker
        placeholder={"Date 2"}
        timeInputProps={{
          disabled: true,
        }}
        w={110}
        value={new Date(dates[1])}
        disabled={disabled}
        onChange={(d) => {
          setDates((prev) => [prev[0], d]);
        }}
        valueFormat={"DD / MM / YYYY"}
        popoverProps={{
          zIndex: 1500,
        }}
      />
    </Group>
  );
};

export default FilterDateRangeSelector;
