import { useLanguage } from "../stores/LanguageStore"

export function getStringFromError(error) {
  const strings = useLanguage.getState().language.errors.main
  if (!error?.response?.data)
    return strings["unknown"]

  let message = error?.response.data?.state_message || ""

  return strings?.[message] || `${error.response.status}: ${strings["unknown"]} ${message}`
}