import { useState } from "react";
import { updateProfile, updateSelf } from "../../api/UserAPI";
import { useProfileStore } from "../../stores/UserStore";
import { useLanguage } from "../../stores/LanguageStore";
import { Checkbox } from "@mantine/core";

const DontShowAgain = ({ type = "step_delete", label }) => {
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);
  const [loading, setLoading] = useState(false);

  const handleCheck = (e) => {
    const v = e.target.checked;

    const newValues = profile?.dont_show_values || {};

    newValues[type] = v;

    setLoading(true);
    updateProfile(null, { dont_show_values: newValues }).then(() => {
      updateSelf()
        .then(() => {
          setLoading(false);
        })
        .catch(() => setLoading(false));
    });
  };

  if (profile?.dont_show_values?.[type] && !label) {
    return "";
  }

  return (
    <Checkbox
      checked={!!profile?.dont_show_values?.[type]}
      label={label ? label : lang.global.dont_show_again}
      onChange={handleCheck}
      disabled={loading}
      mr={"auto"}
    />
  );
};

export default DontShowAgain;
