import { Handle, Position, getOutgoers } from "reactflow";
import { getNodeDetails } from "../flowSettings";
import NodeBrancherCreator from "../components/NodeBranchCreator";
import { useMemo } from "react";
import { useFlowStore } from "../../../../../../stores/WorkflowStore";
import EventNode from "./event/EventNode";
import ActionNode from "./ActionNode";
import NodeId from "./NodeId";
import NodeDeleter from "../components/NodeDeleter";
import ContactNumber from "../components/ContactNumber";
import LinkerOverlay from "../components/LinkerOverlay";

const BaseNode = ({ data, id, xPos, yPos }) => {
  const nodes = useFlowStore((s) => s.nodes);
  const edges = useFlowStore((s) => s.edges);

  const linking = useFlowStore((s) => s.linking);

  const outgoers = useMemo(() => {
    let n = nodes.find((x) => x.id === id);
    if (n)
      return getOutgoers(n, nodes, edges).filter((x) => x.type !== "trail");
    return [];
  }, [id, nodes, edges]);

  const nodeDetail = useMemo(
    () => getNodeDetails(data.actionType),
    [data.actionType]
  );

  const nodeComponent = useMemo(() => {
    if (data?.actionType === "event") {
      return (
        <EventNode
          data={data}
          id={id}
          nodeDetail={nodeDetail}
          xPos={xPos}
          yPos={yPos}
          outgoers={outgoers}
        />
      );
    }
    return (
      <ActionNode
        data={data}
        xPos={xPos}
        yPos={yPos}
        id={id}
        nodeDetail={nodeDetail}
        outgoers={outgoers}
      />
    );
  }, [data, id, nodeDetail, outgoers, xPos, yPos]);

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        style={{ visibility: "hidden" }}
      />

      {nodeComponent}

      {outgoers.length > 0 && !linking && (
        <NodeBrancherCreator
          source={id}
          sourceX={xPos}
          sourceY={yPos}
          shouldBeEvent={data?.actionType !== "event"}
          error={data?.error}
        />
      )}

      <ContactNumber number={data?.number_contacts || 0} />

      <NodeDeleter
        actionType={data.actionType}
        id={id}
        source={data.source}
        outgoers={outgoers}
        disabled={data?.number_contacts > 0}
      />

      <NodeId id={id} />

      <Handle
        type="source"
        position={Position.Bottom}
        style={{ visibility: "hidden" }}
      />

      <LinkerOverlay
        isEvent={data?.actionType === "event"}
        id={id}
        data={data}
        xPos={xPos}
        yPos={yPos}
        active={linking?.id === id}
      />
    </>
  );
};

export default BaseNode;
