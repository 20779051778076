import { useLanguage } from "../../../../../stores/LanguageStore";
import { Group, Paper, Text, Tooltip } from "@mantine/core";
import { types } from "./jobTypes";
import { parseDate } from "../../../../../functions/dates";

const JobItemCancelled = ({ item }) => {
  const lang = useLanguage((s) => s.language);
  const startDate = item.start_date
    ? new Date(Date.parse(item.start_date))
    : null;
  const queuedDate = item?.queued_date
    ? new Date(Date.parse(item.queued_date))
    : null;
  const endDate = new Date(Date.parse(item.end_date));

  return (
    <Paper withBorder p={"sm"} bg={"#00000006"}>
      <Group>
        <Group direction="row" gap={4} mr={1}>
          {types(item.type)?.icon}

          <Text fw={500} fz={14}>
            {types(item.type, false, item?.arguments?.generateEmail)?.label}
          </Text>
        </Group>
        <Text fz={10} style={{ opacity: 0.75 }}>
          {queuedDate && (
            <>
              <b>{lang.contact_lists.jobs.queued_date}</b>{" "}
              <Tooltip label={queuedDate.toLocaleString()}>
                <span style={{ marginRight: 8 }}>{parseDate(queuedDate)}</span>
              </Tooltip>
            </>
          )}
          {startDate && (
            <>
              <b>{lang.contact_lists.jobs.start_date}</b>{" "}
              <Tooltip label={startDate.toLocaleString()}>
                <span style={{ marginRight: 8 }}>{parseDate(startDate)}</span>
              </Tooltip>
            </>
          )}
          <b>{lang.contact_lists.jobs.end_date}</b>{" "}
          <Tooltip label={endDate.toLocaleString()}>
            <span>{parseDate(endDate)}</span>
          </Tooltip>{" "}
        </Text>
      </Group>
    </Paper>
  );
};

export default JobItemCancelled;
