import { useDisclosure } from "@mantine/hooks";
import { useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { ActionIcon, Button, Group, Modal, Text, Tooltip } from "@mantine/core";
import { IconArchiveOff } from "@tabler/icons-react";
import { unarchiveCampaign } from "../../../../../api/StatsAPI";
import { notifications } from "@mantine/notifications";

const UnarchiveCampaign = ({ current, disabled, onFinish, apiRef }) => {
  const lang = useLanguage((s) => s.language);
  const [opened, { open, close }] = useDisclosure();
  const [loading, setLoading] = useState(false);

  const main = () => {
    setLoading(true);
    unarchiveCampaign({
      workflow_id: current.workflow_id,
      programmation_id: current.id,
    })
      .then((res) => {
        close();
        notifications.show({
          message: lang.statistics.unarchive.success,
          color: "tertiary.8",
        });

        let selected = Array.from(apiRef.current.getSelectedRows().keys());
        apiRef.current.setRowSelectionModel(
          selected.filter((x) => x !== current.id)
        );
        document.querySelectorAll(`[data-id='${current.id}']`).forEach((el) => {
          el.style.animation = `forwards 1.5s deletedAnim ease`;
        });
        setTimeout(() => {
          apiRef.current.updateRows([
            { id: current.id, _action: "delete" },
            { id: `#${current.id}`, _action: "delete" },
          ]);
        }, 750);
        onFinish?.();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Button
        onClick={open}
        leftSection={<IconArchiveOff size={18} />}
        fw={600}
        color="tertiary.8"
        variant="subtle"
        justify="start"
        disabled={disabled}
      >
        {lang.statistics.unarchive.label}
      </Button>

      <Modal
        opened={opened}
        onClose={close}
        title={
          <Text size="md" truncate="end">
            {lang.statistics.unarchive.dialog.title} #{current?.id}{" "}
            {lang.statistics.unarchive.dialog.of_workflow}{" "}
            {current?.workflow_name} #{current?.workflow_id}
          </Text>
        }
        centered
        styles={{
          content: {
            boxShadow: "red 0 0 6px",
            border: "1px solid #a00",
          },
          title: {
            maxWidth: "92%",
          },
        }}
      >
        <Text size="sm">
          {lang.statistics.unarchive.dialog.text} <br />
          <b>{lang.statistics.unarchive.dialog.text2}</b>
        </Text>

        <Group justify="end" mt={"sm"}>
          <Button variant="subtle" onClick={close} disabled={loading}>
            {lang.components.deletion_action.dialog.cancel_button}
          </Button>
          <Button color="tertiary.8" onClick={main} loading={loading}>
            {lang.statistics.unarchive.dialog.submit_button}
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export default UnarchiveCampaign;
