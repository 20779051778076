import { useGridApiRef } from "@mui/x-data-grid-pro";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useContactHandler } from "../components/useContactHandler";
import {
  deleteContact,
  deleteFilterContacts,
  downloadFilterListAll,
  searchAllContactlist,
} from "../../../../api/ContactAPI";
import { Anchor, Group } from "@mantine/core";
import MainTable from "../../../../layouts/Table/MainTable";
import { useCallback, useMemo } from "react";
import ItemDeleter from "../../../../components/TableComponents/ItemDeleter";
import TableFilterCopyContacts from "../components/copy/TableFilterCopyContacts";
import CreateListMenu from "../components/CreateListMenu";
import TableLayoutBlock from "../../../../layouts/Table/TableLayoutBlock";
import { Link } from "react-router-dom";
import ListTopMenu from "./ListTopMenu";
import numberColumn from "../../../../layouts/Table/columns/numberColumn";
import UnsubscribeProspect from "../../leadsTracking/prospect/actions/UnsubscribeProspect";
import PageTitle from "../../../../components/View/PageTitle";
import TableFilterDelete from "../../../../components/TableComponents/TableFilterDelete";
import TableFilterExport from "../../../../components/TableComponents/TableFilterExport";

const AllListSearch = () => {
  const lang = useLanguage((s) => s.language);
  const apiRef = useGridApiRef();

  const startingColumns = useMemo(
    () => [
      numberColumn(),
      {
        field: "contact_list_id",
        id: "contact_list_id",
        headerName: lang.prm.details.campaigns_table.contact_list,
        filterable: false,
        sortable: false,
        width: 150,
        renderCell: ({ row, value }) => {
          return (
            <Anchor
              component={Link}
              to={`/contact-lists/${value}`}
              target="_blank"
              c="inherit"
              fw={500}
              truncate="end"
            >
              {row.contact_list_name}
            </Anchor>
          );
        },
      },
    ],
    [lang.prm.details.campaigns_table.contact_list]
  );

  const {
    loading,
    columns,
    rows,
    rowCount,
    serverInfo,
    handleLoading,
    onFilterChange,
    onPaginationChange,
    onSortChange,
  } = useContactHandler({
    apiRef,
    getFirstPage: searchAllContactlist,
    searchFunction: searchAllContactlist,
    startingColumns,
  });

  const actionsCell = useCallback(
    ({ row, value }) => {
      return (
        <>
          <UnsubscribeProspect current={row} closeMenu={() => {}} inList />
          <ItemDeleter
            title_bold={`${row["%first_name%"]} ${row["%last_name%"]}`}
            handleDelete={deleteContact}
            deletedMessage={lang.contact_lists.delete_success}
            parentId={row.contact_list_id}
            id={row.id}
            apiRef={apiRef}
          />
        </>
      );
    },
    [apiRef, lang.contact_lists.delete_success]
  );

  const toolbarActions = useCallback(
    ({ selectedIds, filter }) => (
      <>
        <TableFilterCopyContacts
          isGlobal
          selectedIds={selectedIds}
          filter={filter}
          name={"ALl list search"}
          filterResultNumber={rowCount}
          total={serverInfo?.number_of_results}
        />
        <TableFilterExport
          selectedIds={selectedIds}
          filter={filter}
          handleExport={downloadFilterListAll}
          name={"All List"}
          filterResultNumber={rowCount}
          total={rowCount}
        />
        <TableFilterDelete
          isGlobal
          selectedIds={selectedIds}
          filter={filter}
          handleDelete={deleteFilterContacts}
          apiRef={apiRef}
          onFinish={(type) => handleLoading(type !== "selection")}
          filterResultNumber={rowCount}
        />
      </>
    ),
    [apiRef, handleLoading, rowCount, serverInfo?.number_of_results]
  );

  const params = new URLSearchParams(location.search);
  const initialQuery = params.get("query");
  const initialFilter = params.get("filter");

  if (!columns) {
    return;
  }

  return (
    <>
      <Group className="layout-block top" w={"100%"} justify="space-between">
        <PageTitle title={lang.contact_lists.tabs_title.all_contacts} />

        <ListTopMenu />

        <CreateListMenu isSearch />
      </Group>

      <TableLayoutBlock>
        <MainTable
          isClient={false}
          apiRef={apiRef}
          columns={columns}
          rows={rows}
          rowCount={rowCount}
          loading={loading}
          setLoading={() => {
            handleLoading();
          }}
          id={"list-table"}
          pageName={"contact_lists_contacts"}
          onPaginationChange={onPaginationChange}
          actionsCell={actionsCell}
          onFilterChange={onFilterChange}
          onSortChange={onSortChange}
          // pageOrderName="contact_list_fetch_contacts_order"
          // desiredOrder={desiredOrder}
          // desiredVisibleColumns={desiredOrder}
          toolbarActions={toolbarActions}
          defaultSearch={initialQuery}
          defaultFilter={initialFilter ? JSON.parse(initialFilter) : undefined}
          actionsCellWidth={75}
        />
      </TableLayoutBlock>
    </>
  );
};

export default AllListSearch;
