import { rem } from "@mantine/core";
import { useSessionStore } from "../../stores/UserStore";

export function IconMagileads({ size, style, ...others }) {
  const reseller = useSessionStore.getState().reseller;

  return reseller?.name === "Magileads" ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 90 40.56"
      style={{ width: rem(size), height: rem(size), ...style }}
      {...others}
    >
      <path fill="#fcb614" d="M31.85,23.92,8.24,0,0,8.13,23.61,32.05Z" />
      <path
        fill="#fcb614"
        d="M81.56,35.45a16.65,16.65,0,0,1-24,0L41.72,19.16a5.33,5.33,0,0,0-7.68,0,5.68,5.68,0,0,0,0,7.9,5.32,5.32,0,0,0,7.68,0l1.13-1.15L51,34.3l-1.12,1.15a16.65,16.65,0,0,1-24,0,17.8,17.8,0,0,1,0-24.68c3.2-3.3,12-10.77,12-10.77s8.59,8.24,11.8,11.54l12,12,4,3.47h0a5.34,5.34,0,0,0,7.69,0,5.7,5.7,0,0,0,0-7.9,5.34,5.34,0,0,0-7.69,0l-1.13,1.15-8.15-8.39,1.12-1.15C60.77,7.47,70,0,70,0s8.34,7.74,11.54,11a17,17,0,0,1,5,12.07A17.58,17.58,0,0,1,81.56,35.45Z"
      />
    </svg>
  ) : (
    <img src={reseller?.logo_menu_url} width={size} />
  );
}
