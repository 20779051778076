import { useLanguage } from "../../../../../../stores/LanguageStore";
import { ActionIcon, Tooltip } from "@mantine/core";
import { useFlowStore } from "../../../../../../stores/WorkflowStore";
import { IconMessages, IconMessagesOff } from "@tabler/icons-react";

const ConversationButton = ({ id, data }) => {
  const lang = useLanguage((s) => s.language);
  const updateNodeData = useFlowStore((s) => s.updateNodeData);

  const handleClick = (e) => {
    e.stopPropagation();
    updateNodeData(id, {
      show_reply_as_conversation: !data?.show_reply_as_conversation,
    });
  };

  return (
    <Tooltip label={lang.workflows.step_options.show_reply_as_conversation}>
      <ActionIcon
        size={"sm"}
        variant={data?.show_reply_as_conversation ? "light" : "subtle"}
        pos={"absolute"}
        right={60}
        top={2}
        onClick={handleClick}
        opacity={data?.show_reply_as_conversation ? 1 : 0.5}
      >
        {data?.show_reply_as_conversation ? (
          <IconMessages size={16} />
        ) : (
          <IconMessagesOff size={16} />
        )}
      </ActionIcon>
    </Tooltip>
  );
};

export default ConversationButton;
