import { useModelsStore } from "../stores/ConfigStore";
import { mainAxios as axios } from "./axios";
//#region Emails
export const getMEmails = async () => {
  let response = await axios.get(`/models/email`);

  if (response.data.state) {
    useModelsStore.getState().setModelEmails(response.data.models_list);
  }
  return response;
};

export const findMEmail = async (id) => {
  return axios.get(`/models/email/${id}`);
};
export const findMEmailAndGenerate = async ({ id, contact_id }) => {
  return axios.get(`/models/email/${id}/${contact_id}/regenerate`);
};
export const findMEmailAndRegenerate = async ({ id, contact_id }) => {
  return axios.get(`/models/email/${id}/${contact_id}/regenerate`);
};

export const createMEmail = async ({
  name,
  subject,
  text,
  html,
  template,
  sharing,
  tags_ids,
  folder_id,
  is_ai,
  ai_number_paragraphs,
  ai_language,
  ai_prompt,
  ai_temperature
}) => {
  return axios.post(`/models/email`, {
    name: name,
    subject: subject,
    text: text,
    html: html,
    json_template: template,
    tags_ids: tags_ids,
    folder_id: folder_id,
    sharing: sharing,
    is_template: !!template,
    is_ai,
    ai_number_paragraphs,
    ai_language,
    ai_prompt,
    ai_temperature
  });
};

export const editMEmail = async ({
  id,
  name,
  subject,
  text,
  html,
  template,
  sharing,
  tags_ids,
  folder_id,
  is_template,
  ai_number_paragraphs,
  ai_language,
  ai_prompt,
  ai_temperature
}) => {
  return axios.put(`/models/email/${id}`, {
    name: name,
    subject: subject,
    text: text,
    html: html,
    json_template: template,
    tags_ids: tags_ids,
    folder_id: folder_id,
    sharing: sharing,
    is_template: is_template,
    ai_number_paragraphs,
    ai_language,
    ai_prompt,
    ai_temperature
  });
};

export const deleteMEmail = async ({ id }) => {
  return axios.delete(`/models/email/${id}`);
};

export const sendTestEmail = async ({
  subject,
  text,
  html,
  sender,
  emails,
  email_signature_id,
  contact_list_id,
}) => {
  return axios.post(`/models/email/send/test`, {
    subject,
    text,
    html,
    sender,
    emails,
    email_signature_id,
    contact_list_id,
  });
};

export const sendTestEmailFromModel = async ({
  model_id,
  sender,
  emails,
  email_signature_id,
  contact_list_id,
}) => {
  return axios.post(`/models/email/${model_id}/send/test`, {
    sender,
    emails,
    email_signature_id,
    contact_list_id,
  });
};

export const copyEmail = async ({ id, user_id }) => {
  if (user_id) {
    return axios.post(`/models/email/${id}/copy/to/${user_id}`);
  }
  return axios.post(`/models/email/${id}/copy`);
};
export const getHtmlFromUrl = async (url) => {
  return axios.post(`/models/email/get/html/from/url`, { url });
};
export const generateEmail = async ({
  name,
  prompt,
  language,
  number_paragraphs,
  model_id,
  isHtml,
  temperature
}) => {
  return axios.post(`/models/email/generate${isHtml ? "/html" : ""}`, {
    name,
    prompt,
    language,
    number_paragraphs,
    model_id,
    temperature
  });
};
//#endregion

//#region LinkedIn Invitation
export const getLinkedinInvitations = async () => {
  let response = await axios.get(`/models/linkedin/invitation`);

  if (response.data.state) {
    useModelsStore
      .getState()
      .setModelLinkedinInvitations(response.data.models_list);
  }
  return response;
};

export const findLinkedinInvitation = async (id) => {
  return axios.get(`/models/linkedin/invitation/${id}`);
};

export const createLinkedinInvitation = async ({
  name,
  text,
  sharing,
  tags_ids,
  folder_id,
}) => {
  return axios.post(`/models/linkedin/invitation`, {
    name: name,
    text: text,
    tags_ids: tags_ids,
    folder_id: folder_id,
    sharing: sharing,
  });
};

export const editLinkedinInvitation = async ({
  id,
  name,
  text,
  sharing,
  tags_ids,
  folder_id,
}) => {
  return axios.put(`/models/linkedin/invitation/${id}`, {
    name: name,
    text: text,
    tags_ids: tags_ids,
    folder_id: folder_id,
    sharing: sharing,
  });
};

export const deleteLinkedinInvitation = async ({ id }) => {
  return axios.delete(`/models/linkedin/invitation/${id}`);
};

export const copyLinkedinInvitation = async ({ id, user_id }) => {
  if (user_id) {
    return axios.post(`/models/linkedin/invitation/${id}/copy/to/${user_id}`);
  }
  return axios.post(`/models/linkedin/invitation/${id}/copy`);
};
//#endregion

//#region LinkedIn Message
export const getLinkedinMessages = async () => {
  let response = await axios.get(`/models/linkedin/message`);

  if (response.data.state) {
    useModelsStore
      .getState()
      .setModelLinkedinMessages(response.data.models_list);
  }
  return response;
};

export const findLinkedinMessage = async (id) => {
  return axios.get(`/models/linkedin/message/${id}`);
};

export const createLinkedinMessage = async ({
  name,
  text,
  sharing,
  tags_ids,
  folder_id,
}) => {
  return axios.post(`/models/linkedin/message`, {
    name: name,
    text: text,
    tags_ids: tags_ids,
    folder_id: folder_id,
    sharing: sharing,
  });
};

export const editLinkedinMessage = async ({
  id,
  name,
  text,
  sharing,
  tags_ids,
  folder_id,
}) => {
  return axios.put(`/models/linkedin/message/${id}`, {
    name: name,
    text: text,
    tags_ids: tags_ids,
    folder_id: folder_id,
    sharing: sharing,
  });
};

export const deleteLinkedinMessage = async ({ id }) => {
  return axios.delete(`/models/linkedin/message/${id}`);
};

export const copyLinkedinMessage = async ({ id, user_id }) => {
  if (user_id) {
    return axios.post(`/models/linkedin/message/${id}/copy/to/${user_id}`);
  }
  return axios.post(`/models/linkedin/message/${id}/copy`);
};
//#endregion

//#region SMS
export const getSMS = async () => {
  let response = await axios.get(`/models/sms`);

  if (response.data.state) {
    useModelsStore.getState().setModelSms(response.data.models_list);
  }
  return response;
};

export const findSMS = async (id) => {
  return axios.get(`/models/sms/${id}`);
};

export const createSMS = async ({
  name,
  text,
  sender,
  sharing,
  tags_ids,
  folder_id,
}) => {
  return axios.post(`/models/sms`, {
    name: name,
    sender: sender,
    text: text,
    tags_ids: tags_ids,
    folder_id: folder_id,
    sharing: sharing,
  });
};

export const editSMS = async ({
  id,
  name,
  text,
  sender,
  sharing,
  tags_ids,
  folder_id,
}) => {
  return axios.put(`/models/sms/${id}`, {
    name: name,
    text: text,
    sender: sender,
    tags_ids: tags_ids,
    folder_id: folder_id,
    sharing: sharing,
  });
};

export const deleteSMS = async ({ id }) => {
  return axios.delete(`/models/sms/${id}`);
};

export const copySMS = async ({ id, user_id }) => {
  if (user_id) {
    return axios.post(`/models/sms/${id}/copy/to/${user_id}`);
  }
  return axios.post(`/models/sms/${id}/copy`);
};
//#endregion

//#region SMV
export const getSMV = async () => {
  let response = await axios.get(`/models/smv`);

  if (response.data.state) {
    useModelsStore.getState().setModelSmv(response.data.models_list);
  }
  return response;
};

export const findSMV = async (id) => {
  return axios.get(`/models/smv/${id}`);
};

export const createSMV = async (formData) => {
  return axios.post(`/models/smv`, formData, {
    headers: {
      accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  });
};

export const editSMV = async ({
  id,
  name,
  voice_file,
  sharing,
  tags_ids,
  folder_id,
}) => {
  let formData = new FormData();
  let parsedSharing = sharing?.map((x) => JSON.stringify(x));
  if (voice_file) formData.append("voice_file", voice_file);
  if (name) formData.append("name", name || null);
  if (sharing) formData.append("sharing", parsedSharing);
  if (folder_id) formData.append("folder_id", folder_id);
  if (tags_ids) formData.append("tags_ids", tags_ids);

  return axios.post(`/models/smv/${id}`, formData, {
    headers: {
      accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteSMV = async ({ id }) => {
  return axios.delete(`/models/smv/${id}`);
};

export const copySMV = async ({ id, user_id }) => {
  if (user_id) {
    return axios.post(`/models/smv/${id}/copy/to/${user_id}`);
  }
  return axios.post(`/models/smv/${id}/copy`);
};
//#endregion
