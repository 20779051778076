import { Group, Paper, Text, ThemeIcon } from "@mantine/core";
import { isMainAction, nodeWidth } from "../flowSettings";
import { useMemo } from "react";
import { useFlowStore } from "../../../../../../stores/WorkflowStore";
import EmailNodeBody from "./EmailNodeBody";
import { useReactFlow } from "reactflow";
import LinkedinNodeBody from "./LinkedinNodeBody";
import NodeAdvancedSettingsButton from "../components/NodeAdvancedSettingsButton";
import ConversationButton from "../components/ConversationButton";

const ActionNode = ({ data, id, nodeDetail, xPos, yPos }) => {
  const selectedNode = useFlowStore((s) => s.selectedNode);
  const setSelectedNode = useFlowStore((s) => s.setSelectedNode);
  const reactflow = useReactFlow();

  const getAdditionalInfo = useMemo(() => {
    if (data?.actionType === "email") {
      return <EmailNodeBody node_id={id} data={data} xPos={xPos} yPos={yPos} />;
    }
    if (data?.actionType.includes("linkedin") || data?.actionType === "sms") {
      return (
        <LinkedinNodeBody node_id={id} data={data} xPos={xPos} yPos={yPos} />
      );
    }
    return null;
  }, [id, data]);

  const handleHeadClick = ({ toAdvanced }) => {
    setSelectedNode({ data, id, toAdvanced: toAdvanced });
    setTimeout(() => {
      reactflow?.setCenter(xPos + 142, yPos + 132, {
        duration: 500,
        zoom: 1,
      });
      // reactflow?.fitView({ nodes: [{ data, id }], duration: 500 });
    }, 201);
  };

  return (
    <Paper
      w={nodeWidth}
      withBorder
      className="action-node"
      p={"xs"}
      pt={6}
      pb={4}
      shadow={selectedNode?.id === id ? "md" : "sm"}
      pos={"relative"}
      style={{
        borderColor: data?.error ? "red" : nodeDetail.color,
        borderWidth: selectedNode?.id === id ? 3 : data?.error ? 2 : 1,
      }}
      radius={"md"}
    >
      <Group
        className="head"
        onClick={isMainAction({ data, id }) ? handleHeadClick : undefined}
        style={{ cursor: isMainAction({ data, id }) ? "pointer" : "default" }}
        id={"node_head_" + id}
        gap={"xs"}
      >
        <ThemeIcon variant="light" color={nodeDetail.color} size={"md"}>
          {nodeDetail.icon}
        </ThemeIcon>
        <Text fz={"sm"} ta={"center"}>
          {nodeDetail.label}
        </Text>
        {nodeDetail?.sub_label && (
          <Text fz={"sm"} ta={"center"} fw={400} mb={4}>
            {nodeDetail.sub_label}
          </Text>
        )}
        {data?.source !== "0" && data?.actionType === "email" && (
          <ConversationButton data={data} id={id} />
        )}
        {data?.source !== "0" && isMainAction({ data, id }) && (
          <NodeAdvancedSettingsButton
            handleClick={(e) => {
              e.stopPropagation();
              handleHeadClick({ toAdvanced: true });
            }}
          />
        )}
      </Group>
      {getAdditionalInfo ? <Group mt={4}>{getAdditionalInfo}</Group> : null}
    </Paper>
  );
};

export default ActionNode;
