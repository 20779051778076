import { Select } from "@mantine/core";
import { useMemo } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";

const DatabaseCondition = ({ value, onChange }) => {
  const lang = useLanguage((s) => s.language);
  const data = useMemo(() => {
    let labels = {
      contains: lang.table.toolbar.filter.filters_list_label.server.contains,
      starts_with:
        lang.table.toolbar.filter.filters_list_label.server.start_with,
      ends_with: lang.table.toolbar.filter.filters_list_label.server.end_with,
      exact_match: lang.table.toolbar.filter.filters_list_label.server.equals,
      exists: lang.table.toolbar.filter.filters_list_label.server.does_exist,
    };
    return Object.keys(labels).map((key) => ({
      value: key,
      label: labels[key],
    }));
  }, [lang]);

  return (
    <Select
      data={data}
      value={value}
      onChange={onChange}
      size="xs"
      pos={"absolute"}
      top={-30}
      fw={500}
      right={0}
      w={120}
      style={{
        zIndex: 10,
      }}
      styles={{
        input: {
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: 0,
          borderBottom: 0,
          height: 24,
        },
      }}
    />
  );
};

export default DatabaseCondition;
