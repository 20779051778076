import { useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { notifications } from "@mantine/notifications";
import { editStatus } from "../../../../../api/PRMAPI";
import { ActionIcon, ColorInput, Group, Tooltip } from "@mantine/core";
import { IconDeviceFloppy, IconPalette } from "@tabler/icons-react";
import PopOver from "../../../../../components/View/PopOver";

const EditStatusColor = ({ id, statusColor, handleRefresh, disabled }) => {
  const lang = useLanguage((s) => s.language);
  const [color, setColor] = useState(
    statusColor ? "#" + statusColor : "#333333"
  );
  const [loading, setLoading] = useState(false);

  const handleEdit = (handleClose) => {
    setLoading(true);
    editStatus({
      id,
      color: color.substring(1),
      isSystem: typeof id === "string",
    })
      .then((r) => {
        notifications.show({ message: lang.prm.status.edited, color: "tertiary.8" });
        handleClose();
        handleRefresh("color", color.substring(1));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <PopOver
      opener={(handleOpen, ref) => (
        <Tooltip label={lang.prm.status.color} disabled={disabled}>
          <ActionIcon
            ref={ref}
            onClick={handleOpen}
            disabled={disabled}
            size={"sm"}
            variant="light"
            color={"secondary"}
          >
            <IconPalette size={16} />
          </ActionIcon>
        </Tooltip>
      )}
      popoverChildren={(handleClose) => (
        <Group align="end">
          <ColorInput
            label={lang.prm.status.color}
            placeholder={lang.prm.status.color}
            required
            value={color}
            onChange={setColor}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleEdit(handleClose);
              }
            }}
            popoverProps={{ zIndex: 1500 }}
            swatches={[
              "#2e2e2e",
              "#868e96",
              "#fa5252",
              "#e64980",
              "#be4bdb",
              "#7950f2",
              "#4c6ef5",
              "#228be6",
              "#15aabf",
              "#12b886",
              "#40c057",
              "#82c91e",
              "#fab005",
              "#fd7e14",
            ]}
          />
          <ActionIcon
            onClick={() => handleEdit(handleClose)}
            loading={loading}
            size={"lg"}
          >
            <IconDeviceFloppy size={18} />
          </ActionIcon>
        </Group>
      )}
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      transformOrigin={{ horizontal: "center", vertical: "top" }}
    />
  );
};

export default EditStatusColor;
