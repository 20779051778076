import { Center, Group, SegmentedControl } from "@mantine/core";
import { IconList, IconListDetails } from "@tabler/icons-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLanguage } from "../../../../stores/LanguageStore";

const ListTopMenu = () => {
  const lang = useLanguage((s) => s.language);
  const location = useLocation();
  const locations = location.pathname.split("/").filter(Boolean);
  const actualLocation = locations[locations.length - 1];

  const navigate = useNavigate();
  return (
    <SegmentedControl
      ml={"auto"}
      size="sm"
      w={400}
      data={[
        {
          label: (
            <Center component={Group}>
              <IconList size={18} />

              <span>{lang.contact_lists.tabs_title.user}</span>
            </Center>
          ),
          value: "contact-lists",
        },
        {
          label: (
            <Center component={Group}>
              <IconListDetails size={18} />

              <span>{lang.contact_lists.tabs_title.all_contacts}</span>
            </Center>
          ),
          value: "all-contacts",
        },
      ]}
      value={actualLocation}
      onChange={(v) => {
        if (v === "contact-lists") {
          navigate("/contact-lists");
        } else {
          navigate("/contact-lists/all-contacts");
        }
      }}
    />
  );
};

export default ListTopMenu;
