import { useEffect, useMemo, useState } from "react";
import MultiSelector from "../../../../../components/Selectors/MultiSelector";
import {
  Anchor,
  Button,
  Group,
  Stack,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { IconInfoCircle } from "@tabler/icons-react";
import { getBlacklists } from "../../../../../api/BlacklistAPI";
import { useConfigStore } from "../../../../../stores/ConfigStore";

function getOptionName(option) {
  return `${option.name} ( ${option.number_of_data} )`;
}

const BlacklistsExclusion = ({ programData, setProgramData }) => {
  const lang = useLanguage((s) => s.language);
  const blacklistsStore = useConfigStore((s) => s.blacklists);
  const [blacklists, setBlacklists] = useState(
    programData?.blacklist_ids?.map((listId) => {
      let list = blacklistsStore.find((x) => x.id === listId);
      if (list) {
        return {
          ...list,
          option_name: getOptionName(list),
        };
      }
      return;
    }) || []
  );

  const options = useMemo(() => {
    return blacklistsStore.map((list) => ({
      ...list,
      option_name: getOptionName(list),
    }));
  }, [blacklistsStore]);

  const [loading, setLoading] = useState(false);

  const selectAll = () => {
    setProgramData({
      ...programData,
      blacklist_ids: options.map((x) => x.id),
    });
  };

  useEffect(() => {
    setLoading(true);
    getBlacklists().finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setBlacklists(
      programData?.blacklist_ids
        ?.map((listId) => {
          let list = blacklistsStore?.find((x) => x.id === listId);
          if (list) {
            return {
              ...list,
              option_name: getOptionName(list),
            };
          }
          return;
        })
        ?.filter(Boolean) || []
    );
  }, [blacklistsStore, programData]);

  return (
    <Stack h={"100%"} w={"40%"}>
      <Title order={5}>{lang.campaign.exclusions.blacklist_title}</Title>

      <Group gap={6} wrap="nowrap">
        <ThemeIcon
          variant="subtle"
          opacity={0.5}
          color="black"
          p={0}
          size={"md"}
        >
          <IconInfoCircle />
        </ThemeIcon>
        <Text size="sm">{lang.campaign.exclusions.blacklist_text}</Text>
      </Group>
      <MultiSelector
        options={options}
        loading={loading}
        values={blacklists}
        onChange={(v) =>
          setProgramData({
            ...programData,
            blacklist_ids: v.map((x) => x.id),
          })
        }
        placeholder={lang.campaign.exclusions.search_blacklist}
        rightOptionSection={(item) => {
          return (
            item?.id && (
              <Anchor
                href={`/exclusions/blacklists?highlight=${item.id}`}
                fz={10}
                target="_blank"
                onClick={(e) => e.stopPropagation()}
                // opacity={0.35}
              >
                #{item.id}
              </Anchor>
            )
          );
        }}
        size={"sm"}
        w={"100%"}
        searchable
        checkIconPosition="left"
        footer={
          <Button variant="subtle" onClick={selectAll} size="compact-sm">
            {lang.global.select_all}
          </Button>
        }
      />
    </Stack>
  );
};

export default BlacklistsExclusion;
