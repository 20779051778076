import { Link, useParams } from "react-router-dom";
import { getTypeIcon } from "../../../workflows/CreateCampaign/flow/flowSettings";
import { Button, Tooltip } from "@mantine/core";

const CreateMassMailing = ({ buttonProps, iconSize = 18 }) => {
  const { poolId } = useParams();
  const route = poolId ? `/senders/email/pool/${poolId}` : `/senders/email`;

  return (
    <Button
      leftSection={getTypeIcon(
        "MassMailing",
        false,
        iconSize,
        { variant: "subtle", color: "primary" },
        true
      )}
      component={Link}
      to={`${route}/create/massmailing`}
      variant="subtle"
      w={"100%"}
      justify="start"
      c={"var(--mantine-color-gray-7)"}
      {...buttonProps}
    >
      Mass Mailing
    </Button>
  );
};

export default CreateMassMailing;
