import {
  Anchor,
  Button,
  Flex,
  Group,
  Stack,
  Text,
  ThemeIcon,
} from "@mantine/core";
import { useMemo } from "react";
import { useLanguage } from "../../stores/LanguageStore";
import { getPermission } from "../../functions/utilFunctions";
import { IconArrowBadgeUpFilled } from "@tabler/icons-react";
import { modals } from "@mantine/modals";
import BackButton from "../../components/View/BackButton";
import LimitReachedIllustration from "./LimitReachedIllustration";

const ErrorModal = ({ message, isReseller }) => {
  const lang = useLanguage((s) => s.language);
  const text = useMemo(() => {
    let texts = lang.errors.modal.texts;

    function isMail() {
      let keywords = ["mail", "mass", "SMTP", "gmail", "outlook"];

      if (message.includes("mail")) return true;
      return false;
    }

    function isEnrich() {
      if (message.includes("gener")) return true;
      return false;
    }

    function isLinkedin() {
      if (message.includes("inkedin")) return true;
      return false;
    }

    if (isMail()) {
      return texts["email" + (isReseller ? "_reseller" : "")];
    }
    if (isEnrich()) {
      return texts["enrich" + (isReseller ? "_reseller" : "")];
    }
    if (isLinkedin()) {
      return texts["linkedin" + (isReseller ? "_reseller" : "")];
    }
    return texts.other;
  }, [isReseller, lang.errors.modal.texts, message]);

  const handleClose = () => {
    modals.close("error-modal");
  };

  return (
    <Stack gap={0}>
      <Group justify="center">
        <Text c={"red"} size="xl" ta={"center"}>
          {text}
        </Text>
        <Flex w={200}>
          <LimitReachedIllustration />
        </Flex>
      </Group>

      {getPermission("displayPricing") && (
        <Group wrap="nowrap" mt={28}>
          <Stack gap={"md"} w={"100%"}>
            <Text size="md" ta={"center"}>{lang.errors.modal.you_can_add}</Text>

            <Group justify="center">
              <Anchor href={"/account-settings/subscription"} target="_blank">
                <Button
                  size="sm"
                  leftSection={
                    <ThemeIcon variant={"subtle"} size={28} color={"white"}>
                      <IconArrowBadgeUpFilled size={20} />
                    </ThemeIcon>
                  }
                  onClick={handleClose}
                >
                  {lang.errors.modal.upgrade}
                </Button>
              </Anchor>
            </Group>
          </Stack>
        </Group>
      )}

      <Group justify="center" mt={24}>
        <BackButton onClick={handleClose} />
      </Group>
    </Stack>
  );
};

export default ErrorModal;
