import { useEffect, useState } from "react";
import { getProfile } from "../../../api/UserAPI";
import { useProfileStore } from "../../../stores/UserStore";
import { Group } from "@mantine/core";
import AdminPanelLeft from "./left/AdminPanelLeft";
import AdminPanelCenter from "./center/AdminPanelCenter";
import AdminPanelRight from "./right/AdminPanelRight";

const AdminPanel = ({ defaultUser }) => {
  const me = useProfileStore((s) => s.profile);
  const [current, setCurrent] = useState(defaultUser || me);
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [leftLoading, setLeftLoading] = useState(true);
  const [list, setList] = useState([]);

  useEffect(() => {
    setLoading(true);
    setProfile(null);
    if (current?.toDelete) {
      setList((prev) => prev.filter((x) => x.id !== current.toDelete.id));
    }
    getProfile(current?.id)
      .then((response) => {
        setProfile(response.data.user_profile);
      })
      .finally(() => setLoading(false));
  }, [current]);

  return (
    <Group flex={"1 1 auto"} align="start" wrap="nowrap" gap={0} h={0}>
      {!defaultUser && (
        <AdminPanelLeft
          list={list}
          setList={setList}
          current={current}
          setCurrent={setCurrent}
          loading={leftLoading}
          setLoading={setLeftLoading}
        />
      )}
      <AdminPanelCenter
        setCurrent={setCurrent}
        loading={loading}
        profile={profile}
        setProfile={setProfile}
        me={me}
      />
      <AdminPanelRight
        changingProfile={loading}
        profile={profile}
        setProfile={setProfile}
      />
    </Group>
  );
};

export default AdminPanel;
