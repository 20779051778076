import { useParams } from "react-router-dom";
import { useLanguage } from "../../../../stores/LanguageStore";
import {
  useLeadsContainerStore,
  useLeadsStore,
} from "../../../../stores/LeadsStore";
import { useCallback, useState } from "react";
import { deleteProspects, deleteProspectsUser } from "../../../../api/PRMAPI";
import { notifications } from "@mantine/notifications";
import { IconTrash } from "@tabler/icons-react";
import { Button, Group, Modal, Text, Tooltip } from "@mantine/core";
import BackButton from "../../../../components/View/BackButton";

const defaults = ["opener", "clicker", "answerer", "to_call"];

const LeadsDelete = ({ handleDone }) => {
  const lang = useLanguage((s) => s.language);
  const { userId } = useParams();
  const filter = useLeadsStore((s) => s.filter);
  const searchQuery = useLeadsStore((s) => s.query);

  const selectedIds = useLeadsContainerStore((s) => s.selectedIds);
  const setSelectedIds = useLeadsContainerStore((s) => s.setSelectedIds);
  const unselectedIds = useLeadsContainerStore((s) => s.unselectedIds);
  const setUnselectedIds = useLeadsContainerStore((s) => s.setUnselectedIds);
  const selectedColumns = useLeadsContainerStore((s) => s.selectedColumns);
  const setContainers = useLeadsContainerStore((s) => s.setContainers);
  const setSelectedColumns = useLeadsContainerStore(
    (s) => s.setSelectedColumns
  );
  const containers = useLeadsContainerStore((s) => s.containers);
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpen = () => {
    setOpened(true);
  };

  const handleClose = () => {
    setOpened(false);
  };

  const handleDeleteDone = () => {
    let newContainers = [...containers];
    // Delete individual IDS
    let selectedIdsColumns = Object.keys(selectedIds);
    selectedIdsColumns.forEach((c) => {
      let i = newContainers.findIndex((x) => x.id.toString() === c);
      if (i > -1) newContainers[i].loading = true;
    });
    selectedColumns.forEach((s) => {
      let i = newContainers.findIndex((x) => x.id.toString() === s);
      if (i > -1) newContainers[i].loading = true;
    });
    return setContainers(newContainers);
  };

  const submitDelete = () => {
    const deleteFunc = userId ? deleteProspectsUser : deleteProspects;
    let mainFilterValues = [];
    let excluded = [];
    if (selectedColumns.length > 0) {
      selectedColumns.forEach((col) => {
        if (defaults.includes(col)) {
          let v = [
            {
              field_name: "status",
              type: "equals",
              value: col,
            },
            {
              field_name: "custom_status",
              type: "equals",
              value: "null",
            },
          ];

          if (searchQuery) {
            v.push({
              field_name: "any_datafield",
              type: "equals",
              value: searchQuery,
            });
          }

          if (filter) {
            v.push(filter);
          }

          mainFilterValues.push({
            mode: "and",
            values: v,
          });
        } else {
          let v = [
            {
              field_name: "custom_status",
              type: "equals",
              value: col.toString(),
            },
          ];

          if (filter) {
            v.push(filter);
          }

          mainFilterValues.push({
            mode: "and",
            values: v,
          });
        }
        if (unselectedIds[col]) {
          excluded.push(...unselectedIds[col]);
        }
      });
    }

    let parsedFilter = {
      mode: "or",
      values: mainFilterValues,
    };

    setLoading(true);
    deleteFunc({
      user_id: parseInt(userId),
      filter: parsedFilter,
      contact_ids: Object.values(selectedIds).flatMap((x) => x),
      excluded_contact_ids: excluded,
    })
      .then((res) => {
        notifications.show({
          message: lang.prm.deletion.deleted,
          color: "tertiary.8",
        });
        handleClose();
        handleDeleteDone();
        setSelectedIds([]);
        setUnselectedIds([]);
        setSelectedColumns([]);
      })
      .finally(() => setLoading(false));
  };

  const getSelected = useCallback(() => {
    let final = 0;
    containers.forEach((container) => {
      if (selectedColumns.includes(container.id)) {
        let total_col = container.total;
        let total_unselected = unselectedIds[container.id]?.length || 0;

        final += total_col - total_unselected;
      } else {
        final += selectedIds[container.id]?.length || 0;
      }
    });

    return final;
  }, [containers, selectedColumns, unselectedIds, selectedIds]);

  const canDelete = () => {
    if (Object.values(selectedIds).flatMap((x) => x).length > 0) {
      return true;
    }
    if (selectedColumns.length > 0) {
      return true;
    }

    return false;
  };

  return (
    <>
      <Tooltip label={lang.prm.toolbar.delete_tooltip}>
        <Button
          className="toolbar-action-button"
          variant="light"
          color="red"
          justify="start"
          style={{ borderTopWidth: 1, borderTopRightRadius: 4 }}
          onClick={handleOpen}
          disabled={!canDelete()}
        >
          <IconTrash size={18} />
        </Button>
      </Tooltip>

      <Modal
        opened={opened}
        onClose={handleClose}
        title={lang.prm.toolbar.deletion_title}
        size={"40%"}
      >
        <Text>
          {lang.prm.toolbar.delete_text1}{" "}
          <b>
            {getSelected()
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
            {lang.prm.toolbar.delete_text2}
          </b>{" "}
          <br />
          <b>{lang.prm.toolbar.delete_text_warning}</b>
        </Text>

        <Group justify="end" mt={"md"}>
          <BackButton onClick={handleClose} />

          <Button
            onClick={submitDelete}
            loading={loading}
            color="red"
            leftSection={<IconTrash size={18} />}
          >
            {lang.prm.toolbar.delete}
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export default LeadsDelete;
