import {
  ActionIcon,
  Anchor,
  Button,
  Container,
  Group,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { Logo } from "../../layouts/Logo";
import { Link } from "react-router-dom";
import { IconSearch } from "@tabler/icons-react";
import { useSessionStore } from "../../stores/UserStore";

const Header = () => {
  const reseller = useSessionStore((s) => s.reseller);
  return (
    <Group
      bg={
        reseller?.color_muted !== "A8A8A8"
          ? `#${reseller.color_muted}`
          : `linear-gradient(90deg, var(--mantine-color-primary-4), var(--mantine-color-primary-3))`
      }
      pb={16}
    >
      <Container size={"xl"} w={"100%"}>
        <Stack gap={0}>
          <Group justify="space-between" p={"md"} w={"100%"}>
            <Link to={"/support"}>
              <Group w={300}>
                <Logo w={"100%"} h={52} sm />
              </Group>
            </Link>

            <Group>
              <Button
                variant="subtle"
                component={Link}
                to={"/login"}
                color="white"
              >
                Se connecter
              </Button>
              <Button
                variant="light"
                color="white"
                component={Anchor}
                underline="never"
                href={`mailto:contact@${reseller.domain_saas
                  .split(".")
                  .slice(1, 3)
                  .join(".")}`}
              >
                Nous contacter
              </Button>
            </Group>
          </Group>

          <Stack justify="center" align="center" pb={16}>
            <Title order={1} c={"white"}>
              Comment pouvons-nous vous aider ?
            </Title>

            <TextInput
              mt={"lg"}
              placeholder="Rehercher ..."
              radius={"sm"}
              size="lg"
              w={"45%"}
              rightSection={
                <ActionIcon radius={"sm"} size={"xl"} variant="subtle">
                  <IconSearch />
                </ActionIcon>
              }
              styles={{
                input: {
                  paddingLeft: 28,
                },
              }}
            />

            <Text c={"white"}>
              Article populaire:{" "}
              <Anchor
                c={"secondary.3"}
                component={Link}
                to={"/support/contact-lists/import-linkedin"}
              >
                {" "}
                Créer une liste à partir de LinkedIn
              </Anchor>
            </Text>
          </Stack>
        </Stack>
      </Container>
    </Group>
  );
};

export default Header;
