import { useState } from "react";
import { useLanguage } from "../../../stores/LanguageStore";
import PR1 from "./PR1";
import PR2 from "./PR2";
import PRFinish from "./PRFinish";
import { Box, Group, Stack, Stepper, Title } from "@mantine/core";
import { Logo } from "../../../layouts/Logo";
import LanguageChanger from "../LanguageChanger";
import RecoveryIllustrattion from "./RecoveryIllustrattion";

const PasswordRecovery = () => {
  const lang = useLanguage((s) => s.language);
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => {
    setActiveStep((prev) => prev + 1);
  };

  return (
    <Group w={"100vw"} h={"100vh"} grow gap={0}>
      <Stack
        bg={"#f7f7f7"}
        h={"100%"}
        align="center"
        justify="center"
        w={"100%"}
      >
        <Box w={500}>
          <RecoveryIllustrattion />
        </Box>
      </Stack>

      <Stack
        bg={"var(--mantine-color-body)"}
        h={"100%"}
        align="center"
        justify="center"
        gap={8}
        w={"100%"}
        pos={"relative"}
      >
        <Logo w={200} />
        <Title order={2} mt={8}>
          {lang.password_recovery.step_verify.title}
        </Title>

        <Stepper active={activeStep} w={"75%"} mt={16}>
          <Stepper.Step label={lang.password_recovery.step_verify.step_name}>
            <PR1 handleNext={handleNext} />
          </Stepper.Step>
          <Stepper.Step label={lang.password_recovery.step_edit.step_name}>
            <PR2 handleNext={handleNext} />
          </Stepper.Step>
          <Stepper.Completed
            label={lang.password_recovery.step_finish.step_name}
          >
            <PRFinish />
          </Stepper.Completed>
        </Stepper>

        <LanguageChanger />
      </Stack>
    </Group>
  );
};

export default PasswordRecovery;
