import { useEffect, useMemo, useState } from "react";
import { getProfile, updateSelf } from "../../api/UserAPI";
import { useLANGUAGE, useLanguage } from "../../stores/LanguageStore";
import { useProfileStore, useSessionStore } from "../../stores/UserStore";
import { MainLayout } from "../../layouts/MainLayout";
import { useNavigate } from "react-router-dom";
import { getFields } from "../../api/ContactAPI";
import { useUIStore } from "../../stores/UIStore";
import { Box } from "@mui/material";
import { getOtherPrms } from "../../api/PRMAPI";
import FallbackPage from "../unprotected/errors/FallbackPage";

const params = [
  {
    url: "activate",
    value: "api_key",
  },
  {
    url: "disableNav",
    value: "disable_nav",
  },
  {
    url: "disableHeading",
    value: "disable_heading",
  },
  {
    url: "disableActions",
    value: "disable_actions",
  },
  {
    url: "disableSelections",
    value: "disable_selections",
  },
];

const Protected = () => {
  const reseller = useSessionStore((state) => state.reseller);
  const session = useSessionStore((state) => state.session);
  document.title = ` ${reseller.name}`;
  const navigate = useNavigate();
  const apiKey = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("apiKey");
  }, []);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    //#region Part disable
    // document.body.style.overflow = "hidden";
    const urlParams = new URLSearchParams(window.location.search);

    params.forEach((p) => {
      localStorage.setItem(
        p.value,
        urlParams.get(p.url) ? true : localStorage.getItem(p.value)
      );
    });

    if (urlParams.get("activate") === "true") {
      localStorage.setItem("api_key", urlParams.get("apiKey"));
    }

    if (urlParams.get("clearAll") === "true") {
      params.forEach((p) => {
        localStorage.setItem(p.value, false);
      });
    }
    //#endregion

    const setWindowLoading = useUIStore.getState().setWindowLoading;
    setWindowLoading(true);
    const setMainLoading = useUIStore.getState().setMainLoading;
    const setAsideLoading = useUIStore.getState().setAsideLoading;
    setMainLoading(false);
    if (!apiKey && !session) {
      return navigate(`/login?destination=${window.location.pathname}`);
    }
    getProfile(null, false)
      .then((p) => {
        let new_profile = p.data.user_profile;
        const unswitchedProfile = useProfileStore.getState().unswitchedProfile;
        const setProfile = useProfileStore.getState().setProfile;
        setProfile(new_profile);

        if (useSessionStore.getState().user_switch) {
          useLanguage.getState().changeLanguage(unswitchedProfile?.language);
          useLANGUAGE.getState().changeLanguage(unswitchedProfile?.language);
        }
        else {
          useLanguage.getState().changeLanguage(new_profile?.language);
          useLANGUAGE.getState().changeLanguage(new_profile?.language);

        }

        updateSelf(true);
      })
      .finally(() => {
        setLoading(false);
        setWindowLoading(false);
        setAsideLoading(true);
      });

    getFields();
    getOtherPrms();

    // Community Auto join
    const team_code = urlParams.get("team_code");
    const organization_code = urlParams.get("organization_code");
    if (team_code || organization_code) {
      navigate(
        `/community/join?team_code=${team_code || ""}&organization_code=${organization_code || ""
        }`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box position={"relative"}>
      <FallbackPage />

      {!loading && <MainLayout />}
    </Box>
  );
};

export default Protected;
