import { Button, Tooltip } from "@mantine/core";
import { useLanguage } from "../../../stores/LanguageStore";
import { IconRefresh } from "@tabler/icons-react";

const TableReload = ({ setLoading }) => {
  const lang = useLanguage((s) => s.language);
  return (
    <Tooltip label={lang.table.toolbar.refresh_toolip}>
      <Button
        className="toolbar-action-button"
        variant="outline"
        radius={0}
        style={{
          borderTopRightRadius: 4,
          borderBottomRightRadius: 0,
        }}
        size="small"
        onClick={() => setLoading(true)}
        px={"xs"}
        color="tertiary.8"
      >
        <IconRefresh size={18} />
      </Button>
    </Tooltip>
  );
};

export default TableReload;
