import { ActionIcon, Tooltip } from "@mantine/core";
import { IconRefresh } from "@tabler/icons-react";
import { useLeadsContainerStore } from "../../../../stores/LeadsStore";
import { useLanguage } from "../../../../stores/LanguageStore";

const ReloadButton = ({ container }) => {
  const lang = useLanguage((s) => s.language);
  const setReloadAll = useLeadsContainerStore((s) => s.setReloadAll);

  return (
    <Tooltip label={lang.prm.container.refresh}>
      <ActionIcon
        onClick={setReloadAll}
        disabled={container?.loading}
        variant="subtle"
        mih={0}
        h={26}
      >
        <IconRefresh size={16} />
      </ActionIcon>
    </Tooltip>
  );
};

export default ReloadButton;
