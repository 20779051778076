import {
  Button,
  Divider,
  Group,
  PasswordInput,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { IconMail, IconSettings } from "@tabler/icons-react";
import CreateGmail from "./CreateGmail";
import CreateOutlook from "./CreateOutlook";
import {
  createSMTP,
  discoverEmail,
  getEmails,
} from "../../../../../api/EmailAPI";
import { useState } from "react";
import { defaultSMTPConfigs } from "./defaultConfigs";
import { notifications } from "@mantine/notifications";
import CreateSMTP from "./CreateSMTP";
import { useLanguage } from "../../../../../stores/LanguageStore";

const CreateEmailSender = ({ onCreated, handleClose, ...props }) => {
  const lang = useLanguage((s) => s.language);
  const [isAdvanced, setIsAdvanced] = useState(false);
  const [discoverError, setDiscoverError] = useState(false);
  const [smtpError, setSmtpError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSimpleConnect = (e) => {
    e.preventDefault();
    let fd = new FormData(e.target);
    let data = Object.fromEntries(fd);

    setLoading(true);
    discoverEmail(data?.email)
      .then((response) => {
        let settings = response.data.email_server_settings;

        setLoading(true);
        createSMTP({
          ...defaultSMTPConfigs,
          name: data?.email,
          email: data?.email,
          smtp_username: data?.email,
          smtp_password: data?.password,
          smtp_host: settings?.smtp_host,
          smtp_port: settings?.smtp_port,
          smtp_secure: settings?.smtp_secure,
          imap_username: data?.email,
          imap_password: data?.password,
          imap_host: settings?.imap_host,
          imap_port: settings?.imap_port,
          imap_secure: settings?.imap_secure,
        })
          .then((response) => {
            getEmails()
              .then((res) => {
                let newItemsList = res.data.email_accounts_list;
                let newItem = newItemsList.find(
                  (x) => x.id === response.data.email_account_id
                );
                notifications.show({
                  title: lang.emails_account.success,
                  message: lang.emails_account.success_text,
                  color: "tertiary.8",
                });
                onCreated(newItem);
                handleClose?.();
              })
              .finally(() => {
                setLoading(false);
              });
          })
          .catch((err) => {
            let message = err.response.data.state_message;
            setLoading(false);

            if (message.includes("credentials")) {
              setSmtpError(true);
            }
            setDiscoverError(true);
          });
      })
      .catch((err) => {
        setDiscoverError(true);
        setLoading(false);
      });
  };

  return isAdvanced ? (
    <CreateSMTP
      setIsAdvanced={setIsAdvanced}
      handleClose={handleClose}
      onCreated={onCreated}
      {...props}
    />
  ) : (
    <Stack w={480} gap={0} {...props}>
      <Group justify="space-between" wrap="nowrap">
        <CreateGmail onCreated={onCreated} handleClose={handleClose} />
        <CreateOutlook onCreated={onCreated} handleClose={handleClose} />
      </Group>

      <Button
        variant="default"
        w={"100%"}
        h={120}
        mt={16}
        px={0}
        styles={{
          label: {
            justifyContent: "center",
          },
        }}
        radius={"lg"}
        onClick={() => setIsAdvanced(true)}
        loading={loading}
      >
        <Group>
          <IconMail size={32} />

          <Text size="xl">Autre email</Text>
        </Group>
      </Button>

      {/* <Divider label="Ou" my={"md"} />
      <Text mb={"sm"} size="lg">
        {lang.home.create_campaign.step2.other}
      </Text>
      <form onSubmit={handleSimpleConnect}>
        <Stack>
          <TextInput
            label={lang.login.mail}
            required
            size="md"
            name="email"
            type="email"
            placeholder={lang.login.mail}
            onChange={() => setDiscoverError(false)}
            error={
              discoverError
                ? lang.home.create_campaign.step2.error_discover
                : null
            }
          />
          <PasswordInput
            label={lang.login.password}
            placeholder={lang.login.password}
            required
            name="password"
            size="md"
            autoComplete="new-password"
            onChange={() => setSmtpError(false)}
            error={
              smtpError ? lang.home.create_campaign.step2.error_auth : null
            }
          />
        </Stack>
        <Group mt={"lg"} justify="space-between">
          <Button
            variant="subtle"
            leftSection={<IconSettings />}
            onClick={() => setIsAdvanced(true)}
          >
            {lang.home.create_campaign.step2.more}
          </Button>
          <Button size="md" type="submit" loading={loading}>
            {lang.home.create_campaign.step2.connect}
          </Button>
        </Group>
      </form> */}
    </Stack>
  );
};

export default CreateEmailSender;
