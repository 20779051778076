import { Button, Group, PinInput, Stack, Text, Title } from "@mantine/core";
import Path from "../../../layouts/Path";
import { useEffect, useState } from "react";
import { joinOrg, joinTeam } from "../../../api/UserAPI";
import { useNavigate } from "react-router-dom";

const JoinPage = () => {
  const [teamCode, setTeamCode] = useState("");
  const [orgCode, setOrgCode] = useState("");

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const handleJoin = () => {
    async function main() {
      let res;
      if (teamCode) {
        res = await joinTeam(teamCode);
      }
      if (orgCode) {
        res = await joinOrg(orgCode);
      }

      return res;
    }

    setLoading(true);
    main()
      .then((response) => {
        navigate("/community");
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);

    const team_code = urlParams.get("team_code");
    const organization_code = urlParams.get("organization_code");
    setTeamCode(team_code);
    setOrgCode(organization_code);
  }, []);

  return (
    <>
      <Group
        className="layout-block top"
        w={"100%"}
        gap={0}
        justify="space-between"
      >
        <Path />
      </Group>

      <Stack w={450} mx={"auto"} className="layout-block">
        <Title order={4}> Joindre une communauté </Title>
        <Text>Vous êtes invité à joindre :</Text>

        {teamCode && (
          <Stack>
            <Text fw={600}> L'équipe dont le code est </Text>
            <PinInput value={teamCode} onChange={setTeamCode} length={6} />
          </Stack>
        )}

        {orgCode && (
          <Stack>
            <Text fw={600}> L'organisation dont le code est </Text>
            <PinInput value={orgCode} onChange={setOrgCode} length={6} />
          </Stack>
        )}

        <Button onClick={handleJoin} loading={loading} mb={8}>
          Joindre
        </Button>
      </Stack>
    </>
  );
};

export default JoinPage;
