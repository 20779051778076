import { Button, Stack, Tooltip } from "@mantine/core";
import { IconSettings } from "@tabler/icons-react";
import { defaultButtonType } from "../../initialState";
import PopOver from "../../../../components/View/PopOver";
import TableDensity from "./TableDensity";
import TableConfigSaver from "../columns/TableConfigSaver";
import { useLanguage } from "../../../../stores/LanguageStore";

const TableConfigs = () => {
  const lang = useLanguage((s) => s.language);
  return (
    <PopOver
      opener={(openFunction, ref) => (
        <Tooltip label={lang.table.more_settings}>
          <Button
            ref={ref}
            onClick={openFunction}
            radius={0}
            variant={defaultButtonType}
            style={{ borderLeft: 0, borderBottomRightRadius: 4 }}
            className="toolbar-action-button"
            px={8}
          >
            <IconSettings size={18} />
          </Button>
        </Tooltip>
      )}
      popoverChildren={(closePopover) => (
        <Stack gap={0}>
          <TableDensity />

          <TableConfigSaver />
        </Stack>
      )}
    />
  );
};

export default TableConfigs;
