import { Button, Group, Stack, TextInput, Title } from "@mantine/core";
import { IconList } from "@tabler/icons-react";
import { Link, useNavigate, useRouteLoaderData } from "react-router-dom";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useState } from "react";
import TagsRessourceSelector from "../../../../components/Selectors/TagsRessourceSelector";
import FolderSelector from "../../../../components/Selectors/FolderSelector";
import { SharingInAccordion } from "../../../../components/Sharing/SharingController";
import { useForm } from "react-hook-form";
import { createList } from "../../../../api/ContactAPI";
import { notifications } from "@mantine/notifications";
import PageTitle from "../../../../components/View/PageTitle";

export const listParentRoute = "/contact-lists";

const CreateEmptyList = ({ onFinish }) => {
  const lang = useLanguage((s) => s.language);
  const folderProfile = useRouteLoaderData("list-folder");
  const [tags, setTags] = useState([]);
  const [folder, setFolder] = useState(folderProfile || null);
  const sharingForm = useForm({
    defaultValues: {
      sharing: [],
    },
  });

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    let fd = new FormData(e.target);
    let data = Object.fromEntries(fd);

    const sharing = sharingForm
      .getValues()
      .sharing.filter((x) => x.sharing_targets.length > 0)
      .map((share) => ({
        ...share,
        ids_list: share.sharing_targets.map((x) => x.id),
        sharing_targets: undefined,
      }));

    setLoading(true);
    createList({
      name: data?.name,
      folder_id: folder?.id || null,
      tags_ids: tags?.map((x) => x.id),
      sharing,
    })
      .then((response) => {
        setTimeout(() => {
          notifications.show({
            message: lang.contact_lists.row_highlight.created,
            color: "tertiary.8",
          });
        }, 300);
        onFinish?.(response.data.contact_list_id);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Group className="layout-block top" w={"100%"} justify="space-between">
        <PageTitle title={lang.contact_lists.create.dialog.title} />
      </Group>

      <Stack
        className="layout-block"
        w={"40%"}
        mx={"auto"}
        gap={"xs"}
        component={"form"}
        onSubmit={handleSubmit}
      >
        <Title order={5}>{lang.contact_lists.create.empty}</Title>
        <TextInput
          name="name"
          label={lang.contact_lists.create.dialog.name}
          placeholder={lang.contact_lists.create.dialog.name}
          required
          leftSection={<IconList size={16} />}
          autoFocus
        />
        <TagsRessourceSelector
          value={tags}
          setValue={setTags}
          autoFocus={false}
        />

        <FolderSelector
          value={folder}
          setValue={setFolder}
          type={"ContactList"}
        />

        <SharingInAccordion form={sharingForm} />

        <Group justify="flex-end" mt={"md"}>
          <Button variant="subtle" component={Link} to={".."}>
            {lang.global.back}
          </Button>
          <Button type="submit" loading={loading}>
            {lang.contact_lists.create.dialog.submit_button}
          </Button>
        </Group>
      </Stack>
    </>
  );
};

export default CreateEmptyList;
