import { Button, Group, Modal, Text } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconAlertCircle, IconFolderDown } from "@tabler/icons-react";
import { useState } from "react";
import { useUIStore } from "../../stores/UIStore";
import BackButton from "../../components/View/BackButton";
import { useLanguage } from "../../stores/LanguageStore";

const DragConfirmation = ({
  dragged,
  setDragged,
  folder,
  editFunction,
  getEditFunction,
  apiRef,
  isPool,
}) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(false);
  const setAsideLoading = useUIStore((s) => s.setAsideLoading);
  const handleMove = () => {
    setLoading(true);
    const main = isPool ? getEditFunction(dragged) : editFunction;
    main({
      id: dragged.id,
      [isPool ? "pools_ids" : "folder_id"]: isPool
        ? [...(dragged?.pools || []).map((x) => x?.id), folder.id]
        : folder.id,
    })
      .then((response) => {
        notifications.show({
          message: isPool
            ? lang.components.folder_navigator.move.success_pool
            : lang.components.folder_navigator.move.success,
        });
        setDragged(null);
        if (isPool)
          apiRef?.current?.updateRows([
            { id: dragged.id, pools: [...(dragged.pools || []), folder] },
          ]);
        else
          apiRef?.current?.updateRows([{ id: dragged.id, _action: "delete" }]);
        setAsideLoading(true);
      })
      .finally(() => setLoading(false));
  };

  const handleClose = () => {
    setDragged(null);
  };

  return (
    <Modal
      opened={!!dragged}
      onClose={handleClose}
      title={
        isPool ? (
          <Text> {lang.components.folder_navigator.move.title_pool} </Text>
        ) : (
          <Text>{lang.components.folder_navigator.move.title}</Text>
        )
      }
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 3,
      }}
    >
      {dragged?.account_type === "MassMailing" ? (
        <Group gap={"xs"}>
          <IconAlertCircle size={24} />
          <Text>{lang.components.folder_navigator.move.no_massmailing}</Text>
        </Group>
      ) : (
        <Text>
          {lang.components.folder_navigator.move.text1} {folder?.type} :{" "}
          <b>
            {dragged?.name} #{dragged?.id}
          </b>{" "}
          <br /> <br />
          {lang.components.folder_navigator.move.text2}{" "}
          {isPool ? "Pool" : lang.components.folder_navigator.column_name} :{" "}
          <b>
            {folder?.name} #{folder?.id}
          </b>{" "}
          ?
        </Text>
      )}

      <Group justify="flex-end" mt={"md"}>
        <BackButton onClick={handleClose} />
        {dragged?.account_type !== "MassMailing" && (
          <Button
            onClick={handleMove}
            loading={loading}
            leftSection={<IconFolderDown />}
          >
            {isPool
              ? lang.components.folder_navigator.move.add
              : lang.components.folder_navigator.move.move}
          </Button>
        )}
      </Group>
    </Modal>
  );
};

export default DragConfirmation;
