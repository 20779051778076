import { Stack, Text } from "@mantine/core";
import { useCallback } from "react";
import ContainerLoader from "./body/Loader";
import Listing from "./body/Listing";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useLeadsContainerStore } from "../../../../stores/LeadsStore";
import { requestNextPage } from "../../../../api/PRMAPI";
import { parseProspectDatafields } from "../utils";

const ContainerBody = ({ container }) => {
  const lang = useLanguage((s) => s.language);
  const updateContainer = useLeadsContainerStore((s) => s.updateContainer);

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = useCallback(
    (index) => {
      return !container.nextPageUrl || index < container?.items.length;
    },
    [container]
  );

  const loadNextPage = useCallback(
    (args) => {
      updateContainer(container.id, { loadingNext: false });

      if (container.nextPageUrl) {
        return requestNextPage(container.nextPageUrl).then((response) => {
          updateContainer(container.id, {
            nextPageUrl: response.data?.next_page,
            items: [
              ...container.items,
              ...response.data.results.map((item) =>
                parseProspectDatafields(item)
              ),
            ],
            loadingNext: false,
          });
        });
      }
    },
    [updateContainer, container.id, container.nextPageUrl, container.items]
  );

  const loadMoreItems = useCallback(async () => {
    return container.loadingNext ? () => {} : loadNextPage();
  }, [container, loadNextPage]);

  if (container.loading || !container.loaded) {
    return <ContainerLoader />;
  }
  if (container.total < 1) {
    return (
      <Stack h={"100%"} align={"center"} pt={64}>
        <Text opacity={0.5}>{lang.prm.no_prospect}</Text>
      </Stack>
    );
  } else {
    return (
      <Listing
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        container={container}
      />
    );
  }
};

export default ContainerBody;
