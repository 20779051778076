import { Button, Menu } from "@mantine/core";
import { IconChevronDown, IconPlus } from "@tabler/icons-react";
import CreateDropdown from "./CreateDropdown";
import { useLanguage } from "../../../../stores/LanguageStore";

const CreateEmailSender = ({ setTableLoading, inEdit }) => {
  const lang = useLanguage((s) => s.language);
  return (
    <Menu shadow="md" width={200} position="bottom-end" withArrow offset={2}>
      <Menu.Target>
        <Button
          leftSection={<IconPlus size={18} />}
          radius={"sm"}
          variant={inEdit ? "light" : "filled"}
          color="primary"
          ml={"auto"}
          rightSection={<IconChevronDown size={18} />}
        >
          {lang.senders.emails.add_email}
        </Button>
      </Menu.Target>

      <Menu.Dropdown>
        <CreateDropdown inMenu setTableLoading={setTableLoading} />
      </Menu.Dropdown>
    </Menu>
  );
};

export default CreateEmailSender;
