import { mainAxios as axios } from "./axios";

export const getProducts = async () => {
  return axios.get("/subscriptions/products");
};

export const subscribe = async ({ items, success_url, cancel_url }) => {
  return axios.post("/subscriptions/subscribe", {
    items,
    success_url,
    cancel_url,
  });
};
export const addOptions = async ({ items }) => {
  return axios.put("/subscriptions/add/options", {
    items,
  });
};
export const removeOptions = async ({ items }) => {
  return axios.delete("/subscriptions/remove/options", {
    data: {
      items,
    },
  });
};

export const unsubscribe = async () => {
  return axios.delete("/subscriptions/unsubscribe");
};

export const updateDefaultPayment = async (payment_method_id) => {
  return axios.post("/subscriptions/update/default-payment-method", {
    payment_method_id,
  });
};

export const addDefaultPayment = async ({ success_url, cancel_url }) => {
  return axios.post("/subscriptions/add/payment-method", {
    success_url,
    cancel_url,
  });
};
