import { Box, Button, Stack, Text } from "@mantine/core";
import { useProfileStore } from "../../../../stores/UserStore";
import { IconExternalLink } from "@tabler/icons-react";
import Sparkles from "../../../../components/Sparkles/Sparkles";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../../../stores/LanguageStore";
import { lazy } from "react";

const ResellerFinishIllustration = lazy(() =>
  import("./ResellerFinishIllustration")
);

const Step5 = ({ domain, setTableLoading }) => {
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);
  const navigate = useNavigate();

  return (
    <Stack w={"60%"} pos={"relative"}>
      <Text
        fz={28}
        className="animate__animated animate__fadeIn"
        style={{ animationDelay: ".1s" }}
      >
        {profile.first_name}, {lang.resellers.onboarding.step5.title}
      </Text>

      <Sparkles
        style={{
          width: "fit-content",
          margin: "auto auto",
          position: "absolute",
          left: 0,
          top: "25%",
        }}
      >
        <Button
          leftSection={<IconExternalLink size={22} />}
          variant="gradient"
          size={"xl"}
          radius="xl"
          className="sparkle-button animate__animated animate__fadeIn animate__delay-1s"
          onClick={() => {
            window.open("https://" + domain, "_blank");
            navigate("/resellers/white-labels?domain=" + domain);
            setTableLoading(true);
          }}
        >
          {lang.resellers.onboarding.step5.discover}
        </Button>
      </Sparkles>

      <Box
        w={"75%"}
        className="animate__animated animate__fadeIn"
        pos={"relative"}
        ml={"auto"}
        mr={-128}
      >
        <ResellerFinishIllustration />
      </Box>
    </Stack>
  );
};

export default Step5;
