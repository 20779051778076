import { useSortable } from "@dnd-kit/sortable";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { useState } from "react";
import { editStatus } from "../../../../../api/PRMAPI";
import { notifications } from "@mantine/notifications";
import {
  Group,
  Loader,
  Paper,
  Stack,
  Switch,
  Text,
  Tooltip,
} from "@mantine/core";
import { IconEye, IconEyeOff, IconGripVertical } from "@tabler/icons-react";
import { CSS } from "@dnd-kit/utilities";
import EditStatus from "./EditStatus";
import EditStatusColor from "./EditStatusColor";
import DeleteStatus from "./DeleteStatus";

const StatusItem = ({
  id,
  deletable,
  setItems,
  name,
  stat,
  visible,
  color,
  isCustomDefault,
}) => {
  const lang = useLanguage((s) => s.language);
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id });

  const handleRefresh = (key, newValue) => {
    if (key === "delete") {
      setItems((prev) => {
        let newItems = [...prev].filter((x) => x.id !== newValue);

        return newItems;
      });
    } else {
      setItems((prev) => {
        let newItems = [...prev];
        let index = newItems.findIndex((x) => x.id === id);
        newItems[index] = { ...newItems[index], [key]: newValue };

        return newItems;
      });
    }
  };

  const [loadingVisible, setLoadingVisible] = useState(false);
  const handleVisible = () => {
    setLoadingVisible(true);
    editStatus({ id, visible: !visible, isSystem: !deletable })
      .then((r) => {
        notifications.show({
          message: lang.prm.status.edited,
          color: "tertiary.8",
        });
        handleRefresh("visible", !visible);
      })
      .finally(() => setLoadingVisible(false));
  };

  return (
    <Paper
      component={Group}
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
        zIndex: 10,
        border: "1px solid #00000021",
        background: `#${color}16`,
      }}
      p={"xs"}
      radius={"md"}
      gap={"xs"}
      wrap="nowrap"
    >
      <IconGripVertical
        size={18}
        style={{ cursor: "grab", opacity: 0.5 }}
        {...attributes}
        {...listeners}
      />
      <Tooltip label={visible ? lang.prm.status.hide : lang.prm.status.show}>
        <Stack w={64} align={"center"} justify={"center"} mr={1}>
          <Switch
            size="sm"
            color={"tertiary.8"}
            defaultChecked={visible}
            onChange={handleVisible}
            disabled={loadingVisible}
            thumbIcon={
              loadingVisible ? (
                <Loader type="oval" size={11} />
              ) : visible ? (
                <IconEye size={12} color="tertiary.8" />
              ) : (
                <IconEyeOff size={12} color="red" />
              )
            }
          />
        </Stack>
      </Tooltip>
      <Group flex={"1 1 auto"} w={0}>
        <Text truncate="end">{name}</Text>
      </Group>

      <EditStatus
        id={id}
        statusName={name}
        handleRefresh={handleRefresh}
        disabled={!deletable}
      />

      <EditStatusColor
        id={id}
        statusColor={color}
        handleRefresh={handleRefresh}
      />

      <DeleteStatus
        disabled={!deletable || isCustomDefault}
        handleRefresh={handleRefresh}
        id={id}
        statusName={name}
        number={stat}
      />
    </Paper>
  );
};

export default StatusItem;
