import { useState } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import {
  Button,
  Group,
  ScrollArea,
  Stack,
  Switch,
  TextInput,
} from "@mantine/core";
import ColumnsSaver from "./ColumnsSaver";

const TableColumnsContent = ({
  columns,
  columnVisibility,
  onColumnCheck,
  hideAll,
  showAll,
  pageOrderName,
}) => {
  const lang = useLanguage((s) => s.language);
  const [searchResult, setSearchResult] = useState(columns);
  const onSearch = (e) => {
    const searchText = e.target.value;
    const results = columns.filter((column) => {
      return column.headerName.toLowerCase().includes(searchText);
    });
    setSearchResult(results);
  };

  return (
    <Stack gap={0}>
      <TextInput
        placeholder={lang.table.toolbar.columns.search_placeholder}
        onChange={onSearch}
        variant="unstyled"
        autoFocus
      />
      <Stack mah={150} component={ScrollArea}>
        {searchResult.map((column) => {
          return (
            <Switch
              label={column.headerName}
              key={column.field}
              onChange={(e) => onColumnCheck(column.field, e.target.checked)}
              checked={columnVisibility[column.field] === false ? false : true}
              size="xs"
              mt={8}
            />
          );
        })}
      </Stack>

      <Group justify="space-between" mt={"sm"}>
        <Button size="compact-xs" onClick={hideAll} variant="light">
          {lang.table.toolbar.columns.hide_all}
        </Button>
        <Button size="compact-xs" onClick={showAll} variant="light">
          {lang.table.toolbar.columns.show_all}
        </Button>
      </Group>

      {
        pageOrderName &&
        <ColumnsSaver
          columns={columns}
          columnVisibility={columnVisibility}
          pageOrderName={pageOrderName}
        />
      }
    </Stack>
  );
};

export default TableColumnsContent;
