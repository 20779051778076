import { Box, Button, Stack, Text } from "@mantine/core";
import ErrorImageNetwork from "./ErrorImageNetwork";
import { IconRefresh } from "@tabler/icons-react";

const NetworkError = () => {
  return (
    <Stack
      w={"100vw"}
      h={"100vh"}
      align="center"
      justify="center"
      pt={21}
      pos={"fixed"}
      top={0}
      left={0}
      style={{ zIndex: 9999 }}
      bg={"white"}
    >
      <Box w={325}>
        <ErrorImageNetwork />
      </Box>
      <Text fz={24} fw={500} mb={"md"}>
        Impossible de se connecter au serveur. <br />
        Vérifiez votre Internet ou votre pare-feu
      </Text>
      <Button
        leftSection={<IconRefresh />}
        onClick={() => window.location.reload()}
      >
        Rafraîchir la page
      </Button>
    </Stack>
  );
};

export default NetworkError;
