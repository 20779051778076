import { Group, Text, ThemeIcon, Tooltip } from "@mantine/core";
import {
  IconBrandLinkedin,
  IconBuilding,
  IconMail,
  IconUser,
} from "@tabler/icons-react";
import { useMemo, useState } from "react";
import Jobs from "../components/jobs/Jobs";
import DiffShow from "../../../../components/View/DiffShow";
import ListLanguage from "../components/ListLanguage";
import ListCountry from "../components/ListCountry";

const ListDetails = ({
  currentList,
  setCurrentList,
  serverInfo,
  handleLoading,
  handleGetList,
}) => {
  const [needReload, setNeedReload] = useState(false);
  const list = useMemo(() => {
    return [
      {
        label: "Contacts",
        value: "number_of_contacts",
        icon: (
          <ThemeIcon variant="light" radius="xl" color="tertiary.8">
            <IconUser size={18} strokeWidth={1.4} />
          </ThemeIcon>
        ),
      },
      {
        label: "Emails",
        value: "number_of_emails",
        icon: (
          <ThemeIcon variant="light" radius="xl" color="yellow">
            <IconMail size={18} strokeWidth={1.4} />
          </ThemeIcon>
        ),
      },
      {
        label: "LinkedIn URL",
        value: "number_of_linkedin_url",
        icon: (
          <ThemeIcon variant="light" radius="xl" color="blue">
            <IconBrandLinkedin size={18} strokeWidth={1.4} />
          </ThemeIcon>
        ),
      },
    ];
  }, []);

  return (
    <Group my={12} mx={"sm"} gap={"xl"}>
      <Group mr={"auto"}>
        {list.map((item) => (
          <Group gap={6} key={item.value}>
            <Tooltip label={item.label}>{item.icon}</Tooltip>

            <Text size="sm" fw={500}>
              {serverInfo[item.value] || " - "}
              {serverInfo[item.value] < currentList[item.value] &&
              serverInfo[item.value] ? (
                <b style={{ marginLeft: 10 }}>( {currentList[item.value]} )</b>
              ) : (
                ""
              )}
            </Text>
          </Group>
        ))}

        <Group gap={6}>
          <Tooltip label={"Entreprises"}>
            <ThemeIcon variant="light" color="red" radius="xl">
              <IconBuilding size={18} strokeWidth={1.4} />
            </ThemeIcon>
          </Tooltip>

          <Text size="sm" fw={500}>
            {currentList["number_of_companies"]}
          </Text>
        </Group>
      </Group>

      <DiffShow
        handleLoading={handleLoading}
        show={needReload}
        setShow={setNeedReload}
      />

      <Jobs
        row={currentList}
        setCurrentList={setCurrentList}
        isContent={true}
        onDiff={() => setNeedReload(true)}
      />

      <ListLanguage current={currentList} onFinish={handleGetList} />

      <ListCountry current={currentList} />
    </Group>
  );
};

export default ListDetails;
