import { Group } from "@mantine/core";
import BlacklistsExclusion from "./BlacklistsExclusion";
import SentCampaignExclusion from "./SentCampaignExclusion";

const CampaignExclusion = ({ programData, setProgramData }) => {
  return (
    <Group
      wrap="nowrap"
      w={"100%"}
      h={"100%"}
      justify="space-evenly"
      align="stretch"
      pb={"xs"}
    >
      <BlacklistsExclusion
        programData={programData}
        setProgramData={setProgramData}
      />

      <SentCampaignExclusion
        programData={programData}
        setProgramData={setProgramData}
      />
    </Group>
  );
};

export default CampaignExclusion;
