import { create } from "zustand";
import { persist } from "zustand/middleware";

const leadsStore = (set, get) => ({
  filter: null,
  setFilter: (newData) => set({ filter: newData }),
  filters: [],
  setFilters: (newData) => set({ filters: newData }, false, "setFilters"),
  activeFilter: null,
  setActiveFilter: (newData) => set({ activeFilter: newData }),
  query: "",
  setQuery: (newData) => set({ query: newData }),
  sharedConditions: null,
  setSharedConditions: (newData) => set({ sharedConditions: newData }),
  systemStatuses: [],
  setSystemStatuses: (newData) => set({ systemStatuses: newData }),
  customStatuses: [],
  setCustomStatuses: (newData) => set({ customStatuses: newData }),

  fields: [],
  setFields: (newData) => set({ fields: newData }),

  campaignOptions: [],
  setCampaignOptions: (newData) => set({ campaignOptions: newData }),

  listOptions: [],
  setListOptions: (newData) => set({ listOptions: newData }),

  workflowOptions: [],
  setWorkflowOptions: (newData) => set({ workflowOptions: newData }),

  integrationOptions: [],
  setIntegrationOptions: (newData) => set({ integrationOptions: newData }),

  usersOptions: [],
  setUsersOptions: (newData) => set({ usersOptions: newData }),

  zapierHooks: [],
  setZapierHooks: (newData) => set({ zapierHooks: newData }),

  prms: [],
  setPrms: (newData) => set({ prms: newData }),

  showPersonInCharge: true,
  setShowPersonInCharge: (newData) => set({ showPersonInCharge: newData }),

  sharings: [],
  setSharings: (newData) => set({ sharings: newData }),
});

const leadsContainerStore = (set, get) => ({
  containers: [],
  setContainers: (newData) => set({ containers: newData }),
  updateContainer: (id, data) =>
    set((s) => {
      let newContainers = [...s.containers];
      let index = newContainers.findIndex(
        (x) => x.id === id || x.status === id
      );

      newContainers[index] = {
        ...newContainers[index],
        ...data,
      };

      return {
        containers: newContainers,
      };
    }),

  updateContainerItem: (containerId, itemId, newData) =>
    set((s) => {
      let newContainers = [...s.containers];
      let index = newContainers.findIndex((x) => x.id === containerId);

      let itemIndex = newContainers[index].items.findIndex(
        (x) => x.id === itemId
      );
      newContainers[index].items[itemIndex] = {
        ...newContainers[index].items[itemIndex],
        ...newData,
      };

      return {
        containers: newContainers,
      };
    }),

  selectedIds: {},
  setSelectedIds: (newData) => set({ selectedIds: newData }),

  unselectedIds: {},
  setUnselectedIds: (newData) => set({ unselectedIds: newData }),

  selectedColumns: [],
  setSelectedColumns: (newData) => set({ selectedColumns: newData }),

  reloadAll: false,
  setReloadAll: (newData) => set({ reloadAll: newData }),
});

const leadSheetStore = (set, get) => ({
  opened: false,
  setOpened: (newData) => set({ opened: newData }),

  loading: false,
  setLoading: (newData) => set({ loading: newData }),

  currentContainer: null,
  setCurrentContainer: (newData) => set({ currentContainer: newData }),

  current: null,
  setCurrent: (newData) => set({ current: newData }),

  index: null,
  setIndex: (newData) => set({ index: newData }),
  nextProspect: () =>
    set((store) => ({
      index: store.index + 1,
      current: store.currentContainer.items[store.index + 1],
      loading: true,
    })),
  prevProspect: () =>
    set((store) => ({
      index: store.index - 1,
      current: store.currentContainer.items[store.index - 1],
      loading: true,
    })),

  activeTab: null,
  setActiveTab: (newData) => set({ activeTab: newData }),

  sort: "desc",
  setSort: (newData) => set({ sort: newData }),

  orgProspected: false,
  setOrgProspected: (newData) => set({ orgProspected: newData }),

  openedOrg: false,
  setOpenedOrg: (newData) => set({ openedOrg: newData }),

  handleClose: () =>
    set({
      opened: false,
      loading: false,
      activeTab: "activity",
      currentContainer: null,
      index: null,
      orgProspected: false,
    }),
});

const leadFrontStore = (set, get) => ({
  sizes: {},
  setSizes: (newData) => set({ sizes: newData }),
});

export const useLeadsStore = create(leadsStore);
export const useLeadsContainerStore = create(leadsContainerStore);
export const useLeadsSheetStore = create(leadSheetStore);
export const useLeadsFrontStore = create(
  persist(leadFrontStore, { name: "leadFrontStore" })
);
