import { useState } from "react";
import {
  useLeadsContainerStore,
  useLeadsSheetStore,
} from "../../../../../../../stores/LeadsStore";
import { updateProspect } from "../../../../../../../api/PRMAPI";
import { DateInput, DatePickerInput } from "@mantine/dates";
import { NumberInput } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useLanguage } from "../../../../../../../stores/LanguageStore";
import { IconCalendar } from "@tabler/icons-react";

const HeaderItem = ({
  field,
  defaultValue,
  icon,
  label,
  isDate,
  inputProps,
}) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(false);
  const currentContainer = useLeadsSheetStore((s) => s.currentContainer);
  const updateContainerItem = useLeadsContainerStore(
    (s) => s.updateContainerItem
  );
  const current = useLeadsSheetStore((s) => s.current);
  const setCurrent = useLeadsSheetStore((s) => s.setCurrent);

  const handleSubmitEdit = (newV) => {
    if (field === "score") {
      return;
    }

    setLoading(true);
    updateProspect({
      id: current.id,
      [field]: newV,
    })
      .then((response) => {
        notifications.show({
          message: lang.prm.prospect_updated,
          color: "tertiary.8",
        });
        setCurrent({ ...current, [field]: newV });
        updateContainerItem(currentContainer.id, current.id, {
          [field]: newV,
        });
      })
      .finally(() => setLoading(false));
  };

  return isDate ? (
    <DatePickerInput
      placeholder={label}
      w={200}
      leftSection={<IconCalendar size={18} />}
      defaultValue={defaultValue ? new Date(defaultValue) : null}
      name={"closing_date-" + current?.id}
      disabled={loading || !current?.permission?.write_permission}
      clearable
      onChange={(v) => handleSubmitEdit(v?.toISOString() || null)}
      popoverProps={{
        zIndex: 489
      }}
    />
  ) : (
    <NumberInput
      placeholder={label}
      hideControls
      w={field === "amount" ? 100 : 75}
      defaultValue={defaultValue || null}
      disabled={loading || !current?.permission?.write_permission}
      leftSection={icon}
      leftSectionWidth={28}
      onBlur={(e) => {
        handleSubmitEdit(parseInt(e.target.value));
      }}
      {...inputProps}
    />
  );
};

export default HeaderItem;
