import { useMemo } from "react";
import MainTable from "../../../../../layouts/Table/MainTable";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { Anchor, Text } from "@mantine/core";
import numberColumn from "../../../../../layouts/Table/columns/numberColumn";

const Delivers = ({ stats, loading }) => {
  const apiRef = useGridApiRef();

  const rows = useMemo(() => {
    return (
      stats?.integrations_deliverability?.filter((x) => x.type === "Email") ||
      []
    );
  }, [stats?.integrations_deliverability]);

  const columns = useMemo(
    () => [
      numberColumn(),
      {
        field: "name",
        headerName: "Expéditeur",
        width: 200,
        renderCell: ({ value, row }) => {
          return (
            <Anchor href={"/senders/email/" + row.id} target="_blank">
              {value}
            </Anchor>
          );
        },
      },
      {
        field: "sent",
        headerName: "Envoi total",
        width: 200,
        renderCell: ({ value, row }) => {
          return (
            <Text fw={400} size="lg">
              <b>{value}</b>
            </Text>
          );
        },
      },
      {
        field: "opener",
        headerName: "Ouvreurs",
        width: 200,
        renderCell: ({ value, row }) => {
          let rate = ((value / (row?.sent || 1)) * 100).toFixed(2);
          return (
            <Text fw={400} size="lg">
              <b style={{ marginRight: 8 }}>{value}</b> ( {rate}% )
            </Text>
          );
        },
      },
      {
        field: "clicker",
        headerName: "Cliqueurs",
        width: 200,
        renderCell: ({ value, row }) => {
          let rate = ((value / (row?.opener || 1)) * 100).toFixed(2);
          return (
            <Text fw={400} size="lg">
              <b style={{ marginRight: 8 }}>{value}</b> ( {rate}% )
            </Text>
          );
        },
      },
      {
        field: "answerer",
        headerName: "Répondeurs",
        width: 200,
        renderCell: ({ value, row }) => {
          let rate = ((value / (row?.opener || 1)) * 100).toFixed(2);
          return (
            <Text fw={400} size="lg">
              <b style={{ marginRight: 8 }}>{value}</b> ( {rate}% )
            </Text>
          );
        },
      },
    ],
    []
  );

  return (
    <MainTable
      loading={loading}
      apiRef={apiRef}
      columns={columns}
      rows={rows}
      id={"list-table"}
      withBorderTop
    />
  );
};

export default Delivers;
