import { useCallback, useEffect } from "react";
import FilterContent from "../../../../components/Filter/FilterContent";
import {
  useLeadsContainerStore,
  useLeadsStore,
} from "../../../../stores/LeadsStore";
import { useProfileStore } from "../../../../stores/UserStore";
import PopOver from "../../../../components/View/PopOver";
import { IconFilter, IconX } from "@tabler/icons-react";
import { Button, Group, Indicator, Tooltip } from "@mantine/core";
import { useLanguage } from "../../../../stores/LanguageStore";
import FilterListQuick from "../../../../components/Filter/FilterListQuick";

const LeadsFilter = () => {
  const lang = useLanguage((s) => s.language);
  const filter = useLeadsStore((s) => s.filter);
  const filters = useLeadsStore((s) => s.filters);
  const setFilter = useLeadsStore((s) => s.setFilter);
  const setReloadAll = useLeadsContainerStore((s) => s.setReloadAll);

  const profile = useProfileStore((s) => s.profile);

  const handleFilter = useCallback(
    (newFilter) => {
      setFilter(newFilter);
      setReloadAll(true);
    },
    [setFilter, setReloadAll]
  );

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    let prog = urlParams.get("programmation_id");
    let created_on = urlParams.get("created_on");
    let contact_type = urlParams.get("contact_type");
    let contact_id = urlParams.get("contact_id");

    if (prog && created_on) {
      let v = [
        {
          field_name: "programmation_id",
          type: "equals",
          value: prog,
        },
      ];
      if (contact_type) {
        v.push({
          field_name: contact_type,
          type: "more_than",
          value: created_on,
        });
      }

      setFilter({
        mode: "and",
        values: v,
      });
    } else {
      if (contact_id) {
        setFilter({
          mode: "and",
          values: [
            {
              field_name: "id",
              type: "equals",
              value: contact_id,
            },
          ],
        });
      } else {
        setFilter(
          profile?.saved_filters?.prm?.find((x) => x?.pinned === true)
            ?.filter || null
        );
      }
    }
  }, []);

  return (
    <Group gap={0}>
      <PopOver
        opener={(openFunction, ref) => {
          return (
            <Group gap={0}>
              <Tooltip label={lang.table.toolbar.filter.main_button_tooltip}>
                <Button
                  ref={ref}
                  onClick={openFunction}
                  radius={0}
                  variant={"outline"}
                  style={{
                    borderTopWidth: 1,
                    borderLeft: 0,
                    borderBottomRightRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderRight: filter?.values?.length > 0 ? 0 : undefined,
                  }}
                  className="toolbar-action-button"
                  id={"prm-filter"}
                >
                  <Indicator
                    color="red"
                    offset={2}
                    disabled={!filter || filter?.values?.length === 0}
                    label={filter?.values?.length}
                    size={16}
                  >
                    <IconFilter size={18} />
                  </Indicator>
                  <span style={{ marginLeft: 6 }}>
                    {lang.table.toolbar.filter.main_button_label}
                  </span>
                </Button>
              </Tooltip>
              {filter?.values?.length > 0 && (
                <Tooltip
                  label={lang.table.toolbar.filter.cancel_button_tooltip}
                >
                  <Button
                    variant="light"
                    color="red"
                    px={4}
                    radius={0}
                    style={{
                      border: "1px solid #00000016",
                      borderLeft: "none",
                    }}
                    onClick={() => handleFilter(null)}
                  >
                    <IconX size={18} />
                  </Button>
                </Tooltip>
              )}
            </Group>
          );
        }}
        popoverChildren={(handleClose) => (
          <FilterContent
            id={"prm-filter"}
            isClient={false}
            pageName={"prm"}
            mainFilter={filter}
            setMainFilter={(newFilter) => {
              handleFilter(newFilter);
              handleClose();
            }}
            fields={filters}
            getFieldOptionName={(f) => f.option_name}
            // defaultFiltersClose={true}
          />
        )}
        elevation={3}
        paperSx={{
          ml: -12,
        }}
      />

      <FilterListQuick
        tableId={"prm-filter"}
        pageName={"prm"}
        fields={filters}
        handleFilter={handleFilter}
        withBorderTop
      />
    </Group>
  );
};

export default LeadsFilter;
