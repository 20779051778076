import {
  Button,
  Group,
  Modal,
  Paper,
  Stack,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import { createMEmail, getMEmails } from "../../../api/ModelsApi";
import { IconBook2, IconCopy } from "@tabler/icons-react";
import EmailPreviewer from "../../../components/View/EmailPreviewer";
import { notifications } from "@mantine/notifications";
import { useLanguage } from "../../../stores/LanguageStore";
import BackButton from "../../../components/View/BackButton";
import {
  model1,
  model2,
  model3,
  model4,
  model5,
  model6,
  model7,
} from "./emails/emailTemplates";

const ModelTemplates = ({
  setTableLoading,
  setHighlight,
  inWorkflow,
  onModelCreated,
}) => {
  const lang = useLanguage((s) => s.language);
  const [opened, setOpened] = useState(false);

  const templateItems = useMemo(() => {
    return [model1, model2, model3, model4, model5, model6, model7];
  }, []);

  const handleOpen = () => {
    setOpened(true);
  };
  const handleClose = () => {
    setOpened(false);
  };

  const [selected, setSelected] = useState(null);

  const [duplicating, setDuplicating] = useState(false);
  const handleDuplicate = () => {
    setDuplicating(true);

    createMEmail({ ...selected })
      .then((response) => {
        if (inWorkflow) {
          setDuplicating(true);

          getMEmails()
            .then((res) => {
              let newItemsList = res.data.models_list;
              let newItem = newItemsList.find(
                (x) => x.id === response.data.model_id
              );
              notifications.show({
                title: lang.model_emails.row_highlight.duplicated,
                message: lang.models.created_text,
                color: "tertiary.8",
              });
              onModelCreated?.(newItem);
              handleClose?.();
            })
            .finally(() => {
              setDuplicating(false);
            });
        } else {
          notifications.show({
            message: lang.model_emails.row_highlight.duplicated,
          });
          handleClose();
          setTableLoading?.(true);
          setHighlight?.(response.data.model_id);
          setDuplicating(false);
        }
      })
      .catch(() => setDuplicating(false));
  };

  useEffect(() => {
    if (templateItems.length > 0) {
      setSelected(templateItems[0]);
    }
  }, [templateItems]);

  return (
    <>
      {inWorkflow ? (
        <Tooltip label={lang.model_emails.templates.title}>
          <Button
            variant="subtle"
            size="compact-sm"
            onClick={handleOpen}
            fw={700}
          >
            {lang.model_emails.templates.label}
          </Button>
        </Tooltip>
      ) : (
        <Button
          variant="light"
          ml={"auto"}
          mr={"lg"}
          onClick={handleOpen}
          radius={"sm"}
          leftSection={<IconBook2 size={18} />}
        >
          {lang.model_emails.templates.label}
        </Button>
      )}

      <Modal
        opened={opened}
        onClose={handleClose}
        title={<Title order={5}>{lang.model_emails.templates.title}</Title>}
        size={"80%"}
      >
        <Group
          h={"70vh"}
          w={"100%"}
          wrap="nowrap"
          pos={"relative"}
          align="start"
        >
          <Paper
            component={Stack}
            h={"100%"}
            style={{ overflow: "auto", borderRight: "1px solid #00000018" }}
            shadow="sm"
            w={"30%"}
            px={"sm"}
          >
            {templateItems?.map((item) => (
              <Tooltip label={item.name} key={item.id}>
                <Button
                  key={item.id}
                  variant={selected?.id === item.id ? "light" : "outline"}
                  onClick={() => setSelected(item)}
                >
                  <Text truncate="end" fw={600} size="sm">
                    {item.name}
                  </Text>
                </Button>
              </Tooltip>
            ))}
          </Paper>
          <Stack w={"70%"} h={"100%"}>
            <Paper w={"100%"} h={"100%"}>
              <EmailPreviewer
                key={selected?.id}
                html={selected?.html}
                subject={selected?.subject}
                iframeHeight={"calc(70vh - 96px)"}
              />
            </Paper>
            <Group justify="end">
              <BackButton onClick={handleClose} />
              <Button
                leftSection={<IconCopy size={18} />}
                color="tertiary.8"
                onClick={handleDuplicate}
                loading={duplicating}
              >
                {lang.model_emails.templates.duplicate}
              </Button>
            </Group>
          </Stack>
        </Group>
      </Modal>
    </>
  );
};

export default ModelTemplates;
