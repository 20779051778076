import { Anchor, Button } from "@mantine/core";
import { IconExternalLink } from "@tabler/icons-react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { useMemo } from "react";

const AnswerDirectLink = ({ link, isLinkedin }) => {
  const lang = useLanguage((s) => s.language);
  const keys = useMemo(() => {
    const regexEmail = /\/workflow\/([^\\/]+)\/([^\\/]+)\/reply/;
    const regexLinkedin = /\/workflow\/([^\\/]+)\/reply/;
    const match = link.match(isLinkedin ? regexLinkedin : regexEmail);

    const prm_contact_id = match[1];
    const reply_id = match[2];

    return { prm_contact_id, reply_id };
  }, [isLinkedin, link]);

  return (
    <Anchor
      target="_blank"
      href={`/reply?p_id=${keys.prm_contact_id}${
        keys?.reply_id ? `&r_id=${keys.reply_id}` : ""
      }&linkedin=${link.includes("linkedin") ? "true" : ""}`}
    >
      <Button
        variant="light"
        size={isLinkedin ? "sm" : "compact-sm"}
        leftSection={<IconExternalLink size={18} />}
        ml={8}
      >
        {isLinkedin
          ? lang.statistics.columns.sender.view_conversation
          : lang.statistics.columns.sender.view}
      </Button>
    </Anchor>
  );
};

export default AnswerDirectLink;
