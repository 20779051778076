import { useEffect, useState } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { getFirstPageUsers, searchUser } from "../../../../api/UserAPI";
import { useProfileStore } from "../../../../stores/UserStore";
import { requestPage } from "../../../../api/ContactAPI";
import { Button, Center, Loader, Stack, TextInput } from "@mantine/core";
import { IconRotate2, IconSearch } from "@tabler/icons-react";
import UserItem from "./UserItem";

const defaultSort = {
  field_name: "id",
  sort_direction: "desc",
};
const ascSort = {
  field_name: "first_name",
  sort_direction: "asc",
};
const descSort = {
  field_name: "first_name",
  sort_direction: "desc",
};

const AdminPanelLeft = ({
  setCurrent,
  current,
  list,
  setList,
  loading,
  setLoading,
}) => {
  const lang = useLanguage((s) => s.language);
  const [query, setQuery] = useState("");
  const [nextUrl, setNextUrl] = useState(null);
  const [sort, setSort] = useState("");

  const handleQuery = (e) => {
    e.preventDefault();
    let fd = new FormData(e.target);
    let data = Object.fromEntries(fd);
    setLoading(true);
    setQuery(data?.search_query);
  };

  const handleClick = (newCurrent) => {
    setCurrent(newCurrent);
  };

  useEffect(() => {
    if (loading) {
      if (query) {
        searchUser({ per_page: 100, query: query, sort: defaultSort })
          .then((response) => {
            setList(response.data.results);
            setNextUrl(response.data.next_page);
          })
          .finally(() => setLoading(false));
      } else {
        getFirstPageUsers({
          sort:
            sort === "asc" ? ascSort : sort === "desc" ? descSort : defaultSort,
        })
          .then((response) => {
            let p = useProfileStore.getState().profile;
            const me = { ...p };
            me.last_name += lang.users_list.current_user_indicator;
            setList([me, ...response.data.results]);
            setNextUrl(response.data.next_page);
          })
          .catch((error) => {
            setList([]);
          })
          .finally(() => setLoading(false));
      }
    }
  }, [loading, setList, setLoading, lang, query, nextUrl, sort]);

  useEffect(() => {
    setLoading(true);
    setQuery("");
  }, [setLoading]);

  const [loadingMore, setLoadingMore] = useState(false);
  const handleMore = () => {
    setLoadingMore(true);
    requestPage(nextUrl, "")
      .then((response) => {
        setList((prev) => [...prev, ...response.data.results]);
        setNextUrl(response.data.next_page);
      })
      .finally(() => setLoadingMore(false));
  };

  return (
    <Stack
      w={"20%"}
      h={"100%"}
      gap={"sm"}
      p={"md"}
      py={"xs"}
      style={{ borderRight: "1px solid #00000016" }}
    >
      <Stack
        component={"form"}
        onSubmit={handleQuery}
        direction={"row"}
        spacing={1}
      >
        <TextInput
          placeholder={lang.administration.left.search_name}
          id="search-user-input"
          name="search_query"
          disabled={loading}
          fullWidth
          rightSection={<IconSearch size={18} />}
        />
      </Stack>

      <Stack
        pos={"relative"}
        flex={"1 1 auto"}
        h={0}
        mih={"10%"}
        style={{ overflow: "auto" }}
      >
        {loading ? (
          <Center
            h={"100%"}
            w={"100%"}
            overlayProps={{ blur: 1 }}
            pos={"relative"}
          >
            <Loader type="dots" />
          </Center>
        ) : (
          <Stack gap={"xs"}>
            {list.map((user) => (
              <UserItem
                user={user}
                key={user.id}
                active={current?.id === user.id}
                onClick={() => handleClick(user)}
              />
            ))}
            {nextUrl && (
              <Button
                onClick={handleMore}
                sx={{ mt: 0.5 }}
                leftSection={<IconRotate2 size={18} />}
                loading={loadingMore}
                variant="light"
              >
                Charger plus
              </Button>
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default AdminPanelLeft;
