import { Paper, Stack, Text } from "@mantine/core";
import { Link } from "react-router-dom";

const BaseGroupItem = ({ title, desc, icon, items, url, isSlide }) => {
  const GroupIcon = icon;
  return (
    <Paper
      component={Link}
      to={url}
      radius={"md"}
      py={"lg"}
      px={"md"}
      withBorder
      w={isSlide ? "100%" : "30%"}
      className="group"
    >
      <Stack align="center">
        <GroupIcon size={36} color={"var(--mantine-color-primary-6)"} />

        <Text fw={600} fz={18} c={"black"}>
          {title}
        </Text>
        <Text fw={400} fz={14} c={"dimmed"}>
          {desc}
        </Text>

        <Text fw={500} fz={12} c={"black"}>
          {items.length} article{items.length > 1 ? "s" : ""}
        </Text>
      </Stack>
    </Paper>
  );
};

export default BaseGroupItem;
