import { Box, Button, Stack, Text } from "@mantine/core";
import image from "../../../assets/404.svg";
import { Link } from "react-router-dom";
import { IconHome2 } from "@tabler/icons-react";

const Error404 = () => {
  return (
    <Stack w={"100%"} h={"100%"} align="center" pt={21}>
      <Box w={350}>
        <img src={image} />
      </Box>
      <Text fz={18}>
        La page que vous tentez d'accéder est introuvable.
      </Text>
      <Button component={Link} to={"/"} leftSection={<IconHome2 />}>
        Accueil
      </Button>
    </Stack>
  );
};

export default Error404;
