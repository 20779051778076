import { DataGridPro, GridPagination, enUS, frFR } from "@mui/x-data-grid-pro";
import { forwardRef, useMemo } from "react";
import { actionColumn } from "./columns/actionColumn";
import {
  ActionIcon,
  Button,
  Checkbox,
  Stack,
  Tooltip,
  useMantineColorScheme,
} from "@mantine/core";
import { useLanguage } from "../../stores/LanguageStore";
import TableToolbar from "./toolbar/TableToolbar";
import { getDefaultDensity, initialTableState } from "./initialState";
import TableLoader from "./loader/TableLoader";
import NoRowsDisplay from "./noRowsDisplay/NoRowsDisplay";
import TablePagination from "./pagination/TablePagination";
import { useUIStore } from "../../stores/UIStore";
import "./MainTable.scss";
import { useTableHighlight } from "../../components/TableComponents/tableHooks";
import { IconChevronDown, IconChevronRight } from "@tabler/icons-react";

function handleRowHeight({ densityFactor, model }) {
  if (model?.isChild === true || model?.isParent === false) {
    return "auto";
  }

  return densityFactor * 52;
}

const TableCheckbox = forwardRef((props, ref) => {
  const { touchRippleRef, inputProps, ...rest } = props;
  return <Checkbox {...rest} ref={ref} />;
});

TableCheckbox.displayName = "TableCheckbox";

const getTreeDataPath = (row) => row?.path;

const MainTable = ({
  id,
  rows,
  columns,
  actionsCell,
  actionsCellWidth,
  apiRef,
  loading,
  setLoading,
  hidePerPage,
  pinnedLeft,
  toolbarActions,
  hasFolder,
  pageName,
  defaultColumnVisibilityModel,
  onPaginationChange,
  isClient = true,
  rowCount,
  onSelectionChange,
  onFilterChange,
  onSortChange,
  pageOrderName,
  desiredOrder,
  desiredVisibleColumns,
  defaultSearch,
  defaultFilter,
  handleRowUpdate,
  handleRowUpdateError,
  additionalFilter,

  treeData,
  getRowId,

  noRowsDisplay,
  defaultHighlight,
  setDefaultHighlight,
  setHasQuery,
  hasQuery,

  withBorderTop,
  disableSearch,
  disableFilter,
  disableGotoPage,
  disablePerPage,
  disableCheckboxes,
  disableVisibleColumns,
}) => {
  const layoutIsVertical = useUIStore((s) => s.layoutIsVertical);
  const { colorScheme } = useMantineColorScheme();
  const lang = useLanguage((s) => s.language);

  //#region Disable on URL
  const disableActions = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    return (
      localStorage.getItem("disable_actions") === "true" ||
      urlParams.get("disableActions")
    );
  }, []);

  const disableSelections = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    return (
      localStorage.getItem("disable_selections") === "true" ||
      urlParams.get("disableSelections")
    );
  }, []);

  //#endregion

  const COLUMNS = useMemo(
    () =>
      actionsCell && !disableActions
        ? [...columns, actionColumn(actionsCell, actionsCellWidth)]
        : columns,
    [actionsCell, disableActions, columns, actionsCellWidth]
  );

  const noRadiusLeft = useMemo(
    () => layoutIsVertical && hasFolder,
    [hasFolder, layoutIsVertical]
  );

  const groupingColDef = useMemo(() => {
    return {
      headerName: "Chevron",
      width: 32,
      minWidth: 1,
      renderHeader: () => "",
      renderCell: ({ id, rowNode, row }) => {
        function handleClick() {
          apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
        }

        if (!row?.isParent) {
          return "";
        }

        return (
          <ActionIcon
            onClick={handleClick}
            sx={{ p: 0 }}
            disabled={row?.integrations?.length < 1}
            variant="subtle"
            color="primary"
            h={"100%"}
            radius={0}
            w={32}
          >
            {rowNode.childrenExpanded ? (
              <IconChevronDown size={22} />
            ) : (
              <IconChevronRight size={22} />
            )}
          </ActionIcon>
        );
      },
    };
  }, [apiRef]);

  useTableHighlight({
    apiRef,
    loading,
    defaultHighlight,
    setDefaultHighlight,
    treeData,
  });

  return (
    <Stack
      className="main-table"
      id={id}
      pos={"relative"}
      w={0}
      flex={"1 1 auto"}
      h={"100%"}
    >
      <DataGridPro
        apiRef={apiRef}
        columns={COLUMNS}
        rows={rows || []}
        rowCount={rowCount}
        headerHeight={38}
        disableVirtualization={true}
        getRowHeight={handleRowHeight}
        columnBuffer={12}
        columnThreshold={12}
        rowBuffer={50}
        rowThreshold={1}
        pagination
        keepNonExistentRowsSelected
        checkboxSelection={
          disableCheckboxes || disableSelections ? false : true
        }
        checkboxSelectionVisibleOnly={isClient}
        disableSelectionOnClick
        showCellRightBorder
        showColumnRightBorder
        select
        disableRowSelectionOnClick
        className={"main-table " + colorScheme}
        getRowClassName={(params) =>
          "table-row row-" +
          params.id +
          (params.indexRelativeToCurrentPage % 2 === 0 ? " even" : " odd") +
          (params.id.toString().startsWith("#") ? " isChild" : "") +
          (params?.row?.stopped ? " stopped" : "")
        }
        sx={{
          borderTopLeftRadius: noRadiusLeft ? 0 : 4,
          borderBottomLeftRadius: noRadiusLeft ? 0 : 4,
          borderTopWidth: withBorderTop ? 1 : 0,
          borderRight: 0,
          borderLeft: 0,
          "& .MuiDataGrid-columnHeader.custom_datafield": {
            background: "var(--mantine-color-secondary-0) ",
          },
        }}
        localeText={
          lang?.id === "FRA"
            ? frFR.components.MuiDataGrid.defaultProps.localeText
            : enUS.components.MuiDataGrid.defaultProps.localeText
        }
        loading={loading}
        paginationMode={isClient ? "client" : "server"}
        slots={{
          toolbar: TableToolbar,
          baseButton: Button,
          baseCheckbox: TableCheckbox,
          baseTooltip: (props) => {
            const { enterDelay, ...rest } = props;
            return (
              <Tooltip
                {...rest}
                label={props?.title}
                disabled={!props?.title}
              />
            );
          },
          loadingOverlay: TableLoader,
          noRowsOverlay: () => <NoRowsDisplay component={noRowsDisplay} />,
          pagination: (props) => (
            <GridPagination
              ActionsComponent={TablePagination}
              {...props}
              labelRowsPerPage=""
              sx={{
                width: "58%",
                pr: "16px !important",
                ml: 0,
                "& .MuiToolbar-root": {
                  position: "static !important",
                },
                "& .MuiInputBase-root": {
                  display: disablePerPage ? "none !important" : "flex",
                },
              }}
            />
          ),
          columnFilteredIcon: () => "",
          columnHeaderFilterIconButton: () => "",
        }}
        slotProps={{
          baseButton: {
            variant: "outline",
            sm: "test",
          },
          footer: {
            sx: {
              position: "relative",
              pr: 4,
            },
          },
          columnMenu: {
            slots: {
              columnMenuFilterItem: null,
              columnMenuColumnsItem: null,
            },
          },
        }}
        density={getDefaultDensity()}
        initialState={initialTableState({
          disablePerPage,
          pinnedLeft,
          columnVisibilityModel: defaultColumnVisibilityModel,
          columns,
          pageName,
          pageOrderName,
          desiredOrder,
          desiredVisibleColumns,
          defaultSearch,
          defaultFilter,
        })}
        processRowUpdate={handleRowUpdate}
        onProcessRowUpdateError={handleRowUpdateError}
        onPaginationModelChange={onPaginationChange}
        onRowSelectionModelChange={onSelectionChange}
        filterMode={isClient ? "client" : "server"}
        onFilterModelChange={onFilterChange}
        sortingMode={isClient ? "client" : "server"}
        onSortModelChange={onSortChange}
        disableMultipleColumnsSorting
        treeData={treeData}
        getTreeDataPath={getTreeDataPath}
        groupingColDef={groupingColDef}
        getRowId={getRowId}
        hidePerPage={hidePerPage}
        editMode="cell"
        // RootProps
        isClient={isClient}
        pageName={pageName}
        setLoading={setLoading}
        noRadiusLeft={noRadiusLeft}
        tableId={id}
        toolbarActions={toolbarActions}
        pageOrderName={pageOrderName}
        defaultSearch={defaultSearch}
        defaultFilter={defaultFilter}
        disableSearch={disableSearch}
        // Disable Components
        disableFilter={disableFilter}
        disableGotoPage={disableGotoPage}
        disableVisibleColumns={disableVisibleColumns}
        setHasQuery={setHasQuery}
        hasQuery={hasQuery}
        additionalFilter={additionalFilter}
      />
    </Stack>
  );
};

export default MainTable;
