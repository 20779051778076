import { Anchor, Box, Group, Stack, Text, Title } from "@mantine/core";
import { Logo } from "../../../layouts/Logo";
import LoginForm from "./LoginForm";
import { Link, Navigate } from "react-router-dom";
import LanguageChanger from "../LanguageChanger";
import { useLanguage } from "../../../stores/LanguageStore";
import { useSessionStore } from "../../../stores/UserStore";
import { lazy } from "react";

const LoginIllustration = lazy(() => import("./LoginIllustration"));

const Login = () => {
  const lang = useLanguage((s) => s.language);
  const url = new URLSearchParams(window.location.search);
  const destination = url.get("destination");

  const session = useSessionStore((s) => s.session);
  const params = window.location.href.split("?")?.[1];

  if (
    session &&
    !params?.includes("destination") &&
    !params?.includes("logged_out=true")
  )
    return <Navigate to={"/" + (params ? `?${params}` : "")} />;

  return (
    <Group w={"100vw"} h={"100vh"} grow gap={0}>
      <Stack
        bg={"var(--mantine-color-body)"}
        h={"100%"}
        align="center"
        justify="center"
        gap={12}
        pos={"relative"}
      >
        <Logo pos="absolute" left={16} top={16} w={200} />
        <Title order={2} className={`animate__animated animate__fadeIn`}>
          {lang.login.title}
        </Title>
        {destination ? (
          <Text c={"red"}>{lang.login.need_reconnect}</Text>
        ) : (
          <Text
            className={`animate__animated animate__fadeInUp`}
            style={{ animationDelay: ".9s" }}
          >
            {lang.login.subtitle}
          </Text>
        )}

        <LoginForm />

        <Anchor
          component={Link}
          to={"/signup"}
          size="sm"
          fw={"bold"}
          className={`animate__animated animate__fadeIn`}
          style={{ animationDelay: ".4s" }}
        >
          {lang.login.new_user}
        </Anchor>

        <LanguageChanger />
      </Stack>

      <Stack
        bg={"#f7f7f7"}
        h={"100%"}
        align="center"
        justify="center"
        w={"100%"}
      >
        <Box
          w={"80%"}
          className={`animate__animated animate__fadeIn`}
          style={{ animationDelay: ".25s" }}
        >
          <LoginIllustration />
        </Box>
      </Stack>
    </Group>
  );
};

export default Login;
