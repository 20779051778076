import { useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { updateProfile, updateSelf } from "../../../../../api/UserAPI";
import { notifications } from "@mantine/notifications";
import { Button, Tooltip } from "@mantine/core";
import { IconDeviceFloppy } from "@tabler/icons-react";
import { useConfigStore } from "../../../../../stores/ConfigStore";

const MappingSaver = ({ fields }) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(false);

  const saveMap = () => {
    setLoading(true);

    const result = {};
    for (const key in fields) {
      const field = useConfigStore
        .getState()
        .datafields.find((x) => x.identifier_placeholder === fields[key]).id;

      if (!result[field]) {
        result[field] = [];
      }
      result[field].push(key);
    }

    updateProfile(null, {
      saved_order: {
        mapping: result,
      },
    })
      .then((res) => {
        notifications.show({
          message: lang.contact_lists.import.save_mapping.success,
          color: "green.8",
        });
        updateSelf();
      })
      .finally(() => setLoading(false));
  };

  return (
    <Tooltip label={lang.contact_lists.import.save_mapping.tooltip}>
      <Button
        onClick={saveMap}
        loading={loading}
        leftSection={<IconDeviceFloppy size={18} />}
        variant="light"
        pos={"absolute"}
        bottom={0}
        left={"50%"}
        style={{
          transform: "translateX(-25%)",
        }}
      >
        {lang.contact_lists.import.save_mapping.label}
      </Button>
    </Tooltip>
  );
};

export default MappingSaver;
