import { useEffect, useState } from "react";
import {
  addToOrg,
  addToTeam,
  changeUserReseller,
  getOrgs,
  getResellers,
  getTeams,
} from "../../../../../api/AdminAPI";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { notifications } from "@mantine/notifications";
import Selector from "../../../../../components/Selectors/Selector";

const CommunityChanger = ({
  profile,
  setProfile,
  type,
  handleClose,
  setLoading,
}) => {
  const lang = useLanguage((s) => s.language);
  const [gettingOptions, setGettingOptions] = useState(true);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (gettingOptions) {
      let func =
        type === "teams"
          ? getTeams
          : type === "resellers"
          ? getResellers
          : getOrgs;
      func()
        .then((response) => {
          let newOptions = [];
          if (type !== "resellers") {
            newOptions = response.data[type + "_list"]?.map((item) => ({
              ...item,
              option_name: `${item.name} #${item.id}`,
            }));
          } else {
            response.data.domains_list.forEach((domain) => {
              if (!newOptions.find((x) => x?.id === domain.reseller_id))
                newOptions.push({
                  ...domain,
                  id: domain.reseller_id,
                  option_name: `${domain.name} #${domain.reseller_id}`,
                });
            });
          }

          setOptions([{ label: "All", options: newOptions }]);
        })
        .finally(() => {
          setGettingOptions(false);
        });
    }
  }, [gettingOptions, profile, type]);

  const onChange = (v) => {
    setLoading(true);
    handleClose();
    let func =
      type === "teams"
        ? addToTeam
        : type === "resellers"
        ? changeUserReseller
        : addToOrg;

    func({
      organization_id: v.id,
      team_id: v.id,
      current_reseller_id: profile?.resellers?.[0]?.id || 0,
      new_reseller_id: v.id,
      level: "user",
      user_id: profile.id,
    })
      .then((response) => {
        notifications.show({ message: lang.profile.edited, color: "green" });
        setProfile((prev) => {
          let newProfile = { ...prev };

          newProfile[type] = [...newProfile[type], v];

          return newProfile;
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Selector
      options={options}
      loading={gettingOptions}
      onChange={onChange}
      value={null}
      placeholder={lang.users_list[type]}
      label={lang.administration.right.community.join}
      autoFocus
      other={{ ml: "auto", w: 250 }}
    />
  );
};

export default CommunityChanger;
