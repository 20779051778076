import { ActionIcon, Button, Group, Modal, Text, Tooltip } from "@mantine/core";
import { useState } from "react";
import { useLanguage } from "../../../stores/LanguageStore";
import { IconRefreshAlert } from "@tabler/icons-react";
import BackButton from "../../../components/View/BackButton";
import { regenApiKey } from "../../../api/ConfigsApi";
import { notifications } from "@mantine/notifications";

const ApiKeyRegenerate = ({ row, setTableLoading }) => {
  const lang = useLanguage((s) => s.language);
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpen = () => {
    setOpened(true);
    setLoading(false);
  };
  const handleClose = () => {
    setOpened(false);
  };

  const handleSubmit = () => {
    setLoading(true);
    regenApiKey(row.id)
      .then((response) => {
        notifications.show({
          message: lang.api_keys.regen.success,
          color: "tertiary.8",
        });

        handleClose();
        setTableLoading(true);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Tooltip label={lang.api_keys.regen.label}>
        <ActionIcon variant="subtle" onClick={handleOpen}>
          <IconRefreshAlert size={18} />
        </ActionIcon>
      </Tooltip>

      <Modal
        opened={opened}
        onClose={handleClose}
        size={"md"}
        title={
          <Text>
            {lang.api_keys.regen.title}{" "}
            <b>
              {row.name} #{row.id}
            </b>
          </Text>
        }
      >
        <Text>{lang.api_keys.regen.text}</Text>

        <Group justify="end" mt={"md"}>
          <BackButton onClick={handleClose} />

          <Button
            leftSection={<IconRefreshAlert size={18} />}
            color="red"
            loading={loading}
            onClick={handleSubmit}
          >
            {lang.api_keys.regen.label}
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export default ApiKeyRegenerate;
