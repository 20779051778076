import { Box, Stack, Text } from "@mantine/core";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useThemeStore } from "../../../../stores/ThemeStore";

const Image = ({ ...props }) => {
  const theme = useThemeStore((s) => s.theme);
  const mainColor = theme.colors.primary[6];
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 848.67538 469.44265"
    >
      <path
        d="M612.36555,682.31865a207.53414,207.53414,0,0,1-40.54,1.96c-32.66016-1.65-64.78027-11.15-92.23-29.01-12.17041-7.92-23.78027-17.65-30.08008-30.72-8.55029-17.75-5.56006-40.22,6.6499-55.68a56.01494,56.01494,0,0,1,16.14014-13.77c.91992-.52,1.85986-1.02,2.81006-1.5a60.55749,60.55749,0,0,1,33.48-6.13c19.57959,2.14,37.6499,14.1,48.08984,30.81,9.29981,14.9,12.54,32.48,14.4502,50.1.35986,3.32.66992,6.65.96973,9.96a146.37967,146.37967,0,0,1-7.81006,28.06,9.81128,9.81128,0,0,0-.43994,1.36q3,6.51,5.71,13.14a11.55657,11.55657,0,0,0,5.53027,1.4h37.18994Z"
        transform="translate(-175.66231 -215.27867)"
        fill="#f2f2f2"
      />
      <path
        d="M568.79524,683.16869a2.03341,2.03341,0,0,1-2.69971-1.06c-.16016-.38-.31006-.76-.47021-1.14q-2.15992-5.25-4.52979-10.4a316.78429,316.78429,0,0,0-88.17041-114.1,1.47787,1.47787,0,0,1-.61963-1.37,2.03241,2.03241,0,0,1,2.81006-1.5,1.92729,1.92729,0,0,1,.41992.26,317.52029,317.52029,0,0,1,44.25977,43.95,322.504,322.504,0,0,1,34.91015,51.66q4.06494,7.5,7.71973,15.21c.48047,1.02.96045,2.05,1.43018,3.07995q3,6.51,5.71,13.14c.03027.07.06006.15.09033.22A1.47968,1.47968,0,0,1,568.79524,683.16869Z"
        transform="translate(-175.66231 -215.27867)"
        fill="#fff"
      />
      <circle cx="260.50305" cy="297" r="39" fill={mainColor} />
      <path
        d="M432.29956,527.15627a4.04474,4.04474,0,0,1-2.43372-.80842l-.04353-.03268-9.16526-7.01725a4.07192,4.07192,0,1,1,4.95308-6.46445l5.93657,4.55208,14.029-18.29592a4.07178,4.07178,0,0,1,5.709-.75362l-.08723.11844.08949-.11671a4.07649,4.07649,0,0,1,.75362,5.709l-16.50151,21.52039A4.07356,4.07356,0,0,1,432.29956,527.15627Z"
        transform="translate(-175.66231 -215.27867)"
        fill="#fff"
      />
      <path
        d="M1023.147,684.72133H176.853a1.19069,1.19069,0,0,1,0-2.38137h846.294a1.19069,1.19069,0,0,1,0,2.38137Z"
        transform="translate(-175.66231 -215.27867)"
        fill="#3f3d56"
      />
      <circle cx="165.54584" cy="107.00476" r="28.93994" fill="#2f2e41" />
      <ellipse
        cx="312.2682"
        cy="300.329"
        rx="11.97515"
        ry="8.98136"
        transform="translate(-296.56574 93.49262) rotate(-45)"
        fill="#2f2e41"
      />
      <ellipse
        cx="360.35876"
        cy="292.78538"
        rx="8.98136"
        ry="11.97515"
        transform="translate(-226.11098 293.88362) rotate(-66.86956)"
        fill="#2f2e41"
      />
      <path
        d="M421.79385,489.36573A10.74265,10.74265,0,0,0,415.541,474.126l-41.81616-88.45907L354.59693,399.08l47.1186,82.7347a10.80091,10.80091,0,0,0,20.07832,7.55106Z"
        transform="translate(-175.66231 -215.27867)"
        fill="#ffb8b8"
      />
      <path
        d="M383.31437,400.425l-22.20855,9.93087a4.81689,4.81689,0,0,1-6.60409-3.09631l-6.54916-23.35317a13.37737,13.37737,0,0,1,24.45588-10.84991l13.00137,20.38209a4.81688,4.81688,0,0,1-2.09545,6.98643Z"
        transform="translate(-175.66231 -215.27867)"
        fill="#ccc"
      />
      <path
        d="M280.74681,498.1357a10.74271,10.74271,0,0,0,7.44458-14.69441l39.60233-89.47208-22.65457-5.706L273.335,478.00558a10.80091,10.80091,0,0,0,7.41184,20.13012Z"
        transform="translate(-175.66231 -215.27867)"
        fill="#ffb8b8"
      />
      <polygon
        points="94.289 445.033 105.809 449.227 127.47 406.789 110.467 400.599 94.289 445.033"
        fill="#ffb8b8"
      />
      <path
        d="M264.972,662.26332h38.53072a0,0,0,0,1,0,0v14.88687a0,0,0,0,1,0,0H279.85884A14.88686,14.88686,0,0,1,264.972,662.26333v0A0,0,0,0,1,264.972,662.26332Z"
        transform="translate(146.5349 1180.96579) rotate(-159.99348)"
        fill="#2f2e41"
      />
      <path
        d="M297.20006,635.88967a4.77367,4.77367,0,0,1-2.04387-.45889l-13.23928-6.2709a4.79861,4.79861,0,0,1-2.46853-5.9897L316.81215,519.702a4.81658,4.81658,0,0,1,8.66077-.84149l19.76235,32.93709a4.82207,4.82207,0,0,1,.052,4.86807l-43.88711,76.802A4.84031,4.84031,0,0,1,297.20006,635.88967Z"
        transform="translate(-175.66231 -215.27867)"
        fill="#2f2e41"
      />
      <circle cx="166.46368" cy="113.91339" r="24.56103" fill="#ffb8b8" />
      <path
        d="M376.873,440.86165,333.385,428.9021l-23.12961-46.25868A14.576,14.576,0,0,1,323.2929,361.5488h34.59941a14.557,14.557,0,0,1,9.73013,3.72135c9.28142,8.3145,28.78088,32.2848,9.43874,75.17337Z"
        transform="translate(-175.66231 -215.27867)"
        fill="#ccc"
      />
      <path
        d="M322.4327,409.97074l-22.03343-10.31358a4.81687,4.81687,0,0,1-1.97428-7.02163l13.39047-20.22269a13.37737,13.37737,0,0,1,24.19805,11.41336l-6.92414,23.16293a4.81689,4.81689,0,0,1-6.65667,2.98161Z"
        transform="translate(-175.66231 -215.27867)"
        fill="#ccc"
      />
      <polygon
        points="173.428 456.629 185.687 456.629 191.52 409.341 173.425 409.342 173.428 456.629"
        fill="#ffb8b8"
      />
      <path
        d="M346.463,668.40445h38.53073a0,0,0,0,1,0,0v14.88687a0,0,0,0,1,0,0H361.34984A14.88686,14.88686,0,0,1,346.463,668.40447v0A0,0,0,0,1,346.463,668.40445Z"
        transform="translate(555.82523 1136.40041) rotate(179.99738)"
        fill="#2f2e41"
      />
      <path
        d="M353.71912,653.88805a4.79132,4.79132,0,0,1-4.69769-3.81439c-3.524-16.60391-21.82634-101.6514-36.11121-145.4588-14.53417-44.57049,10.2183-64.87171,15.32784-68.48853a3.7629,3.7629,0,0,0,1.25282-1.51258l3.7122-8.16606a4.83433,4.83433,0,0,1,4.39715-2.82656h.03006c27.82859.16621,37.184,8.93856,39.91435,12.70277a4.761,4.761,0,0,1,.75368,3.92623l-17.60768,72.64473a3.75009,3.75009,0,0,0-.07291,1.37669L378.136,646.17147a4.81781,4.81781,0,0,1-4.22284,5.42l-19.62855,2.26417A4.92263,4.92263,0,0,1,353.71912,653.88805Z"
        transform="translate(-175.66231 -215.27867)"
        fill="#2f2e41"
      />
      <path
        d="M316.31807,313.74325a33.40494,33.40494,0,0,0,19.09068,5.89985,20.47079,20.47079,0,0,1-8.11361,3.338,67.35875,67.35875,0,0,0,27.514.1546,17.80736,17.80736,0,0,0,5.75978-1.97824,7.28919,7.28919,0,0,0,3.55521-4.7547c.60365-3.44852-2.08348-6.58157-4.876-8.69308A35.96737,35.96737,0,0,0,329.02361,301.67c-3.37626.87272-6.75852,2.34726-8.9515,5.05866s-2.84257,6.8915-.75322,9.68353Z"
        transform="translate(-175.66231 -215.27867)"
        fill="#2f2e41"
      />
      <circle cx="591.50305" cy="192" r="13" fill={mainColor} />
      <path
        d="M765.87676,412.23787a1.34825,1.34825,0,0,1-.81124-.26947l-.01451-.01089-3.05509-2.33909a1.3573,1.3573,0,1,1,1.651-2.15481l1.97885,1.51736,4.67635-6.09864a1.35725,1.35725,0,0,1,1.903-.25121l-.02908.03948.02983-.0389a1.35883,1.35883,0,0,1,.25121,1.903l-5.50051,7.17346A1.35784,1.35784,0,0,1,765.87676,412.23787Z"
        transform="translate(-175.66231 -215.27867)"
        fill="#fff"
      />
      <circle cx="591.50305" cy="230" r="13" fill={mainColor} />
      <path
        d="M765.87676,450.23787a1.34825,1.34825,0,0,1-.81124-.26947l-.01451-.01089-3.05509-2.33909a1.3573,1.3573,0,1,1,1.651-2.15481l1.97885,1.51736,4.67635-6.09864a1.35725,1.35725,0,0,1,1.903-.25121l-.02908.03948.02983-.0389a1.35883,1.35883,0,0,1,.25121,1.903l-5.50051,7.17346A1.35784,1.35784,0,0,1,765.87676,450.23787Z"
        transform="translate(-175.66231 -215.27867)"
        fill="#fff"
      />
      <circle cx="598.50305" cy="274" r="13" fill={mainColor} />
      <path
        d="M772.87676,494.23787a1.34825,1.34825,0,0,1-.81124-.26947l-.01451-.01089-3.05509-2.33909a1.3573,1.3573,0,1,1,1.651-2.15481l1.97885,1.51736,4.67635-6.09864a1.35725,1.35725,0,0,1,1.903-.25121l-.02908.03948.02983-.0389a1.35883,1.35883,0,0,1,.25121,1.903l-5.50051,7.17346A1.35784,1.35784,0,0,1,772.87676,494.23787Z"
        transform="translate(-175.66231 -215.27867)"
        fill="#fff"
      />
      <path
        d="M899.5214,597.81813c-.13479,0-.21961-.00349-.24924-.00523l-16.13966.00174v-2.3797h16.198c.375.01336,8.02654.22949,15.07646-8.32488,10.50588-12.74905,19.13345-44.30968.57285-122.264-24.95721-104.82061-4.14182-197.42377,5.9658-232.37211a11.60395,11.60395,0,0,0-11.16442-14.8156H668.747a11.65594,11.65594,0,0,0-9.3994,4.78438c-4.28183,5.89-10.15787,17.20518-13.8349,37.665l-2.34194-.42063c3.75663-20.90428,9.82381-32.55237,14.25205-38.64338a14.04138,14.04138,0,0,1,11.32419-5.76508H909.78124a13.984,13.984,0,0,1,13.45058,17.85646c-10.05562,34.76707-30.76324,126.88976-5.93676,231.16018,18.82147,79.05061,9.77327,111.29506-1.13117,124.42465C908.97135,597.37949,901.04648,597.81813,899.5214,597.81813Z"
        transform="translate(-175.66231 -215.27867)"
        fill="#e6e6e6"
      />
      <path
        d="M856.0919,641.24763c-.13479,0-.21961-.00349-.24924-.00523l-16.13965.00174v-2.3797h16.198c.37706.01162,8.02654.22949,15.07646-8.32488,10.50588-12.74905,19.13345-44.30968.57285-122.264-24.95721-104.82061-4.14182-197.42377,5.9658-232.37211a11.60395,11.60395,0,0,0-11.16442-14.8156H625.31749a11.65594,11.65594,0,0,0-9.3994,4.78438c-4.28183,5.89-10.15787,17.20518-13.83476,37.665l-2.34222-.42063c3.75677-20.90429,9.82395-32.55238,14.25219-38.64338a14.04138,14.04138,0,0,1,11.32419-5.76508H866.35174a13.984,13.984,0,0,1,13.45058,17.85646c-10.05562,34.76707-30.76324,126.88976-5.93675,231.16018,18.82146,79.05061,9.77326,111.29506-1.13117,124.42465C865.54185,640.809,857.617,641.24763,856.0919,641.24763Z"
        transform="translate(-175.66231 -215.27867)"
        fill="#ccc"
      />
      <path
        d="M830.43537,551.15868c-24.83007-104.27-4.12011-196.4,5.93995-231.16a13.99387,13.99387,0,0,0-13.4502-17.86H581.88557a14.03109,14.03109,0,0,0-11.32031,5.76c-9.73,13.38-29.91993,57.75-7.36963,180.52,12.05957,65.68,11.87988,110.46,7.93994,139.96-2.21045,16.61005-5.62012,28.37-8.71045,36.30005l-.32959.84a13.63942,13.63942,0,0,0-1,5.05,13.83428,13.83428,0,0,0,4.52979,10.4,13.24271,13.24271,0,0,0,3.16992,2.2,13.41211,13.41211,0,0,0,3.03027,1.11,13.67578,13.67578,0,0,0,3.27.39H812.41535c.03028,0,.10987.01.25.01,1.52,0,9.4502-.44,16.64014-9.1C840.20539,662.44866,849.2552,630.20867,830.43537,551.15868Zm-2.89013,122.81c-7.04981,8.56-14.69971,8.34-15.06983,8.33H575.09553a11.63613,11.63613,0,0,1-9.63037-5.11,11.36694,11.36694,0,0,1-1.60986-9.43,9.81128,9.81128,0,0,1,.43994-1.36,146.37967,146.37967,0,0,0,7.81006-28.06c5.64013-30.75,6.68994-78.2-6.56983-150.35-22.37988-121.86-2.58984-165.56,6.94971-178.69a11.68138,11.68138,0,0,1,9.40039-4.78H822.92512a11.60377,11.60377,0,0,1,11.16016,14.81c-10.10987,34.95-30.91993,127.56-5.96,232.38C846.68537,629.65868,838.05549,661.21868,827.54524,673.96868Z"
        transform="translate(-175.66231 -215.27867)"
        fill="#3f3d56"
      />
      <path
        d="M715.9248,415.679h-124.186a9.51879,9.51879,0,1,1,0-19.03758h124.186a9.51879,9.51879,0,1,1,0,19.03758Z"
        transform="translate(-175.66231 -215.27867)"
        fill="#ccc"
      />
      <path
        d="M715.9248,456.679h-124.186a9.51879,9.51879,0,1,1,0-19.03758h124.186a9.51879,9.51879,0,1,1,0,19.03758Z"
        transform="translate(-175.66231 -215.27867)"
        fill="#ccc"
      />
      <path
        d="M725.9248,498.679h-124.186a9.51879,9.51879,0,1,1,0-19.03758h124.186a9.51879,9.51879,0,1,1,0,19.03758Z"
        transform="translate(-175.66231 -215.27867)"
        fill="#ccc"
      />
      <path
        d="M732.9248,542.679h-124.186a9.51879,9.51879,0,1,1,0-19.03758h124.186a9.51879,9.51879,0,1,1,0,19.03758Z"
        transform="translate(-175.66231 -215.27867)"
        fill="#ccc"
      />
      <path
        d="M732.97746,341.76923h-89.2387a9.5188,9.5188,0,1,1,0-19.03759h89.2387a9.51879,9.51879,0,1,1,0,19.03759Z"
        transform="translate(-175.66231 -215.27867)"
        fill={mainColor}
      />
      <circle cx="606.50305" cy="317.88152" r="13" fill="#ccc" />
      <path
        d="M738.9248,585.679h-124.186a9.51879,9.51879,0,1,1,0-19.03758h124.186a9.51879,9.51879,0,1,1,0,19.03758Z"
        transform="translate(-175.66231 -215.27867)"
        fill="#ccc"
      />
      <circle cx="612.50305" cy="360.88152" r="13" fill="#ccc" />
    </svg>
  );
};

const Step2 = () => {
  const lang = useLanguage((s) => s.language);
  return (
    <Stack pos={"relative"} style={{ overflow: "hidden" }} w={500}>
      <Text fw={600} fz={"lg"}>
        {lang.home.discover.step2.text1}
      </Text>
      <Text>{lang.home.discover.step2.text2}</Text>
      <Text>{lang.home.discover.step2.text3}</Text>

      <Box w={350} mt={"md"}>
        <Image width="100%" />
      </Box>
    </Stack>
  );
};

export default Step2;
