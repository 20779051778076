import { mainAxios as axios } from "./axios";
// Lists
export const getPermissions = async () => {
  return axios.get("/permissions");
};
export const getPermissionsUser = async ({ user_id }) => {
  return axios.get(`/permissions/${user_id}`);
};
export const updatePermission = async ({ permission_name, user_id, value }) => {
  return axios.put(`/permissions/${permission_name}/${user_id}`, { value });
};
export const deletePermission = async ({ permission_name, user_id }) => {
  return axios.delete(`/permissions/${permission_name}/${user_id}`);
};
export const subscribeUser = async ({ user_id, end_date }) => {
  return axios.post(`/subscriptions/subscribe/${user_id}`, {
    end_date,
  });
};
export const unsubscribeUser = async ({ user_id }) => {
  return axios.delete(`/subscriptions/unsubscribe/${user_id}`);
};
export const addToOrg = async ({ user_id, organization_id }) => {
  return axios.post(`/admin/organizations/${organization_id}/join/${user_id}`);
};
export const addToTeam = async ({ user_id, team_id }) => {
  return axios.post(`/admin/teams/${team_id}/join/${user_id}`);
};
export const removeFromOrg = async ({ user_id, organization_id }) => {
  return axios.delete(
    `/admin/organizations/${organization_id}/leave/${user_id}`
  );
};
export const removeFromTeam = async ({ user_id, team_id }) => {
  return axios.delete(`/admin/teams/${team_id}/leave/${user_id}`);
};
export const removeFromReseller = async ({ user_id, reseller_id }) => {
  return axios.put(
    `/admin/users/${user_id}/change/reseller/${reseller_id}/to/${0}`,
    {
      level: "user",
    }
  );
};

export const getTeams = async () => {
  return axios.get(`/admin/teams`);
};
export const getOrgs = async () => {
  return axios.get(`/admin/organizations`);
};

export const getResellers = async () => {
  return axios.get(`/admin/resellers/domains`);
};

export const changeUserReseller = async ({
  user_id,
  current_reseller_id,
  new_reseller_id,
  level,
}) => {
  return axios.put(
    `/admin/users/${user_id}/change/reseller/${current_reseller_id}/to/${new_reseller_id}`,
    {
      level: level,
    }
  );
};
