import { useState } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useDisclosure } from "@mantine/hooks";
import {
  Button,
  Group,
  Modal,
  NumberInput,
  Stack,
  Text,
} from "@mantine/core";
import BackButton from "../../../../components/View/BackButton";
import { IconLayersIntersect2 } from "@tabler/icons-react";
import { deleteToKeepContacts } from "../../../../api/ContactAPI";
import { notifications } from "@mantine/notifications";

const ListDeleteKeep = ({ current, onFinish, closeMenu }) => {
  const lang = useLanguage((s) => s.language);
  const [opened, { open, close }] = useDisclosure();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    let fd = new FormData(e.target);
    let data = Object.fromEntries(fd);

    setLoading(true);
    deleteToKeepContacts({
      contact_list_id: current.id,
      keep_number: data?.keep,
    })
      .then((res) => {
        notifications.show({
          message: lang.contact_lists.delete_keep.dialog.deleted,
          color: "tertiary.8",
        });
        close();
        onFinish?.();
      })
      .finally(() => setLoading(false));
  };

  const handleClose = () => {
    close();
    closeMenu?.();
  };

  return (
    <>
      <Button
        onClick={open}
        leftSection={<IconLayersIntersect2 size={18} />}
        fw={600}
        color="red"
        variant="subtle"
        justify="start"
      >
        {lang.contact_lists.delete_keep.label}
      </Button>

      {opened && (
        <Modal
          opened={opened}
          size={"md"}
          onClose={handleClose}
          title={
            <>
              {lang.contact_lists.delete_keep.dialog.title}{" "}
              <b>
                {current?.name} #{current.id}
              </b>
            </>
          }
        >
          <Stack component={"form"} onSubmit={handleSubmit} gap={"xs"}>
            <Text c={"dimmed"}>
              {lang.contact_lists.delete_keep.dialog.text}
            </Text>

            <NumberInput
              label={lang.contact_lists.delete_keep.dialog.keep}
              placeholder={lang.contact_lists.delete_keep.dialog.keep}
              defaultValue={2}
              min={1}
              name="keep"
            />

            <Group justify="end" mt={"md"}>
              <BackButton onClick={handleClose} />

              <Button
                leftSection={<IconLayersIntersect2 size={18} />}
                loading={loading}
                color="red"
                type="submit"
              >
                {lang.contact_lists.delete_keep.dialog.submit_button}
              </Button>
            </Group>
          </Stack>
        </Modal>
      )}
    </>
  );
};

export default ListDeleteKeep;
