import { ActionIcon, Button, Group, Modal, Text, Tooltip } from "@mantine/core";
import { useLanguage } from "../../stores/LanguageStore";
import { IconTrash } from "@tabler/icons-react";
import { useState } from "react";
import { notifications } from "@mantine/notifications";
import { useDisclosure } from "@mantine/hooks";

const ItemDeleter = ({
  handleDelete,
  id,
  title_bold,
  parentId,
  apiRef,
  deletedMessage,
  disabled,
  onFinish,
  setRows,
}) => {
  const lang = useLanguage((s) => s.language);
  const [opened, { open, close }] = useDisclosure();
  const [loading, setLoading] = useState(false);

  const main = () => {
    setLoading(true);
    handleDelete({ id, parent_id: parentId })
      .then((res) => {
        close();
        notifications.show({
          message: deletedMessage,
          color: "tertiary.8",
        });

        if (apiRef) {
          let selected = Array.from(apiRef.current.getSelectedRows().keys());
          apiRef.current.setRowSelectionModel(selected.filter((x) => x !== id));
          document.querySelectorAll(`[data-id='${id}']`).forEach((el) => {
            el.style.animation = `forwards 1.5s deletedAnim ease`;
          });
          setTimeout(() => {
            apiRef.current.updateRows([{ id, _action: "delete" }]);
            setRows?.((prev) => prev.filter((x) => x.id !== id));
          }, 750);
        }
        onFinish?.();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Tooltip label={lang.components.deletion_action.button_label}>
        <ActionIcon
          variant="subtle"
          onClick={open}
          color="red"
          disabled={disabled}
        >
          <IconTrash size={16} />
        </ActionIcon>
      </Tooltip>

      <Modal
        opened={opened}
        onClose={close}
        title={
          <Text size="md" truncate="end">
            {lang.components.deletion_action.title} <b>{title_bold}</b>
          </Text>
        }
        centered
        styles={{
          content: {
            boxShadow: "red 0 0 6px",
            border: "1px solid #a00",
          },
          title: {
            maxWidth: "92%",
          },
        }}
      >
        <Text size="sm">
          {lang.components.deletion_action.dialog.text} <br />
          {lang.components.deletion_action.dialog.deleted_on_shared_too} <br />
          <b> {lang.components.deletion_action.dialog.warning_text} </b>
        </Text>

        <Group justify="end" mt={"sm"}>
          <Button variant="subtle" onClick={close} disabled={loading}>
            {lang.components.deletion_action.dialog.cancel_button}
          </Button>
          <Button color="red" onClick={main} loading={loading}>
            {lang.components.deletion_action.dialog.submit_button}
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export default ItemDeleter;
