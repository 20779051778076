import { useMemo, useState } from "react";
import { getEmails } from "../../../../api/EmailAPI";
import { useAccountsStore } from "../../../../stores/ConfigStore";
import MultiSelector from "../../../../components/Selectors/MultiSelector";
import { Link } from "react-router-dom";
import { getTypeIcon } from "../../workflows/CreateCampaign/flow/flowSettings";

function getOptionName(option) {
  return `${option.name} #${option.id} (${option.email})`;
}

const EmailsSelector = ({
  label,
  placeholder,
  size,
  values,
  onChange,
  disabled,
  leftSection,
  inputHeight = 102,
  dropdownHeight = 150,
}) => {
  const emails = useAccountsStore((s) => s.email_accounts);
  const [loading, setLoading] = useState(false);
  const handleLoad = () => {
    setLoading(true);
    getEmails().finally(() => setLoading(false));
  };

  const options = useMemo(() => {
    return emails
      .filter((x) => x.account_type !== "MassMailing")
      .map((item) => ({
        ...item,
        option_name: getOptionName(item),
      }));
  }, [emails]);

  return (
    <MultiSelector
      options={options}
      label={label}
      onDropdownOpen={handleLoad}
      loading={loading}
      values={values}
      onChange={onChange}
      placeholder={placeholder}
      leftSection={leftSection}
      leftOptionSection={(item) =>
        getTypeIcon(item.account_type, false, 18, {
          variant: "subtle",
          size: "sm",
          color: item.account_type === "SMTP" ? "primary" : "pink",
        })
      }
      rightOptionSection={(item) => {
        return <Link to={`/senders/email/${item.id}`}>#{item.id}</Link>;
      }}
      size={size}
      disabled={disabled}
      w={"100%"}
      searchable
      checkIconPosition="left"
      dropdownHeight={dropdownHeight}
      styles={{
        input: {
          borderColor: "var(--mantine-primary-color-4)",
          borderWidth: 2,
          minHeight: inputHeight,
        },
      }}
      autoFocus={false}
    />
  );
};

export default EmailsSelector;
