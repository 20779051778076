import { rem } from "@mantine/core";

export function IconGmaps({ size, style, ...others }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-116.685 -100.925 1011.27 1250.55"
      style={{ width: rem(size), height: rem(size), ...style }}
      {...others}
    >
      <g fill="none">
        <path
          fill="#34A853"
          d="M214.3 826.7c34.5 43.1 69.5 97.1 87.9 129.8 22.4 42.5 31.6 71.2 48.3 122.4 9.8 28.2 19 36.8 38.5 36.8 21.3 0 31-14.4 38.5-36.8 15.5-48.3 27.6-85 46.5-120.1 37.3-67.2 84.5-127 130.4-184.4 12.6-16.1 93.1-110.9 129.3-186.1 0 0 44.2-82.2 44.2-197.1 0-107.4-43.7-182.1-43.7-182.1L607.8 243l-77 202.2-19 27.6-4 5.2-5.2 6.3-8.6 10.3-12.6 12.6-68.4 55.7-170.6 98.8z"
        />
        <path
          fill="#FBBC04"
          d="M37.9 574.5c41.9 95.4 121.8 178.7 176.4 252.2l289-342.4s-40.8 53.4-114.3 53.4c-82.2 0-148.8-65.5-148.8-148.2 0-56.9 33.9-95.9 33.9-95.9l-196 52.3z"
        />
        <path
          fill="#4285F4"
          d="M506.7 17.8c95.9 31 178.1 95.9 227.5 191.9l-231 275.2s33.9-39.6 33.9-95.9c0-84.5-71.2-148.2-148.2-148.2-73 0-114.9 52.9-114.9 52.9V120.1z"
        />
        <path
          fill="#1A73E8"
          d="M90.7 139c57.4-68.4 158-139 297-139C454.9 0 506 17.8 506 17.8L274 293.6H109.7z"
        />
        <path
          fill="#EA4335"
          d="M37.9 574.5S0 499.2 0 390.7c0-102.8 40.2-192.5 91.3-251.1l183.3 154.5z"
        />
      </g>
    </svg>
  );
}
