import { Button, Group, Stack } from "@mantine/core";
import { useRef, useState } from "react";
import TextEditor from "../../../../../../../../components/ExternalEditors/TextEditor";
import { useLanguage } from "../../../../../../../../stores/LanguageStore";
import { editNote } from "../../../../../../../../api/PRMAPI";
import {
  useLeadsContainerStore,
  useLeadsSheetStore,
} from "../../../../../../../../stores/LeadsStore";
import { notifications } from "@mantine/notifications";
import BackButton from "../../../../../../../../components/View/BackButton";
import { IconEdit } from "@tabler/icons-react";

const EditNoteContent = ({ handleClose, item, outsideCurrent, container }) => {
  const editorRef = useRef();
  const lang = useLanguage((s) => s.language);
  const current = useLeadsSheetStore((s) => s.current);
  const setCurrent = useLeadsSheetStore((s) => s.setCurrent);
  const updateContainerItem = useLeadsContainerStore(
    (s) => s.updateContainerItem
  );
  const [loading, setLoading] = useState(false);

  const handleSave = () => {
    setLoading(true);
    const html = editorRef.current.getContent();
    editNote({
      prospect_id: outsideCurrent?.id || current?.id,
      note_id: item.id,
      note: html,
    })
      .then((response) => {
        let newNote = {
          date: item.date,
          id: item.id,
          date_updated: new Date().toISOString(),
          note: html,
          history_type: "note",
        };
        let newHistory = current?.history?.map((x) => {
          if (x.id === item.id) {
            return newNote;
          }
          return x;
        });
        if (outsideCurrent) {
          updateContainerItem(container.id, current.id, {
            ...current,
            history: newHistory,
          });
        } else {
          setCurrent({
            ...current,
            history: newHistory,
          });
        }
        notifications.show({
          message: lang.prm.details.notes.edited,
          color: "tertiary.8",
        });
        handleClose();
      })
      .finally(() => setLoading(false));
  };

  return (
    <Stack w={"100%"} p={4} gap={8}>
      <TextEditor
        editorRef={editorRef}
        id={"tab-note"}
        onInit={(editor) => editor.setContent(item?.note)}
        placeholder={lang.prm.details.notes.placeholder}
        isText
        height={400}
        noTop
        toolbar="bold italic underline numlist bullist link |"
        autoFocus
      />

      <Group justify="end">
        <BackButton onClick={handleClose} />
        <Button
          loading={loading}
          onClick={handleSave}
          leftSection={<IconEdit size={18} />}
          // size="compact-md"
        >
          {lang.global.edit}
        </Button>
      </Group>
    </Stack>
  );
};

export default EditNoteContent;
