import {
  Accordion,
  Anchor,
  CopyButton,
  Group,
  Stack,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { IconWorldShare } from "@tabler/icons-react";
import React from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";

const CustomDomain = ({ emailProfile }) => {
  const lang = useLanguage((s) => s.language);
  return (
    <Accordion variant="contained" mb={"sm"} defaultValue={"send"}>
      <Accordion.Item value={"send"}>
        <Accordion.Control
          icon={<IconWorldShare size={16} />}
          px={"xs"}
          bg={"var(--mantine-color-gray-0)"}
        >
          {lang.emails_account.custom_domain}
        </Accordion.Control>
        <Accordion.Panel>
          <Stack gap={"sm"}>
            <Text size="sm">{lang.emails_account.domain_text}</Text>
            <Group>
              <Text size="sm">{lang.emails_account.domain_text2}
              <CopyButton value={"resellers-pool1.magileads.net"}>
                {({ copied, copy }) => (
                  <Tooltip
                    label={copied ? lang.global.copied : lang.global.copy}
                    color={copied ? "green" : "black"}
                  >
                    <Anchor c={"black"} onClick={copy} truncate="end" fw={600} size="md" ml={8}>
                      {"resellers-pool1.magileads.net"}
                    </Anchor>
                  </Tooltip>
                )}
              </CopyButton></Text>
            </Group>

            <TextInput
              name="custom_tracking_domain"
              label={lang.emails_account.custom_domain}
              placeholder={"ex: app.example.com"}
              defaultValue={emailProfile?.custom_tracking_domain}
            />
          </Stack>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

export default CustomDomain;
