import { useState } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useProfileStore } from "../../../../stores/UserStore";
import GlobalLanguageSelector from "../../../../components/Selectors/GlobalLanguageSelector";
import { updateProfile } from "../../../../api/UserAPI";
import { editList, translateList } from "../../../../api/ContactAPI";
import { useListStore } from "../../../../stores/ListStore";
import { Button, Group, Modal, Text } from "@mantine/core";
import BackButton from "../../../../components/View/BackButton";

const ListLanguage = ({ current, isProfile, onFinish }) => {
  const profile = useProfileStore((s) => s.profile);
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(false);
  const [change, setChange] = useState();

  const [key, setKey] = useState(0);

  const onChange = (v) => {
    setChange(v);
  };
  const handleClose = (v) => {
    setChange(null);
    setKey((prev) => prev + 1);
  };

  const handleLanguageChange = () => {
    setLoading(true);

    const f = isProfile
      ? async () => updateProfile(current.id, { contact_list_language: change })
      : async () => editList({ id: current.id, language: change });

    f()
      .then(() => {
        if (!isProfile) {
          let lists = useListStore.getState().lists;
          let setLists = useListStore.getState().setLists;
          setLists(
            lists.map((x) => {
              if (x.id === current.id) {
                return {
                  ...x,
                  language: change,
                };
              }
              return x;
            })
          );
        } else {
          let setProfile = useProfileStore.getState().setProfile;
          setProfile({
            ...profile,
            contact_list_language: change,
          });
        }

        translateList({
          contact_list_id: current.id,
          language: change,
        })
          .then(() => {
            onFinish?.();
            setChange(null);
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(() => setLoading(false));
  };

  return (
    <>
      <GlobalLanguageSelector
        key={key}
        onChange={onChange}
        required={false}
        name={isProfile ? "contact_list_language" : "language"}
        defaultValue={
          current?.[isProfile ? "contact_list_language" : "language"] ||
          profile?.contact_list_language
        }
        noLabel={!isProfile}
        tooltip={lang.contact_lists.translate.dialog.text}
      />

      <Modal
        opened={!!change}
        onClose={handleClose}
        title={lang.contact_lists.translate.title}
        centered
      >
        <Text size="sm" mb={16}>
          {lang.contact_lists.translate.warning}
        </Text>
        <Group justify="end">
          <BackButton onClick={handleClose} />
          <Button onClick={handleLanguageChange} loading={loading}>
            {lang.contact_lists.translate.confirm}
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export default ListLanguage;
