import { Group, Stack } from "@mantine/core";
import CreateModelEmail from "./emails/CreateModelEmail";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback } from "react";
import CreateModelLinkedin from "./linkedin/CreateModelLinkedin";
import PagePermission from "../../../components/hooks/usePagePermission";
import { getModelTypeProps } from "./modelUtils";
import { useLanguage } from "../../../stores/LanguageStore";
import PageTitle from "../../../components/View/PageTitle";
import CreateModel from "./CreateModel";

const ModelPageCreate = ({ is_ai }) => {
  const lang = useLanguage((s) => s.language);
  const { folderId, modelType } = useParams();
  const navigate = useNavigate();

  const onCreate = useCallback(
    (newItem) =>
      navigate(
        `/models${
          folderId ? `/folder/${folderId}` : `/${modelType}`
        }/?highlight=${newItem.id}`
      ),
    [folderId, modelType, navigate]
  );

  const getComponent = useCallback(() => {
    if (modelType === "email") {
      return <CreateModelEmail onCreated={onCreate} is_ai={is_ai} />;
    }
    if (modelType === "linkedin-invitation") {
      return (
        <CreateModelLinkedin
          type={"linkedin_invitation"}
          onCreated={onCreate}
        />
      );
    }
    if (modelType === "linkedin-message") {
      return (
        <CreateModelLinkedin
          type={"linkedin_message"}
          onCreated={onCreate}
          maxChars={null}
        />
      );
    }
    if (modelType === "sms") {
      return (
        <CreateModelLinkedin type={"sms"} onCreated={onCreate} maxChars={160} />
      );
    }
  }, [modelType, onCreate, is_ai]);

  return (
    <>
      <PagePermission
        permission={getModelTypeProps(modelType)?.create_permission}
      />
      <Group className="layout-block top" justify="space-between">
        <PageTitle
          title={`${lang.models.create_model}: ${
            is_ai
              ? lang.routes.models.prompt
              : getModelTypeProps(modelType)?.name
          }`}
        />
        <CreateModel variant="light" />
      </Group>

      <Stack
        flex={is_ai ? false : 1}
        className="layout-block"
        mx={"auto"}
        px={"sm"}
        pt={"sm"}
        gap={"xs"}
        w={is_ai ? "60%" : undefined}
      >
        {getComponent()}
      </Stack>
    </>
  );
};

export default ModelPageCreate;
