import { Button, Group } from "@mantine/core";
import {
  IconBrandYoutube,
  IconExternalLink,
  IconPlus,
  IconVideo,
} from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { useLanguage } from "../../../stores/LanguageStore";
import ResellerHowTo from "./howto/ResellerHowTo";
import { useSessionStore } from "../../../stores/UserStore";
import Helper from "../../../components/View/Helper";

const CreateResellerButton = () => {
  const lang = useLanguage((s) => s.language);
  const route = `/resellers/white-labels/create`;

  return (
    <Group>
      {useSessionStore.getState().reseller?.name === "Magileads" && (
        <Helper
          label={lang.helpers.reseller}
          leftSection={<IconBrandYoutube size={18} />}
          link={
            "https://app.supademo.com/embed/cm16gjorp082p7rigpb5rfeym?embed_v=2"
          }
        />
      )}

      <ResellerHowTo />

      <Button
        leftSection={<IconPlus size={18} />}
        radius={"sm"}
        color="primary"
        component={Link}
        to={route}
      >
        {lang.resellers.create.button_tooltip}
      </Button>
    </Group>
  );
};

export default CreateResellerButton;
