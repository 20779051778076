import { TimeInput } from "@mantine/dates";
import { useFlowStore } from "../../../../../../../stores/WorkflowStore";
import { defaultNodeConfigs } from "../../flowSettings";

const NodeTimeSelector = ({ node, field, label, error }) => {
  const updateNodeData = useFlowStore((s) => s.updateNodeData);
  const handleTimeChange = (n) => {
    updateNodeData(node.id, { [field]: n });
  };

  return (
    <TimeInput
      label={label}
      defaultValue={node?.data?.[field] || defaultNodeConfigs[field]}
      onChange={(e) => handleTimeChange(e.currentTarget.value)}
      error={error}
      w={"100%"}
      withSeconds
    />
  );
};

export default NodeTimeSelector;
