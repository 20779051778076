import { useEffect, useState } from "react";
import { useFolderStore } from "../../stores/FolderStore";
import CreatableSelector from "./CreatableSelector";
import { useLanguage } from "../../stores/LanguageStore";
import { IconFolder } from "@tabler/icons-react";
import { createFolder, getFolders } from "../../api/FolderAPI";

const FolderSelector = ({ value, setValue, type, required, withinPortal }) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(true);
  const data = useFolderStore((s) => s.folders.filter((x) => x.type === type));

  useEffect(() => {
    if (loading) {
      getFolders().finally(() => setLoading(false));
    }
  }, [loading]);

  return (
    <CreatableSelector
      leftSection={<IconFolder size={16} />}
      label={lang.components.folder_navigator.column_name}
      placeholder={lang.components.folder_navigator.column_name}
      loading={loading}
      createLabel={lang.components.folder_navigator.create_folder}
      data={data}
      createFunction={({ name }) => createFolder({ name, type })}
      value={value}
      setValue={setValue}
      onDropdownOpen={() => setLoading(true)}
      onNewValueCreated={() => setLoading(true)}
      created_field_id={"folder_id"}
      required={required}
      withinPortal={withinPortal}
    />
  );
};

export default FolderSelector;
