import {
  IconArchive,
  IconArchiveOff,
  IconCheck,
  IconPlayerPause,
  IconPlayerPlay,
  IconRocket,
} from "@tabler/icons-react";
import { useMemo } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { Center, SegmentedControl } from "@mantine/core";

const CampaignFilter = ({ onChange, value }) => {
  const lang = useLanguage((s) => s.language);
  const filters = useMemo(() => {
    return [
      {
        value: "running",
        label: (
          <Center style={{ gap: 10 }}>
            <IconRocket size={18} />
            <span>{lang.statistics.filters.running}</span>
          </Center>
        ),
        color: "#0a0",
      },
      {
        value: "stopped",
        label: (
          <Center style={{ gap: 10 }}>
            <IconPlayerPause size={18} />
            <span>{lang.statistics.filters.paused}</span>
          </Center>
        ),
        color: "yellow",
      },
      // {
      //   value: "error",
      //   label: (
      //     <Center style={{ gap: 10 }}>
      //       <IconAlertTriangle size={18} />
      //       <span>{lang.statistics.filters.error}</span>
      //     </Center>
      //   ),
      //   color: "red",
      // },
      {
        value: "archived",
        label: (
          <Center style={{ gap: 10 }}>
            <IconArchive size={18} />
            <span>{lang.statistics.filters.archived}</span>
          </Center>
        ),
        color: "purple",
      },
      {
        value: "non_archived",
        label: (
          <Center style={{ gap: 10 }}>
            <IconArchiveOff size={18} />
            <span>{lang.statistics.filters.non_archived}</span>
          </Center>
        ),
      },
      {
        value: "all",
        label: (
          <Center style={{ gap: 10 }}>
            <span>{lang.statistics.filters.all}</span>
          </Center>
        ),
      },
    ];
  }, [lang.statistics.filters.all, lang.statistics.filters.archived, lang.statistics.filters.non_archived, lang.statistics.filters.paused, lang.statistics.filters.running]);

  return (
    <SegmentedControl
      radius={"sm"}
      onChange={onChange}
      size="sm"
      color={filters.find((x) => x.value === value)?.color}
      data={filters}
    />
  );
};

export default CampaignFilter;
