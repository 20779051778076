import { Button, Group, Modal, Text, Tooltip } from "@mantine/core";
import { useLanguage } from "../../stores/LanguageStore";
import { IconFolderUp } from "@tabler/icons-react";
import { useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import FolderSelector from "../Selectors/FolderSelector";
import BackButton from "../View/BackButton";
import { useUIStore } from "../../stores/UIStore";

const SelectionMover = ({
  handleEdit,
  ids,
  apiRef,
  onFinish,
  setTableLoading,
  folderType,
  disableDelete,
}) => {
  const lang = useLanguage((s) => s.language);
  const [opened, { open, close }] = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [folder, setFolder] = useState(null);

  const main = () => {
    let requests = [];
    ids.forEach((id) => {
      requests.push(
        new Promise((resolve, reject) => {
          handleEdit({ id, folder_id: folder?.id || 0 }).then((res) => {
            if (apiRef && !disableDelete) {
              document.querySelectorAll(`[data-id='${id}']`).forEach((el) => {
                el.style.animation = `forwards 1.5s deletedAnim ease`;
              });
              setTimeout(() => {
                apiRef.current.updateRows([
                  { id, _action: "delete" },
                  { id: `#${id}`, _action: "delete" },
                ]);
              }, 750);
            }
            onFinish?.();
            resolve();
          });
        })
      );
    });

    setLoading(true);
    Promise.all(requests).finally(() => {
      if (apiRef) {
        apiRef.current.setRowSelectionModel([]);
      }
      setTableLoading?.(true);
      setLoading(false);
      close();

      setTimeout(() => {
        useUIStore.getState().setAsideLoading(true);
      }, 200);
    });
  };

  return (
    <>
      <Tooltip label={lang.components.folder_navigator.mover.tooltip}>
        <Button
          className="toolbar-action-button"
          variant="light"
          color="primary"
          disabled={ids.length === 0}
          onClick={open}
        >
          <IconFolderUp size={18} />
        </Button>
      </Tooltip>

      <Modal
        opened={opened}
        onClose={close}
        title={
          <Text size="md" truncate="end">
            {lang.components.folder_navigator.mover.text1}{" "}
            <b>
              {" "}
              {ids.length}
              {lang.components.folder_navigator.mover.elements}{" "}
            </b>
            {lang.components.folder_navigator.mover.text2}
          </Text>
        }
        centered
        styles={{
          title: {
            maxWidth: "92%",
          },
        }}
      >
        <Text size="sm" mb={"sm"}>
          {lang.components.folder_navigator.mover.text3}
        </Text>

        <FolderSelector
          type={folderType}
          value={folder}
          setValue={setFolder}
          required
          withinPortal={true}
        />

        <Group justify="end" mt={"md"}>
          <BackButton onClick={close} />
          <Button color="primary" onClick={main} loading={loading}>
            {lang.components.folder_navigator.move.move}
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export default SelectionMover;
