import { useLanguage } from "../../../../../../stores/LanguageStore";
import { useDisclosure } from "@mantine/hooks";
import { Button, Group, Modal, Stack, Tooltip } from "@mantine/core";
import { IconUserMinus } from "@tabler/icons-react";
import BackButton from "../../../../../../components/View/BackButton";
import ExcludeWorkflow from "./ExcludeWorkflow";
import { useMemo } from "react";
import ExcludeCampaign from "./ExcludeCampaign";

const ExcludeProspect = ({ current, closeMenu, inSheet }) => {
  const lang = useLanguage((s) => s.language);
  const [opened, { open, close }] = useDisclosure();

  const about = useMemo(() => {
    const aboutList = [
      { key: "%first_name%", value: "firstName" },
      { key: "%last_name%", value: "lastName" },
    ];
    let result = {};

    aboutList.forEach((item) => {
      result[item.value] =
        current?.properties?.find((x) => x.identifier_placeholder === item.key)
          ?.value || " - ";
    });

    return result;
  }, [current?.properties]);

  const handleClose = () => {
    close();
    closeMenu?.();
  };

  return (
    <>
      <Tooltip label={lang.prm.exclude.button_tooltip}>
        <Button
          onClick={open}
          leftSection={inSheet ? null : <IconUserMinus size={18} />}
          fw={600}
          color="red"
          variant={inSheet ? "light" : "subtle"}
          justify="start"
          px={inSheet ? "sm" : undefined}
        >
          {inSheet ? (
            <IconUserMinus size={18} />
          ) : (
            lang.prm.exclude.button_label
          )}
        </Button>
      </Tooltip>

      {opened && (
        <Modal
          opened={opened}
          size={"lg"}
          onClose={handleClose}
          title={
            <>
              {lang.prm.exclude.dialog_title}{" "}
              <b>
                {about?.firstName} {about?.lastName} #{current.id}
              </b>
            </>
          }
          styles={{
            content: {
              boxShadow: "red 0 0 6px",
              border: "1px solid #a00",
            },
            title: {
              maxWidth: "92%",
            },
          }}
        >
          <Stack gap={"lg"}>
            <ExcludeWorkflow current={current} />

            <ExcludeCampaign current={current} />

            <Group justify="end" mt={"md"}>
              <BackButton onClick={handleClose} />
            </Group>
          </Stack>
        </Modal>
      )}
    </>
  );
};

export default ExcludeProspect;
