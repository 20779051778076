import { Text } from "@mantine/core";
import { useFlowStore } from "../../../../../../stores/WorkflowStore";

const NodeId = ({ id }) => {
  const linking = useFlowStore((s) => s.linking);
  return (
    <Text
      pos={"absolute"}
      top={linking?.id === id ? -24 : -16}
      left={6}
      size={linking?.id === id ? "lg" : "xs"}
      opacity={linking?.id === id ? 1 : 0.2}
    >
      #{id}
    </Text>
  );
};

export default NodeId;
