import { Box, Button, LoadingOverlay, Stack, Text } from "@mantine/core";
import ResellerOnboardingIllustration from "./ResellerOnboardingIllustration";
import { useProfileStore, useSessionStore } from "../../../../stores/UserStore";
import {
  IconBrandYoutube,
  IconBuildingStore,
  IconExternalLink,
  IconVideo,
} from "@tabler/icons-react";
import Sparkles from "../../../../components/Sparkles/Sparkles";
import { useCallback, useState } from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import { createReseller } from "../../../../api/UserAPI";
import { notifications } from "@mantine/notifications";
import { useLanguage } from "../../../../stores/LanguageStore";
import Step5 from "./Step5";
import Step5Saved from "./Step5Saved";
import StepLoad from "./StepLoad";
import Helper from "../../../../components/View/Helper";

const ResellerOnboarding = ({ setTableLoading }) => {
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [logo, setLogo] = useState(null);
  const [logoMenu, setLogoMenu] = useState(null);
  const [favicon, setFavicon] = useState(null);
  const [colorPrimary, setColorPrimary] = useState("#FDB713");
  const [colorSecondary, setColorSecondary] = useState("#3f51b5");
  const [domain, setDomain] = useState("");

  const handleCreate = useCallback(() => {
    const formData = new FormData();
    formData.set("name", name);
    formData.set("color_primary", colorPrimary.substring(1));
    formData.set("color_secondary", colorSecondary.substring(1));
    formData.set("logo", logo);
    formData.set("logo_menu", logoMenu);
    formData.set("favicon", favicon);
    formData.set("domain_saas2", domain);

    setStep(100);
    createReseller({ formData })
      .then((response) => {
        notifications.show({
          message: lang.resellers.row_highlight.created,
          color: "tertiary.8",
        });
        setStep(5);
      })
      .catch((err) => {
        setStep(4);
      });
  }, [
    colorPrimary,
    colorSecondary,
    domain,
    favicon,
    lang.resellers.row_highlight.created,
    logo,
    logoMenu,
    name,
  ]);

  const handleSave = useCallback(() => {
    const formData = new FormData();
    formData.set("name", name);
    formData.set("color_primary", colorPrimary.substring(1));
    formData.set("color_secondary", colorSecondary.substring(1));
    formData.set("logo", logo);
    formData.set("logo_menu", logoMenu);
    formData.set("favicon", favicon);

    setLoading(true);
    createReseller({ formData })
      .then((response) => {
        notifications.show({
          message: lang.resellers.row_highlight.created,
          color: "tertiary.8",
        });
        setStep(6);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    colorPrimary,
    colorSecondary,
    favicon,
    lang.resellers.row_highlight.created,
    logo,
    logoMenu,
    name,
  ]);

  const getStepComponent = useCallback(() => {
    if (step === 1) {
      return <Step1 setStep={setStep} setName={setName} name={name} />;
    }
    if (step === 2) {
      return (
        <Step2
          setStep={setStep}
          setLogo={setLogo}
          logo={logo}
          favicon={favicon}
          setFavicon={setFavicon}
          logoMenu={logoMenu}
          setLogoMenu={setLogoMenu}
        />
      );
    }
    if (step === 3) {
      return (
        <Step3
          setStep={setStep}
          colorPrimary={colorPrimary}
          colorSecondary={colorSecondary}
          setColorPrimary={setColorPrimary}
          setColorSecondary={setColorSecondary}
        />
      );
    }
    if (step === 4) {
      return (
        <Step4
          setStep={setStep}
          domain={domain}
          setDomain={setDomain}
          handleCreate={handleCreate}
          handleSave={handleSave}
        />
      );
    }
    if (step === 5) {
      return <Step5 domain={domain} setTableLoading={setTableLoading} />;
    }
    if (step === 6) {
      return <Step5Saved setTableLoading={setTableLoading} />;
    }
    if (step === 100) {
      return <StepLoad />;
    }
  }, [
    colorPrimary,
    colorSecondary,
    domain,
    favicon,
    handleCreate,
    handleSave,
    logo,
    logoMenu,
    name,
    setTableLoading,
    step,
  ]);

  return (
    <Stack
      className="layout-block"
      flex={"1 1 auto"}
      justify="center"
      align="center"
      gap={"xl"}
      pos={"relative"}
    >
      <LoadingOverlay visible={loading} overlayProps={{ blur: 2 }} />
      {step > 0 ? (
        getStepComponent()
      ) : (
        <>
          <Text fz={28} className="animate__animated animate__fadeIn">
            {profile.first_name}, {lang.resellers.onboarding.title}{" "}
            <b> {lang.resellers.onboarding.for_free}</b> !
          </Text>
          <Box
            w={"50%"}
            className="animate__animated animate__fadeInUp"
            pos={"relative"}
          >
            <Sparkles
              style={{
                width: "fit-content",
                margin: "auto auto",
                position: "absolute",
                right: "5%",
                top: "22%",
              }}
            >
              <Button
                leftSection={<IconBuildingStore size={22} />}
                variant="gradient"
                size={"xl"}
                radius="xl"
                className="sparkle-button animate__animated animate__fadeInRight animate__delay-1s"
                onClick={() => setStep(1)}
              >
                {lang.resellers.onboarding.first_button}
              </Button>
            </Sparkles>
            {useSessionStore.getState().reseller?.name === "Magileads" && (
              <Helper
                label={lang.helpers.reseller}
                autoPlay={1000}
                title={lang.helpers.reseller_title}
                leftSection={<IconBrandYoutube />}
                link={
                  "https://app.supademo.com/embed/cm16gjorp082p7rigpb5rfeym?embed_v=2"
                }
                variant="gradient"
                size={"lg"}
                radius="xl"
                className="sparkle-button animate__animated animate__fadeInRight"
                gradient={{ from: "primary.4", to: "primary.5", deg: 90 }}
                style={{
                  width: "fit-content",
                  margin: "auto auto",
                  position: "absolute",
                  right: "5%",
                  top: "43%",
                  animationDelay: "1.5s",
                }}
              />
            )}
            <Box w={"75%"}>
              <ResellerOnboardingIllustration />
            </Box>
          </Box>
        </>
      )}
    </Stack>
  );
};

export default ResellerOnboarding;
