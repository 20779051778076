import { Button } from "@mantine/core";
import { IconRocket } from "@tabler/icons-react";
import { useMemo, useState } from "react";
import ReactJoyride from "react-joyride";
import { useUIStore } from "../../../../stores/UIStore";
import { useSessionStore } from "../../../../stores/UserStore";
import { useLanguage } from "../../../../stores/LanguageStore";
import Step0 from "./Step0";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import Step7 from "./Step7";
import Sparkles from "../../../../components/Sparkles/Sparkles";

const MainFeatures = ({ onLaunch, alwaysVisible }) => {
  const reseller = useSessionStore((s) => s.reseller);
  const lang = useLanguage((s) => s.language);
  const [running, setRunning] = useState(false);
  const steps = useMemo(() => {
    return [
      {
        target: "body",
        content: <Step0 />,
        disableBeacon: true, // To autostart the tour
        placement: "center",
      },
      {
        target: "#models",
        content: <Step1 />,
        placement: "right",
      },
      {
        target: "#contact-lists",
        content: <Step2 />,
        placement: "right",
      },
      {
        target: "#senders",
        content: <Step3 />,
        placement: "right",
      },
      {
        target: "#campaign-button",
        content: <Step4 />,
        placement: "right",
      },
      {
        target: "#statistics",
        content: <Step5 />,
        placement: "right",
      },
      {
        target: "#leads-tracking",
        content: <Step6 />,
        placement: "right-end",
      },
      {
        target: "body",
        content: <Step7 />,
        placement: "center",
      },
    ];
  }, []);

  const onButtonClick = () => {
    useUIStore.getState().setCampaignOpened(true);
    useUIStore.getState().setNavbarClosed(false);

    if (onLaunch) {
      onLaunch();
    } else {
      setRunning(true);
    }
  };

  const handleTour = (data) => {
    if (
      (data.type === "tour:end" && data.action !== "skip") ||
      data.action === "skip" ||
      data.action === "close"
    ) {
      setRunning(false);
    }
  };

  return (
    <>
      <ReactJoyride
        steps={steps}
        continuous
        run={running}
        callback={handleTour}
        styles={{
          options: {
            zIndex: 9999,
            width: "auto",
            primaryColor:
              "#" + useSessionStore?.getState()?.reseller?.color_secondary,
          },
          tooltipContent: {
            textAlign: "left",
          },
          buttonNext: {
            fontSize: 14,
            marginLeft: 16,
          },
          buttonClose: {
            fontSize: 14,
          },
          buttonSkip: {
            fontSize: 14,
          },
          buttonBack: {
            fontSize: 14,
          },
        }}
        locale={{
          next: lang.tours.next,
          back: lang.tours.prev,
          skip: lang.tours.skip,
          close: lang.tours.close,
          open: lang.tours.open,
          last: lang.tours.last,
        }}
        spotlightPadding={0}
      />

      <Sparkles
        style={{
          width: "fit-content",
          margin: onLaunch ? "0" : "auto auto",
          display: alwaysVisible
            ? "flex"
            : reseller.name === "Magileads"
            ? "none"
            : "flex",
        }}
        color="white"
      >
        <Button
          leftSection={<IconRocket size={22} />}
          onClick={onButtonClick}
          variant="gradient"
          size={"lg"}
          radius="xl"
          className="sparkle-button animate__animated animate__fadeIn animate__delay-1s"
          style={{ zIndex: 8 }}
          gradient={{ from: "secondary.4", to: "secondary.6" }}
          id="launch-onboarding"
        >
          {lang.home.discover.button_label}
        </Button>
      </Sparkles>
    </>
  );
};

export default MainFeatures;
