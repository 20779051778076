import { Box, Stack, Text } from "@mantine/core";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useThemeStore } from "../../../../stores/ThemeStore";

const Image = ({ ...props }) => {
  const theme = useThemeStore((s) => s.theme);
  const mainColor = theme.colors.primary[6];
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 777.00073 407.99846"
    >
      <path
        d="M986.84973,543.39925c-4.06982,25.87-16.68017,50.98-37.49023,66.87-20.48,15.65-46.71973,21.21-72.04981,25.99q-45.56982,8.61-91.39013,15.74-6.49512,1.035-13,2H594.99963c-.18017-.24-.37011-.49-.5498-.74-.29-.42-.58008-.84-.86035-1.26-.15967-.24-.31983-.49-.48-.74H704.70959a11.96072,11.96072,0,0,0,11.9502-11.94v-148.96c.71973-2.26,1.38965-4.53,2-6.82995a180.05237,180.05237,0,0,0,5.52979-32.71c.60009-7.7.78027-15.4.6499-23.11-.36963-21.19-3.13965-42.38-6.1499-63.42-.21973-1.55-.44971-3.11-.66993-4.66-2.12011-14.62-4.0498-30.53,3.31983-43.34,8.43017-14.66,27.14013-20.77,43.99023-19.27,30.31006,2.71,55.83985,25.92,69.10987,53.31,13.26025,27.38,16.23,58.52,17.43994,88.92.64013,16.12.57031,32.96-1.67969,49.13q11.39941-26.07,22.81982-52.14c4.06006-9.27,8.33008-18.84,15.8501-25.62,15.71973-14.19,41.71973-11.33,58.50977,1.59,16.78027,12.93,25.98,33.36,32.10009,53.63C987.05969,490.9092,990.92981,517.52926,986.84973,543.39925Z"
        transform="translate(-211.49963 -246.00077)"
        fill="#f2f2f2"
      />
      <path
        d="M919.79782,402.88685c10.9923,19.4683,16.03538,41.79209,15.91469,64.06956A155.08734,155.08734,0,0,1,921.38114,530.886a160.6074,160.6074,0,0,1-34.46657,48.29863A146.33182,146.33182,0,0,1,837.383,610.825a129.43749,129.43749,0,0,1-67.21077,6.38653q-4.70407-.80839-9.33861-1.95981c-1.87441-.462-2.6741,2.43033-.79752,2.89284A132.77706,132.77706,0,0,0,831.278,616.0956a146.7727,146.7727,0,0,0,52.18265-29.68634,162.9081,162.9081,0,0,0,37.4259-47.71227,159.49477,159.49477,0,0,0,17.57522-63.86338c1.3102-22.61163-2.48049-45.62617-12.25915-66.13774q-1.77741-3.72827-3.81437-7.32317a1.50113,1.50113,0,0,0-2.59042,1.51415Z"
        transform="translate(-211.49963 -246.00077)"
        fill="#fff"
      />
      <path
        d="M760.5882,296.62254a325.34391,325.34391,0,0,1,28.29774,70.98262,342.83439,342.83439,0,0,1,5.83225,151.56009,324.11436,324.11436,0,0,1-22.78862,72.9844c-10.41915,23.14713-25.4196,47.536-51.04216,55.64059a53.43305,53.43305,0,0,1-8.85978,1.98052c-1.90417.26134-1.09355,3.15238.79752,2.89284,26.50486-3.63773,44.92183-25.65744,56.47214-48.10346,11.95115-23.225,20.461-48.4457,26.12039-73.909a345.52584,345.52584,0,0,0-1.1801-154.12143,330.4157,330.4157,0,0,0-26.52943-72.79677q-2.19828-4.347-4.52954-8.62454c-.92274-1.69656-3.514-.184-2.59041,1.51416Z"
        transform="translate(-211.49963 -246.00077)"
        fill="#fff"
      />
      <path
        d="M635.13977,292.25924H455.53967a13.76779,13.76779,0,0,0-13.75,13.75v89.99h27.64014a11.87878,11.87878,0,0,1,10.10986,5.59l9.58008,15.25,9.16016,14.56994,1.71972,2.74,10.48,16.67.12012.19h138.29v-145A13.76779,13.76779,0,0,0,635.13977,292.25924Z"
        transform="translate(-211.49963 -246.00077)"
        fill="#ccc"
      />
      <path
        d="M563.94983,319.76925H474.95959a8.895,8.895,0,1,0,0,17.79h88.99024a8.895,8.895,0,1,0,0-17.79Z"
        transform="translate(-211.49963 -246.00077)"
        fill="#fff"
      />
      <path
        d="M613.29944,363.44924H475.76965a9.71,9.71,0,0,0,0,19.42H613.29944a9.71,9.71,0,0,0,0-19.42Z"
        transform="translate(-211.49963 -246.00077)"
        fill="#fff"
      />
      <path
        d="M622.07971,403.01925a9.70445,9.70445,0,0,0-8.78027-5.59H475.76965c-.21,0-.41015.01-.62011.02a11.91385,11.91385,0,0,1,4.39013,4.14l9.58008,15.25H613.29944a9.69745,9.69745,0,0,0,8.78027-13.82Z"
        transform="translate(-211.49963 -246.00077)"
        fill="#fff"
      />
      <path
        d="M622.90979,439.80923a9.7092,9.7092,0,0,0-9.61035-8.4H498.27991l1.71972,2.74,10.48,16.67H613.29944a9.59905,9.59905,0,0,0,5.63037-1.81,9.69664,9.69664,0,0,0,4.06982-7.9A9.24451,9.24451,0,0,0,622.90979,439.80923Z"
        transform="translate(-211.49963 -246.00077)"
        fill="#fff"
      />
      <path
        d="M732.68958,369.75924l-14-5.47-69.79981-27.24-83.51025-32.59a13.77453,13.77453,0,0,0-17.81006,7.82l-2.91992,7.49-6.94971,17.79-10.1001,25.89-7.58008,19.42-5.68017,14.56-7.58008,19.41-5.68994,14.56994-.15967.42005-.91016,2.32,10.48,16.67.12012.19H704.70959a11.91612,11.91612,0,0,1,9.29,4.44l.81983-2.08,10.02-25.66,15.66015-40.13A13.77715,13.77715,0,0,0,732.68958,369.75924Z"
        transform="translate(-211.49963 -246.00077)"
        fill={mainColor}
      />
      <path
        d="M656.36975,369.49923l-7.48-2.92-75.41992-29.43a8.93074,8.93074,0,0,0-11.53027,5.05,8.924,8.924,0,0,0,5.06005,11.53l24.91016,9.72,30.81006,12.02,26.16992,10.22,1.00977.39a8.89889,8.89889,0,0,0,6.47021-16.58Z"
        transform="translate(-211.49963 -246.00077)"
        fill="#fff"
      />
      <path
        d="M686.44983,428.13924l-37.56006-14.66-26.81006-10.46-14.32031-5.59-37.29981-14.56-12.12011-4.73a9.70982,9.70982,0,0,0-12.23,4.73c-.11963.25-.23975.51-.33985.78a9.70379,9.70379,0,0,0,5.51026,12.57l3.09961,1.21,49.72021,19.41,44.79,17.48,30.5,11.91a9.62861,9.62861,0,0,0,3.52.66,9.70595,9.70595,0,0,0,3.54-18.75Z"
        transform="translate(-211.49963 -246.00077)"
        fill="#fff"
      />
      <path
        d="M648.88977,449.94924l-2.41016-.94-23.56982-9.2-21.53027-8.4-37.33985-14.56994-18.06006-7.05a9.70758,9.70758,0,1,0-7.0498,18.09l9.04,3.53,45.09961,17.6,4.64013,1.81.48.19h53.41015Z"
        transform="translate(-211.49963 -246.00077)"
        fill="#fff"
      />
      <path
        d="M557.65979,450.81924l-4.64014-1.81-19.39013-7.57a9.69455,9.69455,0,0,0-13.12012,7.57,9.2009,9.2009,0,0,0-.10986,1.81,1.12063,1.12063,0,0,0,.01025.19h37.73975Z"
        transform="translate(-211.49963 -246.00077)"
        fill="#fff"
      />
      <path
        d="M597.70959,450.81924l-4.64013-1.81H520.5094a9.2009,9.2009,0,0,0-.10986,1.81h-9.91993l.12012.19h87.58985Zm0,0h-87.23l.12012.19h87.58985Zm0,0h-87.23l.12012.19h87.58985Zm.48.19-.48-.19h-87.23l.12012.19Zm-175.00977,200.25a11.95822,11.95822,0,0,1-11.93994-11.94v-231.37a11.96034,11.96034,0,0,1,11.93994-11.95h18.60986v-2H423.17981a13.96617,13.96617,0,0,0-13.93994,13.95v231.37a13.95043,13.95043,0,0,0,8.16992,12.68H593.58948c-.15967-.24-.31983-.49-.48-.74Zm291.63965-197.89a13.87759,13.87759,0,0,0-10.10987-4.36h-193l-10.7998-17.18-.26025-.42005-9.15967-14.56994-10.26026-16.32a13.97619,13.97619,0,0,0-11.7998-6.52h-46.25a13.96617,13.96617,0,0,0-13.93994,13.95v231.37a13.95043,13.95043,0,0,0,8.16992,12.68,13.74939,13.74939,0,0,0,5.77,1.26H704.70959a13.79793,13.79793,0,0,0,5.78028-1.26,13.95124,13.95124,0,0,0,8.16992-12.68v-176.36A13.87,13.87,0,0,0,714.81946,453.36922Zm1.84033,185.95a11.96072,11.96072,0,0,1-11.9502,11.94H423.17981a11.95822,11.95822,0,0,1-11.93994-11.94v-231.37a11.96034,11.96034,0,0,1,11.93994-11.95h46.25a11.87878,11.87878,0,0,1,10.10986,5.59l9.58008,15.25,9.16016,14.56994,1.71972,2.74,10.48,16.67.12012.19H704.70959a11.96045,11.96045,0,0,1,11.9502,11.95Zm-118.9502-188.5h-87.23l.12012.19h87.58985Zm0,0h-87.23l.12012.19h87.58985Zm0,0h-87.23l.12012.19h87.58985Zm0,0-4.64013-1.81H511.70959l-10.7998-17.18-.91016,2.32,10.48,16.67.12012.19h87.58985Z"
        transform="translate(-211.49963 -246.00077)"
        fill="#3f3d56"
      />
      <polygon
        points="156.928 287.77 151.2 299.746 102.283 283.352 110.736 265.677 156.928 287.77"
        fill="#ffb8b8"
      />
      <path
        d="M358.52955,543.80507h26.83419a0,0,0,0,1,0,0v16.1195a0,0,0,0,1,0,0h-41.721a0,0,0,0,1,0,0v-1.23264A14.88686,14.88686,0,0,1,358.52955,543.80507Z"
        transform="translate(-502.12206 396.57105) rotate(-64.4384)"
        fill="#2f2e41"
      />
      <polygon
        points="168.459 394.715 155.192 394.267 154.633 342.623 170.186 343.541 168.459 394.715"
        fill="#ffb8b8"
      />
      <path
        d="M355.83539,636.31276h26.83419a0,0,0,0,1,0,0v16.1195a0,0,0,0,1,0,0h-41.721a0,0,0,0,1,0,0v-1.23264A14.88686,14.88686,0,0,1,355.83539,636.31276Z"
        transform="translate(-189.55753 -257.83873) rotate(1.93309)"
        fill="#2f2e41"
      />
      <path
        d="M401.32408,424.5795s-40.605-10.28659-59.01257,4.8726l-10.28659,29.777-41.14638,58.47117,59.554,35.73238,10.828-27.07-25.98718-12.9936,31.40118-27.07-5.414,142.92952,20.57319,3.2484S427.31127,455.98068,401.32408,424.5795Z"
        transform="translate(-211.49963 -246.00077)"
        fill="#2f2e41"
      />
      <path
        d="M339.72057,433.85626l.49487-.87978c5.217-9.27464,4.37164-24.37779,2.744-35.41409-1.36328-9.244-1.32231-25.53461-1.04791-37.57336A17.89121,17.89121,0,0,1,356.173,342.76787c12.35254-2.57059,27.51728-3.11516,35.45746-3.21139l.00661.5414-.00661-.5414a8.24494,8.24494,0,0,1,8.27538,8.04275c.24162,12.964.16654,36.60475-3.05066,49.28852-4.77479,18.82527,2.69166,31.618,2.7678,31.74485l.45046.75288Z"
        transform="translate(-211.49963 -246.00077)"
        fill={mainColor}
      />
      <path
        d="M318.8336,485.774a10.02479,10.02479,0,0,0,3.81053-14.892l22.91578-98.81821H328.23512l-20.22438,96.87052A10.07912,10.07912,0,0,0,318.8336,485.774Z"
        transform="translate(-211.49963 -246.00077)"
        fill="#ffb8b8"
      />
      <path
        d="M458.17105,388.50259l-38.2425-36.17534-10.33582,14.47013,25.83953,22.73879-33.88713,26.91034c-.24477-.01653-.48853-.03722-.73784-.03722a10.85261,10.85261,0,1,0,10.74561,12.296l.107.107Z"
        transform="translate(-211.49963 -246.00077)"
        fill="#ffb8b8"
      />
      <path
        d="M353.74046,344.03808s-23.88114-3.91694-26.04674,14.49065-3.2484,18.40759-3.2484,18.40759,17.32479-3.24839,21.656,1.0828S353.74046,344.03808,353.74046,344.03808Z"
        transform="translate(-211.49963 -246.00077)"
        fill={mainColor}
      />
      <path
        d="M389.41329,341.20394s19.49039-3.24839,28.15278,5.414,12.9936,10.828,12.9936,10.828-19.49039,10.828-11.9108,20.57319c0,0-20.57319-9.74519-23.82158-6.49679S389.41329,341.20394,389.41329,341.20394Z"
        transform="translate(-211.49963 -246.00077)"
        fill={mainColor}
      />
      <path
        d="M372.31839,278.33911c-6.148-4.91-7.76342-14.38628-4.19638-21.39926s11.63171-11.23809,19.4934-10.92256,15.26966,4.82908,19.82422,11.24482c6.39793,9.01238,7.74052,21.53835,16.24786,28.594,7.68395,6.37273,18.69155,6.19022,28.66139,5.68324,3.36071-.1709,6.83013-.369,9.84577-1.86219s5.47946-4.64237,5.09075-7.98489c2.50462,3.96489,1.05154,9.524-2.30876,12.79543s-8.145,4.642-12.80289,5.18773a47.56885,47.56885,0,0,1-43.1514-18.93293c-3.46887-4.78542-6.146-10.3579-10.906-13.86156s-12.69667-3.74754-15.61885,1.39c-1.44527,2.541-1.30342,5.72826-2.63248,8.3319s-5.645,4.00754-6.92478,1.37932"
        transform="translate(-211.49963 -246.00077)"
        fill="#2f2e41"
      />
      <circle cx="159.87891" cy="54.55562" r="30" fill="#2f2e41" />
      <circle cx="159.6978" cy="62.34302" r="24.56103" fill="#ffb8b8" />
      <path
        d="M348.80242,290.30517c3.338.73012,2.24571,6.32966,4.88341,8.50165,2.37937,1.95927,5.8059-.05564,8.80058-.78507,3.78081-.92091,7.73806.42815,11.22871,2.148s6.8565,3.85421,10.66447,4.65545,8.32247-.10183,10.45715-3.3554c2.04109-3.11093,1.28973-7.30611-.30712-10.66677a24.76974,24.76974,0,0,0-44.442-.61588"
        transform="translate(-211.49963 -246.00077)"
        fill="#2f2e41"
      />
      <path
        d="M927.49963,652.99923a1.00307,1.00307,0,0,1-1,1h-714a1,1,0,0,1,0-2h714A1.00307,1.00307,0,0,1,927.49963,652.99923Z"
        transform="translate(-211.49963 -246.00077)"
        fill="#3f3d56"
      />
    </svg>
  );
};

const Step1 = () => {
  const lang = useLanguage((s) => s.language);
  return (
    <Stack pos={"relative"} style={{ overflow: "hidden" }} w={400}>
      <Text fw={600} fz={"lg"}>
        {lang.home.discover.step1.text1}
      </Text>
      <Text>
        {lang.home.discover.step1.text2} <br /> {lang.home.discover.step1.text3}
      </Text>

      <Box w={300} ml={"auto"} mt={"md"}>
        <Image width="100%" />
      </Box>
    </Stack>
  );
};

export default Step1;
