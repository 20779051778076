import { Anchor, Group, SimpleGrid, Stack, Text } from "@mantine/core";
import { useLanguage } from "../../../../stores/LanguageStore";
import ListImportItem from "./ListImportItem";
import { getListMethod } from "../listUtils";
import { useParams } from "react-router-dom";
import PageTitle from "../../../../components/View/PageTitle";
import HowImportWorks from "./HowImportWorks";
import { useListStore } from "../../../../stores/ListStore";
import { useEffect } from "react";
import { modals } from "@mantine/modals";
import { useSessionStore } from "../../../../stores/UserStore";
import BackButton from "../../../../components/View/BackButton";

const ListMethodPage = () => {
  const lang = useLanguage((s) => s.language);
  const { contactListId } = useParams();

  useEffect(() => {
    const lists = useListStore.getState().lists;
    const reseller = useSessionStore.getState().reseller;
    if (lists.length === 1 && reseller.name === "Magileads") {
      modals.open({
        modalId: "new-user",
        title: lang.contact_lists.import.methods.welcome.title,
        size: "xl",
        children: (
          <Stack>
            <Text size="lg">
              {lang.contact_lists.import.methods.welcome.text}{" "}
              <Anchor
                target="_blank"
                href="https://chromewebstore.google.com/detail/magileads-v2/oojfjkpfkaekaifokifpgfaghiokinaf?pli=1"
              >
                {lang.contact_lists.import.methods.welcome.link}
              </Anchor>
            </Text>

            <iframe
              width="100%"
              height="360"
              src={
                "https://www.youtube.com/embed/hRICGIU7Hl4?si=VXhixCJYQ5BR8MtS"
              }
              title="YouTube video player"
              allowfullscreen=""
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              style={{
                borderRadius: 4,
                overflow: "hidden",
                border: "1px solid #00000033",
              }}
            />

            <Group justify="end" mt={"md"}>
              <BackButton onClick={() => modals.close("new-user")} />
            </Group>
          </Stack>
        ),
      });
    }
  }, []);

  return (
    <>
      <Group className="layout-block top" w={"100%"} justify="space-between">
        <PageTitle title={lang.contact_lists.import.button_label} />

        <HowImportWorks />
      </Group>

      <Stack className="layout-block" pb={"sm"} flex={"1 1 auto"}>
        <Text fw={600} size="lg">
          {lang.contact_lists.import.methods.title}
        </Text>

        <SimpleGrid cols={4}>
          {getListMethod(contactListId).map((item) => (
            <ListImportItem key={item.value} item={item} />
          ))}
        </SimpleGrid>
      </Stack>
    </>
  );
};

export default ListMethodPage;
