import { useLanguage } from "../../../../../../../stores/LanguageStore";

export const allEvents = (exclude_answerers) => [
  {
    value: "email_sent",
    label: useLanguage.getState().language.workflows.nodes.events.email_sent,
  },
  {
    value: "email_open",
    label: useLanguage.getState().language.workflows.nodes.events.email_open,
  },
  {
    value: "email_open_no_click",
    label:
      useLanguage.getState().language.workflows.nodes.events
        .email_open_no_click,
  },
  {
    value: "link_click",
    label: useLanguage.getState().language.workflows.nodes.events.link_click,
  },
  {
    value: "email_answered",
    label:
      useLanguage.getState().language.workflows.nodes.events.email_answered,
  },
  {
    value: "linkedin_invitation_accepted",
    label:
      useLanguage.getState().language.workflows.nodes.events
        .linkedin_invitation_accepted,
  },
  {
    value: "linkedin_message_answered",
    label:
      useLanguage.getState().language.workflows.nodes.events
        .linkedin_message_answered,
  },
  {
    value: "sms_got",
    label: useLanguage.getState().language.workflows.nodes.events.sms_received,
  },
  {
    value: "sms_replied",
    label: useLanguage.getState().language.workflows.nodes.events.sms_replied,
  },
  {
    value: "sms_not_got",
    label:
      useLanguage.getState().language.workflows.nodes.events.sms_not_received,
  },
  {
    value: "sms_sent",
    label: useLanguage.getState().language.workflows.nodes.events.sms_sent,
  },
  {
    value: "smv_sent",
    label: useLanguage.getState().language.workflows.nodes.events.smv_sent,
  },
  {
    value: "smv_got",
    label: useLanguage.getState().language.workflows.nodes.events.smv_received,
  },
  {
    value: "smv_not_got",
    label:
      useLanguage.getState().language.workflows.nodes.events.smv_not_received,
  },
  {
    value: "linkedin_visited",
    label:
      useLanguage.getState().language.workflows.nodes.events.linkedin_visited,
  },
  {
    value: "not_active",
    label: useLanguage.getState().language.workflows.nodes.events.not_active,
  },
  {
    value: "all_contacts",
    label:
      useLanguage.getState().language.workflows.nodes.events[
        exclude_answerers ? "all_contacts2" : "all_contacts"
      ],
  },
  // {
  //   value: "score",
  //   label: useLanguage.getState().language.workflows.nodes.events.score,
  //   disabled: true,
  // },
];

export const emailEvents = (exclude_answerers) => [
  {
    value: "email_sent",
    label: useLanguage.getState().language.workflows.nodes.events.email_sent,
  },
  {
    value: "email_open",
    label: useLanguage.getState().language.workflows.nodes.events.email_open,
  },
  {
    value: "email_open_no_click",
    label:
      useLanguage.getState().language.workflows.nodes.events
        .email_open_no_click,
  },
  {
    value: "link_click",
    label: useLanguage.getState().language.workflows.nodes.events.link_click,
  },
  {
    value: "email_answered",
    label:
      useLanguage.getState().language.workflows.nodes.events.email_answered,
  },
  {
    value: "not_active",
    label: useLanguage.getState().language.workflows.nodes.events.not_active,
  },
  {
    value: "all_contacts",
    label:
      useLanguage.getState().language.workflows.nodes.events[
        exclude_answerers ? "all_contacts2" : "all_contacts"
      ],
  },
  // {
  //   value: "score",
  //   label: useLanguage.getState().language.workflows.nodes.events.score,
  //   disabled: true,
  // },
];

export const linkedinMEvents = (exclude_answerers) => [
  {
    value: "linkedin_message_answered",
    label:
      useLanguage.getState().language.workflows.nodes.events
        .linkedin_message_answered,
  },
  {
    value: "not_active",
    label: useLanguage.getState().language.workflows.nodes.events.not_active,
  },
  {
    value: "all_contacts",
    label:
      useLanguage.getState().language.workflows.nodes.events[
        exclude_answerers ? "all_contacts2" : "all_contacts"
      ],
  },
];

export const linkedinIEvents = (exclude_answerers, messagesAreOnlyChildren) => {
  if (messagesAreOnlyChildren) {
    return [
      {
        value: "linkedin_invitation_accepted",
        label:
          useLanguage.getState().language.workflows.nodes.events
            .linkedin_invitation_accepted,
      },
    ];
  }
  return [
    {
      value: "linkedin_invitation_accepted",
      label:
        useLanguage.getState().language.workflows.nodes.events
          .linkedin_invitation_accepted,
    },
    {
      value: "not_active",
      label: useLanguage.getState().language.workflows.nodes.events.not_active,
    },
    {
      value: "all_contacts",
      label:
        useLanguage.getState().language.workflows.nodes.events[
          exclude_answerers ? "all_contacts2" : "all_contacts"
        ],
    },
  ];
};

export const smsEvents = (exclude_answerers) => [
  {
    value: "sms_sent",
    label: useLanguage.getState().language.workflows.nodes.events.sms_sent,
  },
  {
    value: "sms_replied",
    label: useLanguage.getState().language.workflows.nodes.events.sms_replied,
  },
  {
    value: "sms_got",
    label: useLanguage.getState().language.workflows.nodes.events.sms_received,
  },
  {
    value: "sms_not_got",
    label:
      useLanguage.getState().language.workflows.nodes.events.sms_not_received,
  },
  {
    value: "not_active",
    label: useLanguage.getState().language.workflows.nodes.events.not_active,
  },
  {
    value: "all_contacts",
    label:
      useLanguage.getState().language.workflows.nodes.events[
        exclude_answerers ? "all_contacts2" : "all_contacts"
      ],
  },
];

export const smvEvents = (exclude_answerers) => [
  {
    value: "smv_sent",
    label: useLanguage.getState().language.workflows.nodes.events.smv_sent,
  },
  {
    value: "smv_got",
    label: useLanguage.getState().language.workflows.nodes.events.smv_received,
  },
  {
    value: "smv_not_got",
    label:
      useLanguage.getState().language.workflows.nodes.events.smv_not_received,
  },
  {
    value: "not_active",
    label: useLanguage.getState().language.workflows.nodes.events.not_active,
  },
  {
    value: "all_contacts",
    label:
      useLanguage.getState().language.workflows.nodes.events[
        exclude_answerers ? "all_contacts2" : "all_contacts"
      ],
  },
];

export const visitEvents = (exclude_answerers) => [
  {
    value: "linkedin_visited",
    label:
      useLanguage.getState().language.workflows.nodes.events.linkedin_visited,
  },
  {
    value: "all_contacts",
    label:
      useLanguage.getState().language.workflows.nodes.events[
        exclude_answerers ? "all_contacts2" : "all_contacts"
      ],
  },
];

export const callEvents = () => [];

export function getEvents(
  actionType,
  exclude_answerers,
  messagesAreOnlyChildren
) {
  if (actionType === "email") {
    return emailEvents(exclude_answerers);
  }
  if (actionType === "linkedin_visit") {
    return visitEvents(exclude_answerers);
  }
  if (actionType === "linkedin_invitation") {
    return linkedinIEvents(exclude_answerers, messagesAreOnlyChildren);
  }
  if (actionType === "linkedin_message") {
    return linkedinMEvents(exclude_answerers);
  }
  if (actionType === "sms") {
    return smsEvents(exclude_answerers);
  }
  if (actionType === "smv") {
    return smvEvents(exclude_answerers);
  }
  if (actionType === "call") {
    return callEvents(exclude_answerers);
  }
  return allEvents(exclude_answerers);
}

export function getDelay(when_minutes) {
  const lang = useLanguage.getState().language;
  if (!when_minutes) {
    return {
      type: "1",
      value: 1 + " " + lang.workflows.nodes.events.delay.day,
    };
  }
  let j = when_minutes % 1440;
  let jt = when_minutes / 1440;
  let h = when_minutes % 60;
  let ht = when_minutes / 60;

  if (j === 0) {
    return {
      type: "1",
      value:
        jt + " " + lang.workflows.nodes.events.delay.day + (jt > 1 ? "s" : ""),
    };
  }
  if (h === 0) {
    return {
      type: "2",
      value:
        ht + " " + lang.workflows.nodes.events.delay.hour + (ht > 1 ? "s" : ""),
    };
  }
  return {
    type: "3",
    value: when_minutes + " " + lang.workflows.nodes.events.delay.minute,
  };
}

export function getTimeOptions(event_type) {
  return [
    // {
    //   value: "4",
    //   label:
    //     useLanguage.getState().language.workflows.nodes.events.delay.immediate,
    // },
    {
      value: "3",
      label:
        useLanguage.getState().language.workflows.nodes.events.delay.minute,
      disabled: event_type === "not_active",
    },
    {
      value: "2",
      label: useLanguage.getState().language.workflows.nodes.events.delay.hour,
      disabled: event_type === "not_active",
    },
    {
      value: "1",
      label: useLanguage.getState().language.workflows.nodes.events.delay.day,
    },
  ];
}

const createArray = (length, label) =>
  Array.from({ length }, (el, i) => i + 1 + " " + label + (i > 0 ? "s" : ""));
const createArray0 = (length, label) =>
  Array.from({ length }, (el, i) => {
    if (i < 5) {
      return i + 1 + " " + label;
    }
    return (i - 4) * 10 + " " + label;
  });
export const dayOptions = () =>
  createArray(
    30,
    useLanguage.getState().language.workflows.nodes.events.delay.day
  );
export const hourOptions = () =>
  createArray(
    23,
    useLanguage.getState().language.workflows.nodes.events.delay.hour
  );
export const minuteOptions = () =>
  createArray0(
    10,
    useLanguage.getState().language.workflows.nodes.events.delay.minute
  );
