import { Button, Indicator, Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconLayoutColumns } from "@tabler/icons-react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import StatusContent from "./StatusContent";
import { useMemo } from "react";
import { useLeadsContainerStore } from "../../../../../stores/LeadsStore";

const StatusSettings = ({ disabled }) => {
  const lang = useLanguage((s) => s.language);
  const [opened, { open, close }] = useDisclosure();
  const containers = useLeadsContainerStore((s) => s.containers);

  const hiddenCount = useMemo(() => {
    return containers ? containers.filter((x) => !x.visible)?.length : 0;
  }, [containers]);
  return (
    <>
      <Indicator
        label={hiddenCount}
        offset={6}
        color="red"
        disabled={hiddenCount === 0}
        size={16}
      >
        <Button
          variant="light"
          radius={"sm"}
          leftSection={<IconLayoutColumns size={18} />}
          color="tertiary.9"
          onClick={open}
          disabled={disabled}
        >
          {lang.prm.status.button_label}
        </Button>
      </Indicator>

      <Modal
        opened={opened}
        onClose={close}
        title={lang.prm.status.dialog_title}
        size={"40%"}
      >
        <StatusContent handleClose={close} />
      </Modal>
    </>
  );
};

export default StatusSettings;
