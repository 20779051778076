import { Button, Group, Tooltip } from "@mantine/core";
import Status from "./Status";
import HeaderItem from "./HeaderItem";
import {
  IconCurrencyEuro,
  IconPercentage,
  IconReload,
  IconStar,
} from "@tabler/icons-react";
import { useLeadsSheetStore } from "../../../../../../../stores/LeadsStore";
import { useLanguage } from "../../../../../../../stores/LanguageStore";
import PersonInCharge from "../../../responsable/PersonInCharge";

const RightSheetHeader = () => {
  const lang = useLanguage((s) => s.language);
  const current = useLeadsSheetStore((s) => s.current);
  const setLoading = useLeadsSheetStore((s) => s.setLoading);
  return (
    <Group gap={"xs"}>
      <Status />

      <HeaderItem
        field={"amount"}
        icon={
          <Tooltip label={lang.prm.details.amount}>
            <IconCurrencyEuro size={16} />
          </Tooltip>
        }
        defaultValue={current?.amount}
        inputProps={{ min: 0 }}
        label={lang.prm.details.amount}
      />
      <HeaderItem
        field={"probability"}
        icon={
          <Tooltip label={lang.prm.details.probability}>
            <IconPercentage size={16} />
          </Tooltip>
        }
        defaultValue={current?.probability}
        inputProps={{ min: 0, max: 100 }}
        label={lang.prm.details.probability}
      />
      <HeaderItem
        field={"score"}
        icon={
          <Tooltip label={lang.prm.details.score}>
            <IconStar size={16} />
          </Tooltip>
        }
        label={lang.prm.details.score}
        defaultValue={current?.score}
        inputProps={{ min: 0, max: 100 }}
      />

      <HeaderItem
        field={"closing_date"}
        defaultValue={current?.closing_date}
        label={lang.prm.details.closing_date}
        isDate
      />

      <PersonInCharge current={current} inside />
      {/* <MoreActions inside current={current} /> */}
      <Button variant="subtle" px={"sm"} onClick={() => setLoading(true)}>
        <IconReload />
      </Button>
    </Group>
  );
};

export default RightSheetHeader;
