import { useMemo, useState } from "react";
import { Group } from "@mantine/core";
import { useModelsStore } from "../../stores/ConfigStore";
import Selector from "./Selector";
import {
  getLinkedinInvitations,
  getLinkedinMessages,
  getSMS,
} from "../../api/ModelsApi";
import ModelEditor from "../Adder/ModelEditor";
import { useLanguage } from "../../stores/LanguageStore";

function getOptionName(option) {
  return `${option.name} #${option.id}`;
}

const ModelLinkedinSelector = ({
  id,
  label,
  placeholder,
  size,
  required,
  value,
  onChange,
  leftSection,
  disabled,
  withEditor,
  type,
  maxChars,
  getError,
  onClick
}) => {
  const lang = useLanguage((s) => s.language);
  const models = useModelsStore((s) =>
    type === "sms" ? s.model_sms : s[`model_${type}s`]
  );
  const [loading, setLoading] = useState(false);
  const handleLoad = () => {
    setLoading(true);
    let func;
    if (type === "linkedin_invitation") func = getLinkedinInvitations;
    if (type === "linkedin_message") func = getLinkedinMessages;
    if (type === "sms") func = getSMS;
    func().finally(() => setLoading(false));
    onClick?.()
  };

  const options = useMemo(() => {
    let result = [{ label: "No Folder", options: [] }];
    models?.forEach((item) => {
      let place = result.find((x) => x.label === item?.folder?.name);
      if (place) {
        place.options.push({
          ...item,
          option_name: getOptionName(item),
        });
      } else {
        if (item?.folder) {
          result.push({
            label: item?.folder?.name,
            options: [
              {
                ...item,
                option_name: getOptionName(item),
              },
            ],
          });
        } else {
          result[0].options.push({
            ...item,
            option_name: getOptionName(item),
          });
        }
      }
    });
    return models?.length > 0
      ? result.sort((a) => a.label === "No Folder")
      : [];
  }, [models]);

  const onModelEdited = () => {
    onChange(null);
    setTimeout(() => {
      onChange(
        options.flatMap((x) => [...x.options]).find((x) => x.id === value)
      );
    }, 50);
  };

  return (
    <Group wrap="nowrap" w={"100%"} gap={"xs"} align="start">
      <Selector
        disabled={disabled}
        id={id}
        label={label}
        leftSection={leftSection}
        loading={loading}
        onChange={(val) => onChange(val)}
        onDropdownOpen={handleLoad}
        options={options}
        placeholder={
          placeholder ||
          lang.campaign.selectors.email.placeholder + (required ? " *" : "")
        }
        required={required}
        size={size}
        value={value}
        getOptionName={getOptionName}
        hasGroup
        getError={getError ? getError() : required ? !value : false}
      />
      {withEditor && value && (
        <ModelEditor
          model={options
            .flatMap((x) => [...x.options])
            .find((x) => x.id === value)}
          onModelEdited={onModelEdited}
          type={type}
          maxChars={maxChars}
        />
      )}
    </Group>
  );
};

export default ModelLinkedinSelector;
