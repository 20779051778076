import {
  Anchor,
  Group,
  HoverCard,
  Stack,
  Text,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { getNodeDetails, getTypeIcon } from "../../../workflows/CreateCampaign/flow/flowSettings";
import CampaignColumnChildCell from "./CampaignColumnChildCell";
import { IconCircleFilled, IconListDetails } from "@tabler/icons-react";
import EmailModalPreview from "../../../../../components/View/EmailModalPreview";
import MessageModalPreview from "../../../../../components/View/MessageModalPreview";
import { Link } from "react-router-dom";

function getText(type) {
  const lang = useLanguage.getState().language;
  if (type === "linkedin_invitation") {
    return lang.workflows.explorer.all_elements.linkedin_invitation;
  }
  if (type === "linkedin_visit") {
    return lang.workflows.explorer.all_elements.linkedin_visit;
  }
  if (type === "call") {
    return lang.workflows.explorer.all_elements.call;
  }
  if (type === "remove_prospect") {
    return lang.workflows.explorer.all_elements.remove_prospect;
  }
  return "-";
}

function handleModel(step) {
  const lang = useLanguage.getState().language;
  if (step.action_type === "email") {
    return (
      <EmailModalPreview
        opener={(show) => (
          <Tooltip
            label={`${lang.global.preview} : ${step?.model?.name} #${step?.model?.id}`}
          >
            <Anchor
              size={"sm"}
              truncate="end"
              flex={"1 1 auto"}
              c={"black"}
              fw={600}
              onClick={show}
            >
              {step?.model?.name || " - "}
            </Anchor>
          </Tooltip>
        )}
        model={step.model}
        noSign
        standalone
      />
    );
  } else {
    return (
      <MessageModalPreview
        opener={(show) => (
          <Tooltip
            label={`${lang.global.preview} : ${step?.model?.name} #${step?.model?.id}`}
          >
            <Anchor
              size={"sm"}
              truncate="end"
              flex={"1 1 auto"}
              c={"black"}
              fw={600}
              onClick={show}
            >
              {step?.model?.name || " - "}
            </Anchor>
          </Tooltip>
        )}
        model={step.model}
        standalone
        type={step.action_type}
      />
    );
  }
}

const ChildCell = ({ step }) => {
  return (
    <CampaignColumnChildCell>
      {getTypeIcon(step.action_type, false, 15, {
        size: "sm",
        opacity: 0.75,
        variant: "light",
        color: "black",
      })}
      {step?.model?.name ? (
        <Group w={"calc(100% - 32px)"} className="hover-source" wrap="nowrap">
          {handleModel(step)}
          {/* <Group>
            <IconEye size={18} />
          </Group> */}
        </Group>
      ) : (
        <Text
          size={"sm"}
          sx={{
            opacity: getText(step?.action_type) === "-" ? 1 : 0.5,
          }}
        >
          {getText(step?.action_type)}
        </Text>
      )}
    </CampaignColumnChildCell>
  );
};

const ListDisplay = ({ lists }) => {
  const lang = useLanguage((s) => s.language);
  return (
    <HoverCard openDelay={200} position="top" offset={2} withArrow>
      <HoverCard.Target>
        <ThemeIcon size={"xs"} variant="light" color="secondary" ml={"auto"}>
          <IconListDetails size={14} />
        </ThemeIcon>
      </HoverCard.Target>

      <HoverCard.Dropdown>
        <Stack>
          {lists.length > 0 ? (
            lists?.map((list) => (
              <Group key={list.contact_list_id} gap={"xs"}>
                <IconCircleFilled size={6} color="black" />
                <Anchor
                  size="sm"
                  target="_blank"
                  fw={600}
                  href={`/contact-lists/${list.contact_list_id}`}
                  c={"secondary"}
                >
                  {list.name} #{list.contact_list_id}
                </Anchor>
              </Group>
            ))
          ) : (
            <Text c={"dimmed"}>{lang.prm.nurturing.no_list}</Text>
          )}
        </Stack>
      </HoverCard.Dropdown>
    </HoverCard>
  );
};

const ParentCell = ({ value, row }) => {
  const lang = useLanguage((s) => s.language);
  return (
    <Group w="100%" wrap="nowrap" gap={"xs"}>
      {getTypeIcon(row?.initial?.action_type, true, 18, {
        size: "md",
        opacity: 0.75,
        variant: "light",
        color: getNodeDetails(row?.initial?.action_type)?.color || "secondary",
      })}
      <Tooltip
        label={`${lang.statistics.columns.name_model.view_workflow} : ${row?.workflow_name} #${row?.workflow_id}`}
      >
        <Anchor
          component={Link}
          to={`/campaign/edit/${row.id}?workflow=${row.workflow_id}`}
          truncate="end"
          c={getNodeDetails(row?.initial?.action_type)?.color || "secondary"}
        >
          {value + ` #${row.workflow_id}`}
        </Anchor>
      </Tooltip>
      <ListDisplay lists={row.contact_lists} />
    </Group>
  );
};

export function CampaignColumnName() {
  const lang = useLanguage.getState().language;
  return {
    field: "name",
    headerName: lang.statistics.columns.name_model.header_name,
    width: 200,
    renderCell: ({ row, value }) => {
      if (!row) {
        return;
      }
      if (row.isChild) {
        return (
          <Stack py={"xs"} w={"100%"}>
            {row.steps.map((step, index) => {
              return <ChildCell step={step} key={step.id} />;
            })}
          </Stack>
        );
      }

      return <ParentCell row={row} value={value} />;
    },
  };
}
