import { ActionIcon, Anchor, Button, Stack, Tooltip } from "@mantine/core";
import {
  IconCheckbox,
  IconDotsVertical,
  IconRefreshDot,
} from "@tabler/icons-react";
import PopOver from "../../../../../components/View/PopOver";
import { useState } from "react";
import { useProfileStore } from "../../../../../stores/UserStore";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { forceValidateMassMailing } from "../../../../../api/EmailAPI";

const EmailSendersActions = ({ current, inside = false, onFinish }) => {
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);
  const [loading, setLoading] = useState(false);

  const handleValidate = (closeMenu) => {
    setLoading(true);

    forceValidateMassMailing(current.id)
      .then((response) => {
        closeMenu();
        onFinish?.();
      })
      .finally(() => setLoading(false));
  };

  return (
    <PopOver
      opener={(handleOpen, ref) =>
        inside ? (
          <Button
            ref={ref}
            variant="light"
            color="primary"
            leftSection={<IconDotsVertical size={18} />}
            radius={"sm"}
            ml={"auto"}
            onClick={handleOpen}
          >
            {lang.table.more_actions}
          </Button>
        ) : (
          <Tooltip label={lang.table.more_actions}>
            <ActionIcon
              variant="subtle"
              ref={ref}
              color="primary"
              onClick={handleOpen}
            >
              <IconDotsVertical size={18} />
            </ActionIcon>
          </Tooltip>
        )
      }
      popoverChildren={(handleClose) => (
        <Stack gap={0}>
          {current?.account_type === "MassMailing" &&
            profile?.permissions?.find(
              (x) => x.name === "integrationsEmailForceValidate"
            )?.value === true && (
              <Button
                leftSection={<IconCheckbox size={18} />}
                fw={600}
                color="primary"
                variant="subtle"
                justify="start"
                onClick={() => handleValidate(handleClose)}
                loading={loading}
              >
                {lang.emails_account.force_validation}
              </Button>
            )}

          <Anchor target="_blank" href={current.auth_url}>
            <Button
              leftSection={<IconRefreshDot size={18} />}
              fw={600}
              color="primary"
              variant="subtle"
              justify="start"
              disabled={!current.auth_url}
              w={"100%"}
            >
              {lang.emails_account.reconnect}
            </Button>
          </Anchor>
        </Stack>
      )}
      anchorOrigin={
        inside
          ? { horizontal: "right", vertical: "bottom" }
          : { horizontal: "left", vertical: "center" }
      }
      transformOrigin={
        inside
          ? { horizontal: "right", vertical: "top" }
          : { horizontal: "right", vertical: "center" }
      }
      sx={{ zIndex: 100 }}
    />
  );
};

export default EmailSendersActions;
