import { Group, Stack } from "@mantine/core";
import ManualImport from "../../lists/import/manualImport/ManualImport";
import { useNavigate } from "react-router-dom";
import PageTitle from "../../../../components/View/PageTitle";
import { useLanguage } from "../../../../stores/LanguageStore";

const BlacklistImportPage = () => {
  const lang = useLanguage((s) => s.language);
  const navigate = useNavigate();
  return (
    <>
      <Group className="layout-block top" w={"100%"} justify="space-between">
        <PageTitle title={lang.blacklists.import.button_tooltip} />
      </Group>

      <Stack className="layout-block" pl={8} flex={1}>
        <ManualImport
          onFinish={(id) => navigate("../?highlight=" + id)}
          isBlacklist
        />
      </Stack>
    </>
  );
};

export default BlacklistImportPage;
