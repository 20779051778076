import { Button, Modal, Stack, Tooltip } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconUpload } from "@tabler/icons-react";
import WorkflowsListing from "./WorkflowsListing";
import { useLanguage } from "../../../../stores/LanguageStore";

const WorkflowListingModal = () => {
  const lang = useLanguage((s) => s.language);
  const [opened, { open, close }] = useDisclosure();
  return (
    <>
      <Tooltip label={lang.campaign.import.label_tooltip}>
        <Button
          size="xs"
          leftSection={<IconUpload size={18} />}
          variant="light"
          fw={400}
          onClick={open}
          radius={"sm"}
        >
          {lang.campaign.import.label}
        </Button>
      </Tooltip>

      {opened && (
        <Modal
          opened={opened}
          onClose={close}
          title={lang.campaign.import.title}
          size={"100%"}
        >
          <Stack h={"72vh"}>
            <WorkflowsListing closeModal={close} />
          </Stack>
        </Modal>
      )}
    </>
  );
};

export default WorkflowListingModal;
