import {
  IconAt,
  IconBell,
  IconCalendarClock,
  IconEyeCheck,
  IconMail,
  IconMessageCircle,
  IconMicrophone,
  IconSelectAll,
  IconUserMinus,
  IconUserPlus,
} from "@tabler/icons-react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { useThemeStore } from "../../../../../stores/ThemeStore";
import { ThemeIcon, Tooltip } from "@mantine/core";
import { IconGmail } from "../../../../../components/Icons/IconGmail";
import { IconOutlook } from "../../../../../components/Icons/IconOutlook";
import { IconLinkedin } from "../../../../../components/Icons/IconLinkedin";

export const nodeSpacingVertical = 48;
export const nodeSpacingHorizontal = 64;
export const nodeWidth = 256;
export const nodeEventHeight = 190;
export const nodeEmailHeight = 200;
export const nodeLinkedinMessageHeight = 200;
export const nodeHeight = 160;
export const nodeWidthPreview = 480;
export const nodeWidthLinkedinVisitPreview = 300;
export const nodeHeightPreview = 300;

export const maxSalesNavigatorChars = 280;
export const maxNormalChars = 180;

export function getNodeDetails(nodeType, iconProps = { size: 20 }) {
  const lang = useLanguage.getState().language;
  const actionColors = useThemeStore.getState().actionColors;
  let node = { icon: "", type: "", label: "", color: "", tip: "" };
  if (nodeType === "email" || nodeType === "Email") {
    node = {
      icon: <IconMail {...iconProps} />,
      type: "email",
      label: lang.workflows.explorer.all_elements.email,
      color: actionColors.email,
      tip: lang.workflows.explorer.all_elements.email_tooltip,
    };
  } else if (
    nodeType === "linkedin_invitation" ||
    nodeType === "LinkedinInvitation"
  ) {
    node = {
      icon: <IconUserPlus {...iconProps} />,
      type: "linkedin_invitation",
      label: lang.workflows.explorer.all_elements.linkedin_invitation,
      color: actionColors.linkedin_invitation,
      tip: lang.workflows.explorer.all_elements.linkedin_invitation_tooltip,
    };
  } else if (
    nodeType === "linkedin_message" ||
    nodeType === "LinkedinMessage"
  ) {
    node = {
      icon: <IconLinkedin {...iconProps} />,
      type: "linkedin_message",
      label: lang.workflows.explorer.all_elements.linkedin_message,
      color: actionColors.linkedin_message,
      tip: lang.workflows.explorer.all_elements.linkedin_message_tooltip,
    };
  } else if (nodeType === "linkedin_visit") {
    node = {
      icon: <IconEyeCheck {...iconProps} />,
      type: "linkedin_visit",
      label: lang.workflows.explorer.all_elements.linkedin_visit,
      color: actionColors.linkedin_message,
      tip: lang.workflows.explorer.all_elements.linkedin_visit_tooltip,
    };
  } else if (nodeType === "sms" || nodeType === "SMS") {
    node = {
      icon: <IconMessageCircle {...iconProps} />,
      type: "sms",
      label: lang.workflows.explorer.all_elements.sms,
      color: actionColors.sms,
      tip: lang.workflows.explorer.all_elements.sms_tooltip,
    };
  } else if (nodeType === "smv" || nodeType === "SMV") {
    node = {
      icon: <IconMicrophone {...iconProps} />,
      type: "smv",
      label: lang.workflows.explorer.all_elements.smv,
      color: actionColors.smv,
      tip: lang.workflows.explorer.all_elements.smv_tooltip,
    };
  } else if (nodeType === "event") {
    node = {
      icon: <IconCalendarClock {...iconProps} />,
      type: "event",
      label: lang.workflows.explorer.all_elements.event,
      color: actionColors.event,
      tip: lang.workflows.explorer.all_elements.event_tooltip,
    };
  } else if (nodeType === "call") {
    node = {
      icon: <IconBell {...iconProps} />,
      type: "call",
      label: lang.workflows.explorer.all_elements.call,
      sub_label: lang.workflows.explorer.all_elements.call_sub,
      color: actionColors.call,
      tip: lang.workflows.explorer.all_elements.call_tooltip,
    };
  } else if (nodeType === "remove_prospect") {
    node = {
      icon: <IconUserMinus {...iconProps} />,
      type: "remove_prospect",
      label: lang.workflows.explorer.all_elements.remove_prospect,
      color: actionColors.remove_prospect,
      tip: lang.workflows.explorer.all_elements.remove_prospect_tooltip,
    };
  }

  return node;
}

export const nodeActions = [
  "email",
  "linkedin_visit",
  "linkedin_invitation",
  "linkedin_message",
  "sms",
  // "smv",
];
export const nodeSecondaryActions = ["call", "remove_prospect"];

export function isMainAction(node) {
  if (nodeActions.includes(node?.data?.actionType)) {
    return true;
  }
  return false;
}

export const defaultNodeConfigs = {
  limit_send_per_day: 0,
  time_start_sending: "09:00:00",
  time_stop_sending: "20:00:00",
  blacklist_ids: [],
  email_linkedin_exclude: [],
  show_reply_as_conversation: true,
  sending_weekends_allowed: false,
  allow_massmailing: false,
  use_smtp_fallback_server: false,

  when_minutes: 1440,
};

export function getTypeIcon(
  type,
  is_initial,
  iconSize = 18,
  themeIconStyles = { opacity: 0.75 },
  disableTooltip
) {
  let lang = useLanguage.getState().language;
  if (type === "Linkedin") {
    return (
      <Tooltip disabled={disableTooltip} label={lang.workflows.types.Linkedin}>
        <ThemeIcon {...themeIconStyles}>
          <IconLinkedin size={iconSize} />
        </ThemeIcon>
      </Tooltip>
    );
  }
  if (type === "MassMailing") {
    return (
      <Tooltip
        disabled={disableTooltip}
        label={
          (is_initial ? lang.workflows.types.initial_action : "") +
          lang.workflows.types.MassMailing
        }
      >
        <ThemeIcon {...themeIconStyles}>
          <IconAt size={iconSize} />
        </ThemeIcon>
      </Tooltip>
    );
  }
  if (type === "SMTP") {
    return (
      <Tooltip
        disabled={disableTooltip}
        label={
          (is_initial ? lang.workflows.types.initial_action : "") +
          lang.workflows.types.SMTP
        }
      >
        <ThemeIcon {...themeIconStyles}>
          <IconMail size={iconSize} />
        </ThemeIcon>
      </Tooltip>
    );
  }
  if (type === "Outlook") {
    return (
      <Tooltip
        disabled={disableTooltip}
        label={
          (is_initial ? lang.workflows.types.initial_action : "") +
          lang.workflows.types.Outlook
        }
      >
        <ThemeIcon {...themeIconStyles}>
          <IconOutlook size={iconSize} />
        </ThemeIcon>
      </Tooltip>
    );
  }
  if (type === "Gmail") {
    return (
      <Tooltip
        disabled={disableTooltip}
        label={
          (is_initial ? lang.workflows.types.initial_action : "") +
          lang.workflows.types.Gmail
        }
      >
        <ThemeIcon {...themeIconStyles}>
          <IconGmail size={iconSize} />
        </ThemeIcon>
      </Tooltip>
    );
  }
  if (type === "Pool") {
    return (
      <Tooltip
        disabled={disableTooltip}
        label={
          (is_initial ? lang.workflows.types.initial_action : "") +
          lang.workflows.types.Pool
        }
      >
        <ThemeIcon {...themeIconStyles}>
          <IconSelectAll size={iconSize} />
        </ThemeIcon>
      </Tooltip>
    );
  }
  if (getNodeDetails(type)?.type) {
    return (
      <Tooltip
        disabled={disableTooltip}
        label={
          (is_initial ? lang.workflows.types.initial_action : "") +
          getNodeDetails(type).label
        }
      >
        <ThemeIcon {...themeIconStyles}>
          {getNodeDetails(type, { size: iconSize }).icon}
        </ThemeIcon>
      </Tooltip>
    );
  }
  return "";
}

export const defaultProgramData = {
  contactlist_ids: [],
  date_start: "",
  daily_send_limit: 0,
  allowed_monday: true,
  allowed_tuesday: true,
  allowed_wednesday: true,
  allowed_thursday: true,
  allowed_friday: true,
  allowed_saturday: false,
  allowed_sunday: false,
  priority_email: false,
  date_stop: "",
  stop_steps_at_end_date: false,
  time_start_sending: "08:00:00",
  time_stop_sending: "20:00:00",
  limit_total_sending: 0,
  copy_prospect_if_scoring_above: 0,
  ab_proportion: 0,
  ab_subject_2: "",
  ab_subject_3: "",
  blacklist_ids: [],
  exclude_programmation_ids: [],
};
