import { Group, Text, ThemeIcon, Tooltip } from "@mantine/core";
import { IconServer, IconServerCog, IconServerOff } from "@tabler/icons-react";
import { useLanguage } from "../../../stores/LanguageStore";

export const getStatusIcon = (status, preferEmpty) => {
  const lang = useLanguage.getState().language;
  if (status === "waiting_dns") {
    return (
      <Tooltip label={lang.resellers.status.waiting_dns}>
        <ThemeIcon color="orange" variant="light" size={"sm"}>
          <IconServerCog size={18} />
        </ThemeIcon>
      </Tooltip>
    );
  }
  if (status === "active" || status === "new") {
    return (
      <Tooltip label={lang.resellers.status.active}>
        <ThemeIcon color="tertiary.8" variant="light" size={"sm"}>
          <IconServer size={18} />
        </ThemeIcon>
      </Tooltip>
    );
  }
  if (status === "dns_failure") {
    return (
      <Tooltip label={lang.resellers.status.dns_failure}>
        <ThemeIcon color="red" variant="light" size={"sm"}>
          <IconServerOff size={18} />
        </ThemeIcon>
      </Tooltip>
    );
  }
  if (status === "invalid") {
    return (
      <Tooltip label={lang.resellers.status.invalid}>
        <ThemeIcon color="red" variant="light" size={"sm"}>
          <IconServerCog size={18} />
        </ThemeIcon>
      </Tooltip>
    );
  }
  return preferEmpty ? (
    "-"
  ) : (
    <Tooltip label={lang.resellers.status.dns_failure}>
      <ThemeIcon color="red" variant="light" size={"sm"}>
        <IconServerOff size={18} />
      </ThemeIcon>
    </Tooltip>
  );
};

const DomainsCell = ({ value, status, preferEmpty }) => {
  return (
    <Group w={"100%"} wrap="nowrap" gap={"xs"}>
      {getStatusIcon(status, preferEmpty)}
      <Text size="sm" truncate="end">
        {value}
      </Text>
    </Group>
  );
};

export default DomainsCell;
