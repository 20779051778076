import { Button } from "@mantine/core";
import { useLanguage } from "../../../../stores/LanguageStore";
import { Link } from "react-router-dom";
import { IconArrowBarToUp } from "@tabler/icons-react";

const UnsubsImportButton = () => {
  const lang = useLanguage((s) => s.language);
  const route = `/exclusions/unsubscribers/import`;

  return (
    <Button
      leftSection={<IconArrowBarToUp size={18} />}
      radius={"sm"}
            color="primary"
      component={Link}
      to={route}
    >
      {lang.unsubscribers.import.button_tooltip}
    </Button>
  );
};

export default UnsubsImportButton;
