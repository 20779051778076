import { useState } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useDisclosure } from "@mantine/hooks";
import { Button, Group, Modal, NumberInput, Stack, Text } from "@mantine/core";
import BackButton from "../../../../components/View/BackButton";
import { IconDivide } from "@tabler/icons-react";
import { splitList } from "../../../../api/ContactAPI";
import { notifications } from "@mantine/notifications";

const ListSplit = ({ current, onFinish, closeMenu }) => {
  const lang = useLanguage((s) => s.language);
  const [opened, { open, close }] = useDisclosure();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    let fd = new FormData(e.target);
    let data = Object.fromEntries(fd);

    setLoading(true);
    splitList({
      contact_list_id: current.id,
      number: parseInt(data.number),
    })
      .then((res) => {
        notifications.show({
          message: lang.contact_lists.split.success,
          color: "tertiary.8",
        });
        close();
        onFinish?.();
      })
      .finally(() => setLoading(false));
  };

  const handleClose = () => {
    close();
    closeMenu?.();
  };

  return (
    <>
      <Button
        onClick={open}
        leftSection={<IconDivide size={18} />}
        fw={600}
        color="primary"
        variant="subtle"
        justify="start"
      >
        {lang.contact_lists.split.label}
      </Button>

      {opened && (
        <Modal
          opened={opened}
          size={"md"}
          onClose={handleClose}
          title={
            <>
              {lang.contact_lists.split.dialog.title}{" "}
              <b>
                {current?.name} #{current.id}
              </b>
            </>
          }
        >
          <Stack component={"form"} onSubmit={handleSubmit} gap={"xs"}>
            <Text c={"dimmed"}>{lang.contact_lists.split.dialog.text}</Text>

            <NumberInput
              autoFocus
              name="number"
              label={lang.contact_lists.split.dialog.number}
              defaultValue={2}
              min={2}
              max={10}
              required
            />

            <Group justify="end" mt={"md"}>
              <BackButton onClick={handleClose} />

              <Button
                leftSection={<IconDivide size={18} />}
                loading={loading}
                color="primary"
                type="submit"
              >
                {lang.contact_lists.split.dialog.submit_button}
              </Button>
            </Group>
          </Stack>
        </Modal>
      )}
    </>
  );
};

export default ListSplit;
