import { useCallback, useMemo, useState } from "react";
import { useLanguage } from "../../../stores/LanguageStore";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { useListingLoader } from "../../../components/hooks/ListingHooks";
import SelectionDeleter from "../../../components/TableComponents/SelectionDeleter";
import { deleteReseller, getProfile } from "../../../api/UserAPI";
import ItemDeleter from "../../../components/TableComponents/ItemDeleter";
import {
  ActionIcon,
  Anchor,
  Center,
  Group,
  Loader,
  NumberFormatter,
  Tooltip,
} from "@mantine/core";
import dateColumn from "../../../layouts/Table/columns/dateColumn";
import numberColumn from "../../../layouts/Table/columns/numberColumn";
import { nameColumn } from "../../../layouts/Table/columns/nameColumn";
import DomainsCell from "./DomainsCell";
import MainTable from "../../../layouts/Table/MainTable";
import TableLayoutBlock from "../../../layouts/Table/TableLayoutBlock";
import CreateResellerButton from "./CreateResellerButton";
import { IconEdit, IconExternalLink } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import ResellerOnboarding from "./onboarding/ResellerOnboarding";
import { useProfileStore } from "../../../stores/UserStore";
import { getResellers } from "../../../api/AdminAPI";
import PageTitle from "../../../components/View/PageTitle";
import ResellerExtension from "./howto/ResellerExtension";

const ResellersListing = () => {
  const apiRef = useGridApiRef();
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);

  const columns = useMemo(() => {
    let v3Only = !rows.find((x) => x.domain_saas);
    let result = [
      numberColumn(),

      nameColumn({
        parentPath: (row) => `/resellers/white-labels/${row.id}/users`,
        withoutId: true,
        rightSection: (row) => (
          <Group gap={4} wrap="nowrap">
            <Anchor
              target={"_blank"}
              href={`https://${row?.domain_saas2 || row.domain_saas}`}
            >
              <ActionIcon variant="subtle">
                <IconExternalLink size={17} />
              </ActionIcon>
            </Anchor>

            <ResellerExtension id={row.id} inTable name={row.name} />
          </Group>
        ),
      }),
      {
        field: "number_of_users",
        headerName: lang.resellers.columns.number_of_users,
        width: 150,
        type: "number",
        align: "left",
        headerAlign: "left",
        renderCell: ({ value, row }) => (
          <>
            {value ? (
              <NumberFormatter
                value={value}
                thousandSeparator=" "
                style={{ marginRight: 12, fontWeight: 600 }}
              />
            ) : (
              <span style={{ marginRight: 12, fontWeight: 600 }}>1</span>
            )}
            (
            <NumberFormatter
              value={row?.number_of_users_activated || 0}
              thousandSeparator=" "
              style={{ marginLeft: 2 }}
            />{" "}
            <span style={{ marginLeft: 4, marginRight: 2 }}>
              {lang.resellers.columns.active}
            </span>
            )
          </>
        ),
      },
      {
        field: "user_level",
        headerName: lang.resellers.columns.user_level,
        width: 120,
        valueGetter: ({ value }) => {
          if (value === "user") {
            return lang.resellers.columns.user_level_value_user;
          }
          if (value === "admin") {
            return lang.resellers.columns.user_level_value_admin;
          }
          if (value === "super_admin") {
            return lang.resellers.columns.user_level_value_super_admin;
          }
        },
      },
      {
        field: "domain_saas",
        headerName: lang.resellers.columns.domain_saas,
        width: 180,
        renderCell: ({ row, value }) => (
          <DomainsCell status={row.domain_saas_status} value={value} />
        ),
      },
      {
        field: "domain_saas2",
        headerName: lang.resellers.columns.domain_saas + (v3Only ? "" : " 2"),
        width: 180,
        renderCell: ({ row, value }) => (
          <DomainsCell status={row.domain_saas2_status} value={value} />
        ),
      },
      {
        field: "domain_files",
        headerName: lang.resellers.columns.domain_files,
        width: 180,
        renderCell: ({ row, value }) => (
          <DomainsCell status={row.domain_files_status} value={value} />
        ),
      },
      {
        field: "domain_redirect",
        headerName: lang.resellers.columns.domain_redirect,
        width: 180,
        renderCell: ({ row, value }) => (
          <DomainsCell status={row.domain_redirect_status} value={value} />
        ),
      },
      {
        field: "domain_api",
        headerName: lang.resellers.columns.domain_api,
        width: 180,
        renderCell: ({ row, value }) => (
          <DomainsCell status={row.domain_api_status} value={value} />
        ),
      },
      dateColumn(),
    ];

    if (v3Only) {
      result = result.filter((x) => x.field !== "domain_saas");
    }

    return result;
  }, [lang, rows]);

  const actionsCell = useCallback(
    ({ row }) => {
      return (
        <Group wrap="nowrap" gap={4}>
          <Tooltip label={lang.global.edit}>
            <ActionIcon
              component={Link}
              to={"/resellers/white-labels/" + row.id}
              variant="subtle"
            >
              <IconEdit size={18} />
            </ActionIcon>
          </Tooltip>
          <ResellerExtension inTable id={row.id} name={row.name} />
          <ItemDeleter
            title_bold={`${row.name} #${row.id}`}
            handleDelete={deleteReseller}
            deletedMessage={lang.resellers.delete_success}
            id={row.id}
            apiRef={apiRef}
          />
        </Group>
      );
    },
    [apiRef, lang.global.edit, lang.resellers.delete_success]
  );

  const toolbarActions = useCallback(
    ({ selectedIds }) => (
      <SelectionDeleter
        handleDelete={deleteReseller}
        apiRef={apiRef}
        setTableLoading={setLoading}
        ids={selectedIds}
      />
    ),
    [apiRef]
  );

  useListingLoader({
    loading,
    setLoading,
    listGetter: profile.level === "admin" ? getResellers : getProfile,
    responseField: profile.level === "admin" ? "domains_list" : "user_profile",
    setRows,
    getRows: (data) => {
      if (profile.level === "admin") {
        let final = [];
        data.forEach((element) => {
          let same_ids = data.filter(
            (x) => x.reseller_id === element.reseller_id
          );
          let add = {
            id: element.reseller_id,
            name: element.name,
            domain_saas: same_ids.find((x) => x.type === "saas")?.domain,
            domain_saas2: same_ids.find((x) => x.type === "saas2")?.domain,
            domain_saas_status: same_ids.find((x) => x.type === "saas")?.status,
            domain_saas2_status: same_ids.find((x) => x.type === "saas2")
              ?.status,
            domain_saas_status_date: same_ids.find((x) => x.type === "saas")
              ?.domain_saas_status_date,

            domain_files: same_ids.find((x) => x.type === "files")?.domain,
            domain_files_status: same_ids.find((x) => x.type === "files")
              ?.status,
            domain_files_status_date: same_ids.find((x) => x.type === "files")
              ?.domain_saas_status_date,

            domain_redirect: same_ids.find((x) => x.type === "redirect")
              ?.domain,
            domain_redirect_status: same_ids.find((x) => x.type === "redirect")
              ?.status,
            domain_redirect_status_date: same_ids.find(
              (x) => x.type === "redirect"
            )?.domain_saas_status_date,

            domain_api: same_ids.find((x) => x.type === "api")?.domain,
            domain_api_status: same_ids.find((x) => x.type === "api")?.status,
            domain_api_status_date: same_ids.find((x) => x.type === "api")
              ?.domain_saas_status_date,
            number_of_users: element.number_of_users,
            number_of_users_activated: element.number_of_users_activated,
          };

          if (!final.find((x) => x.id === element.reseller_id)) final.push(add);
        });

        return final;
      } else {
        return data.resellers;
      }
    },
  });

  return (
    <>
      <Group
        className="layout-block top"
        w={"100%"}
        gap={0}
        justify="space-between"
      >
        <PageTitle title={lang.resellers.browser_tab_title_list} />

        {rows.length > 0 && <CreateResellerButton />}
      </Group>

      {loading && rows.length === 0 ? (
        <Center flex={"1 1 auto"}>
          <Loader type="dots" size={64} />
        </Center>
      ) : rows?.length === 0 ? (
        <ResellerOnboarding setTableLoading={setLoading} />
      ) : (
        <TableLayoutBlock>
          <MainTable
            apiRef={apiRef}
            columns={columns}
            rows={rows}
            loading={loading}
            setLoading={setLoading}
            id={"list-table"}
            toolbarActions={toolbarActions}
            actionsCell={actionsCell}
            pageOrderName={"resellers_order"}
          />
        </TableLayoutBlock>
      )}
    </>
  );
};

export default ResellersListing;
