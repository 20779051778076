import CodeMirror from "@uiw/react-codemirror";
import { keymap } from "@codemirror/view";
import { insertTab } from "@codemirror/commands";
import { Box, lighten } from "@mui/material";

const CodeEditor = ({ text, setText, placeholder }) => {
  const onChange = (value, viewUpdate) => {
    setText(value);
  };

  const indentWithTab = {
    key: "Tab",

    run: insertTab,
  };

  return (
    <Box
      sx={{
        "& .cm-focused .cm-selectionBackground": {
          backgroundColor: (theme) => theme.palette.primary.main + "66",
        },
        "& *::selection": {
          backgroundColor: (theme) => theme.palette.primary.main + "66",
        },
        "& .cm-activeLine": {
          backgroundColor: (theme) =>
            lighten(theme.palette.primary.main + "33", 0.5),
        },
        "& .cm-activeLineGutter": {
          backgroundColor: (theme) =>
            lighten(theme.palette.primary.main + "33", 0.5),
        },
        "& .code-editor": {
          height: "100%"
        },
        border: "1px solid #00000018",
        mt: "-1px"
      }}
      flex={"1 1 auto"}
      height={0}
      minHeight={200}
      width={0}
      minWidth={"100%"}
    >
      <CodeMirror
        value={text}
        height="100%"
        onChange={onChange}
        className={"code-editor"}
        indentWithTab={false}
        autoFocus
        placeholder={placeholder}
        extensions={[keymap.of(indentWithTab)]}
      />
    </Box>
  );
};
export default CodeEditor;
