import { SegmentedControl, Stack } from "@mantine/core";
import { useCallback, useMemo, useState } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import Permissions from "./Permissions";
import UserLimits from "./UserLimits";
import CommunityManager from "./CommunityManager/CommunityManager";
import Interface from "./Interface";

const AdminPanelRight = ({ profile, setProfile, changingProfile }) => {
  const lang = useLanguage((s) => s.language);
  const [tab, setTab] = useState("permissions");

  const tabs = useMemo(() => {
    return [
      {
        label: lang.administration.right.tabs_title.rights,
        value: "permissions",
      },
      {
        label: lang.administration.right.tabs_title.limits,
        value: "limits",
      },
      {
        label: lang.administration.right.tabs_title.community,
        value: "community",
      },
      {
        label: lang.administration.right.tabs_title.interface,
        value: "interface",
      },
    ];
  }, [
    lang.administration.right.tabs_title.rights,
    lang.administration.right.tabs_title.limits,
    lang.administration.right.tabs_title.community,
    lang.administration.right.tabs_title.interface,
  ]);

  const getComponent = useCallback(() => {
    if (tab === "permissions") {
      return <Permissions profile={profile} setProfile={setProfile} />;
    }
    if (tab === "limits") {
      return <UserLimits profile={profile} setProfile={setProfile} />;
    }
    if (tab === "community") {
      return (
        <CommunityManager
          profile={profile}
          setProfile={setProfile}
          changingProfile={changingProfile}
        />
      );
    }
    if (tab === "interface") {
      return <Interface profile={profile} setProfile={setProfile} />;
    }
  }, [changingProfile, profile, setProfile, tab]);

  return (
    <Stack
      h={"100%"}
      w={"100%"}
      style={{ flexShrink: 100, borderLeft: "1px solid #00000016" }}
      bg={"var(--mantine-color-gray-0)"}
      p={"md"}
      py={"xs"}
    >
      <SegmentedControl
        value={tab}
        onChange={setTab}
        size="sm"
        data={tabs}
        radius={"sm"}
        color="primary.1"
        styles={{
          label: {
            color: "var(--mantine-color-primary-6)",
            fontWeight: 600,
          },
        }}
      />
      {getComponent()}
    </Stack>
  );
};

export default AdminPanelRight;
