import { useCallback, useMemo, useState } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import numberColumn from "../../../../layouts/Table/columns/numberColumn";
import { nameColumn } from "../../../../layouts/Table/columns/nameColumn";
import {
  copySignature,
  deleteSignature,
  editSignature,
  getSignatures,
} from "../../../../api/ConfigsApi";
import { sharingColumn } from "../../../../layouts/Table/columns/sharingColumn";
import dateColumn from "../../../../layouts/Table/columns/dateColumn";
import { IconEdit, IconPlus, IconTagStarred } from "@tabler/icons-react";
import { ActionIcon, Button, Group, Text, Tooltip } from "@mantine/core";
import { Link } from "react-router-dom";
import ItemDeleter from "../../../../components/TableComponents/ItemDeleter";
import SelectionDeleter from "../../../../components/TableComponents/SelectionDeleter";
import { useListingLoader } from "../../../../components/hooks/ListingHooks";
import MainTable from "../../../../layouts/Table/MainTable";
import TableLayoutBlock from "../../../../layouts/Table/TableLayoutBlock";
import CreateSignatureButton from "./CreateSignatureButton";
import PageTitle from "../../../../components/View/PageTitle";
import DuplicateRessource from "../../../../components/View/DuplicateRessource";

const EmailSignatures = () => {
  const lang = useLanguage((s) => s.language);
  const apiRef = useGridApiRef();
  const columns = useMemo(
    () => [
      numberColumn(),
      nameColumn({
        parentPath: (row) => `/models/email-signatures/${row.id}/edit`,
        editFunction: () => editSignature,
        apiRef,
        withoutId: true,
      }),
      {
        field: "is_template",
        headerName: lang.model_emails.columns.is_template,
        width: 100,
        id: "is_template",
        renderCell: ({ value, row }) => {
          if (value) {
            return <Text fw={500}>{lang.model_emails.columns.template}</Text>;
          }
          return (
            <Group gap={"xs"}>
              <Text fw={500}>{lang.model_emails.columns.not_template}</Text>

              {row.is_generated && (
                <Tooltip label={row.prompt}>
                  <IconTagStarred style={{ marginLeft: 4 }} />
                </Tooltip>
              )}
            </Group>
          );
        },
      },
      sharingColumn({ apiRef, editFunction: () => editSignature }),
      dateColumn(),
    ],
    [lang, apiRef]
  );
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);

  const toolbarActions = useCallback(
    ({ selectedIds }) => (
      <SelectionDeleter
        handleDelete={deleteSignature}
        apiRef={apiRef}
        setTableLoading={setLoading}
        ids={selectedIds}
      />
    ),

    [apiRef]
  );

  const actionsCell = useCallback(
    ({ row, value }) => {
      return (
        <Group wrap="nowrap" gap={4}>
          <ActionIcon component={Link} to={`${row.id}/edit`} variant="subtle">
            <IconEdit size={18} />
          </ActionIcon>

          <DuplicateRessource
            current={row}
            onFinish={() => setLoading(true)}
            closeMenu={() => {}}
            handler={copySignature}
            responseField={"email_signature_id"}
            isIcon
          />

          <ItemDeleter
            title_bold={`${row.name} #${row.id}`}
            handleDelete={deleteSignature}
            deletedMessage={lang.emails_signature.delete_success}
            id={row.id}
            disabled={!row?.delete_permission}
            apiRef={apiRef}
          />
        </Group>
      );
    },
    [apiRef, lang.emails_signature.delete_success]
  );

  useListingLoader({
    loading,
    setLoading,
    listGetter: getSignatures,
    responseField: "email_signatures_list",
    setRows,
    folderRouteId: "list-folder",
  });

  return (
    <>
      <Group className="layout-block top" w={"100%"} justify="space-between">
        <PageTitle title={lang.emails_signature.browser_tab_title} />

        <CreateSignatureButton />
      </Group>

      <TableLayoutBlock>
        <MainTable
          apiRef={apiRef}
          columns={columns}
          rows={rows}
          loading={loading}
          setLoading={setLoading}
          id={"list-table"}
          toolbarActions={toolbarActions}
          actionsCell={actionsCell}
          pageOrderName={"email_signatures_order"}
          noRowsDisplay={
            <Button
              component={Link}
              to={`create`}
              leftSection={<IconPlus size={32} />}
              radius={"sm"}
              size="xl"
              variant="light"
              color="tertiary.8"
            >
              {lang.emails_signature.create.button_tooltip}
            </Button>
          }
        />
      </TableLayoutBlock>
    </>
  );
};

export default EmailSignatures;
