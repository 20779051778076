import { NumberFormatter } from "@mantine/core";

const numberColumn = (field = "id", headerName = "ID", width = 75) => {
  return {
    field: field,
    headerName: headerName,
    width: width,
    type: "number",
    align: "left",
    headerAlign: "left",
    renderCell: ({ value }) => (
      <NumberFormatter value={value} thousandSeparator=" " />
    ),
  };
};

export default numberColumn;
