import { ActionIcon, Tooltip } from "@mantine/core";
import { useLanguage } from "../../stores/LanguageStore";
import { IconCodePlus } from "@tabler/icons-react";

const FilterGroupAdder = ({ disabled, onClick, color }) => {
  const lang = useLanguage((s) => s.language);
  return (
    <Tooltip
      label={lang.table.toolbar.filter.conditions.add_condition_group_tooltip}
    >
      <ActionIcon
        size="sm"
        variant="outline"
        onClick={onClick}
        disabled={disabled}
        color={color}
      >
        <IconCodePlus size={16} />
      </ActionIcon>
    </Tooltip>
  );
};

export default FilterGroupAdder;
