import { Group, Stack, Title } from "@mantine/core";
import Path from "../../../layouts/Path";
import AdminPanel from "./AdminPanel";
import { useLoaderData } from "react-router-dom";
import { useLanguage } from "../../../stores/LanguageStore";
import PageTitle from "../../../components/View/PageTitle";

const AdminPage = () => {
  const lang = useLanguage((s) => s.language);
  const defaultUser = useLoaderData();

  return (
    <>
      <Group
        className="layout-block top"
        w={"100%"}
        gap={0}
        justify="space-between"
      >
        <PageTitle title={lang.administration.browser_tab_title} />
      </Group>

      <Stack className="layout-block" flex={"1 1 auto"} p={0}>
        <AdminPanel defaultUser={defaultUser} />
      </Stack>
    </>
  );
};

export default AdminPage;
