import { useLanguage } from "../../../../../stores/LanguageStore";
import { Group, Paper, Text, Tooltip } from "@mantine/core";
import { types } from "./jobTypes";
import { parseDate } from "../../../../../functions/dates";
import { Link } from "react-router-dom";
import { handleJobItem } from "./JobItemArguments";
import { useListStore } from "../../../../../stores/ListStore";
import { useMemo } from "react";

const JobItemFailed = ({ item }) => {
  const lang = useLanguage((s) => s.language);
  const startDate = item.start_date
    ? new Date(Date.parse(item.start_date))
    : null;
  const endDate = item?.end_date ? new Date(Date.parse(item.end_date)) : null;
  const queuedDate = item?.queued_date
    ? new Date(Date.parse(item.queued_date))
    : null;

  const getText = () => {
    let m = item.error.toString();
    if (m === `{"state":false,"state_message":"unexpected_exception"}`) {
      return lang.contact_lists.jobs.error.unknown_error;
    }
    if (m === `Length of search URL too long`) {
      return lang.contact_lists.jobs.error.too_long_criteria;
    }
    if (m === "No more emails generated available for this month") {
      return (
        <Text>
          {lang.errors.main.no_more_enrich}{" "}
          <Link
            style={{ marginLeft: 6, fontWeight: 600 }}
            to={"/account-settings/subscription"}
          >
            {lang.global.here}
          </Link>{" "}
        </Text>
      );
    }

    if (m.includes("errors")) {
      let errors = JSON.parse(item.error)?.errors;
      if (errors)
        return (
          <ul>
            {errors.map((x, i) => (
              <li key={i} style={{ listStyle: "none", fontStyle: "normal" }}>
                <b>line {x.line}</b> : {x.error}
              </li>
            ))}
          </ul>
        );
    }
    return item?.error;
  };

  const lists = useListStore((s) => s.lists);
  const fromLists = useMemo(() => {
    if (item.type === "Merge") {
      return item.arguments.contactListIds.map((id) => {
        return lists?.find((x) => x.id === id) || { id };
      });
    }
    if (item.type === "Split") {
      return (
        lists?.find((x) => x.id === item.arguments.fromContactListId) || {
          id: item.arguments.fromContactListId,
        }
      );
    }
    return null;
  }, [lists, item]);

  return (
    <Paper withBorder p={"sm"} bg={"#00000006"}>
      <Group>
        <Group direction="row" gap={4} mr={1}>
          {types(item.type)?.icon}

          <Text fw={500} fz={14}>
            {types(item.type, false, item?.arguments?.generateEmail)?.label}
          </Text>
        </Group>
        <Text fz={10} style={{ opacity: 0.75 }}>
          {queuedDate && (
            <>
              <b>{lang.contact_lists.jobs.queued_date}</b>{" "}
              <Tooltip label={queuedDate.toLocaleString()}>
                <span style={{ marginRight: 8 }}>{parseDate(queuedDate)}</span>
              </Tooltip>
            </>
          )}
          <b>{lang.contact_lists.jobs.start_date}</b>{" "}
          <Tooltip label={startDate?.toLocaleString()}>
            <span style={{ marginRight: 8 }}>
              {startDate ? parseDate(startDate) : " - "}
            </span>
          </Tooltip>
          {endDate && (
            <>
              <b>{lang.contact_lists.jobs.end_date}</b>{" "}
              <Tooltip label={endDate.toLocaleString()}>
                <span>{parseDate(endDate)}</span>
              </Tooltip>
            </>
          )}{" "}
        </Text>
      </Group>
      <Text size="sm" c={"#c00"} fs={"italic"} mt={6}>
        {getText()}
      </Text>
      {handleJobItem({ item: item, fromLists, isProgress: true })}
    </Paper>
  );
};

export default JobItemFailed;
