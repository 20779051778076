import { useState } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useDisclosure } from "@mantine/hooks";
import {
  ActionIcon,
  Button,
  Group,
  Modal,
  Select,
  Stack,
  Text,
  Textarea,
  Tooltip,
} from "@mantine/core";
import BackButton from "../../../../components/View/BackButton";
import { IconMessageCircleBolt } from "@tabler/icons-react";
import { useProfileStore } from "../../../../stores/UserStore";
import { generateIcebreaker } from "../../../../api/ContactAPI";
import { notifications } from "@mantine/notifications";
import GlobalLanguageSelector from "../../../../components/Selectors/GlobalLanguageSelector";
import TemperatureSlider from "../../../../components/View/TemperatureSlider";

const IceBreaker = ({
  current,
  onFinish,
  inMenu = false,
  closeMenu,
  hidden,
}) => {
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);
  const [opened, { open, close }] = useDisclosure();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    let fd = new FormData(e.target);
    let data = Object.fromEntries(fd);

    setLoading(true);
    generateIcebreaker({
      contact_list_id: current.id,
      subject: data.subject,
      language: data.language,
      temperature: parseFloat(data.temperature),
      version:
        data?.version ||
        (profile.permissions?.find((x) => x.name === "iceBreakerV35")?.value ===
        true
          ? "3.5"
          : "4.0"),
    })
      .then((res) => {
        notifications.show({
          message: lang.contact_lists.icebreaker.success,
          color: "tertiary.8",
        });
        close();
        onFinish?.();
      })
      .finally(() => setLoading(false));
  };

  const handleClose = () => {
    close();
    closeMenu?.();
  };

  if (
    !(
      profile.permissions?.find((x) => x.name === "generateIceBreaker")
        ?.value === true &&
      (profile.permissions?.find((x) => x.name === "iceBreakerV40")?.value ===
        true ||
        profile.permissions?.find((x) => x.name === "iceBreakerV35")?.value ===
          true)
    )
  ) {
    return "";
  }

  return (
    <>
      {inMenu ? (
        <Button
          id="icebreaker-button"
          onClick={open}
          leftSection={<IconMessageCircleBolt size={18} />}
          fw={600}
          color="primary"
          variant="subtle"
          justify="start"
          display={hidden ? "none" : "flex"}
        >
          {lang.contact_lists.icebreaker.label}
        </Button>
      ) : (
        <Tooltip label={lang.contact_lists.icebreaker.label}>
          <ActionIcon variant="subtle" color="tertiary.8" onClick={open}>
            <IconMessageCircleBolt size={18} />
          </ActionIcon>
        </Tooltip>
      )}

      {opened && (
        <Modal
          opened={opened}
          size={"md"}
          onClose={handleClose}
          title={
            <>
              {lang.contact_lists.icebreaker.dialog.title}{" "}
              <b>
                {current?.name} #{current.id}
              </b>
            </>
          }
          styles={{
            title: {
              lineHeight: 1.5,
            },
          }}
        >
          <Stack component={"form"} onSubmit={handleSubmit} gap={"xs"}>
            <Text c={"dimmed"}>
              {lang.contact_lists.icebreaker.dialog.text}
            </Text>

            <Textarea
              label={lang.contact_lists.icebreaker.dialog.subject}
              placeholder={lang.contact_lists.icebreaker.dialog.subject}
              name="subject"
              rows={4}
              maxLength={500}
              required
              autoFocus
              data-autoFocus
            />

            <GlobalLanguageSelector name={"language"} w="100%" size="sm" />

            {profile.permissions?.find((x) => x.name === "iceBreakerV35")
              ?.value === true &&
              profile.permissions?.find((x) => x.name === "iceBreakerV40")
                ?.value === true && (
                <Select
                  select
                  label="Version"
                  defaultValue={
                    profile.permissions?.find((x) => x.name === "iceBreakerV40")
                      ? "4.0"
                      : "3.5"
                  }
                  w={"100%"}
                  name="version"
                  data={[
                    {
                      label: "Version 3.5",
                      value: "3.5",
                    },
                    {
                      label: "Version 4.0",
                      value: "4.0",
                    },
                  ]}
                  required
                />
              )}

            <TemperatureSlider />

            <Group justify="end" mt={"md"}>
              <BackButton onClick={handleClose} />

              <Button
                leftSection={<IconMessageCircleBolt size={18} />}
                loading={loading}
                color="primary"
                type="submit"
              >
                {lang.contact_lists.icebreaker.dialog.submit_button}
              </Button>
            </Group>
          </Stack>
        </Modal>
      )}
    </>
  );
};

export default IceBreaker;
