import { useCallback, useEffect, useState } from "react";
import { useProfileStore, useSessionStore } from "../../stores/UserStore";
import { getFirstPageUsers, searchUser } from "../../api/UserAPI";
import {
  ActionIcon,
  CloseButton,
  Combobox,
  Highlight,
  ScrollArea,
  TextInput,
  useCombobox,
} from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { IconSelector, IconX } from "@tabler/icons-react";

const UserSelector = ({
  isFooter,
  onChange,
  value,
  leftSection,
  placeholder,
  label,
  disabled,
  id,
  withSelf,
  withUnswitchedProfile,
  clearable,
  ...props
}) => {
  const [searching, setSearching] = useState(false);
  // const allUsers = useSessionStore((s) => s.usersOptions);
  const [userOptions, setUserOptions] = useState([]);
  const [users, setUsers] = useState(userOptions);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(value?.name || "");
  const profile = useProfileStore((s) => s.profile);
  const unswitchedProfile = useProfileStore((s) => s.unswitchedProfile);
  const [debounced] = useDebouncedValue(search, 500);

  const handleLoad = useCallback(() => {
    setLoading(true);
    const isSwitched = useSessionStore.getState()?.user_switch;
    let o = useSessionStore.getState().usersOptions;

    if (isFooter && isSwitched) {
      let newOptions = o.map((user) => ({
        ...user,
        id: user.id,
        name: user.first_name + " " + user.last_name,
      }));

      setUserOptions(newOptions);
      setUsers(newOptions);
      return setLoading(false);
    }

    getFirstPageUsers({
      isMain: isFooter,
      sort: {
        field_name: "id",
        sort_direction: "asc",
      },
    })
      .then((res) => {
        if (res.data.state) {
          let newOptions = res.data.results;

          if (withSelf) {
            newOptions.push(profile);
          }
          if (withUnswitchedProfile && isSwitched) {
            newOptions.push(unswitchedProfile);
          }

          const result = newOptions.map((user) => ({
            ...user,
            id: user.id,
            name: user.first_name + " " + user.last_name,
          }));

          setUserOptions(result);
          setUsers(result);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [isFooter, profile, unswitchedProfile, withSelf, withUnswitchedProfile]);

  const handleClear = () => {
    onChange(null);
  };

  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
    },
    onDropdownOpen: search.length > 0 && !value ? undefined : handleLoad,
  });

  useEffect(() => {
    if (debounced.length > 1) {
      searchUser({ query: debounced + "", isMain: isFooter })
        .then((res) => {
          let newOptions = res.data.results;

          if (withSelf) {
            newOptions.push(profile);
          }
          if (
            withUnswitchedProfile &&
            useSessionStore.getState()?.user_switch
          ) {
            newOptions.push(unswitchedProfile);
          }
          const result = res.data.results.map((user) => ({
            ...user,
            id: user.id,
            name: user.first_name + " " + user.last_name,
          }));
          setUsers(result);
        })
        .finally(() => setSearching(false));
    } else {
      setUsers(userOptions);
      setSearching(false);
    }
  }, [
    debounced,
    isFooter,
    profile,
    unswitchedProfile,
    userOptions,
    withSelf,
    withUnswitchedProfile,
  ]);

  useEffect(() => {
    combobox.selectFirstOption();
  }, [users]);

  const options = users.map((item) => (
    <Combobox.Option
      value={item}
      key={item.id}
      bg={value === item.id ? "var(--mantine-primary-color-0)" : undefined}
    >
      <Highlight highlight={debounced} size="sm">
        {`${item.name} #${item.id}`}
      </Highlight>
    </Combobox.Option>
  ));

  return (
    <Combobox
      onOptionSubmit={(optionValue) => {
        onChange(optionValue);
        setSearch(optionValue?.name);
        combobox.closeDropdown();
      }}
      store={combobox}
      width={250}
      withArrow
      shadow="md"
      withinPortal={false}
      {...props}
    >
      <Combobox.Target>
        <TextInput
          id={id}
          label={label}
          placeholder={placeholder}
          value={search}
          onChange={(event) => {
            combobox.openDropdown();
            setSearch(event.currentTarget.value);
            if (event.currentTarget.value.length > 0) {
              setSearching(true);
            }
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => {
            combobox.closeDropdown();
            setSearch(value?.name || "");
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" && users.length > 0) {
              onChange(null, users[0]);
            }
          }}
          leftSection={leftSection}
          rightSection={
            value && clearable ? (
              <CloseButton onClick={handleClear} />
            ) : (
              <IconSelector size={18} />
            )
          }
          disabled={disabled}
        />
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>
          <ScrollArea.Autosize mah={200} type="scroll">
            {loading ? (
              <Combobox.Empty>Loading...</Combobox.Empty>
            ) : searching ? (
              <Combobox.Empty>Searching...</Combobox.Empty>
            ) : options.length === 0 ? (
              <Combobox.Empty>Nothing found</Combobox.Empty>
            ) : (
              options
            )}
          </ScrollArea.Autosize>
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};

export default UserSelector;
