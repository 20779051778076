import { deleteList, editList, getLists } from "../../../../api/ContactAPI";
import { nameColumn } from "../../../../layouts/Table/columns/nameColumn";
import dateColumn from "../../../../layouts/Table/columns/dateColumn";
import TableLayoutBlock from "../../../../layouts/Table/TableLayoutBlock";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useCallback, useMemo, useState } from "react";
import {
  Box,
  Group,
  Loader,
  NumberFormatter,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import MainTable from "../../../../layouts/Table/MainTable";
import SelectionDeleter from "../../../../components/TableComponents/SelectionDeleter";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import numberColumn from "../../../../layouts/Table/columns/numberColumn";
import ItemDeleter from "../../../../components/TableComponents/ItemDeleter";
import CreateListMenu from "../components/CreateListMenu";
import Jobs from "../components/jobs/Jobs";
import { sharingColumn } from "../../../../layouts/Table/columns/sharingColumn";
import { tagsColumn } from "../../../../layouts/Table/columns/tagsColumn";
import MergeLists from "../components/merge/MergeLists";
import { useParams } from "react-router-dom";
import { useListingLoader } from "../../../../components/hooks/ListingHooks";
import ListActions from "../actions/ListActions";
import SelectionMover from "../../../../components/TableComponents/SelectionMover";
import { types } from "../components/jobs/jobTypes";
import ListTopMenu from "./ListTopMenu";
import PageTitle from "../../../../components/View/PageTitle";
import SelectionSharer from "../../../../components/TableComponents/SelectionSharer";
import { useProfileStore } from "../../../../stores/UserStore";
import QuickCampaign from "../components/quickCampaign/QuickCampaign";

const getOrigin = (row) => {
  const lang = useLanguage.getState().language;
  let originDetails = row?.state_details[row.state_details.length - 1];
  if (!originDetails)
    return (
      <Box mr={-8}>
        <Tooltip
          label={`${lang.contact_lists.origin}: ${
            types("Creation", true).label
          }`}
        >
          <ThemeIcon variant="subtle" color="gray.6">
            {types("Creation", true).icon}
          </ThemeIcon>
        </Tooltip>
      </Box>
    );
  return (
    <Box mr={-8}>
      <Tooltip
        label={`${lang.contact_lists.origin}: ${
          types(originDetails?.type, true).label
        }`}
      >
        <ThemeIcon variant="subtle" color="gray.6">
          {types(originDetails?.type, true).icon}
        </ThemeIcon>
      </Tooltip>
    </Box>
  );
};

const ContactListsListing = () => {
  const lang = useLanguage((s) => s.language);
  const apiRef = useGridApiRef();
  const { folderId } = useParams();

  const columns = useMemo(() => {
    let initial = [
      numberColumn(),
      nameColumn({
        parentPath: folderId
          ? `/contact-lists/folder/${folderId}/list`
          : "/contact-lists",
        editFunction: () => editList,
        apiRef,
        leftSection: getOrigin,
      }),
      {
        field: "number_of_contacts",
        headerName: lang.contact_lists.columns.number_of_contacts,
        type: "number",
        align: "left",
        headerAlign: "left",
        width: 120,
        renderCell: ({ row, value }) => {
          return (
            <Group id={"loading-column" + row.id}>
              <NumberFormatter value={value} thousandSeparator=" " />

              {(row.state_details?.find((x) => x.state === "InProgress") ||
                row.state_details?.find((x) => x.state === "InQueue")) && (
                <Tooltip
                  label={
                    lang.contact_lists.jobs.in_progress
                      .loading_indicator_tooltip
                  }
                >
                  <Loader color="blue" type="dots" size={"xs"} />
                </Tooltip>
              )}
            </Group>
          );
        },
      },
      {
        field: "jobs",
        headerName: lang.contact_lists.jobs.column_name,
        width: 200,
        renderCell: ({ row }) => {
          return (
            <Jobs row={row} apiRef={apiRef} isList setLoading={setLoading} />
          );
        },
      },
    ];

    let additional =
      useProfileStore.getState().profile.level === "admin" ? [] : [];

    let complements = [
      {
        field: "create_ai",
        headerName: "",
        width: 130,
        cellClassName: "no-padding",
        renderCell: ({ row }) => {
          return <QuickCampaign current={row} />;
        },
      },
      tagsColumn({ apiRef, editFunction: () => editList }),
      sharingColumn({ apiRef, editFunction: () => editList }),
      dateColumn(),
    ];

    return [...initial, ...additional, ...complements];
  }, [lang, folderId, apiRef]);
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [hasQuery, setHasQuery] = useState(false);

  const toolbarActions = useCallback(
    ({ selectedIds }) => {
      return (
        <>
          <SelectionDeleter
            handleDelete={deleteList}
            apiRef={apiRef}
            setTableLoading={setLoading}
            ids={selectedIds}
            setRows={setRows}
          />
          <SelectionSharer
            handleShare={editList}
            apiRef={apiRef}
            setTableLoading={setLoading}
            ids={selectedIds}
          />
          <MergeLists onFinish={setLoading} selectedIds={selectedIds} />
          <SelectionMover
            handleEdit={editList}
            apiRef={apiRef}
            setTableLoading={setLoading}
            ids={selectedIds}
            folderType={"ContactList"}
          />
        </>
      );
    },
    [apiRef]
  );

  const actionsCell = useCallback(
    ({ row }) => {
      return (
        <Group wrap="nowrap" gap={4}>
          <ListActions current={row} onFinish={() => setLoading(true)} />
          {/* <IceBreaker current={row} onFinish={() => setLoading(true)} /> */}
          <ItemDeleter
            title_bold={`${row.name} #${row.id}`}
            handleDelete={deleteList}
            setRows={setRows}
            deletedMessage={lang.contact_lists.delete_success}
            id={row.id}
            disabled={
              !row?.delete_permission && !row?.permission?.delete_permission
            }
            apiRef={apiRef}
          />
        </Group>
      );
    },
    [apiRef, lang.contact_lists.delete_success]
  );

  useListingLoader({
    loading,
    setLoading,
    listGetter: getLists,
    responseField: "contact_lists",
    setRows,
    folderRouteId: "list-folder",
    hasQuery,
  });

  return (
    <>
      <Group className="layout-block top" justify="space-between">
        <PageTitle title={lang.contact_lists.browser_tab_title} />

        <ListTopMenu />

        <CreateListMenu />
      </Group>

      <TableLayoutBlock
        folderType={"ContactList"}
        parentRoute={"/contact-lists"}
        editFunction={editList}
        apiRef={apiRef}
        setTableLoading={setLoading}
      >
        <MainTable
          apiRef={apiRef}
          columns={columns}
          rows={rows.filter((item) =>
            hasQuery || folderId ? true : !item.folder
          )}
          loading={loading}
          setLoading={setLoading}
          id={"list-table"}
          toolbarActions={toolbarActions}
          actionsCell={actionsCell}
          hasFolder
          pageName={"contact_lists"}
          pageOrderName={"contact_list_order"}
          setHasQuery={setHasQuery}
          hasQuery={hasQuery}
          actionsCellWidth={75}
        />
      </TableLayoutBlock>
    </>
  );
};

export default ContactListsListing;
