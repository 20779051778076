import { ActionIcon, Button, Divider, Stack, Tooltip } from "@mantine/core";
import { IconDotsVertical } from "@tabler/icons-react";
import IceBreaker from "./IceBreaker";
import PopOver from "../../../../components/View/PopOver";
import ListTranslate from "./ListTranslate";
import ListEnrich from "./ListEnrich";
import ListEmailVerify from "./ListEmailVerify";
import ListDeleteKeep from "./ListDeleteKeep";
import ListConvertLinkedin from "./ListConvertLinkedin";
import ListCopyToPrm from "./ListCopyToPrm";
import ListSplit from "./ListSplit";
import DuplicateRessource from "../../../../components/View/DuplicateRessource";
import { copyList, copyListToUser } from "../../../../api/ContactAPI";
import CopyToUser from "../../../../components/View/CopyToUser";
import ListUrlUsed from "./ListUrlUsed";
import ListDownload from "./ListDownload";
import { useLanguage } from "../../../../stores/LanguageStore";
import ListRefreshTargetingGoogle from "./ListRefreshTargetingGoogle";
import ListRefreshTargetingAll from "./ListRefreshTargetingAll";

const ListActions = ({ current, inside = false, onFinish }) => {
  const lang = useLanguage((s) => s.language);
  const handleFinish = (closeMenu) => {
    closeMenu?.();

    setTimeout(() => {
      onFinish?.();
    }, 100);
  };
  return (
    <>
      <PopOver
        opener={(handleOpen, ref) =>
          inside ? (
            <Button
              className="toolbar-action-button"
              variant="light"
              justify="start"
              ref={ref}
              leftSection={<IconDotsVertical size={18} />}
              onClick={handleOpen}
              style={{
                borderBottomRightRadius: 4,
              }}
            >
              {lang.table.more_actions}
            </Button>
          ) : (
            <Tooltip label={lang.table.more_actions}>
              <ActionIcon
                variant="subtle"
                ref={ref}
                color="primary"
                onClick={handleOpen}
              >
                <IconDotsVertical size={18} />
              </ActionIcon>
            </Tooltip>
          )
        }
        popoverChildren={(handleClose) => (
          <Stack gap={0}>
            <ListDownload current={current} closeMenu={handleClose} />

            <DuplicateRessource
              current={current}
              onFinish={() => handleClose()}
              closeMenu={handleClose}
              handler={copyList}
              responseField={"contact_list_id"}
            />

            <CopyToUser
              current={current}
              onFinish={() => handleClose()}
              closeMenu={handleClose}
              handler={copyListToUser}
              responseField={"contact_list_id"}
            />

            <ListSplit
              current={current}
              onFinish={() => handleFinish(handleClose)}
              closeMenu={handleClose}
            />

            <ListCopyToPrm
              current={current}
              onFinish={() => handleFinish(handleClose)}
              closeMenu={handleClose}
            />

            <Divider />

            <ListConvertLinkedin
              current={current}
              onFinish={() => handleFinish(handleClose)}
              closeMenu={handleClose}
            />

            <ListRefreshTargetingAll
              current={current}
              onFinish={() => handleFinish(handleClose)}
              closeMenu={handleClose}
            />

            <ListRefreshTargetingGoogle
              current={current}
              onFinish={() => handleFinish(handleClose)}
              closeMenu={handleClose}
            />

            <ListUrlUsed
              current={current}
              onFinish={() => handleFinish(handleClose)}
              closeMenu={handleClose}
            />

            <Divider />

            <ListEnrich
              current={current}
              onFinish={() => handleFinish(handleClose)}
              closeMenu={handleClose}
            />

            <ListEmailVerify
              current={current}
              onFinish={() => handleFinish(handleClose)}
              closeMenu={handleClose}
            />

            <IceBreaker
              current={current}
              onFinish={() => handleFinish(handleClose)}
              inMenu
              closeMenu={handleClose}
            />

            <ListTranslate
              current={current}
              onFinish={() => handleFinish(handleClose)}
              closeMenu={handleClose}
            />

            <ListDeleteKeep
              current={current}
              onFinish={() => handleFinish(handleClose)}
              closeMenu={handleClose}
            />
          </Stack>
        )}
        anchorOrigin={
          inside
            ? { horizontal: "right", vertical: "bottom" }
            : { horizontal: "left", vertical: "center" }
        }
        transformOrigin={
          inside
            ? { horizontal: "right", vertical: "top" }
            : { horizontal: "right", vertical: "center" }
        }
        sx={{ zIndex: 200 }}
      />

      <ListTranslate
        current={current}
        onFinish={() => handleFinish()}
        closeMenu={() => {}}
        hidden
      />

      <ListEmailVerify
        current={current}
        onFinish={() => handleFinish()}
        closeMenu={() => {}}
        hidden
      />

      <IceBreaker
        current={current}
        onFinish={() => handleFinish()}
        closeMenu={() => {}}
        inMenu
        hidden
      />
    </>
  );
};

export default ListActions;
