import { nameColumn } from "../../../layouts/Table/columns/nameColumn";
import dateColumn from "../../../layouts/Table/columns/dateColumn";
import TableLayoutBlock from "../../../layouts/Table/TableLayoutBlock";
import { useLanguage } from "../../../stores/LanguageStore";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ActionIcon, Group, Tooltip } from "@mantine/core";
import MainTable from "../../../layouts/Table/MainTable";
import { IconEdit } from "@tabler/icons-react";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { tagsColumn } from "../../../layouts/Table/columns/tagsColumn";
import { sharingColumn } from "../../../layouts/Table/columns/sharingColumn";
import ItemDeleter from "../../../components/TableComponents/ItemDeleter";
import { Link, useParams, useRouteLoaderData } from "react-router-dom";
import SelectionDeleter from "../../../components/TableComponents/SelectionDeleter";
import { useListingLoader } from "../../../components/hooks/ListingHooks";
import CreateModel from "./CreateModel";
import { getModelTypeProps, parseModelType } from "./modelUtils";
import numberColumn from "../../../layouts/Table/columns/numberColumn";
import ModelActions from "./actions/ModelActions";
import SelectionMover from "../../../components/TableComponents/SelectionMover";
import ModelTemplates from "./ModelTemplates";
import PageTitle from "../../../components/View/PageTitle";
import SelectionSharer from "../../../components/TableComponents/SelectionSharer";

const ModelListing = () => {
  const { folderId, modelType } = useParams();
  const folder = useRouteLoaderData("model-folder");
  const apiRef = useGridApiRef();
  const lang = useLanguage((s) => s.language);
  const columns = useMemo(
    () => [
      numberColumn(),
      nameColumn({
        parentPath: folderId
          ? (row) =>
              `/models/folder/${folderId}/${parseModelType(row.model_type)}/${
                row.id
              }/edit`
          : (row) => `/models/${modelType}/${row.id}/edit`,
        withoutId: true,
        editFunction: (row) =>
          getModelTypeProps(modelType, folderId, row)?.editFunction,
        apiRef,
      }),
      ...(getModelTypeProps(modelType, folderId)?.columns || []),
      tagsColumn({
        apiRef,
        editFunction: (row) =>
          getModelTypeProps(modelType, folderId, row)?.editFunction,
      }),
      sharingColumn({
        apiRef,
        editFunction: (row) =>
          getModelTypeProps(modelType, folderId, row)?.editFunction,
      }),
      dateColumn(),
    ],
    [folderId, modelType, apiRef]
  );

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [hasQuery, setHasQuery] = useState(false);
  const [highlight, setHighlight] = useState(null);

  const actionsCell = useCallback(
    ({ row, value }) => {
      return (
        <Group wrap="nowrap" gap={4}>
          <ModelActions current={row} setLoading={setLoading} />

          <Tooltip label={lang.global.edit}>
            <ActionIcon
              component={Link}
              to={`/models/${
                folderId
                  ? `folder/${folderId}/${parseModelType(row.model_type)}`
                  : modelType
              }/${row.id}/edit`}
              variant="subtle"
            >
              <IconEdit size={18} />
            </ActionIcon>
          </Tooltip>

          <ItemDeleter
            title_bold={`${row.name} #${row.id}`}
            handleDelete={
              getModelTypeProps(modelType, folderId, row)?.deleteFunction
            }
            deletedMessage={lang.model_emails.delete_success}
            id={row.id}
            disabled={
              !row?.delete_permission && !row?.permission?.delete_permission
            }
            apiRef={apiRef}
          />
        </Group>
      );
    },
    [apiRef, folderId, lang.model_emails.delete_success, modelType]
  );

  const toolbarActions = useCallback(
    ({ selectedIds }) => (
      <>
        <SelectionDeleter
          handleDelete={getModelTypeProps(modelType)?.deleteFunction}
          apiRef={apiRef}
          setTableLoading={setLoading}
          ids={selectedIds}
        />

        <SelectionMover
          handleEdit={getModelTypeProps(modelType)?.editFunction}
          apiRef={apiRef}
          setTableLoading={setLoading}
          ids={selectedIds}
          folderType={"Model"}
        />

        <SelectionSharer
          getEditFunction={(row) => getModelTypeProps(modelType)?.editFunction}
          apiRef={apiRef}
          setTableLoading={setLoading}
          ids={selectedIds}
        />
      </>
    ),
    [apiRef, modelType]
  );

  useEffect(() => {
    setLoading(true);
  }, [modelType]);

  useListingLoader({
    loading,
    setLoading,
    listGetter: getModelTypeProps(modelType, !!folderId)?.listingFunction,
    responseField: "models_list",
    setRows,
    folderRouteId: "model-folder",
    hasQuery,
  });

  return (
    <>
      <Group
        className="layout-block top"
        w={"100%"}
        gap={0}
        justify="space-between"
      >
        <PageTitle
          title={
            folder
              ? folder?.name
              : lang[getModelTypeProps(modelType)?.lang_name]
                  ?.browser_tab_title || "Model"
          }
        />

        {modelType === "email" && (
          <ModelTemplates
            setTableLoading={setLoading}
            setHighlight={setHighlight}
          />
        )}
        <CreateModel />
      </Group>

      <TableLayoutBlock
        folderType={"Model"}
        parentRoute={"/models"}
        editFunction={getModelTypeProps(modelType, !!folderId)?.editFunction}
        apiRef={apiRef}
        setTableLoading={setLoading}
      >
        <MainTable
          apiRef={apiRef}
          columns={columns}
          rows={rows.filter((item) =>
            hasQuery || folderId ? true : !item.folder
          )}
          loading={loading}
          setLoading={setLoading}
          id={"model-table"}
          toolbarActions={toolbarActions}
          actionsCell={actionsCell}
          hasFolder
          pageName={getModelTypeProps(modelType, folderId)?.pageName}
          pageOrderName={
            getModelTypeProps(modelType, folderId)?.pageName + "_order"
          }
          noRowsDisplay={<CreateModel inTable />}
          defaultHighlight={highlight}
          setDefaultHighlight={setHighlight}
          setHasQuery={setHasQuery}
          hasQuery={hasQuery}
        />
      </TableLayoutBlock>
    </>
  );
};

export default ModelListing;
