import { Box, Button, Center, Group, SegmentedControl } from "@mantine/core";
import PageTitle from "../../../components/View/PageTitle";
import { useLanguage } from "../../../stores/LanguageStore";
import {
  IconChartInfographic,
  IconSparkles,
  IconSpeakerphone,
} from "@tabler/icons-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ZapierSettings from "../leadsTracking/titleSection/zapier/ZapierSettings";
import Signage from "../../../layouts/Header/Signage";
import Sparkles from "../../../components/Sparkles/Sparkles";

const StatsPageTitle = ({ defaultTitle }) => {
  const lang = useLanguage((s) => s.language);

  const location = useLocation();
  const locations = location.pathname.split("/").filter(Boolean);
  const actualLocation = locations[locations.length - 1];

  const navigate = useNavigate();
  return (
    <Group className="layout-block top" w={"100%"} gap={0}>
      <PageTitle
        title={defaultTitle || lang.statistics.browser_tab_title}
        maw={"40%"}
      />

      <SegmentedControl
        ml={"auto"}
        size="sm"
        w={400}
        data={[
          {
            label: (
              <Center component={Group}>
                <IconSpeakerphone size={18} />

                <span>{lang.routes.stats.campaigns}</span>
              </Center>
            ),
            value: "campaigns",
          },
          {
            label: (
              <Center component={Group}>
                <IconChartInfographic size={18} />

                <span>{lang.routes.senders.reports}</span>
              </Center>
            ),
            value: "global-report",
          },
        ]}
        value={actualLocation}
        onChange={(v) => navigate("/statistics/" + v)}
      />

      <Group ml={18}>
        <ZapierSettings type="statistics" />
        <Signage />

        <Button
          component={Link}
          to={"/campaign/create"}
          leftSection={<IconSparkles />}
          variant="gradient"
          gradient={{ from: "primary.4", to: "primary.6", deg: 90 }}
          style={{
            overflow: "visible",
          }}
        >
          {lang.header.menus.create_campaign}
        </Button>
      </Group>
    </Group>
  );
};

export default StatsPageTitle;
