import { Button, Group } from "@mantine/core";
import { useLanguage } from "../../stores/LanguageStore";

const FormButtons = ({ onCancel, onSubmit, loading, form, disabled }) => {
  const lang = useLanguage((s) => s.language);
  return (
    <Group wrap="nowrap" justify="end" onClick={(e) => e.preventDefault()}>
      {onCancel && (
        <Button
          onClick={(e) => {
            e.preventDefault();
            onCancel();
          }}
          size="compact-sm"
          variant="subtle"
          disabled={loading}
        >
          {lang.components.closer.submit}
        </Button>
      )}
      <Button
        form={form}
        type="submit"
        loading={loading}
        size="compact-sm"
        color="tertiary.8"
        onClick={onSubmit}
        disabled={disabled}
      >
        {lang.contact_lists.edit.dialog.submit_button}
      </Button>
    </Group>
  );
};

export default FormButtons;
