import { useState } from "react";
import { useEffect } from "react";
import { useSessionStore } from "../../../stores/UserStore";
import axios from "axios";
import { Group, LoadingOverlay, Stack } from "@mantine/core";
import ReplyItem from "./ReplyItem";

const DirectLinkReply = () => {
  const [loading, setLoading] = useState(false);
  const [replies, setReplies] = useState([]);

  useEffect(() => {
    let params = new URLSearchParams(location.search);
    let prm_contact_id = params.get("p_id");
    let reply_id = params.get("r_id");
    let isLinkedin = params.get("linkedin");

    const api_url = useSessionStore.getState().reseller.domain_api;

    setLoading(true);
    axios
      .get(
        `https://${api_url}/workflow/${prm_contact_id}/${
          reply_id ? `${reply_id}/reply` : "reply"
        }/${isLinkedin ? "linkedin" : ""}`
      )
      .then((res) => setReplies(res.data.replies))
      .finally(() => setLoading(false));
  }, []);

  return (
    <Group w={"100vw"} h={"100vh"} justify="center">
      <LoadingOverlay visible={loading} />

      <Stack h={"90%"} w={800} mb={"sm"} mt={16} gap={"lg"}>
        {replies.map((item, index) => (
          <ReplyItem key={index} item={item} type={item.type} />
        ))}
      </Stack>
    </Group>
  );
};

export default DirectLinkReply;
