import { Navigate } from "react-router-dom";
import { useProfileStore } from "../../stores/UserStore";
import { LoadingOverlay } from "@mantine/core";

const PagePermission = ({ permission }) => {
  const profile = useProfileStore((s) => s.profile);
  if (
    profile?.permissions?.find((x) => x.name === permission)?.value === false
  ) {
    return <Navigate to={".."} replace />;
  }

  return <LoadingOverlay overlayProps={{ blur: 10 }} />;
};

export default PagePermission;
