import { useMemo, useState } from "react";
import { useProfileStore } from "../../stores/UserStore";
import { updateSelf } from "../../api/UserAPI";
import MultiSelector from "../Selectors/MultiSelector";

function getOptionName(item) {
  return item?.name || `${item.first_name} ${item.last_name}`;
}

const CommunitySelector = ({
  label,
  placeholder,
  size,
  values,
  onChange,
  disabled,
  leftSection,
  inputProps,
  inputStyles,
  type = "Team",
}) => {
  const [loading, setLoading] = useState(false);
  const handleLoad = () => {
    setLoading(true);
    updateSelf().finally(() => setLoading(false));
  };

  const orgs = useProfileStore((s) => s.profile)?.organizations;
  const teams = useProfileStore((s) => s.profile)?.teams;

  const options = useMemo(() => {
    return (type === "Team" ? teams : orgs).map((item) => ({
      ...item,
      option_name: getOptionName(item),
    }));
  }, [orgs, teams, type]);

  return (
    <MultiSelector
      options={options}
      label={label}
      onDropdownOpen={handleLoad}
      loading={loading}
      values={values}
      onChange={onChange}
      placeholder={placeholder}
      leftSection={leftSection}
      rightOptionSection={(item) => {
        return (
          <span style={{ fontSize: 10, opacity: 0.35, width: 30 }}>
            #{item?.id}
          </span>
        );
      }}
      size={size}
      disabled={disabled}
      w={"100%"}
      searchable
      checkIconPosition="left"
      withDropdown
      inputProps={inputProps}
      inputStyles={inputStyles}
      option_name_props={{
        w: "80%",
      }}
      maxInputHeight={"none"}
      autoFocus={false}
    />
  );
};

export default CommunitySelector;
