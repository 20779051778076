import { ActionIcon, Group, Menu } from "@mantine/core";
import { IconPlus, IconX } from "@tabler/icons-react";
import {
  EdgeLabelRenderer,
  getBezierPath,
  getSmoothStepPath,
  useReactFlow,
} from "reactflow";
import { defaultNodeConfigs, isMainAction, nodeWidth } from "../flowSettings";
import { useMemo, useState } from "react";
import { useFlowStore } from "../../../../../../stores/WorkflowStore";
import { handleLayout } from "../layouter";
import ActionsList from "../components/ActionsList";

function getPath({
  sourceX,
  sourceY,
  sourcePosition,
  targetX,
  targetY,
  targetPosition,
}) {
  let f = sourceY > targetY ? getSmoothStepPath : getBezierPath;

  return f({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
}

const FlowEdge = ({
  sourceX,
  sourceY,
  targetX,
  targetY,
  target,
  source,
  sourcePosition,
  targetPosition,
  data,
  markerEnd,
}) => {
  const reactFlow = useReactFlow();
  const [opened, setOpenTrailAdder] = useState(false);
  const nodes = useFlowStore((s) => s.nodes);
  const linking = useFlowStore((s) => s.linking);
  const edgeId = `e-${source}-${target}`;

  const targetHasMultipleSources = useMemo(() => {
    let targetNode = nodes.find((x) => x.id === target);

    if (targetNode) {
      return Array.isArray(targetNode?.data?.source);
    }
    return false;
  }, [nodes, target]);

  const [edgePath, labelX, labelY] = getPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const handleAdd = (actionType) => {
    const getId = useFlowStore.getState().getId;
    const setNodes = useFlowStore.getState().setNodes;
    const updateNodeData = useFlowStore.getState().updateNodeData;
    const edges = useFlowStore.getState().edges;
    const setEdges = useFlowStore.getState().setEdges;
    let newNodes = [...nodes];
    let newEdges = [...edges.filter((x) => x.id !== edgeId)];

    let newEventNodeId = getId().toString();
    let newNodeId = getId().toString();

    //#region Choose automatically the same sender as initial action
    const initialNode = nodes.find((x) => x.data?.source === "0");
    const initialType = initialNode?.data?.actionType;
    let autoData = { ...defaultNodeConfigs };

    if (
      initialType === actionType ||
      (initialType?.includes("linkedin") && actionType?.includes("linkedin"))
    ) {
      autoData.integration_id = initialNode?.data?.integration_id;
      autoData.pool_id = initialNode?.data?.pool_id;
    }
    //#endregion

    const newEventNode = {
      id: newEventNodeId,
      type: "action",
      data: {
        actionType: "event",
        source: source === "0" || data?.fromEvent ? newNodeId : source,
        error: data?.error,
      },
      position: { x: sourceX - nodeWidth / 2, y: sourceY },
    };
    newNodes.push(newEventNode);

    const newNode = {
      id: newNodeId,
      type: "action",
      data: {
        actionType,
        source:
          source === "0" ? "0" : data?.fromEvent ? source : newEventNodeId,
        error: data?.error,
        ...autoData,
      },
      position: { x: sourceX - nodeWidth / 2, y: sourceY },
    };
    newNodes.push(newNode);

    // Source to event OR event to target
    let newEdge1 = {};
    if (source === "0") {
      newEdge1 = {
        id: `e-${0}-${newNodeId}`,
        source: "0",
        target: newNodeId,
        data: { fromEvent: true },
      };
    } else {
      newEdge1 = {
        id: `e-${source}-${data?.fromEvent ? newNodeId : newEventNodeId}`,
        source: source,
        target: data?.fromEvent ? newNodeId : newEventNodeId,
        data: { fromEvent: data?.fromEvent },
      };
    }
    newEdges.push(newEdge1);

    // Event to new Node
    let newEdge2 = {};
    if (source === "0") {
      newEdge2 = {
        id: `e-${newNodeId}-${newEventNodeId}`,
        source: newNodeId,
        target: newEventNodeId,
        data: { fromEvent: false },
      };
    } else {
      newEdge2 = {
        id: `e-${data?.fromEvent ? newNodeId : newEventNodeId}-${
          data?.fromEvent ? newEventNodeId : newNodeId
        }`,
        source: data?.fromEvent ? newNodeId : newEventNodeId,
        target: data?.fromEvent ? newEventNodeId : newNodeId,
        data: { fromEvent: !data?.fromEvent },
      };
    }
    newEdges.push(newEdge2);

    // New node to Target OR new node to event if on initial
    let newEdge3 = {};
    if (source === "0") {
      newEdge3 = {
        id: `e-${newEventNodeId}-${target}`,
        source: newEventNodeId,
        target: target,
        data: { fromEvent: true },
      };
    } else {
      newEdge3 = {
        id: `e-${data?.fromEvent ? newEventNodeId : newNodeId}-${target}`,
        source: data?.fromEvent ? newEventNodeId : newNodeId,
        target: target,
        data: { fromEvent: data?.fromEvent },
      };
    }
    newEdges.push(newEdge3);

    setNodes(newNodes);
    setEdges(newEdges);

    updateNodeData(target, {
      source:
        source === "0"
          ? newEventNodeId
          : data?.fromEvent
          ? newEventNodeId
          : newNodeId,
    });

    setTimeout(() => {
      handleLayout();
      setTimeout(() => {
        let nodes = useFlowStore.getState().nodes;
        let n = nodes.find((x) => x.id === newNodeId);
        const setSelectedNode = useFlowStore.getState().setSelectedNode;
        if (isMainAction(n)) {
          setSelectedNode(n);
          return setTimeout(() => {
            reactFlow?.fitView({ nodes: [n], duration: 500 });
          }, 201);
        }
        reactFlow?.fitView({ nodes: [n], duration: 500 });
      }, 100);
    }, 50);
  };

  const handleDelete = () => {
    const updateNodeData = useFlowStore.getState().updateNodeData;
    const edges = useFlowStore.getState().edges;
    const setEdges = useFlowStore.getState().setEdges;

    let targetNode = nodes.find((x) => x.id === target);

    let newTargetSource = targetNode.data.source?.filter((x) => x !== source);

    updateNodeData(target, {
      source:
        newTargetSource.length === 1 ? newTargetSource[0] : newTargetSource,
    });

    setEdges(edges.filter((x) => x.id !== edgeId));
    setTimeout(() => {
      handleLayout();
    }, 10);
  };

  return (
    <>
      <path
        id={edgeId}
        className={"react-flow__edge-path"}
        d={edgePath}
        markerEnd={markerEnd}
        style={{
          stroke: data?.color,
          strokeWidth: 1.4,
        }}
      />

      {targetHasMultipleSources && !linking && (
        <EdgeLabelRenderer>
          <div
            style={{
              position: "absolute",
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              borderRadius: "50%",
              fontWeight: 700,
              pointerEvents: "all",
            }}
            className="nodrag nopan"
          >
            <Group w={nodeWidth} justify="center">
              <ActionIcon
                radius={"lg"}
                size={"sm"}
                variant="white"
                style={{ boxShadow: "#00000033 0 1px 3px", border: "none" }}
                onClick={handleDelete}
                color="red"
              >
                <IconX size={16} />
              </ActionIcon>
            </Group>
          </div>
        </EdgeLabelRenderer>
      )}

      {!linking && !targetHasMultipleSources && (
        <EdgeLabelRenderer>
          <div
            style={{
              position: "absolute",
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              borderRadius: "50%",
              fontWeight: 700,
              pointerEvents: "all",
            }}
            className="nodrag nopan"
          >
            <Group w={nodeWidth} justify="center">
              <Menu
                trigger="click-hover"
                openDelay={200}
                closeDelay={200}
                position="right"
                onChange={setOpenTrailAdder}
                shadow="md"
              >
                <Menu.Target>
                  <ActionIcon
                    radius={"lg"}
                    size={"sm"}
                    variant="white"
                    style={{ boxShadow: "#00000033 0 1px 3px", border: "none" }}
                  >
                    <IconPlus
                      size={16}
                      style={{
                        transform: opened ? "rotate(135deg)" : "none",
                        transition: "all ease .15s",
                      }}
                    />
                  </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                  <ActionsList handleActionClick={handleAdd} />
                </Menu.Dropdown>
              </Menu>
            </Group>
          </div>
        </EdgeLabelRenderer>
      )}
    </>
  );
};
export default FlowEdge;
