import React, { useEffect, useState } from "react";
import {
  activateZapierHook,
  deactivateZapierHook,
} from "../../../../../api/ZapierApi";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { notifications } from "@mantine/notifications";
import { Switch } from "@mantine/core";

const ZapierActivator = ({ item }) => {
  const lang = useLanguage((s) => s.language);
  const [active, setActive] = useState(item?.active);
  const [loading, setLoading] = useState(false);
  const handleChange = () => {
    setLoading(true);
    const func = active ? deactivateZapierHook : activateZapierHook;
    func({ id: item.id })
      .then(() => {
        setActive(!active);
        notifications.show({
          message: lang.sync_zapier[active ? "deactivated" : "activated"],
          color: "tertiary.8",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setActive(item?.active);
  }, [item?.active]);

  return (
    <Switch
      disabled={loading}
      checked={active}
      onChange={handleChange}
      color="tertiary.8"
    />
  );
};

export default ZapierActivator;
