import { useCallback, useState } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { deleteAvatar, uploadAvatar } from "../../../../api/UserAPI";
import { notifications } from "@mantine/notifications";
import {
  Avatar,
  Box,
  Button,
  Center,
  Divider,
  Group,
  Modal,
  Paper,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import {
  IconBuildingCommunity,
  IconDeviceFloppy,
  IconEdit,
  IconTrash,
  IconUser,
  IconUserPlus,
} from "@tabler/icons-react";
import { FileUploader } from "react-drag-drop-files";
import { parseDate } from "../../../../functions/dates";

const fileTypes = ["JPG", "JPEG", "PNG"];
const filePickerStyles = {
  border: "1px dashed",
  borderColor: "#00000066",
  borderRadius: "50%",
  padding: 8,
  textAlign: "center",
  cursor: "pointer",
};

const AvatarEditor = ({
  isOther,
  onSuccess,
  profile,
  withCreatedOn,
  ...props
}) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const [dragging, setDragging] = useState(false);
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(profile?.avatar);
  const handleChange = (file) => {
    if (file?.size >= 2000000) {
      return alert(lang.components.files.error_too_big);
    }
    setFile(file);

    if (file) {
      const fr = new FileReader();
      fr.readAsDataURL(file);

      fr.onload = (e) => {
        setPreview(e.target.result);
      };
    } else {
      setPreview("");
    }
  };
  const handleOpen = (event) => {
    setDialogOpen(true);
    setPreview(profile?.avatar);
    setFile(null);
    setLoading(null);
  };
  const handleClose = () => {
    setDialogOpen(false);
  };

  const onSave = useCallback(() => {
    setLoading("edit");
    const formData = new FormData();
    formData.append("file", file);

    uploadAvatar(profile?.id, formData)
      .then((response) => {
        notifications.show({
          message: lang.profile.avatar.changed,
          color: "tertiary.8",
        });
        handleClose();
        onSuccess?.();
      })
      .finally(() => setLoading(null));
  }, [file, profile, onSuccess, lang]);

  const onDelete = useCallback(() => {
    if (!profile?.avatar) {
      return handleClose();
    }
    setLoading("delete");

    deleteAvatar(profile?.id)
      .then((response) => {
        notifications.show({
          message: lang.profile.avatar.deleted,
          color: "tertiary.8",
        });
        handleClose();
        onSuccess?.();
      })
      .catch((err) => {})
      .finally(() => setLoading(null));
  }, [profile, onSuccess, lang]);

  const dimension = isOther ? 48 : 64;

  return (
    <>
      <Group w={"100%"} align="center" wrap="nowrap" {...props}>
        <Paper
          component={Center}
          w={dimension}
          h={dimension}
          pos="relative"
          radius={"xl"}
          className="hover-source"
        >
          <Avatar alt="avatar" src={profile?.avatar} w={"100%"} h={"100%"} />
          <Box
            className="hover-target"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              background: "#00000033",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              color: "#fff",
              cursor: "pointer",
              borderRadius: "50%",
            }}
            onClick={handleOpen}
          >
            <IconEdit />
          </Box>
        </Paper>

        <Stack gap={6} flex={"1 1 auto"} w={0}>
          <Text fw={600} truncate="end">
            {profile?.first_name} {profile?.last_name}
          </Text>

          <Group c={"dimmed"} gap={"xs"}>
            <Tooltip
              label={lang.administration.center.first_organization_tooltip}
            >
              <IconBuildingCommunity size={16} />
            </Tooltip>

            <Text fw={400} size="sm">
              {profile?.organizations?.[0]?.name || " - "}
            </Text>
          </Group>

          {withCreatedOn && (
            <Group c={"dimmed"} gap={"xs"}>
              <Tooltip label={lang.administration.center.created_date}>
                <IconUserPlus size={16} />
              </Tooltip>

              <Text fw={400} size="sm">
                {parseDate(profile?.created_on)}
              </Text>
            </Group>
          )}
        </Stack>
      </Group>

      <Modal
        opened={dialogOpen}
        onClose={handleClose}
        size={"md"}
        title={lang.profile.avatar.dialog.title}
      >
        <Stack>
          <Text size="sm">{lang.profile.avatar.dialog.text}</Text>
          <Group>
            <IconUser size={18} />
            <Text size="sm">
              {lang.profile.avatar.dialog.visible_on_all_platform}
            </Text>
          </Group>
          <Divider />
          <Center>
            <FileUploader
              handleChange={handleChange}
              name="file"
              types={fileTypes}
              hoverTitle={lang.components.files.drop_here}
              onDraggingStateChange={(d) => {
                setDragging(d);
              }}
            >
              <Stack justify="center" align="center" style={filePickerStyles}>
                <Avatar
                  src={preview}
                  style={{
                    width: 100,
                    height: 100,
                    opacity: dragging ? 0.1 : 1,
                  }}
                />
              </Stack>
            </FileUploader>
          </Center>
          <Text mt={4} c={"dimmed"} ta={"center"} size="sm">
            {lang.components.files.drag_drop_here}
          </Text>
          <Group justify="center" mt={"md"}>
            <Button
              leftSection={<IconTrash size={18} />}
              onClick={onDelete}
              variant="light"
              color="red"
              loading={loading === "delete"}
              disabled={loading}
            >
              {lang.profile.avatar.dialog.delete_button}
            </Button>
            <Button
              leftSection={<IconDeviceFloppy size={18} />}
              onClick={onSave}
              variant="light"
              color="tertiary.8"
              loading={loading === "edit"}
              disabled={loading || !file}
            >
              {lang.profile.avatar.dialog.save_button}
            </Button>
          </Group>
        </Stack>
      </Modal>
    </>
  );
};

export default AvatarEditor;
