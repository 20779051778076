import { useCallback } from "react";
import { useParams, useRouteLoaderData } from "react-router-dom";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { useContactHandler } from "../../lists/components/useContactHandler";
import {
  deleteBlacklistData,
  deleteBlacklistDatas,
  downloadFilterList,
  getFirstPageBlacklist,
  searchInBlacklist,
} from "../../../../api/BlacklistAPI";
import ItemDeleter from "../../../../components/TableComponents/ItemDeleter";
import SearchListsButton from "../../lists/components/SearchListsButton";
import SelectionDeleter from "../../../../components/TableComponents/SelectionDeleter";
import { Group } from "@mantine/core";
import BlacklistImportButton from "./BlacklistImportButton";
import TableLayoutBlock from "../../../../layouts/Table/TableLayoutBlock";
import MainTable from "../../../../layouts/Table/MainTable";
import { useConfigStore } from "../../../../stores/ConfigStore";
import PageTitle from "../../../../components/View/PageTitle";
import TableFilterExport from "../../../../components/TableComponents/TableFilterExport";

function resultsHandler(response) {
  const datafields = useConfigStore.getState().datafields;
  let results = response;

  return results.map((data) => {
    let current_field = datafields.find((x) => x.id === data.data_field_id);

    if (current_field) {
      return {
        ...data,
        [current_field.id]: data.value,
      };
    }
    return {
      ...data,
    };
  });
}

const BlacklistContent = () => {
  const { blacklistId } = useParams();
  const blacklistProfile = useRouteLoaderData("blacklist");

  const lang = useLanguage((s) => s.language);
  const apiRef = useGridApiRef();

  const {
    loading,
    columns,
    rows,
    rowCount,
    serverInfo,
    handleLoading,
    onFilterChange,
    onPaginationChange,
    onSortChange,
  } = useContactHandler({
    apiRef,
    listId: parseInt(blacklistId),
    getFirstPage: getFirstPageBlacklist,
    searchFunction: searchInBlacklist,
    resultsHandler,
  });

  const actionsCell = useCallback(
    ({ row, value }) => {
      return (
        <ItemDeleter
          title_bold={`${row["%first_name%"]} ${row["%last_name%"]}`}
          handleDelete={deleteBlacklistData}
          deletedMessage={lang.blacklists.delete_success}
          parentId={parseInt(blacklistId)}
          id={row.id}
          apiRef={apiRef}
        />
      );
    },
    [apiRef, blacklistId, lang.blacklists.delete_success]
  );

  const toolbarActions = useCallback(
    ({ selectedIds, filter }) => (
      <>
        <SearchListsButton isBlacklist />
        <TableFilterExport
          id={parseInt(blacklistId)}
          selectedIds={selectedIds}
          filter={filter}
          handleExport={downloadFilterList}
          name={blacklistProfile?.name}
          filterResultNumber={rowCount}
          total={blacklistProfile?.number_of_contacts}
        />
        <SelectionDeleter
          handleMultipleDelete={deleteBlacklistDatas}
          apiRef={apiRef}
          setTableLoading={() => handleLoading()}
          ids={selectedIds}
        />
      </>
    ),
    [
      apiRef,
      blacklistId,
      blacklistProfile?.name,
      blacklistProfile?.number_of_contacts,
      handleLoading,
      rowCount,
    ]
  );

  if (!columns) {
    return;
  }

  return (
    <>
      <Group className="layout-block top" w={"100%"} justify="space-between">
        <PageTitle title={blacklistProfile?.name} />

        <BlacklistImportButton />
      </Group>

      <TableLayoutBlock>
        <MainTable
          isClient={false}
          apiRef={apiRef}
          columns={columns}
          rows={rows}
          rowCount={rowCount}
          loading={loading}
          setLoading={() => {
            handleLoading();
          }}
          id={"list-table"}
          hasFolder
          pageName={"blacklists"}
          onPaginationChange={onPaginationChange}
          actionsCell={actionsCell}
          onFilterChange={onFilterChange}
          onSortChange={onSortChange}
          pageOrderName="blacklists_order"
          toolbarActions={toolbarActions}
          actionsCellWidth={50}
        />
      </TableLayoutBlock>
    </>
  );
};

export default BlacklistContent;
