import { useLanguage } from "../../../../stores/LanguageStore";
import {
  ActionIcon,
  Button,
  Popover,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import { IconChevronLeft, IconDownload } from "@tabler/icons-react";
import { useState } from "react";
import { downloadList } from "../../../../api/ContactAPI";
import { notifications } from "@mantine/notifications";

const ListDownload = ({ current, closeMenu, handler, isIcon }) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(null);
  const [opened, setOpened] = useState(false);

  const handleDownload = (fileType) => {
    setLoading(fileType);

    const main = handler || downloadList;

    main({
      id: current?.id,
      file_type: fileType,
      name: current.name,
    })
      .then((res) => {
        notifications.show({
          message: lang.contact_lists.download_started,
          color: "tertiary.8",
        });
        setOpened(false);
        closeMenu?.();
      })
      .finally(() => setLoading(null));
  };

  return (
    <Popover
      position="left-start"
      opened={opened}
      onChange={setOpened}
      zIndex={200}
    >
      <Popover.Target>
        {isIcon ? (
          <Tooltip label={lang.contact_lists.download}>
            <ActionIcon variant="subtle" onClick={() => setOpened(true)}>
              <IconDownload size={16} />
            </ActionIcon>
          </Tooltip>
        ) : (
          <Button
            onClick={() => setOpened(true)}
            leftSection={<IconChevronLeft size={18} />}
            // rightSection={< size={14} />}
            fw={600}
            color="primary"
            variant="subtle"
            justify="start"
          >
            {lang.contact_lists.download}
          </Button>
        )}
      </Popover.Target>

      <Popover.Dropdown>
        <Text mb={"xs"}>{lang.contact_lists.download_text} </Text>
        <Stack gap={"sm"}>
          <Button
            variant="light"
            onClick={() => handleDownload("csv")}
            loading={loading === "csv"}
            color="tertiary.8"
          >
            CSV
          </Button>
          <Button
            variant="light"
            onClick={() => handleDownload("xlsx")}
            loading={loading === "xlsx"}
            color="tertiary.8"
          >
            EXCEL
          </Button>
          <Button
            variant="light"
            onClick={() => handleDownload("json")}
            loading={loading === "json"}
            color="tertiary.8"
          >
            JSON
          </Button>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
};

export default ListDownload;
