import {
  Button,
  Group,
  List,
  Paper,
  Stack,
  Text,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import { getNodeDetails } from "../flowSettings";
import {
  IconAlertTriangle,
  IconExclamationCircle,
  IconSquareRoundedArrowRight,
} from "@tabler/icons-react";
import { useLanguage } from "../../../../../../stores/LanguageStore";
import { useFlowStore } from "../../../../../../stores/WorkflowStore";
import { useMemo } from "react";

const unsavable = [
  "time_sending_error",
  "email_answered_but_answerers_excluded",
];

const NodeErrors = ({ errors, handleContinue, reactFlowInstance }) => {
  const lang = useLanguage((s) => s.language);

  const setSelectedNode = useFlowStore((s) => s.setSelectedNode);

  const handleView = ({ id, isEvent, toAdvanced }) => {
    modals.closeAll();
    setSelectedNode(isEvent ? null : { id, toAdvanced: toAdvanced });

    setTimeout(() => {
      reactFlowInstance?.fitView({ nodes: [{ id }], duration: 500 });
    }, 250);
  };

  const hasUnsavable = useMemo(() => {
    let allErrors = errors.reduce(
      (accumulator, currentValue) => [...accumulator, ...currentValue.errors],
      []
    );

    return allErrors.some((element) => unsavable.includes(element));
  }, [errors]);

  return (
    <>
      <Text size="sm" mb={"sm"}>
        {lang.campaign.nodes.errors.title}
      </Text>
      <Stack gap={24} mah={"65vh"} pb={8} style={{ overflow: "auto" }}>
        {errors.map((nodeError) => {
          const nodeDetail = getNodeDetails(nodeError.type);
          return (
            <Paper
              component={Stack}
              key={nodeError?.id}
              withBorder
              p={"sm"}
              gap={0}
              radius={"md"}
              shadow="sm"
              style={{
                borderColor: "#ff000033",
              }}
            >
              <Group mb={"sm"}>
                <ThemeIcon variant="light" color={nodeDetail.color} size={"md"}>
                  {nodeDetail.icon}
                </ThemeIcon>
                <Text fz={"sm"} fw={500}>
                  {nodeDetail.label}
                </Text>
                <Text fz={"sm"} opacity={0.5}>
                  #{nodeError.id}
                </Text>

                <Button
                  ml={"auto"}
                  variant="light"
                  rightSection={<IconSquareRoundedArrowRight size={18} />}
                  size="compact-sm"
                  onClick={() =>
                    handleView({
                      id: nodeError.id,
                      isEvent: nodeError.type === "event",
                      toAdvanced:
                        nodeError?.errors?.includes("time_sending_error"),
                    })
                  }
                >
                  {lang.campaign.nodes.errors.see}
                </Button>
              </Group>
              <List center spacing={"sm"}>
                {nodeError?.errors?.map((error_key, i) => (
                  <List.Item
                    key={i}
                    fz={"sm"}
                    icon={
                      unsavable.includes(error_key) ? (
                        <Tooltip label={lang.campaign.nodes.errors.critical}>
                          <IconAlertTriangle size={16} color="red" />
                        </Tooltip>
                      ) : (
                        <Tooltip label={lang.campaign.nodes.errors.optional}>
                          <IconExclamationCircle size={17} color="orange" />
                        </Tooltip>
                      )
                    }
                    c={unsavable.includes(error_key) ? "red" : undefined}
                  >
                    {lang.errors.main[error_key]}
                  </List.Item>
                ))}
              </List>
            </Paper>
          );
        })}
      </Stack>
      <Group justify="end" mt={"md"}>
        <Button variant="subtle" onClick={() => modals.closeAll()}>
          {lang.global.close}
        </Button>
        <Tooltip
          label={lang.campaign.nodes.errors.cant_save}
          disabled={!hasUnsavable}
          ta={"center"}
        >
          <Button
            onClick={() => {
              modals.closeAll();
              handleContinue();
            }}
            variant="outline"
            disabled={hasUnsavable}
          >
            {lang.campaign.nodes.errors.save_anyway}
          </Button>
        </Tooltip>
      </Group>
    </>
  );
};

export default NodeErrors;
