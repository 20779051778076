import { useCallback, useEffect, useState } from "react";
import {
  findMEmail,
  findMEmailAndGenerate,
} from "../../api/ModelsApi";
import {
  ActionIcon,
  Divider,
  Group,
  Loader,
  LoadingOverlay,
  Paper,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import { replaceTokens } from "./previewUtils";
import { findSignature } from "../../api/ConfigsApi";
import { useLanguage } from "../../stores/LanguageStore";
import { IconRotateClockwise } from "@tabler/icons-react";
import ModelEditor from "../Adder/ModelEditor";

const EmailPreviewer = ({
  model_id,
  subjectSize = "md",
  iframeHeight = 300,
  paperShadow = "sm",
  iframeStyle,
  html,
  subject,
  signatureId,
  disableSubject,
  contact,
  paperStyle,
  noSubjectTooltip,
  withEditor,
}) => {
  const lang = useLanguage((s) => s.language);
  const [modelProfile, setModelProfile] = useState({ html, subject });
  const [signProfile, setSignProfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingSignature, setLoadingSignature] = useState(false);

  const [loadingGen, setLoadingGen] = useState(false);
  const handleGen = useCallback(() => {
    setLoadingGen(true);
    setLoading(true);
    findMEmailAndGenerate({ id: model_id, contact_id: contact?.id })
      .then((res) => {
        setModelProfile(res.data.model_profile);
      })
      .finally(() => {
        setLoadingGen(false);
        setLoading(false);
      });
  }, [contact?.id, model_id]);

  useEffect(() => {
    if (loading) {
      if (model_id) {
        findMEmail(model_id)
          .then((response) => setModelProfile(response.data.model_profile))
          .finally(() => setLoading(false));
      } else if (!html) {
        setModelProfile(null);
        setLoading(false);
      }
    }
  }, [model_id, html, loading]);

  useEffect(() => {
    if (!html) {
      setLoading(true);
    }
  }, [model_id, html]);

  useEffect(() => {
    if (signatureId) {
      setLoadingSignature(true);
      findSignature(signatureId)
        .then((response) =>
          setSignProfile(response.data.email_signature_profile)
        )
        .finally(() => setLoadingSignature(false));
    } else {
      setSignProfile(null);
    }
  }, [signatureId]);

  useEffect(() => {
    if (modelProfile?.is_ai && contact?.id) {
      handleGen();
    }
  }, [contact, handleGen, modelProfile?.is_ai]);

  return (
    <Stack pos={"relative"} h={"100%"} flex={1}>
      <LoadingOverlay visible={loading || loadingGen} />
      {loadingSignature && (
        <Loader size={18} pos={"absolute"} top={52} right={8} />
      )}
      <Paper
        component={Stack}
        withBorder
        shadow={paperShadow}
        h={"100%"}
        flex={1}
        gap={0}
        {...paperStyle}
      >
        {!disableSubject && (
          <>
            <Text size={subjectSize} ml={"xs"} mt={6}>
              {lang.model_emails.create.dialog.subject} :{" "}
              {modelProfile?.is_ai && !modelProfile?.subject ? (
                "[ GENERATED SUBJECT ]"
              ) : (
                <b>
                  {replaceTokens({ text: modelProfile?.subject, contact, shouldEncode: false }) ||
                    ""}
                </b>
              )}
            </Text>
            <Divider mt={6} />
          </>
        )}

        {modelProfile?.is_ai && (
          <>
            <Group pos={"relative"} gap={0}>
              <Tooltip label={modelProfile?.prompt} disabled={noSubjectTooltip}>
                <Text
                  size={subjectSize}
                  ml={"xs"}
                  mt={6}
                  flex={1}
                  truncate={!noSubjectTooltip}
                  mr={16}
                >
                  Prompt : <b>{modelProfile?.prompt}</b>
                </Text>
              </Tooltip>

              {withEditor && (
                <ModelEditor
                  model={modelProfile}
                  onModelEdited={({ closer }) => {
                    closer?.();
                    setLoading(true);
                    handleGen?.();
                  }}
                  type={"email"}
                  variant="light"
                  radius={0}
                />
              )}
              {contact && (
                <Tooltip label="Regénérer le modèle">
                  <ActionIcon
                    top={0}
                    right={0}
                    radius={0}
                    size={"lg"}
                    h={"100%"}
                    loading={loadingGen}
                    onClick={handleGen}
                  >
                    <IconRotateClockwise size={18} />
                  </ActionIcon>
                </Tooltip>
              )}
            </Group>
            <Divider />
          </>
        )}

        <Stack
          style={{ overflow: "auto" }}
          h={disableSubject ? "100%" : "calc(100% - 35px)"}
          flex={1}
        >
          <iframe
            title={modelProfile?.subject}
            srcDoc={`<div style="font-family: sans-serif;">${
              replaceTokens({
                text:
                  modelProfile?.is_ai && !contact
                    ? "[ GENERATED CONTENT ]"
                    : modelProfile?.html,
                signHtml: signProfile?.html,
                contact,
              }) || ""
            }</div>`}
            style={{
              height: iframeHeight,
              width: "100%",
              border: "none",
              ...iframeStyle,
            }}
            sandbox=""
          />
        </Stack>
      </Paper>
    </Stack>
  );
};

export default EmailPreviewer;
