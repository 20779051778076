import { Anchor, Box, Group, Stack, Text, Title } from "@mantine/core";
import { Logo } from "../../../layouts/Logo";
import { Link, Navigate } from "react-router-dom";
import SignupForm from "./SignupForm";
import LanguageChanger from "../LanguageChanger";
import { useSessionStore } from "../../../stores/UserStore";
import { useLanguage } from "../../../stores/LanguageStore";
import { lazy } from "react";

const SignupIllustration = lazy(() => import("./SignupIllustration"));

const Signup = () => {
  const lang = useLanguage((s) => s.language);
  const session = useSessionStore((s) => s.session);
  const params = window.location.href.split("?")?.[1];

  if (session && !params?.includes("destination"))
    return <Navigate to={"/" + (params ? `?${params}` : "")} />;

  return (
    <Group w={"100vw"} h={"100vh"} grow gap={0}>
      <Stack
        bg={"var(--mantine-color-body)"}
        h={"100%"}
        align="center"
        justify="center"
        gap={12}
        pos={"relative"}
      >
        <Logo pos="absolute" left={16} top={16} w={200} />
        <Title order={2} className={`animate__animated animate__fadeIn`}>
          {lang.signup.title}
        </Title>
        <Text
          className={`animate__animated animate__fadeInUp`}
          style={{ animationDelay: "1.1s" }}
        >
          {lang.signup.subtitle}
        </Text>

        <SignupForm />

        <Anchor
          component={Link}
          to={"/login"}
          size="sm"
          fw={"bold"}
          className={`animate__animated animate__fadeInUp`}
          style={{ animationDelay: ".4s" }}
        >
          {lang.signup.already_signed}
        </Anchor>

        <LanguageChanger />
      </Stack>

      <Stack
        bg={"#f7f7f7"}
        h={"100%"}
        align="center"
        justify="center"
        w={"100%"}
      >
        <Box
          w={"90%"}
          className={`animate__animated animate__fadeIn`}
          style={{ animationDelay: ".25s" }}
        >
          <SignupIllustration />
        </Box>
      </Stack>
    </Group>
  );
};

export default Signup;
