import { useDisclosure } from "@mantine/hooks";
import { useState } from "react";
import { useLanguage } from "../../../stores/LanguageStore";
import { notifications } from "@mantine/notifications";
import { inviteToOrg, inviteToTeam } from "../../../api/UserAPI";
import {
  ActionIcon,
  Button,
  Group,
  Modal,
  Stack,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { IconMailUp } from "@tabler/icons-react";
import BackButton from "../../../components/View/BackButton";

const InviteToCommunity = ({ current, isOrg, isAction }) => {
  const lang = useLanguage((s) => s.language);
  const [opened, { open, close }] = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [mailError, setMailError] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const fd = new FormData(e.target);
    const data = Object.fromEntries(fd);

    const main = isOrg ? inviteToOrg : inviteToTeam;

    setLoading(true);
    main({ team_id: current?.id, email: data.email, org_id: current.id })
      .then((response) => {
        if (response.data.state) {
          notifications.show({
            message: lang.teams.invitation_sent,
            color: "tertiary.8",
          });
          close();
        }
      })
      .catch((err) => {
        let message = err.response.data.state_message;
        document.getElementById("email-input").focus();
        if (message.includes("mail")) {
          setMailError(lang.errors.helper_texts.alreadyInTeam);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {isAction ? (
        <Tooltip label={"Inviter quelqu'un par email"}>
          <ActionIcon variant="subtle" color="primary" onClick={open}>
            <IconMailUp size={18} />
          </ActionIcon>
        </Tooltip>
      ) : (
        <Button onClick={open} variant="light" color="primary" radius={"sm"} leftSection={<IconMailUp size={18} />}>
          Inviter par email
        </Button>
      )}

      {opened && (
        <Modal
          opened={opened}
          size={"md"}
          onClose={close}
          title={
            <>
              Inviter à rejoindre l'{isOrg ? "organisation" : "équipe"}{" "}
              <b>
                {current?.name} #{current.id}
              </b>
            </>
          }
          styles={{
            title: {
              lineHeight: 1.5,
            },
          }}
        >
          <Stack component={"form"} onSubmit={handleSubmit} gap={"xs"}>
            <Text c={"dimmed"} size="sm">
              Inviter quelqu'un par email à joindre cette communauté, s'il n'a
              pas encore de compte il pourra en créer.
            </Text>

            <TextInput
              label="Email"
              placeholder="email@example.com"
              name="email"
              required
              autoFocus
              error={mailError}
            />

            <Group justify="end" mt={"md"}>
              <BackButton onClick={close} />

              <Button
                leftSection={<IconMailUp size={18} />}
                loading={loading}
                color="primary"
                type="submit"
              >
                Inviter
              </Button>
            </Group>
          </Stack>
        </Modal>
      )}
    </>
  );
};

export default InviteToCommunity;
