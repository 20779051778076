import {
  Button,
  ColorInput,
  Group,
  Stack,
  Text,
} from "@mantine/core";
import { IconArrowRight } from "@tabler/icons-react";
import { useLanguage } from "../../../../stores/LanguageStore";

const Step3 = ({
  setStep,
  colorPrimary,
  setColorPrimary,
  colorSecondary,
  setColorSecondary,
}) => {
  const lang = useLanguage((s) => s.language);

  return (
    <Stack w={"50%"}>
      <Text
        fz={24}
        ta={"center"}
        className={`animate__animated animate__fadeIn`}
      >
        {lang.resellers.onboarding.step3.title}
      </Text>

      <Group w={"100%"} justify="center" gap={"xl"}>
        <ColorInput
          name="color_primary"
          label={lang.resellers.create.dialog.primary_color}
          value={colorPrimary}
          onChange={setColorPrimary}
          className={`animate__animated animate__fadeIn`}
          style={{ animationDelay: ".1s" }}
          format="hex"
          size="md"
          swatches={[
            "#2e2e2e",
            "#868e96",
            "#fa5252",
            "#e64980",
            "#be4bdb",
            "#7950f2",
            "#4c6ef5",
            "#228be6",
            "#15aabf",
            "#12b886",
            "#40c057",
            "#82c91e",
            "#fab005",
            "#fd7e14",
          ]}
        />
        <ColorInput
          name="color_secondary"
          label={lang.resellers.create.dialog.secondary_color}
          value={colorSecondary}
          onChange={setColorSecondary}
          className={`animate__animated animate__fadeIn`}
          style={{ animationDelay: ".2s" }}
          format="hex"
          size="md"
          swatches={[
            "#2e2e2e",
            "#868e96",
            "#fa5252",
            "#e64980",
            "#be4bdb",
            "#7950f2",
            "#4c6ef5",
            "#228be6",
            "#15aabf",
            "#12b886",
            "#40c057",
            "#82c91e",
            "#fab005",
            "#fd7e14",
          ]}
        />
      </Group>

      <Group justify="space-between" w={"100%"} mt={"sm"}>
        <Button variant="subtle" onClick={() => setStep(2)}>
          {lang.global.back}
        </Button>
        <Button
          rightSection={<IconArrowRight />}
          className={`animate__animated animate__fadeIn`}
          style={{ animationDelay: ".3s" }}
          variant="gradient"
          onClick={() => setStep(4)}
        >
          {lang.global.next}
        </Button>
      </Group>
    </Stack>
  );
};

export default Step3;
