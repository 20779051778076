import {
  ActionIcon,
  Button,
  Group,
  Input,
  Modal,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { IconPlus, IconTrash } from "@tabler/icons-react";
import { executeSequentially } from "../../../../../functions/utilFunctions";
import LinksLoading from "./LinksLoading";

export async function handleLinks({
  links,
  data,
  handler,
  setStep,
  data_key,
  onFinish,
  onError,
}) {
  let requests = [];

  for (let i = 0; i < links.length; i++) {
    requests.push(() =>
      handler({
        ...data,
        [data_key]: links[i],
        contact_list_id: data?.contact_list_id,
        contact_list_name: i === 0 ? data?.contact_list_name : null,
      })
    );
  }

  await executeSequentially(
    requests,
    (result) => {
      data.contact_list_id = result.data.contact_list_id;
      setStep((prev) => prev + 1);
    },
    (err) => onError(err)
  );

  onFinish?.(data.contact_list_id);
}

const LinkItem = ({ value, handleDelete }) => {
  return (
    <Stack
      style={{
        border: "#00000021 solid 1px",
        padding: 8,
        paddingRight: 8,
        position: "relative",
        marginBottom: 1,
        borderRadius: 4,
      }}
    >
      <Text
        size="sm"
        maw={500}
        style={{
          textWrap: "nowrap",
        }}
        truncate
      >
        {value}
      </Text>

      <ActionIcon
        variant="subtle"
        color="red"
        className="on-hover-opacity"
        style={{
          position: "absolute",
          right: 8,
          top: "50%",
          transform: "translateY(-50%)",
          minWidth: 0,
        }}
        onClick={handleDelete}
      >
        <IconTrash size={16} />
      </ActionIcon>
    </Stack>
  );
};

const LinkedinLinks = ({
  links,
  addLink,
  removeLink,
  placeholder,
  loading,
  step,
}) => {
  const lang = useLanguage((s) => s.language);
  return (
    <>
      <TextInput
        label={lang.targeting_linkedin_google.search_url}
        id="url-search"
        autoFocus
        w={"100%"}
        placeholder={placeholder}
        // error={error === "invalid_google_map_search_url"}
        type="url"
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            addLink(document.getElementById("url-search").value);
          }
        }}
        rightSection={
          <Button
            leftSection={<IconPlus />}
            variant="subtle"
            onClick={() => addLink(document.getElementById("url-search").value)}
            ml={"auto"}
            disabled={links.length > 4}
          >
            {lang.targeting_linkedin_google.add}
          </Button>
        }
        rightSectionWidth={110}
      />
      <Stack gap={0} mt={"md"}>
        <Group w={"100%"}>
          <Input.Label>
            {links.length} {lang.targeting_linkedin_google.added_links}{" "}
            <span style={{ opacity: 0.45, fontSize: 10 }}>{"max: 5"}</span>
          </Input.Label>
        </Group>

        <Stack
          style={{
            border: "1px solid #00000021",
            overflow: "auto",
            borderRadius: 4,
          }}
          h={192}
          p={"xs"}
          gap={"xs"}
          mt={6}
        >
          {links.length > 0 ? (
            links.map((link) => (
              <LinkItem
                value={link}
                key={link}
                handleDelete={() => removeLink(link)}
              />
            ))
          ) : (
            <Text opacity={0.5} size="sm">
              {lang.targeting_linkedin_google.no_links}
            </Text>
          )}
        </Stack>
      </Stack>

      <Modal
        opened={loading}
        title={lang.contact_lists.import.loading.title}
        size={"xl"}
        withCloseButton={false}
      >
        <LinksLoading links={links} step={step} />
      </Modal>
    </>
  );
};

export default LinkedinLinks;
