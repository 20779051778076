import { Group, Loader, Stack } from "@mantine/core";
import { Logo } from "../../../layouts/Logo";
import { useSessionStore } from "../../../stores/UserStore";
import { useUIStore } from "../../../stores/UIStore";

const FallbackPage = ({ loading, outside }) => {
  const reseller = useSessionStore((s) => s.reseller);
  const windowLoading = useUIStore((s) => s.windowLoading);

  return (
    <Stack
      w={"100vw"}
      h={"100vh"}
      align="center"
      justify="center"
      pt={21}
      pos={"fixed"}
      top={0}
      left={0}
      style={{
        zIndex: 9999,
        transition: "all ease .8s",
        visibility: windowLoading || loading ? "visible" : "collapse",
        transitionDelay: ".5s",
      }}
      bg={
        reseller?.name === "Magileads"
          ? "white"
          : reseller?.color_muted !== "A8A8A8"
          ? `#${reseller?.color_muted}`
          : "secondary.6"
      }
      opacity={windowLoading || loading ? 1 : 0}
    >
      <Group
        w={300}
        justify="center"
        style={{ transition:  "all ease .6s" }}
        opacity={windowLoading || loading ? 1 : 0}
      >
        {reseller ? (
          <Logo
            h={90}
            className={"animate__animated animate__pulse animate__infinite"}
            isLoader
          />
        ) : (
          <Loader size={64} />
        )}
      </Group>
    </Stack>
  );
};

export default FallbackPage;
