import { Button, Group, Modal, Text, Tooltip } from "@mantine/core";
import { useLanguage } from "../../stores/LanguageStore";
import { IconShare } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import { useForm } from "react-hook-form";
import SharingController from "../Sharing/SharingController";
import { useState } from "react";
import { notifications } from "@mantine/notifications";

const SelectionSharer = ({
  handleShare,
  getEditFunction,
  ids,
  apiRef,
  setTableLoading,
}) => {
  const lang = useLanguage((s) => s.language);
  const [opened, { open, close }] = useDisclosure();
  const [loading, setLoading] = useState(false);

  const sharingForm = useForm({
    defaultValues: {
      sharing: [],
    },
  });

  const main = () => {
    setTableLoading?.(true);
    setLoading(true);

    const newSharing = sharingForm
      .getValues()
      .sharing.filter((x) => x.sharing_targets.length > 0)
      .map((share) => ({
        ...share,
        name: "multiple",
        ids_list: share.sharing_targets.map((x) => x.id),
        sharing_targets: share.sharing_targets.map((x) => ({
          ...x,
          name: x.option_name,
        })),
      }));

    let data = apiRef.current.getSelectedRows();
    const lists = Array.from(data, ([name, value]) => [value]).flatMap(
      (x) => x
    );

    let requests = [];
    ids?.forEach((id) => {
      requests.push(
        new Promise((resolve, reject) => {
          let current = lists.find((x) => x.id === id);

          let editFunction = getEditFunction
            ? getEditFunction(current)
            : handleShare;
          editFunction({
            id,
            sharing: [...current.sharing, ...newSharing],
          }).then((res) => {
            document.querySelectorAll(`[data-id='${id}']`).forEach((el) => {
              el.style.animation = `forwards 1.2s highlightAnim ease`;
            });
            setTimeout(() => {
              apiRef.current?.updateRows([
                {
                  id: current.id,
                  sharing: [...current.sharing, ...newSharing],
                },
              ]);
            }, 50);
            document.querySelectorAll(`[data-id='${id}']`).forEach((el) => {
              el.style.animation = `none`;
            });
            resolve();
          });
        })
      );
    });

    Promise.all(requests).finally(() => {
      setTableLoading?.(false);
      sharingForm.reset();
      notifications.show({
        message: lang.table.toolbar.bulk_share.success,
        color: "green.8",
      });
    });
    close();
    setLoading(false);
  };

  return (
    <>
      <Tooltip label={lang.table.toolbar.bulk_share.button_tooltip}>
        <Button
          className="toolbar-action-button"
          variant="light"
          disabled={ids.length === 0}
          onClick={open}
        >
          <IconShare size={18} />
        </Button>
      </Tooltip>

      <Modal
        opened={opened}
        onClose={close}
        title={
          <Text size="md" truncate="end">
            {lang.table.toolbar.bulk_share.title}{" "}
            <b>
              {" "}
              {ids.length} {lang.components.deletion_action.elements}{" "}
            </b>
          </Text>
        }
        size={"60%"}
        centered
        styles={{
          title: {
            maxWidth: "92%",
          },
        }}
      >
        <SharingController form={sharingForm} />

        <Group justify="end" mt={"sm"}>
          <Button variant="subtle" onClick={close}>
            {lang.components.deletion_action.dialog.cancel_button}
          </Button>
          <Button
            onClick={main}
            leftSection={<IconShare size={18} />}
            loading={loading}
          >
            {lang.table.toolbar.bulk_share.submit_button}
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export default SelectionSharer;
