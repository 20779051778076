import { useMemo, useState } from "react";
import { useConfigStore } from "../../stores/ConfigStore";
import { getBlacklists } from "../../api/BlacklistAPI";
import { parseDate } from "../../functions/dates";
import MultiSelector from "./MultiSelector";

function getOptionName(option) {
  return `${option.name} #${option.id} (${option.number_of_data})`;
}

const BlacklistSelector = ({
  label,
  placeholder,
  size,
  values,
  onChange,
  disabled,
  leftSection,
  inputHeight = 102,
  dropdownHeight = 150,
}) => {
  const blacklists = useConfigStore((s) => s.blacklists);
  const [loading, setLoading] = useState(false);
  const handleLoad = () => {
    setLoading(true);
    getBlacklists().finally(() => setLoading(false));
  };

  const options = useMemo(() => {
    return blacklists.map((list) => ({
      ...list,
      option_name: getOptionName(list),
    }));
  }, [blacklists]);

  return (
    <MultiSelector
      options={options}
      label={label}
      onDropdownOpen={handleLoad}
      loading={loading}
      values={
        values
          ?.map((listId) => {
            let list = blacklists?.find((x) => x.id === listId);
            if (list) {
              return {
                ...list,
                option_name: getOptionName(list),
              };
            }
            return;
          })
          ?.filter(Boolean) || []
      }
      onChange={onChange}
      placeholder={placeholder}
      leftSection={leftSection}
      rightOptionSection={(item) => {
        return (
          <>
            {item?.created_on && (
              <span style={{ fontSize: 10, opacity: 0.35, width: 105 }}>
                {parseDate(item?.created_on)}
              </span>
            )}
            {item?.id && (
              <span style={{ fontSize: 10, opacity: 0.35 }}>#{item?.id}</span>
            )}
          </>
        );
      }}
      size={size}
      disabled={disabled}
      w={"100%"}
      searchable
      checkIconPosition="left"
      dropdownHeight={dropdownHeight}
      styles={{
        input: {
          borderColor: "var(--mantine-primary-color-4)",
          borderWidth: 2,
          minHeight: inputHeight,
        },
      }}
      autoFocus={false}
    />
  );
};

export default BlacklistSelector;
