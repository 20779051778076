import { useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { useForm } from "react-hook-form";
import { createMassMailing } from "../../../../../api/EmailAPI";
import { notifications } from "@mantine/notifications";
import useNavigationBlocker from "../../../../../components/View/NavigationBlocker";
import {
  Accordion,
  Button,
  Group,
  Stack,
  TagsInput,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import BackButton from "../../../../../components/View/BackButton";
import { IconPlus, IconSignature } from "@tabler/icons-react";
import { SharingInAccordion } from "../../../../../components/Sharing/SharingController";
import { useNavigate } from "react-router-dom";
import Path from "../../../../../layouts/Path";
import CreateEmailSender from "../CreateEmailSender";
import SignatureSelector from "../../../../../components/Selectors/SignatureSelector";

const CreateMassMailing = () => {
  const lang = useLanguage((s) => s.language);
  const navigate = useNavigate();
  const [dirty, setDirty] = useState(false);
  const [sign, setSign] = useState(null);
  const sharingForm = useForm({
    defaultValues: {
      sharing: [],
    },
  });

  const [loading, setLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    let fd = new FormData(e.target);
    let data = Object.fromEntries(fd);

    const sharing = sharingForm
      .getValues()
      .sharing.filter((x) => x.sharing_targets.length > 0)
      .map((share) => ({
        ...share,
        ids_list: share.sharing_targets.map((x) => x.id),
        sharing_targets: undefined,
      }));

    setLoading(true);
    createMassMailing({
      name: data?.name,
      email: data?.email,
      sender: data?.sender,
      reply_to: data?.reply_to ? data?.reply_to.split(",") : null,
      email_signatures_id: sign,
      sharing,
    })
      .then((response) => {
        setDirty(false);
        setTimeout(() => {
          notifications.show({
            message: lang.emails_account.row_highlight.created,
            color: "tertiary.8",
          });
          navigate(
            "/senders/email/?highlight=" + response.data.email_account_id
          );
        }, 300);
      })
      .finally(() => setLoading(false));
  };

  useNavigationBlocker(dirty);

  return (
    <>
      <Group className="layout-block top" w={"100%"} justify="space-between">
        <Path />
        <CreateEmailSender />
      </Group>
      <Stack
        className="layout-block"
        w={"40%"}
        mx={"auto"}
        gap={"xs"}
        component={"form"}
        onSubmit={handleSubmit}
        onChange={() => setDirty(true)}
      >
        <Title order={5}>{lang.emails_account.mass_mailing.create.title}</Title>

        <Text>{lang.emails_account.mass_mailing.create.helper_text}</Text>
        <TextInput
          label={lang.emails_account.mass_mailing.create.name}
          placeholder={lang.emails_account.mass_mailing.create.name}
          name="name"
          required
          autoFocus
        />
        <Group wrap="nowrap" w={"100%"}>
          <TextInput
            type="email"
            label={lang.emails_account.mass_mailing.create.email}
            placeholder={lang.emails_account.mass_mailing.create.email}
            name="email"
            required
            w={"100%"}
          />
          <TextInput
            placeholder={lang.emails_account.mass_mailing.create.sender}
            label={lang.emails_account.mass_mailing.create.sender}
            name="sender"
            required
            w={"100%"}
          />
        </Group>

        <TagsInput
          name="reply_to"
          label={lang.emails_account.mass_mailing.reply_to.title}
          placeholder="Appuyez sur Entrée pour ajouter"
          description="Entrée pour ajouter"
          mb={"sm"}
        />

        <Accordion variant="contained" mb={"sm"}>
          <Accordion.Item value={"send"}>
            <Accordion.Control
              icon={<IconSignature size={16} />}
              px={"xs"}
              bg={"var(--mantine-color-gray-0)"}
            >
              {lang.emails_account.smtp.step_pools_signs.signature}
            </Accordion.Control>
            <Accordion.Panel>
              <SignatureSelector
                value={sign}
                onChange={(v) => setSign(v?.id || null)}
              />
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>

        <SharingInAccordion form={sharingForm} />

        <Group justify="right" mt={"sm"}>
          <BackButton />
          <Button
            type="submit"
            leftSection={<IconPlus size={18} />}
            loading={loading}
          >
            {lang.emails_account.create.dialog.submit_button}
          </Button>
        </Group>
      </Stack>
    </>
  );
};

export default CreateMassMailing;
