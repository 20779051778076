import { useState } from "react";
import { useLanguage } from "../../../stores/LanguageStore";
import { updateSelf } from "../../../api/UserAPI";
import { notifications } from "@mantine/notifications";
import { Button, Group, Modal, Stack, Text, Tooltip } from "@mantine/core";
import { IconCreditCardOff, IconUserX } from "@tabler/icons-react";
import BackButton from "../../../components/View/BackButton";
import { unsubscribe } from "../../../api/SubscriptionAPI";

const StopSubscription = ({ profile }) => {
  const lang = useLanguage((s) => s.language);
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpen = () => {
    setOpened(true);
  };

  const handleClose = () => {
    setOpened(false);
  };

  const submitDelete = () => {
    setLoading(true);
    unsubscribe()
      .then((res) => {
        notifications.show({
          message: lang.profile.unsub.success_text,
          color: "green.8",
        });
        handleClose();
        updateSelf(true);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Tooltip label={lang.profile.unsub.button_tooltip}>
        <Button
          color="red"
          variant="light"
          onClick={handleOpen}
          leftSection={<IconUserX size={20} />}
          disabled={!profile?.subscriptions?.subscription_id}
        >
          {lang.profile.unsub.button_label}
        </Button>
      </Tooltip>

      <Modal
        opened={opened}
        onClose={handleClose}
        centered
        title={<Text>{lang.profile.unsub.dialog_title}</Text>}
        styles={{
          content: {
            boxShadow: "red 0 0 6px",
            border: "1px solid #a00",
          },
          title: {
            maxWidth: "92%",
          },
        }}
        size={"md"}
      >
        <Stack>
          <Text>{lang.profile.unsub.dialog_text}</Text>

          <Group justify="end" mt={"md"}>
            <BackButton onClick={handleClose} />
            <Button
              onClick={submitDelete}
              loading={loading}
              color="red"
              leftSection={<IconCreditCardOff size={18} />}
            >
              {lang.profile.unsub.submit_button}
            </Button>
          </Group>
        </Stack>
      </Modal>
    </>
  );
};

export default StopSubscription;
