import { useEffect } from "react";
import { useRouteLoaderData } from "react-router-dom";
import { findFolder } from "../../api/FolderAPI";
import { useUIStore } from "../../stores/UIStore";
import { findPool } from "../../api/EmailAPI";

export const useListingLoader = ({
  loading,
  setLoading,
  setRows,
  responseField,
  listGetter,
  folderRouteId,
  isPool,
  getRows,
  folderId,
  onGet,
}) => {
  const folderProfile = useRouteLoaderData(folderRouteId);

  useEffect(() => {
    if (loading) {
      let mainFunction =
        folderProfile || folderId
          ? isPool
            ? findPool
            : findFolder
          : listGetter;
      mainFunction(folderProfile?.id || folderId)
        .then((response) => {
          if (folderProfile?.id || folderId) {
            if (getRows) {
              return setRows(getRows(response.data.folder_profile.items));
            }
            setRows(
              response.data?.[isPool ? "pool_profile" : "folder_profile"]?.[
                isPool ? "accounts_list" : "items"
              ]?.sort((a, b) => b.id - a.id)
            );
          } else {
            let result = response.data?.[responseField];

            onGet?.(result);
            if (getRows) {
              return setRows(getRows(result));
            }
            return setRows(result.sort((a, b) => b.id - a.id));
          }
        })
        .finally(() => setLoading(false));
    }
  }, [
    loading,
    listGetter,
    setRows,
    responseField,
    setLoading,
    folderProfile,
    isPool,
    folderId,
    getRows,
    onGet,
  ]);

  useEffect(() => {
    if (!folderProfile) {
      const setAsideLoading = useUIStore.getState().setAsideLoading;
      setAsideLoading(true);
    } else {
      setRows(
        folderProfile?.[isPool ? "accounts_list" : "items"]?.sort(
          (a, b) => b?.id - a?.id
        )
      );
    }
  }, [folderProfile]);
};
