import { ActionIcon, Group, Loader, Switch, Tooltip } from "@mantine/core";
import { IconCircleOff } from "@tabler/icons-react";
import { updateWarmup } from "../../../../../api/EmailAPI";
import { useEffect, useState } from "react";
import WarmupModal from "./WarmupModal";
import { IconTimeline } from "@tabler/icons-react";

const WarmupCell = ({ settings, row, apiRef }) => {
  const [switching, setSwitching] = useState(false);
  const [enabled, setEnabled] = useState(!!settings?.warmup_enabled);
  const onSwitch = (e) => {
    let newValue = e.target.checked;

    setSwitching(true);
    updateWarmup({ id: row.id, warmup_enabled: newValue })
      .then(() => setEnabled(newValue))
      .finally(() => setSwitching(false));
  };

  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setEnabled(!!settings?.warmup_enabled);
  }, [settings?.warmup_enabled]);

  return (
    <>
      <Group>
        <Switch
          color="tertiary.8"
          onChange={onSwitch}
          checked={enabled}
          disabled={switching}
          thumbIcon={
            !row.write_permission ? (
              <IconCircleOff size={12} color="red" />
            ) : switching ? (
              <Loader type="oval" size={9} />
            ) : undefined
          }
        />

        <Tooltip label={"Statistiques & Paramètres du Warm-up"}>
          <ActionIcon
            variant="light"
            size={"sm"}
            onClick={() => setOpened(true)}
          >
            <IconTimeline size={20} />
          </ActionIcon>
        </Tooltip>
      </Group>

      {opened && (
        <WarmupModal
          handleClose={() => setOpened(false)}
          row={row}
          apiRef={apiRef}
        />
      )}
    </>
  );
};

export default WarmupCell;
