import { useMemo, useState } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { IconCategory, IconCheck, IconTrash, IconX } from "@tabler/icons-react";
import { deletePermission, updatePermission } from "../../../../api/AdminAPI";
import { notifications } from "@mantine/notifications";
import { updateSelf } from "../../../../api/UserAPI";
import { useProfileStore } from "../../../../stores/UserStore";
import { defaultPermissionsValue } from "./defaultPermissions";
import {
  ActionIcon,
  Anchor,
  Divider,
  Group,
  Loader,
  SimpleGrid,
  Stack,
  Switch,
  Tooltip,
} from "@mantine/core";

const icon_props = {
  size: 18,
};

const Interface = ({ profile, setProfile }) => {
  const lang = useLanguage((s) => s.language);
  const me = useProfileStore((s) => s.profile);

  const rights = useMemo(
    () => ({
      menus: [
        {
          label: lang.administration.right.rights.list.accessTargeting,
          description:
            lang.administration.right.rights.list.accessTargeting_tooltip,
          value: "displaySideMenu",
        },
        {
          label: lang.administration.right.rights.list.accessWorkflows,
          description:
            lang.administration.right.rights.list.accessWorkflows_tooltip,
          value: "accessWorkflows",
        },
        {
          label: lang.administration.right.rights.list.accessContactLists,
          description:
            lang.administration.right.rights.list.accessContactLists_tooltip,
          value: "accessContactLists",
        },
        {
          label: lang.administration.right.rights.list.accessModels,
          description:
            lang.administration.right.rights.list.accessModels_tooltip,
          value: "accessModels",
        },
        {
          label:
            lang.administration.right.rights.list.displayDetailedStatistics,
          description:
            lang.administration.right.rights.list
              .displayDetailedStatistics_tooltip,
          value: "accessReporting",
        },
        {
          label: lang.administration.right.rights.list.accessPRM,
          description: lang.administration.right.rights.list.accessPRM_tooltip,
          value: "accessPRM",
        },
        {
          label: lang.administration.right.rights.list.displayPricing,
          description:
            lang.administration.right.rights.list.displayPricing_tooltip,
          value: "displayPricing",
        },
        {
          label: lang.administration.right.rights.list.displayResellers,
          description:
            lang.administration.right.rights.list.displayResellers_tooltip,
          value: "displayResellers",
        },
      ],
    }),
    [lang]
  );

  const rightsTitles = useMemo(
    () => ({
      menus: {
        title: lang.administration.right.titles.menus,
        icon: <IconCategory {...icon_props} />,
      },
    }),
    [lang]
  );

  const [loading, setLoading] = useState(false);

  const handleCheck = ({ value, checked }) => {
    setLoading(value);
    updatePermission({
      permission_name: value,
      user_id: profile?.id,
      value: checked,
    })
      .then((response) => {
        notifications.show({
          message: lang.administration.right.rights.changed,
          color: "tertiary.8",
        });
        if (profile.id === me.id) {
          updateSelf(true);
        }
        setProfile((prev) => {
          let index = profile.permissions.findIndex((x) => x.name === value);
          let newProfile = { ...prev };

          newProfile.permissions[index] = {
            ...newProfile.permissions[index],
            value: checked,
          };

          return newProfile;
        });
      })
      .finally(() => setLoading(null));
  };

  const handleDelete = (field) => {
    setLoading(field);
    deletePermission({
      permission_name: field,
      user_id: profile?.id,
    })
      .then((response) => {
        notifications.show({
          message: lang.administration.right.rights.deleted,
          color: "tertiary.8",
        });
        setProfile((prev) => {
          let index = profile.permissions.findIndex((x) => x.name === field);
          let newProfile = { ...prev };

          newProfile.permissions[index] = {
            ...newProfile.permissions[index],
            value: defaultPermissionsValue[field],
          };

          return newProfile;
        });
      })
      .finally(() => setLoading(null));
  };

  const getDisabled = (field) => {
    if (loading === field || loading) {
      return true;
    }
    if (me?.level === "admin") {
      return false;
    }
    if (!profile?.permissions?.find((x) => x.name === field)?.is_editable) {
      return true;
    }
    return false;
  };

  return (
    <Stack style={{ overflow: "auto" }} flex={"1 1 auto"} h={0}>
      {Object.keys(rights).map((key) => (
        <Stack key={key}>
          <Group>
            {rightsTitles[key]?.icon}
            <Anchor
              fw={500}
              href={rightsTitles[key]?.link}
              c="black"
              target="_blank"
            >
              {rightsTitles[key]?.title}
            </Anchor>
          </Group>
          <SimpleGrid cols={3}>
            {rights[key].map((item) => (
              <Group
                key={item?.value}
                className="hover-source"
                align="start"
                wrap="nowrap"
              >
                <Tooltip label={item?.description}>
                  <Switch
                    disabled={getDisabled(item.value)}
                    label={item.label}
                    onChange={(e) =>
                      handleCheck({
                        value: item?.value,
                        checked: e.target.checked,
                      })
                    }
                    checked={
                      profile?.permissions?.find((x) => x.name === item?.value)
                        ?.value === true
                    }
                    color="primary"
                    thumbIcon={
                      loading === item?.value ? (
                        <Loader type="oval" size={9} />
                      ) : profile?.permissions?.find(
                          (x) => x.name === item?.value
                        )?.value === true ? (
                        <IconCheck size={11} />
                      ) : (
                        <IconX size={11} color="red" />
                      )
                    }
                  />
                </Tooltip>
                <ActionIcon
                  disabled={getDisabled(item.value)}
                  color="red"
                  size={"sm"}
                  variant="light"
                  className="hover-target"
                  ml={"auto"}
                  onClick={(e) => handleDelete(item?.value)}
                >
                  <IconTrash size={16} />
                </ActionIcon>
              </Group>
            ))}
          </SimpleGrid>

          <Divider />
        </Stack>
      ))}
    </Stack>
  );
};

export default Interface;
