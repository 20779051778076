import {
  IconCheck,
  IconCopy,
  IconGift,
  IconShare,
  IconUserPlus,
} from "@tabler/icons-react";
import {
  Anchor,
  Box,
  Button,
  CopyButton,
  Divider,
  Group,
  Loader,
  Stack,
  Stepper,
  Text,
  TextInput,
  Title,
  rem,
} from "@mantine/core";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useEffect, useMemo, useState } from "react";
import { getReferredUsers } from "../../../../api/UserAPI";
import { useProfileStore, useSessionStore } from "../../../../stores/UserStore";
import PageTitle from "../../../../components/View/PageTitle";
import ReferredUser from "./ReferredUser";
import GiftIllustration from "./GiftIllustration";

const ReferralPage = () => {
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);
  const reseller = useSessionStore((s) => s.reseller);

  const value = useMemo(() => {
    return `https://${
      reseller?.domain_saas2 || reseller?.domain_saas
    }/signup?ref=${profile.id}`;
  }, [profile, reseller]);

  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  useEffect(() => {
    if (loading) {
      getReferredUsers()
        .then((res) => {
          setList(res.data.users_list);
        })
        .finally(() => setLoading(false));
    }
  }, [loading]);

  return (
    <>
      <Group
        className="layout-block top"
        w={"100%"}
        gap={0}
        justify="space-between"
      >
        <PageTitle title={lang.referral.title} />
      </Group>

      <Group
        className="layout-block"
        flex={1}
        wrap="nowrap"
        align="start"
        gap={0}
      >
        <Stack w={"40%"} gap={"xs"} h={"100%"}>
          <Box w={200} mt={8}>
            <GiftIllustration />
          </Box>
          <Title order={3}>{lang.referral.left.title}</Title>
          <Text size="sm">
            {lang.referral.left.text
              .replaceAll("#", reseller?.name)
              .replaceAll("$", profile?.first_name || "-")}
          </Text>

          {reseller.name === "Magileads" && (
            <Anchor
              href="https://www.youtube.com/watch?v=4iC3F7B4vdE"
              target="_blank"
              size="sm"
            >
              {lang.referral.left.watch}
            </Anchor>
          )}

          <Stepper
            mt={16}
            w={"100%"}
            orientation="vertical"
            flex={1}
            styles={{
              stepDescription: {
                marginTop: 10,
              },
            }}
          >
            <Stepper.Step
              icon={<IconShare size={18} />}
              label={lang.referral.left.step1}
              description={lang.referral.left.step1_text}
            />
            <Stepper.Step
              icon={<IconUserPlus size={18} />}
              label={lang.referral.left.step2}
              description={lang.referral.left.step2_text}
            />
            <Stepper.Step
              icon={<IconGift size={18} />}
              styles={{
                stepIcon: {
                  border: "1px solid var(--mantine-color-primary-6)",
                },
              }}
              label={lang.referral.left.step3}
              description={lang.referral.left.step3_text}
            />
          </Stepper>
        </Stack>
        <Divider orientation="vertical" ml={32} />
        <Stack flex={1} ml={32}>
          <TextInput
            label={lang.referral.your_link}
            size="md"
            value={value}
            w={"100%"}
            rightSectionWidth={100}
            rightSection={
              <CopyButton value={value}>
                {({ copied, copy }) => (
                  <Button
                    color={copied ? "teal" : "primary"}
                    radius="sm"
                    variant="filled"
                    onClick={copy}
                    rightSection={
                      copied ? (
                        <IconCheck
                          style={{ width: rem(18), height: rem(18) }}
                          stroke={1.5}
                        />
                      ) : (
                        <IconCopy
                          style={{ width: rem(18), height: rem(18) }}
                          stroke={1.5}
                        />
                      )
                    }
                  >
                    {copied ? lang.referral.copied : lang.referral.copy}
                  </Button>
                )}
              </CopyButton>
            }
          />

          {loading ? (
            <Group justify="center" mt={32}>
              <Loader size={48} />
            </Group>
          ) : (
            <Group justify="space-between" pos={"relative"} flex={1}>
              {list.length > 0 ? (
                list.map((item, i) => <ReferredUser item={item} key={i} />)
              ) : (
                <Text
                  ta={"center"}
                  style={{
                    opacity: 0.5,
                    position: "absolute",
                    left: "50%",
                    top: 150,
                    transform: "translate(-50%, -50%)",
                    fontSize: 18,
                  }}
                >
                  {lang.referral.no_found}
                </Text>
              )}
            </Group>
          )}
        </Stack>
      </Group>
    </>
  );
};

export default ReferralPage;
