import { useMemo, useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { useListStore } from "../../../../../stores/ListStore";
import { Group, Paper, Progress, Text, Tooltip } from "@mantine/core";
import { types } from "./jobTypes";
import { parseDate } from "../../../../../functions/dates";
import { handleJobItem } from "./JobItemArguments";
import StopJob from "./StopJob";
import { useProfileStore } from "../../../../../stores/UserStore";
import { getLimit } from "../../../../../functions/utilFunctions";

const JobItemInProgress = ({ item }) => {
  const lang = useLanguage((s) => s.language);
  const lists = useListStore((s) => s.lists);
  const profile = useProfileStore((s) => s.profile);
  const startDate = new Date(Date.parse(item.start_date));
  const queuedDate = new Date(Date.parse(item.queued_date));
  const [stopped, setStopped] = useState(false);
  const fromLists = useMemo(() => {
    if (item.type === "Merge") {
      return item.arguments.contactListIds.map((id) => {
        return lists?.find((x) => x.id === id) || { id };
      });
    }
    if (item.type === "Split") {
      return (
        lists?.find((x) => x.id === item.arguments.fromContactListId) || {
          id: item.arguments.fromContactListId,
        }
      );
    }
    return null;
  }, [lists, item]);

  const usedLinkedIn = useMemo(() => {
    return {
      used:
        getLimit("maxLinkedinInteractionsPerDay", profile, "value") -
        getLimit("maxLinkedinInteractionsPerDay", profile, "remaining"),
      limit: getLimit("maxLinkedinInteractionsPerDay", profile, "value"),
    };
  }, [profile]);

  return (
    <Paper withBorder p={"sm"} bg={"#00000006"}>
      <Group opacity={stopped ? 0.35 : 1}>
        <Group direction="row" gap={4} mr={1}>
          {types(item.type)?.icon}

          <Text fw={500} fz={14}>
            {types(item.type, false, item?.arguments?.generateEmail)?.label}
          </Text>
        </Group>
        <Text fz={10} style={{ opacity: 0.75 }}>
          <b>{lang.contact_lists.jobs.queued_date}</b>{" "}
          <Tooltip label={queuedDate.toLocaleString()}>
            <span style={{ marginRight: 8 }}>{parseDate(queuedDate)}</span>
          </Tooltip>{" "}
          <b>{lang.contact_lists.jobs.start_date}</b>{" "}
          <Tooltip label={startDate.toLocaleString()}>
            <span>{parseDate(startDate)}</span>
          </Tooltip>
        </Text>
      </Group>

      <Group mt={8} justify="space-between" gap={"sm"} wrap="nowrap">
        <Text style={{ textWrap: "nowrap" }}>{item.percent} %</Text>
        <Progress
          value={item.percent}
          color="tertiary.8"
          animated
          size={"lg"}
          transitionDuration={200}
          opacity={stopped ? 0.35 : 1}
          w={"100%"}
        />
      </Group>

      <Group mt={"xs"}>
        {usedLinkedIn.limit <= usedLinkedIn.used &&
          usedLinkedIn.used !== 0 &&
          item.type.includes("inked") && (
            <Text size="sm" c={"#c00"} fs={"italic"}>
              {lang.contact_lists.jobs.error.limit_reached}
            </Text>
          )}
        {!stopped && <StopJob id={item?.uniqid} setStopped={setStopped} />}
      </Group>
      {handleJobItem({ item: item, fromLists, isProgress: true })}
      {stopped && (
        <Text size="sm" c={"red"} fw={500} mt={6}>
          {lang.contact_lists.jobs.cancellation_request}
        </Text>
      )}
    </Paper>
  );
};

export default JobItemInProgress;
