import {
  Button,
  Group,
  InputLabel,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { IconArrowRight } from "@tabler/icons-react";
import ImageGetter from "../ImageGetter";
import { useLanguage } from "../../../../stores/LanguageStore";

const Step2 = ({
  setStep,
  setLogo,
  logo,
  setLogoMenu,
  logoMenu,
  setFavicon,
  favicon,
}) => {
  const lang = useLanguage((s) => s.language);
  const handleSubmit = (e) => {
    e.preventDefault();

    setStep(3);
  };

  return (
    <Stack component={"form"} onSubmit={handleSubmit}>
      <Text fz={24} className={`animate__animated animate__fadeIn`}>
        {lang.resellers.onboarding.step2.title}
      </Text>

      <Group
        gap={"xl"}
        className={`animate__animated animate__fadeIn`}
        style={{ animationDelay: ".15s" }}
      >
        <ImageGetter
          tip_src={lang.resellers.onboarding.step2.logo}
          title={
            <Group gap={"xs"}>
              <InputLabel fw={500} fz={15} required>
                Logo{" "}
              </InputLabel>
              <Text
                component="span"
                style={{ opacity: 0.5, fontSize: 12, marginLeft: 8 }}
              >
                (.png / .svg / .jpg) max: 2mb
              </Text>
            </Group>
          }
          name={"logo"}
          types={["JPG", "JPEG", "PNG", "SVG"]}
          defaultFile={logo}
          setFile={setLogo}
          required
        />

        <ImageGetter
          tip_src={lang.resellers.onboarding.step2.logo_menu}
          title={
            <Text fw={500}>
              Logo Menu{" "}
              <Text
                component="span"
                style={{ opacity: 0.5, fontSize: 12, marginLeft: 8 }}
              >
                (.png / .svg / .jpg) max: 2mb
              </Text>
            </Text>
          }
          name={"logo_menu"}
          types={["JPG", "JPEG", "PNG", "SVG"]}
          defaultFile={logoMenu}
          setFile={setLogoMenu}
        />

        <ImageGetter
          tip_src={lang.resellers.onboarding.step2.favicon}
          title={
            <Text fw={500}>
              Favicon{" "}
              <Text
                component="span"
                style={{ opacity: 0.5, fontSize: 12, marginLeft: 8 }}
              >
                (.ico) max: 2mb
              </Text>
            </Text>
          }
          name={"favicon"}
          types={["ICO"]}
          isIco
          defaultFile={favicon}
          setFile={setFavicon}
        />
      </Group>

      <Group justify="space-between" w={"100%"} mt={"sm"}>
        <Button variant="subtle" onClick={() => setStep(1)}>
          {lang.global.back}
        </Button>
        <Button
          rightSection={<IconArrowRight />}
          className={`animate__animated animate__fadeIn`}
          style={{ animationDelay: ".3s" }}
          variant="gradient"
          type="submit"
        >
          {lang.global.next}
        </Button>
      </Group>
    </Stack>
  );
};

export default Step2;
