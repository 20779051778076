import { useCallback, useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import {
  ActionIcon,
  Group,
  Input,
  Select,
  Stack,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import LeftBioSaver from "../../../leadsTracking/prospect/sheet/Left/LeftBioSaver";
import { notifications } from "@mantine/notifications";
import { subscribeUser } from "../../../../../api/AdminAPI";
import { updateProfile } from "../../../../../api/UserAPI";
import { parseDateFuture } from "../../../../../functions/dates";
import AdminInputSubscription from "./AdminInputSubscription";
import CountrySelector from "../../../../../components/Selectors/CountrySelector";
import PasswordField from "../../../../../components/View/PasswordField";
import PhoneNumberInputControlled from "../../../../../components/Selectors/PhoneNumberInputControlled";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input/input";

const AdminInput = ({ setting, icon, profile, setProfile }) => {
  const lang = useLanguage((s) => s.language);

  //#region Editing
  const [isEdit, setIsEdit] = useState(false);
  const handleEdit = () => {
    setIsEdit(true);
    setValue(
      setting.value === "subscriptions"
        ? profile?.subscriptions?.end_date
        : profile?.[setting.value]
    );
  };
  const handleStopEdit = () => {
    setIsEdit(false);
  };
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(
    setting.value === "subscriptions"
      ? profile?.subscriptions?.end_date
      : profile?.[setting.value]
  );
  const [pwdError, setPwdError] = useState(false);
  const [country, setCountry] = useState(
    profile?.[`mobile_phone_country`] || "FR"
  );

  const handleSubmitEdit = useCallback(() => {
    setLoading(true);

    if (setting.value === "subscriptions") {
      let d = new Date(value).toISOString();
      return subscribeUser({ end_date: d, user_id: profile.id })
        .then((response) => {
          notifications.show({
            message: lang.profile.edited,
            color: "tertiary.8",
          });
          setProfile((prev) => {
            return {
              ...prev,
              subscriptions: {
                active: true,
                end_date: d,
              },
            };
          });
          handleStopEdit();
        })
        .finally(() => setLoading(false));
    }

    let final_value =
      setting.value === "password"
        ? document.getElementById("pwd-" + profile.id).value
        : value;

    let finalData = {
      [setting.value]: final_value,
    };

    if (setting.value === "mobile_phone") {
      finalData[`mobile_phone_country`] = country;
    }

    updateProfile(profile?.id, finalData)
      .then((response) => {
        notifications.show({
          message: lang.profile.edited,
          color: "tertiary.8",
        });
        setProfile((prev) => {
          return {
            ...prev,
            ...finalData,
          };
        });
        handleStopEdit();
      })
      .finally(() => setLoading(false));
  }, [country, lang.profile.edited, profile, setProfile, setting.value, value]);

  const getEditComponent = useCallback(() => {
    if (setting.value === "subscriptions") {
      return (
        <AdminInputSubscription
          value={value}
          onChange={setValue}
          handleStopEdit={handleStopEdit}
          profile={profile}
          setProfile={setProfile}
        />
      );
    }

    if (setting.value === "language") {
      return (
        <Select
          leftSection={icon}
          label={setting.label}
          placeholder={setting.label}
          autoFocus
          value={value}
          onChange={setValue}
          data={[
            {
              label: "Français",
              value: "FRA",
            },
            {
              label: "English",
              value: "ENG",
            },
          ]}
        />
      );
    }

    if (setting.value === "country") {
      return (
        <CountrySelector
          leftSection={icon}
          label={setting.label}
          placeholder={setting.label}
          autoFocus
          value={value}
          onChange={setValue}
        />
      );
    }

    if (setting.value.includes("phone") && value) {
      let v = value?.startsWith("+") ? value : `+${value}`;

      let c = parsePhoneNumber(v);

      return (
        <PhoneNumberInputControlled
          country={
            setting.value === "mobile_phone" ? country : c?.country || "FR"
          }
          setCountry={setCountry}
          value={v}
          setValue={setValue}
          name={setting.value}
          label={lang.profile[setting.value]}
          w={"100%"}
          withCountrySelector={setting.value === "mobile_phone"}
        />
      );
    }

    if (setting.value === "password") {
      return (
        <PasswordField
          error={pwdError}
          setError={setPwdError}
          // value={value}
          // onChange={(e) => setValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !loading) {
              handleSubmitEdit();
            }
          }}
          id={"pwd-" + profile.id}
          label={setting.label}
          placeholder={setting.label}
          autoFocus
          leftSection={icon}
        />
      );
    }

    return (
      <TextInput
        label={setting.label}
        placeholder={setting.label}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        autoFocus
        leftSection={icon}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !loading) {
            handleSubmitEdit();
          }
        }}
      />
    );
  }, [
    handleSubmitEdit,
    icon,
    loading,
    profile,
    pwdError,
    setProfile,
    setting.label,
    setting.value,
    value,
    country,
    lang.profile,
  ]);

  //#endregion

  const getText = useCallback(() => {
    if (setting.value === "password") {
      return (
        <Text size="sm" fw={500} truncate="end">
          ********
        </Text>
      );
    }

    if (setting.value === "mobile_phone") {
      return (
        <TextInput
          variant="unstyled"
          component={PhoneInput}
          country={profile?.mobile_phone_country || "FR"}
          value={profile?.mobile_phone}
          readOnly
          h={22}
          styles={{
            wrapper: {
              height: 22,
            },
            input: {
              minHeight: 0,
              height: 22,
              fontWeight: 500,
            },
          }}
        />
      );
    }

    if (setting.value === "subscriptions") {
      return (
        <Text
          size="sm"
          fw={500}
          truncate="end"
          c={profile?.subscriptions?.active ? "tertiary.8" : "red"}
        >
          {profile?.subscriptions?.active
            ? profile?.subscriptions?.trial
              ? "FREE"
              : `Fin : ${parseDateFuture(profile?.subscriptions?.end_date)}`
            : "EXPIRED"}
        </Text>
      );
    }
    return (
      <Text size="sm" fw={500} truncate="end">
        {profile?.[setting.value] === "male"
          ? lang.profile.civility_male
          : profile?.[setting.value] === "female"
          ? lang.profile.civility_female
          : profile?.[setting.value] || "-"}
      </Text>
    );
  }, [
    setting.value,
    value,
    profile,
    lang.profile.civility_male,
    lang.profile.civility_female,
  ]);

  return (
    <Stack
      pos="relative"
      w="100%"
      gap={0}
      className="hover-source"
      style={{ borderBottom: "1px solid #0000000a" }}
      pb={8}
      pt={8}
    >
      {isEdit ? (
        <Stack mt={-8} w="100%" gap={8}>
          {getEditComponent()}
          <LeftBioSaver
            handleClose={handleStopEdit}
            handleSave={handleSubmitEdit}
            loading={loading}
          />
        </Stack>
      ) : (
        <Group wrap="nowrap" w={"100%"} gap={0}>
          {icon}

          <Stack gap={0} w={0} flex={1} ml={8}>
            <Text size="xs">{setting.label}</Text>

            {getText()}
          </Stack>

          <Tooltip
            label={
              profile?.[setting.value]
                ? lang.prm.details.edit
                : lang.prm.details.add
            }
          >
            <ActionIcon
              variant="subtle"
              onClick={handleEdit}
              className="hover-target"
            >
              <IconEdit size={20} />
            </ActionIcon>
          </Tooltip>
        </Group>
      )}
    </Stack>
  );
};

export default AdminInput;
