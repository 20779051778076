import { useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { useForm } from "react-hook-form";
import { editMassMailing } from "../../../../../api/EmailAPI";
import { notifications } from "@mantine/notifications";
import useNavigationBlocker from "../../../../../components/View/NavigationBlocker";
import {
  Accordion,
  Button,
  Group,
  LoadingOverlay,
  Stack,
  TagsInput,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import BackButton from "../../../../../components/View/BackButton";
import { IconDeviceFloppy, IconSignature } from "@tabler/icons-react";
import { SharingInAccordion } from "../../../../../components/Sharing/SharingController";
import { useNavigate } from "react-router-dom";
import SignatureSelector from "../../../../../components/Selectors/SignatureSelector";

const EditMassMailing = ({ emailProfile }) => {
  const lang = useLanguage((s) => s.language);
  const navigate = useNavigate();
  const [dirty, setDirty] = useState(false);
  const [sign, setSign] = useState(emailProfile?.email_signatures_id || null);
  const sharingForm = useForm({
    defaultValues: {
      sharing: emailProfile?.sharing?.map((x) => ({
        ...x,
        sharing_targets: x.sharing_targets.map((sharing_target) => ({
          ...sharing_target,
          option_name: sharing_target.name,
        })),
      })),
    },
  });

  const [loading, setLoading] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    let fd = new FormData(e.target);
    let data = Object.fromEntries(fd);

    const sharing = sharingForm
      .getValues()
      .sharing.filter((x) => x.sharing_targets.length > 0)
      .map((share) => ({
        ...share,
        ids_list: share.sharing_targets.map((x) => x.id),
        sharing_targets: undefined,
      }));

    setLoading(true);
    editMassMailing({
      id: emailProfile?.id,
      name: data?.name,
      email: data?.email,
      sender: data?.sender,
      reply_to: data?.reply_to ? data?.reply_to?.split(",") : null,
      email_signatures_id: sign,
      sharing,
    })
      .then((response) => {
        setDirty(false);
        setTimeout(() => {
          notifications.show({
            message: lang.emails_account.row_highlight.updated,
            color: "tertiary.8",
          });
          navigate(
            "/senders/email/?highlight=" +
              (response?.data.email_account_id || emailProfile?.id)
          );
        }, 300);
      })
      .finally(() => setLoading(false));
  };

  useNavigationBlocker(dirty);

  return (
    <Stack
      className="layout-block"
      w={"40%"}
      mx={"auto"}
      gap={"xs"}
      component={"form"}
      id="edit-form"
      onSubmit={handleSubmit}
      onChange={() => setDirty(true)}
    >
      <LoadingOverlay visible={loading} />
      <Title order={5}>
        {lang.emails_account.mass_mailing.edit.dialog.title} :{" "}
        {emailProfile?.name} #{emailProfile?.id}
      </Title>

      <Text>{lang.emails_account.mass_mailing.create.helper_text}</Text>
      <TextInput
        label={lang.emails_account.mass_mailing.create.name}
        placeholder={lang.emails_account.mass_mailing.create.name}
        name="name"
        defaultValue={emailProfile?.name}
        required
        autoFocus
      />
      <Group wrap="nowrap" w={"100%"}>
        <TextInput
          type="email"
          label={lang.emails_account.mass_mailing.create.email}
          placeholder={lang.emails_account.mass_mailing.create.email}
          name="email"
          defaultValue={emailProfile?.email}
          required
          w={"100%"}
        />
        <TextInput
          placeholder={lang.emails_account.mass_mailing.create.sender}
          label={lang.emails_account.mass_mailing.create.sender}
          defaultValue={emailProfile?.sender}
          name="sender"
          required
          w={"100%"}
        />
      </Group>

      <TagsInput
        name="reply_to"
        label={lang.emails_account.mass_mailing.reply_to.title}
        placeholder="Appuyez sur Entrée pour ajouter"
        description="Entrée pour ajouter"
        defaultValue={emailProfile?.reply_to}
        mb={"sm"}
      />

      <Accordion variant="contained" mb={"sm"}>
        <Accordion.Item value={"send"}>
          <Accordion.Control
            icon={<IconSignature size={16} />}
            px={"xs"}
            bg={"var(--mantine-color-gray-0)"}
          >
            {lang.emails_account.smtp.step_pools_signs.signature}
          </Accordion.Control>
          <Accordion.Panel>
            <SignatureSelector
              value={sign}
              onChange={(v) => setSign(v?.id || null)}
            />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>

      <SharingInAccordion form={sharingForm} />

      <Group justify="right" mt={"sm"}>
        <BackButton />
        <Button
          type="submit"
          leftSection={<IconDeviceFloppy size={18} />}
          loading={loading}
        >
          {lang.model_emails.edit.dialog.submit_button}
        </Button>
      </Group>
    </Stack>
  );
};

export default EditMassMailing;
