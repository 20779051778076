import Path from "../../../layouts/Path";
import {
  ActionIcon,
  Button,
  Group,
  InputDescription,
  InputLabel,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useLanguage } from "../../../stores/LanguageStore";
import { useMemo, useState } from "react";
import {
  useLoaderData,
  useNavigate,
  useRouteLoaderData,
} from "react-router-dom";
import { notifications } from "@mantine/notifications";
import BackButton from "../../../components/View/BackButton";
import { createFile, editFile } from "../../../api/FIleAPI";
import { IconExternalLink, IconUpload } from "@tabler/icons-react";
import { FileUploader } from "react-drag-drop-files";
import FolderSelector from "../../../components/Selectors/FolderSelector";
import PageTitle from "../../../components/View/PageTitle";

const supportedTypes = [
  "JPG",
  "JPEG",
  "PNG",
  "GIF",
  "PDF",
  "POT",
  "POTX",
  "PPT",
  "PPTX",
  "XLS",
  "XLSX",
  "HTML",
  "DOC",
  "DOCX",
];

const FilePage = () => {
  const lang = useLanguage((s) => s.language);
  const folderProfile = useRouteLoaderData("file-folder");
  const fileProfile = useLoaderData();
  const [folder, setFolder] = useState(
    fileProfile?.folder || folderProfile || null
  );
  const [incorrectLength, setIncorrectLength] = useState(false);
  const [invalidFile, setInvalidFile] = useState(false);
  const [fileKey, setFileKey] = useState(false);

  const [file, setFile] = useState(null);
  const [dragging, setDragging] = useState(false);

  const [name, setName] = useState(fileProfile?.name || "");

  const handleGetFile = (file) => {
    setFile(file);
    setFileKey(!fileKey);
    setIncorrectLength(false);
    setInvalidFile(false);
    if (!name && file?.name) {
      let newName = file.name.split(".");
      newName.pop();

      setName(newName.join("."));
    }
  };

  const filePickerStyles = useMemo(() => {
    if (incorrectLength || invalidFile) {
      return {
        border: "2px dashed",
        boxShadow: "#c00 0 0 8px",
        borderColor: "#cc0000",
        borderRadius: 1,
        padding: 8,
        textAlign: "center",
        cursor: "pointer",
      };
    } else
      return {
        border: "1px dashed",
        borderColor: "#00000066",
        borderRadius: 1,
        padding: 2,
        textAlign: "center",
        cursor: "pointer",
      };
  }, [incorrectLength, invalidFile]);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    let fd = new FormData(e.target);
    let data = Object.fromEntries(fd);

    setLoading(true);
    const main = fileProfile ? editFile : createFile;
    main({
      id: fileProfile?.id,
      name: data.name,
      file,
      folder_id: folder?.id || null,
    })
      .then((response) => {
        setTimeout(() => {
          notifications.show({
            message:
              lang.files.row_highlight[fileProfile ? "updated" : "created"],
            color: "tertiary.8",
          });
        }, 300);
        navigate(
          `../?highlight=${fileProfile?.id || response.data.api_key_id}`
        );
      })
      .catch((err) => {
        if (err.response.data.state_message === "voice_file_incorrect_length") {
          return setIncorrectLength(true);
        } else if (err.response.data.state_message === "invalid_voice_file") {
          return setInvalidFile(true);
        }
        return setInvalidFile(true);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Group
        className="layout-block top"
        w={"100%"}
        gap={0}
        justify="space-between"
      >
        <PageTitle title={lang.files.browser_tab_title} />
      </Group>
      <Stack
        className="layout-block"
        w={"50%"}
        mx={"auto"}
        px={"md"}
        gap={"xs"}
        component={"form"}
        onSubmit={handleSubmit}
      >
        {fileProfile ? (
          <Group>
            <Title order={5}>
              {lang.files.edit.dialog.title} <b>{fileProfile?.name}</b>
            </Title>

            <Button
              component="a"
              href={fileProfile.file_url}
              target="_blank"
              ml={"auto"}
              variant="light"
              leftSection={<IconExternalLink size={18} />}
              size="xs"
            >
              {lang.files.create.dialog.view_file}
            </Button>
          </Group>
        ) : (
          <Title order={5}>{lang.files.create.dialog.title}</Title>
        )}

        <TextInput
          name="name"
          label={lang.files.create.dialog.name}
          placeholder={lang.files.create.dialog.name}
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <InputLabel mb={-4}>{lang.files.create.dialog.file}</InputLabel>
        {fileProfile && (
          <InputDescription my={-4}>
            {lang.files.create.dialog.if_change}
          </InputDescription>
        )}
        <FileUploader
          handleChange={handleGetFile}
          key={fileKey}
          name="file"
          types={supportedTypes}
          hoverTitle={lang.components.files.drop_here}
          onDraggingStateChange={(d) => {
            setDragging(d);
          }}
          required={fileProfile ? false : !file}
        >
          <Stack
            align="center"
            gap={"xs"}
            h={200}
            justify="center"
            style={filePickerStyles}
          >
            <IconUpload size={32} />
            <Text style={{ opacity: dragging ? 0.1 : 1 }}>
              {lang.components.files.drag_drop_here}
            </Text>
            {file ? (
              <Text fw={600} c={"tertiary.8"}>
                {lang.files.create.dialog.selected}: {file?.name}
              </Text>
            ) : (
              <Text fw={600} c={"red"}>
                {lang.files.create.dialog.no_file}
              </Text>
            )}
          </Stack>
        </FileUploader>
        <Text ta="center" size="sm" c={"dimmed"}>
          (JPG / JPEG / PNG / GIF / PDF / POT / POTX / PPT / PPTX / XLS / XLSX /
          HTML / DOC / DOCX)
        </Text>

        <FolderSelector value={folder} setValue={setFolder} type={"File"} />

        <Group justify="flex-end" mt={"sm"}>
          <BackButton />
          <Button type="submit" loading={loading}>
            {lang.files[fileProfile ? "edit" : "create"].dialog.submit_button}
          </Button>
        </Group>
      </Stack>
    </>
  );
};

export default FilePage;
