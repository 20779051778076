import { create } from "zustand";

export const defaultWhenMinutes = 1440;

const flowStore = (set, get) => ({
  actualId: -1,
  getId: () => {
    set({ actualId: get().actualId + 1 });
    return get().actualId + 1;
  },
  profile: {
    id: null,
    name: "",
    tags: [],
    folder: null,
    sharing: [],
    auto_remove_responders: true,
  },
  setProfile: (newProfile) => set({ profile: newProfile }),

  nodes: [],
  setNodes: (newNodes) => set({ nodes: newNodes }),
  edges: [],
  setEdges: (newEdges) => set({ edges: newEdges }),
  direction: "vertical",
  setDirection: (newData) => set({ direction: newData }),
  updateNodeData: (nodeId, data) => {
    set({
      nodes: get().nodes.map((node) => {
        if (node.id === nodeId) {
          // it's important to create a new object to inform React Flow about the changes
          node.data = { ...node.data, ...data };
        }

        return node;
      }),
    });
  },
  focusNodeId: null,
  setFocusNodeId: (newData) => set({ focusNodeId: newData }),

  workflows: [],
  setWorkflows: (newData) => set({ workflows: newData }),

  selectedNode: null,
  setSelectedNode: (newData) => set({ selectedNode: newData }),

  loadingPage: true,
  setLoadingPage: (newData) => set({ loadingPage: newData }),

  sharing: [
    {
      sharing_type_to: "User",
      ids_list: [],
      sharing_targets: [],
    },
  ],
  setSharing: (newData) => set({ sharing: newData }),

  shareWorkflow: {
    id: "workflow",
    read_permission: true,
    write_permission: false,
    delete_permission: false,
  },
  setShareWorkflow: (newData) => set({ shareWorkflow: newData }),
  sharePrm: {
    id: "prm",
    read_permission: true,
    write_permission: false,
    delete_permission: false,
  },
  setSharePrm: (newData) => set({ sharePrm: newData }),
  shareStats: {
    id: "stats",
    read_permission: true,
  },
  setShareStats: (newData) => set({ shareStats: newData }),

  linking: false,
  setLinking: (newData) => set({ linking: newData }),
});

export const useFlowStore = create(flowStore);
