import { Box, Group, Paper, Text } from "@mantine/core";
import { useSessionStore } from "../../../../stores/UserStore";

const UserItem = ({ user, active, onClick }) => {
  const reseller = useSessionStore((s) => s.reseller);
  return (
    <Paper
      key={user?.id}
      withBorder
      onClick={onClick}
      bg={active ? "#00000016" : "none"}
      p={"sm"}
      py={"xs"}
      style={{ cursor: "pointer", overflow: "hidden" }}
      pos={"relative"}
    >
      <Group justify="space-between" w={"100%"} wrap="nowrap" gap={"xs"}>
        <Text fw={500} truncate="end" size="sm">
          {user?.first_name} {user?.last_name}
        </Text>
        <Text size="xs" opacity={0.35}>
          #{user?.id}
        </Text>
      </Group>

      {user?.in_a_reseller && reseller?.name === "Magileads" && (
        <Box bg={"red"} pos={"absolute"} top={0} right={0} h={"100%"} w={2} />
      )}
    </Paper>
  );
};

export default UserItem;
