import { useLanguage } from "../../stores/LanguageStore";
import {
  ActionIcon,
  Button,
  CopyButton,
  Group,
  Menu,
  Stack,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import {
  IconCheck,
  IconCopy,
  IconSearch,
  IconVariable,
} from "@tabler/icons-react";
import { useEffect, useState } from "react";
import ReplacementVariables from "../View/ReplacementVariables";

const AddTokens = ({ items, handleAdd, long, buttonProps }) => {
  const [opened, setOpened] = useState(false);
  const [searchItems, setSearchItems] = useState(items || []);
  const lang = useLanguage((s) => s.language);
  const handleOptionAdd = (item) => {
    setOpened(false);
    handleAdd(item);
  };

  const handleInput = (e) => {
    let search = e.target.value;

    let filtered = items.filter((x) => {
      let nameContained = x.label.toLowerCase().includes(search.toLowerCase());
      let valueContained = x.value.toLowerCase().includes(search.toLowerCase());

      return nameContained || valueContained;
    });

    setSearchItems(filtered);
  };

  useEffect(() => {
    if (opened) {
      setSearchItems(items);
      setTimeout(() => {
        document.getElementById("search-datafield").focus();
      }, 100);
    }
  }, [opened]);

  return (
    <Menu
      zIndex={900}
      position="bottom-start"
      onChange={setOpened}
      opened={opened}
    >
      <Menu.Target>
        {long ? (
          <Button
            variant="light"
            leftSection={<IconVariable size={18} />}
            size="sm"
            h={32}
            onClick={() => setOpened((prev) => !prev)}
            {...buttonProps}
          >
            Variables
          </Button>
        ) : (
          <Tooltip label={lang.components.variable_manager.add_variable}>
            <ActionIcon
              variant="light"
              {...buttonProps}
              onClick={() => setOpened((prev) => !prev)}
            >
              <IconVariable size={18} />
            </ActionIcon>
          </Tooltip>
        )}
      </Menu.Target>
      <Menu.Dropdown
        style={{ overflow: "auto" }}
        // onBlur={() => setOpened(false)}
      >
        <TextInput
          placeholder={lang.global.search}
          rightSection={<IconSearch size={16} />}
          onChange={handleInput}
          mb={4}
          autoFocus
          data-autoFocus
          id="search-datafield"
        />
        <Stack mah={"30vh"} style={{ overflow: "auto" }} gap={2}>
          {searchItems?.map((item) => (
            <Group key={item.value} wrap="nowrap" gap={"xs"}>
              <Menu.Item onClick={() => handleOptionAdd(item)}>
                <Stack gap={0}>
                  <Text mr={8} size="sm" fw={500}>
                    {item.label}
                  </Text>
                </Stack>
              </Menu.Item>
              <CopyButton value={item.value}>
                {({ copied, copy }) => (
                  <Tooltip
                    label={
                      copied
                        ? lang.global.copied
                        : `${lang.global.copy} variable`
                    }
                    color={copied ? "green" : "black"}
                  >
                    <ActionIcon
                      size={"sm"}
                      variant="light"
                      color={copied ? "green" : "primary"}
                      onClick={(e) => {
                        e.stopPropagation();
                        copy();
                        setOpened(true);
                      }}
                    >
                      {copied ? (
                        <IconCheck size={16} />
                      ) : (
                        <IconCopy size={16} />
                      )}
                    </ActionIcon>
                  </Tooltip>
                )}
              </CopyButton>
            </Group>
          ))}
        </Stack>
        <Menu.Divider />
        <ReplacementVariables />
      </Menu.Dropdown>
    </Menu>
  );
};

export default AddTokens;
