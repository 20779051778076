import { nameColumn } from "../../../../layouts/Table/columns/nameColumn";
import dateColumn from "../../../../layouts/Table/columns/dateColumn";
import TableLayoutBlock from "../../../../layouts/Table/TableLayoutBlock";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useCallback, useEffect, useMemo, useState } from "react";
import MainTable from "../../../../layouts/Table/MainTable";
import { findFolder } from "../../../../api/FolderAPI";
import {
  deleteWorkflow,
  editWorkflow,
  getWorkflows,
} from "../../../../api/WorkflowAPI";
import { ActionIcon, Group, Text, Tooltip } from "@mantine/core";
import { IconCopyPlus, IconEdit } from "@tabler/icons-react";
import { modals } from "@mantine/modals";
import { booleanColumn } from "../../../../layouts/Table/columns/booleanColumn";
import ItemDeleter from "../../../../components/TableComponents/ItemDeleter";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import SelectionDeleter from "../../../../components/TableComponents/SelectionDeleter";
import numberColumn from "../../../../layouts/Table/columns/numberColumn";
import { sharingColumn } from "../../../../layouts/Table/columns/sharingColumn";
import { handleWorkflowGet } from "../CreateCampaign/flow/flowUtils";

const WorkflowsListing = ({ folderId, closeModal, wrapperProps }) => {
  const lang = useLanguage((s) => s.language);
  const apiRef = useGridApiRef();
  const [hasQuery, setHasQuery] = useState(false);
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);

  const handleWorkflowImport = useCallback(
    (workflow, isEdit) => {
      modals.openConfirmModal({
        title: (
          <Text>
            {lang.campaign.import.confirm.title} <b>{workflow?.name}</b>
          </Text>
        ),
        children: (
          <Text size="sm">
            {lang.campaign.import.confirm.text1} <br />
            <b>{lang.campaign.import.confirm.text2}</b>
          </Text>
        ),
        centered: true,
        labels: {
          confirm: lang.campaign.import.label,
          cancel: lang.global.cancel,
        },
        onConfirm: () => {
          closeModal();
          handleWorkflowGet({ id: workflow?.id, isEdit });
        },
      });
    },
    [
      closeModal,
      lang.campaign.import.confirm.text1,
      lang.campaign.import.confirm.text2,
      lang.campaign.import.confirm.title,
      lang.campaign.import.label,
      lang.global.cancel,
    ]
  );

  const columns = useMemo(
    () => [
      numberColumn(),
      nameColumn({
        editFunction: () => editWorkflow,
        handleClick: (e, row) => {
          e.preventDefault();
          return handleWorkflowImport(row, true);
        },
        apiRef,
      }),
      booleanColumn({
        field: "programmed",
        headerName: lang.workflows.columns.programmed.name,
        falseLabel: lang.workflows.columns.programmed.false,
        trueLabel: lang.workflows.columns.programmed.true,
        width: 130,
        noColor: { error: true },
      }),
      dateColumn("updated_on", lang.workflows.columns.updated_on),
      dateColumn(),
      sharingColumn({ apiRef, editFunction: () => editWorkflow }),
    ],
    [
      apiRef,
      lang.workflows.columns.programmed.name,
      lang.workflows.columns.programmed.false,
      lang.workflows.columns.programmed.true,
      lang.workflows.columns.updated_on,
      handleWorkflowImport,
    ]
  );

  const toolbarActions = useCallback(
    (ids) => {
      return (
        <SelectionDeleter
          handleDelete={deleteWorkflow}
          apiRef={apiRef}
          setTableLoading={setLoading}
          ids={ids}
        />
      );
    },
    [apiRef]
  );

  const actionsCell = useCallback(
    ({ row, value }) => {
      return (
        <Group wrap="nowrap" gap={4}>
          <Tooltip label={lang.campaign.import.use}>
            <ActionIcon
              variant="subtle"
              onClick={() => handleWorkflowImport(row, true)}
            >
              <IconEdit size={16} />
            </ActionIcon>
          </Tooltip>
          <Tooltip label={lang.campaign.import.duplicate}>
            <ActionIcon
              variant="subtle"
              onClick={() => handleWorkflowImport(row)}
            >
              <IconCopyPlus size={16} />
            </ActionIcon>
          </Tooltip>
          <ItemDeleter
            title_bold={`${row.name} #${row.id}`}
            handleDelete={deleteWorkflow}
            deletedMessage={lang.workflows.delete_success}
            id={row.id}
            disabled={!row?.delete_permission}
            apiRef={apiRef}
          />
        </Group>
      );
    },
    [
      apiRef,
      handleWorkflowImport,
      lang.campaign.import.duplicate,
      lang.campaign.import.use,
      lang.workflows.delete_success,
    ]
  );

  useEffect(() => {
    if (loading) {
      let mainFunction = folderId ? findFolder : getWorkflows;
      mainFunction({ folderId })
        .then((response) => {
          if (folderId) {
            setRows(
              response.data.folder_profile.items.sort((a, b) => b.id - a.id)
            );
          } else setRows(response.data.workflows.sort((a, b) => b.id - a.id));
        })
        .finally(() => setLoading(false));
    }
  }, [loading, folderId]);

  useEffect(() => {
    setLoading(true);
  }, [folderId]);

  return (
    <TableLayoutBlock
      folderType={"Workflow"}
      parentRoute={"/workflows"}
      editFunction={editWorkflow}
      apiRef={apiRef}
      setTableLoading={setLoading}
      wrapperProps={wrapperProps}
    >
      <MainTable
        apiRef={apiRef}
        columns={columns}
        rows={rows}
        loading={loading}
        setLoading={setLoading}
        id={"workflows-table"}
        toolbarActions={toolbarActions}
        actionsCell={actionsCell}
        hasFolder
        pageName={"workflows"}
        setHasQuery={setHasQuery}
        hasQuery={hasQuery}
      />
    </TableLayoutBlock>
  );
};

export default WorkflowsListing;
