import { alpha } from "@mui/material";
import { useMemo } from "react";

const DragOverlay = ({ disabled, container }) => {
  const dragOverlayColor = useMemo(
    () => alpha(`#${container?.color || "333333"}`, 0.05),
    [container]
  );

  return (
    <>
      <div
        className={"container-overlay"}
        style={{
          background: "transparent",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 99,
          borderRadius: 0,
          display: "none",
        }}
        onDragEnter={(e) => {
          if (!disabled)
            document.getElementById(
              "main-overlay-" + container.id
            ).style.background = dragOverlayColor;
        }}
        onDragLeave={(e) => {
          document.getElementById(
            "main-overlay-" + container.id
          ).style.background = "none";
        }}
      ></div>

      <div
        className="main-overlay"
        id={"main-overlay-" + container.id}
        style={{
          background: "none",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 0,
        }}
      ></div>
    </>
  );
};

export default DragOverlay;
