export const defaultPermissionsValue = {
  accessContactLists: true,
  accessModels: true,
  accessPRM: true,
  accessReporting: true,
  accessWorkflows: true,
  displayDetailedStatistics: false,
  displayEmailsPhones: true,
  displayInternalEmailsPhones: false,
  displayPricing: true,
  displayResellers: true,
  displaySideMenu: true,
  findGoogleMapsPhone: false,
  findMobilePhone: false,
  generateEmail: false,
  integrationsEmailForceValidate: false,
  maxEmailsSentPerDay: 5000,
  maxIntegrationsEmailMassMailing: 1,
  maxIntegrationsEmailSMTP: 1,
  maxIntegrationsLinkedin: 1,
  maxLinkedinConnectionsExtractor: 100,
  maxLinkedinConversationsExtractor: 100,
  maxLinkedinInteractionsPerDay: 2500,
  priorityCampaign: false,
  verifEmail: false,
  copyInternalContactListToPRM: false,
  copyContactListToPRM: true,
  displayAddIntegrationsEmailMassMailing: true,
};
