import { Button } from "@mantine/core";
import { useLanguage } from "../../../../stores/LanguageStore";
import { Link } from "react-router-dom";
import { IconArrowBarToUp } from "@tabler/icons-react";

const BlacklistImportButton = () => {
  const lang = useLanguage((s) => s.language);

  return (
    <Button
      leftSection={<IconArrowBarToUp size={18} />}
      radius={"sm"}
            color="primary"
      component={Link}
      to={"import"}
    >
      {lang.blacklists.import.button_tooltip}
    </Button>
  );
};

export default BlacklistImportButton;
