import { Anchor, Button, Group, Stack, Text, TextInput } from "@mantine/core";
import { IconDeviceFloppy, IconPlus } from "@tabler/icons-react";
import { useLanguage } from "../../../../stores/LanguageStore";

const Step4 = ({ setStep, setDomain, domain, handleCreate, handleSave }) => {
  const lang = useLanguage((s) => s.language);
  const handleSubmit = (e) => {
    e.preventDefault();

    handleCreate();
  };

  return (
    <Stack component={"form"} onSubmit={handleSubmit}>
      <Text fz={24} className={`animate__animated animate__fadeIn`}>
        {lang.resellers.onboarding.step4.title}
      </Text>

      <TextInput
        size="xl"
        placeholder="application.example.com"
        value={domain}
        onChange={(e) => setDomain(e.target.value)}
        className={`animate__animated animate__fadeIn`}
        style={{ animationDelay: ".1s" }}
        autoFocus
        required
      />

      <Anchor
        size="lg"
        target="_blank"
        fw={500}
        className={`animate__animated animate__fadeIn`}
        style={{ animationDelay: ".2s" }}
        href={
          lang.id === "FRA"
            ? "https://support.magileads.net/article/espace-revendeur"
            : "https://support.magileads.net/en-us/article/cname-configuration"
        }
      >
        {lang.resellers.onboarding.step4.how_to}
      </Anchor>
      {/* <Anchor
        size="lg"
        target="_blank"
        fw={500}
        className={`animate__animated animate__fadeIn`}
        style={{ animationDelay: ".3s" }}
      >
        {lang.resellers.onboarding.step4.tuto}
      </Anchor> */}

      <Group justify="space-between" w={"100%"} mt={"sm"}>
        <Button variant="subtle" onClick={() => setStep(3)}>
          {lang.global.back}
        </Button>
        <Button
          className={`animate__animated animate__fadeIn`}
          style={{ animationDelay: ".4s" }}
          variant="gradient"
          ml={"auto"}
          leftSection={<IconDeviceFloppy size={18} />}
          gradient={{ from: "teal", to: "green", deg: 90 }}
          onClick={handleSave}
        >
          {lang.resellers.onboarding.step4.finish_later}
        </Button>
        <Button
          className={`animate__animated animate__fadeIn`}
          style={{ animationDelay: ".4s" }}
          variant="gradient"
          type="submit"
          leftSection={<IconPlus size={18} />}
          ml={"md"}
        >
          {lang.resellers.onboarding.step4.create}
        </Button>
      </Group>
    </Stack>
  );
};

export default Step4;
