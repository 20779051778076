import { Box, Button, Stack, Text } from "@mantine/core";
import { useProfileStore } from "../../../../stores/UserStore";
import { IconRefresh } from "@tabler/icons-react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { Link } from "react-router-dom";
import { lazy } from "react";

const ResellerFinishIllustration = lazy(() =>
  import("./ResellerFinishIllustration")
);

const Step5Saved = ({ setTableLoading }) => {
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);

  return (
    <Stack w={"60%"} pos={"relative"}>
      <Text
        fz={28}
        className="animate__animated animate__fadeIn"
        style={{ animationDelay: ".1s" }}
      >
        {profile.first_name}, {lang.resellers.onboarding.step5.title2}
      </Text>

      <Button
        component={Link}
        leftSection={<IconRefresh size={22} />}
        variant="gradient"
        size={"xl"}
        radius="xl"
        className="sparkle-button animate__animated animate__fadeIn animate__delay-1s"
        style={{
          width: "fit-content",
          margin: "auto auto",
          position: "absolute",
          left: 0,
          top: "29%",
        }}
        to={"/resellers"}
      >
        {lang.resellers.onboarding.step5.return}
      </Button>

      <Box
        w={"75%"}
        className="animate__animated animate__fadeIn"
        pos={"relative"}
        ml={"auto"}
        mr={-128}
      >
        <ResellerFinishIllustration />
      </Box>
    </Stack>
  );
};

export default Step5Saved;
