import { useListStore } from "../stores/ListStore";
import { useProfileStore, useSessionStore } from "../stores/UserStore";
import { mainAxios as axios } from "./axios";

export const login = async ({ email, password }) => {
  return axios.post("/users/authentication", { email, password });
};

export const logout = () => {
  useSessionStore.getState().setSession(null);
  useSessionStore.getState().setUserSwitch(null);
  useSessionStore.getState().setAppUrl("");

  useListStore.getState().setMainLoad(true);
  useListStore.getState().setActiveTab("./");
  useListStore.getState().setLists([]);
  useProfileStore.getState().setUnswitchedProfile(null)
  return useProfileStore.getState().setProfile(null);
};

export const signup = async (data) => {
  return axios.post("/users/register", data);
};

export const forgotPwd = async (data) => {
  return axios.put("/users/password/forgot", data);
};

export const resetPwd = async (data) => {
  return axios.put("/users/password/reset", data);
};

export const unlockAccount = async (data) => {
  return axios.put("/users/unlock", data);
};
