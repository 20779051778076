import {
  Checkbox,
  Divider,
  Group,
  NumberInput,
  Stack,
  Text,
} from "@mantine/core";
import { defaultSMTPConfigs } from "../../../tools/emails/components/defaultConfigs";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { useState } from "react";

const EmailSending = ({ configs }) => {
  const lang = useLanguage((s) => s.language);
  const mainConfig = configs || defaultSMTPConfigs;
  const [minValue, setMinValue] = useState(mainConfig?.sending_frequency_start);
  const [maxValue, setMaxValue] = useState(mainConfig?.sending_frequency_end);

  const onMinChange = (v) => {
    let value = parseInt(v);
    setMinValue(value);

    if (maxValue <= value + 30) {
      setMaxValue(value + 30);
    }
  };

  return (
    <Stack pt={"xs"}>
      <Stack gap={"xs"}>
        <Checkbox
          size="md"
          name="enable_tracking"
          defaultChecked={mainConfig.enable_tracking}
          label={lang.emails_account.smtp.step_suppl.enable_tracking}
          styles={{ label: { fontWeight: 500 } }}
        />
        <Text size="sm">
          {lang.emails_account.smtp.step_suppl.enable_tracking_text}
        </Text>
      </Stack>

      <Divider />

      <Stack gap={"xs"}>
        <Text fw={500}>
          {lang.emails_account.smtp.step_suppl.sending_frequency}
        </Text>
        <Group>
          <Text size="sm">
            {lang.emails_account.smtp.step_suppl.sending_frequency_start}
          </Text>
          <NumberInput
            defaultValue={mainConfig.sending_frequency_start}
            name="sending_frequency_start"
            onChange={onMinChange}
            min={0}
            max={3599}
            clampBehavior="blur"
            w={75}
            required
          />
          <Text size="sm">
            {lang.emails_account.smtp.step_suppl.sending_frequency_end}
          </Text>
          <NumberInput
            defaultValue={mainConfig.sending_frequency_end}
            name="sending_frequency_end"
            min={minValue + 30}
            max={3599}
            clampBehavior="blur"
            value={maxValue}
            onChange={setMaxValue}
            required
            w={75}
          />
          <Text size="sm">
            {lang.emails_account.smtp.step_suppl.sending_frequency_seconds}
          </Text>
        </Group>
      </Stack>

      <Divider />

      <Stack gap={4}>
        <Text fw={500}>{lang.emails_account.smtp.step_suppl.warmup}</Text>
        <Text size="sm" mb={4}>
          {lang.emails_account.smtp.step_suppl.warmup_text}
        </Text>
        <Group>
          {Object.keys(mainConfig.warm_up).map((key, index) => (
            <NumberInput
              key={key}
              defaultValue={mainConfig.warm_up[`day_${index + 1}`]}
              name={`day${index + 1}`}
              label={`${lang.emails_account.smtp.step_suppl.warmup_day} ${
                index === 14 ? "15+" : index + 1
              }`}
              min={0}
              required
              w={100}
              mr={30}
              clampBehavior="blur"
            />
          ))}
        </Group>
      </Stack>
    </Stack>
  );
};

export default EmailSending;
