import { handleLayout } from "../layouter";
import { useFlowStore } from "../../../../../../stores/WorkflowStore";
import { ActionIcon, Menu, Stack, Text } from "@mantine/core";
import { IconCircuitPushbutton } from "@tabler/icons-react";
import { useReactFlow } from "reactflow";
import ActionsList from "./ActionsList";
import { useState } from "react";
import { defaultNodeConfigs, isMainAction } from "../flowSettings";
import { useLanguage } from "../../../../../../stores/LanguageStore";

const NodeBranchCreator = ({
  source,
  shouldBeEvent,
  sourceX,
  sourceY,
  error,
}) => {
  const lang = useLanguage((s) => s.language);
  const reactFlow = useReactFlow();
  const [opened, setOpened] = useState(false);

  const handleNewBranch = (actionType) => {
    const getId = useFlowStore.getState().getId;
    const nodes = useFlowStore.getState().nodes;
    const setNodes = useFlowStore.getState().setNodes;
    const edges = useFlowStore.getState().edges;
    const setEdges = useFlowStore.getState().setEdges;
    let newNodes = [...nodes];
    let newEdges = [...edges];

    let newNodeId = getId().toString();
    let newEventNodeId = "";

    //#region Choose automatically the same sender as initial action
    const initialNode = nodes.find((x) => x.data?.source === "0");
    const initialType = initialNode?.data?.actionType;
    let autoData = { ...defaultNodeConfigs };

    if (
      initialType === actionType ||
      (initialType?.includes("linkedin") && actionType?.includes("linkedin"))
    ) {
      autoData.integration_id = initialNode?.data?.integration_id;
      autoData.pool_id = initialNode?.data?.pool_id;
    }
    //#endregion

    // Event node

    if (shouldBeEvent) {
      newEventNodeId = getId().toString();
      const newEventNode = {
        id: newEventNodeId,
        type: "action",
        data: {
          actionType: "event",
          source,
          error,
        },
        position: { x: sourceX, y: sourceY },
      };
      newNodes.push(newEventNode);

      let newEventEdge = {
        id: `e-${source}-${newEventNodeId}`,
        source: source,
        target: newEventNodeId,
        data: { fromEvent: false, error },
      };
      newEdges.push(newEventEdge);
    }

    const newNode = {
      id: newNodeId,
      type: "action",
      data: {
        actionType: actionType,
        source: shouldBeEvent ? newEventNodeId : source,
        error,
        ...autoData,
      },
      position: { x: sourceX, y: sourceY },
    };
    newNodes.push(newNode);

    // Event to new Node
    let newEdge = {
      id: `e-${shouldBeEvent ? newEventNodeId : source}-${newNodeId}`,
      source: shouldBeEvent ? newEventNodeId : source,
      target: newNodeId,
      data: { fromEvent: shouldBeEvent, error },
    };
    newEdges.push(newEdge);

    setNodes(newNodes);
    setEdges(newEdges);
    setOpened(false);

    setTimeout(() => {
      handleLayout();
      setTimeout(() => {
        let nodes = useFlowStore.getState().nodes;
        let n = nodes.find((x) => x.id === newNodeId);
        let e_n = nodes.find((x) => x.id === newEventNodeId);
        const setSelectedNode = useFlowStore.getState().setSelectedNode;
        if (isMainAction(n)) {
          setSelectedNode(n);
          return setTimeout(() => {
            reactFlow?.fitView({
              nodes: e_n ? [n, e_n, { id: source }] : [n, { id: source }],
              duration: 500,
            });
          }, 201);
        }
        reactFlow?.fitView({
          nodes: e_n ? [n, e_n, { id: source }] : [n, { id: source }],
          duration: 500,
        });
      }, 100);
    }, 50);
  };

  return (
    <Menu
      trigger="click-hover"
      openDelay={200}
      closeDelay={200}
      position="right"
      opened={opened}
      onChange={setOpened}
      shadow="md"
    >
      <Menu.Target>
        <Stack
          pos={"absolute"}
          bottom={-40}
          right={-30}
          justify="center"
          align="center"
          gap={0}
        >
          <ActionIcon
            size={"xs"}
            variant="white"
            style={{ boxShadow: "#00000021 0 1px 3px" }}
          >
            <IconCircuitPushbutton size={14} />
          </ActionIcon>

          <Text size="xs" opacity={0.35} mt={2}>
            {lang.campaign.nodes.add_branch}
          </Text>
        </Stack>
      </Menu.Target>
      <Menu.Dropdown>
        <ActionsList handleActionClick={handleNewBranch} />
      </Menu.Dropdown>
    </Menu>
  );
};

export default NodeBranchCreator;
