import { Button, Tooltip } from "@mantine/core";
import { IconFolderExclamation, IconFolderOpen } from "@tabler/icons-react";
import { defaultButtonType } from "../../initialState";
import { useLanguage } from "../../../../stores/LanguageStore";

const DisplayFoldertems = ({ setHasQuery, hasQuery }) => {
  const lang = useLanguage((s) => s.language);

  const handleClick = () => {
    setHasQuery(!hasQuery);
  };

  return (
    <Tooltip
      label={
        hasQuery ? lang.table.show_folder_items : lang.table.hide_folder_items
      }
    >
      <Button
        onClick={handleClick}
        radius={0}
        variant={"light"}
        color={hasQuery ? "primary" : "red"}
        className="toolbar-action-button"
        px={8}
      >
        {hasQuery ? (
          <IconFolderOpen size={18} />
        ) : (
          <IconFolderExclamation size={18} />
        )}
      </Button>
    </Tooltip>
  );
};

export default DisplayFoldertems;
