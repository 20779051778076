import { useGridApiRef } from "@mui/x-data-grid-pro";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useCallback, useMemo, useState } from "react";
import numberColumn from "../../../../layouts/Table/columns/numberColumn";
import { nameColumn } from "../../../../layouts/Table/columns/nameColumn";
import { getEmailTypeProps } from "../sendersUtil";
import { sharingColumn } from "../../../../layouts/Table/columns/sharingColumn";
import dateColumn from "../../../../layouts/Table/columns/dateColumn";
import SelectionDeleter from "../../../../components/TableComponents/SelectionDeleter";
import { deleteEmail, getEmails } from "../../../../api/EmailAPI";
import { useListingLoader } from "../../../../components/hooks/ListingHooks";
import {
  ActionIcon,
  Anchor,
  CopyButton,
  Group,
  Stack,
  Text,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import CreateEmailSender from "./CreateEmailSender";
import TableLayoutBlock from "../../../../layouts/Table/TableLayoutBlock";
import MainTable from "../../../../layouts/Table/MainTable";
import { IconCheck, IconEdit, IconX } from "@tabler/icons-react";
import { Link, useParams } from "react-router-dom";
import ItemDeleter from "../../../../components/TableComponents/ItemDeleter";
import { getTypeIcon } from "../../workflows/CreateCampaign/flow/flowSettings";
import { poolsColumn } from "../../../../layouts/Table/columns/poolsColumn";
import CreateGmail from "./smtp/CreateGmail";
import CreateOutlook from "./smtp/CreateOutlook";
import CreateSMTP from "./smtp/CreateSMTP";
import CreateMassMailing from "./smtp/CreateMassMailing";
import EmailSendersActions from "./actions/EmailSendersActions";
import WarmupCell from "./warmup/WarmupCell";
import ReputationCell from "./ReputationCell";
import PageTitle from "../../../../components/View/PageTitle";
import SelectionSharer from "../../../../components/TableComponents/SelectionSharer";
import { signatureColumn } from "../../../../layouts/Table/columns/signatureColumn";
import DomainsCell from "../../resellers/DomainsCell";
import Helper from "../../../../components/View/Helper";

const EmailSendersListing = () => {
  const apiRef = useGridApiRef();
  const lang = useLanguage((s) => s.language);
  const { poolId } = useParams();

  const columns = useMemo(
    () => [
      numberColumn(),
      nameColumn({
        parentPath: () =>
          `/senders/email${poolId ? `/pool/${poolId}/email` : ""}`,
        editFunction:
          (row) =>
          ({ id, name }) =>
            getEmailTypeProps(row.account_type)?.editFunction({
              id,
              data: { name },
            }),
        apiRef,
        width: 162,
        getDraggable: (row) => row.account_type !== "MassMailing",
      }),
      nameColumn({
        parentPath: () =>
          `/senders/email${poolId ? `/pool/${poolId}/email` : ""}`,
        editFunction:
          (row) =>
          ({ id, sender }) =>
            getEmailTypeProps(row.account_type)?.editFunction({
              id,
              data: { sender },
            }),
        apiRef,
        headerName: lang.emails_account.columns.sender,
        width: 120,
        field: "sender",
        getDraggable: (row) => row.account_type !== "MassMailing",
      }),
      {
        field: "email",
        headerName: lang.emails_account.columns.email,
        width: 175,
        renderCell: ({ value }) => {
          return (
            <CopyButton value={value}>
              {({ copied, copy }) => (
                <Tooltip
                  label={copied ? lang.global.copied : lang.global.copy}
                  color={copied ? "green" : "black"}
                >
                  <Anchor c={"black"} onClick={copy} truncate="end">
                    {value}
                  </Anchor>
                </Tooltip>
              )}
            </CopyButton>
          );
        },
      },
      {
        field: "account_type",
        headerName: lang.emails_account.columns.type,
        width: 75,
        renderCell: ({ value }) => (
          <Group gap={"xs"} wrap="nowrap" w={"100%"}>
            {getTypeIcon(value, false, 22, {
              variant: "subtle",
              color: value === "SMTP" ? "primary" : "pink",
            })}
          </Group>
        ),
      },
      {
        field: "is_valid",
        headerName: lang.emails_account.columns.is_valid_column,
        width: 75,
        renderCell: ({ value }) => (
          <Tooltip
            label={
              value
                ? lang.emails_account.columns.is_valid
                : lang.emails_account.columns.not_valid
            }
          >
            <ThemeIcon variant="subtle" color={value ? "tertiary.8" : "red"}>
              {value ? <IconCheck /> : <IconX />}
            </ThemeIcon>
          </Tooltip>
        ),
      },
      signatureColumn({
        apiRef,
        editFunction:
          (row) =>
          ({ id, email_signatures_id }) =>
            getEmailTypeProps(row.account_type)?.editFunction({
              id,
              data: { email_signatures_id, connection_test: false },
            }),
      }),
      poolsColumn({
        apiRef,
        editFunction:
          (row) =>
          ({ id, pools_ids }) =>
            getEmailTypeProps(row.account_type)?.editFunction({
              id,
              data: { pools_ids },
            }),
        type: "Email",
      }),
      {
        field: "warmup_settings",
        headerName: "Warm-Up",
        width: 120,
        renderCell: ({ value, row }) =>
          row.account_type === "SMTP" ? (
            <WarmupCell settings={value} row={row} apiRef={apiRef} />
          ) : (
            "-"
          ),
      },
      {
        field: "reputation",
        headerName: "Reputation",
        width: 90,
        renderCell: ({ value, row }) =>
          row.account_type === "SMTP" ? <ReputationCell value={value} /> : "-",
      },
      {
        field: "custom_tracking_domain",
        headerName: lang.emails_account.columns.custom_domain,
        width: 180,
        renderCell: ({ row, value }) => (
          <DomainsCell
            status={row.custom_tracking_domain_status}
            value={value}
            preferEmpty
          />
        ),
      },
      dateColumn("last_use", lang.emails_account.columns.last_use),
      dateColumn(),
      sharingColumn({
        apiRef,
        editFunction: (row) =>
          getEmailTypeProps(row.account_type)?.editFunction,
      }),
    ],
    [apiRef, lang, poolId]
  );

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);

  const actionsCell = useCallback(
    ({ row }) => {
      return (
        <Group wrap="nowrap" gap={4}>
          <EmailSendersActions
            current={row}
            onFinish={() => setLoading(true)}
          />

          <ActionIcon
            component={Link}
            to={poolId ? `email/${row.id}` : `${row.id}`}
            variant="subtle"
          >
            <IconEdit size={18} />
          </ActionIcon>

          <ItemDeleter
            title_bold={`${row.name} #${row.id}`}
            handleDelete={deleteEmail}
            deletedMessage={lang.emails_account.delete_success}
            id={row.id}
            disabled={
              !row?.delete_permission && !row?.permission?.delete_permission
            }
            apiRef={apiRef}
          />
        </Group>
      );
    },
    [apiRef, lang.emails_account.delete_success]
  );

  const toolbarActions = useCallback(
    ({ selectedIds }) => (
      <>
        <SelectionDeleter
          handleDelete={deleteEmail}
          apiRef={apiRef}
          setTableLoading={setLoading}
          ids={selectedIds}
        />
        <SelectionSharer
          getEditFunction={(row) =>
            getEmailTypeProps(row.account_type)?.editFunction
          }
          apiRef={apiRef}
          setTableLoading={setLoading}
          ids={selectedIds}
        />
        {/* <ReconnectAll setTableLoading={setLoading} ids={selectedIds} /> */}
      </>
    ),
    [apiRef]
  );

  useListingLoader({
    loading,
    setLoading,
    listGetter: getEmails,
    responseField: "email_accounts_list",
    setRows,
    folderRouteId: "email-pool",
    isPool: true,
  });

  return (
    <>
      <Group
        className="layout-block top"
        w={"100%"}
        gap={0}
        justify="space-between"
      >
        <PageTitle title={lang.emails_account.browser_tab_title} />

        <Helper
          label={lang.helpers.email}
          link={
            "https://app.supademo.com/embed/cm155plfx01ukme0pe5q3yfxx?embed_v=2"
          }
          autoPlay={!loading && rows.length === 0 ? 1 : 0}
        />

        <CreateEmailSender setTableLoading={setLoading} />
      </Group>

      <TableLayoutBlock
        folderType={"Email"}
        parentRoute={"/senders/email"}
        getEditFunction={(dragged) => {
          return ({ id, pools_ids }) =>
            getEmailTypeProps(dragged.account_type).editFunction({
              id,
              data: { pools_ids, connection_test: false },
            });
        }}
        apiRef={apiRef}
        isPool
        setTableLoading={setLoading}
      >
        <MainTable
          apiRef={apiRef}
          columns={columns}
          rows={rows}
          loading={loading}
          setLoading={setLoading}
          id={"sender-table"}
          toolbarActions={toolbarActions}
          actionsCell={actionsCell}
          hasFolder
          pageName={"integrations_email"}
          pageOrderName={"integrations_email_order"}
          noRowsDisplay={
            <Stack w={"100%"} h={"100%"} p={"xl"} pt={48}>
              <Text size="xl" fw={600} ta={"center"}>
                {lang.home.create_campaign.step2.title}
              </Text>
              <Group justify="center" mt={"xl"} gap={"lg"}>
                <Group>
                  <CreateGmail
                    buttonProps={{
                      size: "xl",
                      variant: "default",
                      radius: "lg",
                      w: 225,
                      justify: "center",
                      h: 100,
                      style: {
                        boxShadow: "#00000018 0 3px 8px",
                      },
                    }}
                    iconSize={32}
                    // onCreated={() => setLoading(true)}
                  />
                  <CreateOutlook
                    buttonProps={{
                      size: "xl",
                      variant: "default",
                      radius: "lg",
                      w: 225,
                      justify: "center",
                      h: 100,
                      style: {
                        boxShadow: "#00000018 0 3px 8px",
                      },
                    }}
                    iconSize={32}
                    // onCreated={() => setLoading(true)}
                  />
                </Group>

                <Group>
                  <CreateSMTP
                    buttonProps={{
                      size: "xl",
                      variant: "default",
                      radius: "lg",
                      w: 225,
                      justify: "center",
                      h: 100,
                      style: {
                        boxShadow: "#00000018 0 3px 8px",
                      },
                    }}
                    iconSize={32}
                  />
                  <CreateMassMailing
                    buttonProps={{
                      size: "xl",
                      variant: "default",
                      radius: "lg",
                      w: 225,
                      justify: "center",
                      h: 100,
                      style: {
                        boxShadow: "#00000018 0 3px 8px",
                      },
                    }}
                    iconSize={32}
                  />
                </Group>
              </Group>
            </Stack>
          }
        />
      </TableLayoutBlock>
    </>
  );
};

export default EmailSendersListing;
