import { Group } from "@mantine/core";
import LeadsSearch from "./LeadsSearch";
import LeadsFilter from "./LeadsFilter";
import ReloadButton from "./ReloadButton";
import ExportContent from "./LeadsExport";
import LeadsDelete from "./LeadsDelete";
import LeadCreate from "./LeadCreate";
import LeadsConfig from "./LeadsConfig";
import LeadMarkAuto from "./LeadMarkAuto";
import CaptchaContacts from "../titleSection/captchas/CaptchaContacts";

const Controller = () => {
  return (
    <Group gap={0}>
      <LeadsSearch />
      <LeadsFilter />
      <LeadsConfig />

      <Group ml={"xl"} gap={0}>
        <ExportContent />

        <LeadsDelete />

        <LeadMarkAuto />

        <CaptchaContacts />
      </Group>

      <LeadCreate />
      <ReloadButton />
    </Group>
  );
};

export default Controller;
