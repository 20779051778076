/* eslint-disable react/jsx-no-target-blank */
import { Stack } from "@mantine/core";
import {
  IconAddressBook,
  IconHelp,
  IconSettings,
  IconSpeakerphone,
  IconTimelineEvent,
} from "@tabler/icons-react";
import { useSessionStore } from "../../stores/UserStore";

export const getKnowledges = (lang) => {
  const reseller = useSessionStore.getState().reseller;

  return [
    {
      url: "contact-lists",
      icon: IconAddressBook,
      title: "Listes de contact",
      desc: "Toutes les connaissances sur les listes de contacts",
      items: [
        {
          url: "import-linkedin",
          title: "Créer une liste à partir de LinkedIn",
          content: (
            <Stack>
              <p>
                Avec {reseller?.name}, vous pouvez créer des listes de contacts
                à partir d’une recherche sur LinkedIn ou sales Navigator. Vous
                devez donc disposer d’un compte LinkedIn.
                <br />
              </p>
              <p>
                Le principe est simple, à partir de votre compte LinkedIn, vous
                faites une recherche de contacts sur le site de LinkedIn et
                copiez/collez le lien de votre recherche sur la plateforme
                {reseller?.name}.
                <br />
              </p>
              <p>
                Ainsi, le système créera une liste de contacts correspondants
                aux profils que vous avez ciblés sur LinkedIn.
                <br />
              </p>
              <p>
                Pour réaliser l’import de vos contacts LinkedIn, cliquez sur le
                lien :{" "}
                <a
                  href={`https://${
                    reseller?.domain_saas2 || reseller?.domain_saas
                  }/contact-lists/import/linkedin`}
                  target="_blank"
                  rel="noreferrer"
                >
                  https://{reseller?.domain_saas2 || reseller?.domain_saas}
                  /contact-lists/import/linkedin
                </a>{" "}
                puis cliquez sur Recherche sur LinkedIn
              </p>
              <p>
                Ou allez sur l’onglet “Contact “ &gt; Recherche sur LinkedIn
              </p>
              <p>Vous avez ainsi plusieurs possibilités : </p>
              <b>
                {" "}
                Extraire des contacts à partir d’une recherche LinkedIn/Sales
                Navigator en suivant les étapes ci-dessous :<br />
              </b>
              <ol>
                <li>
                  {" "}
                  Effectuez votre recherche sur LinkedIn/Sales Navigator.{" "}
                </li>
                <li>
                  {" "}
                  Copiez le lien URL de la page de votre recherche sur LinkedIn
                  ou Sales Navigator.
                </li>
                <li>
                  {" "}
                  Collez dans la rubrique “ URL LinkedIn de la recherche” &gt;
                  Suivant.
                </li>
                <li>
                  {" "}
                  Choisissez le nombre de profils à extraire qui devra être
                  supérieur ou égal au nombre de contacts trouvés à l'issue de
                  votre recherche &gt; Suivant.
                </li>
                <li>
                  {" "}
                  Sélectionnez le compte LinkedIn avec lequel vous souhaitez
                  extraire les contacts &gt; Suivant
                </li>
                <li> Donnez un nom à votre liste.</li>
                <li>
                  {" "}
                  Cochez la case enrichissez ma liste si vous souhaitez obtenir
                  les adresses email professionnelles de vos contactes. &gt;
                  Suivant. <br />
                </li>
              </ol>
              <p>
                {" "}
                <b>Note </b>: Le système importe votre liste, il faut compter 20
                minutes pour extraire 2000 contacts, vous retrouvez votre liste
                dans “Contacts”{" "}
              </p>
              <p>
                <b>
                  Extraire des contacts qui ont commenté/liké un post sur
                  LinkedIn en suivant les étapes ci-dessous :<br />
                </b>
              </p>
              <ol>
                <li>
                  {" "}
                  Dans LinkedIn, une fois sur le post de votre choix, cliquez
                  sur les 3 petits points en haut à droite du post et
                  sélectionnez “Copier le lien du post”{" "}
                </li>
                <li>
                  {" "}
                  Dans {reseller?.name}, sur “Contacts” puis “Ciblage” puis
                  “Recherche sur LinkedIn” sélectionnez “Extraire des contacts
                  qui ont commenté un post sur LinkedIn” ou “Extraire des
                  contacts qui ont liké un post sur LinkedIn
                </li>
                <li>
                  {" "}
                  Collez le lien URL dans la rubrique “URL LinkedIn de la
                  recherche”.
                </li>
                <li>
                  {" "}
                  Choisissez le nombre de profils à extraire qui devra être
                  supérieur ou égal au nombre de contacts trouvés à l'issue de
                  votre recherche &gt; Suivant.{" "}
                </li>
                <li>
                  {" "}
                  Sélectionnez le compte LinkedIn avec lequel vous souhaitez
                  extraire les contacts.&gt; Suivant.
                </li>
                <li> Donnez un nom à votre liste &gt; Suivant</li>
                <li>
                  {" "}
                  Cochez la case enrichissez ma liste si vous souhaitez obtenir
                  les adresses email professionnelles de vos contacts.
                </li>
                <li>
                  {" "}
                  Le système importe votre liste, vous retrouvez votre liste
                  dans “Contacts”
                  <br />
                </li>
              </ol>
              <p>
                {" "}
                <b>
                  Extraire des contacts connectés à votre réseau LinkedIn
                  (niveau 1) en suivant les étapes ci-dessous :<br />
                </b>
              </p>
              <ol>
                <li>
                  {" "}
                  Dans {reseller?.name}, sur “Ciblage” puis “Recherche sur
                  LinkedIn” sélectionnez “Extraire vos connexions sur LinkedIn”
                </li>
                <li> Précisez le nombre de profils à importer </li>
                <li>
                  {" "}
                  Sélectionnez le compte LinkedIn avec lequel vous souhaitez
                  extraire les contacts.&gt; Suivant
                </li>
                <li> Donnez un nom à votre liste &gt; Suivant</li>
                <li>
                  {" "}
                  Cochez la case enrichissez ma liste si vous souhaitez obtenir
                  les adresses email professionnelles de vos contacts.
                </li>
                <li>
                  {" "}
                  Le système importe votre liste, vous retrouvez votre liste
                  dans “Contacts”
                </li>
              </ol>
              <p>
                <b>
                  Extraire les publishers d'offres d'emploi sur LinkedIn en
                  suivant les étapes ci-dessous :<br />
                </b>
              </p>
              <ol>
                <li>
                  {" "}
                  Lancez votre recherche d’emploi sur LinkedIn en sélectionnant
                  “emplois” dans LinkedIn.
                </li>
                <li> Copiez le lien de votre recherche.</li>
                <li>
                  {" "}
                  Collez ce lien dans {reseller?.name}, sur “Ciblage” puis
                  “Recherche sur LinkedIn” puis “Extraire les publishers
                  d’offres d’emplois sur LinkedIn”
                </li>
                <li>
                  {" "}
                  Choisissez le nombre de profils à extraire qui devra être
                  supérieur ou égal au nombre de contacts trouvés à l'issue de
                  votre recherche &gt; Suivant.{" "}
                </li>
                <li>
                  {" "}
                  Sélectionnez le compte LinkedIn avec lequel vous souhaitez
                  extraire les contacts.&gt; Suivant
                </li>
                <li> Donnez un nom à votre liste &gt; Suivant</li>
                <li>
                  {" "}
                  Cochez la case enrichissez ma liste si vous souhaitez obtenir
                  les adresses email professionnelles de vos contacts.
                </li>
                <li>
                  {" "}
                  Le système importe votre liste, vous retrouvez votre liste
                  dans “Contacts”.
                </li>
              </ol>
            </Stack>
          ),
        },
        {
          url: "import-google",
          title: "Créer une liste à partir de Google  Map",
          content: (
            <Stack>
              <p>
                Avec {reseller?.name}, à partir de votre compte Google, vous
                pouvez créer des listes de contacts à partir d’une recherche sur
                Google Maps en suivant les étapes ci-dessous :
              </p>
              <p>
                {" "}
                <br />
                Vous pouvez créer une liste de contacts à partir d’une recherche
                sur Google Maps via le lien suivant :{" "}
                <a
                  href={`https://${
                    reseller?.domain_saas2 || reseller?.domain_saas
                  }/contact-lists/import/google`}
                  target="_blank"
                  rel="noreferrer"
                >
                  https://{reseller?.domain_saas2 || reseller?.domain_saas}
                  /contact-lists/import/google
                </a>{" "}
                <br />
                Ou allez sur l’onglet “Ciblage &gt; Google
              </p>
              <p>
                &nbsp;
                <br />
              </p>
              <ol>
                <li>
                  Aller sur Google Maps pour faire une recherche en mettant vos
                  éléments de recherche à savoir la fonction et la situation
                  géographique : Exemple Informatique à Paris.{" "}
                  <a
                    href={`https://www.google.com/maps/search/informatique+paris/@48.8592223,2.3418555,13z/data=!3m1!4b1?entry=ttu`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.google.com/maps/search/informatique+paris/@48.8592223,2.3418555,13z/
                  </a>{" "}
                  <br /> <br />
                </li>
                <li>
                  {" "}
                  Dans la barre de recherche de votre navigateur, copiez le lien
                  URL de la page de votre recherche Google Maps. Exemple :{" "}
                  <a
                    href={`https://www.google.com/maps/search/informatique+paris/@48.8592223,2.3418555,13z/data=!3m1!4b1?entry=ttu`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.google.com/maps/search/informatique+paris/@48.8592223,2.3418555,13z/data=!3m1!4b1?entry=ttu
                  </a>
                  <br />
                </li>
                <li>
                  {" "}
                  Coller le lien URL dans {reseller?.name} sur&nbsp;sur l’onglet
                  “Ciblage &gt; Google” enfin d' extraire les résultats d’une
                  recherche sur Google Maps.
                  <br />
                </li>
                <li>
                  Sélectionner votre compte Google que vous avez configuré.{" "}
                  <br />
                </li>
                <li>
                  {" "}
                  Choisir le nombre de résultats / profils à extraire.
                  <br />
                </li>
                <li>
                  {" "}
                  Donner un nom à votre liste afin de la sauvegarder ou
                  sélectionnez le dossier et une liste de contacts existante.
                  <br />
                  Le système importera votre liste que vous retrouverez dans
                  l'onglet “Contacts”.
                  <br />
                  Vous pouvez aussi cocher l'option d'enrichissement ''Enrichir
                  base hors France'' de votre liste afin de la compléter des
                  informations juridiques et y ajouter les mails pour des listes
                  étranges <br />
                </li>
              </ol>
            </Stack>
          ),
        },
        {
          url: "import-database",
          title: "Créer une liste à partir de la base de contacts",
          content: (
            <Stack>
              <p>
                Vous avez la possibilité sur la plateforme de créer une liste de
                contacts à partir de notre base de plusieurs millions de
                contacts BtoB.
                <br />
              </p>
              <p>
                Pour créer une liste de contacts à partir de notre base de
                données en cliquant sur le lien suivant :{" "}
                <a
                  href={`https://${
                    reseller?.domain_saas2 || reseller?.domain_saas
                  }/contact-lists/import/base`}
                  target="_blank"
                  rel="noreferrer"
                >
                  https://{reseller?.domain_saas2 || reseller?.domain_saas}
                  /contact-lists/import/base
                </a>{" "}
                <br />
              </p>
              <p>
                Ou allez sur l’onglet <b>Ciblage</b>
              </p>
              <ul>
                <li>
                  Vous avez ainsi la possibilité d’affiner votre recherche en
                  ajoutant plus de critères comme la fonction, le domaine
                  d’activité et encore la zone géographique.
                </li>
                <li>
                  Vous pouvez aussi filtrer votre liste en précisant le code
                  NAF, l’activité de l'entreprise, son chiffre d'affaires, sa
                  forme juridique, sa présence sur le web : Cliquez à chaque
                  fois sur la flèche bleue à côté de chaque filtre pour ajouter
                  votre choix.
                </li>
              </ul>
              <p>
                <br />
                Pour enregistrer les contacts trouvés sur votre liste, Cliquez
                sur le bouton “enregistrer” à droite des profils qui
                apparaissent.
                <br />
              </p>
              <p>
                <b>Note </b>: vous avez la possibilité de créer ou d’ajouter ces
                contacts à une liste déjà créée.
                <br />
              </p>
              <p>
                Votre liste est ainsi ajoutée dans en cliquant sur le lien
                suivant :{" "}
                <a
                  href={`https://${
                    reseller?.domain_saas2 || reseller?.domain_saas
                  }/contact-lists`}
                  target="_blank"
                  rel="noreferrer"
                >
                  https://{reseller?.domain_saas2 || reseller?.domain_saas}
                  /contact-lists
                </a>{" "}
              </p>
              <p>
                Ou allez sur la page <b>Contacts</b>
              </p>
            </Stack>
          ),
        },
        {
          url: "filters",
          title: "Filtrer mes contacts dans une liste",
          content: (
            <Stack>
              <p>
                Une fois que votre liste est créée, vous aurez la possibilité de
                trier votre liste. Si elle ne vous convient pas, ou que vous
                désirez la filtrer, vous pouvez alors exclure des contacts.{" "}
                <br />
              </p>
              <p>
                Sur la page Contacts, vous pouvez cliquer sur le nom de votre
                liste et vous verrez apparaître le détail de votre liste.
                <br />
              </p>
              <p>
                Vous trouverez notamment au-dessus de votre liste, une icône
                “Filtres” qui vous permettra d’exclure des contacts de votre
                liste,
                <br />
              </p>
              <p>
                Par exemple : Je souhaite supprimer tous les contacts dont “la
                fonction” contient “Assistant”.{" "}
              </p>
            </Stack>
          ),
        },
        {
          url: "linkedin-import-not-working",
          title: "L'import des contacts LinkedIn ne fonctionne pas",
          content: (
            <Stack>
              <p>
                Si votre import LinkedIn ne fonctionne pas, vous pouvez vérifier
                ces points :
              </p>
              <p>
                <br />
                1.Aller sur{" "}
                <a
                  href={`https://${
                    reseller?.domain_saas2 || reseller?.domain_saas
                  }/senders/linkedin`}
                  target="_blank"
                  rel="noreferrer"
                >
                  https://{reseller?.domain_saas2 || reseller?.domain_saas}
                  /senders/linkedin
                </a>
              </p>
              <p>Ou dans “Configurations” puis “Comptes LinkedIn” </p>
              <ul>
                <li>
                  Vérifier que votre compte est toujours connecté
                  <br />
                  Si non, réactualiser le cookie de connexion
                  <br />
                </li>
              </ul>
              <p></p>
              <p>
                2. Aller sur{" "}
                <a
                  href={`https://${
                    reseller?.domain_saas2 || reseller?.domain_saas
                  }/contact-lists/import/linkedin`}
                  target="_blank"
                  rel="noreferrer"
                >
                  https://{reseller?.domain_saas2 || reseller?.domain_saas}
                  /contact-lists/import/linkedin <br />
                </a>
              </p>
              <p>
                Ou dans ''Ciblage'' &gt; ''LinkedIn''
                <br />
              </p>
              <ul>
                <li>
                  <b>A</b> : Vérifier que le lien importé est bien compatible
                  avec la nature de votre compte LinkedIn (un compte non Sales
                  Navigator ne peut pas importer un lien Sales Navigator)
                  <br />
                </li>
              </ul>
              <ul>
                <li>
                  <b>B</b> : Vérifier que le format du lien est bien celui
                  demandé lors de la création de la liste par le système
                </li>
              </ul>
              <p></p>
              <p>
                Si votre import ne fonctionne toujours pas, n’hésitez pas à nous
                solliciter, nous sommes la pour vous aider !
              </p>
            </Stack>
          ),
        },
        {
          url: "page",
          title: "Fonctionnalités de la page Contacts",
          content: (
            <Stack>
              <p>
                L'onglet liste vous permet d'y retrouver l’ensemble de vos
                listes de contacts afin de réaliser vos campagnes de
                prospection.
                <br />
                <br />
              </p>
              <p>
                Vous y retrouverez toutes les fonctionnalités et des outils pour
                une bonne gestion de vos listes de contacts
                <br />
              </p>
              <ul>
                <li>
                  Création de liste à partir de notre base de contacts B2B
                  <br />
                </li>
                <li>Création de liste à partir de LinkedIn</li>
                <li>Création de vos listes à partir de Google</li>
                <li>Importation de vos listes</li>
              </ul>

              {/* Image here */}

              <p>
                <b>
                  VUE DES DOSSIERS
                  <br />
                </b>
              </p>
              <ul>
                <li>
                  Afficher toutes les listes
                  <br />
                </li>
                <li>Créer un dossier</li>
                <li>Renommer un dossier</li>
                <li>Effacer un dossier</li>
                <li>Rechercher un dossier</li>
              </ul>
              <p>
                <b>
                  <br />
                  COLONNE ''ACTION''
                  <br />
                  Bouton ''Crayon'' vous pouvez :
                </b>
              </p>
              <ul>
                <li>Renommer votre liste</li>
                <li>Ajouter des Tags</li>
                <li>Créer et déplacer votre liste dans un dossier</li>
              </ul>
              <p>
                <br />
              </p>
              <p></p>
              <p>
                <b>Bouton ''+'' vous pourrez :</b>
                <br />
              </p>
              <ul>
                <li>Voir les contacts de votre liste dans un nouvel onglet</li>
                <li>Importer une liste de contacts depuis un fichier</li>
                <li>Importer une liste de contacts par un copier-coller</li>
                <li>Télécharger votre fichier en CSV ou XLSX</li>
                <li>Dupliquer votre liste</li>
                <li>
                  Copier une liste à un utilisateur, une équipe, une entreprise
                </li>
                <li>Copier votre liste au PRM</li>
                <li>Diviser votre liste en X listes (splitter)</li>
              </ul>
              <p>
                <br />
              </p>
              <p>
                <b>Bouton ''Corbeille''</b>
                <br />
              </p>
              <ul>
                <li>Effacer votre liste</li>
              </ul>
              <p>
                <br />
                <b>INFO </b>: Vous pouvez sélectionner plusieurs listes afin de
                réaliser simultanément une des actions
              </p>

              {/* Image here */}

              <p>
                <b>COLONNE "ACTION"</b>
              </p>
              <ul>
                <li>Télécharger vos contacts en CSV ou XLSX</li>
                <li>
                  Dupliquer la sélection de vos contacts dans une nouvelle liste
                </li>
                <li>
                  Copier une liste à un utilisateur, une équipe, une entreprise
                </li>
                <li>
                  Copier la sélection de vos contacts à un utilisateur, une
                  équipe, une entreprise
                </li>
                <li>Copier&nbsp;la sélection de vos contacts au PRM</li>
                <li>
                  Diviser&nbsp;la sélection de vos contacts en X listes
                  (splitter)
                </li>
              </ul>
              <p>
                <br />
              </p>
              <p>
                <b>Bouton ''Corbeille''</b>
              </p>
              <ul>
                <li> Effacer votre liste</li>
              </ul>
              <p></p>
              <p></p>

              {/* Image here */}

              <p>
                <b>
                  Barre d'outils d’accès rapides
                  <br />
                </b>
              </p>
              <ul>
                <li>Rechercher dans une liste ou les dossiers</li>
                <li>Filtre de recherche multicritères avec conditions</li>
                <li>Gestion de l'affichage des colonnes</li>
                <li>Exporter une liste ou un dossier</li>
                <li>Supprimer une liste ou un dossier</li>
              </ul>
            </Stack>
          ),
        },
      ],
    },
    {
      url: "configurations",
      icon: IconSettings,
      title: "Configurations",
      desc: "Configurer le logiciel de prospection",
      items: [
        {
          url: "add-exclusions",
          title: "Créer vos listes de désinscrits ou de Blacklists",
          content: (
            <Stack>
              <p>
                Sur la plateforme, vous avez la possibilité d’exclure des
                contacts de vos campagnes.
                <br />
                <br />
                Ainsi ils ne recevront pas vos messages et seront exclus
                automatiquement de vos campagnes / scénarios.
                <br />
                <br />{" "}
              </p>
              <p>
                C’est un principe de précaution afin de ne pas solliciter vos
                prospects, clients, partenaires, fournisseurs, etc… sans avoir à
                pointer manuellement chaque contact de votre liste avant l’envoi
                de votre campagne.
                <br />
                <br />
              </p>
              <p>Pour cela, il suffit de :</p>
              <p>
                {" "}
                Importer une liste des contacts sous forme de fichier CSV ici{" "}
                <a
                  href={`https://${
                    reseller?.domain_saas2 || reseller?.domain_saas
                  }/exclusions/blacklists`}
                  target="_blank"
                  rel="noreferrer"
                >
                  https://{reseller?.domain_saas2 || reseller?.domain_saas}
                  /exclusions/blacklists.
                  <br />
                </a>
                OU de cliquer sur l’onglet “Configuration” &gt; “Blackliste”&gt;
                “Importer'' une liste de contact à repousser”. <br />
                <br />
                Vous pouvez aussi créer des dossiers de vos listes à repousser
                par typologie d'exclusion comme vos clients, les personnes à ne
                jamais contacter...
                <br />
                <br />
              </p>
              <p>
                Les clés de désabonnement prises en compte par le système sont :
                l’email, le nom de domaine, le SIRET, le SIREN, le lien LinkedIn
                ou la raison sociale.
                <br />
                <br />
              </p>
              <p>
                <b>Pour désabonner définitivement des contacts</b>, Il faut
                aller sur cette page :{" "}
                <a
                  href={`https://${
                    reseller?.domain_saas2 || reseller?.domain_saas
                  }/exclusions/unsubscribers`}
                  target="_blank"
                  rel="noreferrer"
                >
                  https://{reseller?.domain_saas2 || reseller?.domain_saas}
                  /exclusions/unsubscribers
                </a>{" "}
                .<br />
                Ou de cliquer sur l’onglet “Configuration” &gt; “Liste des
                désinscrits”
                <br />
                <br />
              </p>
              <p>
                <b>Attention</b>: Le fichier doit contenir une seule information
                par ligne. <br />
                Ces informations peuvent êtres mixées dans votre fichier
                d'importation.
                <br />
                <b>
                  <br />
                </b>
              </p>
              <p>
                <b>
                  La différence entre une liste repoussoir et un désabonnement :
                  <br />
                  <br />
                </b>
              </p>
              <p>
                {" "}
                <b>Le désabonnement </b>est automatique et irréversible : Une
                fois que le contact est marqué comme désabonné, vous pouvez être
                certain qu’il ne recevra plus rien de votre part.
                <br />
                <br />
              </p>
              <p>
                {" "}
                <b>La base repoussoir</b> se sélectionne à chaque campagne en
                fonction de votre volonté ou non de la prendre en compte sur
                chaque envoi.
              </p>
            </Stack>
          ),
        },
        {
          url: "connect-email",
          title: "Connecter mon compte e-mail",
          content: (
            <Stack>
              <p>
                Avant de lancer une campagne e-mail, il faut faire attention à
                la méthode d’envoi et à l’e-mail expéditeur choisi.
              </p>
              <p>Vous aurez ainsi deux alternatives:</p>
              <ol>
                <li>
                  {" "}
                  Envoi en mass mailing : cela permet d’envoyer des campagnes à
                  de gros volumes et rapidement, mais avec un taux d’ouvreurs
                  moins important.
                  <br />
                  Les envois (routage) se font au travers de la plateforme
                  {reseller?.name}. Vous avez la possibilité de configurer un
                  alias lors de l’envoi. Un alias (artefact) est le mail qui
                  sera vu par vos prospects, mais qui ne sera pas utilisé lors
                  des envois.
                  <br />
                  <b>Attention</b>, {reseller?.name} n'apparaîtra jamais lors de
                  vos envois en mass mailing
                </li>
                <li>
                  {" "}
                  Envoi depuis votre boîte e-mail : Cela permet d’augmenter vos
                  taux de réponse en communiquant avec chaque prospect à un
                  niveau individuel.
                </li>
              </ol>
              <p>
                {" "}
                Vous pouvez connecter vos boîtes e-mail à la plateforme. Vous
                pourrez voir lors de l’envoi de la campagne, les e-mails envoyés
                dans votre onglet « E-mails envoyés » sur votre boite mail.{" "}
              </p>
              <p>
                Attention, nous vous recommandons de limiter les envois à 100
                e-mails / jour par e-mail expéditeur.
                <br />
                <br />
              </p>
              <p>
                <b>
                  Pour configurer vos e-mails, nous vous invitons à cliquer sur
                  le lien :{" "}
                </b>
              </p>
              <p>
                <a
                  href={`https://${
                    reseller?.domain_saas2 || reseller?.domain_saas
                  }/senders/email`}
                  target="_blank"
                >
                  https://{reseller?.domain_saas2 || reseller?.domain_saas}
                  /senders/email
                </a>{" "}
                ou allez sur l’onglet ‘’Outil’’ &gt; Emails expéditeurs
              </p>
              <p>Vous aurez deux possibilités :</p>
              <ol>
                <li>
                  {" "}
                  Cliquez sur ‘’Ajoutez votre email en tant qu'expéditeur’’
                  (bouton bleu) qui correspond à l’ajout d’un alias mass
                  mailing.
                  <br />- Entrez votre e-mail puis validez sur ‘’Enregistrer’’.
                  Vous recevrez un e-mail de validation sur votre boîte email
                  pour nous assurer que le domaine vous appartient.
                </li>
                <li>
                  {" "}
                  Cliquez sur “Envoyer votre e-mail à partir de votre domaine
                  SMTP’’ (bouton vert) qui correspond à la connexion de votre
                  boite mail.
                </li>
              </ol>
              <p>
                {" "}
                À l'ouverture du pop-up, sélectionnez le fournisseur de votre
                compte e-mail
              </p>
              <ul>
                <li>
                  &nbsp;Pour Gmail ou Outlook, sélectionnez l’adresse e-mail que
                  vous souhaitez ajouter puis suivre les étapes suivantes.
                </li>
                <li>
                  &nbsp;Pour les autres fournisseurs e-mail, renseignez les
                  informations demandées.
                  <br />
                  <br />
                </li>
              </ul>
              <p>
                <b>Configuration complémentaire :</b>
              </p>
              <p>
                Ces options vont vous permettre d'optimiser la bonne réception
                de vos e-mails :
              </p>
              <ul>
                <li> Activer / Désactiver le lien de désabonnement.</li>
                <li>
                  {" "}
                  Activer / Désactiver le tracking des ouvreurs/cliqueurs.
                </li>
                <li>
                  {" "}
                  Fréquences des envois : Laps de temps entre deux envois d’un
                  e-mail.
                </li>
                <li>
                  {" "}
                  Warm UP : Préchauffage d’un e-mail ( technique de préservation
                  de réputation IP).
                </li>
                <li>
                  {" "}
                  Pool SMTP : Pouvoir rattacher vos e-mails expéditeurs à un
                  pool SMTP.
                </li>
              </ul>
            </Stack>
          ),
        },
        {
          url: "email-validation",
          title: "Validation d'un E-mail",
          content: (
            <Stack>
              <p>
                <i>
                  <b>Question </b>: J’ai reçu le mail de validation d'ajout d'un
                  compte E-mail en provenance de De :{" "}
                  <a
                    href={`http://${
                      reseller?.domain_saas2 || reseller?.domain_saas
                    }`}
                    target="_blank"
                  >
                    {reseller?.domain_saas2 || reseller?.domain_saas}
                  </a>{" "}
                  info@{reseller?.domain_saas2 || reseller?.domain_saas} ce qui
                  me semble anormal.
                </i>
              </p>
              <br />
              <p>
                <b>Réponse </b>: Ce n’est pas une erreur, car la validation d’un
                nouveau compte (procédure système) provient de la plateforme{" "}
                <b>{reseller?.name}</b>.<br />
              </p>
              <p>
                Afin de disposer d'un expéditeur personnalisé pour les alertes
                ou les validations du logiciel, il faut changer de forfait afin
                de passer en Marque blanche.
                <br />
              </p>
              <p>Merci de contacter le service commercial à ce sujet</p>
            </Stack>
          ),
        },
        {
          url: "create-signature",
          title: "Créer vos signatures d’e-mail",
          content: (
            <Stack>
              <p>
                Lorsque vous créez un e-mail, vous pouvez ajouter au contenu
                votre signature. <br />
              </p>
              <p>
                Pour faire cela, vous pouvez préalablement importer sur la
                plateforme votre signature.
              </p>
              <p>
                Cliquez sur le lien{" "}
                <a
                  href={`https://${
                    reseller?.domain_saas2 || reseller?.domain_saas
                  }/senders/email-signatures`}
                  target="_blank"
                >
                  https://{reseller?.domain_saas2 || reseller?.domain_saas}
                  /senders/email-signatures
                </a>{" "}
                puis cliquer sur le bouton ‘‘Créer une signature’’
              </p>
              <p>
                Ou allez sur l’onglet “Configurations” &gt; Signature des
                E-mails.
                <br />
              </p>
              <p>
                Une fois votre signature ajoutée, lorsque vous modifierez ou
                créerez votre modèle de scénario, vous pouvez cliquer sur le
                bouton ''Signature'' pour ajouter votre signature en choisissant
                l'une des signatures déjà créées. <br />
                <br />
              </p>
              <p>
                <b>INFO </b>: Lors de la création de vos messages d'e-mails,
                vous pourrez malgré tout prévisualiser votre message avec la
                signature voulue
              </p>
              <p></p>
            </Stack>
          ),
        },
        {
          url: "dynamic-columns",
          title: "Colonnes dynamiques",
          content: (
            <Stack>
              <p>
                Les colonnes dynamiques sont utilisées lors d'une importation
                d'une liste de contacts et que les colonnes dans votre fichier
                n'ont pas été prévue et créée par le logiciel.
              </p>

              {/* Image here */}

              <p>
                Afin de pallier à ce manque de colonnes, lors de l'importation
                de votre fichier, vous avez la possibilité de créer ces colonnes
                dynamiques.
                <br />
                <br />
              </p>
              <p>
                Pour la gestion de ces colonnes : l'ajout ou la suppression,
                vous pouvez aller ici :{" "}
                <a
                  href={`https://${
                    reseller?.domain_saas2 || reseller?.domain_saas
                  }/extra/datafields`}
                  target="_blank"
                >
                  https://{reseller?.domain_saas2 || reseller?.domain_saas}
                  /extra/datafields
                </a>
                &nbsp;{" "}
              </p>
              <p>
                Ou dans l'onglet ''Configurations'' -&gt; colonnes dynamiques
              </p>
              <p>
                <br />
                Dans la colonne ''<b>Action</b>'', vous pouvez :{" "}
              </p>
              <p>
                <b>Effacer </b>: permets d'effacer la colonne dynamique.
              </p>
              <p>
                <br />
                <b>ATTENTION </b>: Cette action d'effacer une colonne dynamique
                va aussi effacer <b>TOUTES LES DONNÉES</b> de cette colonne{" "}
                <a href={`http://dynamique.La`} target="_blank">
                  dynamique.
                  <br />
                </a>
                <a href={`http://dynamique.La`} target="_blank">
                  <br />
                </a>
                La colonne ''<b>Partage</b>'' vous permet de partager cette
                colonne à vos collaborateurs, une équipe ou à une entreprise.
              </p>
            </Stack>
          ),
        },
      ],
    },
    {
      url: "campaigns",
      icon: IconSpeakerphone,
      title: "Campagnes",
      desc: "La base sur vos envois de campagnes",
      items: [
        {
          url: "statistics",
          title: "Statistiques de vos campagnes",
          content: (
            <Stack>
              <p>
                Sur la plateforme {reseller?.name}, vous disposez des
                statistiques de vos compagnes.
                <br />
              </p>
              <p>
                Sur votre dashboard vous trouverez le nombre d’emails envoyés,
                emails programmés, emails cliqués, emails ouverts, emails reçus.
                <br />
              </p>
              <p>
                Pour les campagnes LinkedIn, les KPIs à suivre seront les taux
                d’acceptation de vos invitations (niveau de relation 1), les
                taux de réponse et les taux de lecture de vos messages (niveau
                de relation 2 et 3).
                <br />
              </p>
              <p>
                La data est reproduite sous forme de tableau : ainsi vous avez
                les statistiques analysant vos compagnes organisées campagne par
                campagne.
                <br />
              </p>
              <p>
                Cliquez sur le lien :{" "}
                <a
                  href={`https://${
                    reseller?.domain_saas2 || reseller?.domain_saas
                  }/statistics/campaigns`}
                  target="_blank"
                  rel="noreferrer"
                >
                  https://{reseller?.domain_saas2 || reseller?.domain_saas}
                  /statistics/campaigns
                </a>
              </p>
              <p>Ou allez sur l’onglet ‘’Campagnes’’</p>
              <p>
                <br />
                <b>Dans le tableau vous trouverez :</b>
              </p>
              <ul>
                <li>
                  {" "}
                  Les emails programmés : le nombre d’emails qui sont en cours
                  d'exécution.
                </li>
                <li>
                  {" "}
                  Le nombre d’emails envoyés à partir des emails programmés.
                </li>
                <li>
                  {" "}
                  Les emails ouverts : Le nombre d’emails ouverts dès leurs
                  réceptions.
                </li>
                <li>
                  {" "}
                  Les emails cliqués : le nombre d'emails ou il y a un lien sur
                  lequel le receveur a cliqué.
                </li>
                <li>
                  {" "}
                  Le nombre des réponses reçues suite à l'envoi des emails.
                </li>
              </ul>
              <p>
                <br />
                Si vous souhaitez plus de détails et accéder aux statistiques
                d’envoi quotidien, vous pouvez cliquer sur l'icône “Rapport” à
                droite de chacune de vos campagnes sur l’onglet des
                statistiques.{" "}
              </p>
            </Stack>
          ),
        },
        {
          url: "view-campaigns",
          title: "Retrouver mes campagnes programmées",
          disabled: true,
          content: (
            <Stack>
              <p>
                Vous avez la possibilité de voir toutes vos campagnes une fois
                qu’elles sont programmées en cliquant sur l’onglet “Campagnes” :
                <br />
              </p>
              <p>
                Vous pouvez filtrer rapidement vos campagnes en choisissant “ En
                cours, En pause, Erreur, Archivé''
                <br />
                <br />
                Vous pouvez aussi utiliser les filtres multi-critères par la
                fonction ''Filtres''
                <br />
              </p>
            </Stack>
          ),
        },
      ],
    },
    {
      url: "leads-tracking",
      icon: IconAddressBook,
      title: "Suivi des leads",
      desc: "Gestion de la Relation Prospects",
      items: [
        {
          url: "general",
          title: "Suivi des leads en général",
          disabled: true,
          content: (
            <Stack>
              <p>
                Pour chaque campagne envoyée, le système crée un dossier sur la
                plateforme dans lequel il stocke vos contacts actifs : c’est le
                PRM (Gestion de la Relation Prospects).
                <br />
              </p>
              <p>
                On ne gère pas un PRM comme on gère un CRM (Gestion de la
                Relation Clients).
                <br />
              </p>
              <p>
                Pour une raison assez simple à comprendre : un client, ce n’est
                pas un prospect et nous ne nous adressons pas de la même manière
                à un client qu'à un prospect.
                <br />
              </p>
              <p>
                Les démarches relationnelles et les actions qui en découlent
                sont différentes !
              </p>
              <p>
                À la différence d'un CRM, le PRM permet d'automatiser des tâches
                de relances au travers du scénario, d'orienter le prospect vers
                de nouvelles boucles de prospections, d'activer le nurturing en
                fonction de son statut : intéressé, pas encore prêt ...
                <br />
              </p>
              <p>
                Ainsi, dans chaque dossier, vous retrouverez les prospects de
                chaque campagne de façon indépendante : les ouvreurs, cliqueurs,
                répondeurs, personnes ayant accepté votre invitation LinkedIn,
                personnes vous ayant répondu sur LinkedIn, etc.
                <br />
              </p>
              <p>
                Le PRM est accessible sur{" "}
                <a
                  href={`https://${
                    reseller?.domain_saas2 || reseller?.domain_saas
                  }/leads-tracking`}
                  target="_blank"
                  rel="noreferrer"
                >
                  https://{reseller?.domain_saas2 || reseller?.domain_saas}
                  /leads-tracking
                </a>{" "}
              </p>
              <p>
                ou par l’onglet “PRM”.
                <br />
              </p>
              <p>
                Dans chaque dossier PRM, vous retrouverez ainsi vos contacts
                actifs, classés par statut et par ordre de scoring décroissant.
                <br />
              </p>
              <p>
                Vous pouvez aussi synchroniser automatiquement dans votre CRM
                après avoir créé une synchronisation avec {reseller?.name} ou
                télécharger vos contacts actifs et les travailler directement
                sur votre CRM
              </p>
            </Stack>
          ),
        },
      ],
    },
    {
      url: "workflows",
      icon: IconTimelineEvent,
      title: "Scénario",
      desc: "Tout sur les scénarios",
      items: [
        {
          url: "create-email",
          title: "Créer un scénario",
          content: (
            <Stack>
              <p>
                Sur la plateforme vous pouvez créer des scénarios qui
                représentent une succession d’actions déclenchées à des
                conditions prédéfinies.
                <br />
                <br />
              </p>
              <p>
                Pour créer un nouveau scénario, nous vous invitons à aller sur
                ce lien :{" "}
                <a
                  href={`https://${
                    reseller?.domain_saas2 || reseller?.domain_saas
                  }/campaign/create`}
                  target="_blank"
                  rel="noreferrer"
                >
                  https://{reseller?.domain_saas2 || reseller?.domain_saas}
                  /campaign/create
                  <br />
                  <br />
                </a>
              </p>
              <p>
                Ou allez sur l’onglet “Mes scénarios” &gt; Créer un nouveau
                scénario &gt; Ajouter un nom de scénario.
                <br />
                <br />
              </p>
              <p>Voici la procédure à suivre pour créer un scénario :</p>
              <ol>
                <li>
                  &nbsp;Choisissez le canal que vous souhaitez parmi LinkedIn /
                  Email / SMS / Message vocal.
                </li>
                <li>
                  {" "}
                  Premier message : Ajoutez votre message de prospection à votre
                  arborescence de scénario en cliquant sur le petit plus
                  au-dessous de l’action initiale.
                </li>
                <li>
                  {" "}
                  Relance : Choisissez le canal par lequel vous souhaitez faire
                  la relance.
                </li>
                <li>
                  {" "}
                  Sélectionnez la population qui recevra votre relance
                  (ouvreurs, cliqueurs, non-actifs, personnes ayant accepté
                  votre invitation sur LinkedIn, etc.) et le délai dans lequel
                  votre relance partira.
                </li>
                <li> Ajoutez les contenus de vos messages de relance.</li>
                <li>
                  {" "}
                  Enregistrez votre scénario.
                  <br />
                  <br />
                </li>
              </ol>
              <p>
                <b>Attention</b>, assurez-vous d’avoir précédemment créé vos
                différents messages sur le lien :{" "}
                <a
                  href={`https://${
                    reseller?.domain_saas2 || reseller?.domain_saas
                  }/models/email`}
                  target="_blank"
                  rel="noreferrer"
                >
                  https://{reseller?.domain_saas2 || reseller?.domain_saas}
                  /models/email
                </a>{" "}
                ou en allant sur l'onglet ‘’Mes modèles d’emails’’
              </p>
            </Stack>
          ),
        },
      ],
    },
    {
      url: "faq-guides-questions",
      icon: IconHelp,
      title: "FAQ, GUIDES & QUESTIONS",
      desc: "Questions fréquentes sur l'application",
      items: [
        {
          url: "smtp-limit",
          title: "Limite SMTP atteinte",
          content: (
            <Stack>
              <p>
                <b>Question </b>:{" "}
                <i>
                  Je suis en train d’essayer de saisir de nouveaux emails
                  expéditeurs, et quand j’arrive à la fin de la saisie j’ai un
                  message d’erreur qui apparait me disant : LIMITE SMTP atteinte
                </i>
              </p>

              <br />
              <p>
                <b>Réponse </b>: Vous avez atteint la limite du nombre de
                comptes à intégrer au logiciel.
              </p>
              <p>
                Il faut vérifier la limite du nombre de comptes de votre offre.
                <br />
              </p>
              <p>
                Si vous souhaitez disposer de plus de comptes SMTP, veuillez
                passer à une offre supérieure
              </p>
            </Stack>
          ),
        },
        {
          url: "edit-sender-name",
          title: "Comment modifier le nom d'expéditeur ?",
          content: (
            <Stack>
              <p>
                <b>Question </b>:{" "}
                <i>
                  Lorsqu'un prospect répond à un mail, je reçois ce mail de
                  ''Nicolas de {reseller?.name}''. Comment modifier le nom
                  d'expéditeur ?
                </i>
              </p>
              <br />
              <p>
                <b>Réponse </b>: Lorsque vous créez/modifiez votre email ici :{" "}
                <a
                  href={`https://${
                    reseller?.domain_saas2 || reseller?.domain_saas
                  }/models/email`}
                  target="_blank"
                  rel="noreferrer"
                >
                  https://{reseller?.domain_saas2 || reseller?.domain_saas}
                  /models/email
                </a>
                <br />
                <br />
              </p>
              <p>
                À droite du champ « e-mail de retour » vous avez un « + » qui
                vous permet de choisir l’option de la réponse
                <br />
              </p>
              <ol>
                <li>
                  ==&gt; Option 01 : Vous recevez l’e-mail en direct comme si
                  votre prospect vous répondait directement sans que la réponse
                  passe par notre plateforme. À ce moment-là, vous n’aurez plus
                  les problèmes remontés, mais nous ne pourrons pas sauvegarder
                  la réponse reçue à l’intérieur de notre plateforme
                  <br />
                  <br />
                </li>
                <li>
                  ==&gt; Option 02 : (celle sélectionnée pour le moment) Lorsque
                  le prospect répond, il répond à notre serveur qui vous
                  retransfère l’e-mail, c’est la raison pour laquelle vous voyez
                  apparaitre « Nicolas de {reseller?.name} ». Cette solution
                  nous permet de sauvegarder la réponse du prospect à
                  l’intérieur de la plateforme.
                  <br />
                  <br />
                </li>
              </ol>
              Avec cette solution, une fois que vous recevez la réponse, il faut
              mieux ouvrir une nouvelle conversation pour continuer l’échange
              avec votre prospect.
            </Stack>
          ),
        },
        {
          url: "checkout-failed",
          title: "Pourquoi mon paiement par carte échoue ?",
          content: (
            <Stack>
              <p>
                Nous utilisons un système de paiement sécurisé (3D secure) agréé
                par l’ACPR, sous le numéro 16568 afin de protéger vos achats.
                <br />
                <br />
              </p>
              <p>
                Vérifiez la réception d’un SMS afin de valider votre achat.{" "}
                <br />
                Si le problème persiste, contactez-nous.
              </p>
            </Stack>
          ),
        },
        {
          url: "segment-contact-list",
          title: "Est-il possible de segmenter mes listes de contacts ?",
          content: (
            <Stack>
              <p>
                <b>Oui, </b>en créant autant de listes de contacts de
                prospection que vous le désirez.
                <br />
                <br />
              </p>
              <p>
                Grâce à nos filtres intelligents, vous pouvez combiner les
                filtres de recherche : Fonction, secteur d’activité, code NAF,
                Effectif, Chiffre d’affaires, civilité, centre d’intérêt, centre
                de compétences …<br />
                <br />
              </p>
              <p>
                Si un filtre vous manque parmi nos 20 filtres, n’hésitez pas à
                nous le demander.
              </p>
            </Stack>
          ),
        },
        {
          url: "database-reactive",
          title: "Votre base de contacts est-elle réactive ?",
          content: (
            <Stack>
              <p>
                <b>Oui</b>, car nos bases sont testée lors de l’envoi des
                e-mailngs pour nos clients.
                <br />
              </p>
              <p>
                Grâce aux traceurs que nos mettons dans nos mails lors de
                l’envoi, il nous est possible de contrôler si le prospect ouvre
                sa boite e-mail.
                <br />
              </p>
              <p>
                Si un prospect n’ouvre pas sa boite mail dans le mois, nous
                mettons en isolement ce contact afin de ne plus le prospecter.
                <br />
              </p>
              <p>
                Ce qui nous permet de disposer d’une base de contacts B2B
                réactive.
                <br />
              </p>
            </Stack>
          ),
        },
        {
          url: "cname-error",
          title: "Messages d'erreurs CNAME",
          content: (
            <Stack>
              <p>
                <b>Question </b>: J'ai ce message d'erreur quand je veux accéder
                à ma marque blanche
              </p>
              <p>{`{"state":false,"state_message":"action_not_exist","documentation":null}`}</p>
              <p>
                <br />
              </p>
              <p>
                <b>Réponse </b>: Peut-être que vous n'avez pas bien renseigné
                les paramètres dans le logiciel ou dans la redirection CNAME de
                votre domaine.
                <br />
                Ce problème provient toujours d'une erreur de configuration.
              </p>
              <p>
                <br />
                <b>
                  Consulter les enregistrements CNAME auprès de votre hébergeur
                  de domaine
                </b>
              </p>
              <ul>
                <li>
                  Sous-domaine mail tapé dans votre domaine CNAME
                  <br />
                  <br />
                  <b>Consulter les paramètres dans le logiciel</b>
                  <br />
                </li>
                <li>Erreur de saisie du nom de domaine dans le logiciel</li>
              </ul>
              <p>
                <br />
              </p>
              <p>
                Solution de vérification : Exécuter <b>nslookup </b>sur votre
                ordinateur
                <br />
                <br />
              </p>
              <p>
                L'application nslookup est disponible sur la plupart des
                systèmes d'exploitation. <br />
                Elle permet de rechercher les informations concernant les
                serveurs de noms à l'aide d'une ligne de commande sous Microsoft
                Windows, Apple Mac ou Linux.
                <br />
                <br />
                Pour exécuter la ligne de commande sous Windows, cliquez sur
                Démarrer &gt; Exécuter.&nbsp;
              </p>
              <ul>
                <li>Saisissez cmd, puis appuyez sur Entrée.</li>
                <li> Sous Mac ou Linux, démarrez le terminal.</li>
                <li> Saisissez la commande suivante :</li>
                <li>
                  {" "}
                  nslookup -q=cname{" "}
                  <a href={`saas.example.com`} target="_blank">
                    saas.example.com
                  </a>{" "}
                </li>
                <li>
                  {" "}
                  Remplacez{" "}
                  <a
                    href={`http://example.com`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    example.com
                  </a>{" "}
                  par le nom du domaine contenant l'enregistrement CNAME que
                  vous souhaitez consulter.
                  <br />
                  <br />
                </li>
              </ul>
              <p>
                {" "}
                Interprétez le résultat comme dans l'exemple suivant,&nbsp;
                <a href={`example.com`} target="_blank">
                  example.com
                </a>{" "}
                :
              </p>
              <p>
                {" "}
                nslookup -q=cname{" "}
                <a href={`saas.exemple.com`}>saas.exemple.com</a>
              </p>
              <p>
                {" "}
                <br />
                Avec cet exemple, nous obtenons les données suivantes :
              </p>
              <p> Server: box </p>
              <p> Address: 192.168.1.1</p>
              <p> Réponse ne faisant pas autorité :</p>
              <p>
                {" "}
                <a href={`saas.exemple.com`} target="_blank">
                  saas.exemple.com
                </a>
                &nbsp; canonical name =&nbsp;
                <a
                  href={`resellers-pool1.staging.magileads.net`}
                  target="_blank"
                >
                  resellers-pool1.staging.magileads.net
                </a>
                .
              </p>
              <p>
                {" "}
                <br />
                Les informations recherchées figurent dans les deux dernières
                lignes. <br />
                Plus précisément, le résultat nous indique que{" "}
                <a href={`saas.exemple.com`} target="_blank">
                  saas.exemple.com
                </a>{" "}
                pointe vers le nom r
                <a
                  href={`resellers-pool1.staging.magileads.net.`}
                  target="_blank"
                >
                  esellers-pool1.staging.magileads.net.
                </a>
              </p>
              <p>
                <br />
              </p>
              <p>
                Vous pouvez aussi tester vos sous-domaines sur ce lien si vous
                connaissez l'IP de votre hebergeur :{" "}
                <a
                  href={`https://www.saas-secure.com/online-services/nslookup.html`}
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.saas-secure.com/online-services/nslookup.html
                </a>
              </p>
              <p>
                <br />
              </p>
              <p></p>
            </Stack>
          ),
        },
        {
          url: "is-it-french",
          title: "Votre solution est-elle développée en France ?",
          content: (
            <Stack>
              <p>
                <b>Oui</b>, notre solution est 100% Française
                <br />
              </p>
              <ul>
                <li> Elle est développée en France</li>
                <li>
                  {" "}
                  Elle est hébergé sur des serveurs sécurisés et en France par
                  OVH
                </li>
                <li>
                  {" "}
                  Toutes les données de {reseller?.name} et de nos clients
                  seront stockés sur des serveurs sécurisés en France
                </li>
              </ul>
            </Stack>
          ),
        },
        {
          url: "what-is-your-data-sources",
          title: "Quelles sont les sources de vos fichiers",
          content: (
            <Stack>
              <p>
                Les principales sources de notre base de données
                professionnelles B2B proviennent de fichiers déjà constitués par
                des annuaires professionnels spécialisés et leader dans leurs
                domaines :<br />
              </p>
              <ul>
                <li> Les données publiques des réseaux sociaux</li>
                <li>
                  {" "}
                  La base de données d’infos légales de l’INSEE, Infogreffe, le
                  Bodacc, Legi France, RNCS …
                </li>
                <li>
                  {" "}
                  De la CCI : Le portail des Chambres de commerce et d’industrie
                </li>
                <li> L’annuaire officiel des artisans</li>
                <li>
                  {" "}
                  Les annuaires en libre accès : Vérif.com, Societe.com,{" "}
                  <a
                    href={`http://Kompass.fr`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Kompass.fr
                  </a>{" "}
                  …
                </li>
                <li>
                  {" "}
                  Les fichiers en OpenData des administrations publiques, de la
                  santé, de l’éducation, des associations …
                </li>
              </ul>
            </Stack>
          ),
        },
        {
          url: "database-constitution",
          title: "Comment est constituée votre base de contacts B2B ?",
          content: (
            <Stack>
              <p>
                Grâce à nos scripts et nos algorithmes intelligents, nous
                agrégeons tous
              </p>
              <p>
                les jours plus de 20 sources de fichiers de professionnels
                qualifiés.
                <br />
              </p>
              <p>
                Les profils de notre base B2B sont structurés, fusionnés et
                enrichis et revérifiés quotidiennement.
              </p>
            </Stack>
          ),
        },
        {
          url: "does-it-follow-rgpd-guidelines",
          title: "Votre solution est-elle conforme à la règlementation RGPD ?",
          content: (
            <Stack>
              <p>
                <b>Oui</b>, notre solution est conforme au RGPD (Règlement
                Général sur la Protection des données Personnelles).
                <br />
              </p>
              <p>
                Il s’agit d’un règlement adopté par le parlement européen le 14
                avril 2016 et entré en vigueur dans l’Union Européenne le 24 mai
                2018.
                <br />
              </p>
              <ul>
                <li>
                  Notre base est constituée d’informations que nous collectons à
                  partir de plus de 20 sources de fichiers de professionnels
                  qualifiés et en Open-data ainsi que des données Insee, que
                  nous agrégeons et structurons au travers de nos algorithmes
                  intelligents.
                </li>
                <li>
                  {" "}
                  Nous ne travaillons que sur des données B2B, non soumis au
                  consentement préalable comme le stipule l’article 6 de la CNIL
                  par la directive e-Privacy qui prévôt au RGPD, et transposé en
                  droit français.
                </li>
                <li>
                  {" "}
                  Nous respectons l’obligation de mettre un lien de
                  désabonnement sur chaque campagne de nos clients.
                </li>
                <li>
                  {" "}
                  Si le client oublie de mettre un lien de désinscription ou
                  qu’il est mal écrit, alors notre système le détecte et ajoute
                  AUTOMATIQUEMENT un lien de désinscription en clair en bas de
                  la page.
                </li>
                <li>
                  {" "}
                  D’informer l’internaute des informations que nous détenons, de
                  l’origine de la collecte ainsi que l’effacement de ses
                  informations que nous détenons.
                </li>
                <li>
                  {" "}
                  Nous pouvons aussi exclure le mail, le domaine ou le Numéro
                  SIREN des entreprises qui le demandent afin de ne plus les
                  prospecter.
                </li>
                <li>
                  {" "}
                  Chaque client pourra importer ses listes (cryptage MD5) des
                  contacts, domaines ou SIREN à exclure de vos campagnes.
                </li>
              </ul>
            </Stack>
          ),
        },
      ],
    },
  ];
};
