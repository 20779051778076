import { useCallback, useState } from "react";
import Sparkles from "../../../../components/Sparkles/Sparkles";
import { Button, CloseButton, Modal } from "@mantine/core";
import { IconSparkles } from "@tabler/icons-react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useSessionStore } from "../../../../stores/UserStore";

const GenerateCampaign = () => {
  const reseller = useSessionStore((s) => s.reseller);
  const lang = useLanguage((s) => s.language);
  const [opened, setOpened] = useState(false);
  const [step, setStep] = useState(0);
  const [email, setEmail] = useState(null);
  const [workflowId, setWorkflowId] = useState(null);

  const handleOpen = () => {
    setOpened(true);
    setStep(0);
    setEmail(null);
  };
  const handleClose = () => {
    setOpened(false);
    setStep(0);
    setEmail(null);
  };

  const getStepComponent = useCallback(() => {
    if (step === 0) {
      return <Step1 setStep={setStep} />;
    }
    if (step === 1) {
      return <Step2 setStep={setStep} setEmail={setEmail} />;
    }
    if (step === 2) {
      return (
        <Step3 setStep={setStep} email={email} setWorkflowId={setWorkflowId} />
      );
    }
    if (step === 3) {
      return <Step4 workflowId={workflowId} />;
    }
  }, [email, step, workflowId]);

  return (
    <>
      <Sparkles
        style={{
          width: "fit-content",
          margin: "auto auto",
          marginLeft: -100,
          display: reseller.name === "Magileads" ? "none" : "flex",
        }}
      >
        <Button
          leftSection={<IconSparkles size={22} />}
          variant="light"
          color="primary.8"
          style={{ zIndex: 8 }}
          bg={"primary.0"}
          size={"lg"}
          radius="xl"
          className="animate__animated animate__fadeIn animate__delay-1s"
          onClick={handleOpen}
        >
          {lang.home.create_campaign.button_label}
        </Button>
      </Sparkles>

      {opened && (
        <Modal
          overlayProps={{ blur: 3 }}
          opened={opened}
          withCloseButton={false}
          closeOnClickOutside={false}
          size={"55%"}
          radius={"sm"}
        >
          <CloseButton
            pos={"absolute"}
            top={8}
            right={8}
            radius={"sm"}
            onClick={handleClose}
          />
          {getStepComponent()}
        </Modal>
      )}
    </>
  );
};

export default GenerateCampaign;
