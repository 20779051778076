import { useMemo, useState } from "react";
import { Button, Group, Tooltip } from "@mantine/core";
import { useModelsStore } from "../../stores/ConfigStore";
import { useLanguage } from "../../stores/LanguageStore";
import Selector from "./Selector";
import { getMEmails } from "../../api/ModelsApi";
import ModelEditor from "../Adder/ModelEditor";
import SendBAT from "../../pages/protected/models/emails/SendBAT";
import EmailModalPreview from "../View/EmailModalPreview";
import { IconEye } from "@tabler/icons-react";

function getOptionName(option) {
  return `${option.name} #${option.id}`;
}

// value = model_profile

const ModelEmailSelector = ({
  id,
  label,
  placeholder,
  size,
  required,
  value,
  onChange,
  leftSection,
  disabled,
  withEditor,
  withBAT,
  defaultSignature,
  withPreview,
  onClick,
}) => {
  const lang = useLanguage((s) => s.language);
  const models = useModelsStore((s) => s.model_emails);
  const [loading, setLoading] = useState(false);
  const handleLoad = () => {
    setLoading(true);
    getMEmails().finally(() => setLoading(false));
    onClick?.();
  };

  const options = useMemo(() => {
    let result = [{ label: lang.global.no_folder, options: [] }];
    models?.forEach((item) => {
      let place = result.find((x) => x.label === item?.folder?.name);
      if (place) {
        place.options.push({
          ...item,
          option_name: getOptionName(item),
        });
      } else {
        if (item?.folder) {
          result.push({
            label: item?.folder?.name,
            options: [
              {
                ...item,
                option_name: getOptionName(item),
              },
            ],
          });
        } else {
          result[0].options.push({
            ...item,
            option_name: getOptionName(item),
          });
        }
      }
    });
    return models?.length > 0
      ? result.sort((a, b) => a.label === lang.global.no_folder)
      : [];
  }, [lang.global.no_folder, models]);

  const onModelEdited = ({ closer }) => {
    onChange(null);
    closer();
    setTimeout(() => {
      onChange(
        options.flatMap((x) => [...x.options]).find((x) => x.id === value)
      );
    }, 50);
  };

  return (
    <Group wrap="nowrap" w={"100%"} gap={"xs"}>
      <Selector
        disabled={disabled}
        id={id}
        label={label}
        leftSection={leftSection}
        loading={loading}
        onChange={(val) => onChange(val)}
        onDropdownOpen={handleLoad}
        options={options}
        placeholder={
          placeholder ||
          lang.campaign.selectors.email.placeholder + (required ? " *" : "")
        }
        required={required}
        size={size}
        value={value}
        getOptionName={getOptionName}
        hasGroup
        getError={required ? !value : false}
      />
      {withEditor && (
        <ModelEditor
          model={options
            .flatMap((x) => [...x.options])
            .find((x) => x.id === value)}
          onModelEdited={onModelEdited}
          type={"email"}
          disabled={!value}
        />
      )}

      {withBAT && (
        <SendBAT
          isIcon
          model={value ? { id: value } : null}
          disabled={
            !value ||
            options.flatMap((x) => [...x.options]).find((x) => x.id === value)
              ?.is_ai
          }
          defaultSignature={defaultSignature ? { id: defaultSignature } : null}
        />
      )}

      {withPreview && (
        <EmailModalPreview
          opener={(show) => (
            <Tooltip label={lang.components.email_preview.button_label}>
              <Button
                variant="subtle"
                px={"xs"}
                onClick={show}
                disabled={!value}
              >
                <IconEye size={21} />
              </Button>
            </Tooltip>
          )}
          model={
            value
              ? options
                  .flatMap((x) => [...x.options])
                  .find((x) => x.id === value)
              : null
          }
          defaultSign={defaultSignature}
          standalone
        />
      )}
    </Group>
  );
};

export default ModelEmailSelector;
