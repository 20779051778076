import { useConfigStore } from "../../stores/ConfigStore";
import { useLanguage } from "../../stores/LanguageStore";

function htmlEncode(str) {
  return String(str).replace(/[^\w. ]/gi, function (c) {
    return '&#' + c.charCodeAt(0) + ';';
  });
}

export function replaceTokens({ text = "", signHtml = "", contact, shouldEncode = true }) {
  const lang = useLanguage.getState().language;
  let result = text || "";
  let tokens = [...useConfigStore.getState().datafields];

  tokens.push(
    {
      identifier_placeholder: "%unsubscribe%",
      name: lang.components.variable_manager.dialog.unsubscribe_variable_name,
    },
    {
      identifier_placeholder: "%webversion%",
      name: lang.components.variable_manager.dialog.webversion_variable_name,
    }
  );

  tokens.forEach((token) => {
    const contact_value = contact?.properties?.find(
      (x) => x.data_field_id === token.id
    )?.value;
    result = result.replaceAll(
      token.identifier_placeholder,
      shouldEncode ?
        htmlEncode(`${contact_value || `[${token.name}]`}`)
        :
        `${contact_value || `[${token.name}]`}`
    );
  });

  if (result.includes("%signature%")) {
    result = result.replaceAll("%signature%", signHtml || "[SIGNATURE]");
  } else result += signHtml;

  return result.trim();
}
