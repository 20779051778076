import { Button, Center, Text } from "@mantine/core";
import anim from "../../../assets/lotties/error.json";
import { Logo } from "../../../layouts/Logo";
import { Link } from "react-router-dom";
// import { lazy } from "react";
import { useLanguage } from "../../../stores/LanguageStore";
import Lottie from "react-lottie";

// const Lottie = lazy(() => import("react-lottie"));

const SubscriptionCancelled = () => {
  const lang = useLanguage((s) => s.language);
  return (
    <Center
      w={"60vw"}
      h={"50vh"}
      style={{ flexDirection: "column" }}
      mx={"auto"}
      mt={128}
    >
      <Logo w={200} mb={64} />
      <Lottie
        options={{
          animationData: anim,
          autoplay: true,
          loop: true,
        }}
        height={150}
      />
      <Text fz={30}>{lang.pricing.error.title}</Text>

      <Button
        variant="filled"
        mt={32}
        component={Link}
        size="lg"
        to={"/account-settings/subscription"}
      >
        {lang.pricing.error.back}
      </Button>
    </Center>
  );
};

export default SubscriptionCancelled;
