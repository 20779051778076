import { Group, Paper, Text } from "@mantine/core";
import { nodeWidth, nodeWidthPreview } from "../../flowSettings";
import { allEvents, getDelay } from "../event/events";
import NodeId from "../NodeId";
import { useLanguage } from "../../../../../../../stores/LanguageStore";

const EventNodePreview = ({ data, id, nodeDetail }) => {
  const lang = useLanguage((s) => s.language);
  return (
    <Group w={nodeWidthPreview} justify="center">
      <Paper
        w={nodeWidth}
        withBorder
        p={"xs"}
        shadow={"sm"}
        className="action-node event"
        pos={"relative"}
        style={{
          borderColor: data?.error ? "red" : nodeDetail.color,
          borderWidth: data?.error ? 2 : 1,
          cursor: "default",
        }}
        radius={"md"}
      >
        <Text>
          {lang.campaign.preview.if}{" "}
          <b>
            {allEvents().find((x) => x.value === data?.event_type)?.label ||
              lang.campaign.preview.no_event}
          </b>
        </Text>
        <Text>
          {lang.campaign.preview.delay}:{" "}
          <b>{getDelay(data?.when_minutes).value}</b>
        </Text>

        <NodeId id={id} />
      </Paper>
    </Group>
  );
};

export default EventNodePreview;
