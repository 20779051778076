import { Stack } from "@mantine/core";
import GettingStarted from "./GettingStarted/GettingStarted";
import NeedHelpBlock from "./GettingStarted/NeedHelpBlock";
import { Suspense, lazy } from "react";
import { useProfileStore } from "../../../stores/UserStore";

const AfterSignup = lazy(() => import("../onboarding/AfterSignup"));

const Home = () => {
  const profile = useProfileStore((s) => s.profile);

  return (
    <>
      {/* <Group className="layout-block">
        <Anchor component={Link} to={"/"} c={"primary"}>
          <Group wrap="nowrap">
            <IconHome2 size={14} />

            <Text size="lg" underline="never" fw={600}>
              {lang.home.browser_tab_title}
            </Text>
          </Group>
        </Anchor>

        <GradientButton
          icon={<IconSeeding size={18} />}
          label={"Upgrade"}
          tooltip={"Upgrade your account for more features"}
          gradient={{ from: "tertiary.4", to: "tertiary.7", deg: 90 }}
          link={"/account-settings/subscription"}
          mt={0}
          ml="auto"
          px={32}
          fz={13}
        />
      </Group> */}

      <Stack className="layout-block" gap={0} h={"100%"} mt={16}>
        <GettingStarted />
      </Stack>

      <Stack className="layout-block" gap={0}>
        <NeedHelpBlock />
      </Stack>

      <Suspense>
        <AfterSignup />
      </Suspense>
    </>
  );
};

export default Home;
