import { useCallback, useMemo, useState } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { useListingLoader } from "../../../../components/hooks/ListingHooks";
import SelectionDeleter from "../../../../components/TableComponents/SelectionDeleter";
import ItemDeleter from "../../../../components/TableComponents/ItemDeleter";
import {
  ActionIcon,
  Anchor,
  CopyButton,
  Group,
  HoverCard,
  List,
  ListItem,
  Text,
  Tooltip,
} from "@mantine/core";
import dateColumn from "../../../../layouts/Table/columns/dateColumn";
import { nameColumn } from "../../../../layouts/Table/columns/nameColumn";
import MainTable from "../../../../layouts/Table/MainTable";
import TableLayoutBlock from "../../../../layouts/Table/TableLayoutBlock";
import { sharingColumn } from "../../../../layouts/Table/columns/sharingColumn";
import { deleteField, editField, getFields } from "../../../../api/ContactAPI";
import CreateDatafieldButton from "./CreateDatafieldButton";
import numberColumn from "../../../../layouts/Table/columns/numberColumn";
import PageTitle from "../../../../components/View/PageTitle";
import SelectionSharer from "../../../../components/TableComponents/SelectionSharer";
import { Link } from "react-router-dom";
import { IconEdit } from "@tabler/icons-react";

const DatafieldsListing = () => {
  const apiRef = useGridApiRef();
  const lang = useLanguage((s) => s.language);

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);

  const columns = useMemo(
    () => [
      numberColumn(),
      nameColumn({
        parentPath: () => `/extra/datafields`,
        editFunction: () => editField,
        getDisabled: (row) => row.created_by === null,
        apiRef,
      }),
      {
        field: "identifier",
        headerName: lang.datafields.columns.identifier,
        width: 150,
      },
      {
        field: "identifier_placeholder",
        headerName: lang.datafields.columns.identifier_placeholder,
        width: 150,
        renderCell: ({ row, value }) => {
          return (
            <CopyButton value={value}>
              {({ copied, copy }) => (
                <Tooltip
                  label={copied ? "Copié" : "Cliquez pour Copier"}
                  color={copied ? "green" : "black"}
                >
                  <Anchor c={"black"} onClick={copy} truncate="end">
                    {value}
                  </Anchor>
                </Tooltip>
              )}
            </CopyButton>
          );
        },
      },
      {
        field: "possible_values",
        headerName: lang.datafields.create.dialog.possible_values,
        width: 150,
        renderCell: ({ row, value }) => {
          return value?.length === 0 || !value ? (
            "-"
          ) : (
            <HoverCard withArrow offset={0}>
              <HoverCard.Target>
                <Text>
                  <b>{value.length}</b>{" "}
                  {lang.datafields.create.dialog.possible_values}
                </Text>
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <List gap={"sm"}>
                  {value.map((item, i) => (
                    <ListItem key={i}>{item}</ListItem>
                  ))}
                </List>
              </HoverCard.Dropdown>
            </HoverCard>
          );
        },
      },
      dateColumn(),
      sharingColumn({ apiRef, editFunction: () => editField }),
    ],
    [
      apiRef,
      lang.datafields.columns.identifier,
      lang.datafields.columns.identifier_placeholder,
      lang.datafields.create.dialog.possible_values,
    ]
  );

  const actionsCell = useCallback(
    ({ row }) => {
      return (
        <Group wrap="nowrap" gap={"xs"}>
          <Tooltip label={lang.global.edit}>
            <ActionIcon
              component={Link}
              to={`${row.id}`}
              variant="subtle"
              disabled={row.created_by == null}
            >
              <IconEdit size={18} />
            </ActionIcon>
          </Tooltip>
          <ItemDeleter
            title_bold={`${row.name} #${row.id}`}
            handleDelete={deleteField}
            deletedMessage={lang.datafields.delete_success}
            id={row.id}
            apiRef={apiRef}
            disabled={row.created_by == null}
          />
        </Group>
      );
    },
    [apiRef, lang.datafields.delete_success]
  );

  const toolbarActions = useCallback(
    ({ selectedIds }) => (
      <>
        <SelectionDeleter
          handleDelete={deleteField}
          apiRef={apiRef}
          setTableLoading={setLoading}
          ids={selectedIds}
        />

        <SelectionSharer
          handleShare={editField}
          apiRef={apiRef}
          setTableLoading={setLoading}
          ids={selectedIds}
        />
      </>
    ),
    [apiRef]
  );

  useListingLoader({
    loading,
    setLoading,
    listGetter: getFields,
    responseField: "data_fields_list",
    setRows,
  });

  return (
    <>
      <Group
        className="layout-block top"
        w={"100%"}
        gap={0}
        justify="space-between"
      >
        <PageTitle title={lang.datafields.browser_tab_title} />

        <CreateDatafieldButton setTableLoading={setLoading} />
      </Group>

      <TableLayoutBlock>
        <MainTable
          apiRef={apiRef}
          columns={columns}
          rows={rows}
          loading={loading}
          setLoading={setLoading}
          id={"list-table"}
          toolbarActions={toolbarActions}
          actionsCell={actionsCell}
          actionsCellWidth={75}
        />
      </TableLayoutBlock>
    </>
  );
};

export default DatafieldsListing;
