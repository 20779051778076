import { useCallback, useMemo, useState } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { useListingLoader } from "../../../../components/hooks/ListingHooks";
import SelectionDeleter from "../../../../components/TableComponents/SelectionDeleter";
import ItemDeleter from "../../../../components/TableComponents/ItemDeleter";
import { Anchor, CopyButton, Group, Tooltip } from "@mantine/core";
import dateColumn from "../../../../layouts/Table/columns/dateColumn";
import { nameColumn } from "../../../../layouts/Table/columns/nameColumn";
import MainTable from "../../../../layouts/Table/MainTable";
import TableLayoutBlock from "../../../../layouts/Table/TableLayoutBlock";
import { sharingColumn } from "../../../../layouts/Table/columns/sharingColumn";
import {
  deleteUrlShortened,
  editUrlShortened,
  getUrlsShortened,
} from "../../../../api/ConfigsApi";
import CreateUrlButton from "./CreateUrlButton";
import numberColumn from "../../../../layouts/Table/columns/numberColumn";
import PageTitle from "../../../../components/View/PageTitle";
import SelectionSharer from "../../../../components/TableComponents/SelectionSharer";

const UrlListing = () => {
  const apiRef = useGridApiRef();
  const lang = useLanguage((s) => s.language);

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);

  const columns = useMemo(
    () => [
      nameColumn({
        parentPath: () => `/extra/url-shortener`,
        editFunction: () => editUrlShortened,
        getDisabled: (row) => row.created_by === null,
        apiRef,
      }),
      {
        field: "url",
        headerName: lang.url_shortener.columns.url,
        width: 175,
        renderCell: ({ value }) => {
          return (
            <Anchor size="sm" href={value} target="_blank" truncate="end">
              {value}
            </Anchor>
          );
        },
      },
      {
        field: "shortened_url",
        headerName: lang.url_shortener.columns.shortened_url,
        width: 150,
        renderCell: ({ value }) => {
          return (
            <Anchor size="sm" href={value} target="_blank" truncate="end">
              {value}
            </Anchor>
          );
        },
      },
      {
        field: "url_code",
        headerName: lang.url_shortener.columns.url_code,
        width: 100,
        renderCell: ({ row, value }) => {
          return (
            <CopyButton value={value}>
              {({ copied, copy }) => (
                <Tooltip
                  label={
                    copied
                      ? lang.url_shortener.var_copied
                      : "Cliquez pour Copier"
                  }
                  color={copied ? "green" : "black"}
                >
                  <Anchor c={"black"} onClick={copy} truncate="end">
                    {value}
                  </Anchor>
                </Tooltip>
              )}
            </CopyButton>
          );
        },
      },
      numberColumn("nb_clicks", lang.url_shortener.columns.nb_clicks, 120),
      dateColumn(),
      sharingColumn({ apiRef, editFunction: () => editUrlShortened }),
    ],
    [apiRef, lang]
  );

  const actionsCell = useCallback(
    ({ row }) => {
      return (
        <ItemDeleter
          title_bold={`${row.name} #${row.id}`}
          handleDelete={deleteUrlShortened}
          deletedMessage={lang.url_shortener.delete_success}
          id={row.id}
          apiRef={apiRef}
        />
      );
    },
    [apiRef, lang.url_shortener.delete_success]
  );

  const toolbarActions = useCallback(
    ({ selectedIds }) => (
      <>
        <SelectionDeleter
          handleDelete={deleteUrlShortened}
          apiRef={apiRef}
          setTableLoading={setLoading}
          ids={selectedIds}
        />

        <SelectionSharer
          handleShare={editUrlShortened}
          apiRef={apiRef}
          setTableLoading={setLoading}
          ids={selectedIds}
        />
      </>
    ),
    [apiRef]
  );

  useListingLoader({
    loading,
    setLoading,
    listGetter: getUrlsShortened,
    responseField: "shortened_urls_list",
    setRows,
  });

  return (
    <>
      <Group
        className="layout-block top"
        w={"100%"}
        gap={0}
        justify="space-between"
      >
        <PageTitle title={lang.datafields.browser_tab_title} />

        <CreateUrlButton />
      </Group>

      <TableLayoutBlock>
        <MainTable
          apiRef={apiRef}
          columns={columns}
          rows={rows}
          loading={loading}
          setLoading={setLoading}
          id={"list-table"}
          toolbarActions={toolbarActions}
          actionsCell={actionsCell}
          actionsCellWidth={75}
        />
      </TableLayoutBlock>
    </>
  );
};

export default UrlListing;
