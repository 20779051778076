import { Badge, Button, Group, Stack, Tooltip } from "@mantine/core";
import { useLanguage } from "../../../stores/LanguageStore";
import ColumnEditor from "./ColumnEditor";
import { useState } from "react";
import TagsRessourceSelector from "../../../components/Selectors/TagsRessourceSelector";

const TagEditor = ({ editFunction, cell, closePopover, apiRef }) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState(cell.row.tags);

  const handleSubmit = (e) => {
    e.preventDefault();

    let fd = new FormData(e.target);
    let data = Object.fromEntries(fd);

    setLoading(true);
    editFunction({
      id: cell.row.id,
      tags_ids: tags?.map((x) => x.id),
    })
      .then((response) => {
        closePopover();
        apiRef.current?.updateRows([{ id: cell.row.id, tags: tags }]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Stack component={"form"} onSubmit={handleSubmit} gap={"xs"} w={400}>
      <TagsRessourceSelector value={tags} setValue={setTags} autoFocus />
      <Group wrap="nowrap" justify="end">
        <Button
          onClick={closePopover}
          size="compact-sm"
          variant="subtle"
          disabled={loading}
        >
          {lang.components.closer.submit}
        </Button>
        <Button
          type="submit"
          loading={loading}
          size="compact-sm"
          color="tertiary.8"
        >
          {lang.contact_lists.edit.dialog.submit_button}
        </Button>
      </Group>
    </Stack>
  );
};

export const tagsColumn = ({ apiRef, editFunction }) => {
  const lang = useLanguage.getState().language;
  return {
    field: "tags",
    headerName: "Tags",
    width: 150,
    cellClassName: "hover-source",
    valueGetter: (params) => {
      if (params.value) return params.value.map((e) => e.name).join(", ");
      return null;
    },
    renderCell: (cell) => {
      const mainEdit =
        typeof editFunction === "function"
          ? editFunction(cell.row)
          : editFunction;
      return (
        <Group wrap="nowrap" w={"100%"}>
          {cell.value ? (
            <Group flex={"1 1 auto"} wrap="nowrap">
              {cell.value?.split(", ")?.map((tag) => (
                <Tooltip label={`${lang.tags.filter} ${tag}`} key={tag}>
                  <Badge
                    variant="gradient"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      apiRef.current.setFilterModel({
                        items: [
                          {
                            field: "tags",
                            operator: "contains",
                            value: tag,
                          },
                        ],
                        logicOperator: "and",
                        quickFilterLogicOperator: "and",
                        quickFilterValues: [],
                      });
                    }}
                    size="xs"
                    radius={"xl"}
                    py={9}
                  >
                    {tag}
                  </Badge>
                </Tooltip>
              ))}
            </Group>
          ) : (
            <Group flex={"1 1 auto"}>-</Group>
          )}
          {editFunction && (
            <ColumnEditor
              disabled={
                !cell.row?.write_permission &&
                !cell?.row?.permission?.write_permission
              }
              isAdd={cell.row?.tags?.length === 0}
              dropDownComponent={(closePopover) => (
                <TagEditor
                  editFunction={mainEdit}
                  cell={cell}
                  closePopover={closePopover}
                  apiRef={apiRef}
                />
              )}
            />
          )}
        </Group>
      );
    },
  };
};
