import { lazy, useEffect, useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import {
  ActionIcon,
  CheckIcon,
  Combobox,
  Group,
  Highlight,
  Loader,
  Pill,
  PillsInput,
  Text,
  Tooltip,
  useCombobox,
} from "@mantine/core";
import { IconMinus, IconPlus, IconX } from "@tabler/icons-react";
import DatabaseCondition from "./DatabaseCondition";
const FileImport = lazy(() => import("../quickImport/FileImport"));

function getFilteredOptions(data, searchQuery, limit) {
  const result = [];

  for (let i = 0; i < data?.length; i += 1) {
    if (result.length === limit) {
      break;
    }

    if (
      data[i].toLowerCase().includes(
        searchQuery
          .trim()
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .toLowerCase()
      )
    ) {
      result.push(data[i]);
    }
  }

  return result;
}

// function removeDuplicates(array) {
//   // Create an empty object to keep track of unique values
//   var uniqueValues = {};

//   // Filter the array to keep only the objects with unique "value" property
//   return array.filter(function (obj) {
//     // Check if the current value is already in the uniqueValues object
//     // If not, add it to the uniqueValues object and return true (to keep the object)
//     if (!uniqueValues[obj.value]) {
//       uniqueValues[obj.value] = true;
//       return true;
//     }
//     // If the value already exists in uniqueValues, return false (to filter out the duplicate)
//     return false;
//   });
// }

const DatabaseInput = ({
  data,
  label,
  placeholder,
  leftSection,
  values,
  setValues,
  excludes,
  setExcludes,
  autoFocus,
  field,
  conditions,
  handleCondition,
  canImport,
  searchFunction,
}) => {
  const lang = useLanguage((s) => s.language);
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => {
      combobox.updateSelectedOptionIndex("active");
    },
  });

  const [searching, setSearching] = useState(false);
  const [internalOptions, setInternalOptions] = useState(
    searchFunction ? [] : null
  );

  const [search, setSearch] = useState("");

  const exactOptionMatch = data?.some((item) => item === search);

  const handleNewOptionValue = () => {
    setSearch("");

    setValues((current) => [...current, search.trim()]);
  };

  const handleValueSelect = ({ value, exclude, disableRemove }) => {
    if (value === "$create") {
      handleNewOptionValue();
    } else {
      if (exclude) {
        setExcludes((current) => [...current, value]);
      } else {
        setExcludes((current) => current.filter((x) => x !== value));
      }

      if (!disableRemove) {
        setValues((current) =>
          current.includes(value)
            ? current.filter((v) => v !== value)
            : [...current, value]
        );
      }

      setSearch("");
    }
    // combobox.closeDropdown();
  };

  const handleValueRemove = (val) => {
    setValues((current) => current.filter((v) => v !== val));
    setExcludes((current) => current.filter((v) => v !== val));
  };

  const handleExclude = (item) => {
    setExcludes((current) =>
      current.includes(item)
        ? current.filter((v) => v !== item)
        : [...current, item]
    );
  };

  const actualValues = values.map((item) => {
    const excluded = excludes.includes(item);
    return (
      <Group
        key={item}
        gap={4}
        pos={"relative"}
        style={{
          background:
            excludes.includes(item) && conditions?.[field] !== "exact_match"
              ? "var(--mantine-color-red-1)"
              : "var(--mantine-color-green-1)",
          borderRadius: 12,
        }}
        px={4}
        py={4}
        maw={"100%"}
        wrap="nowrap"
        pl={field && conditions?.[field] === "exact_match" ? 8 : 4}
      >
        {((field && conditions?.[field] !== "exact_match") || !field) && (
          <Tooltip label={excluded ? "Exclure" : "Inclure"} withArrow>
            <ActionIcon
              color={excluded ? "red" : "tertiary.8"}
              size={"xs"}
              radius={"sm"}
              w={10}
              h={10}
              variant="subtle"
              onClick={() => handleExclude(item)}
            >
              {excluded ? <IconMinus size={15} /> : <IconPlus size={15} />}
            </ActionIcon>
          </Tooltip>
        )}
        <Text size="sm" truncate="end" maw={"90%"}>
          {item}
        </Text>
        <ActionIcon
          color="black"
          size={"xs"}
          radius={"sm"}
          ml={"auto"}
          w={10}
          h={10}
          variant="light"
          onClick={() => handleValueRemove(item)}
        >
          <IconX size={12} />
        </ActionIcon>
      </Group>
    );
  });

  const options = getFilteredOptions(internalOptions || data, search, 172)
    .filter((item) => item.toLowerCase().includes(search.trim().toLowerCase()))
    .map((item) => {
      const active = values.includes(item);
      const excluded = excludes.includes(item);

      return (
        <Combobox.Option
          value={item}
          key={item}
          active={active}
          styles={{ option: { padding: 0 } }}
        >
          <Group
            gap="sm"
            bg={
              excluded
                ? "var(--mantine-color-red-1)"
                : active
                ? "var(--mantine-color-green-1)"
                : "none"
            }
            p={6}
            wrap="nowrap"
          >
            {active ? <CheckIcon size={12} /> : null}
            <Highlight size="sm" highlight={search} truncate="end">
              {item}
            </Highlight>

            <Group ml={"auto"} wrap="nowrap" gap={4}>
              <Tooltip label="Inclure">
                <ActionIcon
                  size={"sm"}
                  color="tertiary.8"
                  disabled={active && !excluded}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleValueSelect({
                      value: item,
                      exclude: false,
                      disableRemove: values.includes(item),
                    });
                  }}
                  variant="subtle"
                >
                  <IconPlus size={16} />
                </ActionIcon>
              </Tooltip>

              <Tooltip label="Exclure">
                <ActionIcon
                  size={"sm"}
                  color="red"
                  disabled={active && excluded}
                  variant="subtle"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleValueSelect({
                      value: item,
                      exclude: true,
                      disableRemove: values.includes(item),
                    });
                  }}
                >
                  <IconMinus size={16} />
                </ActionIcon>
              </Tooltip>
            </Group>
          </Group>
        </Combobox.Option>
      );
    });

  useEffect(() => {
    if (searchFunction && search?.length > 2) {
      setSearching(true);
      searchFunction(search)
        .then((res) => {
          let result = res.data?.locations;
          let newOptions = [];

          result.forEach((item) => {
            newOptions.push(item?.name);
          });

          setInternalOptions(newOptions);
        })
        .finally(() => setSearching(false));
    }
  }, [search, searchFunction]);

  return (
    <Group w={"100%"} pos={"relative"}>
      {field && (
        <DatabaseCondition
          value={conditions[field]}
          onChange={(v) => handleCondition(v, field)}
        />
      )}
      <Combobox
        store={combobox}
        onOptionSubmit={(v) => handleValueSelect({ value: v, exclude: false })}
        withinPortal={true}
        zIndex={1400}
        shadow="sm"
      >
        <Combobox.DropdownTarget>
          <PillsInput
            onClick={() => combobox.openDropdown()}
            label={label}
            leftSection={leftSection}
            w={"100%"}
            styles={{
              input: {
                borderTopRightRadius: 0,
              },
            }}
            disabled={field ? conditions[field] === "exists" : false}
            rightSection={
              searching ? (
                <Loader size={16} />
              ) : (
                canImport && <FileImport setValues={setValues} />
              )
            }
          >
            <Pill.Group>
              {actualValues}

              <Combobox.EventsTarget>
                <PillsInput.Field
                  onFocus={() => combobox.openDropdown()}
                  onBlur={() => combobox.closeDropdown()}
                  autoFocus={autoFocus}
                  value={search}
                  placeholder={placeholder}
                  onChange={(event) => {
                    combobox.updateSelectedOptionIndex();
                    setSearch(event.currentTarget.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Backspace" && search.length === 0) {
                      event.preventDefault();
                      return handleValueRemove(values[values.length - 1]);
                    }
                    if (
                      event.key === "Enter" &&
                      !exactOptionMatch &&
                      search.trim().length > 0
                    ) {
                      return handleNewOptionValue();
                    }
                  }}
                  mih={28}
                />
              </Combobox.EventsTarget>
            </Pill.Group>
          </PillsInput>
        </Combobox.DropdownTarget>

        {internalOptions?.length === 0 || data?.length === 0 ? (
          !exactOptionMatch &&
          search.trim().length > 0 && (
            <Combobox.Dropdown>
              <Combobox.Options>
                <Combobox.Option
                  value="$create"
                  component={Group}
                  gap={6}
                  disabled
                >
                  <span>
                    Appuyez sur Entrée pour ajouter <b> {search}</b>
                  </span>
                </Combobox.Option>
              </Combobox.Options>
            </Combobox.Dropdown>
          )
        ) : (
          <Combobox.Dropdown>
            <Combobox.Options mah={128} style={{ overflowY: "auto" }}>
              {search.trim().length > 0 && (
                <Combobox.Option
                  value="$create"
                  component={Group}
                  gap={6}
                  disabled
                >
                  <IconPlus size={14} />
                  <span>
                    {lang.global.enter_key_to_add} <b> {search}</b>
                  </span>
                </Combobox.Option>
              )}

              {options}

              {exactOptionMatch &&
                (internalOptions.length > 0 || data?.length > 0) &&
                search.trim().length > 0 &&
                options.length === 0 && (
                  <Combobox.Empty>
                    {lang.global.no_element_found}
                  </Combobox.Empty>
                )}
            </Combobox.Options>
          </Combobox.Dropdown>
        )}
      </Combobox>
    </Group>
  );
};

export default DatabaseInput;
