import {
  ActionIcon,
  Button,
  Group,
  Modal,
  SimpleGrid,
  Stack,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { IconShare3, IconUserPlus, IconUserUp } from "@tabler/icons-react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useParams } from "react-router-dom";
import { useCallback, useState } from "react";
import {
  useLeadsContainerStore,
  useLeadsStore,
} from "../../../../stores/LeadsStore";
import { createProspect, createProspectUser } from "../../../../api/PRMAPI";
import PRMStatusSelector from "./../../../../components/Selectors/PRMStatusSelector";
import BackButton from "../../../../components/View/BackButton";
import { modals } from "@mantine/modals";

const LeadCreate = () => {
  const lang = useLanguage((s) => s.language);
  const { userId } = useParams();
  const updateContainer = useLeadsContainerStore((s) => s.updateContainer);
  const columns = useLeadsStore((s) => s.fields);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [errors, setErrors] = useState({});

  const handleOpen = () => {
    setOpen(true);
    setLoading(false);
    setDirty(false);

    setErrors({});
  };

  const handleClose = useCallback(
    (isSubmit) => {
      if (dirty && !isSubmit)
        modals.openConfirmModal({
          title: "Attention",
          children: (
            <Text size="sm">Vos modifications ne seront pas sauvegardés</Text>
          ),
          labels: { confirm: "Fermer", cancel: "Annuler" },
          onCancel: () => {},
          onConfirm: () => {
            setOpen(false);
            setDirty(false);
          },
          centered: true,
        });
      else {
        setOpen(false);
        setDirty(false);
      }
    },
    [dirty]
  );

  const onSubmit = async (e) => {
    e.preventDefault();
    const fd = new FormData(e.target);
    const data = Object.fromEntries(fd);

    let properties = [];
    Object.keys(data).forEach((x) => {
      if (data[x]) {
        if (x !== "status") {
          properties.push({
            data_field_id: columns.find(
              (col) => col.identifier_placeholder === x
            )?.id,
            value: data[x],
          });
        }
      }
    });

    let func = userId ? createProspectUser : createProspect;

    const custom_status = parseInt(data.status);

    setErrors({});
    setLoading(true);
    func({
      user_id: parseInt(userId),
      properties,
      custom_status: custom_status || undefined,
      status: custom_status ? "opener" : data.status,
    })
      .then((response) => {
        setDirty(false);
        handleClose(true);
        updateContainer(data.status, {
          loading: true,
        });
      })
      .catch((error) => {
        let errorResponse = error.response.data.errors;
        let result = {};
        if (errorResponse.find((x) => x?.error?.includes("email"))) {
          result["%email%"] = true;
          document.getElementById("%email%").focus();
        }
        if (errorResponse.find((x) => x?.error?.includes("linkedin"))) {
          result["%linkedin_url%"] = true;
        }

        setErrors(result);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Tooltip label={lang.prm.create_lead}>
        <ActionIcon ml={"auto"} variant="subtle" mr={"lg"} onClick={handleOpen}>
          <IconUserPlus size={18} />
        </ActionIcon>
      </Tooltip>

      <Modal
        size={"xl"}
        title={lang.prm.toolbar.add_prospect}
        onClose={handleClose}
        opened={open}
      >
        <Stack
          component={"form"}
          onChange={() => setDirty(true)}
          onSubmit={onSubmit}
          h={"76vh"}
        >
          <SimpleGrid
            cols={3}
            flex={"1 1 auto"}
            h={0}
            style={{ overflow: "auto" }}
          >
            <PRMStatusSelector
              label={"Status"}
              required
              withinPortal={false}
              name={"status"}
              id={"status"}
            />
            {columns
              .filter((x) => !x.readonly)
              .map((column, index) => {
                return (
                  <TextInput
                    key={column.id}
                    id={column.identifier_placeholder}
                    name={column.identifier_placeholder}
                    label={column.name}
                    placeholder={column.name}
                    type={
                      column.identifier_placeholder === "%email%"
                        ? "email"
                        : "text"
                    }
                    disabled={column.identifier_placeholder === "id"}
                    error={errors[column.identifier_placeholder]}
                    leftSection={
                      column.created_by && !column.from_sharing ? (
                        <Tooltip label={lang.prm.toolbar.custom_datafield}>
                          <IconUserUp size={18} />
                        </Tooltip>
                      ) : (
                        column.created_by &&
                        column.from_sharing && (
                          <Tooltip label={lang.prm.toolbar.from_sharing}>
                            <IconShare3 size={18} />
                          </Tooltip>
                        )
                      )
                    }
                  />
                );
              })}
          </SimpleGrid>

          <Group justify="end">
            <BackButton onClick={handleClose} />

            <Button
              loading={loading}
              leftSection={<IconUserPlus size={18} />}
              color="tertiary.8"
              type="submit"
            >
              {lang.prm.toolbar.add_prospect_button}
            </Button>
          </Group>
        </Stack>
      </Modal>
    </>
  );
};

export default LeadCreate;
