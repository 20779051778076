import React, { useEffect, useMemo, useState } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { IconCheck, IconLoader } from "@tabler/icons-react";
import {
  Box,
  Group,
  Loader,
  Stack,
  Text,
  Timeline,
  TimelineItem,
} from "@mantine/core";
import { LoadingIllustration } from "../../../../assets/LoadingIllustration";

const StepLoad = ({ ...props }) => {
  const lang = useLanguage((s) => s.language);
  const [loadingStep, setLoadingStep] = useState(0);

  const steps = useMemo(
    () => [
      lang.resellers.onboarding.loading.step1,
      lang.resellers.onboarding.loading.step2,
      lang.resellers.onboarding.loading.step3,
    ],
    [lang]
  );

  useEffect(() => {
    setTimeout(() => {
      setLoadingStep(1);
      setTimeout(() => {
        setLoadingStep(2);
      }, 1000);
    }, 2500);
  }, []);

  return (
    <Group wrap="nowrap" align="end" justify="left" mt={-64} {...props}>
      <Box
        mt={-100}
        w={200}
        mih={210}
        className={`animate__animated ${"animate__fadeInLeft"}`}
      >
        <LoadingIllustration />
      </Box>
      <Stack my={32} w={400}>
        <Text fz={24} mb={8}>
          {lang.home.create_campaign.step3.title2}
        </Text>

        <Timeline active={loadingStep} color="tertiary.8" bulletSize={28}>
          {steps.map((step, index) => (
            <TimelineItem
              key={step}
              title={
                <Text pt={3} opacity={loadingStep >= index ? 1 : 0.5}>
                  {step}
                </Text>
              }
              bullet={
                loadingStep === index ? (
                  <Loader type="oval" size={16} color="white" />
                ) : loadingStep > index ? (
                  <IconCheck size={18} />
                ) : (
                  <IconLoader size={18} />
                )
              }
              className={`animate__animated ${"animate__fadeInUp"}`}
              style={{
                animationDelay: `${0.2 * index}s`,
              }}
            />
          ))}
        </Timeline>
      </Stack>
    </Group>
  );
};

export default StepLoad;
