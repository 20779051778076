import { Anchor, Button, Group, Stack } from "@mantine/core";
import { useLanguage } from "../../../stores/LanguageStore";
import { useState } from "react";
import ColumnEditor from "./ColumnEditor";
import { Link } from "react-router-dom";
import SignatureSelector from "../../../components/Selectors/SignatureSelector";

const SignatureEditor = ({ editFunction, cell, closePopover, apiRef }) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(false);
  const [sign, setSign] = useState(cell.row.email_signatures_profile);

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    editFunction({
      id: cell.row.id,
      email_signatures_id: sign?.id || null,
      connection_test: false,
    })
      .then((response) => {
        closePopover();
        apiRef.current?.updateRows([
          {
            id: cell.row.id,
            email_signatures_id: sign.id,
            email_signatures_profile: sign,
          },
        ]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Stack component={"form"} onSubmit={handleSubmit} gap={"xs"} w={400}>
      <SignatureSelector value={sign?.id || null} onChange={setSign} />
      <Group wrap="nowrap" justify="end">
        <Button
          onClick={closePopover}
          size="compact-sm"
          variant="subtle"
          disabled={loading}
        >
          {lang.components.closer.submit}
        </Button>
        <Button
          type="submit"
          loading={loading}
          size="compact-sm"
          color="tertiary.8"
        >
          {lang.contact_lists.edit.dialog.submit_button}
        </Button>
      </Group>
    </Stack>
  );
};

export const signatureColumn = ({ apiRef, editFunction, type }) => {
  const lang = useLanguage.getState().language;
  return {
    field: "email_signatures_profile",
    headerName: lang.emails_account.columns.signature,
    width: 150,
    cellClassName: "hover-source",
    renderCell: (cell) => {
      const mainEdit = editFunction(cell.row);
      return (
        <Group wrap="nowrap" w={"100%"}>
          {cell.value ? (
            <Anchor
              component={Link}
              to={"/models/email-signatures/" + cell.value.id}
            >
              {cell.value.name}
            </Anchor>
          ) : (
            "-"
          )}

          <ColumnEditor
            disabled={
              !cell.row?.write_permission &&
              !cell?.row?.permission?.write_permission
            }
            isAdd={!cell.row?.email_signatures_id}
            dropDownComponent={(closePopover) => (
              <SignatureEditor
                editFunction={mainEdit}
                cell={cell}
                closePopover={closePopover}
                apiRef={apiRef}
                type={type}
              />
            )}
          />
        </Group>
      );
    },
  };
};
