import { Group } from "@mantine/core";

const CampaignColumnChildCell = ({ children }) => {
  return (
    <Group w="100%" h={18} gap={"xs"} wrap="nowrap">
      {children}
    </Group>
  );
};

export default CampaignColumnChildCell;
