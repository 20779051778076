import {
  Divider,
  Group,
  HoverCard,
  NumberFormatter,
  RingProgress,
  Stack,
  Text,
} from "@mantine/core";
import { useLanguage } from "../../../../../stores/LanguageStore";
import CampaignColumnChildCell from "./CampaignColumnChildCell";
import { useProfileStore } from "../../../../../stores/UserStore";
import { Link } from "react-router-dom";

const ChildCell = ({ row, step }) => {
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);
  const unswitchedProfile = useProfileStore((s) => s.unswitchedProfile);

  //#region Maths
  const contacted = step?.contacted === null ? " - " : step.contacted;
  const to_contact = step?.to_contact === null ? " - " : step.to_contact;
  const bounced = step?.bounced === null ? " - " : step?.bounced;
  const unsubscribers =
    step?.unsubscribers === null ? " - " : step?.unsubscribers;
  const blacklisted = step?.blacklisted === null ? " - " : step?.blacklisted;
  const sent_today = step?.sent_today === null ? " - " : step?.sent_today;
  const limit_send_per_day =
    step?.limit_send_per_day === null ? " - " : step?.limit_send_per_day;
  const excluded_previous_programmation =
    step?.excluded_previous_programmation === null
      ? " - "
      : step?.excluded_previous_programmation;
  const excluded_workflow =
    step?.excluded_workflow === null ? " - " : step?.excluded_workflow;
  const excluded_programmation =
    step?.excluded_programmation === null
      ? " - "
      : step?.excluded_programmation;
  const waiting = step?.waiting === null ? " - " : step?.waiting;
  const in_queue = step?.in_queue === null ? " - " : step?.in_queue;
  const in_queue_failed =
    step?.in_queue_failed === null ? " - " : step?.in_queue_failed;
  const to_contact_failed =
    step?.to_contact_failed === null ? " - " : step?.to_contact_failed;
  const total =
    step?.contacted === null
      ? step?.to_contact === null
        ? " - "
        : step?.to_contact
      : step.contacted + step.to_contact - (step?.to_contact_failed || 0);

  //#endregion

  return (
    <CampaignColumnChildCell>
      <HoverCard
        zIndex={200}
        openDelay={300}
        withArrow
        offset={2}
        position="left"
      >
        <HoverCard.Target>
          <Group
            justify="space-between"
            fz={13}
            component={Link}
            w="100%"
            h={"100%"}
            gap={"xs"}
            wrap="nowrap"
            className="statistics-button"
            px={12}
            to={`/statistics/campaigns/${row.prog_id}/workflow/${row.workflow.id}/step/${step.id}/contacts/contacted/${step.action_type}`}
          >
            <Text truncate="end" fw={600} size="sm">
              <NumberFormatter value={contacted} thousandSeparator=" " />
              <span style={{ margin: "0 8px" }}>/</span>
              <NumberFormatter value={total} thousandSeparator=" " />
            </Text>
          </Group>
        </HoverCard.Target>

        <HoverCard.Dropdown>
          <Text fw={500}>Action #{step.id}</Text>
          <Divider mt={4} />
          <Text size="sm" style={{ lineHeight: 2 }}>
            {lang.statistics.contacts_type.contacted} :{" "}
            <b> {contacted.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</b>{" "}
            <br />
            {lang.statistics.contacts_type.sent_today} :{" "}
            <b>
              {sent_today?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
            </b>{" "}
            {(unswitchedProfile?.level === "admin" ||
              profile?.level === "admin") && (
              <>
                <br />
                {lang.statistics.contacts_type.to_contact} :{" "}
                <b>
                  {" "}
                  {to_contact.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                </b>{" "}
                ({" "}
                {to_contact_failed
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
                {lang.statistics.contacts_type.failed} ) <br />
                {lang.statistics.contacts_type.in_queue} :{" "}
                <b>
                  {in_queue?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                </b>{" "}
                ({" "}
                {in_queue_failed
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
                {lang.statistics.contacts_type.failed} )
              </>
            )}{" "}
            <br />
            {lang.statistics.contacts_type.waiting} :{" "}
            <b>{waiting?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</b>{" "}
            <br />
            {lang.statistics.contacts_type.bounced} :{" "}
            <b>{bounced?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</b>{" "}
            <br />
            {lang.statistics.contacts_type.unsubscriber} :{" "}
            <b>
              {unsubscribers?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
            </b>{" "}
            <br />
            {lang.statistics.contacts_type.blacklisted} :{" "}
            <b>
              {blacklisted?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
            </b>{" "}
            <br />
            {
              lang.statistics.contacts_type.excluded_previous_programmation
            } :{" "}
            <b>
              {excluded_previous_programmation
                ?.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
            </b>{" "}
            <br />
            {lang.statistics.contacts_type.excluded_workflow} :{" "}
            <b>
              {excluded_workflow
                ?.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
            </b>{" "}
            <br />
            {lang.statistics.contacts_type.excluded_programmation} :{" "}
            <b>
              {excluded_programmation
                ?.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
            </b>{" "}
            <br />
            {lang.statistics.contacts_type.daily_limit} :{" "}
            <b>
              {limit_send_per_day
                ?.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
            </b>{" "}
            <br />
          </Text>
        </HoverCard.Dropdown>
      </HoverCard>
    </CampaignColumnChildCell>
  );
};

const ParentCell = ({ row }) => {
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);
  const unswitchedProfile = useProfileStore((s) => s.unswitchedProfile);

  //#region Maths
  const percent = row?.initial?.contacted
    ? ((row.initial?.contacted + (row.initial?.to_contact_failed || 0)) /
        (row.initial?.contacted + (row?.initial?.to_contact || 0))) *
      100
    : 0;

  const contacted =
    row?.initial?.contacted === null ? " - " : row?.initial?.contacted;
  const to_contact =
    row?.initial?.to_contact === null ? " - " : row?.initial?.to_contact;
  const bounced = row?.bounced === null ? " - " : row?.bounced;
  const unsubscribers =
    row?.unsubscribers === null ? " - " : row?.unsubscribers;
  const blacklisted = row?.blacklisted === null ? " - " : row?.blacklisted;
  const excluded_previous_programmation =
    row?.excluded_previous_programmation === null
      ? " - "
      : row?.excluded_previous_programmation;
  const excluded_workflow =
    row?.excluded_workflow === null ? " - " : row?.excluded_workflow;
  const excluded_programmation =
    row?.excluded_programmation === null ? " - " : row?.excluded_programmation;
  const sent_today =
    row?.initial?.sent_today === null ? " - " : row?.initial?.sent_today;
  const in_queue =
    row?.initial?.in_queue === null ? " - " : row?.initial?.in_queue;
  const in_queue_failed =
    row?.initial?.in_queue_failed === null
      ? " - "
      : row?.initial?.in_queue_failed;
  const to_contact_failed =
    row?.initial?.to_contact_failed === null
      ? " - "
      : row?.initial?.to_contact_failed;
  const limit_send_per_day =
    row?.initial?.limit_send_per_day === null
      ? " - "
      : row?.initial?.limit_send_per_day;
  const total =
    row?.initial?.contacted === null
      ? row?.initial?.to_contact === null
        ? " - "
        : row?.initial?.to_contact
      : row?.initial?.contacted + row?.initial?.to_contact;
  //#endregion

  return (
    <HoverCard
      zIndex={200}
      openDelay={300}
      withArrow
      offset={0}
      position="left"
    >
      <HoverCard.Target>
        <Group
          component={Link}
          w="100%"
          h={"100%"}
          gap={"xs"}
          wrap="nowrap"
          className="statistics-button"
          px={6}
          to={`/statistics/campaigns/${row.id}/workflow/${
            row.workflow_id
          }/contacts/contacted?createdOn=${new Date(
            row?.created_on
          ).toISOString()}`}
        >
          <RingProgress
            size={30}
            thickness={4}
            roundCaps
            sections={percent ? [{ value: percent, color: "tertiary.8" }] : []}
          />
          <Group gap={4} flex={"1 1 auto"} wrap="nowrap">
            <Text truncate="end">
              <NumberFormatter
                value={contacted + (to_contact_failed || 0)}
                thousandSeparator=" "
              />
            </Text>
            <span>/</span>
            <Text truncate="end">
              {typeof total === "string" ? (
                total
              ) : (
                <NumberFormatter value={total} thousandSeparator=" " />
              )}
            </Text>
          </Group>
        </Group>
      </HoverCard.Target>

      <HoverCard.Dropdown>
        <Text mb={4}>Campagne entière</Text>
        <Text size="sm" style={{ lineHeight: 2 }}>
          {lang.statistics.contacts_type.contacted} :{" "}
          <b> {contacted?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</b>{" "}
          <br />
          {lang.statistics.contacts_type.sent_today} :{" "}
          <b>{sent_today?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</b>{" "}
          {(unswitchedProfile?.level === "admin" ||
            profile?.level === "admin") && (
            <>
              <br />
              {lang.statistics.contacts_type.to_contact} :
              <b>
                {" "}
                {to_contact?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
              </b>{" "}
              ({" "}
              <b>
                {to_contact_failed
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
              </b>{" "}
              {lang.statistics.contacts_type.failed} ) <br />
              {lang.statistics.contacts_type.in_queue} :{" "}
              <b>
                {in_queue?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
              </b>{" "}
              ({" "}
              <b>
                {in_queue_failed
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
              </b>{" "}
              {lang.statistics.contacts_type.failed} )
            </>
          )}
          <br />
          {lang.statistics.contacts_type.bounced}{" "}
          {lang.statistics.contacts_type.in_list} :{" "}
          <b>{bounced?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</b>{" "}
          <br />
          {lang.statistics.contacts_type.unsubscriber}{" "}
          {lang.statistics.contacts_type.in_list} :{" "}
          <b>
            {unsubscribers?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
          </b>{" "}
          <br />
          {lang.statistics.contacts_type.blacklisted}{" "}
          {lang.statistics.contacts_type.in_list} :{" "}
          <b>{blacklisted?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</b>{" "}
          <br />
          {lang.statistics.contacts_type.excluded_previous_programmation} :{" "}
          <b>
            {excluded_previous_programmation
              ?.toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
          </b>{" "}
          <br />
          {lang.statistics.contacts_type.excluded_workflow} :{" "}
          <b>
            {excluded_workflow
              ?.toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
          </b>{" "}
          <br />
          {lang.statistics.contacts_type.excluded_programmation} :{" "}
          <b>
            {excluded_programmation
              ?.toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
          </b>{" "}
          <br />
          {lang.statistics.contacts_type.daily_limit} :{" "}
          <b>
            {limit_send_per_day
              ?.toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
          </b>{" "}
          <br />
        </Text>
      </HoverCard.Dropdown>
    </HoverCard>
  );
};

export function CampaignColumnContacted() {
  const lang = useLanguage.getState().language;
  return {
    field: "contacted",
    headerName: lang.statistics.columns.contacted.header_name,
    cellClassName: "statistics-button-parent",
    width: 150,
    renderCell: ({ row, value }) => {
      if (!row) {
        return;
      }
      if (row.isChild) {
        return (
          <Stack py={"xs"} w={"100%"}>
            {row.steps.map((step, index) => {
              return <ChildCell row={row} step={step} key={step.id} />;
            })}
          </Stack>
        );
      }

      return <ParentCell row={row} value={value} />;
    },
  };
}
