import { useCallback, useMemo, useState } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { useListingLoader } from "../../../../components/hooks/ListingHooks";
import SelectionDeleter from "../../../../components/TableComponents/SelectionDeleter";
import ItemDeleter from "../../../../components/TableComponents/ItemDeleter";
import {
  ActionIcon,
  Box,
  Divider,
  Flex,
  Group,
  Stack,
  Stepper,
  Text,
  ThemeIcon,
  Title,
  Tooltip,
} from "@mantine/core";
import dateColumn from "../../../../layouts/Table/columns/dateColumn";
import { nameColumn } from "../../../../layouts/Table/columns/nameColumn";
import MainTable from "../../../../layouts/Table/MainTable";
import TableLayoutBlock from "../../../../layouts/Table/TableLayoutBlock";
import numberColumn from "../../../../layouts/Table/columns/numberColumn";
import PageTitle from "../../../../components/View/PageTitle";
import { deleteAIKey, editAIKey, getAIKeys } from "../../../../api/ConfigsApi";
import { CommCode } from "../../community/CommCode";
import CreateAiKey from "./CreateAiKey";
import {
  IconBrandOpenai,
  IconCheck,
  IconEdit,
  IconX,
} from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { useSessionStore } from "../../../../stores/UserStore";
import Integrationllustration from "./Integrationllustration";
import kaspr_logo from "../../../../assets/logos/kaspr.png";

const AIKeyListing = () => {
  const apiRef = useGridApiRef();
  const lang = useLanguage((s) => s.language);
  const reseller = useSessionStore((s) => s.reseller);

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);

  const columns = useMemo(
    () => [
      numberColumn(),
      nameColumn({
        parentPath: () => `/extra/external-api-keys`,
        editFunction: () => editAIKey,
        getDisabled: (row) => row.created_by === null,
        apiRef,
      }),
      {
        field: "api_key",
        headerName: lang.api_keys.columns.key,
        width: 150,
        renderCell: ({ value }) => {
          return <CommCode token={value} />;
        },
      },
      {
        field: "type",
        headerName: lang.api_keys.columns.type,
        width: 100,
        renderCell: ({ value }) => {
          return <Text tt={"uppercase"}> {value} </Text>;
        },
      },
      {
        field: "is_valid",
        headerName: lang.emails_account.columns.is_valid_column,
        width: 75,
        renderCell: ({ value }) => (
          <Tooltip
            label={
              value
                ? lang.emails_account.columns.is_valid
                : lang.emails_account.columns.not_valid
            }
          >
            <ThemeIcon variant="subtle" color={value ? "tertiary.8" : "red"}>
              {value ? <IconCheck /> : <IconX />}
            </ThemeIcon>
          </Tooltip>
        ),
      },
      dateColumn(),
    ],
    [lang, apiRef]
  );

  const actionsCell = useCallback(
    ({ row }) => {
      return (
        <Group wrap="nowrap" gap={"xs"}>
          <Tooltip label={lang.global.edit}>
            <ActionIcon component={Link} to={`${row.id}`} variant="subtle">
              <IconEdit size={18} />
            </ActionIcon>
          </Tooltip>
          <ItemDeleter
            title_bold={`${row.name} #${row.id}`}
            handleDelete={deleteAIKey}
            deletedMessage={lang.integrations.delete_success}
            id={row.id}
            apiRef={apiRef}
          />
        </Group>
      );
    },
    [apiRef, lang.global.edit, lang.integrations.delete_success]
  );

  const toolbarActions = useCallback(
    ({ selectedIds }) => (
      <SelectionDeleter
        handleDelete={deleteAIKey}
        apiRef={apiRef}
        setTableLoading={setLoading}
        ids={selectedIds}
      />
    ),
    [apiRef]
  );

  useListingLoader({
    loading,
    setLoading,
    listGetter: getAIKeys,
    responseField: "external_api_keys_list",
    setRows,
  });

  return (
    <>
      <Group
        className="layout-block top"
        w={"100%"}
        gap={0}
        justify="space-between"
      >
        <PageTitle title={lang.integrations.browser_tab_title} />

        <CreateAiKey setTableLoading={setLoading} />
      </Group>

      <Group
        className="layout-block"
        flex={1}
        wrap="nowrap"
        align="stretch"
        gap={0}
        pr={0}
        pt={0}
      >
        <Stack w={"40%"} gap={"xs"} h={"100%"} pt={16}>
          <Box w={300} mt={8}>
            <Integrationllustration />
          </Box>
          <Title order={3}>
            {lang.integrations.left_title.replaceAll("#", reseller?.name)}
          </Title>
          <Text size="sm">
            {lang.integrations.left_text.replaceAll("#", reseller?.name)}
          </Text>

          <Stepper
            mt={16}
            w={"100%"}
            orientation="vertical"
            flex={1}
            styles={{
              stepDescription: {
                marginTop: 10,
              },
            }}
          >
            <Stepper.Step
              icon={<IconBrandOpenai size={24} />}
              label={lang.integrations.left_openai_title}
              description={lang.integrations.left_openai_text}
              styles={{
                stepDescription: {
                  lineHeight: 1.5,
                  marginBottom: 16,
                },
              }}
            />
            <Stepper.Step
              icon={
                <ThemeIcon size={24} variant="subtle">
                  <img src={kaspr_logo} style={{ width: 24 }} />
                </ThemeIcon>
              }
              label={lang.integrations.left_kaspr_title}
              description={lang.integrations.left_kaspr_text}
              styles={{
                stepDescription: {
                  lineHeight: 1.5,
                  marginBottom: 16,
                },
              }}
            />
          </Stepper>
        </Stack>

        <Divider orientation="vertical" ml={32} />

        <Flex w={"100%"}>
          <MainTable
            apiRef={apiRef}
            columns={columns}
            rows={rows}
            loading={loading}
            setLoading={setLoading}
            id={"list-table"}
            toolbarActions={toolbarActions}
            actionsCell={actionsCell}
            actionsCellWidth={75}
          />
        </Flex>
      </Group>
    </>
  );
};

export default AIKeyListing;
