import { Button, Tooltip } from "@mantine/core";
import { IconUserPlus } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { useLanguage } from "../../../../stores/LanguageStore";

const CreateUser = () => {
  const lang = useLanguage((s) => s.language);
  return (
    <Tooltip label={lang.users_list.create.button_tooltip}>
      <Button
        leftSection={<IconUserPlus size={18} />}
        radius={"sm"}
        component={Link}
        to={"create"}
      >
        {lang.users_list.create.button_label}
      </Button>
    </Tooltip>
  );
};

export default CreateUser;
