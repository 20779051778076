import { useMemo, useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { extractConnections } from "../../../../../api/TargetingLinkedInAPI";
import {
  Button,
  Center,
  Divider,
  Group,
  NumberInput,
  Radio,
  Stack,
  TextInput,
  Timeline,
  TimelineItem,
  Title,
} from "@mantine/core";
import {
  useProfileStore,
  useSessionStore,
} from "../../../../../stores/UserStore";
import {
  IconArrowBarUp,
  IconListDetails,
  IconSparkles,
} from "@tabler/icons-react";
import LinkedinAccountSelector from "../../../../../components/Selectors/LinkedinAccountSelector";
import { useRouteLoaderData } from "react-router-dom";
import GlobalLanguageSelector from "../../../../../components/Selectors/GlobalLanguageSelector";
import CountrySelector from "../../../../../components/Selectors/CountrySelector";
import CreatableContactListSelector from "../../../../../components/Selectors/CreatableContactListSelector";
import ImportWelcome from "../ImportWelcome";
import HowItWorks from "../../pages/HowItWorks";
import RemainingHoverCard from "../../components/RemainingHoverCard";

const LinkedinConnections = ({ onFinish, listId, setShouldWarn }) => {
  const profile = useProfileStore((s) => s.profile);
  const lang = useLanguage((s) => s.language);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const listDataLoader = useRouteLoaderData("contact-list");
  const listDataFolderLoader = useRouteLoaderData("folder-contact-list");
  const listProfile = useMemo(() => {
    return listDataLoader || listDataFolderLoader;
  }, [listDataLoader, listDataFolderLoader]);
  const [list, setList] = useState(listProfile);
  const [listState, setListState] = useState(listProfile ? 2 : 1);

  const [account, setAccount] = useState(null);
  const handleSubmit = (e) => {
    let form = document.getElementById("form");
    if (form.reportValidity()) {
      let f = new FormData(form);
      let data = Object.fromEntries(f);

      data = {
        ...data,
        linkedin_account_id: account || null,
        contact_list_name: listState === 1 ? data?.contact_list_name : null,
        contact_list_id: listState === 2 ? list?.id : null,
        max_results: parseInt(data?.max_results),
        generate_email: e?.generate_email || false,
      };

      setLoading(e?.generate_email ? "yes" : "no");
      setError(null);
      extractConnections(data)
        .then((response) => {
          onFinish?.(response.data.contact_list_id);
        })
        .catch((err) => {
          if (err.response.data.state_message === "empty_name") {
            document.getElementById("new-list-name").focus();
          }
          if (
            err.response.data.state_message ===
            "invalid_linkedin_people_search_url"
          ) {
            document.getElementById("url-search").focus();
          }
          setError(err.response.data.state_message);
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <Group
      px={"sm"}
      justify="space-between"
      h={"100%"}
      align="start"
      wrap="nowrap"
      gap={48}
    >
      <ImportWelcome
        title={lang.targeting_linkedin_google.connection}
        type={"linkedin_connections_welcome"}
        video_url={
          "https://www.youtube.com/embed/hPFIR_-xJpg?si=5OxyxRQbpElYjwbz"
        }
      />
      <Stack
        component={"form"}
        onSubmit={handleSubmit}
        gap={"md"}
        onChange={() => setShouldWarn?.(true)}
        w={"100%"}
        id="form"
      >
        <Title order={5}>{lang.targeting_linkedin_google.connection}</Title>

        <Timeline
          styles={{
            item: {
              marginBottom: -12,
            },
            itemContent: {
              marginLeft: 8,
            },
            itemBullet: {
              top: 6,
            },
          }}
          lineWidth={2}
          mb={16}
          bulletSize={12}
          color="tertiary.8"
        >
          <TimelineItem mt={-8}>
            <LinkedinAccountSelector
              label={lang.targeting_linkedin_google.account}
              required
              value={account}
              onChange={(v) => setAccount(v?.id || null)}
              size={"sm"}
              w="100%"
            />
          </TimelineItem>

          <TimelineItem>
            <NumberInput
              name="max_results"
              label={lang.targeting_linkedin_google.max_results}
              required
              min={1}
              max={10000}
              clampBehavior="strict"
              description="max: 10 000"
              defaultValue={10000}
              w={"100%"}
              allowNegative={false}
              allowDecimal={false}
            />
          </TimelineItem>

          <TimelineItem>
            <Group w={"100%"} wrap="nowrap" gap={"xl"}>
              <GlobalLanguageSelector
                key={"language-" + list?.id}
                defaultValue={list?.language || profile?.contact_list_language}
                name={"contact_list_language"}
                size="sm"
                w={"100%"}
              />
              <CountrySelector
                key={"country-" + list?.id}
                defaultValue={list?.country || profile?.contact_list_country}
                name={"contact_list_country"}
                required
                w={"100%"}
              />
            </Group>
          </TimelineItem>

          <TimelineItem>
            <Group wrap="nowrap" align="end" gap={"xl"}>
              <Stack w="100%" gap={8}>
                <Radio
                  label={lang.targeting_linkedin_google.create_new_list}
                  name="list_state"
                  checked={listState === 1}
                  size="sm"
                  onChange={(e) => setListState(1)}
                />
                <TextInput
                  id="new-list-name"
                  name="contact_list_name"
                  w={"100%"}
                  disabled={listState !== 1}
                  styles={{ label: { fontSize: 13 } }}
                  label={lang.targeting_linkedin_google.list_name}
                  description={lang.contact_lists.create.dialog.desc}
                  placeholder={lang.targeting_linkedin_google.list_name}
                  required={listState === 1}
                />
              </Stack>
              <Stack w="100%" gap={8}>
                <Radio
                  label={lang.targeting_linkedin_google.import}
                  name="list_state"
                  size="sm"
                  checked={listState === 2}
                  onChange={(e) => setListState(2)}
                />
                <CreatableContactListSelector
                  value={list}
                  setValue={setList}
                  label={lang.targeting_linkedin_google.select_list}
                  placeholder={lang.contact_lists.create.dialog.desc}
                  w={"100%"}
                  disabled={listState !== 2}
                  styles={{ label: { fontSize: 13 } }}
                  required={listState === 2}
                  leftSection={<IconListDetails size={16} />}
                  disableCreate
                  description={lang.contact_lists.create.dialog.desc}
                />
              </Stack>
            </Group>

            <Group justify="center" mt={"lg"} gap={"xl"} wrap="nowrap">
              <Button
                variant="light"
                leftSection={<IconArrowBarUp />}
                loading={loading === "no"}
                onClick={(e) => handleSubmit({ ...e, generate_email: false })}
                w={300}
              >
                {lang.targeting_linkedin_google.submit_button_add}
              </Button>

              {profile?.permissions?.find((x) => x.name === "generateEmail")
                ?.value && (
                <Group wrap="nowrap" w={300} gap={"md"}>
                  <RemainingHoverCard>
                    <Button
                      leftSection={<IconSparkles />}
                      loading={loading === "yes"}
                      onClick={(e) =>
                        handleSubmit({ ...e, generate_email: true })
                      }
                      w={250}
                    >
                      {lang.targeting_linkedin_google.submit_button_add_email}
                    </Button>
                  </RemainingHoverCard>

                  <HowItWorks type={"linkedin"} inside />
                </Group>
              )}
            </Group>
          </TimelineItem>
        </Timeline>
      </Stack>

      {useSessionStore.getState().reseller?.name === "Magileads" && (
        <>
          <Divider orientation="vertical" />

          <Center h={"100%"} pr={"lg"}>
            <iframe
              width="480"
              height="400"
              src="https://www.youtube.com/embed/hPFIR_-xJpg?si=5OxyxRQbpElYjwbz"
              title="YouTube video player"
              allowfullscreen=""
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              style={{
                borderRadius: 12,
                overflow: "hidden",
                border: "1px solid #00000033",
              }}
            />
          </Center>
        </>
      )}
    </Group>
  );
};

export default LinkedinConnections;
