import { Button, Modal, Tooltip } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useLanguage } from "../../stores/LanguageStore";
import CreateModelLinkedin from "../../pages/protected/models/linkedin/CreateModelLinkedin";

const AddModelLinkedin = ({ onModelCreated, type, maxChars }) => {
  const lang = useLanguage((s) => s.language);
  const [opened, { open, close }] = useDisclosure();

  return (
    <>
      <Tooltip label={lang.campaign.panel.model.tooltip}>
        <Button variant="subtle" size="compact-sm" onClick={open} fw={700}>
          {lang.campaign.panel.model.label}
        </Button>
      </Tooltip>

      <Modal
        opened={opened}
        onClose={close}
        size={600}
        title={
          lang[
            type === "linkedin_invitation"
              ? "model_invitation_linkedin"
              : type === "linkedin_message"
              ? "model_message_linkedin"
              : "model_sms"
          ].create.dialog.title
        }
        styles={{
          header: {
            paddingBottom: 8,
            minHeight: 32,
          },
        }}
        removeScrollProps={{
          enabled: false,
        }}
      >
        <CreateModelLinkedin
          onCreated={onModelCreated}
          handleClose={close}
          type={type}
          maxChars={maxChars}
        />
      </Modal>
    </>
  );
};

export default AddModelLinkedin;
