import { useGridApiRef } from "@mui/x-data-grid-pro";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useCallback, useMemo, useState } from "react";
import numberColumn from "../../../../layouts/Table/columns/numberColumn";
import { nameColumn } from "../../../../layouts/Table/columns/nameColumn";
import { sharingColumn } from "../../../../layouts/Table/columns/sharingColumn";
import dateColumn from "../../../../layouts/Table/columns/dateColumn";
import SelectionDeleter from "../../../../components/TableComponents/SelectionDeleter";
import {
  deleteLinkedinAccount,
  editLinkedinAccount,
  getLinkedinAccounts,
} from "../../../../api/EmailAPI";
import { useListingLoader } from "../../../../components/hooks/ListingHooks";
import {
  ActionIcon,
  Anchor,
  CopyButton,
  Group,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import TableLayoutBlock from "../../../../layouts/Table/TableLayoutBlock";
import MainTable from "../../../../layouts/Table/MainTable";
import {
  IconBrandLinkedin,
  IconCheck,
  IconCompass,
  IconEdit,
  IconX,
} from "@tabler/icons-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ItemDeleter from "../../../../components/TableComponents/ItemDeleter";
import CreateLinkedinSender from "./CreateLinkedinSender";
import { poolsColumn } from "../../../../layouts/Table/columns/poolsColumn";
import PageTitle from "../../../../components/View/PageTitle";
import SelectionSharer from "../../../../components/TableComponents/SelectionSharer";
import Helper from "../../../../components/View/Helper";

const LinkedinAccountsListing = () => {
  const apiRef = useGridApiRef();
  const lang = useLanguage((s) => s.language);
  const { poolId } = useParams();
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      numberColumn(),
      nameColumn({
        parentPath: () =>
          `/senders/linkedin${poolId ? `/pool/${poolId}/linkedin` : ""}`,
        editFunction:
          () =>
          ({ id, name }) =>
            editLinkedinAccount({ id, data: { name } }),
        apiRef,
      }),
      {
        field: "is_sales_navigator_account",
        headerName: lang.linkedin_accounts.columns.is_sales_navigator_account,
        width: 125,
        renderCell: ({ value }) => (
          <Tooltip label={value ? "Sales Navigator" : "LinkedIn"}>
            <ThemeIcon
              variant="light"
              color={value ? "tertiary.8" : "var(--mantine-color-blue-7)"}
            >
              {value ? (
                <IconCompass size={23} />
              ) : (
                <IconBrandLinkedin size={23} />
              )}
            </ThemeIcon>
          </Tooltip>
        ),
      },
      {
        field: "is_valid",
        headerName: lang.emails_account.columns.is_valid_column,
        width: 75,
        renderCell: ({ value }) => (
          <Tooltip
            label={
              value
                ? lang.emails_account.columns.is_valid
                : lang.emails_account.columns.not_valid
            }
          >
            <ThemeIcon variant="subtle" color={value ? "tertiary.8" : "red"}>
              {value ? <IconCheck /> : <IconX />}
            </ThemeIcon>
          </Tooltip>
        ),
      },
      {
        field: "session_cookie",
        headerName: lang.linkedin_accounts.columns.session_cookie,
        width: 120,
        renderCell: ({ value }) => (
          <CopyButton value={value}>
            {({ copied, copy }) => (
              <Tooltip
                label={copied ? lang.global.copied : lang.global.copy}
                color={copied ? "green" : "black"}
              >
                <Anchor c={"black"} onClick={copy} truncate="end">
                  {value}
                </Anchor>
              </Tooltip>
            )}
          </CopyButton>
        ),
      },
      {
        field: "session_cookie_sn",
        headerName: lang.linkedin_accounts.columns.session_cookie + " SN",
        width: 120,
        renderCell: ({ value }) => (
          <CopyButton value={value}>
            {({ copied, copy }) => (
              <Tooltip
                label={copied ? lang.global.copied : lang.global.copy}
                color={copied ? "green" : "black"}
              >
                <Anchor c={"black"} onClick={copy} truncate="end">
                  {value}
                </Anchor>
              </Tooltip>
            )}
          </CopyButton>
        ),
      },
      poolsColumn({
        apiRef,
        editFunction:
          (row) =>
          ({ id, pools_ids }) =>
            editLinkedinAccount({
              id,
              data: { pools_ids },
            }),
        type: "Linkedin",
      }),
      dateColumn("last_use", lang.emails_account.columns.last_use),
      dateColumn(),
      sharingColumn({
        apiRef,
        editFunction: () => editLinkedinAccount,
      }),
    ],
    [apiRef, lang, poolId]
  );

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);

  const actionsCell = useCallback(
    ({ row }) => {
      return (
        <Group wrap="nowrap" gap={4}>
          <ActionIcon component={Link} to={`${row.id}`} variant="subtle">
            <IconEdit size={18} />
          </ActionIcon>

          <ItemDeleter
            title_bold={`${row.name} #${row.id}`}
            handleDelete={deleteLinkedinAccount}
            deletedMessage={lang.linkedin_accounts.delete_success}
            id={row.id}
            disabled={
              !row?.delete_permission && !row?.permission?.delete_permission
            }
            apiRef={apiRef}
          />
        </Group>
      );
    },
    [apiRef, lang.linkedin_accounts.delete_success]
  );

  const toolbarActions = useCallback(
    ({ selectedIds }) => (
      <>
        <SelectionDeleter
          handleDelete={deleteLinkedinAccount}
          apiRef={apiRef}
          setTableLoading={setLoading}
          ids={selectedIds}
        />
        <SelectionSharer
          handleShare={editLinkedinAccount}
          apiRef={apiRef}
          setTableLoading={setLoading}
          ids={selectedIds}
        />
      </>
    ),
    [apiRef]
  );

  useListingLoader({
    loading,
    setLoading,
    listGetter: getLinkedinAccounts,
    responseField: "linkedin_accounts_list",
    setRows,
    folderRouteId: "linkedin-pool",
    isPool: true,
    onGet: (results) => {
      if (results.length === 0) {
        navigate("create");
      }
    },
  });

  return (
    <>
      <Group
        className="layout-block top"
        w={"100%"}
        gap={0}
        justify="space-between"
      >
        <PageTitle title={lang.linkedin_accounts.browser_tab_title} />

        <Helper
          label={lang.helpers.linkedin}
          link={
            "https://app.supademo.com/embed/cm156yhou02w9me0purpj74pp?embed_v=2"
          }
        />
        <CreateLinkedinSender />
      </Group>

      <TableLayoutBlock
        folderType={"Linkedin"}
        parentRoute={"/senders/linkedin"}
        getEditFunction={() => {
          return ({ id, pools_ids }) =>
            editLinkedinAccount({
              id,
              data: { pools_ids },
            });
        }}
        apiRef={apiRef}
        isPool
        setTableLoading={setLoading}
      >
        <MainTable
          apiRef={apiRef}
          columns={columns}
          rows={rows}
          loading={loading}
          setLoading={setLoading}
          id={"sender-table"}
          toolbarActions={toolbarActions}
          actionsCell={actionsCell}
          pageName={"integrations_linkedin"}
          pageOrderName={"integrations_linkedin_order"}
          actionsCellWidth={75}
        />
      </TableLayoutBlock>
    </>
  );
};

export default LinkedinAccountsListing;
