import { Handle, Position } from "reactflow";
import { getNodeDetails } from "../../flowSettings";
import { useMemo } from "react";
import ActionNodePreview from "./ActionNodePreview";
import EventNodePreview from "./EventNodePreview";

const BaseNodePreview = ({ data, id }) => {
  const nodeDetail = useMemo(
    () => getNodeDetails(data.actionType),
    [data.actionType]
  );

  const nodeComponent = useMemo(() => {
    if (data?.actionType === "event") {
      return <EventNodePreview data={data} id={id} nodeDetail={nodeDetail} />;
    }
    return <ActionNodePreview data={data} id={id} nodeDetail={nodeDetail} />;
  }, [data, id, nodeDetail]);

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        style={{ visibility: "hidden" }}
      />

      {nodeComponent}

      <Handle
        type="source"
        position={Position.Bottom}
        style={{ visibility: "hidden" }}
      />
    </>
  );
};

export default BaseNodePreview;
