import { Button } from "@mantine/core";
import { IconNote } from "@tabler/icons-react";
import { useLeadsSheetStore } from "../../../../../../../../stores/LeadsStore";
import { useLanguage } from "../../../../../../../../stores/LanguageStore";

const AddNote = () => {
  const lang = useLanguage((s) => s.language);
  const current = useLeadsSheetStore((s) => s.current);
  const setCurrent = useLeadsSheetStore((s) => s.setCurrent);
  const handleAdd = () => {
    let alreadyThere = document.getElementById("note-creator");

    if (alreadyThere) {
      alreadyThere.focus();
    } else {
      let newNote = {
        date: new Date().toISOString(),
        id: "note-creator",
        date_updated: null,
        note: true,
        history_type: "note",
        isCreate: true,
      };
      setCurrent({
        ...current,
        history: [newNote, ...(current?.history || [])],
      });
    }
  };

  return (
    <Button
      leftSection={<IconNote size={18} />}
      color="secondary"
      onClick={handleAdd}
      mih={36}
      variant="light"
      mb={24}
      style={{
        border: "1px solid var(--mantine-color-secondary-2)",
      }}
    >
      {lang.prm.details.notes.button_label}
    </Button>
  );
};

export default AddNote;
