import { HoverCard, LoadingOverlay, Stack, Text } from "@mantine/core";
import { useProfileStore } from "../../../../stores/UserStore";
import { useEffect, useMemo, useState } from "react";
import { updateSelf } from "../../../../api/UserAPI";
import { useLanguage } from "../../../../stores/LanguageStore";

const RemainingHoverCard = ({ children }) => {
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);
  const [loading, setLoading] = useState(false);

  const enrichments = useMemo(() => {
    if (!profile?.permissions) {
      return " - ";
    }
    return profile?.permissions?.find(
      (x) => x.name === "maxEmailsGeneratedPerMonth"
    )?.remaining;
  }, [profile]);

  useEffect(() => {
    if (
      !profile?.permissions?.find(
        (x) => x.name === "maxEmailsGeneratedPerMonth"
      )
    ) {
      setLoading(true);
      updateSelf(true).finally(() => setLoading(false));
    }
  }, [profile]);

  return (
    <HoverCard position="top">
      <HoverCard.Target>{children}</HoverCard.Target>
      <HoverCard.Dropdown>
        <Stack>
          <LoadingOverlay visible={loading} loaderProps={{ size: 16 }} />
          <Text>
            {lang.contact_lists.import.methods.remains.replace(
              "#",
              enrichments
            )}
          </Text>
        </Stack>
      </HoverCard.Dropdown>
    </HoverCard>
  );
};

export default RemainingHoverCard;
