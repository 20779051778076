import Sparkles from "../../../../components/Sparkles/Sparkles";
import {
  Box,
  Button,
  CloseButton,
  Group,
  NumberInput,
  Paper,
  Popover,
  Slider,
  Stack,
  Text,
  Textarea,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  IconBaselineDensityMedium,
  IconSparkles,
  IconTemperature,
} from "@tabler/icons-react";
import { useState } from "react";
import GlobalLanguageSelector from "../../../../components/Selectors/GlobalLanguageSelector";
import { useLanguage } from "../../../../stores/LanguageStore";
import { generateEmail } from "../../../../api/ModelsApi";
import TemperatureSlider from "../../../../components/View/TemperatureSlider";

const Generator = ({ style, disabled, defaultPrompt, onFinish }) => {
  const lang = useLanguage((s) => s.language);
  const [prompt, setPrompt] = useState(defaultPrompt || "");
  const [opened, { open, close }] = useDisclosure();
  const [loading, setLoading] = useState(false);

  const handleGenerate = (e) => {
    e.preventDefault();
    let fd = new FormData(e.target);
    let data = Object.fromEntries(fd);

    setLoading(true);
    generateEmail({
      prompt: prompt,
      language: data?.language,
      number_paragraphs: parseInt(data?.paragraphs || 4),
      isHtml: true,
      temperature: parseFloat(data.temperature),
    })
      .then((response) => {
        let generatedHtml = response.data.html;
        close();
        onFinish(generatedHtml);
      })
      .finally(() => setLoading(false));

    e.stopPropagation();
  };

  return (
    <Popover
      trapFocus
      shadow="sm"
      withArrow
      opened={opened}
      onClose={close}
      onOpen={open}
      zIndex={900}
    >
      <Popover.Target>
        <Tooltip label={lang.models.generator.text}>
          <Box style={style} onClick={disabled ? null : open}>
            <Sparkles>
              <Button
                leftSection={<IconSparkles size={16} />}
                variant="gradient"
                disabled={disabled}
                size="sm"
                h={32}
              >
                {lang.models.generator.label}
              </Button>
            </Sparkles>
          </Box>
        </Tooltip>
      </Popover.Target>
      <Popover.Dropdown>
        <Stack
          component={"form"}
          onSubmit={handleGenerate}
          w={400}
          gap={6}
          pos={"relative"}
          id="ai-form"
        >
          <CloseButton pos={"absolute"} top={0} right={0} onClick={close} />
          <Text>{lang.models.generator.text}</Text>
          <Textarea
            required
            value={prompt}
            placeholder={lang.model_emails.create.dialog.generate_placeholder}
            onChange={(e) =>
              255 - prompt.length > 0 ? setPrompt(e.target.value) : null
            }
            label="Prompt"
            description={255 - prompt.length + lang.global.char_remaining}
            rows={4}
            data-autoFocus
          />
          <Group mt={"sm"}>
            <GlobalLanguageSelector
              noLabel
              name={"language"}
              withinPortal={false}
            />
            <NumberInput
              name="number_paragraphs"
              defaultValue={3}
              leftSection={
                <Tooltip label={lang.model_emails.create.dialog.paragraphs}>
                  <IconBaselineDensityMedium size={16} />
                </Tooltip>
              }
              w={75}
              size="xs"
              mr={"auto"}
              min={1}
              max={4}
            />

            <TemperatureSlider sm />
          </Group>

          <Group justify="end" mt={"sm"}>
            <Button
              leftSection={<IconSparkles size={18} />}
              type="submit"
              form="ai-form"
              loading={loading}
            >
              {lang.models.generator.generate}
            </Button>
          </Group>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
};

export default Generator;
