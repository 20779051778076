import {
  Box,
  Group,
  Loader,
  Stack,
  Text,
  Timeline,
  TimelineItem,
} from "@mantine/core";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { LoadingIllustration } from "../../../../../assets/LoadingIllustration";
import { IconCheck, IconLoader } from "@tabler/icons-react";

const LinksLoading = ({ step, links }) => {
  const lang = useLanguage((s) => s.language);

  return (
    <Group wrap="nowrap" align="start" justify="left" w={"100%"}>
      <Box
        w={200}
        mih={210}
        className={`animate__animated ${"animate__fadeInLeft"}`}
      >
        <LoadingIllustration />
      </Box>
      <Stack w={500}>
        <Text fz={18} mb={8}>
          {lang.contact_lists.import.loading.text}
        </Text>

        <Timeline active={step} color="tertiary.8" bulletSize={28}>
          {links.map((link, index) => (
            <TimelineItem
              key={link}
              title={
                <Text pt={3} opacity={step >= index ? 1 : 0.5} truncate>
                  {link}
                </Text>
              }
              bullet={
                step === index ? (
                  <Loader type="oval" size={16} color="white" />
                ) : step > index ? (
                  <IconCheck size={18} />
                ) : (
                  <IconLoader size={18} />
                )
              }
              className={`animate__animated ${"animate__fadeInUp"}`}
              style={{
                animationDelay: `${0.2 * index}s`,
              }}
            />
          ))}
        </Timeline>
      </Stack>
    </Group>
  );
};

export default LinksLoading;
