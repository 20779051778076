import {
  ActionIcon,
  Button,
  Group,
  Indicator,
  Loader,
  Menu,
  Text,
  Tooltip,
} from "@mantine/core";
import { IconBell, IconMail, IconTrash } from "@tabler/icons-react";
import { useCallback, useEffect, useState } from "react";
import {
  useAccountsStore,
  useNotificationStore,
} from "../../stores/ConfigStore";
import { Link } from "react-router-dom";
import { IconLinkedin } from "../../components/Icons/IconLinkedin";
import { useLanguage } from "../../stores/LanguageStore";
import { getEmails, getLinkedinAccounts } from "../../api/EmailAPI";

const Notifications = ({ is2 }) => {
  const lang = useLanguage((s) => s.language);
  const emails = useAccountsStore((s) => s.email_accounts);
  const linkedins = useAccountsStore((s) => s.linkedin_accounts);

  const notifs = useNotificationStore((s) => s.notifications);
  const setNotifications = useNotificationStore((s) => s.setNotifications);
  const deleted = useNotificationStore((s) => s.deleted);
  const setDeleted = useNotificationStore((s) => s.setDeleted);

  const [loading, setLoading] = useState(false);
  const handleLoad = useCallback(() => {
    setLoading(true);
    getEmails().then(() =>
      getLinkedinAccounts().finally(() => setLoading(false))
    );
  }, []);

  const deleteNotif = (item, isLinkedin) => {
    let newDeleted = { ...deleted };

    if (isLinkedin) {
      newDeleted.linkedins.push(item);
    } else {
      newDeleted.emails.push(item);
    }

    setDeleted(newDeleted);
  };

  useEffect(() => {
    handleLoad();
  }, [handleLoad]);

  useEffect(() => {
    setNotifications({
      emails:
        emails?.filter(
          (x) => !x.is_valid && !deleted.emails.find((d) => d.id === x.id)
        ) || [],
      linkedins:
        linkedins?.filter(
          (x) => !x.is_valid && !deleted.linkedins.find((d) => d.id === x.id)
        ) || [],
    });
  }, [deleted.emails, deleted.linkedins, emails, linkedins, setNotifications]);

  return (
    <Menu withArrow offset={-4} onOpen={handleLoad}>
      <Menu.Target>
        <Tooltip label="Notifications">
          <Indicator
            color="red"
            label={notifs?.emails?.length + notifs?.linkedins?.length || null}
            offset={14}
            disabled={
              notifs?.emails?.length + notifs?.linkedins?.length === 0 ||
              (!notifs?.emails && !notifs.linkedins)
            }
            size={16}
          >
            <Button variant="subtle" px={12} size="lg" color={is2 ? "#000" : "white"}>
              <IconBell />
            </Button>
          </Indicator>
        </Tooltip>
      </Menu.Target>
      <Menu.Dropdown>
        {notifs?.emails?.map((email) => (
          <Menu.Item
            component={Link}
            key={email.id}
            to={`/senders/email/${email.id}`}
            variant="subtle"
            justify="start"
            color="red"
            leftSection={<IconMail size={18} />}
            fw={500}
            rightSection={
              <ActionIcon
                variant="filled"
                color="red"
                onClick={(e) => {
                  e.preventDefault();
                  deleteNotif(email);
                  e.stopPropagation();
                }}
              >
                <IconTrash size={18} />
              </ActionIcon>
            }
          >
            {email?.name || `#${email?.id}`} {lang.header.is_disconnected}
          </Menu.Item>
        ))}
        {notifs?.linkedins?.map((account) => (
          <Menu.Item
            component={Link}
            key={account.id}
            to={`/senders/linkedin/${account.id}`}
            variant="subtle"
            justify="start"
            color="red"
            leftSection={<IconLinkedin size={18} />}
            fw={500}
            rightSection={
              <ActionIcon
                variant="filled"
                color="red"
                onClick={(e) => {
                  e.preventDefault();
                  deleteNotif(account, true);
                  e.stopPropagation();
                }}
              >
                <IconTrash size={18} />
              </ActionIcon>
            }
          >
            {account.name} {lang.header.is_not_verified}
          </Menu.Item>
        ))}

        {notifs?.linkedins?.length === 0 && notifs?.emails?.length === 0 ? (
          <Text mx={16} my={8} c={"dimmed"}>
            {lang.header.no_notifications}
          </Text>
        ) : (
          ""
        )}

        {loading && (
          <Group justify="center" my={"xs"}>
            <Loader size={18} />
          </Group>
        )}
      </Menu.Dropdown>
    </Menu>
  );
};

export default Notifications;
