import { Button, Group, Stack, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import CreateEmailSender from "../../tools/emails/components/CreateEmailSender";
import { IconArrowRight } from "@tabler/icons-react";
import { useLanguage } from "../../../../stores/LanguageStore";

const Step2 = ({ setStep, setEmail }) => {
  const lang = useLanguage((s) => s.language);
  const [goingNext, setGoingNext] = useState(false);

  useEffect(() => {
    if (goingNext) {
      setTimeout(() => {
        setStep(2);
      }, 600);
    }
  }, [goingNext]);

  return (
    <Stack pos={"relative"} justify="center" align="center">
      <Text
        fz={26}
        className={`animate__animated ${
          goingNext ? "animate__fadeOut" : "animate__fadeIn"
        }`}
        style={{ animationDelay: goingNext ? "0s" : ".1s" }}
      >
        {lang.home.create_campaign.step2.title}
      </Text>

      <CreateEmailSender
        onCreated={(newItem) => {
          setEmail(newItem);
          setGoingNext(true);
        }}
        className={`animate__animated ${
          goingNext ? "animate__fadeOut" : "animate__fadeIn"
        }`}
        style={{ animationDelay: goingNext ? ".1s" : ".2s" }}
      />

      <Group justify="space-between" w={"100%"} px={"sm"} mt={"sm"}>
        <Button variant="subtle" onClick={() => setStep(0)}>
          {lang.global.back}
        </Button>
        <Button
          rightSection={<IconArrowRight />}
          className={`animate__animated ${
            goingNext ? "animate__fadeOut" : "animate__fadeIn"
          }`}
          style={{ animationDelay: goingNext ? ".2s" : ".3s" }}
          variant="gradient"
          onClick={() => setGoingNext(true)}
          gradient={{ from: "secondary.3", to: "secondary.6" }}
          radius={"sm"}
          pl={24}
        >
          {lang.global.ignore}
        </Button>
      </Group>
    </Stack>
  );
};

export default Step2;
