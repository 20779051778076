import { useState } from "react";
import { useLanguage } from "../../stores/LanguageStore";
import { useDisclosure } from "@mantine/hooks";
import {
  ActionIcon,
  Button,
  Group,
  Modal,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import { IconCopy } from "@tabler/icons-react";
import BackButton from "./BackButton";
import { notifications } from "@mantine/notifications";

const DuplicateRessource = ({
  current,
  handler,
  closeMenu,
  onFinish,
  responseField,
  isIcon,
}) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(false);
  const [opened, { open, close }] = useDisclosure();

  const handleClose = () => {
    close();
    closeMenu?.();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    handler({ id: current.id })
      .then((res) => {
        notifications.show({
          message: lang.contact_lists.duplicate.success,
          color: "tertiary.8",
        });
        close();
        onFinish?.(res.data?.[responseField]);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {isIcon ? (
        <Tooltip label={lang.contact_lists.duplicate.label}>
          <ActionIcon onClick={open} color="primary" variant="subtle">
            <IconCopy size={16} />
          </ActionIcon>
        </Tooltip>
      ) : (
        <Button
          onClick={open}
          leftSection={<IconCopy size={18} />}
          fw={600}
          color="primary"
          variant="subtle"
          justify="start"
        >
          {lang.contact_lists.duplicate.label}
        </Button>
      )}

      {opened && (
        <Modal
          opened={opened}
          size={"md"}
          onClose={handleClose}
          title={
            <>
              {lang.contact_lists.duplicate.dialog.title}{" "}
              <b>
                {current?.name} #{current.id}
              </b>
            </>
          }
        >
          <Stack component={"form"} onSubmit={handleSubmit} gap={"xs"}>
            <Text c={"dimmed"}>{lang.contact_lists.duplicate.dialog.text}</Text>

            <Group justify="end" mt={"md"}>
              <BackButton onClick={handleClose} />

              <Button
                leftSection={<IconCopy size={18} />}
                loading={loading}
                color="primary"
                type="submit"
              >
                {lang.contact_lists.duplicate.dialog.submit_button}
              </Button>
            </Group>
          </Stack>
        </Modal>
      )}
    </>
  );
};

export default DuplicateRessource;
