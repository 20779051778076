import { useEffect, useMemo, useState } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useProfileStore } from "../../../../stores/UserStore";
import { updatePermission } from "../../../../api/AdminAPI";
import { notifications } from "@mantine/notifications";
import { Group, Loader, NumberInput, Tooltip } from "@mantine/core";

const LimitField = ({ item, profile, setProfile, loadingProfile }) => {
  const me = useProfileStore((s) => s.profile);
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(false);

  const defaultValue = useMemo(() => {
    return profile?.permissions?.find((x) => x.name === item?.value)?.value;
  }, [item?.value, profile?.permissions]);

  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = ({ field, newValue }) => {
    if (newValue !== defaultValue) {
      setLoading(true);
      updatePermission({
        permission_name: field,
        user_id: profile?.id,
        value: newValue,
      })
        .then((response) => {
          setProfile({
            ...profile,
            permissions: profile.permissions.map((p) => {
              if (p.name === item.value) {
                return {
                  ...p,
                  value: newValue,
                };
              }
              return p;
            }),
          });
          notifications.show({
            message: lang.administration.right.limits.changed,
            color: "tertiary.8",
          });
        })
        .catch((err) => {
          setValue(
            profile?.permissions?.find((x) => x.name === item?.value)?.value
          );
        })
        .finally(() => setLoading(false));
    }
  };

  const getDisabled = (field) => {
    if (loading || loadingProfile) {
      return true;
    }
    if (me?.level === "admin") {
      return false;
    }
    if (profile?.permissions?.find((x) => x.name === field)?.is_editable) {
      return false;
    }
    return false;
  };

  const used = useMemo(() => {
    if (item.value.includes("PerDay")) {
      let x = profile?.permissions?.find((x) => x.name === item?.value);
      if (x) return x.value - x.remaining;
    }
    return null;
  }, [item.value, profile?.permissions]);

  return (
    <Tooltip label={item?.description}>
      <NumberInput
        label={item?.label}
        min={0}
        w={"100%"}
        value={value || 0}
        onChange={setValue}
        onBlur={(e) =>
          handleChange({
            field: item?.value,
            newValue: parseInt(e.target.value),
          })
        }
        disabled={getDisabled(item.value)}
        leftSectionWidth={"50%"}
        leftSection={
          loading ? (
            <Loader size={14} />
          ) : (
            (item.value.includes("PerDay") ||
              item.value.includes("PerMonth")) && (
              <Tooltip
                label={used ? `${used} utilisés` : null}
                disabled={!used}
              >
                <Group pl={16} pr={16} justify="space-between" gap={8} w={"100%"} c="dimmed">
                  <span>{used || " - "}</span>
                  <span>/</span>
                </Group>
              </Tooltip>
            )
          )
        }
      />
    </Tooltip>
  );
};

export default LimitField;
