import { Button, SegmentedControl, Stack, Tooltip } from "@mantine/core";
import PopOver from "../View/PopOver";
import {
  IconChevronDown,
  IconCircleArrowDownFilled,
} from "@tabler/icons-react";
import { useLanguage } from "../../stores/LanguageStore";
import { useState } from "react";
import { transformFilterToServer } from "../../functions/utilFunctions";

const TableFilterExport = ({
  handleExport,
  id,
  name,
  onFinish,
  selectedIds,
  filter,
  filterResultNumber,
  total,
  withSelection = true,
  withExcludedIds = true,
}) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(null);
  const [fileType, setFileType] = useState("xlsx");

  const main = (type, handleClose) => {
    setLoading(type);
    return handleExport({
      id: id,
      ids:
        type === "selection_inverse"
          ? []
          : type === "selection"
          ? selectedIds
          : [],
      excluded_ids: type === "selection_inverse" ? selectedIds : [],
      name: `${name}.${fileType}`,
      filter:
        type === "selection"
          ? { mode: "or", values: [] }
          : filter?.items?.length > 0 && type !== "selection"
          ? transformFilterToServer(filter)
          : { mode: "or", values: [] },
      export_type: type,
      file_type: fileType,
    })
      .then((res) => {
        onFinish?.(type);
        handleClose();
      })
      .finally(() => {
        setLoading(null);
      });
  };

  return (
    <PopOver
      opener={(handleOpen, ref) => (
        <Tooltip label={lang.contact_lists.export_contacts.tooltip}>
          <Button
            className="toolbar-action-button"
            variant="light"
            color="tertiary.8"
            ref={ref}
            rightSection={<IconChevronDown size={16} />}
            onClick={handleOpen}
          >
            <IconCircleArrowDownFilled size={18} />
          </Button>
        </Tooltip>
      )}
      popoverChildren={(handleClose) => (
        <Stack gap={"xs"}>
          <SegmentedControl
            data={[
              {
                label: ".XLSX",
                value: "xlsx",
              },
              {
                label: ".CSV",
                value: "csv",
              },
              {
                label: ".JSON",
                value: "json",
              },
            ]}
            value={fileType}
            onChange={setFileType}
          />
          <Tooltip
            label={lang.contact_lists.delete_contacts.no_filter}
            disabled={filter?.items?.length > 0}
          >
            <Button
              loading={loading === "filter"}
              onClick={() => main("filter", handleClose)}
              disabled={!filter || filter?.items?.length < 1}
              variant="subtle"
              size="sm"
              color="tertiary.8"
            >
              {lang.contact_lists.export_contacts.export_filter}{" "}
              {filter?.items?.length > 0 ? `( ${filterResultNumber} )` : ""}
            </Button>
          </Tooltip>
          {withSelection && (
            <Tooltip
              label={lang.contact_lists.delete_contacts.no_selection}
              disabled={selectedIds?.length > 0}
            >
              <Button
                loading={loading === "selection"}
                onClick={() => main("selection", handleClose)}
                disabled={!selectedIds || selectedIds?.length < 1}
                variant="subtle"
                size="sm"
                color="tertiary.8"
              >
                {lang.contact_lists.export_contacts.export_selection}{" "}
                {selectedIds?.length > 0 ? `( ${selectedIds.length} )` : ""}
              </Button>
            </Tooltip>
          )}
          {withExcludedIds && (
            <Tooltip
              label={lang.contact_lists.delete_contacts.no_selection}
              disabled={selectedIds?.length > 0}
            >
              <Button
                loading={loading === "selection_inverse"}
                onClick={() => main("selection_inverse", handleClose)}
                disabled={!selectedIds || selectedIds?.length < 1}
                variant="subtle"
                size="sm"
                color="tertiary.8"
              >
                {lang.contact_lists.export_contacts.export_all_but_selection}{" "}
                {selectedIds?.length > 0
                  ? `( ${filterResultNumber - selectedIds.length} )`
                  : ""}
              </Button>
            </Tooltip>
          )}
          <Tooltip label={lang.contact_lists.export_contacts.export_all}>
            <Button
              loading={loading === "all"}
              onClick={() => main("all", handleClose)}
              variant="subtle"
              size="sm"
              color="tertiary.8"
            >
              {lang.contact_lists.export_contacts.export_all}{" "}
              {total ? `(${total})` : ""}
            </Button>
          </Tooltip>
        </Stack>
      )}
    />
  );
};

export default TableFilterExport;
