import { useMemo } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { notifications } from "@mantine/notifications";
import { Center, Divider, Group, Paper, Skeleton, Stack } from "@mantine/core";
import AvatarEditor from "../../account/components/AvatarEditor";
import { getFieldIcon } from "../../community/util";
import AdminInput from "./adminInput/AdminInput";
import UnlockAccount from "./UnlockAccount";
import ActivateAccount from "./ActivateAccount";
import DisableAccount from "../DisableAccount";
import DeleteAccount from "../DeleteAccount";

const AdminPanelCenter = ({ setCurrent, profile, loading, setProfile, me }) => {
  const lang = useLanguage((s) => s.language);

  const userSettings = useMemo(
    () => [
      {
        label: lang.profile.subscription,
        value: "subscriptions",
      },
      {
        label: lang.profile.last_name,
        value: "last_name",
      },
      {
        label: lang.profile.first_name,
        value: "first_name",
      },
      {
        label: lang.profile.mail,
        value: "email",
      },
      {
        label: lang.profile.password,
        value: "password",
      },
      {
        label: lang.profile.civility,
        value: "sex",
      },
      {
        label: lang.profile.phone,
        value: "phone",
      },
      {
        label: lang.profile.mobile_phone,
        value: "mobile_phone",
      },
      {
        label: lang.profile.city,
        value: "city",
      },
      {
        label: lang.profile.address,
        value: "address",
      },
      {
        label: lang.profile.postal_code,
        value: "postal_code",
      },
      {
        label: lang.profile.country,
        value: "country",
      },
      {
        label: lang.profile.language,
        value: "language",
      },
    ],
    [lang]
  );

  const onAccountDeleted = () => {
    setCurrent({ ...me, toDelete: profile });
  };
  const onAccountDisabled = () => {
    setCurrent((prev) => ({ ...prev, activated: false }));
  };
  const onAccountActivated = () => {
    setCurrent((prev) => ({ ...prev, activated: true }));
  };
  const onAccountUnlocked = () => {
    notifications.show({ message: "Compte debloqué", color: "green.8" });
  };

  return (
    <Paper w={"20%"} component={Stack} gap={0} h={"100%"} p={"md"} py={"xs"}>
      <Group pb={6}>
        {loading ? (
          <Skeleton height={50} radius={"sm"} />
        ) : (
          <AvatarEditor
            profile={profile}
            isOther
            onSuccess={() => setCurrent((prev) => ({ ...prev, changed: true }))}
            withCreatedOn
          />
        )}
      </Group>
      <Divider />

      <Stack gap={0} flex={"1 1 auto"} h={0} style={{ overflow: "auto" }}>
        {loading ? (
          <Center pos={"relative"} h={"100%"} w={"100%"}>
            <Skeleton height={"100%"} width={"100%"} />
          </Center>
        ) : (
          userSettings.map((setting) => (
            <AdminInput
              key={setting.value}
              profile={profile}
              setting={setting}
              icon={getFieldIcon(setting.value)}
              setProfile={setProfile}
            />
          ))
        )}
      </Stack>

      <Group wrap="nowrap" pt={6}>
        <UnlockAccount profile={profile} onSuccess={onAccountUnlocked} />
        <ActivateAccount profile={profile} onSuccess={onAccountActivated} />
        <DisableAccount
          profile={profile}
          onSuccess={onAccountDisabled}
          isOther
        />
        <DeleteAccount profile={profile} onSuccess={onAccountDeleted} isOther />
      </Group>
    </Paper>
  );
};

export default AdminPanelCenter;
