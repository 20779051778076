import { Popover, Stack } from "@mui/material";
import { useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import {
  useLeadsContainerStore,
  useLeadsSheetStore,
} from "../../../../../stores/LeadsStore";
import { markReply, updateProspect } from "../../../../../api/PRMAPI";
import { IconThumbUp } from "@tabler/icons-react";
import { ActionIcon, Button, Tooltip } from "@mantine/core";
import { IconThumbUpFilled } from "@tabler/icons-react";
import { IconThumbDownFilled } from "@tabler/icons-react";

const PositiveChanger = ({
  containerId,
  prospectId,
  itemId,
  is_positive,
  isInDetail,
  isAnswer,
  reply_type,
  actualProspect,
  setActualProspect,
  container,
}) => {
  const lang = useLanguage((s) => s.language);
  const currentIndex = useLeadsSheetStore((s) => s.index);
  const current = useLeadsSheetStore((s) => s.current);
  const setCurrent = useLeadsSheetStore((s) => s.setCurrent);
  const containers = useLeadsContainerStore((s) => s.containers);
  const updateContainerItem = useLeadsContainerStore(
    (s) => s.updateContainerItem
  );
  const updateContainer = useLeadsContainerStore((s) => s.updateContainer);

  const [anchor, setAnchor] = useState(null);
  const [opened, setOpened] = useState(false);

  const handleOpen = (e) => {
    setAnchor(e.target);
    setOpened(true);
  };
  const handleClose = () => {
    setOpened(false);
  };

  const [loading, setLoading] = useState(false);
  const handlePositive = (newValue) => {
    setLoading(
      newValue === null
        ? "neutral"
        : newValue === true
        ? "positive"
        : "negative"
    );

    const main = isAnswer ? markReply : updateProspect;
    main({
      id: itemId,
      is_positive: newValue,
      contact_id: prospectId,
      reply_id: itemId,
      history_type_reply: reply_type,
    })
      .then(() => {
        if (isInDetail) {
          if (currentIndex !== null) {
            updateContainerItem(containerId, itemId, { is_positive: newValue });
          }
          setCurrent({ ...current, is_positive: newValue });
        }
        if (isAnswer) {
          let newProspect = { ...actualProspect };
          let i = newProspect.history.findIndex((x) => x.id === itemId);
          newProspect.history[i] = {
            ...newProspect.history[i],
            is_positive_reply: newValue,
          };
          updateContainerItem(containerId, itemId, {
            history: newProspect.history,
          });
          setActualProspect({
            ...newProspect,
            history: newProspect.history,
          });

          let newContainer = containers.find((x) => {
            if (newValue === true) {
              return x?.type_default_status === "answerer_positive";
            }
            x?.type_default_status === "answerer_negative";
          });
          if (!current?.custom_status && newValue !== null && newContainer) {
            // Delete the item from its container
            let newItemsOnCurrentContainer = container.items.filter(
              (item) => item.id !== current.id
            );
            updateContainer(container.id, {
              items: newItemsOnCurrentContainer,
              total: container.total - 1,
              needCacheReset: true,
            });

            // Add the item to its next container
            let newItemsFuture = [...newContainer.items];
            let newItem = { ...current, custom_status: newContainer.id };
            newItemsFuture.unshift({
              ...newItem,
              status_changed_date: new Date(),
            });
            updateContainer(newContainer.id, {
              total: newContainer.total + 1,
              items: newItemsFuture,
              needCacheReset: true,
            });
          }
        } else {
          updateContainerItem(containerId, itemId, { is_positive: newValue });
        }
        handleClose();
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Tooltip
        label={
          is_positive === null
            ? lang.prm.neutral
            : is_positive === true
            ? lang.prm.positive
            : lang.prm.negative
        }
        position={isInDetail ? "bottom" : "top"}
      >
        <ActionIcon
          size={isAnswer ? "md" : "xs"}
          variant="subtle"
          color={
            is_positive === null ? "gray" : is_positive ? "green.8" : "red"
          }
          onClick={handleOpen}
          mt={isAnswer ? 0 : -4}
          ml={"auto"}
        >
          {is_positive === null ? (
            <IconThumbUp size={isAnswer ? 18 : 14} />
          ) : is_positive === false ? (
            <IconThumbDownFilled size={isAnswer ? 18 : 14} />
          ) : (
            <IconThumbUpFilled size={isAnswer ? 18 : 14} />
          )}
        </ActionIcon>
      </Tooltip>

      {opened && (
        <Popover
          open={opened}
          anchorEl={anchor}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          sx={{ mt: 0.25 }}
        >
          <Stack p={0.5} spacing={0.5}>
            {!is_positive && (
              <Button
                onClick={() => handlePositive(true)}
                color="green.8"
                size="xs"
                variant="light"
                leftSection={<IconThumbUpFilled size={16} />}
                loading={loading === "positive"}
              >
                {lang.prm.positive}
              </Button>
            )}
            {(is_positive || is_positive === null) && (
              <Button
                onClick={() => handlePositive(false)}
                color="red"
                size="xs"
                variant="light"
                leftSection={<IconThumbDownFilled size={16} />}
                loading={loading === "negative"}
              >
                {lang.prm.negative}
              </Button>
            )}
            {is_positive !== null && (
              <Button
                onClick={() => handlePositive(null)}
                color="gray"
                size="xs"
                variant="light"
                loading={loading === "neutral"}
                leftSection={<IconThumbUp size={16} />}
              >
                {lang.prm.neutral}
              </Button>
            )}
          </Stack>
        </Popover>
      )}
    </>
  );
};

export default PositiveChanger;
