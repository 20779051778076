import Selector from "../../Selectors/Selector";
import { Box } from "@mantine/core";

const FilterField = ({
  field,
  fields,
  handleFieldChange,
  getFieldOptionName,
}) => {
  return (
    <Box w={150}>
      <Selector
        options={fields}
        value={field?.id}
        onChange={handleFieldChange}
        size={"sm"}
        getOptionName={getFieldOptionName}
        hasGroup
        required
        unclearable 
        withinPortal={true}
        offset={2}
      />
    </Box>
  );
};

export default FilterField;
