import { useState } from "react";
import { createOutlook } from "../../../../../api/EmailAPI";
import { defaultSMTPConfigs } from "../../../tools/emails/components/defaultConfigs";
import { useParams } from "react-router-dom";
import { getTypeIcon } from "../../../workflows/CreateCampaign/flow/flowSettings";
import { Button, Tooltip } from "@mantine/core";
import { useLanguage } from "../../../../../stores/LanguageStore";
import EmailSendingModal from "./EmailSendingModal";
import { modals } from "@mantine/modals";

const CreateOutlook = ({
  buttonProps,
  iconSize,
  onCreated,
  setTableLoading,
}) => {
  const lang = useLanguage((s) => s.language);
  const { poolId } = useParams();
  const [loading, setLoading] = useState(false);

  const handleGmail = () => {
    setLoading(true);
    createOutlook({
      data: {
        ...defaultSMTPConfigs,
        pools_ids: poolId ? [parseInt(poolId)] : undefined,
      },
    })
      .then((response) => {
        if (response.data?.email_account?.redirect_url) {
          window.open(response.data?.email_account?.redirect_url, "_blank");
        }
        setLoading(false);
        onCreated?.();
        modals.open({
          modalId: "gmail",
          size: "xl",
          title: lang.senders.emails.google_modal_title,
          children: (
            <EmailSendingModal
              setTableLoading={setTableLoading}
              id={response.data?.email_account?.id}
              type={"Outlook"}
            />
          ),
        });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Tooltip label={lang.senders.emails.outlook_tooltip} position="left">
      <Button
        leftSection={getTypeIcon(
          "Outlook",
          false,
          iconSize,
          { variant: "subtle" },
          true
        )}
        onClick={handleGmail}
        variant="subtle"
        w={"100%"}
        justify="start"
        c={"var(--mantine-color-gray-7)"}
        // size={inMenu ? "sm" : "md"}
        // fw={inMenu ? 600 : 500}
        loading={loading}
        {...buttonProps}
      >
        Outlook
      </Button>
    </Tooltip>
  );
};

export default CreateOutlook;
