import { Paper } from "@mantine/core";
import EditNoteContent from "./EditNoteContent";
import AddNoteContent from "./AddNoteContent";

const ActivityNote = ({
  item,
  isEdit,
  setIsEdit,
  outsideCurrent,
  outsideContainer,
}) => {
  return (
    <Paper
      withBorder
      p={0}
      fz={"sm"}
      mb={"xl"}
      bg={"#ffaa0012"}
      mt={10}
      w={"90%"}
    >
      {isEdit ? (
        <EditNoteContent
          handleClose={() => setIsEdit(false)}
          item={item}
          outsideCurrent={outsideCurrent}
          container={outsideContainer}
        />
      ) : item?.isCreate ? (
        <AddNoteContent />
      ) : (
        <iframe
          onLoad={(e) => {
            let contentHeight =
              e.target.contentWindow.document.body.scrollHeight + 16;
            e.target.style.height = contentHeight + "px";
          }}
          title={`note-${item?.id}`}
          srcDoc={`
        <head>
          <style>
            p { margin: 0 }
          </style>
        </head>
        <div style="font-family: sans-serif; font-size: 13px;">${item.note}</div>
        `}
          style={{
            width: "100%",
            border: "none",
          }}
          // sandbox=""
        />
      )}
    </Paper>
  );
};

export default ActivityNote;
