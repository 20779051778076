import { ActionIcon, Tooltip } from "@mantine/core";
import { useLanguage } from "../../stores/LanguageStore";
import { IconX } from "@tabler/icons-react";
import { useProfileStore, useSessionStore } from "../../stores/UserStore";
import { useCallback } from "react";
import { useUIStore } from "../../stores/UIStore";

const SwitchCancel = ({ navbarClosed }) => {
  const setWindowLoading = useUIStore((s) => s.setWindowLoading);
  const user_switch = useSessionStore((state) => state.user_switch);
  const setSwitch = useSessionStore((state) => state.setUserSwitch);
  const lang = useLanguage((s) => s.language);
  const handleReset = useCallback(() => {
    setWindowLoading(true);
    setSwitch(null);
    useProfileStore.getState().setUnswitchedProfile(null);

    window.location.reload();
  }, [setSwitch, setWindowLoading]);

  if (!user_switch) {
    return null;
  }
  return (
    <Tooltip
      label={lang.footer.return_to_main_account_tooltip}
      position="bottom"
    >
      <ActionIcon
        h={60}
        w={42}
        // variant="light"
        color="red.6"
        px={0}
        onClick={handleReset}
        radius={0}
      >
        <IconX size={19} />
      </ActionIcon>
    </Tooltip>
  );
};

export default SwitchCancel;
