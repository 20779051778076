import { useCallback, useEffect, useState } from "react";
import { useSessionStore } from "../../stores/UserStore";
import { getFirstPageUsers, searchUser } from "../../api/UserAPI";
import {
  Box,
  Combobox,
  Highlight,
  ScrollArea,
  TextInput,
  useCombobox,
} from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { IconSearch } from "@tabler/icons-react";
import { useLanguage } from "../../stores/LanguageStore";

const UserSelectorSwitch = ({ isFooter, onChange, ...props }) => {
  const lang = useLanguage((s) => s.language);
  const [searching, setSearching] = useState(false);
  const [userOptions, setUserOptions] = useState([]);
  const [users, setUsers] = useState(userOptions);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [debounced] = useDebouncedValue(value, 600);

  const handleLoad = useCallback(() => {
    setLoading(true);
    const isSwitched = useSessionStore.getState().user_switch;
    let o = useSessionStore.getState().usersOptions;

    if (isFooter && isSwitched) {
      let newOptions = o.map((user) => ({
        ...user,
        id: user.id,
        name: user.first_name + " " + user.last_name,
      }));

      setUserOptions(newOptions);
      setUsers(newOptions);
      return setLoading(false);
    }

    getFirstPageUsers({
      isMain: true,
      sort: {
        field_name: "id",
        sort_direction: "desc",
      },
    })
      .then((res) => {
        if (res.data.state) {
          let newOptions = res.data.results.map((user) => ({
            ...user,
            id: user.id,
            name: user.first_name + " " + user.last_name,
          }));

          setUserOptions(newOptions);
          setUsers(newOptions);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [isFooter]);

  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
    },
  });

  useEffect(() => {
    handleLoad();
  }, []);

  useEffect(() => {
    if (debounced.length > 1) {
      setSearching(true);
      searchUser({ query: debounced + "", isMain: true }).then((res) => {
        let actualValue = document.getElementById("switch-input").value;
        if (actualValue === debounced) {
          let newOptions = res.data.results.map((user) => ({
            ...user,
            id: user.id,
            name: user.first_name + " " + user.last_name,
          }));
          setUsers(newOptions);
          setSearching(false);
        }
      });
    }

    if (value.length < 2) {
      setUsers(userOptions);
      setSearching(false);
    }
  }, [debounced, isFooter, userOptions, value]);

  useEffect(() => {
    combobox.selectFirstOption();
  }, [users]);

  const options = users.map((item) => (
    <Combobox.Option value={item} key={item.id} disabled={!item?.activated}>
      <Highlight highlight={debounced} size="sm">
        {`${item.name} #${item.id}`}
      </Highlight>
    </Combobox.Option>
  ));

  return (
    <Combobox
      onOptionSubmit={(optionValue) => {
        onChange(optionValue);
        combobox.closeDropdown();
      }}
      store={combobox}
      width={250}
      withArrow
      shadow="md"
      withinPortal={false}
      position="top"
      offset={50}
      {...props}
    >
      <Combobox.Target>
        <TextInput
          id="switch-input"
          label={lang.header.switch}
          placeholder={lang.header.search_user}
          value={value}
          onChange={(event) => {
            combobox.openDropdown();
            setValue(event.currentTarget.value);
            if (event.currentTarget.value.length > 0) {
              setSearching(true);
            }
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => combobox.closeDropdown()}
          onKeyDown={(e) => {
            if (e.key === "Enter" && users.length > 0) {
              onChange(null, users[0]);
            }
          }}
          autoFocus
          rightSection={<IconSearch size={18} />}
        />
      </Combobox.Target>

      <Box mah={300} mt={"xs"} w={250}>
        <Combobox.Options>
          <ScrollArea.Autosize mah={300} type="scroll">
            {loading ? (
              <Combobox.Empty>Loading...</Combobox.Empty>
            ) : searching ? (
              <Combobox.Empty>Searching...</Combobox.Empty>
            ) : options.length === 0 ? (
              <Combobox.Empty>Nothing found</Combobox.Empty>
            ) : (
              options
            )}
          </ScrollArea.Autosize>
        </Combobox.Options>
      </Box>
    </Combobox>
  );
};

export default UserSelectorSwitch;
