import { useEffect, useMemo, useState } from "react";
import {
  Combobox,
  Group,
  Highlight,
  InputBase,
  Loader,
  useCombobox,
} from "@mantine/core";
import { useLanguage } from "../../stores/LanguageStore";

export function _getOptionName(option) {
  return `${option.name} #${option.id}`;
}

// Value Output = { id: 1, option_name: "name", ... }
// Value Input = 32 (id)

const Selector = ({
  id,
  label,
  placeholder,
  size,
  required,
  value,
  onChange,
  leftSection,
  disabled,
  getOptionName = _getOptionName,
  getError,
  options,
  hasGroup = true,
  rightOptionSection,
  loading,
  onDropdownOpen,
  getValidOption,
  unclearable,
  withinPortal,
  shadow = "sm",
  offset,
  description,
  other,
}) => {
  const lang = useLanguage((s) => s.language);
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => {
      combobox.updateSelectedOptionIndex("active");
      onDropdownOpen?.();
    },
  });

  const [search, setSearch] = useState("");

  const allOptions = useMemo(() => {
    if (hasGroup) {
      return options.reduce((acc, group) => [...acc, ...group.options], []);
    }
    return options;
  }, [hasGroup, options]);

  const shouldFilterOptions = allOptions.every(
    (item) => getOptionName(item) !== search
  );
  const filteredGroups = options.map((group) => {
    if (hasGroup) {
      const filteredOptions = shouldFilterOptions
        ? group.options.filter((item) =>
            item.option_name
              .toLowerCase()
              .trim()
              .normalize("NFD")
              .replace(/\p{Diacritic}/gu, "")
              .includes(search.toLowerCase().trim())
          )
        : group.options;

      return { ...group, options: filteredOptions };
    }
    const filteredOptions = shouldFilterOptions
      ? options.filter((item) =>
          item.option_name
            .toLowerCase()
            .trim()
            .normalize("NFD")
            .replace(/\p{Diacritic}/gu, "")
            .includes(search.toLowerCase().trim())
        )
      : options;

    return { ...group, options: filteredOptions };
  });

  const totalOptions = filteredGroups.reduce(
    (acc, group) => acc + group.options.length,
    0
  );

  const groups = filteredGroups.map((group) => {
    const options = group.options.map((item) => (
      <Combobox.Option
        value={item}
        key={item.id}
        bg={value === item.id ? "var(--mantine-primary-color-0)" : undefined}
        disabled={item.disabled}
      >
        <Group wrap="nowrap">
          <Highlight
            highlight={shouldFilterOptions ? search : null}
            size="sm"
            mr={"auto"}
            fz={"sm"}
            c={
              getValidOption
                ? !getValidOption(item)
                  ? "red"
                  : undefined
                : undefined
            }
          >
            {item.option_name}
          </Highlight>
          {rightOptionSection?.(item)}
        </Group>
      </Combobox.Option>
    ));

    if (hasGroup) {
      return (
        <Combobox.Group label={group.label} key={group.label}>
          {options}
        </Combobox.Group>
      );
    }
    return options;
  });

  const onClear = () => {
    onChange(null);
    setSearch("");
  };

  useEffect(() => {
    const v = allOptions.find((x) => x.id === value);

    setSearch(v ? getOptionName(v) : "");
  }, [value, allOptions, getOptionName]);

  return (
    <Combobox
      store={combobox}
      withinPortal={withinPortal}
      shadow={shadow}
      onOptionSubmit={(newValue) => {
        onChange(newValue);
        setSearch(getOptionName(newValue));
        combobox.closeDropdown();
      }}
      keepMounted={false}
      offset={offset}
      zIndex={1400}
    >
      <Combobox.Target>
        <InputBase
          id={id}
          label={label}
          required={required}
          placeholder={placeholder}
          description={description}
          value={search}
          onChange={(event) => {
            combobox.openDropdown();
            combobox.updateSelectedOptionIndex();
            setSearch(event.currentTarget.value);
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={() => combobox.openDropdown()}
          onBlur={() => {
            combobox.closeDropdown();
            const v = allOptions.find((x) => x.id === value);
            setSearch(v ? getOptionName(v) : "");
          }}
          error={getError}
          disabled={disabled}
          leftSection={leftSection}
          rightSection={
            loading ? (
              <Loader size={"xs"} type="dots" />
            ) : value && !unclearable ? (
              <Combobox.ClearButton onClear={onClear} />
            ) : (
              <Combobox.Chevron onClick={combobox.openDropdown} />
            )
          }
          size={size || "md"}
          w={"100%"}
          autoComplete="off"
          {...other}
        />
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options mah={200} style={{ overflowY: "auto" }}>
          {totalOptions > 0 ? (
            groups
          ) : (
            <Combobox.Empty>
              {loading ? lang.global.loading : lang.global.no_element_found}
            </Combobox.Empty>
          )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};

export default Selector;
