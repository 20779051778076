import {
  ActionIcon,
  Button,
  Group,
  Popover,
  Text,
  Tooltip,
} from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import { useState } from "react";
import { deleteNote } from "../../../../../../../../api/PRMAPI";
import { useLeadsSheetStore } from "../../../../../../../../stores/LeadsStore";
import { useLanguage } from "../../../../../../../../stores/LanguageStore";
import { notifications } from "@mantine/notifications";
import BackButton from "../../../../../../../../components/View/BackButton";

const DeleteNote = ({ id }) => {
  const lang = useLanguage((s) => s.language);
  const current = useLeadsSheetStore((s) => s.current);
  const setCurrent = useLeadsSheetStore((s) => s.setCurrent);
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleDelete = () => {
    setLoading(true);
    deleteNote({ prospect_id: current.id, note_id: id })
      .then(() => {
        notifications.show({
          message: lang.prm.details.notes.deleted,
          color: "tertiary.8",
        });
        let newHistory = current.history.filter((x) => x.id !== id);
        setCurrent({ ...current, history: newHistory });
        setOpened(false);
      })
      .finally(() => setLoading(false));
  };
  return (
    <Popover
      withArrow
      position="right"
      zIndex={1200}
      opened={opened}
      onChange={setOpened}
    >
      <Popover.Target>
        <Tooltip label={lang.prm.details.history.action_menu_delete}>
          <ActionIcon
            color="red"
            variant="light"
            className="on-hover-opacity"
            onClick={() => setOpened(true)}
          >
            <IconTrash size={18} />
          </ActionIcon>
        </Tooltip>
      </Popover.Target>

      <Popover.Dropdown>
        <Text mb={"sm"}>{lang.prm.details.notes.delete_text}</Text>
        <Group justify="end">
          <BackButton onClick={() => setOpened(false)} />
          <Button
            variant="light"
            color="red"
            onClick={handleDelete}
            loading={loading}
          >
            {lang.global.delete}
          </Button>
        </Group>
      </Popover.Dropdown>
    </Popover>
  );
};

export default DeleteNote;
