import { Group, Loader } from "@mantine/core";
import { memo } from "react";
import { areEqual } from "react-window";
import LeadItem from "../../prospect/LeadItem";

const Row = memo(({ data, index, style }) => {
  const { container } = data;
  const current = container.items[index];

  return (
    <div style={{ ...style, paddingLeft: 8, paddingRight: 8, marginTop: 8 }}>
      {current?.id ? (
        <LeadItem current={current} container={container} index={index} />
      ) : (
        <Group w={"100%"} justify="center" h={"100%"}>
          <Loader type="dots" />
        </Group>
      )}
    </div>
  );
}, areEqual);

Row.displayName = "Row";

export default Row;
