import Path from "../../../layouts/Path";
import { Button, Group, Stack, TextInput, Title } from "@mantine/core";
import { useLanguage } from "../../../stores/LanguageStore";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createApiKey } from "../../../api/ConfigsApi";
import { notifications } from "@mantine/notifications";
import BackButton from "../../../components/View/BackButton";
import PageTitle from "../../../components/View/PageTitle";

const CreateApiKeyPage = () => {
  const lang = useLanguage((s) => s.language);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    let fd = new FormData(e.target);
    let data = Object.fromEntries(fd);

    setLoading(true);
    createApiKey(data?.name)
      .then((response) => {
        setTimeout(() => {
          notifications.show({
            message: lang.api_keys.row_highlight.created,
            color: "tertiary.8",
          });
        }, 300);
        navigate(`/api/${response.data.api_key_id}`);
      })
      .finally(() => setLoading(false));
  };
  return (
    <>
      <Group
        className="layout-block top"
        w={"100%"}
        gap={0}
        justify="space-between"
      >
        <PageTitle title={lang.api_keys.browser_tab_title} />
      </Group>
      <Stack
        className="layout-block"
        w={"40%"}
        mx={"auto"}
        gap={"xs"}
        component={"form"}
        onSubmit={handleSubmit}
      >
        <Title order={5}>{lang.api_keys.create.dialog.title}</Title>
        <TextInput
          name="name"
          label={lang.api_keys.create.dialog.name}
          placeholder={lang.api_keys.create.dialog.name}
          autoFocus
          required
        />

        <Group justify="flex-end">
          <BackButton />
          <Button type="submit" loading={loading}>
            {lang.api_keys.create.dialog.submit_button}
          </Button>
        </Group>
      </Stack>
    </>
  );
};

export default CreateApiKeyPage;
