import React, { useMemo } from "react";
import { parseProspectDatafields } from "../../../leadsTracking/utils";
import {
  Anchor,
  Group,
  Paper,
  Stack,
  Text,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import { IconMail, IconPhone } from "@tabler/icons-react";
import { IconLinkedin } from "../../../../../components/Icons/IconLinkedin";

const DatabaseContactItem = ({ item }) => {
  const data = useMemo(() => {
    let properties = parseProspectDatafields(item)?.properties;

    return {
      first_name: properties.find(
        (x) => x.identifier_placeholder === "%first_name%"
      )?.value,
      last_name: properties.find(
        (x) => x.identifier_placeholder === "%last_name%"
      )?.value,
      email: properties.find((x) => x.identifier_placeholder === "%email%")
        ?.value,
      phone:
        properties.find((x) => x.identifier_placeholder === "%phone%")?.value ||
        properties.find((x) => x.identifier_placeholder === "%mobile_phone%")
          ?.value,
      job_title: properties.find(
        (x) => x.identifier_placeholder === "%job_title%"
      )?.value,
      company: properties.find((x) => x.identifier_placeholder === "%company%")
        ?.value,
      country: properties.find((x) => x.identifier_placeholder === "%contact_location%")
        ?.value,
      linkedin_url: properties.find(
        (x) => x.identifier_placeholder === "%linkedin_url%"
      )?.value,
    };
  }, [item]);

  return (
    <Paper component={Stack} p={"sm"} withBorder gap={4} radius={"md"}>
      <Group gap={"xs"} wrap="nowrap">
        {data?.first_name ? (
          <Anchor
            target="_blank"
            href={data?.linkedin_url || null}
            fw={600}
            truncate="end"
          >
            {data?.first_name || " - "} {data?.last_name || " - "}
          </Anchor>
        ) : (
          <Anchor
            target="_blank"
            href={data?.linkedin_url || null}
            fw={600}
            truncate="end"
          >
            {data?.company || " - "}
          </Anchor>
        )}

        {data?.linkedin_url && (
          <Tooltip label={data.linkedin_url} withArrow>
            <ThemeIcon variant="subtle" size={"xs"}>
              <IconLinkedin />
            </ThemeIcon>
          </Tooltip>
        )}
        {data?.email && (
          <Tooltip label={data.email} withArrow>
            <ThemeIcon variant="subtle" size={"xs"}>
              <IconMail />
            </ThemeIcon>
          </Tooltip>
        )}
        {data?.phone && (
          <Tooltip label={data.phone} withArrow>
            <ThemeIcon variant="subtle" size={"xs"}>
              <IconPhone />
            </ThemeIcon>
          </Tooltip>
        )}
      </Group>
      {data?.first_name && (
        <>
          <Text size="sm" truncate="end">
            {data?.job_title || " - "}
          </Text>

          <Text size="sm" fw={700} truncate="end">
            {data?.company || " - "}
          </Text>
        </>
      )}
      <Text size="sm" fw={400} truncate="end">
        {data?.country || " - "}
      </Text>
    </Paper>
  );
};

export default DatabaseContactItem;
