import { Button, Group, Stack, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { useEffect } from "react";
import { useBlocker } from "react-router-dom";
import { useLanguage } from "../../stores/LanguageStore";
import BackButton from "./BackButton";

const Content = ({ buttonId, blocker }) => {
  const lang = useLanguage((s) => s.language);

  const handleClose = () => {
    blocker.proceed();
    modals.close("blocker-modal");
  };

  const handleSave = () => {
    blocker.reset();
    modals.close("blocker-modal");

    if (buttonId) {
      let button = document.getElementById(buttonId);

      setTimeout(() => {
        button.click();
      }, 50);
    }
  };

  return (
    <Stack>
      <Text>{lang.components.navigation_blocker.text}</Text>

      <Group justify="end" mt={"md"}>
        <BackButton onClick={handleClose} label={lang.global.ignore} />
        {buttonId ? (
          <Button onClick={handleSave}>{lang.global.save}</Button>
        ) : (
          <Button onClick={handleSave}>
            {lang.components.navigation_blocker.rest}
          </Button>
        )}
      </Group>
    </Stack>
  );
};

const useNavigationBlocker = (shouldBlock, buttonId) => {
  const lang = useLanguage((s) => s.language);
  const blocker = useBlocker(shouldBlock);

  useEffect(() => {
    if (blocker.state === "blocked") {
      modals.open({
        modalId: "blocker-modal",
        title: <Text>{lang.components.navigation_blocker.title}</Text>,
        children: <Content buttonId={buttonId} blocker={blocker} />,
      });
    } else {
      blocker?.reset?.();
    }
  }, [blocker, buttonId, lang]);
};

export default useNavigationBlocker;
