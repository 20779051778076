import { Outlet } from "react-router-dom";
import { useLanguage } from "../../../stores/LanguageStore";

export function getStatsPathName(params) {
  const lang = useLanguage.getState().language;
  // const labels = {
  //   contacted: lang.statistics.contacts_type.contacted,
  //   sent_today: lang.statistics.contacts_type.sent_today,
  //   opener: lang.statistics.contacts_type.opener,
  //   clicker: lang.statistics.contacts_type.clicker,
  //   answerer: lang.statistics.contacts_type.answerer,
  //   unsubscriber: lang.statistics.contacts_type.unsubscriber,
  //   bounced: lang.statistics.contacts_type.bounced,
  //   to_contact: lang.statistics.contacts_type.to_contact,
  //   blacklisted: lang.statistics.contacts_type.blacklisted,
  //   excluded_previous_programmation:
  //     lang.statistics.contacts_type.excluded_previous_programmation,
  //   excluded_workflow: lang.statistics.contacts_type.excluded_workflow,
  // };
  if (params.stepId) {
    return `${lang.routes.stats.contacts_of} #${params.campaign} ${lang.routes.stats.of_workflow} #${params.workflow}, ${lang.routes.stats.step} #${params.stepId}`;
  }
  return `${lang.routes.stats.contacts_of} #${params.campaign} ${lang.routes.stats.of_workflow} #${params.workflow}`;
}

const Stats = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default Stats;
