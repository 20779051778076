import { useCallback, useMemo, useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { ActionIcon, Anchor, Flex, Modal, Text, Tooltip } from "@mantine/core";
import MainTable from "../../../../../layouts/Table/MainTable";
import { IconUsers } from "@tabler/icons-react";
import { useParams } from "react-router-dom";
import { findCampaignStats } from "../../../../../api/StatsAPI";
import { useListingLoader } from "../../../../../components/hooks/ListingHooks";
import UrlContacts from "./UrlContacts";

const ClickerLinks = () => {
  const { campaign, workflow, stepId, type, stepType } = useParams();
  const lang = useLanguage((s) => s.language);

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [url, setUrl] = useState(null);
  const handleOpen = (val) => {
    setUrl(val);
  };

  const columns = useMemo(
    () => [
      {
        field: "url",
        headerName: lang.workflow_preview.links_columns.url,
        minWidth: 50,
        flex: 1,
        renderCell: ({ value }) => (
          <Anchor href={value} target="_blank" truncate>
            {value}
          </Anchor>
        ),
      },
      {
        field: "clicks",
        headerName: lang.workflow_preview.links_columns.click,
        width: 120,
      },
    ],
    [lang]
  );

  const actionsCell = useCallback(({ row }) => {
    return (
      <Tooltip label="Voir les autres contacts qui ont cliqué sur ce lien">
        <ActionIcon variant="subtle" onClick={() => handleOpen(row.url)}>
          <IconUsers size={18} />
        </ActionIcon>
      </Tooltip>
    );
  }, []);

  useListingLoader({
    loading,
    setLoading,
    listGetter: () => findCampaignStats(parseInt(campaign)),
    responseField: "programmation",
    setRows,
    getRows: (result) => {
      let step_id = parseInt(stepId);
      let step = result?.steps?.find((x) => x.id === step_id);
      let p_links = step?.links;

      let allLinks = [];
      p_links.forEach((x) => {
        if (!allLinks.includes(x.url)) {
          allLinks.push(x.url);
        }
      });

      return allLinks?.map((link) => ({
        id: link,
        url: link,
        clicks: p_links?.find((x) => x.url === link)?.clicks || 0,
      }));
    },
  });

  return (
    <>
      <Flex
        flex={"1 1 auto"}
        w={"25%"}
        ml={32}
        style={{ borderLeft: "1px solid #00000021" }}
      >
        <MainTable
          columns={columns}
          rows={rows}
          loading={loading}
          setLoading={setLoading}
          actionsCell={actionsCell}
          actionsCellWidth={50}
          id={"links_table"}
          disableVisibleColumns
          disableSelections
          disableGotoPage
          disablePerPage
          disableFilter
          withBorderTop
        />
      </Flex>

      {url && (
        <Modal
          opened={!!url}
          onClose={() => setUrl(null)}
          title={
            <Text>
              {lang.statistics.contacts_per_link}{" "}
              <Anchor href={url} target="_blank">
                {url}
              </Anchor>
            </Text>
          }
          size={"90%"}
        >
          <UrlContacts url={url} />
        </Modal>
      )}
    </>
  );
};

export default ClickerLinks;
