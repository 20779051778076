import { ActionIcon, Box, Group, Tooltip } from "@mantine/core";
import { useLanguage } from "../../stores/LanguageStore";
import { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import FilterValue from "./valueBlock/FilterValue";
import FilterCondition from "./conditionBlock/FilterCondition";
import FilterField from "./fieldBlock/FilterField";
import { IconTrash } from "@tabler/icons-react";

const booleanCondition = [
  "does_exist",
  "does_not_exist",
  "is_empty",
  "is_not_empty",
  "isEmpty",
  "isNotEmpty",
];

const FilterItem = ({
  item,
  fields,
  handleChange,
  isClient,
  parentMode,
  lineColor,
  isLast,
  getFieldOptionName,
  handleDelete,
}) => {
  const lang = useLanguage((s) => s.language);
  const allFields = useMemo(
    () => fields.reduce((acc, group) => [...acc, ...group.options], []),
    [fields]
  );
  const [field, setField] = useState(
    allFields?.find((x) => x?.id?.toString() === item?.field_name)
  );

  useEffect(() => {
    setField(allFields?.find((x) => x?.id?.toString() === item?.field_name));
  }, [allFields, item?.field_name]);

  const [condition, setCondition] = useState(item?.type || "equals");
  const [conditionType, setConditionType] = useState(field?.type || "text");
  const [value, setValue] = useState(item?.value || "");

  const handleFieldChange = (newValue) => {
    setField(newValue);

    const newValueIsDate =
      newValue?.type === "date" && conditionType !== "date";

    if (newValueIsDate) {
      if (isClient) {
        setCondition("after");
      } else {
        setCondition("start_with");
      }
      const actual = dayjs()
        .set("h", 0)
        .set("minutes", 0)
        .set("second", 0)
        .set("milliseconds", 0);

      setValue(actual.toISOString());

      return setConditionType("date");
    } else if (field?.type !== newValue?.type) {
      if (newValue?.type === "number") setCondition(isClient ? "=" : "equals");
      else setCondition("equals");
    }
    if (field?.type !== newValue?.type) {
      if (newValue?.type === "number") setCondition(isClient ? "=" : "equals");
      else setCondition("equals");
    }

    if (
      (field?.type === "date" ||
        field?.option_name?.toLowerCase().includes("date")) &&
      !newValueIsDate
    ) {
      setValue("");
    }

    if (newValue?.type === "exist") {
      setCondition("does_exist");
      setValue("");
    }

    if (newValue?.type === "date_range") {
      setCondition("between");
      setValue(`${new Date().toISOString()};${new Date().toISOString()}`);
    }

    setConditionType(newValue?.type || "text");
  };

  useEffect(() => {
    if (handleChange && field?.id) {
      handleChange({
        field_name: field?.id?.toString(),
        type: condition,
        value: value?.toString() || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field, condition, value]);

  return (
    <Group pos={"relative"} pl={32} ml={6} mb={6} wrap="nowrap">
      {!isLast && (
        <Box
          style={{
            position: "absolute",
            top: "calc(50% + 29px)",
            left: 7,
            transform: "translate(-50%, -50%)",
            zIndex: 5,
            background: lineColor,
            borderRadius: 2,
            fontWeight: 500,
            color: "#fff",
            fontSize: 8,
          }}
          px={2}
          py={1}
        >
          {parentMode === "and" ? "ET" : "OU"}
        </Box>
      )}

      <Box
        style={{
          position: "absolute",
          top: "50%",
          left: 6,
          width: 42,
          height: 0,
          borderTop: "2px solid",
          borderColor: lineColor,
        }}
      />

      <FilterField
        field={field}
        fields={fields}
        handleFieldChange={handleFieldChange}
        getFieldOptionName={getFieldOptionName}
      />

      <FilterCondition
        isClient={isClient}
        type={conditionType}
        value={condition}
        setValue={setCondition}
        disabled={field?.disabledCondition}
      />
      {!booleanCondition.includes(condition) && (
        <FilterValue
          value={value}
          setValue={setValue}
          label={field?.option_name}
          type={conditionType}
          valueOptions={field?.options}
          getter={field?.getter}
          responseField={field?.responseField}
          storeKey={field?.storeKey}
          setStoreKey={field?.setStoreKey}
          nameField={field?.nameField}
          groups={field?.groups}
          singleSelectOptions={field?.valueOptions}
        />
      )}
      <Tooltip
        label={lang.table.toolbar.filter.conditions.delete_condition_tooltip}
      >
        <ActionIcon color="red" variant="subtle" onClick={handleDelete}>
          <IconTrash size={18} />
        </ActionIcon>
      </Tooltip>
    </Group>
  );
};

export default FilterItem;
