import { Button, Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconShare } from "@tabler/icons-react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import LeadsSharingContent from "./LeadsSharingContent";

const LeadsSharing = ({ disabled }) => {
  const lang = useLanguage((s) => s.language);
  const [opened, { open, close }] = useDisclosure();

  return (
    <>
      <Button
        variant="light"
        radius={"sm"}
        leftSection={<IconShare size={18} />}
        color="orange"
        onClick={open}
        disabled={disabled}
      >
        {lang.prm.sharing.my_sharings}
      </Button>

      <Modal
        opened={opened}
        onClose={close}
        title={lang.prm.sharing.my_sharings}
        size={"40%"}
      >
        <LeadsSharingContent handleClose={close} />
      </Modal>
    </>
  );
};

export default LeadsSharing;
