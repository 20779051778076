import { useFolderStore } from "../stores/FolderStore";
import { mainAxios as axios } from "./axios";

export const getFolders = async () => {
  let res = await axios.get(`/folders`);

  if (res.data.state) {
    useFolderStore.getState().setFolders(res.data.folders);
  }
  return res;
};

export const findFolder = async (id) => {
  return axios.get(`/folders/${id}`);
};

export const createFolder = async ({ name, type, parent_id }) => {
  return axios.post("/folders", {
    name,
    type,
    parent_id,
  });
};

export const editFolder = async ({ id, name }) => {
  return axios.put(`/folders/${id}`, {
    name: name,
  });
};

export const deleteFolder = async ({ id }) => {
  return axios.delete(`/folders/${id}`);
};
