import MultiSelector from "../../../../../../components/Selectors/MultiSelector";
import { useEffect, useMemo, useState } from "react";
import { parseDate } from "../../../../../../functions/dates";
import { excludeProspectFromProgrammation } from "../../../../../../api/PRMAPI";
import { useLanguage } from "../../../../../../stores/LanguageStore";
import { notifications } from "@mantine/notifications";
import { Anchor, Stack, Text } from "@mantine/core";
import { getQuickCampaigns } from "../../../../../../api/StatsAPI";
import { usePrmStore } from "../../../../../../stores/PrmStore";

function getOptionName(option) {
  return `${option.workflow_name}`;
}

function getExcluded(current) {
  let result = [];
  current.programmations.forEach((p) => {
    if (p.excluded_programmation) {
      result.push(p.programmation_id);
    }
  });

  return result;
}

const ExcludeCampaign = ({ current }) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(false);
  const lists = usePrmStore((s) => s.campaignOptions);
  const handleLoad = () => {
    setLoading(true);
    getQuickCampaigns().finally(() => setLoading(false));
  };

  const options = useMemo(() => {
    return lists.map((list) => ({
      ...list,
      option_name: getOptionName(list),
    }));
  }, [lists]);

  const [values, setValues] = useState(getExcluded(current));
  const [checking, setChecking] = useState(false);
  const onChange = (v) => {
    setChecking(true);
    excludeProspectFromProgrammation({
      contact_id: current.id,
      programmation_id: v[v.length - 1]?.id,
    })
      .then((response) => {
        notifications.show({
          message: lang.prm.exclude.success_workflow,
          color: "tertiary.8",
        });
        setValues(v.map((x) => x.id));
      })
      .finally(() => setChecking(false));
  };

  useEffect(() => {
    handleLoad();
  }, []);

  return (
    <Stack gap={0}>
      <Text fw={600}>Campagnes</Text>
      <Text c={"dimmed"} size="sm" mb={"xs"}>
        Exclure le prospect d'une de vos campagnes
      </Text>
      <MultiSelector
        options={options}
        label={null}
        onDropdownOpen={handleLoad}
        loading={loading}
        values={
          values
            ?.map((listId) => {
              let list = lists?.find((x) => x.id === listId);
              if (list) {
                return {
                  ...list,
                  option_name: getOptionName(list),
                };
              }
              return;
            })
            ?.filter(Boolean) || []
        }
        onChange={onChange}
        placeholder={"Campagnes"}
        rightOptionSection={(item) => {
          return (
            <>
              {item?.created_on && (
                <span style={{ fontSize: 10, opacity: 0.35, width: 105 }}>
                  {parseDate(item?.date_start)}
                </span>
              )}
              {item?.id && (
                <Anchor
                  href={`/statistics/campaigns?highlight=${item.id}`}
                  target="_blank"
                  style={{ fontSize: 10 }}
                >
                  #{item?.id}
                </Anchor>
              )}
            </>
          );
        }}
        size={"sm"}
        w={"100%"}
        searchable
        checkIconPosition="left"
        dropdownHeight={110}
        styles={{
          input: {
            borderColor: "var(--mantine-primary-color-4)",
            borderWidth: 2,
            minHeight: 102,
          },
        }}
        autoFocus={false}
        checkboxColor="red"
        disabled={checking}
      />
    </Stack>
  );
};

export default ExcludeCampaign;
