import { Button, Indicator } from "@mantine/core";
import FilterContent from "../../../../../../../components/Filter/FilterContent";
import { useFilterStore } from "../../../../../../../stores/ConfigStore";
import { useFlowStore } from "../../../../../../../stores/WorkflowStore";
import { useState } from "react";
import { Popover } from "@mui/material";
import { useLanguage } from "../../../../../../../stores/LanguageStore";

const EventFilter = ({ id, filter, disabled }) => {
  const lang = useLanguage((s) => s.language);
  const datafieldsFilter = useFilterStore((s) => s.datafieldsFilter);
  const updateNodeData = useFlowStore((s) => s.updateNodeData);

  const handleEventFilterChange = (newFilter) => {
    updateNodeData(id, { filter: newFilter });
    handleClose();
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpen = (e) => {
    setAnchorEl(document.getElementById("event-filter-" + id));
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Indicator
        w={"100%"}
        offset={16}
        color="red"
        position="middle-start"
        withBorder
        mt={8}
        disabled={!filter || filter?.values?.length === 0}
        styles={{
          indicator: {
            width: 12,
            height: 12,
          },
        }}
      >
        <Button
          variant="default"
          size="xs"
          w={"100%"}
          onClick={handleOpen}
          id={"event-filter-" + id}
        >
          {lang.campaign.selectors.event.filter_label}
        </Button>
      </Indicator>

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={3}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        slotProps={{
          paper: {
            sx: {
              ml: -12,
              p: 1,
              border: "1px solid #00000018",
              boxShadow: "#00000021 0 4px 10px",
            },
          },
        }}
      >
        <FilterContent
          mainFilter={filter}
          setMainFilter={handleEventFilterChange}
          fields={datafieldsFilter}
          getFieldOptionName={(f) => f.name}
          id={id}
          maxHeight={200}
          pageName={"contact_lists_contacts"}
          // disableEdit={disabled}
          // message={disabled ? lang.campaign.selectors.event.not_editable : ""}
        />
      </Popover>
    </>
  );
};

export default EventFilter;
