import { ActionIcon, Group, Menu } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { EdgeLabelRenderer, getBezierPath, useReactFlow } from "reactflow";
import { isMainAction, nodeWidth } from "../flowSettings";
import { useState } from "react";
import { useFlowStore } from "../../../../../../stores/WorkflowStore";
import { handleLayout } from "../layouter";
import ActionsList from "../components/ActionsList";

const PreviewEdge = ({
  sourceX,
  sourceY,
  targetX,
  targetY,
  target,
  source,
  sourcePosition,
  targetPosition,
  data,
  markerEnd,
}) => {
  const edgeId = `e-${source}-${target}`;
  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <path
      id={edgeId}
      className={"react-flow__edge-path"}
      d={edgePath}
      markerEnd={markerEnd}
      style={{
        stroke: data?.color,
        strokeWidth: 1.4,
      }}
    />
  );
};
export default PreviewEdge;
