import { useMemo, useState } from "react";
import { Group, Tooltip } from "@mantine/core";
import { useAccountsStore } from "../../stores/ConfigStore";
import { useLanguage } from "../../stores/LanguageStore";
import { getLinkedinAccounts, getPools } from "../../api/EmailAPI";
import { IconCheck, IconCompass, IconX } from "@tabler/icons-react";
import Selector from "./Selector";
import AddLinkedinAccount from "../Adder/AddLinkedinAccount";

function getOptionName(option, isPool) {
  if (isPool)
    return `POOL: ${option.name} #${option.id} (${option.number_of_accounts})`;
  return `${option.name} #${option.id}`;
}

const LinkedinAccountSelector = ({
  id,
  label,
  placeholder,
  size,
  required,
  value,
  onChange,
  leftSection,
  disabled,
  withAdder,
  description,
  onClick,
  ...other
}) => {
  const lang = useLanguage((s) => s.language);
  const accounts = useAccountsStore((s) => s.linkedin_accounts);
  const linkedin_pools = useAccountsStore((s) => s.linkedin_pools);
  const [loading, setLoading] = useState(false);
  const handleLoad = () => {
    setLoading(true);
    getLinkedinAccounts().then(() => {
      getPools().finally(() => setLoading(false));
    });
    onClick?.();
  };

  const options = useMemo(() => {
    let result = [
      { label: "Compte", options: [] },
      { label: "Pools", options: [] },
    ];
    accounts?.forEach((account) => {
      let place = result.find((x) => x.label === "Compte");
      if (place)
        place.options.push({
          ...account,
          option_name: getOptionName(account),
        });
    });
    linkedin_pools?.forEach((pool) => {
      let place = result.find((x) => x.label === "Pools");
      place.options.push({
        ...pool,
        option_name: getOptionName(pool, true),
      });
    });
    return [...accounts, ...linkedin_pools].length > 0 ? result : [];
  }, [accounts, linkedin_pools]);

  return (
    <Group wrap="nowrap" align="flex-start" w={"100%"} {...other}>
      <Selector
        disabled={disabled}
        getError={
          accounts.find((x) => x.id === value)?.is_valid === false && !disabled
            ? lang.emails_account.columns.not_valid
            : required
            ? !value
            : null
        }
        id={id}
        label={label}
        leftSection={leftSection}
        loading={loading}
        onChange={(val) => onChange(val)}
        onDropdownOpen={handleLoad}
        options={options}
        description={description}
        placeholder={
          placeholder || lang.campaign.selectors.senders.placeholder_linkedin
        }
        required={required}
        rightOptionSection={(item) => {
          return (
            <>
              {item.is_sales_navigator_account ? (
                <Tooltip label="Sales Navigator" withArrow>
                  <IconCompass size={16} mr="sm" />
                </Tooltip>
              ) : (
                ""
              )}
              {item.is_valid ? (
                <Tooltip
                  label={lang.campaign.selectors.senders.verified}
                  withArrow
                >
                  <IconCheck size={16} color="tertiary.8" />
                </Tooltip>
              ) : (
                <Tooltip
                  label={lang.campaign.selectors.senders.not_verified}
                  withArrow
                >
                  <IconX size={16} color="red" />
                </Tooltip>
              )}
            </>
          );
        }}
        getValidOption={(o) => o?.is_valid}
        size={size}
        value={value}
        getOptionName={getOptionName}
        {...other}
      />
      {withAdder && <AddLinkedinAccount />}
    </Group>
  );
};

export default LinkedinAccountSelector;
