import { useMemo, useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { Group, Paper, Text, Tooltip } from "@mantine/core";
import { types } from "./jobTypes";
import { parseDate } from "../../../../../functions/dates";
import StopJob from "./StopJob";
import { handleJobItem } from "./JobItemArguments";
import { useListStore } from "../../../../../stores/ListStore";

const JobItemInQueue = ({ item }) => {
  const lang = useLanguage((s) => s.language);
  const queuedDate = new Date(Date.parse(item?.queued_date));
  const [stopped, setStopped] = useState(false);

  const lists = useListStore((s) => s.lists);
  const fromLists = useMemo(() => {
    if (item.type === "Merge") {
      return item.arguments.contactListIds.map((id) => {
        return lists?.find((x) => x.id === id) || { id };
      });
    }
    if (item.type === "Split") {
      return (
        lists?.find((x) => x.id === item.arguments.fromContactListId) || {
          id: item.arguments.fromContactListId,
        }
      );
    }
    return null;
  }, [lists, item]);

  return (
    <Paper withBorder p={"sm"} bg={"#00000006"}>
      <Group opacity={stopped ? 0.35 : 1}>
        <Group direction="row" gap={4} mr={1}>
          {types(item.type)?.icon}

          <Text fw={500} fz={14}>
            {types(item.type, false, item?.arguments?.generateEmail)?.label}
          </Text>
        </Group>
        <Text fz={10} style={{ opacity: 0.75 }}>
          <b>{lang.contact_lists.jobs.queued_date}</b>{" "}
          <Tooltip label={queuedDate.toLocaleString()}>
            <span>{parseDate(queuedDate)}</span>
          </Tooltip>{" "}
        </Text>

        {!stopped && <StopJob id={item?.uniqid} setStopped={setStopped} />}
      </Group>
      {handleJobItem({ item: item, fromLists, isProgress: true })}
      {stopped && (
        <Text size="sm" c={"red"} fw={500} mt={6}>
          {lang.contact_lists.jobs.cancellation_request}
        </Text>
      )}
    </Paper>
  );
};

export default JobItemInQueue;
