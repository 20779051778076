import { useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import {
  Button,
  Group,
  Indicator,
  Modal,
  Select,
  Stack,
  Switch,
  TextInput,
} from "@mantine/core";
import { IconFilter, IconPlus } from "@tabler/icons-react";
import { useLeadsStore } from "../../../../../stores/LeadsStore";
import FilterContent from "../../../../../components/Filter/FilterContent";
import { notifications } from "@mantine/notifications";
import PopOver from "../../../../../components/View/PopOver";
import BackButton from "../../../../../components/View/BackButton";
import { createZapierHooks } from "../../../../../api/ZapierApi";

const CreateZapier = ({ handleDone, type }) => {
  const lang = useLanguage((s) => s.language);
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const filters = useLeadsStore((s) => s.filters);
  const [invalidUrl, setInvalidUrl] = useState(false);

  const [filter, setFilter] = useState(null);

  const handleOpen = () => {
    setOpened(true);
    setFilter(null);
    setInvalidUrl(false);
  };
  const handleClose = () => {
    setOpened(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    let formData = new FormData(e.target);
    let data = Object.fromEntries(formData);

    createZapierHooks({
      name: data?.name,
      event: data?.event,
      source: type || "prm",
      url: data?.url,
      active: true,
      filter,
      resend_synchronized_contacts:
        data?.resend_synchronized_contacts === "on" ? false : true,
    })
      .then((response) => {
        notifications.show({
          message: lang.sync_zapier.created,
          color: "tertiary.8",
        });
        handleClose();
        handleDone();
      })
      .catch((err) => {
        if (err.response.data.state_message === "invalid_zapier_url") {
          document.getElementById("new-webhook-url").focus();
          setInvalidUrl(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        leftSection={<IconPlus size={18} />}
      >
        {lang.sync_zapier.create_label}
      </Button>

      <Modal
        opened={opened}
        title={lang.sync_zapier.create_dialog_title}
        onClose={handleClose}
        size={"50%"}
      >
        <Stack component={"form"} onSubmit={onSubmit}>
          <Group wrap="nowrap">
            <TextInput
              name="name"
              label={lang.prm.nurturing.create_name}
              placeholder={lang.prm.nurturing.create_name}
              required
              w={"100%"}
              autoFocus
              data-autoFocus
            />

            <Select
              name="event"
              label={lang.sync_zapier.event_label}
              placeholder={lang.sync_zapier.event_label}
              defaultValue={"manual"}
              required
              data={Object.keys(lang.sync_zapier.events).map((x) => ({
                value: x,
                label: lang.sync_zapier.events[x],
              }))}
            />
          </Group>

          <TextInput
            name="url"
            label={lang.sync_zapier.hook_url}
            id="new-webhook-url"
            type="url"
            placeholder="https://hooks.zapier.com/hooks/catch/1234/abc/"
            required
            error={invalidUrl}
          />

          <Switch
            label={lang.sync_zapier.ignore}
            defaultChecked
            name="resend_synchronized_contacts"
          />

          {type === "prm" && (
            <PopOver
              opener={(openFunction, ref) => {
                return (
                  <Button
                    ref={ref}
                    variant="default"
                    onClick={openFunction}
                    id={"nurturing-filter-" + "creation"}
                  >
                    <Indicator
                      color="red"
                      offset={2}
                      disabled={filter?.items?.length === 0 || !filter}
                      label={filter?.items?.length}
                      size={16}
                    >
                      <IconFilter size={18} />
                    </Indicator>
                    <span style={{ marginLeft: 6 }}>
                      {lang.table.toolbar.filter.main_button_label} *
                    </span>
                  </Button>
                );
              }}
              popoverChildren={(closePopover) => (
                <FilterContent
                  fields={filters}
                  id={"nurturing-create"}
                  isClient={false}
                  pageName={"prm"}
                  maxHeight={350}
                  mainFilter={filter}
                  setMainFilter={(v) => {
                    closePopover();
                    setFilter(v);
                  }}
                  defaultFiltersClose
                  getFieldOptionName={(f) => f.option_name}
                />
              )}
              elevation={3}
            />
          )}

          <Group justify="end">
            <BackButton onClick={handleClose} />
            <Button
              // disabled={
              //   type === "prm" ? !filter || filter?.values?.length === 0 : false
              // }
              leftSection={<IconPlus size={18} />}
              loading={loading}
              type="submit"
            >
              {lang.sync_zapier.create}
            </Button>
          </Group>
        </Stack>
      </Modal>
    </>
  );
};

export default CreateZapier;
