import { mainAxios as axios } from "./axios";

export const countResults = async ({ filters }) => {
  return axios.post("/targeting/database/count-preview", { filters });
};
export const extractResults = async ({
  filters,
  contact_list_id,
  contact_list_name,
  contact_list_country,
  contact_list_language,
  max_results,
}) => {
  return axios.post("/targeting/database/extract", {
    filters,
    contact_list_id,
    contact_list_name,
    contact_list_country,
    contact_list_language,
    max_results,
  });
};

export const searchDatabaseLocation = async (name) => {
  return axios.post(`/targeting/database/locations/search`, { name });
};
