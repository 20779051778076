import {
  Accordion,
  Checkbox,
  Group,
  NumberInput,
  Stack,
  Textarea,
} from "@mantine/core";
import { IconAdjustments } from "@tabler/icons-react";
import { useFlowStore } from "../../../../../../../stores/WorkflowStore";
import NodeTimeSelector from "./NodeTimeSelector";
import { useLanguage } from "../../../../../../../stores/LanguageStore";
import dayjs from "dayjs";
import { useMemo } from "react";
import { defaultNodeConfigs } from "../../flowSettings";
import BlacklistSelector from "../../../../../../../components/Selectors/BlacklistSelector";

const NodePanelAdvanced = ({ node }) => {
  const lang = useLanguage((s) => s.language);
  const updateNodeData = useFlowStore((s) => s.updateNodeData);
  const handleLimitChange = (n) => {
    updateNodeData(node.id, { limit_send_per_day: n });
  };

  const handleBlacklistChange = (values) => {
    updateNodeData(node.id, { blacklist_ids: values.map((x) => x.id) });
  };

  const handleExcludeChange = (text) => {
    let values = text.split("\n");
    updateNodeData(node.id, { email_linkedin_exclude: values });
  };

  // const timeError = useMemo(() => {
  //   const timeStart = dayjs(
  //     node?.data?.time_start_sending || defaultNodeConfigs.time_start_sending,
  //     "HH:mm:ss"
  //   );
  //   const timeStop = dayjs(
  //     node?.data?.time_stop_sending || defaultNodeConfigs.time_stop_sending,
  //     "HH:mm:ss"
  //   );

  //   return {
  //     start: timeStart.diff(timeStop) >= 0,
  //     stop: timeStop.diff(timeStart) <= 0,
  //   };
  // }, [node?.data?.time_start_sending, node?.data?.time_stop_sending]);

  return (
    <Accordion
      mt={"sm"}
      variant="contained"
      defaultValue={"show"}
      id="panel-advaced-settings"
    >
      <Accordion.Item value={"show"}>
        <Accordion.Control icon={<IconAdjustments />}>
          {lang.campaign.nodes.advanced_settings.label}
        </Accordion.Control>
        <Accordion.Panel>
          <Stack gap={"xs"}>
            <Group w={"100%"} wrap="nowrap" gap={"lg"}>
              <NumberInput
                label={lang.workflows.step_options.limit_send_per_day}
                defaultValue={
                  node?.data?.limit_send_per_day ||
                  defaultNodeConfigs.limit_send_per_day
                }
                onChange={handleLimitChange}
                min={0}
                w={"100%"}
              />

              <NodeTimeSelector
                label={lang.workflows.step_options.time_start_sending}
                field={"time_start_sending"}
                node={node}
                // error={timeError.start}
              />

              <NodeTimeSelector
                label={lang.workflows.step_options.time_stop_sending}
                field={"time_stop_sending"}
                node={node}
                // error={timeError.stop}
              />
            </Group>

            <Textarea
              key={node?.id}
              label={
                lang.workflows.step_options[
                  node?.data?.actionType === "email"
                    ? "email_exclude"
                    : "account_exclude"
                ]
              }
              placeholder={
                lang.workflows.step_options[
                  node?.data?.actionType === "email"
                    ? "one_email_per_row"
                    : "one_account_link_per_row"
                ]
              }
              w={"100%"}
              styles={{
                wrapper: {
                  height: "100%",
                },
                input: {
                  minHeight: "calc(100% - 23px)",
                },
              }}
              defaultValue={node?.data?.email_linkedin_exclude?.join("\n")}
              onBlur={(e) => handleExcludeChange(e.target.value)}
              rows={4}
            />

            <BlacklistSelector
              label={lang.workflows.step_options.blacklists}
              placeholder={lang.workflows.step_options.blacklists}
              values={node?.data?.blacklist_ids || []}
              onChange={handleBlacklistChange}
              dropdownHeight={120}
            />

            <Group mt={"xs"} gap={"lg"}>
              <Checkbox
                label={lang.workflows.step_options.sending_weekends_allowed}
                checked={!!node?.data?.sending_weekends_allowed}
                onChange={(e) =>
                  updateNodeData(node.id, {
                    sending_weekends_allowed: e.target.checked,
                  })
                }
              />
              <Checkbox
                label={lang.workflows.step_options.show_reply_as_conversation}
                checked={!!node?.data?.show_reply_as_conversation}
                onChange={(e) =>
                  updateNodeData(node.id, {
                    show_reply_as_conversation: e.target.checked,
                  })
                }
              />
            </Group>
          </Stack>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

export default NodePanelAdvanced;
