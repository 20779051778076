import { useLanguage } from "../../../../../stores/LanguageStore";
import {
  ActionIcon,
  Button,
  Group,
  Modal,
  Radio,
  RadioGroup,
  Text,
  Tooltip,
} from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import { useState } from "react";
import { deleteStatusCustom } from "../../../../../api/PRMAPI";
import { notifications } from "@mantine/notifications";
import BackButton from "../../../../../components/View/BackButton";
import PRMStatusSelector from "../../../../../components/Selectors/PRMStatusSelector";

const DeleteStatus = ({ id, statusName, handleRefresh, disabled, number }) => {
  const lang = useLanguage((s) => s.language);
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState("delete");

  const [status, setStatus] = useState("opener");

  const handleDelete = () => {
    setLoading(true);

    deleteStatusCustom({ id })
      .then((response) => {
        handleRefresh("delete", id);
        setOpened(false);
        notifications.show({
          message: lang.prm.status.deleted,
          color: "tertiary.8",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Tooltip label={lang.prm.status.delete}>
        <ActionIcon
          size={"sm"}
          variant="light"
          disabled={disabled}
          color="red"
          onClick={() => setOpened(true)}
        >
          <IconTrash size={16} />
        </ActionIcon>
      </Tooltip>

      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={lang.prm.status.deletion_title}
        centered
      >
        <Text>
          {lang.prm.status.delete_text} <b>{statusName}</b>{" "}
          {lang.prm.status.with}{" "}
          <b>
            {number > -1 ? number : " - "} {lang.prm.status.prospect}
          </b>{" "}
          ?
        </Text>

        <RadioGroup value={state} onChange={setState} mt={8}>
          <Radio
            label={lang.prm.status.move_prospects_text}
            value={"move"}
            mb={8}
            disabled
          />
          <PRMStatusSelector
            value={status}
            onChange={setStatus}
            w={"100%"}
            required
            withinPortal={true}
            disabled={state !== "move"}
            noLabel
            disabledOption={id}
          />

          <Radio
            mt={"md"}
            label={lang.prm.status.delete_prospects}
            color="red"
            value={"delete"}
          />
        </RadioGroup>

        <Group mt={"md"} justify="end">
          <BackButton onClick={() => setOpened(false)} />

          <Button color="red" onClick={handleDelete} loading={loading}>
            {lang.prm.status.delete}
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export default DeleteStatus;
