import { Button, Modal, Tooltip } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconBrandZapier } from "@tabler/icons-react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import ZapierContent from "./ZapierContent";
import { useSessionStore } from "../../../../../stores/UserStore";

const ZapierSettings = ({ type = "prm", disabled }) => {
  const lang = useLanguage((s) => s.language);
  const [opened, { open, close }] = useDisclosure();

  return (
    <>
      {type === "prm" ? (
        <Button
          variant="light"
          radius={"sm"}
          leftSection={<IconBrandZapier size={18} />}
          onClick={open}
          disabled={disabled}
        >
          {lang.sync_zapier.button_label}
        </Button>
      ) : (
        <Tooltip label={lang.sync_zapier.dialog_title}>
          <Button
            variant="light"
            px={"xs"}
            radius={"sm"}
            onClick={open}
            disabled={disabled}
          >
            <IconBrandZapier />
          </Button>
        </Tooltip>
      )}

      <Modal
        opened={opened}
        onClose={close}
        title={lang.sync_zapier.dialog_title}
        size={
          useSessionStore.getState().reseller?.name === "Magileads"
            ? "85%"
            : "60%"
        }
      >
        <ZapierContent type={type} handleClose={close} />
      </Modal>
    </>
  );
};

export default ZapierSettings;
