import { useState } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { updateProfile } from "../../../../api/UserAPI";
import { Switch, Tooltip } from "@mantine/core";
import { useProfileStore } from "../../../../stores/UserStore";
import { useParams } from "react-router-dom";

const LeadMarkAuto = () => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(false);
  const profile = useProfileStore((s) => s.profile);
  const { userId } = useParams();

  const [checked, setChecked] = useState(profile?.answer_ai_mark_positive);

  const onChange = () => {
    let newValue = !checked;
    setChecked(newValue);
    setLoading(true);
    updateProfile(null, { answer_ai_mark_positive: newValue })
      .catch(() => {
        setChecked(!newValue);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (
    profile?.permissions?.find((x) => x.name === "answsersAIMarkPositive")
      ?.value === true
  )
    return (
      <Tooltip
        label={
          lang.administration.right.rights.list.answsersAIMarkPositive_tooltip
        }
      >
        <Switch
          disabled={userId ? profile.id !== parseInt(userId) : loading}
          onChange={onChange}
          checked={checked}
          label={lang.administration.right.rights.list.answsersAIMarkPositive}
          ml={32}
        />
      </Tooltip>
    );

  return "";
};

export default LeadMarkAuto;
