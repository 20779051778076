import { useCallback, useEffect, useMemo, useRef } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { useLeadsContainerStore } from "../../../../../stores/LeadsStore";
import { Stack } from "@mantine/core";
import InfiniteLoader from "react-window-infinite-loader";
import { VariableSizeList } from "react-window";
import Row from "./Row";

const Listing = ({ isItemLoaded, loadMoreItems, container }) => {
  const updateContainer = useLeadsContainerStore((s) => s.updateContainer);

  //#region Items
  const listRef = useRef(null);
  useEffect(() => {
    if (container.needCacheReset) {
      listRef.current?._listRef.resetAfterIndex(0);
      updateContainer(container.id, { ...container, needCacheReset: false });
    }
  }, [container, updateContainer]);

  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const itemCount = useMemo(() => {
    if (container.nextPageUrl) {
      return container?.items.length + 1;
    }
    return container?.items.length;
  }, [container]);

  const getItemSize = useCallback(
    (index) => {
      if (container.items[index]?.calls?.length > 0) {
        return 142;
      }
      return 122;
    },
    [container.items]
  );

  useEffect(() => {
    listRef.current?._listRef.resetAfterIndex(0);
  }, [container.items]);

  //#endregion

  return (
    <Stack flex={"1 1 auto"} gap={0}>
      <AutoSizer>
        {({ height, width }) => {
          return (
            <InfiniteLoader
              ref={listRef}
              isItemLoaded={isItemLoaded}
              itemCount={itemCount}
              loadMoreItems={loadMoreItems}
              threshold={1}
            >
              {({ onItemsRendered, ref }) => (
                <VariableSizeList
                  className="List"
                  height={height}
                  itemCount={itemCount}
                  itemSize={getItemSize}
                  itemData={{ container: container }}
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                  width={width}
                  useIsScrolling
                >
                  {Row}
                </VariableSizeList>
              )}
            </InfiniteLoader>
          );
        }}
      </AutoSizer>
    </Stack>
  );
};

export default Listing;
