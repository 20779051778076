import { useSessionStore } from "../stores/UserStore";
import { mainAxios } from "./axios";

const defaultAPI = "application.magileads.io";

export function extractDomainFromUrl(url) {
  const domainRegex = /^https?:\/\/([^/]+)\//;
  const match = url.match(domainRegex);

  if (match && match[1]) {
    let d = match[1];
    if (d === "localhost:3000") {
      return defaultAPI;
    }
    return match[1];
  }

  return null;
}

export const getApiDetailsDomain = async () => {
  let response = await mainAxios.get(
    `/api/${extractDomainFromUrl(window.location.href)}`
  );

  if (response) {
    let data = response.data.api;
    mainAxios.defaults.baseURL = "https://" + data.domain_api;

    useSessionStore
      .getState()
      .setAppUrl(
        window.location.href.includes("localhost")
          ? "http://localhost:3000"
          : "https://" +
              data.domain_saas.replace(
                "application.magileads.net",
                "application.magileads.io"
              )
      );
    useSessionStore.getState().setReseller(data);
  }

  return response;
};

export const getCnames = async () => {
  return mainAxios.get(`/resellers/cnames`);
};

export const downloadExtension = async ({ id, name }) => {
  return mainAxios
    .get(`/resellers/${id}/download/extension/chrome`, { responseType: "blob" })
    .then((res) => {
      const url = window.URL.createObjectURL(res.data);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${name} #${id}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
};
