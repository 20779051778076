import { useCallback, useEffect, useState } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useDebouncedValue } from "@mantine/hooks";
import { ActionIcon, TextInput } from "@mantine/core";
import { IconSearch, IconX } from "@tabler/icons-react";
import {
  useLeadsContainerStore,
  useLeadsStore,
} from "../../../../stores/LeadsStore";

const LeadsSearch = () => {
  const lang = useLanguage((s) => s.language);
  const setReloadAll = useLeadsContainerStore((s) => s.setReloadAll);
  const query = useLeadsStore((s) => s.query);
  const setQuery = useLeadsStore((s) => s.setQuery);

  const [value, setValue] = useState(query || "");
  const [debounced] = useDebouncedValue(value, 1000);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleClear = () => {
    setValue("");
    setQuery("");
  };

  useEffect(() => {
    if (debounced.length > 0) {
      setQuery(value);
    }
  }, [debounced, setQuery, setReloadAll, value]);

  useEffect(() => {
    if (query === debounced) {
      setReloadAll(true);
    }
  }, [query, debounced, setReloadAll]);

  return (
    <TextInput
      placeholder={lang.table.toolbar.search_placeholder}
      w={180}
      value={value}
      style={{
        borderTop: 0,
      }}
      styles={{
        input: {
          paddingLeft: 14,
          borderBottomRightRadius: 0,
          borderTopRightRadius: 0,
        },
      }}
      onChange={handleChange}
      rightSection={
        value.length > 0 ? (
          <ActionIcon onClick={handleClear} variant="subtle">
            <IconX size={18} />
          </ActionIcon>
        ) : (
          <IconSearch size={18} />
        )
      }
    />
  );
};

export default LeadsSearch;
