import { Button, Group, Paper, Stack, Text, Tooltip } from "@mantine/core";
import { useLanguage } from "../../../stores/LanguageStore";
import { useProfileStore, useSessionStore } from "../../../stores/UserStore";
import { useMemo, useState } from "react";
import { IconCheck, IconExternalLink } from "@tabler/icons-react";
import {
  addDefaultPayment,
  updateDefaultPayment,
} from "../../../api/SubscriptionAPI";
import { updateSelf } from "../../../api/UserAPI";
import { Navigate } from "react-router-dom";

const svg_width = 48;
const visa_svg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1000 324.68"
    fill="#fff"
    width={svg_width}
  >
    <path d="m651.19.5c-70.93,0-134.32,36.77-134.32,104.69,0,77.9,112.42,83.28,112.42,122.42,0,16.48-18.88,31.23-51.14,31.23-45.77,0-79.98-20.61-79.98-20.61l-14.64,68.55s39.41,17.41,91.73,17.41c77.55,0,138.58-38.57,138.58-107.66,0-82.32-112.89-87.54-112.89-123.86,0-12.91,15.5-27.05,47.66-27.05,36.29,0,65.89,14.99,65.89,14.99l14.33-66.2S696.61.5,651.18.5h0ZM2.22,5.5L.5,15.49s29.84,5.46,56.72,16.36c34.61,12.49,37.07,19.77,42.9,42.35l63.51,244.83h85.14L379.93,5.5h-84.94l-84.28,213.17-34.39-180.7c-3.15-20.68-19.13-32.48-38.68-32.48,0,0-135.41,0-135.41,0Zm411.87,0l-66.63,313.53h81L494.85,5.5h-80.76Zm451.76,0c-19.53,0-29.88,10.46-37.47,28.73l-118.67,284.8h84.94l16.43-47.47h103.48l9.99,47.47h74.95L934.12,5.5h-68.27Zm11.05,84.71l25.18,117.65h-67.45l42.28-117.65h0Z" />
  </svg>
);

const mc_svg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 152.407 108"
    width={svg_width}
  >
    <g>
      <rect width="152.407" height="108" fill="none" />
      <g>
        <rect
          x="60.4117"
          y="25.6968"
          width="31.5"
          height="56.6064"
          fill="#ff5f00"
        />
        <path
          d="M382.20839,306a35.9375,35.9375,0,0,1,13.7499-28.3032,36,36,0,1,0,0,56.6064A35.938,35.938,0,0,1,382.20839,306Z"
          transform="translate(-319.79649 -252)"
          fill="#eb001b"
        />
        <path
          d="M454.20349,306a35.99867,35.99867,0,0,1-58.2452,28.3032,36.00518,36.00518,0,0,0,0-56.6064A35.99867,35.99867,0,0,1,454.20349,306Z"
          transform="translate(-319.79649 -252)"
          fill="#f79e1b"
        />
        <path
          d="M450.76889,328.3077v-1.1589h.4673v-.2361h-1.1901v.2361h.4675v1.1589Zm2.3105,0v-1.3973h-.3648l-.41959.9611-.41971-.9611h-.365v1.3973h.2576v-1.054l.3935.9087h.2671l.39351-.911v1.0563Z"
          transform="translate(-319.79649 -252)"
          fill="#f79e1b"
        />
      </g>
    </g>
  </svg>
);

const amex_svg = (
  <svg
    version="1.1"
    width={svg_width}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 291.764 291.764"
  >
    <g>
      <path
        fill="#26A6D1"
        d="M18.235,41.025h255.294c10.066,0,18.235,8.169,18.235,18.244v173.235
		c0,10.066-8.169,18.235-18.235,18.235H18.235C8.16,250.74,0,242.57,0,232.505V59.269C0,49.194,8.169,41.025,18.235,41.025z"
      />
      <path
        fill="#FFFFFF"
        d="M47.047,113.966l-28.812,63.76h34.492l4.276-10.166h9.774l4.276,10.166h37.966v-7.759l3.383,7.759
		h19.639l3.383-7.923v7.923h78.959l9.601-9.902l8.99,9.902l40.555,0.082l-28.903-31.784l28.903-32.058h-39.926l-9.346,9.719
		l-8.707-9.719h-85.897l-7.376,16.457l-7.549-16.457h-34.42v7.495l-3.829-7.495C76.479,113.966,47.047,113.966,47.047,113.966z
		 M53.721,123.02h16.813l19.111,43.236V123.02h18.418l14.761,31l13.604-31h18.326v45.752h-11.151l-0.091-35.851l-16.257,35.851
		h-9.975l-16.348-35.851v35.851h-22.94l-4.349-10.257H50.147l-4.34,10.248H33.516C33.516,168.763,53.721,123.02,53.721,123.02z
		 M164.956,123.02h45.342L224.166,138l14.315-14.98h13.868l-21.071,22.995l21.071,22.73h-14.497l-13.868-15.154l-14.388,15.154
		h-44.64L164.956,123.02L164.956,123.02z M61.9,130.761l-7.741,18.272h15.473L61.9,130.761z M176.153,132.493v8.352h24.736v9.309
		h-24.736v9.118h27.745l12.892-13.43l-12.345-13.357h-28.292L176.153,132.493z"
      />
    </g>
  </svg>
);

const Chip = ({ ...props }) => (
  <svg
    fill="#00000066"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      d="M28,30.426H4c-1.337,0-2.425-1.089-2.425-2.426V4c0-1.337,1.088-2.425,2.425-2.425h24
	c1.337,0,2.426,1.088,2.426,2.425v24C30.426,29.337,29.337,30.426,28,30.426z M16.425,29.574H28c0.868,0,1.574-0.706,1.574-1.574
	v-2.574H25c-0.113,0-0.221-0.045-0.301-0.125l-3.875-3.875h-4.399V29.574z M2.425,25.426V28c0,0.868,0.707,1.574,1.575,1.574h11.575
	v-8.148h-4.398l-3.875,3.875c-0.08,0.08-0.188,0.125-0.301,0.125C7.001,25.426,2.425,25.426,2.425,25.426z M25.176,24.574h4.398
	v-8.149h-8.148v4.399L25.176,24.574z M2.425,24.574h4.398l3.751-3.75v-4.399H2.425V24.574z M11.425,20.574h9.149v-9.149h-9.149
	C11.425,11.425,11.425,20.574,11.425,20.574z M21.426,15.575h8.148v-8.15h-4.398l-3.75,3.751
	C21.426,11.176,21.426,15.575,21.426,15.575z M2.425,15.575h8.149v-4.398l-3.75-3.752H2.425V15.575z M16.425,10.575h4.399
	L24.699,6.7c0.08-0.08,0.188-0.125,0.301-0.125h4.574V4c0-0.868-0.706-1.575-1.574-1.575H16.425V10.575z M11.176,10.575h4.398v-8.15
	H4C3.132,2.425,2.425,3.132,2.425,4v2.575H7c0.113,0,0.221,0.044,0.301,0.125L11.176,10.575z"
    />
    <rect fill="none" width="32" height="32" />
  </svg>
);

const Card = ({ card }) => {
  const lang = useLanguage((s) => s.language);
  const props = {
    visa: {
      bg: "#4c6fff",
      svg: visa_svg,
      name: "VISA",
    },
    mastercard: {
      bg: "#333",
      svg: mc_svg,
      name: "MasterCard",
    },
    american_express: {
      bg: "#999",
      svg: amex_svg,
      name: "American Express",
    },
    other: {
      bg: "#666",
      svg: null,
      name: card.brand,
    },
  };
  const brand = props[card.brand];

  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    if (card?.is_default) {
      return;
    }
    setLoading(true);
    updateDefaultPayment(card.id)
      .then(() => {
        updateSelf(false);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Paper
      bg={"#edf2f7"}
      p={"md"}
      radius={"lg"}
      w={500}
      className="hover-source"
    >
      <Group justify="space-between">
        <Text fw={600} mb={8}>
          {card.name}
        </Text>

        <Tooltip
          label={
            card?.is_default
              ? lang.profile.payment.is_default
              : lang.profile.payment.set_as_default
          }
        >
          <Button
            variant={card?.is_default ? "filled" : "outline"}
            size="compact-sm"
            pr={12}
            className={"half " + card?.is_default ? "" : "hover-target"}
            leftSection={<IconCheck size={18} />}
            loading={loading}
            onClick={handleClick}
            radius={"lg"}
            style={{
              cursor: card?.is_default ? "default" : "pointer",
            }}
          >
            {lang.profile.payment.default}
          </Button>
        </Tooltip>
      </Group>
      <Group>
        <Stack
          style={{
            background: brand.bg,
            borderRadius: 16,
          }}
          p={"sm"}
          w={200}
          c={"#fff"}
          gap={4}
        >
          <Group justify="space-between">
            <Text truncate w={"80%"} size="sm">
              {card.name}
            </Text>
            <Text>$</Text>
          </Group>

          <Group
            mb={8}
            bg={"#fff"}
            w={"fit-content"}
            style={{ borderRadius: 4 }}
          >
            <Chip height={20} />
          </Group>

          <Group justify="space-between">
            <Text size="sm">{card.last4}</Text>

            {brand.svg}
          </Group>
        </Stack>

        <Stack gap={"xs"}>
          <Group>
            <Text size="lg" fw={600}>
              {brand.name} :
            </Text>
            <Text size="lg" fw={700} style={{ letterSpacing: 1.5 }}>
              {card.last4}
            </Text>
          </Group>
          <Group>
            <Text size="lg" fw={600}>
              {lang.profile.payment.date_exp}
            </Text>
            <Text size="lg" fw={700} style={{ letterSpacing: 1 }}>
              {card.exp_month} / {card.exp_year}
            </Text>
          </Group>
        </Stack>
      </Group>
    </Paper>
  );
};

const PaymentMethod = () => {
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);
  const reseller = useSessionStore((s) => s.reseller);
  const [loading, setLoading] = useState(false);

  const handleAdd = () => {
    setLoading(true);
    addDefaultPayment({
      success_url: `https://${
        reseller?.domain_saas2 || reseller?.domain_saas
      }/account-settings/subscription?session_id={CHECKOUT_SESSION_ID}`,
      cancel_url: `https://${
        reseller?.domain_saas2 || reseller?.domain_saas
      }/error/subscription`,
    }).then((r) => {
      window.open(r.data.redirect_url, "_self");
    });
  };

  const methods = useMemo(() => {
    return profile?.subscriptions?.payment_methods;
  }, [profile]);

  if (!methods) {
    return <Navigate to={".."} replace />;
  }

  return (
    <Group>
      <Paper
        withBorder
        component={Stack}
        w={"100%"}
        p={"md"}
        px={"xl"}
        radius={"md"}
        gap={"sm"}
      >
        <Text fw={600} size="lg">
          {lang.profile.payment_methods}
        </Text>

        <Stack mb={16}>
          {methods.map((item) => (
            <Card card={item} key={item.id} />
          ))}
        </Stack>

        <Button
          w={500}
          size="lg"
          variant="light"
          rightSection={<IconExternalLink />}
          loading={loading}
          onClick={handleAdd}
          radius={"lg"}
        >
          {lang.profile.payment.add}
        </Button>
        <Text mt={0} size="sm">
          * {lang.profile.payment.add_text}
        </Text>
      </Paper>
    </Group>
  );
};

export default PaymentMethod;
