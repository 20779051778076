import { Group, Select, Stack, Text } from "@mantine/core";
import { useLeadsSheetStore } from "../../../../../../../stores/LeadsStore";
import { useMemo, useState } from "react";
import ActivityGroup from "./ActivityGroup";
import { IconArrowDown, IconArrowUp } from "@tabler/icons-react";
import { useLanguage } from "../../../../../../../stores/LanguageStore";
import Answers from "../../../answers/Answers";
import AddNote from "./note/AddNote";

const ProspectActivities = () => {
  const lang = useLanguage((s) => s.language);
  const current = useLeadsSheetStore((s) => s.current);
  const sort = useLeadsSheetStore((s) => s.sort);
  const setSort = useLeadsSheetStore((s) => s.setSort);
  const currentContainer = useLeadsSheetStore((s) => s.currentContainer);
  const index = useLeadsSheetStore((s) => s.index);

  const [filter, setFilter] = useState(null);

  const activities = useMemo(() => {
    let h = [
      ...(current?.history || []),
      {
        history_type: "creation",
        date: current?.created_on,
        event: "email_open",
        workflow_step_id: 0,
        workflow_programmation_id: 0,
      },
    ];
    let history =
      h.sort((a, b) =>
        sort === "desc"
          ? Date.parse(b.date) - Date.parse(a.date)
          : Date.parse(a.date) - Date.parse(b.date)
      ) || [];

    const filtered = filter
      ? history.filter((x) => x.history_type === filter)
      : history;

    const groupedActivities = {};

    filtered.forEach((activity) => {
      const date = activity.date.split("T")[0]; // Extracting only date part
      if (groupedActivities[date]) {
        groupedActivities[date].push(activity);
      } else {
        groupedActivities[date] = [activity];
      }
    });

    return groupedActivities;
  }, [current?.created_on, current?.history, filter, sort]);

  const filters = useMemo(() => {
    return [
      {
        label: lang.prm.details.filters.notes,
        value: "note",
      },
      {
        label: lang.prm.details.filters.replies,
        value: "reply",
      },
      {
        label: lang.prm.details.filters.sent,
        value: "sent",
      },
      {
        label: lang.prm.details.filters.events,
        value: "event",
      },
    ];
  }, [
    lang.prm.details.filters.events,
    lang.prm.details.filters.notes,
    lang.prm.details.filters.replies,
    lang.prm.details.filters.sent,
  ]);
  const orders = useMemo(() => {
    return [
      {
        label: lang.prm.details.filters.desc,
        value: "desc",
      },
      {
        label: lang.prm.details.filters.asc,
        value: "asc",
      },
    ];
  }, [lang.prm.details.filters.asc, lang.prm.details.filters.desc]);

  const handleFilterChange = (v) => {
    setFilter(v);
  };

  return (
    <Stack gap={0} h={"100%"} style={{ overflow: "auto" }}>
      <Group mb={"xs"}>
        <Select
          value={sort}
          data={orders}
          w={180}
          size="xs"
          onChange={setSort}
          leftSection={
            sort === "asc" ? (
              <IconArrowUp size={18} />
            ) : (
              <IconArrowDown size={18} />
            )
          }
        />
        <Select
          placeholder={lang.prm.details.filters.filter}
          data={filters}
          onChange={handleFilterChange}
          w={100}
          size="xs"
          clearable
        />

        <Answers container={currentContainer} index={index} inTopSheet />
      </Group>

      <AddNote />

      {Object.keys(activities).length > 0 ? (
        Object.keys(activities).map((date) => (
          <ActivityGroup key={date} date={date} group={activities[date]} />
        ))
      ) : (
        <Text c={"dimmed"} mt={32} ta={"center"}>
          {lang.prm.details.no_activity}
        </Text>
      )}
    </Stack>
  );
};

export default ProspectActivities;
