import {
  ActionIcon,
  Button,
  Group,
  Popover,
  Stack,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { useLanguage } from "../../../stores/LanguageStore";
import { useState } from "react";
import { createFolder } from "../../../api/FolderAPI";
import { useUIStore } from "../../../stores/UIStore";
import { createPool } from "../../../api/EmailAPI";

const CreateFolder = ({ type, isPool }) => {
  const lang = useLanguage((s) => s.language);
  const setAsideLoading = useUIStore((s) => s.setAsideLoading);

  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const fd = new FormData(e.target);
    const data = Object.fromEntries(fd);

    setLoading(true);
    const main = isPool ? createPool : createFolder;
    main({ name: data.name, type })
      .then(() => {
        setOpened(false);
        setTimeout(() => {
          setAsideLoading(true);
        }, 100);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Popover trapFocus withArrow opened={opened} onChange={setOpened}>
      <Popover.Target>
        <Tooltip
          label={
            isPool
              ? lang.pools.create.dialog.title
              : lang.components.folder_navigator.create.new
          }
          withArrow
        >
          <ActionIcon
            size={"sm"}
            variant="light"
            onClick={() => setOpened(true)}
          >
            <IconPlus size={16} />
          </ActionIcon>
        </Tooltip>
      </Popover.Target>

      <Popover.Dropdown>
        <Stack component={"form"} onSubmit={handleSubmit} w={200}>
          <TextInput
            label={
              isPool
                ? lang.pools.create.dialog.name
                : lang.components.folder_navigator.create.name
            }
            name="name"
            data-autoFocus
            required
          />
          <Group wrap="nowrap" justify="end">
            <Button
              size="compact-sm"
              variant="subtle"
              disabled={loading}
              onClick={() => setOpened(false)}
            >
              {lang.components.closer.submit}
            </Button>
            <Button
              type="submit"
              loading={loading}
              size="compact-sm"
              color="tertiary.8"
            >
              {lang.contact_lists.edit.dialog.submit_button}
            </Button>
          </Group>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
};

export default CreateFolder;
