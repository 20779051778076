import { Flex } from "@mantine/core";
import { useUIStore } from "../../stores/UIStore";
import AsideFolder from "../Aside/AsideFolder";
import FolderDeleteDrag from "./FolderDeleteDrag";

const TableLayoutBlock = ({
  children,
  folderType,
  parentRoute,
  editFunction,
  getEditFunction,
  apiRef,
  isPool,
  setTableLoading,
  wrapperProps,
  folderId,
  onFolderItemClick,
}) => {
  const layoutIsVertical = useUIStore((s) => s.layoutIsVertical);

  return (
    <>
      <Flex
        className="layout-block"
        w={"calc(100% - 32px)"}
        gap={0}
        p={0}
        mx={16}
        style={{
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderBottom: 0,
          // borderLeftWidth: folderType ? 1 : 0,
        }}
        flex={"1 1 auto"}
        h={0}
        {...wrapperProps}
      >
        {folderType && layoutIsVertical && (
          <AsideFolder
            isInMain
            type={folderType}
            parentRoute={parentRoute}
            editFunction={editFunction}
            getEditFunction={getEditFunction}
            apiRef={apiRef}
            isPool={isPool}
            setTableLoading={setTableLoading}
            actualFolderId={folderId}
            onFolderItemClick={onFolderItemClick}
          />
        )}
        {children}
      </Flex>

      {folderType && (
        <FolderDeleteDrag
          getEditFunction={getEditFunction}
          editFunction={editFunction}
          apiRef={apiRef}
        />
      )}
    </>
  );
};

export default TableLayoutBlock;
