import { Divider, MenuItem, Stack } from "@mantine/core";
import {
  getNodeDetails,
  nodeActions,
  nodeSecondaryActions,
} from "../flowSettings";

const ActionsList = ({ handleActionClick, noSecondary }) => {
  return (
    <Stack gap={4}>
      {nodeActions.map((node) => {
        const nodeDetails = getNodeDetails(node);

        return (
          <MenuItem
            key={node}
            leftSection={nodeDetails.icon}
            justify="flex-start"
            variant="light"
            onClick={(e) => {
              e.stopPropagation();
              handleActionClick(node);
            }}
            w={"100%"}
            color={nodeDetails.color}
          >
            {nodeDetails.label}
          </MenuItem>
        );
      })}
      {!noSecondary && (
        <>
          <Divider />
          {nodeSecondaryActions.map((node) => {
            const nodeDetails = getNodeDetails(node);

            return (
              <MenuItem
                key={node}
                leftSection={nodeDetails.icon}
                justify="flex-start"
                variant="light"
                onClick={(e) => {
                  e.stopPropagation();
                  handleActionClick(node);
                }}
                w={"100%"}
                color={nodeDetails.color}
              >
                {nodeDetails.label}
              </MenuItem>
            );
          })}
        </>
      )}
    </Stack>
  );
};

export default ActionsList;
