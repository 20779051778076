import { Button, Center, Text } from "@mantine/core";
import successAnim from "../../../assets/lotties/success.json";
import { Logo } from "../../../layouts/Logo";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../../stores/LanguageStore";
import { lazy } from "react";

const Lottie = lazy(() => import("react-lottie"));

const AccountVerifiedPage = () => {
  const lang = useLanguage((s) => s.language);
  const navigate = useNavigate();
  return (
    <Center
      w={"60vw"}
      h={"55vh"}
      style={{ flexDirection: "column" }}
      mx={"auto"}
      mt={128}
    >
      <Logo w={200} mb={64} />
      <Lottie
        options={{
          animationData: successAnim,
          autoplay: true,
          loop: true,
        }}
        height={150}
      />
      <Text fz={28} ta={"center"}>
        Votre compte a été vérifié avec succès <br />{" "}
        <span style={{ fontSize: 22 }}>
          {" "}
          Vous pouvez maintenant vous connecter.
        </span>
      </Text>

      <Button size="md" h={52} mt={"md"} onClick={() => navigate("/login")}>
        {lang.password_recovery.step_finish.button_label}
      </Button>
    </Center>
  );
};

export default AccountVerifiedPage;
