import {
  IconActivity,
  IconBrandGoogleMaps,
  IconBrandLinkedin,
  IconBrandTwitter,
  IconBriefcase,
  IconBuildingCommunity,
  IconCirclePlus,
  IconClipboardCheck,
  IconDeviceMobile,
  IconHome,
  IconLanguage,
  IconMail,
  IconMan,
  IconMapPin,
  IconNumber,
  IconPasswordUser,
  IconPhone,
  IconShare3,
  IconSitemap,
  IconUser,
  IconWorld,
  IconWorldWww,
} from "@tabler/icons-react";
import { useLanguage } from "../../../stores/LanguageStore";
import { Tooltip } from "@mantine/core";

export function getFieldIcon(field, fromSharing, created) {
  const lang = useLanguage.getState().language;
  const iconProps = { size: 18 };

  if (fromSharing) {
    return (
      <Tooltip label={lang.prm.details.from_sharing}>
        <IconShare3 {...iconProps} />
      </Tooltip>
    );
  }
  if (created) {
    return (
      <Tooltip label={lang.prm.details.custom_column}>
        <IconCirclePlus {...iconProps} />
      </Tooltip>
    );
  }
  if (field.includes("mail")) {
    return <IconMail {...iconProps} />;
  }
  if (field.includes("name")) {
    return <IconUser {...iconProps} />;
  }
  if (field.includes("sex")) {
    return <IconMan {...iconProps} />;
  }
  if (field.includes("mobile_phone")) {
    return <IconDeviceMobile {...iconProps} />;
  }
  if (field.includes("phone")) {
    return <IconPhone {...iconProps} />;
  }
  if (field.includes("%address%")) {
    return <IconHome {...iconProps} />;
  }
  if (field.includes("%company%")) {
    return <IconBuildingCommunity {...iconProps} />;
  }
  if (field.includes("%company_address%")) {
    return <IconMapPin {...iconProps} />;
  }
  if (field.includes("code")) {
    return <IconNumber {...iconProps} />;
  }
  if (field.includes("ident")) {
    return <IconNumber {...iconProps} />;
  }
  if (field.includes("activity")) {
    return <IconActivity {...iconProps} />;
  }
  if (field.includes("size")) {
    return <IconSitemap {...iconProps} />;
  }
  if (field.includes("city")) {
    return <IconMapPin {...iconProps} />;
  }
  if (field.includes("country")) {
    return <IconWorld {...iconProps} />;
  }
  if (field.includes("%job_title%")) {
    return <IconBriefcase {...iconProps} />;
  }
  if (field.includes("linkedin")) {
    return <IconBrandLinkedin {...iconProps} />;
  }
  if (field.includes("twitter")) {
    return <IconBrandTwitter {...iconProps} />;
  }
  if (field.includes("sire")) {
    return <IconNumber {...iconProps} />;
  }
  if (field.includes("website")) {
    return <IconWorldWww {...iconProps} />;
  }
  if (field.includes("language")) {
    return <IconLanguage {...iconProps} />;
  }
  if (field.includes("password")) {
    return <IconPasswordUser {...iconProps} />;
  }
  if (field.includes("role")) {
    return <IconClipboardCheck {...iconProps} />;
  }
  if (field.includes("location")) {
    return <IconMapPin {...iconProps} />;
  }
  if (field.includes("gmaps_link")) {
    return <IconBrandGoogleMaps {...iconProps} />;
  }
  return <IconUser {...iconProps} />;
}
