import {
  Blockquote,
  Button,
  Group,
  LoadingOverlay,
  Modal,
  NumberFormatter,
  Stack,
  Text,
  ThemeIcon,
} from "@mantine/core";
import { useLanguage } from "../../../stores/LanguageStore";
import BackButton from "../../../components/View/BackButton";
import { useMemo, useState } from "react";
import { IconLinkedin } from "../../../components/Icons/IconLinkedin";
import {
  IconArrowNarrowRight,
  IconEdit,
  IconMail,
  IconShoppingCartCheck,
  IconUserSearch,
} from "@tabler/icons-react";
import { addOptions, removeOptions } from "../../../api/SubscriptionAPI";
import { notifications } from "@mantine/notifications";

const ChangeValidation = ({
  email,
  linkedin,
  enrich,
  defaultValues,
  edited,
  opened,
  setOpened,
  price,
  defaultPrice,
  productsMap,
  reccurence,
  setMainLoading,
}) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpened(false);
  };

  const handleValidate = async () => {
    let toAdd = [];
    let toRemove = [];

    // LinkedIn
    if (edited.includes("linkedin")) {
      if (linkedin > defaultValues?.linkedin) {
        toAdd.push({
          price_id: productsMap.linkedin_product.price_id,
          quantity: linkedin - defaultValues?.linkedin,
        });
      }
      if (linkedin < defaultValues?.linkedin) {
        toRemove.push({
          price_id: productsMap.linkedin_product.price_id,
          quantity: defaultValues?.linkedin - linkedin,
        });
      }
    }
    // Email
    if (edited.includes("email")) {
      if (email * 5 > defaultValues?.email) {
        toAdd.push({
          price_id: productsMap.email_product.price_id,
          quantity: (email * 5 - defaultValues?.email) / 5,
        });
      }
      if (email * 5 < defaultValues?.email) {
        toRemove.push({
          price_id: productsMap.email_product.price_id,
          quantity: (defaultValues?.email - email * 5) / 5,
        });
      }
    }
    // Enrich
    if (edited.includes("enrich")) {
      if (enrich * 1000 > defaultValues?.enrich) {
        toAdd.push({
          price_id: productsMap.enrich_product.price_id,
          quantity: (enrich * 1000 - defaultValues?.enrich) / 1000,
        });
      }
      if (enrich * 1000 < defaultValues?.enrich) {
        toRemove.push({
          price_id: productsMap.enrich_product.price_id,
          quantity: (defaultValues?.enrich - enrich * 1000) / 1000,
        });
      }
    }

    setLoading(true);
    let added, removed;
    if (toAdd.length > 0) {
      added = await addOptions({ items: toAdd }).catch(() => setLoading(false));
    }
    if (toRemove.length > 0) {
      removed = await removeOptions({ items: toRemove }).catch(() =>
        setLoading(false)
      );
    }

    if (added || removed) {
      setOpened(false);
      setMainLoading(true);
      setLoading(false);

      notifications.show({
        message: lang.pricing.changed,
        color: "green.8",
      });
    }
  };

  const isPayment = useMemo(() => {
    if (linkedin > defaultValues?.linkedin) {
      return true
    }
    if (email * 5 > defaultValues?.email) {
      return true
    }
    if (enrich * 1000 > defaultValues?.enrich) {
      return true
    }
    return false
  }, [defaultValues?.email, defaultValues?.enrich, defaultValues?.linkedin, email, enrich, linkedin])

  return opened ? (
    <Modal
      opened={opened}
      size={"xl"}
      title={lang.pricing.change_title}
      onClose={handleClose}
      p={"md"}
      styles={{
        content: {
          padding: 8,
        },
      }}
    >
      <Stack pos={"relative"}>
        <LoadingOverlay visible={loading} />
        <Stack gap={"sm"}>
          <Text size="lg">{lang.pricing.change_text}</Text>
          <Group w={"100%"} justify="space-between">
            <Stack>
              <Group>
                <ThemeIcon color="orange" variant="subtle">
                  <IconLinkedin size={20} />
                </ThemeIcon>
                <Text
                  size="lg"
                  style={{
                    textDecoration: edited?.includes("linkedin")
                      ? "line-through"
                      : "none",
                  }}
                >
                  <b>{defaultValues?.linkedin}</b>{" "}
                  {lang.pricing.linkedin_accounts}
                </Text>
              </Group>
              <Group>
                <ThemeIcon color="orange" variant="subtle">
                  <IconMail size={20} />
                </ThemeIcon>
                <Text
                  size="lg"
                  style={{
                    textDecoration: edited.includes("email")
                      ? "line-through"
                      : "none",
                  }}
                >
                  <b>{defaultValues?.email}</b> {lang.pricing.emails}
                </Text>
              </Group>
              <Group>
                <ThemeIcon color="tertiary.8" variant="subtle">
                  <IconUserSearch size={20} />
                </ThemeIcon>
                <Text
                  size="lg"
                  style={{
                    textDecoration: edited.includes("enrich")
                      ? "line-through"
                      : "none",
                  }}
                >
                  <b>{defaultValues?.enrich}</b> {lang.pricing.enrich_monthly}
                </Text>
              </Group>
            </Stack>
            <Stack>
              <Group h={32}>
                {edited.includes("linkedin") && (
                  <IconArrowNarrowRight size={32} />
                )}
              </Group>
              <Group h={32}>
                {edited.includes("email") && <IconArrowNarrowRight size={32} />}
              </Group>
              <Group h={32}>
                {edited.includes("enrich") && (
                  <IconArrowNarrowRight size={32} />
                )}
              </Group>
            </Stack>
            <Stack>
              <Group>
                <ThemeIcon color="orange" variant="subtle">
                  <IconLinkedin size={20} />
                </ThemeIcon>
                <Text size="lg">
                  <b>
                    {edited.includes("linkedin")
                      ? linkedin
                      : defaultValues?.linkedin}
                  </b>{" "}
                  {lang.pricing.linkedin_accounts}
                </Text>
              </Group>
              <Group>
                <ThemeIcon color="orange" variant="subtle">
                  <IconMail size={20} />
                </ThemeIcon>
                <Text size="lg">
                  <b>
                    {edited.includes("email")
                      ? email * 5
                      : defaultValues?.email}
                  </b>{" "}
                  {lang.pricing.emails}
                </Text>
              </Group>
              <Group>
                <ThemeIcon color="tertiary.8" variant="subtle">
                  <IconUserSearch size={20} />
                </ThemeIcon>
                <Text size="lg">
                  <b>
                    {edited.includes("enrich")
                      ? enrich * 1000
                      : defaultValues?.enrich}
                  </b>{" "}
                  {lang.pricing.enrich_monthly}
                </Text>
              </Group>
            </Stack>
          </Group>
          <Group gap={96} w={"100%"} justify="center" my={"md"}>
            <Text size="lg">
              <b
                style={{
                  fontSize: 32,
                  marginRight: 8,
                  textDecoration: "line-through",
                }}
              >
                <NumberFormatter value={defaultPrice} />€
              </b>{" "}
              {reccurence === "monthly"
                ? lang.pricing.monthly_payment
                : lang.pricing.annual_payment}
            </Text>
            <IconArrowNarrowRight size={32} />
            <Text size="lg">
              <b style={{ fontSize: 32, marginRight: 8 }}>
                <NumberFormatter value={price} />€
              </b>{" "}
              {reccurence === "monthly"
                ? lang.pricing.monthly_payment
                : lang.pricing.annual_payment}
            </Text>
          </Group>
          <Blockquote py={"sm"} ml={-24} mr={-24} pl={"md"}>
            <Text>{lang.pricing.alert1}</Text>

            <Text mt={16}>{lang.pricing.alert2}</Text>
          </Blockquote>
        </Stack>
        <Group mt={"md"} justify="end">
          <BackButton onClick={handleClose} />

          <Button
            onClick={handleValidate}
            leftSection={isPayment ? <IconShoppingCartCheck size={18} /> : <IconEdit size={18} />}
            loading={loading}
          >

            {isPayment ? lang.pricing.validate : lang.pricing.edit_payment}
          </Button>
        </Group>
      </Stack>
    </Modal>
  ) : null;
};

export default ChangeValidation;
