import { Select } from "@mantine/core";
import { useFlowStore } from "../../../../../../../stores/WorkflowStore";
import { useLanguage } from "../../../../../../../stores/LanguageStore";
import { getEvents } from "./events";
import LinksSelector from "./LinksSelector";
import { useEffect, useMemo } from "react";
import EventName from "./EventName";
import { useAccountsStore } from "../../../../../../../stores/ConfigStore";
import { getNodeIdsOfChildren } from "../../components/NodeDeleter";

const EventSelector = ({
  id,
  event_type,
  event_name,
  nodeSource,
  which_links_clicked,
  sourceModel,
  goodEvent,
  messagesAreOnlyChildren,
  disabled,
}) => {
  const lang = useLanguage((s) => s.language);
  const workflowProfile = useFlowStore((s) => s.profile);
  const updateNodeData = useFlowStore((s) => s.updateNodeData);

  const handleEventChange = (newEvent) => {
    updateNodeData(id, { event_type: newEvent, name: null });
  };

  useEffect(() => {
    if (!goodEvent) {
      updateNodeData(id, {
        event_type: null,
        which_links_clicked: [],
        name: null,
      });
    }
  }, [goodEvent, updateNodeData, id]);

  const isError = useMemo(() => {
    if (nodeSource?.data?.actionType === "email") {
      const emails = useAccountsStore.getState().email_accounts;
      const email_pools = useAccountsStore.getState().email_pools;
      const integration = emails.find(
        (x) => x.id === nodeSource?.data?.integration_id
      );
      const pool = email_pools.find((x) => x.id === nodeSource?.data?.pool_id);

      if (integration) {
        if (
          !integration?.enable_tracking &&
          ["link_click", "email_open", "email_open_no_click"].includes(
            event_type
          )
        ) {
          return lang.workflows.nodes.no_tracking;
        }
        if (
          !integration?.can_read_responses &&
          event_type === "email_answered"
        ) {
          return lang.workflows.nodes.no_imap;
        }
      }

      if (pool) {
        if (
          !pool.accounts_list.find((x) => x?.enable_tracking) &&
          ["link_click", "email_open", "email_open_no_click"].includes(
            event_type
          )
        ) {
          return lang.workflows.nodes.no_tracking;
        }
        if (
          !pool.accounts_list.find((x) => x?.can_read_responses) &&
          event_type === "email_answered"
        ) {
          return lang.workflows.nodes.no_imap;
        }
      }
    }

    return !event_type;
  }, [
    nodeSource?.data?.actionType,
    nodeSource?.data?.integration_id,
    nodeSource?.data?.pool_id,
    event_type,
    lang.workflows.nodes.no_tracking,
    lang.workflows.nodes.no_imap,
  ]);

  useEffect(() => {
    if (!nodeSource?.data?.error) {
      const updateNodeData = useFlowStore.getState().updateNodeData;
      const edges = useFlowStore.getState().edges;
      const nodes = useFlowStore.getState().nodes;
      let childrenIds = getNodeIdsOfChildren(edges, id, nodes);
      childrenIds.forEach((childId) => {
        updateNodeData(childId, { error: isError });
      });
    }
  }, [id, isError, nodeSource]);

  return nodeSource?.data?.actionType === "call" ? (
    <EventName event_name={event_name} goodEvent={goodEvent} id={id} />
  ) : (
    <Select
      label={lang.workflows.nodes.event_label}
      value={goodEvent ? event_type : null}
      onChange={handleEventChange}
      size="xs"
      required
      disabled={disabled}
      placeholder={lang.campaign.selectors.event.placeholder}
      data={getEvents(
        nodeSource?.data?.actionType,
        !!workflowProfile?.auto_remove_responders,
        messagesAreOnlyChildren
      )?.map((e) => ({
        ...e,
        disabled:
          e.value === "link_click"
            ? sourceModel?.links?.length === 0 || !sourceModel
            : e.disabled,
      }))}
      searchable
      maxDropdownHeight={200}
      w={"100%"}
      error={isError}
      mt={-3}
      comboboxProps={{
        size: "sm",
      }}
      rightSectionPointerEvents="all"
      rightSection={
        event_type === "link_click" && (
          <LinksSelector
            id={id}
            sourceModel={sourceModel}
            which_links_clicked={which_links_clicked}
          />
        )
      }
    />
  );
};

export default EventSelector;
