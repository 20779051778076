import { ActionIcon, Tooltip } from "@mantine/core";
import { IconEdit, IconPlus } from "@tabler/icons-react";
import PopOver from "../../../components/View/PopOver";
import { useLanguage } from "../../../stores/LanguageStore";

const ColumnEditor = ({ dropDownComponent, style, disabled, isAdd }) => {
  const lang = useLanguage((s) => s.language);
  return (
    <PopOver
      opener={(openFunction, ref, opened) => {
        return (
          <Tooltip
            label={
              isAdd ? lang.components.sharing.add : lang.tags.edit.button_label
            }
          >
            <ActionIcon
              variant="light"
              ref={ref}
              size={"sm"}
              color="primary"
              className="hover-target"
              onClick={openFunction}
              style={{
                position: "absolute",
                right: 0,
                background: "white",
                boxShadow: "#00000033 0 2px 5px",
                opacity: opened ? 1 : undefined,
                ...style,
              }}
              disabled={disabled}
            >
              {isAdd ? <IconPlus size={16} /> : <IconEdit size={16} />}
            </ActionIcon>
          </Tooltip>
        );
      }}
      popoverChildren={(closePopover) => dropDownComponent(closePopover)}
      elevation={3}
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      transformOrigin={{ horizontal: "center", vertical: "top" }}
    />
  );
};

export default ColumnEditor;
