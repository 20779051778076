import { useEffect, useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { getSharings } from "../../../../../api/PRMAPI";
import BackButton from "../../../../../components/View/BackButton";
import { Group, LoadingOverlay, Stack, Text } from "@mantine/core";
import LeadsSharingCreate from "./LeadsSharingCreate";
import LeadsSharingItem from "./LeadsSharingItem";

const LeadsSharingContent = ({ handleClose }) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (loading) {
      getSharings()
        .then((response) => {
          let newSharings = response.data.sharings;
          setItems(newSharings);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [loading]);

  return (
    <>
      <Stack pos={"relative"} gap={"xs"} h={"100%"}>
        <LoadingOverlay visible={loading} />

        <Text c={"dimmed"}>{lang.prm.sharing.text}</Text>

        {items.length > 0 ? (
          <Stack gap={"lg"} h={"64vh"} style={{ overflow: "auto" }}>
            {items.map((item, index) => {
              return (
                <LeadsSharingItem
                  share={item}
                  key={item.id}
                  handleReload={() => setLoading(true)}
                  setItems={setItems}
                />
              );
            })}
          </Stack>
        ) : (
          <Text c={"dimmed"} size="lg" ta={"center"}>
            {lang.prm.sharing.no_sharing}
          </Text>
        )}
      </Stack>

      <Group justify="end" mt={"md"}>
        <BackButton onClick={handleClose} />
        <LeadsSharingCreate handleDone={() => setLoading(true)} />
      </Group>
    </>
  );
};

export default LeadsSharingContent;
