import { mainAxios as axios } from "./axios";

export const getFiles = async () => {
  return axios.get(`/files`);
};

export const findFile = async (id) => {
  return axios.get(`/files/${id}`);
};

export const createFile = async ({ file, name, sharing, folder_id }) => {
  let formData = new FormData();
  let parsedSharing = sharing?.map((x) => JSON.stringify(x));
  if (file) formData.append("file", file);
  if (name) formData.append("name", name || null);
  if (sharing) formData.append("sharing", parsedSharing);
  if (folder_id) formData.append("folder_id", folder_id);

  return axios.post(`/files`, formData, {
    headers: {
      accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  });
};

export const editFile = async ({ id, file, name, sharing, folder_id }) => {
  let formData = new FormData();
  let parsedSharing = sharing?.map((x) => JSON.stringify(x));
  if (file) formData.append("file", file);
  if (name) formData.append("name", name || null);
  if (sharing) formData.append("sharing", parsedSharing);
  if (folder_id) formData.append("folder_id", folder_id);

  return axios.post(`/files/${id}`, formData, {
    headers: {
      accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteFile = async ({ id }) => {
  return axios.delete(`/files/${id}`);
};

export const searchFile = async ({ per_page, sort, filter, query }) => {
  return axios.post(
    `/files/search`,
    {
      query: query,
    },
    {
      params: {
        options: {
          per_page: per_page,
          filter: filter,
          sort: sort,
        },
      },
    }
  );
};
