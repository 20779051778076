import { useEffect, useState } from "react";
import { getNurturings } from "../../../../../api/PRMAPI";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { Group, LoadingOverlay, Stack, Text } from "@mantine/core";
import BackButton from "../../../../../components/View/BackButton";
import CreateNurturing from "./CreateNurturing";
import NurturingItem from "./NurturingItem";
import { getLists } from "../../../../../api/ContactAPI";
import NurturingHowItWorks from "./NurturingHowItWorks";

const NurturingContent = ({ handleClose }) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (loading) {
      getNurturings()
        .then((response) => {
          setItems(response.data.nurturings.sort((a, b) => b.id - a.id));
          getLists();
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [loading]);

  return (
    <>
      <Stack pos={"relative"} gap={"xs"} h={"100%"} mih={300}>
        <LoadingOverlay visible={loading} />

        <Text c={"dimmed"}>{lang.prm.nurturing.main_text}</Text>

        {items.length > 0 ? (
          <Stack gap={"lg"} h={"64vh"} style={{ overflow: "auto" }}>
            {items.map((item, index) => {
              return (
                <NurturingItem
                  key={item.id}
                  item={item}
                  handleReload={() => setLoading(true)}
                />
              );
            })}
          </Stack>
        ) : (
          <Text c={"dimmed"} size="lg" ta={"center"} mt={64}>
            {lang.prm.nurturing.no_nurturings}
          </Text>
        )}
      </Stack>

      <Group justify="end" mt={"md"}>
        <NurturingHowItWorks />
        <BackButton onClick={handleClose} />
        <CreateNurturing handleDone={() => setLoading(true)} />
      </Group>
    </>
  );
};

export default NurturingContent;
