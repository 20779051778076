import { Skeleton, Stack } from "@mantine/core";

const ContainerLoader = () => {
  return (
    <Stack flex={"1 1 auto"} style={{ overflow: "auto" }}>
      <Stack
        align="center"
        justify="flex-start"
        w="100%"
        h={"100%"}
        mt={8}
        px={"sm"}
      >
        {Array(3)
          .fill(true)
          .map((x, i) => (
            <Skeleton
              key={i}
              w={"100%"}
              height={94}
              radius={"sm"}
              opacity={1 - i * 0.25}
            />
          ))}
      </Stack>
    </Stack>
  );
};

export default ContainerLoader;
