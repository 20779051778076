import { useState } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useDisclosure } from "@mantine/hooks";
import { Button, Group, Modal, Stack, Text } from "@mantine/core";
import BackButton from "../../../../components/View/BackButton";
import { IconLanguage } from "@tabler/icons-react";
import { useProfileStore } from "../../../../stores/UserStore";
import { translateList } from "../../../../api/ContactAPI";
import { notifications } from "@mantine/notifications";
import GlobalLanguageSelector from "../../../../components/Selectors/GlobalLanguageSelector";

const ListTranslate = ({ current, onFinish, closeMenu, hidden }) => {
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);
  const [opened, { open, close }] = useDisclosure();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    let fd = new FormData(e.target);
    let data = Object.fromEntries(fd);

    setLoading(true);
    translateList({
      contact_list_id: current.id,
      language: data.language,
    })
      .then((res) => {
        notifications.show({
          message: lang.contact_lists.translate.success,
          color: "tertiary.8",
        });
        close();
        onFinish?.();
      })
      .finally(() => setLoading(false));
  };

  const handleClose = () => {
    close();
    closeMenu?.();
  };

  return (
    <>
      <Button
        id="list-translate-button"
        onClick={open}
        leftSection={<IconLanguage size={18} />}
        fw={600}
        color="primary"
        variant="subtle"
        justify="start"
        display={hidden ? "none" : "flex"}
      >
        {lang.contact_lists.translate.label}
      </Button>

      {opened && (
        <Modal
          opened={opened}
          size={"md"}
          onClose={handleClose}
          title={
            <>
              {lang.contact_lists.translate.dialog.title}{" "}
              <b>
                {current?.name} #{current.id}
              </b>
            </>
          }
        >
          <Stack component={"form"} onSubmit={handleSubmit} gap={"xs"}>
            <Text c={"dimmed"}>{lang.contact_lists.translate.dialog.text}</Text>

            <GlobalLanguageSelector
              name={"language"}
              w="100%"
              size="sm"
              defaultValue={current?.language || profile?.contact_list_language}
            />

            <Group justify="end" mt={"md"}>
              <BackButton onClick={handleClose} />

              <Button
                leftSection={<IconLanguage size={18} />}
                loading={loading}
                color="primary"
                type="submit"
              >
                {lang.contact_lists.translate.dialog.submit_button}
              </Button>
            </Group>
          </Stack>
        </Modal>
      )}
    </>
  );
};

export default ListTranslate;
