import { useEffect, useLayoutEffect, useState } from "react";

export const useTableHighlight = ({
  apiRef,
  loading,
  defaultHighlight,
  setDefaultHighlight,
  treeData,
}) => {
  const [done, setDone] = useState(false);

  useLayoutEffect(() => {
    const params = new URLSearchParams(location.search);
    const highlight = params.get("highlight");

    if (!loading && !done)
      setTimeout(() => {
        if (highlight || defaultHighlight) {
          document
            .querySelectorAll(`[data-id='${highlight || defaultHighlight}']`)
            .forEach((el) => {
              el.style.animation = `forwards .6s highlightAnim ease`;
              el.style.animationIterationCount = "3";
              setTimeout(() => {
                el.style.animation = `none`;
                setDone(true);
                setDefaultHighlight?.(null);
              }, 1800);
            });

          // Expand when treeData
          if (treeData) {
            return;
            // apiRef.current.setRowChildrenExpansion(parseInt(highlight), true);
          }
        }
      }, 400);
  }, [loading, done, defaultHighlight, treeData, setDefaultHighlight, apiRef]);

  useEffect(() => {
    if (defaultHighlight) setDone(false);
  }, [defaultHighlight]);

  return;
};
