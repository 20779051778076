import { Box, Button, Group, Stack, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useThemeStore } from "../../../../stores/ThemeStore";
import BackButton from "../../../../components/View/BackButton";
import { IconHelp } from "@tabler/icons-react";

const Illustration = () => {
  const theme = useThemeStore((s) => s.theme);
  const mainColor = theme.colors.secondary[6];

  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 738.21997 557.74675"
    >
      <path
        id="a9afa624-d143-4da3-9790-afb66062e65a-182"
        data-name="Path 2429"
        d="M535.17415,599.59713c0,19.24928-14.948,34.85393-33.38792,34.85393s-33.38792-15.60461-33.38792-34.85393c0-.43634.00676-.86964.0246-1.3023.33849-19.24559,15.56341-34.5554,33.99969-34.196s33.10172,16.2527,32.75707,35.49829Z"
        transform="translate(-230.89001 -213.15682)"
        fill={mainColor}
      />
      <path
        id="e3bc7ff1-d70c-4de9-8a31-9e1cd8f5d93e-183"
        data-name="Path 2431"
        d="M490.02877,615.07623l-9.61633-12.8222,2.27715-1.7091,7.83463,10.44595,30.84427-25.23327,1.80325,2.20391Z"
        transform="translate(-230.89001 -213.15682)"
        fill="#fff"
      />
      <rect
        id="a7b62467-cb6d-4a33-bede-3daf66fae9b8"
        data-name="Rectangle 256"
        y="555.74672"
        width="738.21997"
        height="2"
        fill="#e6e6e6"
      />
      <g id="a0df80b4-37f6-4e81-b192-ad058c9b46d3" data-name="Group 24">
        <path
          id="ab0ed44f-d533-4992-a1b3-ca9ac72248ac-184"
          data-name="Path 552"
          d="M716.948,409.11358a14.21117,14.21117,0,0,1,1.987,1.108l63.154-26.954,2.253-16.636,25.235-.155-1.491,38.158-83.357,22.047a14.94008,14.94008,0,0,1-.631,1.7,14.411,14.411,0,1,1-7.151-19.265Z"
          transform="translate(-230.89001 -213.15682)"
          fill="#a0616a"
        />
        <path
          id="e96706e1-851e-4da2-ad9b-ad313a8c64fc-185"
          data-name="Path 553"
          d="M818.754,754.87454H801.491l-8.212-66.584h25.477Z"
          transform="translate(-230.89001 -213.15682)"
          fill="#a0616a"
        />
        <path
          id="b540b71d-0f03-4ed3-b276-b3415efc1443-186"
          data-name="Path 554"
          d="M789.161,749.94255h33.292v20.955H768.199a20.962,20.962,0,0,1,20.962-20.962h0Z"
          transform="translate(-230.89001 -213.15682)"
          fill="#2f2e41"
        />
        <path
          id="b87c34d6-a0a3-40d8-a436-08cd71fc342e-187"
          data-name="Path 555"
          d="M885.89,749.99658l-17.178,1.69-14.7-65.458,25.355-2.491Z"
          transform="translate(-230.89001 -213.15682)"
          fill="#a0616a"
        />
        <path
          id="bb56473f-8578-4371-8d17-16b3f8ffc95c-188"
          data-name="Path 556"
          d="M855.946,747.99257l33.126-3.263,2.054,20.857-53.981,5.317a20.958,20.958,0,0,1,18.8-22.911Z"
          transform="translate(-230.89001 -213.15682)"
          fill="#2f2e41"
        />
        <circle
          id="b06ede21-2df7-4399-95f3-667c473f6547"
          data-name="Ellipse 84"
          cx="592.669"
          cy="48.26774"
          r="34.583"
          fill="#a0616a"
        />
        <path
          id="e3edd339-fa60-4375-a5a7-b8711a5f77a0-189"
          data-name="Path 557"
          d="M797.633,741.40854a6.3,6.3,0,0,1-6.217-5.21c-8.929-49.59-38.141-211.772-38.84-216.268a1.99063,1.99063,0,0,1-.023-.312V507.52456a2.1,2.1,0,0,1,.393-1.228l3.858-5.4a2.082,2.082,0,0,1,1.611-.88c22-1.031,94.036-4.054,97.516.294h0c3.495,4.365,2.253,17.611,1.971,20.22l.014.272,32.366,206.98211a6.353,6.353,0,0,1-5.233,7.23l-20.214,3.33a6.366,6.366,0,0,1-7.076-4.355c-6.252-19.978-27.216-87.184-34.482-113.19a.7.7,0,0,0-1.381.2c.363,24.79,1.24,88.036,1.549,109.881l.032,2.353a6.362,6.362,0,0,1-5.763,6.387l-19.492,1.766A5.8754,5.8754,0,0,1,797.633,741.40854Z"
          transform="translate(-230.89001 -213.15682)"
          fill="#2f2e41"
        />
        <path
          id="fafafea0-d187-42ce-9adc-60692d5c21c2-190"
          data-name="Path 99"
          d="M801.611,309.60656c-6.035,3.588-9.647,10.18-11.719,16.9a160.07254,160.07254,0,0,0-6.877,38.241l-2.191,38.86-27.113,103.028c23.5,19.883,37.053,15.363,68.686-.9s35.246,5.422,35.246,5.422l6.325-87.663,9.037-95.793a42.48654,42.48654,0,0,0-6.846-6.581,69.923,69.923,0,0,0-59.761-12.673Z"
          transform="translate(-230.89001 -213.15682)"
          fill={mainColor}
        />
        <path
          id="bcb42d09-5dc7-4cce-9178-bbabc9608de0-191"
          data-name="Path 558"
          d="M792.805,429.92056a14.82766,14.82766,0,0,1,2.112.986l62.447-31.259,1.036-16.933,25.759-1.776,1.38,38.6-83.45,27.6a14.784,14.784,0,1,1-9.283-17.223Z"
          transform="translate(-230.89001 -213.15682)"
          fill="#a0616a"
        />
        <path
          id="a6b68414-708f-47b2-ba8b-69f9e74523ce-192"
          data-name="Path 101"
          d="M872.555,328.13256c15.363,5.422,18.071,64.171,18.071,64.171-18.075-9.941-39.765,6.326-39.765,6.326s-4.518-15.365-9.941-35.246a34.54,34.54,0,0,1,7.229-32.535S857.19,322.70758,872.555,328.13256Z"
          transform="translate(-230.89001 -213.15682)"
          fill={mainColor}
        />
        <path
          id="bcacc9ce-a4f2-4033-9198-64d9603f2d9b-193"
          data-name="Path 102"
          d="M854.562,270.56756c-4.309-3.447-10.187,2.816-10.187,2.816l-3.447-31.016s-21.543,2.581-35.334-.862-15.943,12.5-15.943,12.5a110.65357,110.65357,0,0,1-.431-19.391c.862-7.756,12.064-15.512,31.885-20.683s30.162,17.236,30.162,17.236C865.063,238.05658,858.876,274.01858,854.562,270.56756Z"
          transform="translate(-230.89001 -213.15682)"
          fill="#2f2e41"
        />
      </g>
      <path
        d="M392.01957,258.70856a3.10716,3.10716,0,0,0,0,6.21431H618.87829a3.10716,3.10716,0,0,0,0-6.21431Z"
        transform="translate(-230.89001 -213.15682)"
        fill="#ccc"
      />
      <path
        d="M392.01957,277.35146a3.10717,3.10717,0,0,0-.01311,6.21432H535.42889a3.10716,3.10716,0,1,0,0-6.21432Z"
        transform="translate(-230.89001 -213.15682)"
        fill="#ccc"
      />
      <path
        id="baa6ea24-e79a-410b-95a0-90b7a92ae1c1-194"
        data-name="Path 2429"
        d="M616.03488,234.95609c0,7.53426-5.8507,13.642-13.06819,13.642s-13.0682-6.10772-13.0682-13.642c0-.17079.00265-.34038.00963-.50973.13249-7.53282,6.09159-13.52515,13.30764-13.38447s12.95618,6.36138,12.82128,13.8942Z"
        transform="translate(-230.89001 -213.15682)"
        fill={mainColor}
      />
      <path
        id="a0820f47-ad40-463a-872c-13bb80005143-195"
        data-name="Path 2431"
        d="M598.36476,241.01468l-3.76388-5.01867.89129-.669,3.06651,4.0886,12.0726-9.87642.7058.86262Z"
        transform="translate(-230.89001 -213.15682)"
        fill="#fff"
      />
      <path
        d="M392.01957,372.70856a3.10716,3.10716,0,0,0,0,6.21431H618.87829a3.10716,3.10716,0,0,0,0-6.21431Z"
        transform="translate(-230.89001 -213.15682)"
        fill="#ccc"
      />
      <path
        d="M392.01957,391.35146a3.10717,3.10717,0,0,0-.01311,6.21432H535.42889a3.10716,3.10716,0,1,0,0-6.21432Z"
        transform="translate(-230.89001 -213.15682)"
        fill="#ccc"
      />
      <path
        id="b63fc892-67cf-40d0-9003-f173040a559c-196"
        data-name="Path 2429"
        d="M615.03488,348.95609c0,7.53426-5.8507,13.642-13.06819,13.642s-13.0682-6.10772-13.0682-13.642c0-.17079.00265-.34038.00963-.50973.13249-7.53282,6.09159-13.52515,13.30764-13.38447s12.95618,6.36138,12.82128,13.8942Z"
        transform="translate(-230.89001 -213.15682)"
        fill={mainColor}
      />
      <path
        id="b8e61bbe-38fc-40ac-ac8c-5bb63bac7af2-197"
        data-name="Path 2431"
        d="M597.36476,355.01468l-3.76388-5.01867.89129-.66895,3.06651,4.0886,12.0726-9.87642.7058.86262Z"
        transform="translate(-230.89001 -213.15682)"
        fill="#fff"
      />
      <path
        d="M392.01957,486.70856a3.10716,3.10716,0,0,0,0,6.21431H618.87829a3.10716,3.10716,0,0,0,0-6.21431Z"
        transform="translate(-230.89001 -213.15682)"
        fill="#ccc"
      />
      <path
        d="M392.01957,505.35146a3.10717,3.10717,0,0,0-.01311,6.21432H535.42889a3.10716,3.10716,0,1,0,0-6.21432Z"
        transform="translate(-230.89001 -213.15682)"
        fill="#ccc"
      />
      <path
        id="f67391c6-f0f4-4d09-a78c-675b0edf0c3b-198"
        data-name="Path 2429"
        d="M615.03488,462.95609c0,7.53426-5.8507,13.642-13.06819,13.642s-13.0682-6.10772-13.0682-13.642c0-.17079.00265-.34038.00963-.50973.13249-7.53282,6.09159-13.52515,13.30764-13.38447s12.95618,6.36138,12.82128,13.8942Z"
        transform="translate(-230.89001 -213.15682)"
        fill={mainColor}
      />
      <path
        id="e94ecbaf-2ca7-4fd5-871d-ab097a8f2c0d-199"
        data-name="Path 2431"
        d="M597.36476,469.01468l-3.76388-5.01867.89129-.66895,3.06651,4.0886,12.0726-9.87642.7058.86262Z"
        transform="translate(-230.89001 -213.15682)"
        fill="#fff"
      />
    </svg>
  );
};

const HowImportWorks = () => {
  const lang = useLanguage((s) => s.language);
  const handleModal = () => {
    modals.open({
      modalId: "how_import_work",
      title: lang.contact_lists.how_import_work,
      size: "xl",
      children: (
        <Stack>
          <Group wrap="nowrap" align="start" mt={16}>
            <Stack w={"65%"}>
              <Text>{lang.contact_lists.how1}</Text>

              <Text>{lang.contact_lists.how2}</Text>
            </Stack>

            <Box w={"35%"} pt={32}>
              <Illustration />
            </Box>
          </Group>

          <Group justify="end" mt={"md"}>
            <BackButton onClick={() => modals.close("how_import_work")} />
          </Group>
        </Stack>
      ),
    });
  };
  return (
    <Button
      onClick={handleModal}
      variant="light"
      leftSection={<IconHelp size={18} />}
      ml={"auto"}
    >
      {lang.contact_lists.how_import_work}
    </Button>
  );
};

export default HowImportWorks;
