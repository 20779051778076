import { mainAxios as axios } from "./axios";
import { useProfileStore, useSessionStore } from "../stores/UserStore";
import { useConfigStore } from "../stores/ConfigStore";
export async function updateSelf(allPermissions) {
  let user = await axios.get(allPermissions ? "/users/me" : "/users/me/quick");

  // Compare permissions
  if (user.data.state) {
    let oldPermissions = useProfileStore.getState().profile?.permissions || [];
    let newPermissions = user.data.user_profile.permissions;

    let finalPermissions = [...oldPermissions];
    newPermissions.forEach((newObj) => {
      const index = finalPermissions.findIndex(
        (oldPermissions) => oldPermissions?.name === newObj?.name
      );

      if (index !== -1) {
        // If the object with the same 'name' exists, update the value
        finalPermissions[index] = newObj;
      } else {
        // If the object with the 'name' doesn't exist, add the new object
        finalPermissions.push(newObj);
      }
    });

    useProfileStore
      .getState()
      .setProfile({ ...user.data.user_profile, permissions: finalPermissions });
  }

  return user;
}

export const getProfile = async (id, allPermissions) => {
  if (id) {
    return axios.get(`users/${id}`);
  }
  return axios.get(allPermissions ? "/users/me" : "/users/me/quick");
};

export const updateProfile = async (id, data) => {
  if (id) return axios.put(`/users/${id}`, { ...data });
  else return axios.put(`/users/me`, { ...data });
};

export const getFirstPageUsers = async (data) => {
  let res = await axios.get(`/users/list`, {
    params: {
      options: JSON.stringify({
        per_page: data?.per_page || 100,
        filter: data?.filter,
        sort: data?.sort,
      }),
    },
    headers: {
      "Is-Main": data?.isMain,
    },
  });

  // Set users options automatically
  if (res) {
    let newOptions = res.data.results.map((user) => ({
      ...user,
      id: user.id,
      name: user.first_name + " " + user.last_name,
    }));
    useConfigStore.getState().setUsersList(newOptions);
    const isSwitched = useSessionStore.getState().user_switch;

    if (!isSwitched) {
      useSessionStore.getState().setUsersOptions(res.data.results);
    }
  }

  return res;
};

export const searchUser = async ({ per_page, sort, filter, query, isMain }) => {
  return axios.post(
    `/users/list/search`,
    {
      query: query,
    },
    {
      params: {
        options: JSON.stringify({
          per_page: per_page,
          filter: filter,
          sort: sort,
        }),
      },
      headers: {
        "Is-Main": isMain,
      },
    }
  );
};

export const deleteUser = async (id) => {
  if (id) return axios.delete(`/users/${id}`);
  else return axios.delete(`/users/me`);
};

export const disableUser = async (id) => {
  if (id) return axios.put(`/users/${id}/disable`);
  else return axios.put(`/users/me/disable`);
};

export const uploadAvatar = async (id, formData) => {
  if (id) {
    return axios.post(`/users/${id}/avatar`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
  return axios.post(`/users/me/avatar`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteAvatar = async (id) => {
  if (id) {
    return axios.delete(`/users/${id}/avatar`);
  }
  return axios.delete(`/users/me/avatar`);
};

//#region Teams
export const createTeam = async (name) => {
  let r = await axios.post("/teams", { name: name });

  if (r.data.state) {
    updateSelf();
    return r;
  }
};

export const findTeam = async (id) => {
  return axios.get(`/teams/${id}`);
};

export const editTeam = async ({ id, name }) => {
  let r = await axios.put(`/teams/${id}`, { name: name });

  if (r.data.state) {
    return updateSelf();
  }
};

export const deleteTeam = async ({ id }) => {
  let r = await axios.delete(`/teams/${id}`);

  if (r.data.state) {
    return updateSelf();
  }
};

export const joinTeam = async (team_code) => {
  let r = await axios.post(`/teams/join`, { team_code: team_code });

  if (r.data.state) {
    return updateSelf();
  }
};

export const inviteToTeam = async ({ team_id, email }) => {
  return axios.post(`/teams/${team_id}/invite`, { email: email });
};

export const leaveTeam = async (id) => {
  let r = await axios.delete(`/teams/${id}/leave`);

  if (r.data.state) {
    return updateSelf();
  }
};

export const getTeamUsers = async (id) => {
  return axios.get(`/teams/${id}/users`);
};

//#endregion

//#region Organizations
export const createOrg = async (data) => {
  let r = await axios.post("/organizations", { ...data });

  if (r.data.state) {
    updateSelf();
    return r;
  }
};

export const editOrg = async ({ id, name, data }) => {
  let r = await axios.put(`/organizations/${id}`, { ...data, name });

  if (r.data.state) {
    return updateSelf();
  }
};

export const findOrg = async (id) => {
  return axios.get(`/organizations/${id}`);
};

export const deleteOrg = async ({ id }) => {
  let r = await axios.delete(`/organizations/${id}`);

  if (r.data.state) {
    return updateSelf();
  }
};

export const joinOrg = async (organization_code) => {
  let r = await axios.post(`/organizations/join`, {
    organization_code: organization_code,
  });

  if (r.data.state) {
    updateSelf();
    return r;
  }
};

export const inviteToOrg = async ({ org_id, email }) => {
  return axios.post(`/organizations/${org_id}/invite`, { email: email });
};

export const leaveOrg = async (id) => {
  let r = await axios.delete(`/organizations/${id}/leave`);

  if (r.data.state) {
    return updateSelf();
  }
};

export const getOrgUsers = async (id) => {
  return axios.get(`/organizations/${id}/users`);
};

//#endregion

//#region Resellers
export const createReseller = async ({ formData }) => {
  let r = await axios.post(`/resellers`, formData, {
    headers: {
      accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  });

  if (r.data.state) {
    updateSelf();
    return r;
  }
};

export const editReseller = async ({ id, formData }) => {
  let r = await axios.post(`/resellers/${id}`, formData, {
    headers: {
      accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  });

  if (r.data.state) {
    updateSelf();
    return r;
  }
};

export const findReseller = async (id) => {
  return axios.get(`/resellers/${id}`);
};

export const deleteReseller = async ({ id }) => {
  let r = await axios.delete(`/resellers/${id}`);

  if (r.data.state) {
    return updateSelf();
  }
};

export const getResellerUsers = async ({
  reseller_id,
  include_teams,
  include_organizations,
  include_resellers,
  include_actions_required,
  include_subscriptions,
}) => {
  return axios.get(`/resellers/${reseller_id}/users`, {
    params: {
      include_teams,
      include_organizations,
      include_resellers,
      include_actions_required,
      include_subscriptions,
    },
  });
};

//#endregion

export const switchToUser = async (id) => {
  return axios.post(`/users/switch/to/${id}`, undefined, {
    headers: { "Is-Main": true },
  });
};
export const enableUserAccount = async (user_id) => {
  return axios.put(`/admin/users/${user_id}/enable`);
};
export const unlockUserAccount = async (user_id) => {
  return axios.put(`/admin/users/${user_id}/unlock`);
};
export const getReferredUsers = async () => {
  return axios.get(`/affiliation/users`);
};
