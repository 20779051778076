import { Button, Group, Stack, Text, Tooltip } from "@mantine/core";
import { useMemo } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { IconBriefcase, IconBuilding } from "@tabler/icons-react";
import { getDifference, reminderTypes } from "../../utils";
import { parseDate, parseDateFuture } from "../../../../../functions/dates";

const AnswersLeadItem = ({
  current,
  color,
  handleClick,
  active,
  disabled,
  index,
}) => {
  const lang = useLanguage((s) => s.language);

  //#region About
  const about = useMemo(() => {
    const aboutList = [
      { key: "%last_name%", value: "lastName" },
      { key: "%first_name%", value: "firstName" },
      { key: "%job_title%", value: "jobTitle" },
      { key: "%company%", value: "company" },
    ];
    let result = {};

    aboutList.forEach((item) => {
      result[item.value] =
        current?.properties?.find((x) => x.identifier_placeholder === item.key)
          ?.value || " - ";
    });

    return result;
  }, [current?.properties]);

  const difference = useMemo(() => {
    if (current?.calls) return getDifference(current?.calls[0]?.call_date);
    return null;
  }, [current?.calls]);
  //#endregion

  return (
    <Button
      color={active ? "primary" : color || "gray"}
      disabled={disabled}
      onClick={handleClick}
      variant={"outline"}
      h={"auto"}
      justify="start"
      radius={0}
      id={"prospect-" + current.id}
      style={{
        borderTopWidth: index === 0 || active ? 1 : 0,
        borderColor: active
          ? color || "var(--mantine-color-primary-6)"
          : "#00000021",
        // background: active ? "#fff" : "#fafafa",
        boxShadow: active ? "#00000021 1px 2px 4px" : "none",
        zIndex: active ? 4 : 0,
      }}
    >
      <Stack py={"xs"} gap={4} w={"100%"}>
        <Group gap={"xs"} wrap="nowrap">
          <Tooltip label={about.firstName + " " + about.lastName}>
            <Text fw={600} truncate="end" c={"#333"}>
              {about.firstName} {about.lastName}
            </Text>
          </Tooltip>
        </Group>

        <Group wrap="nowrap" gap={"xs"} w={"100%"} opacity={0.75}>
          <Tooltip label={lang.prm.job_title}>
            <Group>
              <IconBriefcase size={12} strokeWidth={1.3} />
            </Group>
          </Tooltip>

          <Tooltip label={about.jobTitle} disabled={about.jobTitle === " - "}>
            <Text size="xs" truncate="end" w={"fit-content"}>
              {about.jobTitle}
            </Text>
          </Tooltip>
        </Group>

        <Group wrap="nowrap" gap={"xs"} w={"100%"} opacity={0.75}>
          <Tooltip label={lang.prm.company}>
            <Group>
              <IconBuilding size={12} strokeWidth={1.3} />
            </Group>
          </Tooltip>

          <Tooltip label={about.company} disabled={about.company === " - "}>
            <Text size="xs" truncate="end" w={"fit-content"}>
              {about.company}
            </Text>
          </Tooltip>
        </Group>

        {current?.calls?.length > 0 && (
          <Group mt={"auto"} pt={2}>
            <Tooltip label={current.calls[0]?.name}>
              <Text c={"red"} size="xs" fw={500}>
                {reminderTypes()[current.calls[0]?.type]}:{" "}
                {difference.result < 0
                  ? parseDate(current.calls[0]?.call_date)
                  : parseDateFuture(current.calls[0]?.call_date)}
              </Text>
            </Tooltip>
          </Group>
        )}
      </Stack>
    </Button>
  );
};

export default AnswersLeadItem;
