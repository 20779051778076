import { useEffect, useState } from "react";
import { useLanguage } from "../../../stores/LanguageStore";
import { notifications } from "@mantine/notifications";
import { CloseButton, Group, Stack, Text, Tooltip } from "@mantine/core";
import { FileUploader } from "react-drag-drop-files";

const filePickerStyles = {
  border: "1px dashed",
  borderColor: "#00000066",
  borderRadius: 4,
  padding: 1,
  textAlign: "center",
  cursor: "pointer",
  width: "100%",
  height: 150,
};

const ImageGetter = ({
  title,
  types,
  name,
  tip_src,
  is50,
  isIco,
  setFile,
  defaultPreview,
  defaultFile,
  required,
  w,
  h,
}) => {
  const lang = useLanguage((s) => s.language);
  const [fileKey, setFileKey] = useState(false);
  const [value, setValue] = useState(null);

  const [dragging, setDragging] = useState(false);
  const [preview, setPreview] = useState(defaultPreview);

  const handleChange = (file) => {
    setFileKey(!fileKey);
    setValue(file);
    setFile(file);

    if (file) {
      const fr = new FileReader();
      fr.readAsDataURL(file);

      fr.onload = (e) => {
        setPreview(e.target.result);
      };
    } else {
      setPreview("");
    }
  };

  const onTypeError = () => {
    notifications.show({
      message: lang.components.files.error_file_type,
      color: "red",
    });
  };
  const onSizeError = () => {
    notifications.show({
      message: lang.components.files.error_too_big,
      color: "red",
    });
  };

  const handleClear = () => {
    setValue(null);
    setPreview(null);
  };

  useEffect(() => {
    if (defaultFile) {
      const fr = new FileReader();
      fr.readAsDataURL(defaultFile);

      fr.onload = (e) => {
        setPreview(e.target.result);
      };
    }
  }, [defaultFile]);

  return (
    <Stack w={w ? w : isIco ? 200 : 285} gap={2} h={h}>
      <Group w={"100%"} h={25}>
        {title}

        {value && (
          <Tooltip label={lang.components.files.clear}>
            <CloseButton color="red" ml={"auto"} onClick={handleClear} />
          </Tooltip>
        )}
      </Group>
      <FileUploader
        handleChange={handleChange}
        name={name}
        types={types}
        key={fileKey}
        onTypeError={onTypeError}
        onSizeError={onSizeError}
        maxSize={2}
        hoverTitle={lang.components.files.drop_here}
        onDraggingStateChange={(d) => {
          setDragging(d);
        }}
        required={required ? !preview : false}
      >
        <Tooltip label={tip_src}>
          <Stack
            align="center"
            justify={"center"}
            style={filePickerStyles}
            h={h}
          >
            {preview ? (
              <Stack w={"100%"} h={"100%"}>
                <img
                  style={{
                    objectFit: "contain",
                    display: "flex",
                    height: is50 ? 50 : isIco ? 30 : "100%",
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}
                  src={preview}
                  alt="logo"
                />
              </Stack>
            ) : (
              <Text opacity={dragging ? 0 : 1} size="sm">
                {lang.components.files.drag_drop_here}
              </Text>
            )}
          </Stack>
        </Tooltip>
      </FileUploader>
    </Stack>
  );
};

export default ImageGetter;
