import { useLanguage } from "../stores/LanguageStore";
import { useLeadsStore } from "../stores/LeadsStore";
import { usePrmStore } from "../stores/PrmStore";
import { mainAxios as axios } from "./axios";

export const getCampaignsStats = async ({ page, type }) => {
  const response = await axios.get("/statistics/programmations", {
    params: {
      page,
      type,
    },
  });
  return response;
};

export const findCampaignStats = async (programmation_id) => {
  return axios.get(`/statistics/programmations/${programmation_id}`);
};

export const getQuickCampaigns = async () => {
  const response = await axios.get("/workflows/programmations");
  if (response.data) {
    usePrmStore.getState().setCampaignOptions(response.data.results);
    let setPrmOptions = useLeadsStore.getState().setCampaignOptions;
    setPrmOptions([
      {
        label: useLanguage.getState().language.prm.filter_labels.campaign,
        options:
          response.data["results"]
            ?.sort((a, b) => b.id - a.id)
            ?.map((x) => ({ ...x, option_name: x["workflow_name"] })) || [],
      },
    ]);
  }
  return response;
};

export const findCampaign = async (workflow_id, programmation_id) => {
  return axios.get(
    `/workflows/${workflow_id}/programmation/${programmation_id}`
  );
};

export const editCampaign = async ({ workflow_id, programmation_id, data }) => {
  return axios.put(
    `/workflows/${workflow_id}/programmation/${programmation_id}`,
    { ...data }
  );
};

export const resumeCampaign = async (workflow_id, programmation_id) => {
  return axios.put(
    `/workflows/${workflow_id}/programmation/${programmation_id}/resume`
  );
};

export const stopCampaign = async (workflow_id, programmation_id) => {
  return axios.put(
    `/workflows/${workflow_id}/programmation/${programmation_id}/stop`
  );
};

export const resumeStepCampaign = async (
  workflow_id,
  programmation_id,
  step_id
) => {
  return axios.put(
    `/workflows/${workflow_id}/programmation/${programmation_id}/step/${step_id}/resume`
  );
};

export const stopStepCampaign = async (
  workflow_id,
  programmation_id,
  step_id
) => {
  return axios.put(
    `/workflows/${workflow_id}/programmation/${programmation_id}/step/${step_id}/stop`
  );
};

export const archiveCampaign = async ({ workflow_id, programmation_id }) => {
  return axios.put(
    `/workflows/${workflow_id}/programmation/${programmation_id}/archive`
  );
};

export const unarchiveCampaign = async ({ workflow_id, programmation_id }) => {
  return axios.put(
    `/workflows/${workflow_id}/programmation/${programmation_id}/unarchive`
  );
};

export const getProgrammationContacts = async ({
  workflow_id,
  programmation_id,
  step_id,
  contact_type,
  per_page,
  filter,
  sort,
}) => {
  return axios.get(
    `/workflows/${workflow_id}/programmation/${programmation_id}/step/${step_id}/${contact_type}`,
    {
      params: {
        options: JSON.stringify({
          per_page,
          filter,
          sort,
        }),
      },
    }
  );
};

export const getProgrammationUniqueContacts = async ({
  workflow_id,
  programmation_id,
  contact_type,
  per_page,
  filter,
  sort,
}) => {
  return axios.get(
    `/workflows/${workflow_id}/programmation/${programmation_id}/${contact_type}`,
    {
      params: {
        options: JSON.stringify({
          per_page,
          filter,
          sort,
        }),
      },
    }
  );
};

export const downloadCampaignsContact = async ({
  workflow_id,
  programmation_id,
  contact_type,
  file_type,
  contacts_selection,
}) => {
  return axios
    .post(
      `/workflows/${workflow_id}/programmation/${programmation_id}/${contact_type}/${file_type}`,
      { contacts_selection },
      { responseType: "blob" }
    )
    .then((res) => {
      const url = window.URL.createObjectURL(res.data);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${contact_type} campaign: #${programmation_id}, workflow: #${workflow_id}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
};

export const downloadCampaignsContactStep = async ({
  workflow_id,
  programmation_id,
  step_id,
  contact_type,
  file_type,
  contacts_selection,
}) => {
  return axios
    .post(
      `/workflows/${workflow_id}/programmation/${programmation_id}/step/${step_id}/${contact_type}/${file_type}`,
      { contacts_selection },
      { responseType: "blob" }
    )
    .then((res) => {
      const url = window.URL.createObjectURL(res.data);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${contact_type} campaign: #${programmation_id}, workflow: #${workflow_id}, step: #${step_id}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
};

export const getIntegrationsStats = async () => {
  return axios.get(`/statistics/integrations`);
};
export const deleteProgrammationExclusion = async (exclusion_id) => {
  return axios.get(`/workflows/exclude/programmation/${exclusion_id}`);
};
export const getProgrammationExclusion = async (programmation_id) => {
  return axios.get(`/workflows/exclude/programmation/${programmation_id}`);
};

export const getGlobalReporting = async () => {
  return axios.get(`/statistics/global`);
};
export const getGlobalReportingDate = async ({ start_date, end_date }) => {
  return axios.post(`/statistics/date`, { start_date, end_date });
};

export const getProgrammationClickers = async ({
  workflow_id,
  programmation_id,
  step_id,
  link_url,
  per_page,
  filter,
  sort,
}) => {
  return axios.get(
    `/workflows/${workflow_id}/programmation/${programmation_id}/step/${step_id}/click/link/${link_url}`,
    {
      params: {
        options: JSON.stringify({
          per_page,
          filter,
          sort,
        }),
      },
    }
  );
};
export const downloadAllCampaigns = async ({ file_type }) => {
  return axios
    .get(`/statistics/programmations/download/${file_type}`, {
      responseType: "blob",
    })
    .then((res) => {
      const url = window.URL.createObjectURL(res.data);
      const a = document.createElement("a");
      a.href = url;
      a.download = `My Campaigns`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
};
export const downloadCampaignsByDate = async ({ start_date, end_date }) => {
  return axios
    .post(
      `/statistics/date/contacts`,
      {
        start_date,
        end_date,
      },
      {
        responseType: "blob",
      }
    )
    .then((res) => {
      const url = window.URL.createObjectURL(res.data);
      const a = document.createElement("a");
      a.href = url;
      a.download = `All campaigns contact from ${start_date} to ${end_date}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
};

export const downloadCampaign = async ({ id, file_type, name }) => {
  return axios
    .get(`/statistics/programmations/${id}/download/${file_type}`, {
      responseType: "blob",
    })
    .then((res) => {
      const url = window.URL.createObjectURL(res.data);
      const a = document.createElement("a");
      a.href = url;
      a.download = `Campaign #${id} - ${name}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
};

export const getMessages = async () => {
  return axios.get(`/statistics/messages`)
}