import { Button, InputLabel, PasswordInput, Stack } from "@mantine/core";
import { useState } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { IconKey } from "@tabler/icons-react";
import PasswordField from "../../../../components/View/PasswordField";

const PasswordChanger = ({ pwdConfirm }) => {
  const lang = useLanguage((s) => s.language);
  const [clicked, setClicked] = useState(false);
  const [pwdError, setPwdError] = useState(true);

  return (
    <Stack w={250} gap={"xs"}>
      {clicked ? (
        <>
          <PasswordField
            error={pwdError}
            setError={setPwdError}
            id={"pwd"}
            w={250}
            size="sm"
            autoComplete="new-password"
            name="password"
            required={false}
          />
          <PasswordInput
            name="password_confirm"
            id="pwd-confirm"
            label={lang.signup.password_confirm}
            placeholder={lang.signup.password_confirm}
            w={250}
            error={pwdConfirm ? lang.signup.password_confirm_error : null}
          />

          <Button
            size="xs"
            variant="default"
            mt={6}
            onClick={() => setClicked(false)}
          >
            {lang.global.cancel}
          </Button>
        </>
      ) : (
        <Stack gap={2} mt={1} w={"100%"}>
          <InputLabel fz={12}>{lang.profile.password}</InputLabel>
          <Button
            variant="default"
            onClick={() => setClicked(true)}
            leftSection={<IconKey size={18} />}
            w={"100%"}
          >
            {lang.profile.change_password}
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default PasswordChanger;
