import { useThemeStore } from "../../stores/ThemeStore";

const UpgradeIllustration = () => {
  const theme = useThemeStore((s) => s.theme);
  const mainColor = theme.colors.secondary[6];
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 422.29"
    >
      <path
        d="m432.91,72.21c-8.05-26.95-30.78-44.55-58.25-48.41-.3-.04-.59.01-.89,0-.39-.12-.79-.21-1.19-.27-37.78-4.88-79.02,3.86-111.84,24.4-31.46,19.69-67.61,30.79-104.73,30.55-1.15,0-2.31.02-3.47.07-28.56,1.43-53.77,22.22-61.49,49.56-1.01,3.59.75,6.79,3.59,8.83,4.99,3.57,8.4,6.08,14.54,7.38,10.95,2.32,21.94,4.45,32.97,6.4v-43.93c.01-8.66,7.03-15.68,15.69-15.69h215.47c8.66.01,15.68,7.03,15.69,15.69v54.71c2.96-.92,5.84-2.06,8.63-3.4,10.39-5.13,19.27-12.88,25.77-22.49,12.47-18.1,15.77-42.43,9.51-63.39Z"
        style={{ fill: "#f2f2f2" }}
      />
      <path
        d="m342.19,329.29h-190.48c-9.15-.01-16.57-7.43-16.58-16.58v-73.91c-23.2-7.1-47.63-9.24-71.72-6.29-.4.06-.8.15-1.19.27-.3.02-.59-.04-.89,0-27.47,3.86-50.2,21.46-58.25,48.41-6.26,20.96-2.96,45.29,9.51,63.39,6.49,9.6,15.37,17.35,25.77,22.49,14.29,6.88,29.33,7.4,44.92,7.89,46.82,1.46,93.68-.31,140.26-5.3,22.92-2.46,45.75-5.67,68.48-9.64,11.63-2.04,23.23-4.28,34.79-6.73,6.14-1.3,9.55-3.8,14.54-7.38,2.84-2.03,4.61-5.24,3.59-8.83-.75-2.66-1.67-5.26-2.75-7.81Z"
        style={{ fill: "#f2f2f2" }}
      />
      <path
        d="m359.05,307.36h-186.94c-7.51,0-13.6-6.1-13.61-13.61V120.03c0-7.51,6.1-13.6,13.61-13.61h186.94c7.51,0,13.6,6.1,13.61,13.61v173.72c0,7.51-6.1,13.6-13.61,13.61Z"
        style={{ fill: "#e6e6e6" }}
      />
      <path
        d="m319.7,298.18h-138.41c-7.51,0-13.6-6.1-13.61-13.61v-155.36c0-7.51,6.1-13.6,13.61-13.61h168.57c7.51,0,13.6,6.1,13.61,13.61v125.19c-.03,24.16-19.61,43.75-43.77,43.77Z"
        style={{ fill: "#fff" }}
      />
      <circle cx="265.58" cy="206.9" r="61" style={{ fill: mainColor }} />
      <path
        d="m291.28,203.83l-20.36-24.75c-2.11-2.56-5.89-2.93-8.45-.82-.25.2-.48.43-.69.67l-21.77,24.75c-2.18,2.49-1.94,6.28.56,8.47,2.49,2.18,6.27,1.94,8.46-.54l11.26-12.81v32.11c0,3.31,2.69,6,6,6s6-2.69,6-6v-31.28l9.72,11.82c2.1,2.56,5.89,2.93,8.45.82s2.93-5.89.82-8.45h0Z"
        style={{ fill: "#fff" }}
      />
      <path
        d="m256.62,422.29c-7.81,0-14.15-6.33-14.15-14.15s6.33-14.15,14.15-14.15,14.15,6.33,14.15,14.15h0c0,7.81-6.34,14.14-14.15,14.15Zm0-26.29c-6.71,0-12.15,5.44-12.15,12.15s5.44,12.15,12.15,12.15,12.15-5.44,12.15-12.15h0c0-6.7-5.44-12.14-12.15-12.15Z"
        style={{ fill: "#f2f2f2" }}
      />
      <path
        d="m262.16,407.48l-4.39-5.33c-.45-.55-1.27-.63-1.82-.18-.05.04-.1.09-.15.14l-4.69,5.33c-.47.54-.41,1.36.13,1.82.54.47,1.35.41,1.82-.12l2.43-2.76v6.92c0,.71.58,1.29,1.29,1.29.71,0,1.29-.58,1.29-1.29h0v-6.74l2.1,2.55c.45.55,1.27.63,1.82.18.55-.45.63-1.27.18-1.82h0Z"
        style={{ fill: "#f2f2f2" }}
      />
      <path
        d="m417.62,213.29c-7.81,0-14.15-6.33-14.15-14.15s6.33-14.15,14.15-14.15,14.15,6.33,14.15,14.15h0c0,7.81-6.34,14.14-14.15,14.15Zm0-26.29c-6.71,0-12.15,5.44-12.15,12.15s5.44,12.15,12.15,12.15,12.15-5.44,12.15-12.15h0c0-6.7-5.44-12.14-12.15-12.15h0Z"
        style={{ fill: "#f2f2f2" }}
      />
      <path
        d="m423.16,198.48l-4.39-5.33c-.45-.55-1.27-.63-1.82-.18-.05.04-.1.09-.15.14l-4.69,5.33c-.47.54-.41,1.36.13,1.82.54.47,1.35.41,1.82-.12l2.43-2.76v6.92c0,.71.58,1.29,1.29,1.29s1.29-.58,1.29-1.29h0v-6.74l2.1,2.55c.45.55,1.27.63,1.82.18.55-.45.63-1.27.18-1.82h0Z"
        style={{ fill: "#f2f2f2" }}
      />
      <path
        d="m98.62,201.29c-7.81,0-14.15-6.33-14.15-14.15,0-7.81,6.33-14.15,14.15-14.15,7.81,0,14.15,6.33,14.15,14.15h0c0,7.81-6.34,14.14-14.15,14.15Zm0-26.29c-6.71,0-12.15,5.44-12.15,12.15,0,6.71,5.44,12.15,12.15,12.15,6.71,0,12.15-5.44,12.15-12.15h0c0-6.7-5.44-12.14-12.15-12.15h0Z"
        style={{ fill: "#f2f2f2" }}
      />
      <path
        d="m104.16,186.48l-4.39-5.33c-.45-.55-1.27-.63-1.82-.18-.05.04-.1.09-.15.14l-4.69,5.33c-.47.54-.41,1.36.13,1.82.53.46,1.34.41,1.81-.12l2.43-2.76v6.92c0,.71.58,1.29,1.29,1.29.71,0,1.29-.58,1.29-1.29v-6.74l2.1,2.55c.45.55,1.27.63,1.82.18.55-.45.63-1.27.18-1.82h0Z"
        style={{ fill: "#f2f2f2" }}
      />
      <path
        d="m211.62,28.29c-7.81,0-14.15-6.33-14.15-14.15,0-7.81,6.33-14.15,14.15-14.15s14.15,6.33,14.15,14.15h0c0,7.81-6.34,14.14-14.15,14.15Zm0-26.29c-6.71,0-12.15,5.44-12.15,12.15,0,6.71,5.44,12.15,12.15,12.15,6.71,0,12.15-5.44,12.15-12.15h0c0-6.7-5.44-12.14-12.15-12.15h0Z"
        style={{ fill: "#f2f2f2" }}
      />
      <path
        d="m217.16,13.48l-4.39-5.33c-.45-.55-1.27-.63-1.82-.18-.05.04-.1.09-.15.14l-4.69,5.33c-.47.54-.41,1.36.13,1.82.53.46,1.34.41,1.81-.12l2.43-2.76v6.92c0,.71.58,1.29,1.29,1.29.71,0,1.29-.58,1.29-1.29v-6.74l2.1,2.55c.45.55,1.27.63,1.82.18.55-.45.63-1.27.18-1.82h0Z"
        style={{ fill: "#f2f2f2" }}
      />
      <path
        d="m60.62,306.29c-7.81,0-14.15-6.33-14.15-14.15s6.33-14.15,14.15-14.15c7.81,0,14.15,6.33,14.15,14.15h0c0,7.81-6.34,14.14-14.15,14.15Zm0-26.29c-6.71,0-12.15,5.44-12.15,12.15,0,6.71,5.44,12.15,12.15,12.15,6.71,0,12.15-5.44,12.15-12.15,0-6.7-5.44-12.14-12.15-12.15h0Z"
        style={{ fill: "#fff" }}
      />
      <path
        d="m66.16,291.48l-4.39-5.33c-.45-.55-1.27-.63-1.82-.18-.05.04-.1.09-.15.14l-4.69,5.33c-.47.54-.41,1.36.13,1.82.53.46,1.34.41,1.81-.12l2.43-2.76v6.92c0,.71.58,1.29,1.29,1.29.71,0,1.29-.58,1.29-1.29v-6.74l2.1,2.55c.45.55,1.27.63,1.82.18.55-.45.63-1.27.18-1.82h0Z"
        style={{ fill: "#fff" }}
      />
      <path
        d="m366.62,69.29c-7.81,0-14.15-6.33-14.15-14.15s6.33-14.15,14.15-14.15,14.15,6.33,14.15,14.15h0c0,7.81-6.34,14.14-14.15,14.15Zm0-26.29c-6.71,0-12.15,5.44-12.15,12.15s5.44,12.15,12.15,12.15,12.15-5.44,12.15-12.15c0-6.7-5.44-12.14-12.15-12.15Z"
        style={{ fill: "#fff" }}
      />
      <path
        d="m372.16,54.48l-4.39-5.33c-.45-.55-1.27-.63-1.82-.18-.05.04-.1.09-.15.14l-4.69,5.33c-.47.54-.41,1.36.13,1.82.54.47,1.35.41,1.82-.12l2.43-2.76v6.92c0,.71.58,1.29,1.29,1.29.71,0,1.29-.58,1.29-1.29h0v-6.74l2.1,2.55c.45.55,1.27.63,1.82.18.55-.45.63-1.27.18-1.82h0Z"
        style={{ fill: "#fff" }}
      />
    </svg>
  );
};

export default UpgradeIllustration;
