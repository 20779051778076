import { useFlowStore } from "../../../../../stores/WorkflowStore";
import "reactflow/dist/style.css";
import ReactFlow, { Background, Controls, Panel } from "reactflow";
import { useEffect, useState } from "react";
import { handleLayoutPreview } from "./layouter";
import { nodeWidthPreview } from "./flowSettings";
import { Flex, Group, Stack, Switch, Text, Tooltip } from "@mantine/core";
import "./flowStyles.scss";
import CampaignSettings from "../campaignSettings/CampaignSettings";
import PreviewEdge from "./edges/PreviewEdge";
import BaseNodePreview from "./nodes/Preview/BaseNodePreview";
import StartNodePreview from "./nodes/Preview/StartNodePreview";
import {
  IconInfoCircle,
  IconUserCancel,
  IconUserExclamation,
} from "@tabler/icons-react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { editWorkflow } from "../../../../../api/WorkflowAPI";

const edgeTypes = {
  main: PreviewEdge,
};

const nodeTypes = {
  start: StartNodePreview,
  action: BaseNodePreview,
};

const FlowPreview = ({ programData, handleProgramConfigs, isPast, isReprog }) => {
  const lang = useLanguage((s) => s.language);
  const nodes = useFlowStore((s) => s.nodes);
  const edges = useFlowStore((s) => s.edges);
  const onNodesChange = useFlowStore((s) => s.onNodesChange);
  const onEdgesChange = useFlowStore((s) => s.onEdgesChange);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const flowProfile = useFlowStore((s) => s.profile);
  const setFlowProfile = useFlowStore((s) => s.setProfile);

  const handleNodeClick = (e, node) => {
    return false;
  };

  useEffect(() => {
    handleLayoutPreview();
    setTimeout(() => {
      let nodes = useFlowStore.getState().nodes;
      let n = nodes.find((x) => x.data?.source === "0");
      reactFlowInstance?.fitView({ nodes: [n], duration: 1000 });
    }, 200);
  }, [reactFlowInstance]);

  const [loading, setLoading] = useState(false);
  const handleAutoRemove = (e) => {
    const v = e.target.checked;

    setLoading(true);
    editWorkflow({ auto_remove_responders: v, id: flowProfile.id })
      .then((response) => {
        setFlowProfile({ ...flowProfile, auto_remove_responders: v });
      })
      .finally(() => setLoading(false));
  };

  return (
    <Flex h={"100%"} style={{ overflow: "hidden" }}>
      <ReactFlow
        nodes={nodes}
        nodeTypes={nodeTypes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onInit={setReactFlowInstance}
        connectOnClick={false}
        panOnScroll
        zoomOnScroll={false}
        preventScrolling
        defaultEdgeOptions={{
          type: "main",
          deletable: false,
          focusable: false,
          updatable: false,
        }}
        edgeTypes={edgeTypes}
        disableKeyboardA11y
        translateExtent={[
          [-Infinity, 32],
          [Infinity, Infinity],
        ]}
        nodeExtent={[
          [-Infinity, 32],
          [Infinity, Infinity],
        ]}
        defaultViewport={{
          x: window.innerWidth / 2 - nodeWidthPreview - 40,
          y: 180,
          zoom: 1,
        }}
        nodesDraggable={false}
        proOptions={{
          hideAttribution: true,
        }}
        maxZoom={1}
        edgesUpdatable={false}
        edgesFocusable={false}
        onNodeClick={handleNodeClick}
      >
        <Background />
        <Controls position="bottom-right" showInteractive={false} />

        <Panel position="top-left">
          <Stack ml={-14} mt={-16}>
            <Group gap={"xs"} opacity={0.5}>
              <IconInfoCircle size={18} />
              <Text size="xs">{lang.campaign.preview.text}</Text>
            </Group>

            <Tooltip
              label={lang.workflows.configs.exclude_answerers}
              position="bottom"
              refProp="rootRef"
            >
              <Switch
                size="md"
                onLabel={<IconUserCancel size={18} />}
                offLabel={<IconUserExclamation size={18} />}
                checked={flowProfile?.auto_remove_responders === true}
                onChange={handleAutoRemove}
                disabled={loading}
                name="auto_remove_responders"
              />
            </Tooltip>
          </Stack>
        </Panel>
      </ReactFlow>

      <CampaignSettings
        programData={programData}
        handleProgramConfigs={handleProgramConfigs}
        isPast={isPast}
        isReprog={isReprog}
      />
    </Flex>
  );
};

export default FlowPreview;
