import { Button, Group, Paper, Stack, Text } from "@mantine/core";
import { useProfileStore, useSessionStore } from "../../../../stores/UserStore";
import {
  IconBook,
  IconBuildingCommunity,
  IconCalendarUser,
} from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useMemo } from "react";
import { getPermission } from "../../../../functions/utilFunctions";

const iconStyle = { width: 48, height: 48, strokeWidth: 1 };

const NeedHelpBlock = () => {
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);

  const reseller = useSessionStore((s) => s.reseller);

  const list = useMemo(() => {
    let result = [
      {
        icon: <IconBuildingCommunity color="violet" style={iconStyle} />,
        title: lang.home.helps.first.title,
        description: lang.home.helps.first.text,
        link: {
          label: lang.home.helps.first.button,
          url: "/resellers/white-labels",
        },
        color: "violet",
        internal: true,
        permission: "displayResellers",
      },
      {
        icon: <IconCalendarUser color="orange" style={iconStyle} />,
        title: lang.home.helps.second.title,
        description: lang.home.helps.second.text,
        link: {
          label: lang.home.helps.second.button,
          url: "https://www.magileads.com/reservation-demo-prospection-commerciale",
        },
        color: "orange",
      },
      {
        icon: <IconBook style={iconStyle} />,
        title: lang.home.helps.third.title,
        description: lang.home.helps.third.text,
        link: {
          label: lang.home.helps.third.button,
          url: "/support",
        },
        color: "tertiary.8",
      },
    ];

    if (reseller?.name === "Magileads") {
      return result;
    } else {
      return [
        {
          icon: <IconBook style={iconStyle} />,
          title: lang.home.helps.third.title,
          description: lang.home.helps.third.text,
          link: {
            label: lang.home.helps.third.button,
            url: "/support",
          },
          color: "tertiary.8",
        },
      ];
    }
  }, [lang, reseller?.name]);

  return (
    <Stack style={{ overflow: "hidden" }} gap={"xs"}>
      <Text size="lg" opacity={0.6} fw={"bold"}>
        {profile?.first_name && <>{profile?.first_name}, </>}{" "}
        {lang.home.helps.title}
      </Text>

      <Group wrap="nowrap" align="stretch">
        {list.map((item, i) => {
          if (item?.permission && getPermission(item?.permission) === false) {
            return "";
          }
          return (
            <Paper
              key={i}
              withBorder
              p={"md"}
              maw={"33%"}
              radius={12}
              style={{
                border: `${item.color} 1px solid`,
                animationDelay: `${i * 0.25}s`,
              }}
              className={`animate__animated animate__fadeInUp`}
              flex={1}
              component={Stack}
            >
              <Group wrap="nowrap">
                {item.icon}
                <Stack gap={0}>
                  <Text size="lg" fw={"bold"}>
                    {item.title}
                  </Text>
                  <Text opacity={0.5}>{item.description}</Text>
                </Stack>
              </Group>
              <Button
                onClick={
                  item.internal
                    ? undefined
                    : () => window.open(item.link.url, "_blank")
                }
                component={item.internal ? Link : undefined}
                to={item.link.url}
                mt={"auto"}
                w={"100%"}
                variant="light"
                color={item.color}
              >
                {item.link.label}
              </Button>
            </Paper>
          );
        })}
      </Group>
    </Stack>
  );
};

export default NeedHelpBlock;
