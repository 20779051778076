import { Center, Text } from "@mantine/core";
import successAnim from "../../../assets/lotties/success.json";
import { Logo } from "../../../layouts/Logo";
import { useLanguage } from "../../../stores/LanguageStore";
import { lazy } from "react";

const Lottie = lazy(() => import("react-lottie"));

const EmailAddedPage = () => {
  const lang = useLanguage((s) => s.language);
  return (
    <Center
      w={"60vw"}
      h={"50vh"}
      style={{ flexDirection: "column" }}
      mx={"auto"}
      mt={128}
    >
      <Logo w={200} mb={64} />
      <Lottie
        options={{
          animationData: successAnim,
          autoplay: true,
          loop: true,
        }}
        height={150}
      />
      <Text fz={28} ta={"center"}>
        L'Email a bien été ajouté, vous pouvez fermer cette fenêtre et
        l'utiliser dans votre campagne. <br />{" "}
        <span style={{ fontSize: 22 }}>
          {" "}
          S'il n'est pas listé, veuillez rafraîchir.
        </span>
      </Text>
    </Center>
  );
};

export default EmailAddedPage;
