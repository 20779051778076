import { Group, Stack } from "@mantine/core";
import { useNavigate, useRouteLoaderData } from "react-router-dom";
import EditSignature from "./EditSignature";
import CreateSignatureButton from "./CreateSignatureButton";
import PageTitle from "../../../../components/View/PageTitle";
import { useCallback } from "react";

const SignaturePageEdit = ({ loaderId }) => {
  const modelProfile = useRouteLoaderData(loaderId);

  const navigate = useNavigate();

  const handleBack = useCallback(() => {
    navigate(`/models/email-signatures`);
  }, []);

  return (
    <>
      <Group className="layout-block top" justify="space-between">
        <PageTitle title={modelProfile?.name} />

        <CreateSignatureButton />
      </Group>

      <Stack flex={1} className="layout-block" px={"sm"} pt={"sm"} gap={"xs"}>
        <EditSignature model={modelProfile} handleClose={handleBack} />
      </Stack>
    </>
  );
};

export default SignaturePageEdit;
