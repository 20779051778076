import { Button, Modal } from "@mantine/core";
import { IconHelpSquareRounded } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useSessionStore } from "../../stores/UserStore";

const Helper = ({ link, label, title, autoPlay, ...props }) => {
  const reseller = useSessionStore((s) => s.reseller);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (autoPlay) {
      setTimeout(() => {
        setShow(true);
      }, autoPlay);
    }
  }, [autoPlay]);

  if (reseller?.name !== "Magileads") {
    return "";
  }

  return (
    <>
      <Button
        variant="light"
        leftSection={<IconHelpSquareRounded size={24} />}
        onClick={() => setShow(true)}
        ml={"auto"}
        {...props}
      >
        {label}
      </Button>
      <Modal
        opened={show}
        onClose={setShow}
        size={"auto"}
        title={title || label}
      >
        <iframe
          src={link}
          loading="lazy"
          title={title || label}
          allow="clipboard-write"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowfullscreen
          style={{
            position: "relative",
            width: 900,
            height: 500,
            border: 0,
          }}
        ></iframe>
      </Modal>
    </>
  );
};

export default Helper;
