import { Button, Modal, Tooltip } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import CreateLinkedinAccount from "../../pages/protected/tools/linkedin/components/LinkedinAccount";
import { useLanguage } from "../../stores/LanguageStore";

const AddLinkedinAccount = ({ onCreated }) => {
  const lang = useLanguage((s) => s.language);
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Tooltip label={lang.campaign.panel.linkedin.tooltip}>
        <Button
          variant="subtle"
          size="compact-sm"
          onClick={open}
          type="button"
          fw={700}
        >
          {lang.campaign.panel.linkedin.label}
        </Button>
      </Tooltip>

      <Modal
        opened={opened}
        onClose={close}
        title={lang.campaign.panel.linkedin.title}
        size={"auto"}
        styles={{
          header: {
            paddingBottom: 8,
            minHeight: 32,
          },
        }}
        removeScrollProps={{
          enabled: false,
        }}
        radius={"md"}
      >
        <CreateLinkedinAccount onCreated={onCreated} handleClose={close} />
      </Modal>
    </>
  );
};

export default AddLinkedinAccount;
