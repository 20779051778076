import { ActionIcon, Group, Menu, Stack, Text } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { Handle, Position, useReactFlow } from "reactflow";
import { defaultNodeConfigs, isMainAction, nodeWidth } from "../flowSettings";
import { useFlowStore } from "../../../../../../stores/WorkflowStore";
import { handleLayout } from "../layouter";
import { useState } from "react";
import ActionsList from "../components/ActionsList";
import { useLanguage } from "../../../../../../stores/LanguageStore";

const TrailNode = ({ data, xPos, yPos }) => {
  const lang = useLanguage((s) => s.language);
  const reactFlow = useReactFlow();
  const linking = useFlowStore((s) => s.linking);
  const [opened, setOpened] = useState(false);
  const getId = useFlowStore((s) => s.getId);
  const nodes = useFlowStore((s) => s.nodes);
  const setNodes = useFlowStore((s) => s.setNodes);
  const edges = useFlowStore((s) => s.edges);
  const setEdges = useFlowStore((s) => s.setEdges);

  const handleAdd = (actionType) => {
    let newNodes = [...nodes];
    let newEdges = [...edges];
    let newNodeId = getId().toString();
    let newEventNodeId = data?.source;

    //#region Choose automatically the same sender as initial action
    const initialNode = nodes.find((x) => x.data?.source === "0");
    const initialType = initialNode?.data?.actionType;
    let autoData = { ...defaultNodeConfigs };

    if (
      initialType === actionType ||
      (initialType?.includes("linkedin") && actionType?.includes("linkedin"))
    ) {
      autoData.integration_id = initialNode?.data?.integration_id;
      autoData.pool_id = initialNode?.data?.pool_id;
      autoData.signature_id = initialNode?.data?.signature_id;
    }
    //#endregion

    // Add Event node if source is not initial
    if (data?.source !== "0" && data?.shouldBeEvent) {
      newEventNodeId = getId().toString();
      const newEventNode = {
        id: newEventNodeId,
        type: "action",
        data: {
          actionType: "event",
          source: data?.source,
          event_type: data?.fromCall ? "call_custom" : undefined,
          error: data?.error,
        },
        position: { x: xPos, y: yPos },
      };
      newNodes.push(newEventNode);

      const newEventEdge = {
        id: `e-${data?.source}-${newEventNodeId}`,
        source: data?.source,
        target: newEventNodeId,
        data: { fromEvent: false, error: data?.error },
      };
      newEdges.push(newEventEdge);
    }

    const newNode = {
      id: newNodeId,
      type: "action",
      data: {
        actionType: actionType,
        source: data?.source === "0" ? "0" : newEventNodeId,
        ...autoData,
        error: data?.error,
      },
      position: { x: xPos, y: yPos },
    };
    newNodes.push(newNode);

    if (data?.source === "0" || !data?.shouldBeEvent) {
      const newEdge = {
        id: `e-${data?.source}-${newNodeId}`,
        source: data?.source,
        target: newNodeId,
        data: { fromEvent: false, error: data?.error },
      };
      newEdges.push(newEdge);
    } else {
      const newEdge = {
        id: `e-${newEventNodeId}-${newNodeId}`,
        source: newEventNodeId,
        target: newNodeId,
        data: { fromEvent: true, error: data?.error },
      };
      newEdges.push(newEdge);
    }

    setNodes(newNodes.filter((x) => x.id !== `${data.source}-trail`));
    setEdges(newEdges.filter((x) => x.id !== `e-${data.source}-trail`));

    setTimeout(() => {
      handleLayout();
      setTimeout(() => {
        let nodes = useFlowStore.getState().nodes;
        let n = nodes.find((x) => x.id === newNodeId);
        // let n_trail = nodes.find((x) => x.id === newNodeId + "-trail");
        let e_n = nodes.find((x) => x.id === newEventNodeId);
        const setSelectedNode = useFlowStore.getState().setSelectedNode;
        if (isMainAction(n)) {
          setSelectedNode(n);

          return setTimeout(() => {
            reactFlow?.setCenter(
              n.x + 64,
              data?.source === "0" ? 312 : n.y + (e_n ? 0 : 132),
              {
                duration: 500,
                zoom: 1,
              }
            );
            // reactFlow?.fitView({
            //   nodes: e_n ? [n, n_trail, e_n] : [n],
            //   duration: 500,
            // });
          }, 201);
        }
        reactFlow?.setCenter(n.x + 64, n.y + (e_n ? 0 : 132), {
          duration: 500,
          zoom: 1,
        });
        // reactFlow?.fitView({
        //   nodes: e_n ? [n, n_trail, e_n] : [n],
        //   duration: 500,
        // });
      }, 100);
    }, 25);
  };

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        style={{ visibility: "hidden" }}
      />
      <Group w={nodeWidth} justify="center">
        <Menu
          trigger="click-hover"
          openDelay={200}
          closeDelay={200}
          position="right"
          onChange={setOpened}
          shadow="md"
          disabled={linking}
        >
          <Menu.Target>
            <Stack gap={0} justify="center" align="center">
              <ActionIcon
                radius={"lg"}
                variant="white"
                style={{ boxShadow: "#00000033 0 1px 3px", border: "none" }}
                disabled={linking}
              >
                <IconPlus
                  size={18}
                  style={{
                    transform: opened ? "rotate(135deg)" : "none",
                    transition: "all ease .15s",
                  }}
                />
              </ActionIcon>

              <Text size="sm" mt={4} opacity={0.75}>
                {lang.campaign.nodes.add_action}
              </Text>
            </Stack>
          </Menu.Target>
          <Menu.Dropdown>
            <ActionsList
              handleActionClick={handleAdd}
              noSecondary={data?.source === "0"}
            />
          </Menu.Dropdown>
        </Menu>
      </Group>
    </>
  );
};

export default TrailNode;
