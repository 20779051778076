import {
  Button,
  Group,
  Modal,
  Stack,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import { useMemo, useState } from "react";
import { IconBook2 } from "@tabler/icons-react";
import { useLanguage } from "../../../../stores/LanguageStore";
import TemplateItem from "./TemplateItem";
import BackButton from "../../../../components/View/BackButton";
import {
  workflow1,
  workflow2,
  workflow3,
  workflow4,
  workflow5,
} from "./workflowProfileTemplates";
import DontShowAgain from "../../../../components/View/DontShowAgain";
import WorkflowsListing from "../listing/WorkflowsListing";

const WorkflowTemplates = ({ inWorkflow, onCreated }) => {
  const lang = useLanguage((s) => s.language);
  const [opened, setOpened] = useState(false);

  const templateItems = useMemo(() => {
    return [workflow1, workflow2, workflow3, workflow4, workflow5];
  }, []);

  const handleOpen = () => {
    setOpened(true);
  };
  const handleClose = () => {
    setOpened(false);
  };

  return (
    <>
      {inWorkflow ? (
        <Tooltip label={lang.workflows.templates.title}>
          <Button
            size="xs"
            leftSection={<IconBook2 size={18} />}
            variant="light"
            fw={400}
            color="tertiary.8"
            radius={"sm"}
            onClick={handleOpen}
            id="workflow-library"
          >
            {lang.workflows.templates.label}
          </Button>
        </Tooltip>
      ) : (
        <Button
          variant="light"
          ml={"auto"}
          onClick={handleOpen}
          radius={"sm"}
          leftSection={<IconBook2 size={18} />}
        >
          {lang.workflows.templates.label}
        </Button>
      )}

      <Modal
        opened={opened}
        onClose={handleClose}
        title={<Title order={5}>{lang.workflows.templates.title}</Title>}
        size={"90%"}
      >
        <Group
          mih={"40vh"}
          w={"100%"}
          wrap="nowrap"
          flex={"1 1 auto"}
          justify="space-between"
        >
          {templateItems.map((item, index) => (
            <TemplateItem
              key={item.id}
              index={index}
              item={item}
              handleClose={handleClose}
              onCreated={onCreated}
            />
          ))}
        </Group>

        {/* <Title order={5} mt={16} mb={16}>
          {lang.workflows.templates.title2}
        </Title>

        <Stack flex={1} h={400}>
          <WorkflowsListing
            closeModal={handleClose}
            wrapperProps={{
              mx: 0,
              w: "100%",
              style: {
                borderBottom: "1px solid #00000016",
              },
            }}
          />
        </Stack> */}

        <Group justify="end" mt={"lg"}>
          <DontShowAgain type="library_auto_show" />
          <BackButton
            onClick={handleClose}
            label={lang.workflows.templates.create}
          />
        </Group>
      </Modal>
    </>
  );
};

export default WorkflowTemplates;
