import { DateTimePicker } from "@mantine/dates";
import { useLanguage } from "../../../../../stores/LanguageStore";
import dayjs from "dayjs";
import { unsubscribeUser } from "../../../../../api/AdminAPI";
import { ActionIcon, Tooltip } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import { useState } from "react";
import { notifications } from "@mantine/notifications";

const AdminInputSubscription = ({
  value,
  onChange,
  profile,
  setProfile,
  handleStopEdit,
}) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(false);

  const handleSubscriptionDelete = () => {
    setLoading(true);
    unsubscribeUser({ user_id: profile.id })
      .then((response) => {
        notifications.show({ message: lang.profile.edited, color: "green.8" });
        setProfile((prev) => {
          return {
            ...prev,
            subscriptions: {
              active: false,
              end_date: null,
            },
          };
        });
        handleStopEdit();
      })
      .finally(() => setLoading(false));
  };

  return (
    <DateTimePicker
      required
      value={value ? new Date(value) : null}
      onChange={onChange}
      minDate={new Date()}
      label={lang.profile.subscription_end}
      placeholder={lang.profile.subscription_end}
      dropdownType="modal"
      autoFocus
      rightSection={
        <Tooltip label={lang.profile.delete_subscription}>
          <ActionIcon
            loading={loading}
            color="red"
            onClick={handleSubscriptionDelete}
            variant="light"
          >
            <IconTrash size={18} />
          </ActionIcon>
        </Tooltip>
      }
    />
  );
};

export default AdminInputSubscription;
