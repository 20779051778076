import {
  ActionIcon,
  Anchor,
  Button,
  Group,
  Stack,
  StepperStep,
  Text,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import { getProps } from "./ItemProps";
import { parseDate } from "../../../../../../../functions/dates";
import ActivityNote from "./note/ActivityNote";
import { useLeadsSheetStore } from "../../../../../../../stores/LeadsStore";
import Answers from "../../../answers/Answers";
import DeleteNote from "./note/DeleteNote";
import { IconEdit, IconExternalLink } from "@tabler/icons-react";
import { useLanguage } from "../../../../../../../stores/LanguageStore";
import { useMemo, useState } from "react";

const ActivityItem = ({ item, isLast, isOutside }) => {
  const lang = useLanguage((s) => s.language);
  const Icon = getProps(item).icon;
  const current = useLeadsSheetStore((s) => s.current);
  const currentContainer = useLeadsSheetStore((s) => s.currentContainer);
  const index = useLeadsSheetStore((s) => s.index);

  const [isEdit, setIsEdit] = useState(false);

  const programmation = useMemo(() => {
    return current?.programmations?.find(
      (x) => x.programmation_id === item.workflow_programmation_id
    );
  }, [current?.programmations, item.workflow_programmation_id]);

  return (
    <Stack gap={4} mt={-6} w={"100%"}>
      <Group>
        <StepperStep
          component={Group}
          // align="start"
          miw={120}
          gap={0}
          pl={3}
          style={{ cursor: "text" }}
          icon={
            <ThemeIcon
              variant="white"
              radius={"lg"}
              color={getProps(item)?.iconColor}
              style={{ boxShadow: "#00000021 1px 1px 3px" }}
              mt={-4}
            >
              <Icon size={18} />
            </ThemeIcon>
          }
          label={
            <Group
              gap={"xs"}
              bg={"white"}
              px={"xs"}
              pt={4}
              style={{
                borderTopLeftRadius: 4,
                borderTopRightRadius: 4,
                border: "1px solid #00000018",
                borderBottom: 0,
              }}
              miw={120}
            >
              <Text>{getProps(item).label}</Text>

              {programmation?.workflow_name && (
                <Anchor
                  fw={500}
                  href={`/statistics/campaigns?highlight=${programmation?.programmation_id}`}
                  target="_blank"
                >
                  {programmation?.workflow_name}
                  {item?.workflow_step_id && (
                    <span style={{ fontSize: 12, marginLeft: 16 }}>
                      ( {lang.prm.resume.step} #{item.workflow_step_id} )
                    </span>
                  )}
                </Anchor>
              )}
            </Group>
          }
          description={
            <Group
              bg={"white"}
              px={"xs"}
              pb={6}
              mt={-4}
              pt={4}
              miw={120}
              style={{
                borderBottomLeftRadius: 4,
                borderBottomRightRadius: 4,
                border: "1px solid #00000018",
                borderTop: 0,
              }}
            >
              <Text size="sm">{parseDate(item?.date)} </Text>
            </Group>
          }
          mih={0}
        />

        {(item?.reply || item?.sent) && (
          <Answers container={currentContainer} index={index} inSheet />
        )}
        {item?.note && !isOutside && !item?.isCreate && (
          <>
            <Tooltip label="Modifier">
              <ActionIcon
                variant="light"
                className="on-hover-opacity"
                onClick={() => setIsEdit(true)}
              >
                <IconEdit size={18} />
              </ActionIcon>
            </Tooltip>
            <DeleteNote id={item.id} />
          </>
        )}
        {item?.event === "link_click" && (
          <Tooltip label={lang.prm.details.history.view_link}>
            <Anchor target="_blank" href={item?.additional_data}>
              <Button
                variant="light"
                className="on-hover-opacity"
                color="primary"
                leftSection={<IconExternalLink size={18} />}
                size="xs"
              >
                {item?.additional_data}
              </Button>
            </Anchor>
          </Tooltip>
        )}
      </Group>
      <Group
        mih={32}
        pl={26}
        style={{
          borderLeft: isLast ? "1px solid transparent" : "1px solid #00000021",
        }}
        ml={17}
        mt={-10}
      >
        {item?.note && (
          <ActivityNote item={item} isEdit={isEdit} setIsEdit={setIsEdit} />
        )}
      </Group>
    </Stack>
  );
};

export default ActivityItem;
