import { useState } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useDisclosure } from "@mantine/hooks";
import {
  ActionIcon,
  Button,
  Group,
  Loader,
  Modal,
  Radio,
  Select,
  Stack,
  Text,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import BackButton from "../../../../components/View/BackButton";
import { IconExternalLink, IconWorldSearch } from "@tabler/icons-react";
import { useProfileStore } from "../../../../stores/UserStore";
import { enrichListByExternalApi } from "../../../../api/ContactAPI";
import { notifications } from "@mantine/notifications";
import { useParams } from "react-router-dom";
import { transformFilterToServer } from "../../../../functions/utilFunctions";
import { getAIKeys } from "../../../../api/ConfigsApi";
import kaspr_logo from "../../../../assets/logos/kaspr.png";

const EnrichDatafieldExternal = ({
  onFinish,
  filter,
  total,
  filterResultNumber,
}) => {
  const { contactListId } = useParams();
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);
  const [opened, { open, close }] = useDisclosure();
  const [loading, setLoading] = useState(false);

  const [apiKeys, setApiKeys] = useState([]);
  const [loadingKeys, setLoadingKeys] = useState(true);
  const [apiKey, setApiKey] = useState(null);
  const [apiKeyError, setApiKeyError] = useState(false);
  const [enrichType, setEnrichType] = useState("kaspr");

  const handleGetKeys = () => {
    getAIKeys()
      .then((res) => {
        setApiKeys(
          res.data.external_api_keys_list?.filter((x) => x.type === enrichType)
        );
      })
      .finally(() => setLoadingKeys(false));
  };

  const handleOpen = (e) => {
    open();
    handleGetKeys();
    e.stopPropagation();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let fd = new FormData(e.target);
    let data = Object.fromEntries(fd);

    let type = data.type;

    setApiKeyError(false);
    if (!apiKey) {
      document.getElementById("api_key").focus();
      return setApiKeyError(true);
    }

    setLoading(true);
    enrichListByExternalApi({
      contact_list_id: parseInt(contactListId),
      filter:
        type === "filter"
          ? transformFilterToServer(filter)
          : { mode: "or", values: [] },
      external_api_key_id: parseInt(apiKey),
      enrich_external_api: enrichType,
    })
      .then((res) => {
        notifications.show({
          message: lang.contact_lists.datafield_content.success_enrich,
          color: "tertiary.8",
        });
        close();
        onFinish?.();
      })
      .finally(() => setLoading(false));
  };

  const handleClose = () => {
    close();
  };

  return (
    <>
      <ActionIcon
        variant="subtle"
        onClick={handleOpen}
        id={`kaspr-button`}
        style={{ display: "none" }}
      >
        <img src={kaspr_logo} style={{ width: 20 }} />
      </ActionIcon>

      {opened && (
        <Modal
          opened={opened}
          size={500}
          onClose={handleClose}
          title={
            <>
              {lang.contact_lists.datafield_content.dialog.title_enrich_kaspr}
            </>
          }
          styles={{
            title: {
              lineHeight: 1.5,
            },
          }}
        >
          <Stack
            component={"form"}
            onSubmit={handleSubmit}
            gap={"xs"}
            pos={"relative"}
          >
            <Text fw={600}>
              {
                lang.contact_lists.datafield_content.dialog
                  .text_enrich_kaspr_info
              }
            </Text>

            <Group wrap="nowrap" align="start" gap={"sm"}>
              <Select
                id="api_key"
                label={
                  lang.contact_lists.datafield_content.dialog.kaspr_api_key
                }
                required
                placeholder={
                  lang.contact_lists.datafield_content.dialog.kaspr_api_key
                }
                flex={1}
                data={apiKeys.map((item) => ({
                  label: item.name,
                  value: item.id.toString(),
                }))}
                rightSection={loadingKeys ? <Loader size={16} /> : null}
                value={apiKey}
                onChange={setApiKey}
                error={
                  apiKeyError
                    ? lang.contact_lists.datafield_content.dialog.api_key_needed
                    : null
                }
              />

              <Tooltip
                label={lang.contact_lists.datafield_content.dialog.keys_list}
              >
                <Button
                  variant="light"
                  component={"a"}
                  href={`/extra/external-api-keys`}
                  target="_blank"
                  px={12}
                  mt={22}
                >
                  <IconExternalLink size={18} />
                </Button>
              </Tooltip>
            </Group>

            <Radio
              disabled={!filter || filter?.items?.length < 1}
              label={`${lang.contact_lists.enrich_contacts.enrich_filter} ${
                filterResultNumber ? `(${filterResultNumber})` : ""
              }`}
              name="type"
              value={"filter"}
              mt={"xs"}
            />
            <Radio
              label={`${lang.contact_lists.enrich_contacts.enrich_all} ${
                total ? `(${total})` : ""
              }`}
              name="type"
              value={"all"}
              defaultChecked
              mt={4}
            />

            <Group justify="end" mt={"md"}>
              <BackButton onClick={handleClose} />

              <Button
                leftSection={
                  <ThemeIcon size={24} variant="subtle">
                    <img src={kaspr_logo} style={{ width: 22 }} />
                  </ThemeIcon>
                }
                loading={loading}
                color="primary"
                type="submit"
                // variant="light"
              >
                {
                  lang.contact_lists.datafield_content.dialog
                    .submit_button_enrich
                }
              </Button>
            </Group>
          </Stack>
        </Modal>
      )}
    </>
  );
};

export default EnrichDatafieldExternal;
