import { Button } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { useLanguage } from "../../../stores/LanguageStore";

const CreateApiKeyButton = () => {
  const lang = useLanguage((s) => s.language);
  const route = `/api/create`;

  return (
    <Button
      leftSection={<IconPlus size={18} />}
      radius={"sm"}
            color="primary"
      component={Link}
      to={route}
    >
      {lang.api_keys.create.button_tooltip}
    </Button>
  );
};

export default CreateApiKeyButton;
