import { useGridApiRef } from "@mui/x-data-grid-pro";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useContactHandler } from "../../lists/components/useContactHandler";
import {
  deleteBlacklistData,
  deleteBlacklistDatas,
  searchAllBlacklist,
} from "../../../../api/BlacklistAPI";
import { useCallback, useMemo } from "react";
import ItemDeleter from "../../../../components/TableComponents/ItemDeleter";
import SelectionDeleter from "../../../../components/TableComponents/SelectionDeleter";
import MainTable from "../../../../layouts/Table/MainTable";
import { Anchor, Group } from "@mantine/core";
import TableLayoutBlock from "../../../../layouts/Table/TableLayoutBlock";
import BlacklistImportButton from "./BlacklistImportButton";
import Path from "../../../../layouts/Path";
import { useConfigStore } from "../../../../stores/ConfigStore";
import { Link } from "react-router-dom";

function resultsHandler(response) {
  const datafields = useConfigStore.getState().datafields;
  let results = response;

  return results.map((data) => {
    let current_field = datafields.find((x) => x.id === data.data_field_id);

    if (current_field) {
      return {
        ...data,
        [current_field.id]: data.value,
      };
    }
    return {
      ...data,
    };
  });
}

const BlacklistsAllSearch = () => {
  const lang = useLanguage((s) => s.language);
  const apiRef = useGridApiRef();

  const startingColumns = useMemo(
    () => [
      {
        field: "blacklist_id",
        id: "blacklist_id",
        headerName: lang.prm.details.campaigns_table.contact_list,
        filterable: false,
        sortable: false,
        width: 150,
        renderCell: ({ row, value }) => {
          return (
            <Anchor
              component={Link}
              to={`/exclusions/blacklists/${value}`}
              target="_blank"
              c="inherit"
              fw={500}
              truncate="end"
            >
              {row.blacklist_name}
            </Anchor>
          );
        },
      },
    ],
    [lang.prm.details.campaigns_table.contact_list]
  );

  const {
    loading,
    columns,
    rows,
    rowCount,
    serverInfo,
    handleLoading,
    onFilterChange,
    onPaginationChange,
    onSortChange,
  } = useContactHandler({
    apiRef,
    getFirstPage: searchAllBlacklist,
    searchFunction: searchAllBlacklist,
    disableQueryTransform: true,
    resultsHandler,
    startingColumns,
  });

  const actionsCell = useCallback(
    ({ row, value }) => {
      return (
        <ItemDeleter
          title_bold={`#${row.id}`}
          handleDelete={deleteBlacklistData}
          deletedMessage={lang.blacklists.delete_success}
          parentId={row.blacklist_id}
          id={row.id}
          apiRef={apiRef}
        />
      );
    },
    [apiRef, lang.blacklists.delete_success]
  );

  const toolbarActions = useCallback(
    ({ selectedIds }) => (
      <SelectionDeleter
        handleMultipleDelete={deleteBlacklistDatas}
        apiRef={apiRef}
        setTableLoading={() => handleLoading()}
        ids={selectedIds}
      />
    ),
    [apiRef, handleLoading]
  );

  const params = new URLSearchParams(location.search);
  const initialQuery = params.get("query");
  const initialFilter = params.get("filter");

  if (!columns) {
    return;
  }

  return (
    <>
      <Group className="layout-block top" w={"100%"} justify="space-between">
        <Path />

        <BlacklistImportButton />
      </Group>

      <TableLayoutBlock>
        <MainTable
          isClient={false}
          apiRef={apiRef}
          columns={columns}
          rows={rows}
          rowCount={rowCount}
          loading={loading}
          setLoading={() => {
            handleLoading();
          }}
          id={"list-table"}
          pageName={"blacklists"}
          onPaginationChange={onPaginationChange}
          actionsCell={actionsCell}
          onFilterChange={onFilterChange}
          onSortChange={onSortChange}
          pageOrderName="blacklists_order"
          toolbarActions={toolbarActions}
          defaultSearch={initialQuery}
          defaultFilter={initialFilter ? JSON.parse(initialFilter) : undefined}
        />
      </TableLayoutBlock>
    </>
  );
};

export default BlacklistsAllSearch;
