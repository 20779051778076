import { useMemo, useState } from "react";
import { Group, ThemeIcon, Tooltip } from "@mantine/core";
import { useConfigStore } from "../../stores/ConfigStore";
import { useLanguage } from "../../stores/LanguageStore";
import Selector from "./Selector";
import { getSignatures } from "../../api/ConfigsApi";
import { IconInfoCircle } from "@tabler/icons-react";

function getOptionName(option) {
  return `${option.name} #${option.id}`;
}

const EmailSignatureSelector = ({
  id,
  label,
  placeholder,
  size,
  value,
  onChange,
  leftSection,
  disabled,
  accountIsPool,
  ...props
}) => {
  const lang = useLanguage((s) => s.language);
  const signs = useConfigStore((s) => s.email_signatures);
  const [loading, setLoading] = useState(false);
  const handleLoad = (e) => {
    setLoading(true);
    getSignatures().finally(() => setLoading(false));
  };

  const options = useMemo(() => {
    let result = [
      { label: "Text", options: [] },
      { label: "Template", options: [] },
    ];
    signs?.forEach((account) => {
      result[account?.is_template ? 1 : 0].options.push({
        ...account,
        option_name: getOptionName(account),
      });
    });
    return signs.length > 0 ? result : [];
  }, [signs]);

  return (
    <Group wrap="nowrap" align="flex-start" gap={"xs"} {...props}>
      <Selector
        disabled={disabled}
        id={id}
        label={label}
        leftSection={leftSection}
        loading={loading}
        onChange={(val) => onChange(val)}
        onDropdownOpen={handleLoad}
        options={options}
        description={props.description}
        placeholder={
          placeholder || lang.campaign.selectors.signature.placeholder
        }
        size={size}
        value={value}
        getOptionName={getOptionName}
        hasGroup
      />
      {accountIsPool && value && (
        <Tooltip label={lang.campaign.selectors.signature.alert}>
          <ThemeIcon variant="light">
            <IconInfoCircle size={20} />
          </ThemeIcon>
        </Tooltip>
      )}
    </Group>
  );
};

export default EmailSignatureSelector;
