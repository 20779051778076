import { useState } from "react";
import { createField, getFields } from "../../../../../api/ContactAPI";
import { notifications } from "@mantine/notifications";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { useConfigStore } from "../../../../../stores/ConfigStore";
import { useDisclosure } from "@mantine/hooks";
import {
  Button,
  Group,
  Menu,
  Modal,
  Stack,
  TagsInput,
  TextInput,
  ThemeIcon,
} from "@mantine/core";
import BackButton from "../../../../../components/View/BackButton";
import { IconSelector } from "@tabler/icons-react";

const CreateFieldOptions = ({ onFinish }) => {
  const lang = useLanguage((s) => s.language);
  const fields = useConfigStore((s) => s.datafields);

  const [opened, { open, close }] = useDisclosure();
  const [possibleValues, setPossibleValues] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [creatingDatafield, setCreatingDatafield] = useState(false);
  const [error, setError] = useState(false);
  const createDatafield = (e) => {
    e.preventDefault();
    let fd = new FormData(e.target);
    let data = Object.fromEntries(fd);

    if (fields.some((x) => x.name.toLowerCase() === data?.name.toLowerCase())) {
      return setError(true);
    }
    setCreatingDatafield(true);
    createField({ name: data?.name, possible_values: possibleValues })
      .then((res) => {
        notifications.show({
          message: lang.datafields.row_highlight.created,
          color: "tertiary.8",
        });

        getFields()
          .then(() => {
            onFinish?.({ data_field_id: res.data.data_field_id });
            close();
          })
          .finally(() => setCreatingDatafield(false));
      })
      .catch(() => setCreatingDatafield(false));
  };

  return (
    <>
      <Menu.Item onClick={open}>
        <Group gap={4}>
          <ThemeIcon variant="subtle" p={0}>
            <IconSelector size={18} />
          </ThemeIcon>
          {lang.contact_lists.create_column.label_options}
        </Group>
      </Menu.Item>

      {opened && (
        <Modal
          opened={opened}
          onClose={close}
          title={lang.contact_lists.create_column.title}
          centered
        >
          <Stack component={"form"} onSubmit={createDatafield} gap={"xs"}>
            <TextInput
              name="name"
              required
              label={lang.contact_lists.create_column.label}
              placeholder={lang.contact_lists.create_column.placeholder}
              data-autofocus
              onChange={(e) => {
                setError(false);
              }}
              error={error ? lang.contact_lists.create_column.error : null}
            />

            <TagsInput
              label={lang.datafields.create.dialog.possible_values}
              placeholder={lang.datafields.create.dialog.possible_values}
              description={lang.datafields.create.dialog.possible_values_desc}
              value={possibleValues}
              onChange={setPossibleValues}
              onBlur={(e) => {
                let v = e.target.value;
                if (v) {
                  setPossibleValues([...possibleValues, v]);
                  setSearchValue("");
                }
              }}
              searchValue={searchValue}
              onSearchChange={setSearchValue}
            />

            <Group justify="end" mt={"md"}>
              <BackButton onClick={close} />
              <Button type="submit" loading={creatingDatafield}>
                {lang.datafields.create.dialog.submit_button}
              </Button>
            </Group>
          </Stack>
        </Modal>
      )}
    </>
  );
};

export default CreateFieldOptions;
