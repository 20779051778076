import { useState } from "react";
import { createOutlook, getEmails } from "../../../../../api/EmailAPI";
import { Button } from "@mantine/core";
import outlook_logo from "../../../../../assets/logos/outlook.svg";
import { defaultSMTPConfigs } from "./defaultConfigs";

const CreateOutlook = ({ onCreated, handleClose }) => {
  const [loading, setLoading] = useState(false);

  const handleGmail = () => {
    setLoading(true);
    createOutlook({
      data: {
        ...defaultSMTPConfigs,
        pools_ids: [],
      },
    })
      .then((response) => {
        getEmails()
          .then((res) => {
            let newItemsList = res.data.email_accounts_list;
            let newItem = newItemsList.find(
              (x) => x.id === response.data.email_account?.id
            );
            onCreated?.(newItem);
            handleClose?.();
          })
          .finally(() => {
            setLoading(false);
          });
        window.open(response.data?.email_account?.redirect_url, "_blank");
      })
      .catch((e) => setLoading(false));
  };

  return (
    <Button
      variant="default"
      w={"100%"}
      h={120}
      px={0}
      styles={{
        label: {
          justifyContent: "center",
        },
      }}
      radius={"lg"}
      onClick={handleGmail}
      loading={loading}
    >
      <img src={outlook_logo} width={160} />
    </Button>
  );
};

export default CreateOutlook;
