import { Anchor, Group, Stack, Tooltip } from "@mantine/core";
import { useLanguage } from "../../../../../stores/LanguageStore";
import CampaignColumnChildCell from "./CampaignColumnChildCell";
import { getTypeIcon } from "../../../workflows/CreateCampaign/flow/flowSettings";
import { Link } from "react-router-dom";

const ChildCell = ({ step }) => {
  const lang = useLanguage.getState().language;
  const account = step?.integration || step?.pool;
  let parentLink = "";
  if (step?.pool?.id) {
    if (step.pool?.type === "Email") {
      parentLink = "email/pool";
    } else {
      parentLink = "linkedin/pool";
    }
  } else if (step.action_type === "email") {
    parentLink = "email";
  } else if (step.action_type.includes("linkedin")) {
    parentLink = "linkedin";
  }
  if (!account) {
    return "-";
  }
  return (
    <CampaignColumnChildCell>
      {getTypeIcon(
        step?.pool
          ? "Pool"
          : step?.integration?.account_type_email || step?.action_type,
        false,
        15,
        {
          size: "sm",
          opacity: 0.75,
          variant: "light",
          color: "black",
        }
      )}
      <Group w={"calc(100% - 32px)"} wrap="nowrap">
        <Tooltip
          label={`${lang.statistics.columns.sender.view} : ${account?.name} ${account?.id ? `#${account.id}` : ""}`}
          disabled={!account?.id}
        >
          <Anchor
            component={account?.id ? Link : undefined}
            size="sm"
            c={"black"}
            truncate="end"
            to={account?.id ? `/senders/${parentLink}/${account.id}` : null}
            fw={600}
            flex={"1 1 auto"}
            underline={account?.id ? "hover" : "never"}
            style={{
              cursor: account?.id ? "pointer" : "text"
            }}
          >
            {account?.name || "-"} {account?.id ? `#${account.id}` : ""}
          </Anchor>
        </Tooltip>
      </Group>
    </CampaignColumnChildCell>
  );
};

const ParentCell = ({ row }) => {
  const lang = useLanguage.getState().language;
  const step = row?.steps?.[0];
  const account = step?.integration || step?.pool;

  let parentLink = "";
  if (step?.pool?.id) {
    if (step?.pool?.type === "Email") {
      parentLink = "email/pool";
    } else {
      parentLink = "linkedin/pool";
    }
  } else if (step?.action_type === "email") {
    parentLink = "email";
  } else if (step?.action_type.includes("linkedin")) {
    parentLink = "linkedin";
  }
  if (!account) {
    return "-";
  }

  return (
    <Group w="100%" wrap="nowrap" gap={"xs"}>
      {getTypeIcon(
        step?.pool
          ? "Pool"
          : step?.integration?.account_type_email || step?.action_type,
        false,
        15,
        {
          size: "sm",
          opacity: 0.75,
          variant: "light",
          color: "black",
        }
      )}
      <Group w={"calc(100% - 32px)"} wrap="nowrap">
        <Tooltip
          label={`${lang.statistics.columns.sender.view} : ${account?.name} ${account?.id ? `#${account.id}` : ""}`}
          disabled={!account?.id}
        >
          <Anchor
            component={account?.id ? Link : undefined}
            size="sm"
            c={"black"}
            truncate="end"
            to={account?.id ? `/senders/${parentLink}/${account.id}` : null}
            fw={600}
            flex={"1 1 auto"}
            underline={account?.id ? "hover" : "never"}
            style={{
              cursor: account?.id ? "pointer" : "text"
            }}
          >
            {account?.name || "-"} {account?.id ? `#${account.id}` : ""}
          </Anchor>
        </Tooltip>
      </Group>
    </Group>
  );
};

export const CampaignColumnSender = () => {
  const lang = useLanguage.getState().language;
  return {
    field: "sender",
    headerName: lang.statistics.columns.sender.header_name,
    width: 150,
    renderCell: ({ row, value }) => {
      if (!row) {
        return;
      }
      if (row.isChild) {
        return (
          <Stack py={"xs"} w={"100%"} h={"100%"}>
            {row.steps.map((step, index) => (
              <ChildCell key={step.id} step={step} />
            ))}
          </Stack>
        );
      }
      return <ParentCell row={row} />;
    },
  };
};
