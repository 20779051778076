import { useState } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useProfileStore } from "../../../../stores/UserStore";
import { updateProfile } from "../../../../api/UserAPI";
import { editList } from "../../../../api/ContactAPI";
import { useListStore } from "../../../../stores/ListStore";
import { Button, Group, Modal, Text, Tooltip } from "@mantine/core";
import BackButton from "../../../../components/View/BackButton";
import CountrySelector from "../../../../components/Selectors/CountrySelector";
import { IconWorld } from "@tabler/icons-react";

const ListCountry = ({ current, isProfile, onFinish }) => {
  const profile = useProfileStore((s) => s.profile);
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(false);
  const [change, setChange] = useState();

  const [key, setKey] = useState(0);

  const onChange = (v) => {
    setChange(v);
  };
  const handleClose = (v) => {
    setChange(null);
    setKey((prev) => prev + 1);
  };

  const handleCountryChange = () => {
    setLoading(true);

    const f = isProfile
      ? async () => updateProfile(current.id, { contact_list_country: change })
      : async () => editList({ id: current.id, country: change });

    f()
      .then(() => {
        if (!isProfile) {
          let lists = useListStore.getState().lists;
          let setLists = useListStore.getState().setLists;
          setLists(
            lists.map((x) => {
              if (x.id === current.id) {
                return {
                  ...x,
                  country: change,
                };
              }
              return x;
            })
          );
        } else {
          let setProfile = useProfileStore.getState().setProfile;
          setProfile({
            ...profile,
            contact_list_country: change,
          });
        }

        onFinish?.();
        setChange(null);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <CountrySelector
        key={key}
        onChange={onChange}
        required={false}
        name={isProfile ? "contact_list_country" : "country"}
        defaultValue={
          current?.[isProfile ? "contact_list_country" : "country"] ||
          profile?.contact_list_country
        }
        noLabel
        size="xs"
        ml={-16}
        leftSection={
          <Tooltip label={lang.contact_lists.translate.dialog.text_country}>
            <IconWorld size={18} />
          </Tooltip>
        }
      />

      <Modal
        opened={!!change}
        onClose={handleClose}
        title={lang.contact_lists.translate.title_country}
        centered
      >
        <Text size="sm" mb={16}>
          {lang.contact_lists.translate.warning_country}
        </Text>
        <Group justify="end">
          <BackButton onClick={handleClose} />
          <Button onClick={handleCountryChange} loading={loading}>
            {lang.contact_lists.translate.confirm}
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export default ListCountry;
