import { useMemo } from "react";
import { useLanguage } from "../../stores/LanguageStore";
import { Select, Tooltip } from "@mantine/core";
import { useProfileStore } from "../../stores/UserStore";
import { IconLanguage } from "@tabler/icons-react";

const GlobalLanguageSelector = ({
  noLabel,
  name,
  defaultValue,
  withinPortal = true,
  tooltip,
  size = "xs",
  ...props
}) => {
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);

  const langOptions = useMemo(() => {
    let reference = lang.contact_lists.jobs.completed.language_maps;
    let l = Object.keys(reference).map((key) => ({
      label: reference[key],
      value: key,
    }));

    return l;
  }, [lang]);
  return (
    <Select
      name={name}
      label={noLabel ? null : lang.contact_lists.icebreaker.dialog.language}
      placeholder={lang.contact_lists.icebreaker.dialog.language}
      required
      size={size}
      data={langOptions}
      defaultValue={defaultValue || profile?.language}
      searchable
      comboboxProps={{
        withinPortal,
      }}
      w={130}
      leftSection={
        <Tooltip
          label={tooltip || lang.contact_lists.icebreaker.dialog.language}
        >
          <IconLanguage size={18} />
        </Tooltip>
      }
      {...props}
    />
  );
};

export default GlobalLanguageSelector;
