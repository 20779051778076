import {
  Box,
  Group,
  ThemeIcon,
  Tooltip,
  UnstyledButton,
  rem,
} from "@mantine/core";
import classes from "./NavbarLinksGroup.module.css";
import { Link, useMatches } from "react-router-dom";
import { forwardRef } from "react";
import { IconChevronRight } from "@tabler/icons-react";

const NavbarLinkButton = forwardRef(
  (
    {
      icon: Icon,
      path,
      opened,
      hasLinks,
      closed,
      name,
      noNav,
      customIcon,
      isPricing,
      ...others
    },
    ref
  ) => {
    const matches = useMatches();

    return (
      <Tooltip label={name} disabled={!closed} position="right" offset={-4}>
        <UnstyledButton
          {...others}
          component={noNav ? undefined : Link}
          to={path}
          className={`${classes.control} ${customIcon ? "" : "bump"}`}
          ref={ref}
          py="xs"
          px="md"
          pl={closed ? 22 : 16}
          style={{
            color: matches.find((x) => x.pathname.includes(path))
              ? "var(--mantine-primary-color-filled)"
              : "var(--mantine-color-dark-3)",
            // borderLeft: "6px solid",
            // borderLeftColor: matches.find((x) => x.pathname.includes(path))
            //   ? "var(--mantine-primary-color-filled)"
            //   : "transparent",
            background: matches.find((x) => x.pathname.includes(path))
              ? "var(--mantine-color-primary-0)"
              : "transparent",
          }}
        >
          <Group justify="space-between" gap={0} wrap="nowrap">
            <Box style={{ display: "flex", alignItems: "center" }}>
              {customIcon || (
                <ThemeIcon
                  variant={"filled"}
                  size={32}
                  color={
                    matches.find((x) => x.pathname.includes(path))
                      ? "primary"
                      : "gray.2"
                  }
                >
                  <Icon
                    style={{
                      width: rem(20),
                      height: rem(20),
                      color: matches.find((x) => x.pathname.includes(path))
                        ? "white"
                        : "var(--mantine-color-gray-6)",
                    }}
                  />
                </ThemeIcon>
              )}
              {!closed && (
                <Box
                  ml="md"
                  fw={
                    isPricing
                      ? 650
                      : matches.find((x) => x.pathname.includes(path))
                      ? 650
                      : 550
                  }
                  className="name"
                >
                  {name}
                </Box>
              )}
            </Box>

            {hasLinks && !closed && (
              <IconChevronRight
                className={classes.chevron}
                stroke={1.5}
                style={{
                  marginRight: -8,
                  width: rem(16),
                  height: rem(16),
                  transform: opened ? "rotate(-90deg)" : "none",
                }}
              />
            )}
          </Group>
        </UnstyledButton>
      </Tooltip>
    );
  }
);

NavbarLinkButton.displayName = "NavbarLinkButton";

export default NavbarLinkButton;
