import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Checkbox,
  Combobox,
  Flex,
  Group,
  Highlight,
  Loader,
  Paper,
  Pill,
  PillsInput,
  Stack,
  Text,
  useCombobox,
} from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import { useDebouncedValue } from "@mantine/hooks";
import { useLanguage } from "../../stores/LanguageStore";

const MultiSelector = ({
  id,
  label,
  placeholder,
  size,
  required,
  values = [],
  onChange,
  leftSection,
  disabled,
  getError,
  options,
  leftOptionSection,
  rightOptionSection,
  loading,
  onDropdownOpen,
  getValidOption,
  dropdownHeight = 200,
  footer,
  withDropdown,
  inputProps,
  inputStyles,
  option_name_props,
  maxInputHeight = 80,
  onDebouncedChange,
  newItems,
  autoFocus = true,
  checkboxColor = "primary",
  rightSection,
}) => {
  const lang = useLanguage((s) => s.language);
  const combobox = useCombobox({
    // onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => {
      combobox.updateSelectedOptionIndex("active");
      onDropdownOpen?.();
    },
  });
  const [search, setSearch] = useState("");
  const [debounced] = useDebouncedValue(search, 500);
  useEffect(() => {
    onDebouncedChange?.(debounced);
  }, [debounced]);

  const handleValueSelect = (value) => {
    let newValues = values?.find((x) => x.id === value.id)
      ? values?.filter((v) => v.id !== value.id)
      : [...values, options.find((x) => x.id === value.id)];

    onChange(newValues);
    setSearch("");
  };

  const handleValueRemove = useCallback(
    (val) => {
      onChange(values?.filter((v) => v?.id !== val?.id));
    },
    [onChange, values]
  );

  const pills = useMemo(
    () =>
      values?.map((item) => {
        let isNew = newItems?.includes(item?.id);
        return (
          <Pill
            key={item?.id}
            withRemoveButton
            disabled={disabled}
            onRemove={() => handleValueRemove(item)}
            size={size === "sm" ? "xs" : "sm"}
            bg={isNew ? "var(--mantine-color-green-1)" : undefined}
          >
            {item?.option_name}
          </Pill>
        );
      }),
    [disabled, handleValueRemove, newItems, size, values]
  );

  const renderedOptions = useMemo(
    () =>
      options
        .filter((item) => {
          let searchTarget = `${item?.option_name}#${item?.id}`;

          return searchTarget
            ?.toLowerCase()
            .includes(search.trim().toLowerCase());
        })
        .map((item) => (
          <Combobox.Option
            value={item}
            key={item.id}
            active={values?.find((x) => x?.option_name === item.option_name)}
            style={{
              zIndex: 99999,
            }}
            styles={{
              option: {
                zIndex: 999999999999,
              },
            }}
            disabled={disabled}
          >
            <Group gap="sm" wrap="nowrap">
              <Checkbox
                checked={values?.find((x) => x?.id === item.id)}
                onChange={() => {}}
                aria-hidden
                tabIndex={-1}
                style={{ pointerEvents: "none" }}
                size="xs"
                disabled={disabled}
                color={checkboxColor}
              />
              {leftOptionSection?.(item)}
              <Highlight
                mr={"auto"}
                fz={"sm"}
                c={
                  getValidOption
                    ? !getValidOption(item)
                      ? "red"
                      : undefined
                    : undefined
                }
                highlight={search}
                size="sm"
                truncate="end"
                {...option_name_props}
              >
                {item.option_name}
              </Highlight>
              {rightOptionSection?.(item)}
            </Group>
          </Combobox.Option>
        )),
    [
      options,
      search,
      values,
      disabled,
      checkboxColor,
      leftOptionSection,
      getValidOption,
      option_name_props,
      rightOptionSection,
    ]
  );

  const onClear = () => {
    onChange([]);
    setSearch("");
  };

  const DropdownContent = () => (
    <>
      {renderedOptions?.length > 0 ? (
        <Combobox.Options>{renderedOptions}</Combobox.Options>
      ) : loading ? (
        <Flex
          h={"100%"}
          w={"100%"}
          justify={"center"}
          align={"center"}
          py={"xs"}
        >
          <Loader size={"xs"} type="oval" />
        </Flex>
      ) : (
        <Flex h={"100%"} w={"100%"} justify={"center"} align={"center"} mt={8}>
          <Text opacity={0.35} mb={"sm"} size="sm">
            {lang.global.no_element_found}
          </Text>
        </Flex>
      )}
      {footer && (
        <Paper
          component={Group}
          pos={"sticky"}
          mt={"auto"}
          bottom={0}
          left={0}
          style={{ borderTop: "1px solid #00000021" }}
          p={"xs"}
          w={"100%"}
          radius={0}
        >
          {footer}
        </Paper>
      )}
    </>
  );

  return (
    <Stack w={"100%"} h={"100%"} gap={0}>
      <Combobox
        store={combobox}
        onOptionSubmit={handleValueSelect}
        offset={0}
        shadow="sm"
      >
        <Combobox.DropdownTarget>
          <PillsInput
            id={id}
            pointer
            label={label}
            leftSection={leftSection}
            rightSectionWidth={rightSection ? 135 : undefined}
            rightSection={
              <>
                {loading ? (
                  <Loader size={16} type="dots" />
                ) : values?.length > 0 ? (
                  <Combobox.ClearButton onClear={onClear} disabled={disabled} />
                ) : (
                  <IconSearch size={16} />
                )}
                {rightSection}
              </>
            }
            required={required}
            error={getError}
            disabled={disabled}
            size={size}
            w={"100%"}
            styles={{
              input: {
                borderBottomLeftRadius: withDropdown ? undefined : 0,
                borderBottomRightRadius: withDropdown ? undefined : 0,
                overflow: "auto",
                maxHeight: maxInputHeight,
                ...inputStyles,
              },
            }}
            {...inputProps}
          >
            <Pill.Group mt={1}>
              {pills.length > 0 ? pills : null}

              <Combobox.EventsTarget>
                <PillsInput.Field
                  // onFocus={() => combobox.openDropdown()}
                  onClick={() => combobox.openDropdown()}
                  value={search}
                  placeholder={placeholder}
                  autoFocus={autoFocus}
                  onChange={(event) => {
                    combobox.updateSelectedOptionIndex();
                    setSearch(event.currentTarget.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Backspace" && search.length === 0) {
                      event.preventDefault();
                      handleValueRemove(values[values?.length - 1]);
                    }
                    event.stopPropagation();
                  }}
                />
              </Combobox.EventsTarget>
            </Pill.Group>
          </PillsInput>
        </Combobox.DropdownTarget>

        {withDropdown ? (
          <Combobox.Dropdown>
            <Combobox.Options mah={128} style={{ overflowY: "auto" }}>
              <DropdownContent />
            </Combobox.Options>
          </Combobox.Dropdown>
        ) : (
          <Paper
            withBorder
            style={{
              borderTop: 0,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              overflow: "auto",
              position: "relative",
              flex: "1 1 auto",
            }}
            pt={4}
            h={dropdownHeight}
            component={Stack}
          >
            <DropdownContent />
          </Paper>
        )}
      </Combobox>
    </Stack>
  );
};

export default MultiSelector;
