import { Stack } from "@mantine/core";
import Header from "./Header";
import "./support.scss";
import Footer from "./Footer";
import { lazy } from "react";
const KnowledgeBase = lazy(() => import("./base/KnowledgeBase"));

const Support = () => {
  document.title = " Support";
  return (
    <Stack w={"100%"} gap={0}>
      <Header />
      <KnowledgeBase />
      <Footer />
    </Stack>
  );
};

export default Support;
