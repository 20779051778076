import { useState } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useDisclosure } from "@mantine/hooks";
import { Button, Group, Modal, Stack, Text } from "@mantine/core";
import BackButton from "../../../../components/View/BackButton";
import { IconUserUp } from "@tabler/icons-react";
import { copyContactListToPrm } from "../../../../api/ContactAPI";
import { notifications } from "@mantine/notifications";
import PRMStatusSelector from "../../../../components/Selectors/PRMStatusSelector";

const ListCopyToPrm = ({ current, onFinish, closeMenu }) => {
  const lang = useLanguage((s) => s.language);
  const [opened, { open, close }] = useDisclosure();
  const [loading, setLoading] = useState(false);

  const [status, setStatus] = useState("opener");

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    copyContactListToPrm({
      contact_list_id: current.id,
      custom_status: parseInt(status) ? parseInt(status) : undefined,
      status: parseInt(status) ? "opener" : status,
    })
      .then((res) => {
        notifications.show({
          message: lang.contact_lists.copy_list_prm.success,
          color: "tertiary.8",
        });
        close();
        onFinish?.();
      })
      .finally(() => setLoading(false));
  };

  const handleClose = () => {
    close();
    closeMenu?.();
  };

  return (
    <>
      <Button
        onClick={open}
        leftSection={<IconUserUp size={18} />}
        fw={600}
        color="primary"
        variant="subtle"
        justify="start"
      >
        {lang.contact_lists.copy_list_prm.label}
      </Button>

      {opened && (
        <Modal
          opened={opened}
          size={"md"}
          onClose={handleClose}
          title={
            <>
              {lang.contact_lists.copy_list_prm.dialog.title}{" "}
              <b>
                {current?.name} #{current.id}
              </b>
            </>
          }
        >
          <Stack component={"form"} onSubmit={handleSubmit} gap={"xs"}>
            <Text c={"dimmed"}>
              {lang.contact_lists.copy_list_prm.dialog.text}
            </Text>

            <PRMStatusSelector
              value={status}
              onChange={setStatus}
              w={"100%"}
              required
              withinPortal={true}
            />

            <Group justify="end" mt={"md"}>
              <BackButton onClick={handleClose} />

              <Button
                leftSection={<IconUserUp size={18} />}
                loading={loading}
                color="primary"
                type="submit"
              >
                {lang.contact_lists.copy_list_prm.dialog.submit_button}
              </Button>
            </Group>
          </Stack>
        </Modal>
      )}
    </>
  );
};

export default ListCopyToPrm;
