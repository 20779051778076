import React from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { useDisclosure } from "@mantine/hooks";
import { Button, Modal } from "@mantine/core";
import { IconStar } from "@tabler/icons-react";
import ScoreContent from "./ScoreContent";

const ScoreSettings = ({ disabled }) => {
  const lang = useLanguage((s) => s.language);
  const [opened, { open, close }] = useDisclosure();

  return (
    <>
      <Button
        variant="light"
        radius={"sm"}
        leftSection={<IconStar size={18} />}
        color="primary"
        onClick={open}
        disabled={disabled}
      >
        {lang.prm.scoring.button_label}
      </Button>

      <Modal
        opened={opened}
        onClose={close}
        title={lang.prm.nurturing.dialog_title}
        size={"50%"}
      >
        <ScoreContent handleClose={close} />
      </Modal>
    </>
  );
};

export default ScoreSettings;
