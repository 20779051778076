import { Group } from "@mantine/core";
import StatusSettings from "./status/StatusSettings";
import NurturingSettings from "./nurturing/NurturingSettings";
import ZapierSettings from "./zapier/ZapierSettings";
import LeadsSharing from "./sharing/LeadsSharing";
import { useParams } from "react-router-dom";
import { useProfileStore } from "../../../../stores/UserStore";
import ScoreSettings from "./score/ScoreSettings";

const TitleSection = () => {
  const { userId } = useParams();
  const profile = useProfileStore((s) => s.profile);

  return (
    <Group>
      <StatusSettings
        disabled={userId ? parseInt(userId) !== profile.id : false}
      />
      <NurturingSettings
        disabled={userId ? parseInt(userId) !== profile.id : false}
      />
      <ZapierSettings
        disabled={userId ? parseInt(userId) !== profile.id : false}
      />
      <LeadsSharing
        disabled={userId ? parseInt(userId) !== profile.id : false}
      />
      <ScoreSettings
        disabled={userId ? parseInt(userId) !== profile.id : false}
      />
    </Group>
  );
};

export default TitleSection;
