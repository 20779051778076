import { Group, HoverCard, Stack, Text } from "@mantine/core";
import { useLanguage } from "../../../../../stores/LanguageStore";
import {
  allEvents,
  getDelay,
} from "../../../workflows/CreateCampaign/flow/nodes/event/events";
import CampaignColumnChildCell from "./CampaignColumnChildCell";

const Cell = ({ step }) => {
  const lang = useLanguage((s) => s.language);
  let parents = step.parent_steps;
  return (
    <CampaignColumnChildCell>
      <Group
        justify={"center"}
        style={{
          padding: "2px 4px",
          backgroundColor: step.details.find(
            (x) => x.level === "critical" || x.level === "error"
          )
            ? "#a00"
            : step.is_stopped
            ? "#DB7304"
            : "#19c39e",
          color: "#fff",
          width: "100%",
        }}
      >
        <HoverCard openDelay={300} position="top" withArrow offset={2}>
          <HoverCard.Target>
            <Text
              size="sm"
              fw={500}
              px={4}
              style={{ cursor: "pointer" }}
              ta={"center"}
              // onClick={() => {
              //   handlePreview(
              //     {
              //       ...row.workflow,
              //       prog_id: row.prog_id,
              //       programmation: row.prog,
              //     },
              //     step.id
              //   );
              // }}
            >
              # {step.id}
            </Text>
          </HoverCard.Target>

          <HoverCard.Dropdown>
            <Text size="sm">
              {step.is_initial ? (
                lang.workflows.nodes.is_initial
              ) : (
                <>
                  <span style={{ fontWeight: 500 }}>
                    {lang.statistics.columns.id.event}
                    <br />
                  </span>
                  {parents.map((x, i) => (
                    <span key={i}>
                      {allEvents().find((e) => e.value === x.event_type)
                        ?.label || lang.statistics.columns.id.undefined}
                      : +{getDelay(x.when_minutes).value} <br />
                    </span>
                  ))}
                </>
              )}
            </Text>
          </HoverCard.Dropdown>
        </HoverCard>
      </Group>
    </CampaignColumnChildCell>
  );
};

function CampaignColumnId(handlePreview) {
  return {
    field: "id",
    headerName: "ID",
    width: 90,
    renderCell: ({ row, value }) => {
      if (!row) {
        return;
      }
      if (row.isChild) {
        return (
          <Stack py={"xs"} w={"100%"}>
            {row.steps.map((step, index) => (
              <Cell key={step.id} step={step} />
            ))}
          </Stack>
        );
      }
      return value;
    },
  };
}

export default CampaignColumnId;
