import { Button, Menu } from "@mantine/core";
import { IconChevronDown, IconPlus } from "@tabler/icons-react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import CreateFieldAny from "./CreateFieldAny";
import CreateFieldOptions from "./CreateFieldOptions";
import CreateFieldAI from "./CreateFieldAI";

const CreateField = ({ onFinish }) => {
  const lang = useLanguage((s) => s.language);

  return (
    <Menu keepMounted>
      <Menu.Target>
        <Button
          leftSection={<IconPlus size={18} />}
          ml={"auto"}
          variant="light"
          rightSection={<IconChevronDown size={16} />}
        >
          {lang.datafields.create.button_tooltip}
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        <CreateFieldAny onFinish={onFinish} />
        <CreateFieldOptions onFinish={onFinish} />
        <CreateFieldAI onFinish={onFinish} />
      </Menu.Dropdown>
    </Menu>
  );
};

export default CreateField;
