import { ActionIcon, Stack, Text } from "@mantine/core";
import { IconLine } from "@tabler/icons-react";
import { useFlowStore } from "../../../../../../../stores/WorkflowStore";
import { useLanguage } from "../../../../../../../stores/LanguageStore";

const Linker = ({ id, xPos, yPos }) => {
  const lang = useLanguage((s) => s.language);
  const setLinking = useFlowStore((s) => s.setLinking);

  const handleClick = () => {
    setLinking({ xPos, yPos, id });
  };

  return (
    <Stack
      pos={"absolute"}
      bottom={-40}
      left={-20}
      justify="center"
      align="center"
      gap={0}
    >
      <ActionIcon
        size={"xs"}
        variant="white"
        style={{ boxShadow: "#00000021 0 1px 3px" }}
        onClick={handleClick}
      >
        <IconLine size={14} />
      </ActionIcon>

      <Text size="xs" opacity={0.35} mt={2}>
        {lang.workflows.linker_text}
      </Text>
    </Stack>
  );
};

export default Linker;
