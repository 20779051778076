import { Button, Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconUserHeart } from "@tabler/icons-react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import NurturingContent from "./NurturingContent";

const NurturingSettings = ({ disabled }) => {
  const lang = useLanguage((s) => s.language);
  const [opened, { open, close }] = useDisclosure();

  return (
    <>
      <Button
        variant="light"
        radius={"sm"}
        leftSection={<IconUserHeart size={18} />}
        color="pink"
        onClick={open}
        disabled={disabled}
      >
        {lang.prm.nurturing.button_label}
      </Button>

      <Modal
        opened={opened}
        onClose={close}
        title={lang.prm.nurturing.dialog_title}
        size={"60%"}
      >
        <NurturingContent handleClose={close} />
      </Modal>
    </>
  );
};

export default NurturingSettings;
