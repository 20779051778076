import { useConfigStore } from "../stores/ConfigStore";
import { mainAxios as axios } from "./axios";

//#region API Key
export const createApiKey = async (name) => {
  let a = axios.post(`/api-keys`, {
    name: name,
  });

  return a;
};

export const getApiKeys = async () => {
  let a = axios.get(`/api-keys`);

  return a;
};

export const findApiKey = async (id) => {
  let a = axios.get(`/api-keys/${id}`);

  return a;
};

export const regenApiKey = async (id) => {
  let a = axios.put(`/api-keys/${id}/regenerate`);

  return a;
};

export const editApiKey = async ({ id, name }) => {
  let a = axios.put(`/api-keys/${id}`, { name: name });

  return a;
};

export const deleteApiKey = async ({ id }) => {
  let a = axios.delete(`/api-keys/${id}`);

  return a;
};
//#endregion

//#region URL Shortener
export const getUrlsShortened = async () => {
  let a = axios.get(`/urls-shortener`);

  return a;
};

export const createUrlShortened = async (name, url, share) => {
  let request = axios.post(`/urls-shortener`, {
    name: name,
    url: url,
    sharing: share,
  });

  return request;
};

export const editUrlShortened = async ({ id, name, url, sharing }) => {
  let request = axios.put(`/urls-shortener/${id}`, {
    name: name,
    url: url,
    sharing: sharing,
  });

  return request;
};

export const deleteUrlShortened = async ({ id }) => {
  let a = axios.delete(`/urls-shortener/${id}`);

  return a;
};
//#endregion

//#region Email Signatures
export const getSignatures = async () => {
  let response = await axios.get(`/email-signatures`);

  if (response.data.state) {
    useConfigStore
      .getState()
      .setEmailSignatures(response.data.email_signatures_list);
  }
  return response;
};

export const createSignature = async ({
  name,
  html,
  text,
  template,
  is_template,
  sharing,
}) => {
  let request = axios.post(`/email-signatures`, {
    name: name,
    html: html,
    text: text,
    json_template: template,
    is_template,
    sharing: sharing,
  });

  return request;
};

export const editSignature = async ({
  id,
  name,
  html,
  text,
  template,
  sharing,
  is_template,
}) => {
  let request = axios.put(`/email-signatures/${id}`, {
    name: name,
    html: html,
    text: text,
    json_template: template,
    sharing: sharing,
    is_template,
  });

  return request;
};

export const findSignature = async (id) => {
  return axios.get(`/email-signatures/${id}`);
};

export const deleteSignature = async ({ id }) => {
  let a = axios.delete(`/email-signatures/${id}`);

  return a;
};

export const copySignature = async ({ id }) => {
  let a = axios.post(`/email-signatures/${id}/copy`);

  return a;
};
//#endregion

//#region OPENAI API Key
export const createAIKey = async ({ name, api_key, type }) => {
  let a = axios.post(`/external-api-keys`, {
    name,
    type,
    api_key,
  });

  return a;
};

export const getAIKeys = async () => {
  let a = axios.get(`/external-api-keys`);

  return a;
};

export const findAIKey = async (id) => {
  let a = axios.get(`/external-api-keys/${id}`);

  return a;
};

export const editAIKey = async ({ id, name, api_key, type }) => {
  let a = axios.put(`/external-api-keys/${id}`, { name, type, api_key });

  return a;
};

export const deleteAIKey = async ({ id }) => {
  let a = axios.delete(`/external-api-keys/${id}`);

  return a;
};
//#endregion
