import {
  Anchor,
  CloseButton,
  Group,
  Paper,
  Text,
  ThemeIcon,
} from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import { useLanguage } from "../../stores/LanguageStore";

const DiffShow = ({ show, setShow, handleLoading }) => {
  const lang = useLanguage((s) => s.language);
  if (show)
    return (
      <Paper
        component={Group}
        pos={"relative"}
        withBorder
        p={4}
        mb={-4}
        mt={-4}
      >
        <ThemeIcon variant="subtle" color="primary" size={"sm"}>
          <IconInfoCircle />
        </ThemeIcon>

        <Text c="secondary" size="sm" fw={600}>
          {lang.contact_lists.job_finished}
        </Text>

        <Anchor
          size="sm"
          onClick={() => {
            setShow(false);
            handleLoading(true);
          }}
        >
          {lang.contact_lists.update}
        </Anchor>

        <CloseButton size={"sm"} onClick={() => setShow(false)} />
      </Paper>
    );
  return "";
};

export default DiffShow;
