import { useEffect, useMemo, useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { updateProfile, updateSelf } from "../../../../../api/UserAPI";
import { useProfileStore } from "../../../../../stores/UserStore";
import {
  Button,
  Group,
  LoadingOverlay,
  NumberInput,
  Stack,
  Text,
} from "@mantine/core";
import BackButton from "../../../../../components/View/BackButton";
import { notifications } from "@mantine/notifications";

const ScoreContent = ({ handleClose }) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(true);
  const profile = useProfileStore((s) => s.profile);
  const [submitting, setSubmitting] = useState(false);

  const defaultScore = useMemo(() => {
    if (profile?.prm_scoring) return profile.prm_scoring;
    return {
      link_click: 10,
      open: 10,
      answer: 10,
      positive_answer: 50,
      negative_answer: -10,
      invitation_accepted: 30,
    };
  }, [profile]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const fd = new FormData(e.target);
    const data = Object.fromEntries(fd);

    let newData = {};
    Object.keys(data).forEach((k) => {
      newData[k] = parseInt(data[k]);
    });

    setSubmitting(true);
    updateProfile(null, { prm_scoring: newData })
      .then((response) => {
        updateSelf();
        notifications.show({
          message: lang.prm.scoring.saved,
          color: "green.8",
        });
        handleClose();
      })
      .finally(() => setSubmitting(false));
  };

  useEffect(() => {
    if (loading) {
      updateSelf().finally(() => {
        setLoading(false);
      });
    }
  }, [loading]);

  return (
    <>
      <Stack
        pos={"relative"}
        gap={"xs"}
        h={"100%"}
        component="form"
        onSubmit={handleSubmit}
        id="form"
      >
        <LoadingOverlay visible={loading} />

        <Text c={"dimmed"}>{lang.prm.scoring.text}</Text>

        <Group justify="space-between">
          {Object.keys(defaultScore).map((key) => (
            <NumberInput
              key={key}
              name={key}
              label={lang.prm.scoring.list[key]}
              defaultValue={defaultScore[key]}
              w={"30%"}
            />
          ))}
        </Group>
      </Stack>

      <Group justify="end" mt={"xl"}>
        <BackButton onClick={handleClose} />
        <Button loading={submitting} form="form" type="submit">
          {lang.global.save}
        </Button>
      </Group>
    </>
  );
};

export default ScoreContent;
