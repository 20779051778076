import { Group, HoverCard, Text } from "@mantine/core";
import {
  copyEmail,
  copyLinkedinInvitation,
  copyLinkedinMessage,
  copySMS,
  deleteLinkedinInvitation,
  deleteLinkedinMessage,
  deleteMEmail,
  deleteSMS,
  editLinkedinInvitation,
  editLinkedinMessage,
  editMEmail,
  editSMS,
  findLinkedinInvitation,
  findLinkedinMessage,
  findMEmail,
  findSMS,
  getLinkedinInvitations,
  getLinkedinMessages,
  getMEmails,
  getSMS,
} from "../../../api/ModelsApi";
import { useLanguage } from "../../../stores/LanguageStore";
import { IconSparkles } from "@tabler/icons-react";
import { getTypeIcon } from "../workflows/CreateCampaign/flow/flowSettings";

export function parseModelType(serverType) {
  if (serverType === "Email") {
    return "email";
  }
  if (serverType === "LinkedinInvitation") {
    return "linkedin-invitation";
  }
  if (serverType === "LinkedinMessage") {
    return "linkedin-message";
  }
  if (serverType === "SMS") {
    return "sms";
  }
  if (serverType === "SMV") {
    return "smv";
  }
}

export function getModelTypeProps(type, isFolder, row) {
  const lang = useLanguage.getState().language;

  if (isFolder) {
    return {
      editFunction: getModelTypeProps(parseModelType(row?.model_type))
        ?.editFunction,
      findFunction: getModelTypeProps(parseModelType(row?.model_type))
        ?.findFunction,
      deleteFunction: getModelTypeProps(parseModelType(row?.model_type))
        ?.deleteFunction,
      listingFunction: getModelTypeProps(parseModelType(row?.model_type))
        ?.listingFunction,
      pageName: "folder",
      pathName: lang.components.folder_navigator.column_name,
      name: lang.components.folder_navigator.column_name,
      columns: [
        {
          field: "model_type",
          headerName: lang.model_folder.columns.model_type,
          width: 170,
          renderCell: ({ row, value }) => {
            return (
              <Group wrap="nowrap" w={"100%"} gap={"xs"}>
                {getTypeIcon(
                  row?.model_type,
                  false,
                  18,
                  { color: "secondary.8", variant: "light" },
                  true
                )}
                <Text size="sm" truncate="end">
                  {value?.split(/(?=[A-Z])/).join(" ")}
                </Text>
              </Group>
            );
          },
        },
      ],
    };
  }

  if (type === "email") {
    return {
      editFunction: editMEmail,
      findFunction: findMEmail,
      deleteFunction: deleteMEmail,
      listingFunction: getMEmails,
      copyFunction: copyEmail,
      pageName: "models_email",
      lang_name: "model_emails",
      name: lang.routes.models.email,
      pathName: lang.routes.models.emails,
      columns: [
        {
          field: "subject",
          headerName: lang.model_emails.columns.subject,
          width: 150,
        },
        {
          field: "is_template",
          headerName: lang.model_emails.columns.is_template,
          width: 100,
          id: "is_template",
          renderCell: ({ value, row }) => {
            if (value) {
              return (
                <Text fw={500} size="sm">
                  {lang.model_emails.columns.template}
                </Text>
              );
            }
            return (
              <Group gap={"xs"}>
                <Text fw={500} size="sm">
                  {row.is_ai ? "AI" : lang.model_emails.columns.not_template}
                </Text>

                {row.is_generated ||
                  (row.is_ai && (
                    <HoverCard position="top">
                      <HoverCard.Target>
                        <IconSparkles style={{ marginLeft: 4 }} stroke={1} />
                      </HoverCard.Target>

                      <HoverCard.Dropdown>
                        <Text maw={400}>
                          {!row.is_ai && (
                            <>
                              {lang.model_emails.prompt_by} <br />{" "}
                            </>
                          )}
                          <span style={{ fontWeight: 500 }}>{row.prompt}</span>
                        </Text>
                      </HoverCard.Dropdown>
                    </HoverCard>
                  ))}
              </Group>
            );
          },
        },
      ],
    };
  }
  if (type === "linkedin-invitation") {
    return {
      editFunction: editLinkedinInvitation,
      findFunction: findLinkedinInvitation,
      deleteFunction: deleteLinkedinInvitation,
      listingFunction: getLinkedinInvitations,
      copyFunction: copyLinkedinInvitation,
      pageName: "models_linkedin_invitation",
      lang_name: "model_invitation_linkedin",
      name: lang.routes.models.linkedin_invitation,
      pathName: lang.routes.models.linkedin_invitations,
      create_permission: "createModelsLinkedinInvitation",
      columns: [],
    };
  }
  if (type === "linkedin-message") {
    return {
      editFunction: editLinkedinMessage,
      findFunction: findLinkedinMessage,
      deleteFunction: deleteLinkedinMessage,
      listingFunction: getLinkedinMessages,
      copyFunction: copyLinkedinMessage,
      pageName: "models_linkedin_message",
      lang_name: "model_message_linkedin",
      create_permission: "createModelsLinkedinMessage",
      name: lang.routes.models.linkedin_message,
      pathName: lang.routes.models.linkedin_messages,
      columns: [],
    };
  }
  if (type === "sms") {
    return {
      editFunction: editSMS,
      findFunction: findSMS,
      deleteFunction: deleteSMS,
      listingFunction: getSMS,
      copyFunction: copySMS,
      pageName: "models_sms",
      lang_name: "model_sms",
      name: lang.routes.models.sms,
      create_permission: "createModelsSms",
      pathName: lang.routes.models.sms,
      columns: [
        {
          field: "sender",
          headerName: lang.model_sms.columns.sender,
          width: 150,
        },
      ],
    };
  }
}

export const getDefaultHtmlCode = (inside) => `<html>
<head>
  <meta charset="UTF-8" />
</head>
<body>
  ${inside}
</body>
</html>`;
