import { useState } from "react";
import { discoverEmail } from "../../../../../api/EmailAPI";
import { Button, Tooltip } from "@mantine/core";
import { IconSettingsSearch } from "@tabler/icons-react";
import { useLanguage } from "../../../../../stores/LanguageStore";

const Autodiscover = ({ onFinish, inputId }) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(false);
  const handleSimpleConnect = () => {
    setLoading(true);

    const value = document.getElementById(inputId).value;

    discoverEmail(value)
      .then((response) => {
        let settings = response.data.email_server_settings;
        onFinish?.({ ...settings, smtp_username: value, imap_username: value });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Tooltip label={lang.senders.emails.autodiscover_tooltip}>
      <Button
        variant="light"
        color="pink"
        loading={loading}
        onClick={handleSimpleConnect}
        leftSection={<IconSettingsSearch size={18} />}
        ml={"auto"}
      >
        {lang.senders.emails.autodiscover_label}
      </Button>
    </Tooltip>
  );
};

export default Autodiscover;
