import { useMemo } from "react";
import { parseProspectDatafields } from "../../../leadsTracking/utils";
import { IconUser } from "@tabler/icons-react";
import Selector from "../../../../../components/Selectors/Selector";

function getOptionName(option) {
  let contact = parseProspectDatafields(option);

  let firstName =
    contact?.properties?.find(
      (x) => x.identifier_placeholder === "%first_name%"
    )?.value || " - ";

  let lastName =
    contact?.properties?.find((x) => x.identifier_placeholder === "%last_name%")
      ?.value || " - ";

  let email = contact?.properties?.find(
    (x) => x.identifier_placeholder === "%email%"
  )?.value;

  let linkedin = contact?.properties?.find(
    (x) => x.identifier_placeholder === "%linkedin_url%"
  )?.value;

  return `${firstName} ${lastName} ${email || linkedin || " - "}`;
}

const ContactPreview = ({ contact, contacts, setContact }) => {
  const options = useMemo(() => {
    let result = [{ label: "Contacts", options: [] }];
    contacts?.forEach((account) => {
      result[0].options.push({
        ...account,
        option_name: getOptionName(account),
      });
    });
    return contacts.length > 0 ? result : [];
  }, [contacts]);

  return (
    <Selector
      leftSection={<IconUser size={18} />}
      onChange={setContact}
      options={options}
      size={"md"}
      value={contact?.id}
      offset={0}
      unclearable
      getOptionName={getOptionName}
      other={{
        radius: 0,
        styles: {
          input: {
            border: "0",
          },
        },
      }}
    />
  );
};

export default ContactPreview;
