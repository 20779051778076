import { Button, Group, Stack } from "@mantine/core";
import { Link, useRouteLoaderData } from "react-router-dom";
import EmailPreviewer from "../../../../components/View/EmailPreviewer";
import { IconEdit } from "@tabler/icons-react";
import BackButton from "../../../../components/View/BackButton";
import CreateSignatureButton from "./CreateSignatureButton";
import PageTitle from "../../../../components/View/PageTitle";

const SignaturePage = ({ loaderId }) => {
  const modelProfile = useRouteLoaderData(loaderId);

  return (
    <>
      <Group className="layout-block top" justify="space-between">
        <PageTitle title={modelProfile?.name} />

        <CreateSignatureButton />
      </Group>

      <Stack
        w={"60%"}
        mx={"auto"}
        h={"100%"}
        className="layout-block"
        px={"sm"}
        pt={"sm"}
        gap={"xs"}
      >
        <EmailPreviewer
          html={modelProfile?.html}
          disableSubject
          iframeStyle={{ flex: "1 1 auto", height: "100%" }}
          paperShadow="none"
        />
        <Group justify="right">
          <BackButton />
          <Button
            component={Link}
            to={"edit"}
            leftSection={<IconEdit size={18} />}
          >
            Modifier
          </Button>
        </Group>
      </Stack>
    </>
  );
};

export default SignaturePage;
