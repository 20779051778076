import { useLanguage } from "../../../../stores/LanguageStore";
import { useDisclosure } from "@mantine/hooks";
import {
  ActionIcon,
  Anchor,
  Button,
  CopyButton,
  Group,
  Modal,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import BackButton from "../../../../components/View/BackButton";
import { IconCheck, IconCopy, IconLink } from "@tabler/icons-react";

const ListUrlUsed = ({ current, closeMenu }) => {
  const lang = useLanguage((s) => s.language);
  const [opened, { open, close }] = useDisclosure();

  const handleClose = () => {
    close();
    closeMenu?.();
  };

  return (
    <>
      <Button
        onClick={open}
        leftSection={<IconLink size={18} />}
        fw={600}
        color="primary"
        variant="subtle"
        justify="start"
      >
        {lang.contact_lists.url_used.label}
      </Button>

      {opened && (
        <Modal
          opened={opened}
          size={"lg"}
          onClose={handleClose}
          title={
            <>
              {lang.contact_lists.url_used.dialog.title}{" "}
              <b>
                {current?.name} #{current.id}
              </b>
            </>
          }
        >
          <Stack gap={"xs"}>
            <Stack>
              {current?.urls_used?.length > 0 ? (
                current?.urls_used?.map((url, index) => (
                  <Group key={index} wrap="nowrap">
                    <Anchor href={url} target="_blank" truncate="end">
                      {url}
                    </Anchor>

                    <CopyButton value={url}>
                      {({ copied, copy }) => (
                        <Tooltip
                          label={
                            copied
                              ? lang.contact_lists.url_used.dialog.copy_success
                              : lang.contact_lists.url_used.dialog.copy
                          }
                          color={copied ? "green" : "black"}
                        >
                          <ActionIcon
                            size={"lg"}
                            variant="light"
                            color={copied ? "green" : "primary"}
                            onClick={copy}
                          >
                            {copied ? (
                              <IconCheck size={20} />
                            ) : (
                              <IconCopy size={20} />
                            )}
                          </ActionIcon>
                        </Tooltip>
                      )}
                    </CopyButton>
                  </Group>
                ))
              ) : (
                <Text fontSize={16} c={"dimmed"} ta={"center"}>
                  {lang.contact_lists.url_used.dialog.no_links}
                </Text>
              )}
            </Stack>

            <Group justify="end" mt={"md"}>
              <BackButton onClick={handleClose} />
            </Group>
          </Stack>
        </Modal>
      )}
    </>
  );
};

export default ListUrlUsed;
