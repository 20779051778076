import {
  Button,
  Group,
  Popover,
  Radio,
  Stack,
  TextInput,
  Tooltip,
} from "@mantine/core";
import PopOver from "../../../../../components/View/PopOver";
import {
  IconChevronDown,
  IconChevronLeft,
  IconSendOff,
} from "@tabler/icons-react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { useState } from "react";
import { transformFilterToServer } from "../../../../../functions/utilFunctions";
import {
  createBlacklist,
  importBlacklistFromStepStat,
} from "../../../../../api/BlacklistAPI";
import { notifications } from "@mantine/notifications";
import CreatableBlacklistSelector from "../../../../../components/Selectors/CreatableBlacklistSelector";
import { useParams } from "react-router-dom";

const BlacklistDestination = ({
  blacklistType,
  handleClose,
  onFinish,
  filter,
}) => {
  const { campaign, workflow, stepId, type } = useParams();
  const lang = useLanguage((s) => s.language);

  const [name, setName] = useState("");
  const [list, setList] = useState(null);
  const [listState, setListState] = useState(1);

  const [loading, setLoading] = useState(false);

  const main = () => {
    setLoading(true);

    function main(blacklist_id) {
      importBlacklistFromStepStat({
        blacklist_id,
        filter:
          blacklistType === "all"
            ? { mode: "or", values: [] }
            : transformFilterToServer(filter),
        contact_type: type,
        step_id: stepId,
        workflow_id: workflow,
        workflow_programmation_id: campaign,
      })
        .then((res) => {
          onFinish?.(blacklistType);
          notifications.show({
            message: lang.blacklists.campaign_contacts.success,
            color: "tertiary.8",
          });
          handleClose();
        })
        .finally(() => {
          setLoading(false);
        });
    }

    if (!list?.id) {
      return createBlacklist({ name })
        .catch((createError) => {
          let state_message = createError.response.data.state_message;
          // if (state_message === "empty_name") {
          //   return handleErrors(state_message);
          // }
        })
        .then((response) => {
          if (response.data.state) {
            // If list created, import to that list
            main(response.data.blacklist_id);
          }
        });
    }
    return main(list?.id);
  };

  return (
    <Stack w={300}>
      <Stack gap={16} mt={"sm"}>
        <Stack w="100%" gap={4}>
          <Radio
            label={lang.blacklists.import.step_list.create_new}
            name="list_state"
            checked={listState === 1}
            size="sm"
            onChange={(e) => setListState(1)}
          />
          <TextInput
            id="new-list-name"
            name="contact_list_name"
            value={name}
            w={"100%"}
            disabled={listState !== 1}
            styles={{ label: { fontSize: 13 } }}
            label={lang.blacklists.import.step_list.create_name}
            placeholder={lang.blacklists.import.step_list.create_name}
            onChange={(e) => setName(e.target.value)}
            required={listState === 1}
          />
        </Stack>
        <Stack w="100%" gap={4}>
          <Radio
            label={lang.blacklists.import.step_list.import_exist}
            name="list_state"
            size="sm"
            checked={listState === 2}
            onChange={(e) => setListState(2)}
          />
          <CreatableBlacklistSelector
            value={list}
            setValue={setList}
            label={lang.blacklists.import.step_list.import_exist}
            placeholder={lang.blacklists.import.step_list.import_exist}
            w={"100%"}
            disabled={listState !== 2}
            styles={{ label: { fontSize: 13 } }}
            required={listState === 2}
            disableCreate
            description={" "}
          />
        </Stack>
      </Stack>
      <Group justify="end">
        <Button
          leftSection={<IconSendOff size={16} />}
          onClick={main}
          loading={loading}
          color="red"
        >
          {lang.blacklists.campaign_contacts.button_label}
        </Button>
      </Group>
    </Stack>
  );
};

const TableFilterBlacklistContacts = ({
  onFinish,
  selectedIds,
  filter,
  filterResultNumber,
  total,
}) => {
  const lang = useLanguage((s) => s.language);

  return (
    <PopOver
      opener={(handleOpen, ref) => (
        <Tooltip label={lang.blacklists.campaign_contacts.button_tooltip}>
          <Button
            className="toolbar-action-button"
            variant="light"
            color="red"
            justify="start"
            ref={ref}
            leftSection={<IconSendOff size={18} />}
            rightSection={<IconChevronDown size={16} />}
            onClick={handleOpen}
            // fw={550}
          >
            {lang.blacklists.campaign_contacts.button_label}
          </Button>
        </Tooltip>
      )}
      popoverChildren={(handleClose) => (
        <Stack gap={"xs"}>
          <Popover
            zIndex={1600}
            position="left"
            offset={0}
            shadow="md"
            withArrow
          >
            <Popover.Target>
              <Tooltip
                label={lang.blacklists.campaign_contacts.blacklist_filter}
                disabled={
                  filter?.items?.length > 0 ||
                  filter.quickFilterValues.filter(Boolean).length > 0
                }
              >
                <Button
                  disabled={
                    !filter ||
                    (filter?.items?.length < 1 &&
                      filter.quickFilterValues.filter(Boolean).length < 1)
                  }
                  variant="subtle"
                  size="sm"
                  color="tertiary.8"
                  justify="start"
                  leftSection={<IconChevronLeft size={16} />}
                >
                  {lang.blacklists.campaign_contacts.blacklist_filter}{" "}
                  {filter?.items?.length > 0 ||
                  filter.quickFilterValues.filter(Boolean).length > 0
                    ? `( ${filterResultNumber} )`
                    : ""}
                </Button>
              </Tooltip>
            </Popover.Target>
            <Popover.Dropdown>
              <BlacklistDestination
                handleClose={handleClose}
                filter={filter}
                onFinish={onFinish}
                selectedIds={selectedIds}
                type={"filter"}
              />
            </Popover.Dropdown>
          </Popover>

          <Popover
            zIndex={1600}
            position="left"
            offset={0}
            shadow="md"
            withArrow
          >
            <Popover.Target>
              <Tooltip label={lang.blacklists.campaign_contacts.blacklist_all}>
                <Button
                  leftSection={<IconChevronLeft size={16} />}
                  variant="subtle"
                  size="sm"
                  color="tertiary.8"
                  justify="start"
                >
                  {lang.blacklists.campaign_contacts.blacklist_all}
                </Button>
              </Tooltip>
            </Popover.Target>
            <Popover.Dropdown>
              <BlacklistDestination
                handleClose={handleClose}
                filter={filter}
                onFinish={onFinish}
                selectedIds={selectedIds}
                type={"all"}
              />
            </Popover.Dropdown>
          </Popover>
        </Stack>
      )}
    />
  );
};

export default TableFilterBlacklistContacts;
