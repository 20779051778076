import { Button, Checkbox, Group, Tooltip } from "@mantine/core";
import { IconEdit, IconEye, IconTrash } from "@tabler/icons-react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { useFlowStore } from "../../../../../stores/WorkflowStore";

const CampaignSharer = () => {
  const lang = useLanguage((s) => s.language);
  const sharePrm = useFlowStore((s) => s.sharePrm);
  const shareStats = useFlowStore((s) => s.shareStats);
  const shareWorkflow = useFlowStore((s) => s.shareWorkflow);
  const setShareWorkflow = useFlowStore((s) => s.setShareWorkflow);
  const setSharePrm = useFlowStore((s) => s.setSharePrm);
  const setShareStats = useFlowStore((s) => s.setShareStats);

  const getPropsFunction = (id) => {
    if (id === "workflow") {
      return {
        value: shareWorkflow,
        setter: setShareWorkflow,
        label: lang.components.sharing.share_workflow,
      };
    }
    if (id === "prm") {
      return {
        value: sharePrm,
        setter: setSharePrm,
        label: lang.components.sharing.share_prm,
      };
    }
    return {
      value: shareStats,
      setter: setShareStats,
      label: lang.components.sharing.share_stats,
    };
  };

  return [shareStats, sharePrm].map((item) => {
    let value = getPropsFunction(item.id).value;
    let setter = getPropsFunction(item.id).setter;
    let label = getPropsFunction(item.id).label;
    return (
      <Group key={item.id}>
        <Checkbox
          mb={8}
          checked={item.read_permission}
          label={label}
          onClick={() =>
            setter({
              ...value,
              read_permission: !value.read_permission,
            })
          }
        />

        <Group wrap="nowrap" gap={"xs"} ml={"auto"} mb={8}>
          <Tooltip label={lang.components.sharing.permissions.read}>
            <Button variant="default" px={6} size="xs" disabled>
              <IconEye size={16} />
            </Button>
          </Tooltip>

          {Object.keys(value).includes("write_permission") && (
            <Tooltip label={lang.components.sharing.permissions.write}>
              <Button
                color="tertiary.8"
                variant={value?.write_permission ? "filled" : "outline"}
                px={6}
                size="xs"
                disabled={!value.read_permission}
                onClick={() =>
                  setter({
                    ...value,
                    write_permission: !value.write_permission,
                  })
                }
              >
                <IconEdit size={16} />
              </Button>
            </Tooltip>
          )}

          {Object.keys(value).includes("delete_permission") && (
            <Tooltip label={lang.components.sharing.permissions.delete}>
              <Button
                color="tertiary.8"
                variant={value.delete_permission ? "filled" : "outline"}
                px={6}
                size="xs"
                disabled={!value.read_permission}
                onClick={() =>
                  setter({
                    ...value,
                    delete_permission: !value.delete_permission,
                  })
                }
              >
                <IconTrash size={16} />
              </Button>
            </Tooltip>
          )}
        </Group>
      </Group>
    );
  });
};

export default CampaignSharer;
