import { LoadingOverlay, Stack } from "@mantine/core";
import { Outlet } from "react-router-dom";
import { useUIStore } from "../../stores/UIStore";
import classes from "./Main.module.css";

const Main = () => {
  const mainLoading = useUIStore((s) => s.mainLoading);

  return (
    <>
      <LoadingOverlay
        visible={mainLoading}
        zIndex={1000}
        overlayProps={{ blur: 2 }}
        w={"100%"}
        h={"100%"}
      />
      {/* <MaintenanceNotifier /> */}
      <Stack
        style={{
          flex: "1 1 auto",
          height: "100%",
          overflow: "auto",
        }}
        className={classes.background}
        direction="column"
        gap={16}
        pos={"relative"}
      >
        <Outlet />
      </Stack>
    </>
  );
};

export default Main;
