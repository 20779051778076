import { Group, Stack } from "@mantine/core";
import {
  useLeadsContainerStore,
  useLeadsFrontStore,
  useLeadsStore,
} from "../../../../stores/LeadsStore";
import { useEffect } from "react";
import Container from "./Container";
import ContainersFooter from "./ContainersFooter";

const Containers = () => {
  const systemStatuses = useLeadsStore((s) => s.systemStatuses);
  const customStatuses = useLeadsStore((s) => s.customStatuses);
  const containers = useLeadsContainerStore((s) => s.containers);
  const setContainers = useLeadsContainerStore((s) => s.setContainers);
  const reloadAll = useLeadsContainerStore((s) => s.reloadAll);
  const setReloadAll = useLeadsContainerStore((s) => s.setReloadAll);

  useEffect(() => {
    const sizes = useLeadsFrontStore.getState().sizes;
    const setSizes = useLeadsFrontStore.getState().setSizes;

    let newContainers = [...systemStatuses, ...customStatuses].map((x, i) => {
      return {
        ...x,
        items: [],
        total: null,
        loading: true,
        nextPageUrl: null,
        sort: null,
        loadingNext: false,
        size: sizes?.[x.id] || 250,
      };
    });
    let newSizes = { ...sizes };
    newContainers.forEach((item) => {
      newSizes[item.id] = sizes?.[item.id] || 250;
    });
    setSizes(newSizes);
    setContainers(newContainers.sort((a, b) => a?.sorting - b?.sorting));
  }, [setContainers]);

  useEffect(() => {
    if (reloadAll) {
      setContainers(
        containers.map((x) => ({ ...x, loaded: false, loading: true }))
      );
      setReloadAll(false);
    }
  }, [reloadAll]);

  return (
    <Stack flex={1}>
      <Group
        h={"100%"}
        style={{ overflow: "auto" }}
        flex={"1 1 auto"}
        w={0}
        miw={"100%"}
        id="main-prm"
        align="stretch"
      >
        <Group wrap="nowrap">
          {containers
            .filter((x) => x.visible)
            .map((container) => (
              <Container
                key={container?.id || container?.status}
                container={container}
              />
            ))}
        </Group>
      </Group>

      <ContainersFooter />
    </Stack>
  );
};

export default Containers;
