import { ActionIcon, Popover, Tooltip } from "@mantine/core";
import { IconSwitchVertical } from "@tabler/icons-react";
import { useProfileStore, useSessionStore } from "../../stores/UserStore";
import { useCallback, useEffect, useState } from "react";
import { switchToUser } from "../../api/UserAPI";
import { useUIStore } from "../../stores/UIStore";
import SwitchCancel from "./SwitchCancel";
import UserSelectorSwitch from "../../components/UserSelector/UserSelectorSwitch";
import { useLanguage } from "../../stores/LanguageStore";

const AllSwitcher = ({ navbarClosed, is22 }) => {
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);
  const setWindowLoading = useUIStore((s) => s.setWindowLoading);
  const unswitchedProfile = useProfileStore((s) => s.unswitchedProfile);
  const setUnswitchedProfile = useProfileStore((s) => s.setUnswitchedProfile);
  const userSwitch = useSessionStore((state) => state.user_switch);
  const setSwitch = useSessionStore((state) => state.setUserSwitch);

  const [opened, setOpened] = useState(false);

  const handleSwitch = useCallback(
    (newUser) => {
      if (newUser) {
        setOpened(false);
        setWindowLoading(true);
        switchToUser(newUser.id)
          .then((response) => {
            let token = response.data?.token;

            // Get the current timestamp in milliseconds
            const currentTimestamp = Date.now();
            const currentDate = new Date(currentTimestamp);
            // Add delay (minutes)
            currentDate.setMinutes(currentDate.getMinutes() + 50);
            const updatedTimestamp = currentDate.getTime();

            let user_switch = {
              token,
              id: newUser.id,
              name: newUser?.name,
              exp: updatedTimestamp,
            };

            setSwitch(user_switch);

            if (!userSwitch) setUnswitchedProfile(profile);

            if (location.href.includes("leads-tracking")) {
              window.location.href = "/leads-tracking";
            } else window.location.reload();
          })
          .catch(() => setWindowLoading(false));
      }
    },
    [setWindowLoading, setSwitch, userSwitch, setUnswitchedProfile, profile]
  );

  // Auto reload when new session key is added in localStorage
  useEffect(() => {
    window.addEventListener("storage", (e) => {
      if (e.key === "session") {
        const newValue = JSON.parse(e.newValue);
        const newUserSwitch = newValue.state.user_switch;
        if (newUserSwitch) {
          if (newUserSwitch?.id !== profile?.id) {
            window.location.reload();
          }
        } else {
          if (userSwitch) {
            window.location.reload();
          }
        }
      }
    });
  }, [userSwitch, profile, unswitchedProfile]);

  return (
    <>
      <Popover
        trapFocus
        position="bottom"
        offset={-8}
        withArrow
        opened={opened}
        onChange={setOpened}
      >
        <Popover.Target>
          <Tooltip label={lang.header.switch} position="bottom-bottom">
            <ActionIcon
              variant="subtle"
              h={60}
              w={48}
              p={0}
              ml={8}
              justify="flex-start"
              radius={0}
              size="lg"
              color={is22 ? "primary" : "white"}
              onClick={() => setOpened(true)}
            >
              <IconSwitchVertical />
            </ActionIcon>
          </Tooltip>
        </Popover.Target>
        <Popover.Dropdown>
          <UserSelectorSwitch onChange={handleSwitch} />
        </Popover.Dropdown>
      </Popover>
      <SwitchCancel navbarClosed={navbarClosed} />
    </>
  );
};

export default AllSwitcher;
