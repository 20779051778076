import { Group, Input, Stack } from "@mantine/core";
import { useFlowStore } from "../../../../../../../stores/WorkflowStore";
import ModelEmailSelector from "../../../../../../../components/Selectors/ModelEmailSelector";
import EmailPreviewer from "../../../../../../../components/View/EmailPreviewer";
import ModelLinkedinSelector from "../../../../../../../components/Selectors/ModelLinkedinSelector";
import MessagePreviewer from "../../../../../../../components/View/MessagePreviewer";
import AddModelLinkedin from "../../../../../../../components/Adder/AddModelLinkedin";
import { useAccountsStore } from "../../../../../../../stores/ConfigStore";
import { useEffect, useMemo, useState } from "react";
import { findLinkedinInvitation } from "../../../../../../../api/ModelsApi";
import { maxNormalChars, maxSalesNavigatorChars } from "../../flowSettings";
import { useLanguage } from "../../../../../../../stores/LanguageStore";
import ModelTemplates from "../../../../../models/ModelTemplates";
import SignatureSelector from "../../../../../../../components/Selectors/SignatureSelector";
import { IconSignature } from "@tabler/icons-react";
import EmailPreviewerEditable from "../../../../../../../components/View/EmailPreviewerEditable";

const PanelModel = ({ node }) => {
  const lang = useLanguage((s) => s.language);
  const updateNodeData = useFlowStore((s) => s.updateNodeData);
  const handleModelChange = (v) => {
    const newValue = v?.id ? parseInt(v?.id) : null;
    updateNodeData(node?.id, {
      model_id: newValue,
    });
  };
  const handleSignChange = (v) => {
    const newValue = v?.id ? parseInt(v?.id) : null;
    updateNodeData(node?.id, {
      signature_id: newValue,
    });
  };

  const accounts = useAccountsStore((s) => s.linkedin_accounts);
  const pools = useAccountsStore((s) => s.linkedin_pools);
  const isSales = useMemo(() => {
    if (node?.data?.pool_id) {
      let pool = pools.find((x) => x.id === node?.data?.pool_id);
      return pool?.accounts_list?.find(
        (x) => x?.is_sales_navigator_account === true
      );
    }
    if (node?.data?.integration_id) {
      let account = accounts.find((x) => x.id === node?.data?.integration_id);
      return account?.is_sales_navigator_account === true;
    }
    return true;
  }, [pools, accounts, node]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      node?.data?.model_id &&
      node?.data?.actionType === "linkedin_invitation" &&
      node?.data?.integration_id
    ) {
      setLoading(true);

      findLinkedinInvitation(node?.data?.model_id)
        .then((response) => {
          updateNodeData(node?.id, {
            invitation_text: response.data.model_profile?.text,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [
    node?.data?.actionType,
    node?.data?.model_id,
    node?.data?.integration_id,
    node?.id,
  ]);

  return (
    <Stack
      mt={["sms", "smv"].includes(node?.data?.actionType) ? 0 : "md"}
      gap={4}
    >
      <Group mb={"xs"}>
        <Input.Label
          size="md"
          required={node?.data?.actionType !== "linkedin_invitation"}
          fw={"normal"}
        >
          {lang.campaign.panel.model_title}
        </Input.Label>
        {node?.data?.actionType === "email" ? (
          <Group>
            {/* <AddModelEmail onModelCreated={handleModelChange} /> */}
            <ModelTemplates inWorkflow onModelCreated={handleModelChange} />
          </Group>
        ) : (
          <AddModelLinkedin
            onModelCreated={handleModelChange}
            type={node?.data?.actionType}
            maxChars={isSales ? maxSalesNavigatorChars : maxNormalChars}
          />
        )}
      </Group>
      {node?.data?.actionType === "email" ? (
        <>
          <Stack w={"100%"} mb={"xs"}>
            <ModelEmailSelector
              value={node?.data?.model_id}
              onChange={handleModelChange}
              required
              size={"sm"}
              withEditor
              withBAT
              defaultSignature={node?.data?.signature_id}
              withPreview
            />
          </Stack>

          {node?.data?.model_id ? (
            <EmailPreviewer
              model_id={node?.data?.model_id}
              paperShadow="xs"
              signatureId={node?.data?.signature_id}
              noSubjectTooltip
            />
          ) : (
            <EmailPreviewerEditable onFinish={handleModelChange} />
          )}
          <SignatureSelector
            leftSection={<IconSignature size={18} />}
            onChange={handleSignChange}
            size={"sm"}
            value={node?.data?.signature_id}
            mt={"xs"}
          />
        </>
      ) : (
        <>
          <Stack w={"70%"} mb={"xs"}>
            <ModelLinkedinSelector
              value={node?.data?.model_id}
              onChange={handleModelChange}
              type={node?.data?.actionType}
              required
              size={"sm"}
              withEditor
              maxChars={
                node?.data?.actionType !== "linkedin_invitation"
                  ? 9999
                  : isSales
                  ? maxSalesNavigatorChars
                  : maxNormalChars
              }
              disabled={loading}
              getError={() => {
                if (
                  !node?.data?.model_id &&
                  node?.data?.actionType !== "linkedin_invitation"
                ) {
                  return true;
                }
                if (node?.data?.actionType === "linkedin_invitation") {
                  let maxLength = isSales
                    ? maxSalesNavigatorChars
                    : maxNormalChars;
                  let encoder = new TextEncoder("utf-8");
                  let length = encoder.encode(
                    node?.data?.invitation_text
                  )?.length;
                  if (length > maxLength) {
                    return `${lang.campaign.panel.model.model_length_error} ( ${length} / ${maxLength} ) `;
                  }
                }
                return false;
              }}
            />
          </Stack>

          {node?.data?.model_id && (
            <MessagePreviewer
              model_id={node?.data?.model_id}
              type={node?.data?.actionType}
              paperShadow="xs"
            />
          )}
        </>
      )}
    </Stack>
  );
};

export default PanelModel;
