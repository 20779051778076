import { useLeadsSheetStore } from "../../../../../stores/LeadsStore";
import { Drawer, Group } from "@mantine/core";
import LeftSheet from "./Left/LeftSheet";
import { useEffect } from "react";
import { findProspect } from "../../../../../api/PRMAPI";
import LeadSheetController from "./LeadSheetController";
import RightSheet from "./Right/RightSheet";
import { parseProspectDatafields } from "../../utils";
import SameOrg from "./SameOrg/SameOrg";

const LeadSheet = () => {
  const opened = useLeadsSheetStore((s) => s.opened);
  const setOpened = useLeadsSheetStore((s) => s.setOpened);

  const current = useLeadsSheetStore((s) => s.current);
  const setCurrent = useLeadsSheetStore((s) => s.setCurrent);
  const setLoading = useLeadsSheetStore((s) => s.setLoading);
  const loading = useLeadsSheetStore((s) => s.loading);

  useEffect(() => {
    if (loading && current?.id) {
      findProspect(current?.id)
        .then((response) => {
          setCurrent({
            ...parseProspectDatafields(response.data.contact_profile),
            unsubscribed: current?.unsubscribed,
          });
          setLoading(false);
        })
        .finally(() => setLoading(false));
    }
  }, [current?.id, loading, setCurrent, setLoading]);

  useEffect(() => {
    if (opened) {
      setLoading(true);
    }
  }, [opened]);

  return (
    <Drawer
      offset={8}
      radius="md"
      opened={opened}
      onClose={() => setOpened(false)}
      overlayProps={{ backgroundOpacity: 0.5, blur: 1 }}
      size={"calc(100% - 140px)"}
      position="right"
      withCloseButton={false}
      styles={{
        body: {
          height: "100%",
          padding: 0,
        },
        content: {
          overflow: "visible",
        },
      }}
    >
      <LeadSheetController />
      <Group
        flex={"1 1 auto"}
        h={"100%"}
        align="start"
        className="lead-sheet"
        wrap="nowrap"
        gap={0}
      >
        <SameOrg />
        <LeftSheet />
        <RightSheet />
      </Group>
    </Drawer>
  );
};

export default LeadSheet;
