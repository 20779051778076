import {
  Box,
  Button,
  Divider,
  Group,
  Loader,
  Modal,
  Stack,
  Text,
  Textarea,
  Timeline,
  TimelineItem,
  Title,
} from "@mantine/core";
import {
  IconArrowBarUp,
  IconArrowRight,
  IconCheck,
  IconLoader,
} from "@tabler/icons-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { createMEmail, generateEmail } from "../../../../api/ModelsApi";
import { useProfileStore } from "../../../../stores/UserStore";
import { createWorkflow } from "../../../../api/WorkflowAPI";
import { incrementHour } from "../../../../functions/dates";
import { defaultNodeConfigs } from "../../workflows/CreateCampaign/flow/flowSettings";
import {
  useAccountsStore,
  useConfigStore,
} from "../../../../stores/ConfigStore";
import { useLanguage } from "../../../../stores/LanguageStore";
import {
  model1,
  model2,
  model3,
  model4,
  model5,
  model6,
  model7,
} from "../../models/emails/emailTemplates";
import BackButton from "../../../../components/View/BackButton";
import EmailPreviewer from "../../../../components/View/EmailPreviewer";
import { notifications } from "@mantine/notifications";
import { LoadingIllustration } from "../../../../assets/LoadingIllustration";

const Step3 = ({ setStep, setWorkflowId, email }) => {
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);
  const [goingNext, setGoingNext] = useState(false);
  const [loadingStep, setLoadingStep] = useState(null);

  const emails = useAccountsStore((s) => s.email_accounts);
  const signatures = useConfigStore((s) => s.email_signatures);

  const integration_id =
    email?.id || emails.sort()?.find((x) => x.is_valid === true)?.id || null;
  const signature_id = signatures?.[0]?.id || null;

  const steps = useMemo(
    () => [
      lang.home.create_campaign.step3.step1,
      lang.home.create_campaign.step3.step2,
      lang.home.create_campaign.step3.step3,
      lang.home.create_campaign.step3.step4,
    ],
    [lang]
  );

  //#region Template
  const [duplicating, setDuplicating] = useState(false);
  const [opened, setOpened] = useState(null);
  const templateItems = useMemo(() => {
    return [model1, model2, model3, model4, model5, model6, model7];
  }, []);

  const handleOpen = (item) => {
    setOpened(item);
  };
  const handleClose = () => {
    setOpened(null);
  };

  const handleTemplateSelect = () => {
    setDuplicating(true);

    createMEmail({ ...opened })
      .then((response) => {
        notifications.show({
          message: lang.model_emails.row_highlight.duplicated,
        });
        handleClose();
        setDuplicating(false);
        setTimeout(() => {
          handleModels({ model_id: response.data.model_id });
        }, 50);
      })
      .catch(() => setDuplicating(false));
  };
  //#endregion

  const handleModels = useCallback(
    (e) => {
      e?.preventDefault?.();
      const fd = new FormData(e?.target);
      const data = Object.fromEntries(fd);
      let timezoneOffset = new Date().getTimezoneOffset() / 60;
      const time_start_sending = incrementHour(
        defaultNodeConfigs.time_start_sending,
        timezoneOffset
      );
      const time_stop_sending = incrementHour(
        defaultNodeConfigs.time_stop_sending,
        timezoneOffset
      );

      setLoadingStep(0);

      async function secondStep(model_id) {
        setLoadingStep(1);
        const newModelId = model_id;

        const firstStep = {
          action_type: "email",
          allow_massmailing: false,
          blacklist_ids: [],
          email_linkedin_exclude: [],
          integration_id: integration_id,
          is_initial: true,
          limit_send_per_day: 0,
          model_id: newModelId,
          parent_ids: [],
          pool_id: null,
          position_x: 40,
          position_y: 168,
          sending_weekends_allowed: false,
          show_reply_as_conversation: false,
          signature_id: signature_id,
          step_id: {
            id: 1,
            type: "new",
          },
          step_type: "action",
          time_start_sending: time_start_sending,
          time_stop_sending: time_stop_sending,
          use_smtp_fallback_server: false,
        };

        const eventStep = {
          event_type: "email_sent",
          name: null,
          parent_ids: [
            {
              id: 1,
              type: "new",
            },
          ],
          position_x: 40,
          position_y: 432,
          step_id: {
            id: 3,
            type: "new",
          },
          step_type: "event",
          when_minutes: 10080,
          which_links_clicked: [],
        };

        generateEmail({
          name: "Generated Email - follow_up",
          language: profile.language || "FRA",
          number_paragraphs: 3,
          // prompt: data.prompt,
          prompt: "&nbsp;",
          model_id: newModelId,
        }).then((newRes) => {
          setLoadingStep(2);
          const newModelId2 = newRes.data.model_id;
          const secondStep = {
            action_type: "email",
            allow_massmailing: false,
            blacklist_ids: [],
            email_linkedin_exclude: [],
            integration_id: integration_id,
            is_initial: false,
            limit_send_per_day: 0,
            model_id: newModelId2,
            parent_ids: [
              {
                id: 3,
                type: "new",
              },
            ],
            pool_id: null,
            position_x: 40,
            position_y: 686,
            sending_weekends_allowed: false,
            show_reply_as_conversation: false,
            signature_id: signature_id,
            step_id: {
              id: 2,
              type: "new",
            },
            step_type: "action",
            time_start_sending: time_start_sending,
            time_stop_sending: time_stop_sending,
            use_smtp_fallback_server: false,
          };

          createWorkflow({
            name: "Generated Workflow",
            auto_remove_responders: true,
            steps: [firstStep, eventStep, secondStep],
          }).then((workflowResponse) => {
            setLoadingStep(3);
            setWorkflowId(workflowResponse.data.workflow_id);
            setGoingNext(true);
          });
        });
      }

      if (e?.model_id) {
        return secondStep(e.model_id);
      } else {
        generateEmail({
          name: "Generated Email",
          language: profile.language || "FRA",
          number_paragraphs: 3,
          prompt: data.prompt,
        }).then((response) => {
          secondStep(response.data.model_id);
        });
      }
    },
    [integration_id, profile.language, setWorkflowId, signature_id]
  );

  useEffect(() => {
    if (goingNext) {
      setTimeout(() => {
        setStep(3);
      }, 600);
    }
  }, [goingNext]);

  return (
    <Stack pos={"relative"} px={16} component={"form"} onSubmit={handleModels}>
      {loadingStep !== null ? (
        <Group wrap="nowrap" align="end" justify="left">
          <Box
            mt={-100}
            w={200}
            mih={210}
            className={`animate__animated ${"animate__fadeInLeft"}`}
          >
            <LoadingIllustration />
          </Box>
          <Stack my={32} w={300}>
            <Text fz={24} mb={8}>
              {lang.home.create_campaign.step3.title2}
            </Text>

            <Timeline active={loadingStep} color="tertiary.8" bulletSize={28}>
              {steps.map((step, index) => (
                <TimelineItem
                  key={step}
                  title={
                    <Text pt={3} opacity={loadingStep >= index ? 1 : 0.5}>
                      {" "}
                      {step}{" "}
                    </Text>
                  }
                  bullet={
                    loadingStep === index ? (
                      <Loader type="oval" size={16} color="white" />
                    ) : loadingStep > index ? (
                      <IconCheck size={18} />
                    ) : (
                      <IconLoader size={18} />
                    )
                  }
                  className={`animate__animated ${"animate__fadeInUp"}`}
                  style={{
                    animationDelay: `${0.2 * index}s`,
                  }}
                />
              ))}
            </Timeline>
          </Stack>
        </Group>
      ) : (
        <>
          <Text
            fz={26}
            fw={500}
            className={`animate__animated ${
              goingNext ? "animate__fadeOut" : "animate__fadeIn"
            }`}
            style={{ animationDelay: goingNext ? "0s" : "0s" }}
            mr={64}
            ta={"center"}
          >
            {lang.home.create_campaign.step3.title}
          </Text>

          <Textarea
            placeholder={lang.home.create_campaign.step3.placeholder}
            size="lg"
            rows={5}
            className={`animate__animated ${
              goingNext ? "animate__fadeOut" : "animate__fadeIn"
            }`}
            style={{ animationDelay: goingNext ? ".1s" : ".2s" }}
            name="prompt"
            required
            autoFocus
          />

          <Divider label={"Ou"} w={"60%"} mx={"auto"} />
          <Text ta={"center"} size="lg">
            {lang.model_emails.templates.title}
          </Text>
          <Group justify="center">
            {templateItems.map((item) => (
              <Button
                key={item.id}
                variant="light"
                size="md"
                onClick={() => handleOpen(item)}
              >
                {item.name}
              </Button>
            ))}
          </Group>

          <Group
            justify="space-between"
            w={"100%"}
            mt={"md"}
            className={`animate__animated ${
              goingNext ? "animate__fadeOut" : "animate__fadeIn"
            }`}
            style={{ animationDelay: goingNext ? ".2s" : ".3s" }}
          >
            <Button variant="subtle" onClick={() => setStep(1)}>
              {lang.global.back}
            </Button>

            <Button
              w={200}
              rightSection={<IconArrowRight />}
              variant="gradient"
              type="submit"
              gradient={{ from: "secondary.3", to: "secondary.6" }}
              radius={"sm"}
            >
              {lang.global.next}
            </Button>
          </Group>

          <Modal
            opened={!!opened}
            onClose={handleClose}
            title={<Title order={5}>{opened?.name}</Title>}
            size={"64%"}
          >
            <EmailPreviewer
              key={opened?.id}
              html={opened?.html}
              subject={opened?.subject}
              iframeHeight={"60vh"}
            />
            <Group justify="end" mt={"md"}>
              <BackButton onClick={handleClose} />
              <Button
                leftSection={<IconArrowBarUp size={18} />}
                color="tertiary.8"
                onClick={handleTemplateSelect}
                loading={duplicating}
              >
                {lang.workflows.templates.use}
              </Button>
            </Group>
          </Modal>
        </>
      )}
    </Stack>
  );
};

export default Step3;
