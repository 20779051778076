import {
  Box,
  Button,
  Checkbox,
  Group,
  Loader,
  NumberFormatter,
  Stack,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { IconDeviceFloppy } from "@tabler/icons-react";
import business_sector from "../../../../../assets/data/business_sector.json";
import countries_en from "react-phone-number-input/locale/en.json";
import countries_fr from "react-phone-number-input/locale/fr.json";
import { getCountries } from "react-phone-number-input";
import DatabaseInput from "./DatabaseInput";
import DatabaseModal from "./DatabaseModal";
import {
  useProfileStore,
  useSessionStore,
} from "../../../../../stores/UserStore";
import { useRouteLoaderData } from "react-router-dom";
import {
  countResults,
  searchDatabaseLocation,
} from "../../../../../api/TargetingDatabaseAPI";
import DatabaseIllustration from "./DatabaseIllustration";
import DatabaseIllustrationNoData from "./DatabaseIllustrationNoData";
import DatabaseContactItem from "./DatabaseContactItem";
import PagePermission from "../../../../../components/hooks/usePagePermission";

const TargetingDatabase = ({ onFinish }) => {
  const lang = useLanguage((s) => s.language);

  const companySizeOptions = useMemo(() => {
    return [
      {
        label: "0-10",
        id: "company_size_0",
        value: {
          field: "company_size",
          starts_with: ["0-"],
        },
      },
      {
        label: "11-50",
        id: "company_size_1",
        value: {
          field: "company_size",
          starts_with: ["1-"],
        },
      },
      {
        label: "51-200",
        id: "company_size_2",
        value: {
          field: "company_size",
          starts_with: ["20-"],
        },
      },
      {
        label: "201-500",
        id: "company_size_3",
        value: {
          field: "company_size",
          starts_with: ["50-"],
        },
      },
      {
        label: "501-1000",
        id: "company_size_4",
        value: {
          field: "company_size",
          starts_with: ["100-"],
        },
      },
      {
        label: "1001-5000",
        id: "company_size_5",
        value: {
          field: "company_size",
          starts_with: ["500-"],
        },
      },
      {
        label: "5001-10000",
        id: "company_size_6",
        value: {
          field: "company_size",
          starts_with: ["1000-", "999-"],
        },
      },
      {
        label: "10001+",
        id: "company_size_7",
        value: {
          field: "company_size",
          starts_with: ["1000-", "999-"],
        },
      },
    ];
  }, []);

  const profile = useProfileStore((s) => s.profile);
  const reseller = useSessionStore((s) => s.reseller);
  const labels = useMemo(
    () => (profile.language === "FRA" ? countries_fr : countries_en),
    [profile.language]
  );
  const countryOptions = useMemo(() => {
    return getCountries().map((c) => `${labels[c]}`);
  }, [labels]);

  const [jobTitles, setJobTitles] = useState([]);
  const [excludedJobTitles, setExcludedJobTitles] = useState([]);
  const [contactLocalizations, setContactLocalizations] = useState([]);
  const [contactLocalizationsExcluded, setContactLocalizationsExcluded] =
    useState([]);
  const [postalCodes, setPostalCodes] = useState([]);
  const [postalCodesExcluded, setPostalCodesExcluded] = useState([]);

  const [companies, setCompanies] = useState([]);
  const [companiesExcluded, setCompaniesExcluded] = useState([]);
  const [companySizes, setCompanySizes] = useState([]);
  const [companySizesExcluded, setCompanySizesExcluded] = useState([]);
  const [activities, setActivities] = useState([]);
  const [activitiesExcluded, setActivitiesExcluded] = useState([]);
  const [countries, setCountries] = useState([]);
  const [countriesExcluded, setCountriesExcluded] = useState([]);
  const [activityKeyword, setActivityKeyword] = useState([]);
  const [activityKeywordExcluded, setActivityKeywordExcluded] = useState([]);

  const [withPhone, setWithPhone] = useState(false);
  const [withLinkedin, setWithLinkedin] = useState(false);
  const [withWebsite, setWithWebsite] = useState(false);
  const [withBio, setWithBio] = useState(false);

  const [conditions, setConditions] = useState({
    jobTitles: "contains",
    contactLocalizations: "contains",
    postalCodes: "starts_with",
    companies: "contains",
    companySizes: "contains",
    activities: "contains",
    nafCodes: "contains",
    countries: "contains",
    activityKeyword: "contains",
  });

  const negativeConditions = useMemo(
    () => ({
      contains: "does_not_contain",
      starts_with: "does_not_start_with",
      ends_with: "does_not_end_with",
      exact_match: "exact_match",
    }),
    []
  );

  const handleCondition = (value, key) => {
    setConditions((prev) => ({ ...prev, [key]: value }));
  };

  const [opened, setOpened] = useState(false);
  const [filters, setFilters] = useState([]);
  const handleOpen = () => {
    setOpened(true);
  };

  //#region Count
  const listDataLoader = useRouteLoaderData("contact-list");
  const listDataFolderLoader = useRouteLoaderData("folder-contact-list");
  const listProfile = useMemo(() => {
    return listDataLoader || listDataFolderLoader;
  }, [listDataLoader, listDataFolderLoader]);

  const [count, setCount] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleCount = useCallback(() => {
    if (filters.length > 0) {
      setLoading(true);
      countResults({ filters })
        .then((response) => {
          let result = response.data.number_of_contacts;

          setCount(result.count);
          setContacts(result.contacts);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setCount(null);
    }
  }, [filters]);

  useEffect(() => {
    let newFilters = [];

    if (jobTitles.length > 0) {
      let included = jobTitles.filter((x) => !excludedJobTitles.includes(x));
      if (included.length > 0)
        newFilters.push({
          field: "job_title",
          [conditions.jobTitles]: included,
        });
      if (excludedJobTitles.length > 0) {
        newFilters.push({
          field: "job_title",
          [negativeConditions[conditions.jobTitles]]: excludedJobTitles,
        });
      }
    }

    if (postalCodes.length > 0) {
      let included = postalCodes.filter(
        (x) => !postalCodesExcluded.includes(x)
      );
      if (included.length > 0)
        newFilters.push({
          field: "zip_code",
          [conditions.postalCodes]: included,
        });
      if (postalCodesExcluded.length > 0) {
        newFilters.push({
          field: "zip_code",
          [negativeConditions[conditions.postalCodes]]: postalCodesExcluded,
        });
      }
    }

    if (contactLocalizations.length > 0) {
      let included = contactLocalizations.filter(
        (x) => !contactLocalizationsExcluded.includes(x)
      );
      if (included.length > 0)
        newFilters.push({
          field: "contact_location",
          [conditions.contactLocalizations]: included,
        });
      if (contactLocalizationsExcluded.length > 0) {
        newFilters.push({
          field: "contact_location",
          [negativeConditions[conditions.contactLocalizations]]:
            contactLocalizationsExcluded,
        });
      }
    }

    if (companies.length > 0) {
      let included = companies.filter((x) => !companiesExcluded.includes(x));
      if (included.length > 0)
        newFilters.push({
          field: "company",
          [conditions.companies]: included,
        });
      if (companiesExcluded.length > 0) {
        newFilters.push({
          field: "company",
          [negativeConditions[conditions.companies]]: companiesExcluded,
        });
      }
    }

    if (companySizes.length > 0) {
      let included = companySizes.filter(
        (x) => !companySizesExcluded.includes(x)
      );
      if (included.length > 0)
        newFilters.push({
          field: "company_size",
          [conditions.companySizes]: included,
        });
      if (companySizesExcluded.length > 0) {
        newFilters.push({
          field: "company_size",
          [negativeConditions[conditions.companySizes]]: companySizesExcluded,
        });
      }
    }

    if (activities.length > 0) {
      let included = activities.filter((x) => !activitiesExcluded.includes(x));
      if (included.length > 0)
        newFilters.push({
          field: "activity",
          [conditions.activities]: included,
        });
      if (activitiesExcluded.length > 0) {
        newFilters.push({
          field: "activity",
          [negativeConditions[conditions.activities]]: activitiesExcluded,
        });
      }
    }

    if (activityKeyword.length > 0) {
      let included = activityKeyword.filter(
        (x) => !activityKeywordExcluded.includes(x)
      );
      if (included.length > 0)
        newFilters.push({
          field: "category",
          [conditions.activityKeyword]: included,
        });
      if (activityKeywordExcluded.length > 0) {
        newFilters.push({
          field: "category",
          [negativeConditions[conditions.activityKeyword]]:
            activityKeywordExcluded,
        });
      }
    }

    if (countries.length > 0) {
      let included = countries.filter((x) => !countriesExcluded.includes(x));
      if (included.length > 0)
        newFilters.push({
          field: "country",
          [conditions.countries]: included,
        });
      if (countriesExcluded.length > 0) {
        newFilters.push({
          field: "country",
          [negativeConditions[conditions.countries]]: countriesExcluded,
        });
      }
    }

    if (withPhone) {
      newFilters.push({
        field: "phone",
        exists: true,
      });
      // newFilters.push({
      //   field: "mobile_phone",
      //   exists: true,
      // });
    }

    if (withLinkedin) {
      newFilters.push({
        field: "linkedin_url",
        exists: true,
      });
    }

    if (withWebsite) {
      newFilters.push({
        field: "website",
        exists: true,
      });
    }

    if (withBio) {
      newFilters.push({
        field: "summary",
        exists: true,
      });
    }

    setFilters(newFilters);
  }, [
    jobTitles,
    excludedJobTitles,
    contactLocalizations,
    contactLocalizationsExcluded,
    postalCodes,
    postalCodesExcluded,
    companies,
    companiesExcluded,
    companySizes,
    companySizesExcluded,
    activities,
    activitiesExcluded,
    countries,
    countriesExcluded,
    withPhone,
    withLinkedin,
    conditions,
    negativeConditions,
    withWebsite,
    withBio,
    activityKeyword,
    activityKeywordExcluded,
  ]);

  useEffect(() => {
    handleCount();
  }, [filters, handleCount]);

  //#endregion

  return (
    <>
      <PagePermission permission={"displayTargetingDatabase"} />
      <Group wrap="nowrap" align="stretch" flex={1} h={"100%"} gap={0}>
        <Stack
          px={"sm"}
          gap={"sm"}
          w={"100%"}
          style={{ overflow: "auto", borderRight: "1px solid #00000016" }}
          mah={"100%"}
          pr={32}
        >
          <Title order={5} mb={8}>
            {lang.contact_lists.import.methods.database.label} {reseller?.name}
          </Title>

          {/* <Text fz={18}>Contacts</Text> */}

          <Group
            align="start"
            justify="space-between"
            pb={22}
            style={{ borderBottom: "1px solid #00000018" }}
          >
            <Stack gap={6} w={"48%"}>
              <Text fw={600}>{lang.database.fields.job_title}</Text>

              <DatabaseInput
                data={[]}
                values={jobTitles}
                setValues={setJobTitles}
                excludes={excludedJobTitles}
                setExcludes={setExcludedJobTitles}
                placeholder={lang.database.placeholders.job_title}
                autoFocus={true}
                conditions={conditions}
                field={"jobTitles"}
                handleCondition={handleCondition}
                canImport
              />
            </Stack>

            <Stack gap={6} w={"48%"}>
              <Text fw={600}>{lang.database.fields.contact_location}</Text>

              <DatabaseInput
                values={contactLocalizations}
                setValues={setContactLocalizations}
                excludes={contactLocalizationsExcluded}
                setExcludes={setContactLocalizationsExcluded}
                placeholder={lang.database.placeholders.contact_location}
                conditions={conditions}
                field={"contactLocalizations"}
                handleCondition={handleCondition}
                searchFunction={searchDatabaseLocation}
              />
            </Stack>
          </Group>

          {/* <Text fz={18}>{lang.database.fields.company}</Text> */}
          <Group
            gap={"lg"}
            align="start"
            justify="space-between"
            pb={22}
            style={{ borderBottom: "1px solid #00000018" }}
          >
            <Stack gap={6} w={"48%"}>
              <Text fw={600}>{lang.database.fields.company}</Text>

              <DatabaseInput
                data={[]}
                values={companies}
                setValues={setCompanies}
                excludes={companiesExcluded}
                setExcludes={setCompaniesExcluded}
                placeholder={lang.database.placeholders.company}
                conditions={conditions}
                field={"companies"}
                handleCondition={handleCondition}
                canImport
              />
            </Stack>

            <Stack gap={6} w={"48%"}>
              <Text fw={600}>{lang.database.fields.company_size}</Text>

              <DatabaseInput
                data={companySizeOptions.map((x) => x.label)}
                values={companySizes}
                setValues={setCompanySizes}
                excludes={companySizesExcluded}
                setExcludes={setCompanySizesExcluded}
                placeholder={lang.database.placeholders.company_size}
                conditions={conditions}
                field={"companySizes"}
                handleCondition={handleCondition}
              />
            </Stack>

            <Stack gap={6} w={"48%"}>
              <Text fw={600}>{lang.database.fields.activity}</Text>

              <DatabaseInput
                data={business_sector}
                values={activities}
                setValues={setActivities}
                excludes={activitiesExcluded}
                setExcludes={setActivitiesExcluded}
                placeholder={lang.database.placeholders.activity}
                conditions={conditions}
                field={"activities"}
                handleCondition={handleCondition}
              />
            </Stack>

            <Stack gap={6} w={"48%"}>
              <Text fw={600}>{lang.database.fields.company_activity}</Text>

              <DatabaseInput
                data={[]}
                values={activityKeyword}
                setValues={setActivityKeyword}
                excludes={activityKeywordExcluded}
                setExcludes={setActivityKeywordExcluded}
                placeholder={lang.database.placeholders.company_activity}
                conditions={conditions}
                field={"activityKeyword"}
                handleCondition={handleCondition}
              />
            </Stack>

            <Stack gap={6} w={"48%"}>
              <Text fw={600}>{lang.database.fields.zip_code}</Text>

              <DatabaseInput
                data={[]}
                values={postalCodes}
                setValues={setPostalCodes}
                excludes={postalCodesExcluded}
                setExcludes={setPostalCodesExcluded}
                placeholder={lang.database.placeholders.zip_code}
                conditions={conditions}
                field={"postalCodes"}
                handleCondition={handleCondition}
              />
            </Stack>

            <Stack gap={6} w={"48%"}>
              <Text fw={600}>{lang.database.fields.country}</Text>

              <DatabaseInput
                data={countryOptions}
                values={countries}
                setValues={setCountries}
                excludes={countriesExcluded}
                setExcludes={setCountriesExcluded}
                placeholder={lang.database.placeholders.country}
                conditions={conditions}
                field={"countries"}
                handleCondition={handleCondition}
              />
            </Stack>
          </Group>

          <Stack gap={8} w={"100%"} pb={0}>
            <Text fw={600}>{lang.database.exists_title}</Text>
            <Group gap={"xl"}>
              <Checkbox
                name="with_phone"
                label={lang.database.exists_label + lang.database.fields.phone}
                onChange={(e) => setWithPhone(e.target.checked)}
              />
              <Checkbox
                name="with_linkedin"
                label={
                  lang.database.exists_label + lang.database.fields.linkedin_url
                }
                onChange={(e) => setWithLinkedin(e.target.checked)}
              />
              <Checkbox
                name="with_website"
                label={
                  lang.database.exists_label + lang.database.fields.website
                }
                onChange={(e) => setWithWebsite(e.target.checked)}
              />
              <Checkbox
                name="with_bio"
                label={lang.database.exists_label + lang.database.fields.bio}
                onChange={(e) => setWithBio(e.target.checked)}
              />
            </Group>
          </Stack>

          <Group wrap="nowrap" justify="space-between">
            <Tooltip
              label="Non autorisé"
              disabled={
                profile.permissions?.find(
                  (x) => x.name === "accessTargetingDatabase"
                )?.value === true
              }
            >
              <Button
                leftSection={<IconDeviceFloppy size={20} />}
                type="submit"
                mt={"md"}
                w={"36%"}
                mx={"auto"}
                gradient={{ from: "primary.4", to: "primary.5", deg: 90 }}
                onClick={handleOpen}
                disabled={
                  profile.permissions?.find(
                    (x) => x.name === "accessTargetingDatabase"
                  )?.value === false || count === 0
                }
              >
                {lang.database.save}
              </Button>
            </Tooltip>
          </Group>
        </Stack>

        <Stack w={"64%"} pl={32} style={{ overflow: "auto" }} mah={"100%"}>
          <Group>
            <Text size="xl">
              <b>
                <NumberFormatter value={count || " - "} thousandSeparator=" " />{" "}
                {lang.database.contacts}
              </b>{" "}
              {lang.database.found}
            </Text>
            {loading && <Loader size={20} type="oval" />}
          </Group>

          {count === 0 ? (
            <Stack>
              <Text c={"dimmed"} fw={400} fz={16}>
                Aucun contact trouvé avec vos critères de recherche
              </Text>
              <Box
                w={"60%"}
                mx={"auto"}
                mt={32}
                className={`animate__animated animate__fadeIn`}
              >
                <DatabaseIllustrationNoData />
              </Box>
            </Stack>
          ) : count === null ? (
            <Stack>
              <Text c={"dimmed"} fw={400} fz={16}>
                Lancez une recherche pour voir un aperçu des résultats
              </Text>
              <Box
                w={"70%"}
                mx={"auto"}
                mt={32}
                className={`animate__animated animate__fadeIn`}
              >
                <DatabaseIllustration />
              </Box>
            </Stack>
          ) : (
            <Stack>
              {contacts.map((item) => (
                <DatabaseContactItem key={item.id} item={item} />
              ))}
            </Stack>
          )}
        </Stack>
      </Group>

      {opened && (
        <DatabaseModal
          handleClose={() => setOpened(false)}
          filters={filters}
          onFinish={onFinish}
          count={count}
          listProfile={listProfile}
        />
      )}
    </>
  );
};

export default TargetingDatabase;
