import { useEffect, useMemo, useState } from "react";
import { Anchor, Group, Stack } from "@mantine/core";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { useLanguage } from "../../../../../stores/LanguageStore";
import {
  getIntegrationsStats,
  getQuickCampaigns,
} from "../../../../../api/StatsAPI";
import { getTypeIcon } from "../../../workflows/CreateCampaign/flow/flowSettings";
import { usePrmStore } from "../../../../../stores/PrmStore";
import MainTable from "../../../../../layouts/Table/MainTable";

const ProgrammationColumn = ({ id }) => {
  const lang = useLanguage((s) => s.language);
  const campaigns = usePrmStore((s) => s.campaignOptions);
  const campaign = useMemo(() => {
    return campaigns?.find((x) => x.id === id) || {};
  }, [campaigns, id]);

  return (
    <Stack direction="row" width={"100%"} overflow={"hidden"}>
      <Anchor
        size="sm"
        href={`/statistics/${id}`}
        target="_blank"
        truncate="end"
      >
        {campaign?.workflow_name ||
          lang.statistics_integrations.campaign_not_found}{" "}
        #{id}
      </Anchor>
    </Stack>
  );
};

function getIntegrationId(string) {
  const regex = /#(.*?)#/;
  const match = string.match(regex);

  if (match) {
    return match[1];
  } else {
    return null; // Return null if no match is found
  }
}

const Senders = () => {
  const lang = useLanguage((s) => s.language);
  const apiRef = useGridApiRef();
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (loading) {
      getIntegrationsStats()
        .then((response) => {
          let results = response.data.integrations;

          let final = [];

          results.forEach((stat) => {
            let date = new Date(stat.date).toDateString();
            let today = new Date().toDateString();
            let parent = {
              ...stat,
              path: [stat.date],
              id: stat.date,
              date:
                (date === today ? lang.statistics_integrations.today : date) +
                ` ( ${stat?.integrations?.length || 0} )`,
              isParent: true,
            };

            final.push(parent);

            stat.integrations.forEach((integration) => {
              let id =
                "#" +
                integration.id.toString() +
                "#" +
                stat.date +
                "/" +
                integration.programmation_id.toString(); // # = child row
              final.push({
                ...integration,
                path: [stat.date, id],
                id: id,
              });
            });
          });

          apiRef.current.updateRows(final);
          setLoading(false);
        })
        .finally(() => setLoading(false));

      getQuickCampaigns();
    }
  }, [loading, lang, apiRef]);

  const columns = useMemo(
    () => [
      {
        field: "date",
        headerName: lang.statistics_integrations.columns.date,
        width: 175,
      },
      {
        field: "id",
        headerName: "ID",
        width: 75,
        renderCell: ({ value, row }) => {
          if (row.isParent) {
            return "";
          }
          return getIntegrationId(value) || "";
        },
      },
      {
        field: "type",
        headerName: lang.statistics_integrations.columns.sender,
        width: 250,
        renderCell: ({ value, row }) => {
          if (row.isParent) {
            return "";
          }
          let parentLink = "";
          if (value === "Email") {
            parentLink = "email";
          } else if (value.includes("Linkedin")) {
            parentLink = "linkedin";
          }
          return (
            <Group w={"100%"} wrap="nowrap">
              {getTypeIcon(row?.account_type_email || value, false, 22, {
                size: "sm",
                variant: "subtle",
              })}
              <Anchor
                size="sm"
                truncate="end"
                href={`/senders/${parentLink}/?highlight=${getIntegrationId(
                  row.id
                )}`}
                target="_blank"
              >
                {row?.name || "-"}
              </Anchor>
            </Group>
          );
        },
      },
      {
        field: "interactions",
        headerName: lang.statistics_integrations.columns.interactions,
        width: 200,
        renderCell: ({ value, row }) => {
          if (row.isParent) {
            return "";
          }
          return value;
        },
      },
      {
        field: "programmation_id",
        headerName: lang.statistics_integrations.columns.prog,
        width: 250,
        renderCell: ({ value, row }) => {
          if (row.isParent) {
            return "";
          }
          return <ProgrammationColumn id={value} />;
        },
      },
    ],
    [lang]
  );

  return (
    <MainTable
      apiRef={apiRef}
      columns={columns}
      rows={rows}
      loading={loading}
      setLoading={setLoading}
      id={"list-table"}
      treeData
      withBorderTop
    />
  );
};

export default Senders;
