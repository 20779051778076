import { Stack, Text } from "@mantine/core";
import { Handle, Position } from "reactflow";
import { nodeWidth } from "../flowSettings";
import { useLanguage } from "../../../../../../stores/LanguageStore";

const StartNode = () => {
  const lang = useLanguage((s) => s.language)
  return (
    <>
      <Handle
        type="source"
        position={Position.Bottom}
        style={{ visibility: "hidden" }}
      />
      <Stack w={nodeWidth}>
        <Text fz={"lg"} ta={"center"}>
          🚀 {lang.campaign.nodes.starting_node}
        </Text>
        {/* <TextInput label="🚀 Début du scénario" w={"100%"} /> */}
      </Stack>
    </>
  );
};

export default StartNode;
