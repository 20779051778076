import { Button, Center, Text } from "@mantine/core";
import anim from "../../../assets/lotties/error.json";
import { Logo } from "../../../layouts/Logo";
import { Link } from "react-router-dom";
import { lazy } from "react";
const Lottie = lazy(() => import("react-lottie"));

const EmailNotAdded = () => {
  return (
    <Center
      w={"60vw"}
      h={"50vh"}
      style={{ flexDirection: "column" }}
      mx={"auto"}
      mt={128}
    >
      <Logo w={200} mb={64} />
      <Lottie
        options={{
          animationData: anim,
          autoplay: true,
          loop: true,
        }}
        height={150}
      />
      <Text fz={30}>L'Email n'a pas été ajouté.</Text>

      <Button variant="filled" mt={32} component={Link} to={"/senders/email"}>
        Revenir aux liste d'emails
      </Button>
    </Center>
  );
};

export default EmailNotAdded;
