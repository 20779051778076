import { Button, Popover, Stack, Text } from "@mantine/core";
import { useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { downloadAllCampaigns } from "../../../../../api/StatsAPI";
import { notifications } from "@mantine/notifications";
import { IconChevronDown, IconDownload } from "@tabler/icons-react";

const DownloadAllCampaigns = () => {
  const lang = useLanguage((s) => s.language);

  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(null);
  const handleDownload = (fileType) => {
    setLoading(fileType);

    downloadAllCampaigns({
      file_type: fileType,
    })
      .then((res) => {
        notifications.show({
          message: lang.contact_lists.download_started,
          color: "tertiary.8",
        });
        setOpened(false);
      })
      .finally(() => setLoading(null));
  };

  return (
    <Popover opened={opened} onChange={setOpened} zIndex={200}>
      <Popover.Target>
        <Button
          onClick={() => setOpened(true)}
          leftSection={<IconDownload size={18} />}
          rightSection={<IconChevronDown size={16} />}
          fw={600}
          color="primary"
          variant="light"
          justify="start"
          ml={"auto"}
        >
          {lang.statistics.download_all}
        </Button>
      </Popover.Target>

      <Popover.Dropdown>
        <Text mb={"xs"}>{lang.contact_lists.download_text} </Text>
        <Stack gap={"sm"}>
          <Button
            variant="light"
            onClick={() => handleDownload("csv")}
            loading={loading === "csv"}
            color="tertiary.8"
          >
            CSV
          </Button>
          <Button
            variant="light"
            onClick={() => handleDownload("xlsx")}
            loading={loading === "xlsx"}
            color="tertiary.8"
          >
            EXCEL
          </Button>
          <Button
            variant="light"
            onClick={() => handleDownload("json")}
            loading={loading === "json"}
            color="tertiary.8"
          >
            JSON
          </Button>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
};

export default DownloadAllCampaigns;
