import {
  Accordion,
  Anchor,
  Button,
  Group,
  Modal,
  Stack,
  Text,
} from "@mantine/core";
import { IconQuestionMark, IconReceipt2, IconUser } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import { useProfileStore } from "../../../../stores/UserStore";
import { Link } from "react-router-dom";
import BackButton from "../../../../components/View/BackButton";
import { useEffect, useMemo, useState } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";

const ResellerHowTo = () => {
  const lang = useLanguage((s) => s.language);
  const [opened, { open, close }] = useDisclosure();
  const profile = useProfileStore((s) => s.profile);

  const [link, setLink] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const domain = params.get("domain");

    setLink(domain || "https://test.com");

    if (domain) open();
  }, []);

  const howtos = useMemo(
    () => [
      {
        icon: <IconUser size={18} />,
        value: "create",
        label: lang.resellers.how.creation.label,
        content: (
          <Stack>
            <Text c={"dimmed"}>{lang.resellers.how.creation.text1}</Text>
            <Text fw={500}>
              {lang.resellers.how.creation.text2} <br /> (
              <Anchor target="_blank" href={link + "/signup"}>
                {" "}
                {link + "/signup"}{" "}
              </Anchor>
              ). {lang.resellers.how.creation.text3}
            </Text>
            <Text fw={500}>{lang.resellers.how.creation.text4}</Text>
          </Stack>
        ),
      },
      {
        icon: <IconReceipt2 size={18} />,
        value: "pricing",
        label: lang.resellers.how.pricing.label,
        content: (
          <Stack>
            <Text c={"dimmed"}>{lang.resellers.how.pricing.text1}</Text>
            <Text fw={500}>
              {lang.resellers.how.pricing.text2}{" "}
              <Anchor component={Link} to={"/account-settings/subscription"}>
                {lang.resellers.how.pricing.text3}
              </Anchor>
              {lang.resellers.how.pricing.text4} <br />
              <br />
              {lang.resellers.how.pricing.text5} <br />
              <br />
              {lang.resellers.how.pricing.text6}
            </Text>
          </Stack>
        ),
      },
    ],
    [link]
  );

  return (
    <>
      <Button
        leftSection={<IconQuestionMark size={18} />}
        radius={"sm"}
        variant="light"
        color="primary"
        onClick={open}
      >
        {lang.resellers.how.button_label}
      </Button>

      <Modal
        onClose={close}
        title={lang.resellers.how.title}
        opened={opened}
        centered
        overlayProps={{ opacity: 0.6 }}
        size={"lg"}
      >
        <Text fw={500} size="lg">
          {profile.first_name}, {lang.resellers.how.text1} <span style={{ fontSize: 18 }}>🚀</span>
        </Text>
        <Group mt={"md"} w={"100%"}>
          <Accordion variant="separated" w={"100%"}>
            {howtos.map((item) => (
              <Accordion.Item key={item.value} value={item.value}>
                <Accordion.Control
                  icon={item.icon}
                  bg={"var(--mantine-color-gray-1)"}
                >
                  {item.label}
                </Accordion.Control>
                <Accordion.Panel>{item.content}</Accordion.Panel>
              </Accordion.Item>
            ))}
          </Accordion>
        </Group>

        <Group justify="end" mt={"md"}>
          <BackButton onClick={close} />
        </Group>
      </Modal>
    </>
  );
};

export default ResellerHowTo;
