import { useGridApiRef } from "@mui/x-data-grid-pro";
import { useCallback, useState } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import {
  deleteUnsub,
  downloadUnsubscribers,
  getFirstPageUnsub,
  getFirstPageUnsubAdmin,
  searchAllUnsub,
} from "../../../../api/UnsubscribersAPI";
import { useContactHandler } from "../../lists/components/useContactHandler";
import MainTable from "../../../../layouts/Table/MainTable";
import { Button, Center, Group, SegmentedControl, Text } from "@mantine/core";
import ItemDeleter from "../../../../components/TableComponents/ItemDeleter";
import TableLayoutBlock from "../../../../layouts/Table/TableLayoutBlock";
import dateColumn from "../../../../layouts/Table/columns/dateColumn";
import { Link } from "react-router-dom";
import { useProfileStore } from "../../../../stores/UserStore";
import SelectionDeleter from "../../../../components/TableComponents/SelectionDeleter";
import UnsubsImportButton from "./UnsubsImportButton";
import { IconArrowBarToUp, IconUser, IconWorld } from "@tabler/icons-react";
import PageTitle from "../../../../components/View/PageTitle";
import TableFilterExport from "../../../../components/TableComponents/TableFilterExport";

const columnsHandler = () => {
  const lang = useLanguage.getState().language;
  return [
    // numberColumn(),
    {
      field: "organization_id",
      headerName: lang.unsubscribers.columns.org,
      width: 120,
      renderCell: ({ row, value }) => {
        return <Text>{row.organization_name}</Text>;
      },
      id: "organization_id",
      sortable: false,
      getFilterOptions: () => {
        const profile = useProfileStore.getState().profile;
        return profile.organizations.map((x) => ({
          label: x.name,
          value: x.id.toString(),
        }));
      },
      disabledCondition: true,
    },
    dateColumn(),
    {
      field: "email",
      headerName: lang.unsubscribers.columns.email,
      width: 150,
      filterable: false,
      sortable: false,
      id: "email",
    },
    {
      field: "domain_email",
      headerName: lang.unsubscribers.columns.domain_email,
      width: 150,
      filterable: false,
      sortable: false,
      id: "domain_email",
    },
    {
      field: "linkedin_url",
      headerName: lang.unsubscribers.columns.linkedin_url,
      width: 150,
      filterable: false,
      sortable: false,
      id: "linkedin_url",
    },
    {
      field: "company",
      headerName: lang.unsubscribers.columns.company,
      width: 150,
      filterable: false,
      sortable: false,
      id: "company",
    },
    {
      field: "phone",
      headerName: lang.unsubscribers.columns.phone,
      width: 150,
      filterable: false,
      sortable: false,
      id: "phone",
    },
    {
      field: "siren",
      headerName: lang.unsubscribers.columns.siren,
      width: 150,
      filterable: false,
      sortable: false,
      id: "siren",
    },
    {
      field: "siret",
      headerName: lang.unsubscribers.columns.siret,
      width: 150,
      filterable: false,
      sortable: false,
      id: "siret",
    },
    {
      field: "naf_code",
      headerName: lang.unsubscribers.columns.naf_code,
      width: 150,
      filterable: false,
      sortable: false,
      id: "naf_code",
    },
  ];
};

const resultsHandler = (results) => {
  return results.map((data) => ({
    ...data,
    id: data.id,
    [data.value_type]: data.value,
    organization_name: data?.organization_name,
    organization_id: data.organization_id,
    created_by: data.created_by,
  }));
};

const UnsubscribersListing = () => {
  const lang = useLanguage((s) => s.language);
  const apiRef = useGridApiRef();
  const profile = useProfileStore((s) => s.profile);
  const [type, setType] = useState("account");

  const {
    loading,
    columns,
    rows,
    rowCount,
    handleLoading,
    onFilterChange,
    onPaginationChange,
    onSortChange,
  } = useContactHandler({
    apiRef,
    listId: null,
    getFirstPage:
      type === "account" ? getFirstPageUnsub : getFirstPageUnsubAdmin,
    searchFunction: searchAllUnsub,
    resultsHandler,
    columnsHandler,
    disableQueryTransform: true,
  });

  const actionsCell = useCallback(
    ({ row, value }) => {
      return (
        <ItemDeleter
          title_bold={`#${row.id} ?`}
          handleDelete={deleteUnsub}
          deletedMessage={lang.unsubscribers.delete_success}
          id={row.id}
          apiRef={apiRef}
        />
      );
    },
    [apiRef, lang.unsubscribers.delete_success]
  );

  const toolbarActions = useCallback(
    ({ selectedIds, filter }) => (
      <>
        <TableFilterExport
          selectedIds={selectedIds}
          filter={filter}
          handleExport={downloadUnsubscribers}
          name={"Unsubscribers"}
          filterResultNumber={rowCount}
          total={rowCount}
          withExcludedIds={false}
          withSelection={false}
        />
        <SelectionDeleter
          handleDelete={deleteUnsub}
          apiRef={apiRef}
          setTableLoading={() => handleLoading()}
          ids={selectedIds}
        />
      </>
    ),
    [apiRef, handleLoading, rowCount]
  );

  if (!columns) {
    return;
  }

  return (
    <>
      <Group className="layout-block top" w={"100%"} justify="space-between">
        <PageTitle title={lang.unsubscribers.browser_tab_title} />

        {profile?.level === "admin" && (
          <SegmentedControl
            ml={"auto"}
            size="sm"
            w={400}
            data={[
              {
                label: (
                  <Center component={Group}>
                    <IconUser size={18} />

                    <span>{lang.unsubscribers.tabs_title.user_when_admin}</span>
                  </Center>
                ),
                value: "account",
              },
              {
                label: (
                  <Center component={Group}>
                    <IconWorld size={18} />

                    <span>{lang.unsubscribers.tabs_title.admin}</span>
                  </Center>
                ),
                value: "admin",
              },
            ]}
            value={type}
            onChange={(v) => {
              setType(v);
              handleLoading(
                true,
                v === "account" ? getFirstPageUnsub : getFirstPageUnsubAdmin
              );
            }}
            disabled={loading}
          />
        )}

        <UnsubsImportButton />
      </Group>

      <TableLayoutBlock>
        <MainTable
          isClient={false}
          apiRef={apiRef}
          columns={columns}
          rows={rows}
          rowCount={rowCount}
          loading={loading}
          setLoading={() => {
            handleLoading(true);
          }}
          id={"list-table"}
          hasFolder
          pageName={"unsubscribers"}
          onPaginationChange={onPaginationChange}
          actionsCell={actionsCell}
          onFilterChange={onFilterChange}
          onSortChange={onSortChange}
          pageOrderName="unsubscribers_order"
          toolbarActions={toolbarActions}
          actionsCellWidth={50}
          noRowsDisplay={
            <Button
              leftSection={<IconArrowBarToUp size={32} />}
              size="xl"
              radius={"sm"}
              variant="light"
              color="tertiary.8"
              component={Link}
              to={"import"}
            >
              Importer une liste à désinscrire
            </Button>
          }
          disableSearch={type === "admin"}
        />
      </TableLayoutBlock>
    </>
  );
};

export default UnsubscribersListing;
