import {
  Button,
  Group,
  Modal,
  Paper,
  Stack,
  Switch,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import {
  IconDeviceFloppy,
  IconUserCancel,
  IconUserExclamation,
  IconX,
} from "@tabler/icons-react";
import { useLanguage } from "../../../../../../stores/LanguageStore";
import { useFlowStore } from "../../../../../../stores/WorkflowStore";
import { handleLayout } from "../layouter";
import { useDisclosure } from "@mantine/hooks";
import { useReactFlow } from "reactflow";
import WorkflowListingModal from "../../../listing/WorkflowListingModal";
import { useCallback, useState } from "react";
import { closeModal, modals } from "@mantine/modals";
import WorkflowTemplates from "../../../templates/WorkflowTemplates";
import { handleWorkflowGet } from "../flowUtils";

const FlowConfig = ({ handleSubmit }) => {
  const lang = useLanguage((s) => s.language);
  const [opened, { open, close }] = useDisclosure();
  const reactFlow = useReactFlow();
  const [isSave, setIsSave] = useState(false);
  const flowProfile = useFlowStore((s) => s.profile);

  const handleWorkflowImport = useCallback(
    (workflow_profile, handleClose) => {
      modals.openConfirmModal({
        title: lang.campaign.import.confirm.title,
        children: (
          <Text size="sm">
            {lang.campaign.import.confirm.text1} <br />
            <b>{lang.campaign.import.confirm.text2}</b>
          </Text>
        ),
        centered: true,
        labels: {
          confirm: lang.campaign.import.label,
          cancel: lang.global.cancel,
        },
        onConfirm: () => {
          closeModal();
          handleWorkflowGet({
            workflowProfile: workflow_profile,
            isEdit: false,
            onFinish: ({ newNodes, newEdges }) => {
              setTimeout(() => {
                document.getElementById("node_head_" + newNodes[0].id)?.click();
              }, 300);
            },
          });
          handleClose?.();
        },
      });
    },
    [
      lang.campaign.import.confirm.text1,
      lang.campaign.import.confirm.text2,
      lang.campaign.import.confirm.title,
      lang.campaign.import.label,
      lang.global.cancel,
    ]
  );

  const handleClear = () => {
    const setNodes = useFlowStore.getState().setNodes;
    const setEdges = useFlowStore.getState().setEdges;

    setNodes([]);
    setEdges([]);
    setTimeout(() => {
      handleLayout();
      setTimeout(() => {
        close();
        let nodes = useFlowStore.getState().nodes;
        let n = nodes.find((x) => x.data.source === "0");
        if (n) reactFlow.fitView({ nodes: [n], duration: 1000 });
      }, 100);
    }, 10);
  };

  return (
    <>
      <Paper m={-15} maw={300} bg={"transparent"} pos={"relative"}>
        <Group
          wrap="nowrap"
          gap={"xs"}
          component={"form"}
          onSubmit={(e) => {
            handleSubmit(e, isSave);
            setIsSave(false);
          }}
          id="workflow-config-form"
        >
          <TextInput
            placeholder={lang.campaign.name}
            w={"100%"}
            name="name"
            required
            defaultValue={useFlowStore.getState().profile?.name}
            id="workflow-name"
          />
          <Tooltip
            label={lang.workflows.configs.exclude_answerers}
            refProp="rootRef"
          >
            <Switch
              key={flowProfile?.id}
              size="md"
              onLabel={<IconUserCancel size={18} />}
              offLabel={<IconUserExclamation size={18} />}
              defaultChecked={flowProfile.auto_remove_responders}
              onChange={(e) =>
                useFlowStore.getState().setProfile({
                  ...useFlowStore.getState().profile,
                  auto_remove_responders: e.target.checked,
                })
              }
              name="auto_remove_responders"
            />
          </Tooltip>

          <button id="workflow-config-submitter" style={{ display: "none" }} />
        </Group>
        <Stack gap={"sm"} mt={"xs"} w={112} pos={"absolute"}>
          <WorkflowListingModal />

          <Tooltip label={lang.campaign.save_tooltip}>
            <Button
              size="xs"
              leftSection={<IconDeviceFloppy size={18} />}
              variant="light"
              fw={400}
              radius={"sm"}
              onClick={() => {
                setIsSave(true);
                setTimeout(() => {
                  document.getElementById("workflow-config-submitter").click();
                }, 50);
              }}
            >
              {lang.campaign.save}
            </Button>
          </Tooltip>

          <WorkflowTemplates inWorkflow onCreated={handleWorkflowImport} />

          <Tooltip label={lang.campaign.delete_all.tooltip}>
            <Button
              size="xs"
              leftSection={<IconX size={18} />}
              variant="light"
              fw={400}
              color="red"
              onClick={open}
              radius={"sm"}
            >
              {lang.campaign.delete_all.label}
            </Button>
          </Tooltip>
        </Stack>
      </Paper>

      <Modal
        opened={opened}
        onClose={close}
        title={lang.campaign.delete_all.confirm.title}
        centered
      >
        <Text>
          {lang.campaign.delete_all.confirm.text1}{" "}
          <b>{lang.campaign.delete_all.confirm.text2}</b> ?
        </Text>
        <Group justify="flex-end" mt={"md"}>
          <Button variant="subtle" onClick={close}>
            {lang.global.cancel}
          </Button>
          <Button color="red" onClick={handleClear}>
            {lang.campaign.delete_all.confirm.title}
          </Button>
        </Group>
      </Modal>
    </>
  );
};

export default FlowConfig;
