import { Button, Group, Text, Tooltip } from "@mantine/core";
import { IconArrowsRandom, IconFolder } from "@tabler/icons-react";
import { Link, useParams } from "react-router-dom";
import { useUIStore } from "../../../stores/UIStore";
import { useState } from "react";
import DragConfirmation from "../DragConfirmation";
import RenameFolder from "./RenameFolder";
import DeleteFolder from "./DeleteFolder";
import { notifications } from "@mantine/notifications";

const FolderItem = ({
  item,
  editFunction,
  getEditFunction,
  apiRef,
  isPool,
  setTableLoading,
  onFolderItemClick,
  actualFolderId,
}) => {
  const { folderId, poolId } = useParams();
  const mainLoading = useUIStore((s) => s.mainLoading);

  const [dragging, setDragging] = useState(false);
  const [dragged, setDragged] = useState(null);

  const onDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const onDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const onDrop = (ev) => {
    ev.preventDefault();
    var data = ev.dataTransfer.getData("text");

    let mainData = JSON?.parse(data);

    let draggedElement = mainData?.name?.includes("http") ? null : mainData;

    if (draggedElement) {
      setDragging(false);
      if (isPool && draggedElement?.pools?.find((x) => x.id === item.id)) {
        return notifications.show({
          message: "L'élément est déjà dans le Pool",
        });
      }
      if (draggedElement.folder?.id === item.id) {
        return notifications.show({
          message: "L'élément est déjà dans le Pool",
        });
      }
      return setDragged(draggedElement);
    }
  };

  return (
    <>
      <Button
        variant={
          folderId === item.id.toString() ||
          poolId === item.id.toString() ||
          actualFolderId === item.id
            ? "filled"
            : dragging
            ? "light"
            : "default"
        }
        px={8}
        py={4}
        leftSection={
          isPool ? <IconArrowsRandom size={16} /> : <IconFolder size={16} />
        }
        ta={"left"}
        justify="space-between"
        w={"100%"}
        styles={{ label: { width: "100%" }, section: { marginRight: 6 } }}
        component={onFolderItemClick ? undefined : Link}
        to={item.url}
        disabled={mainLoading}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        mt={"xs"}
        className="hover-source"
        onClick={onFolderItemClick ? () => onFolderItemClick(item) : undefined}
      >
        <Tooltip label={item.name}>
          <Text fz={"xs"} truncate="end" mr={4} flex={"1 1 auto"} w={0}>
            {item.name}
          </Text>
        </Tooltip>
        <Text opacity={0.5} ml={"auto"} fz={"sm"}>
          {item?.number_of_items || item?.number_of_accounts || 0}
        </Text>

        <Group pos={"absolute"} right={8} gap={"xs"}>
          <DeleteFolder
            item={item}
            isPool={isPool}
            setTableLoading={setTableLoading}
          />
          <RenameFolder
            item={item}
            isPool={isPool}
            setTableLoading={setTableLoading}
          />
        </Group>
      </Button>

      {dragged && (
        <DragConfirmation
          dragged={dragged}
          setDragged={setDragged}
          folder={item}
          editFunction={editFunction}
          getEditFunction={getEditFunction}
          apiRef={apiRef}
          isPool={isPool}
        />
      )}
    </>
  );
};

export default FolderItem;
