import { useMemo, useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { Center, Group, SegmentedControl, Stack, Text } from "@mantine/core";
import BackButton from "../../../../../components/View/BackButton";
import CaptchaItem from "./CaptchaItem";
import { IconMailCheck, IconMailX } from "@tabler/icons-react";

const CaptchaContent = ({ items, setItems, handleClose }) => {
  const lang = useLanguage((s) => s.language);
  const [type, setType] = useState("unhandled");

  const emails = useMemo(() => {
    return items.filter((x) => (type === "handled" ? x.handled : !x.handled));
  }, [items, type]);

  return (
    <>
      <SegmentedControl
        data={[
          {
            label: (
              <Center>
                <IconMailX size={20} style={{ marginRight: 8 }} />
                {lang.prm.captcha.unhandled} (
                  {items.filter((x) => !x.handled)?.length || 0})
              </Center>
            ),
            value: "unhandled",
          },
          {
            label: (
              <Center>
                <IconMailCheck size={20} style={{ marginRight: 8 }} />
                {lang.prm.captcha.handled} (
                {items.filter((x) => x.handled)?.length || 0})
              </Center>
            ),
            value: "handled",
          },
        ]}
        size="md"
        w={"100%"}
        onChange={setType}
        value={type}
        color={type === "handled" ? "green.8" : "red"}
      />
      <Stack pos={"relative"} gap={"xs"} h={"100%"} mt={"md"}>
        <Text c={"dimmed"}>{lang.prm.captcha.main_text}</Text>

        {emails.length > 0 ? (
          <Stack gap={"md"} h={"55vh"} style={{ overflow: "auto" }}>
            {emails.map((item, index) => {
              return (
                <CaptchaItem key={item.id} item={item} setItems={setItems} />
              );
            })}
          </Stack>
        ) : (
          <Text c={"dimmed"} size="lg" ta={"center"}>
            {lang.prm.captcha.no_captcha}
          </Text>
        )}
      </Stack>

      <Group justify="end" mt={"md"}>
        <BackButton onClick={handleClose} />
      </Group>
    </>
  );
};

export default CaptchaContent;
