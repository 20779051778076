import { useCallback, useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import {
  Button,
  Group,
  Indicator,
  InputDescription,
  InputLabel,
  Modal,
  Select,
  Stack,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { IconEdit, IconFilter, IconTrash } from "@tabler/icons-react";
import { useLeadsStore } from "../../../../../stores/LeadsStore";
import { editSharing } from "../../../../../api/PRMAPI";
import FilterContent from "../../../../../components/Filter/FilterContent";
import { notifications } from "@mantine/notifications";
import PopOver from "../../../../../components/View/PopOver";
import BackButton from "../../../../../components/View/BackButton";
import UsersSelector from "../../../../../components/UserSelector/UsersSelector";
import CommunitySelector from "../../../../../components/Sharing/CommunitySelector";
import { IconEye } from "@tabler/icons-react";

const LeadsSharingEdit = ({ share, handleDone }) => {
  const lang = useLanguage((s) => s.language);
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const filters = useLeadsStore((s) => s.filters);
  const [targets, setTargets] = useState(
    share?.sharing_targets?.map((sharing_target) => ({
      ...sharing_target,
      option_name: sharing_target.name,
    })) || []
  );
  const [type, setType] = useState(share?.sharing_type_to);
  const [writePermission, setWritePermission] = useState(
    share?.write_permission
  );
  const [deletePermission, setDeletePermission] = useState(
    share?.delete_permission
  );
  const [filter, setFilter] = useState(share?.filter || null);

  const handleTypeChange = (v) => {
    setType(v);
    setTargets([]);
  };

  const handleTargetsChange = useCallback((change) => {
    setTargets(
      change.map((x) => ({
        id: x.id,
        option_name:
          x?.option_name || x?.name || `${x.first_name} ${x.last_name}`,
      }))
    );
  }, []);

  const getTargetsComponents = useCallback(() => {
    if (type === "User") {
      return (
        <UsersSelector
          values={targets}
          onChange={handleTargetsChange}
          placeholder={lang.components.sharing.values.user_placeholder}
          inputStyles={{
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0,
          }}
        />
      );
    } else {
      return (
        <CommunitySelector
          values={targets}
          onChange={handleTargetsChange}
          placeholder={
            lang.components.sharing.values[
              type === "Team" ? "team_placeholder" : "org_placeholder"
            ]
          }
          type={type}
          inputStyles={{
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0,
          }}
        />
      );
    }
  }, [handleTargetsChange, lang.components.sharing.values, targets, type]);

  const handleOpen = () => {
    setOpened(true);
  };
  const handleClose = () => {
    setOpened(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    let formData = new FormData(e.target);
    let data = Object.fromEntries(formData);

    editSharing({
      id: share.id,
      name: data?.name,
      filter: filter === null ? { mode: "and", values: [] } : filter,
      delete_permission: deletePermission,
      read_permission: true,
      write_permission: writePermission,
      sharing_type_to: type,
      ids_list: targets.map((x) => x.id),
    })
      .then((response) => {
        handleClose();
        notifications.show({
          message: "Partagé",
          color: "tertiary.8",
        });
        handleDone();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        leftSection={<IconEdit size={18} />}
        variant="light"
        h={28}
      >
        {lang.prm.sharing.edit}
      </Button>

      <Modal
        opened={opened}
        title={lang.prm.sharing.create_dialog_title}
        onClose={handleClose}
        size={"50%"}
      >
        <Stack component={"form"} onSubmit={onSubmit}>
          <Group>
            <TextInput
              name="name"
              label={lang.prm.sharing.name}
              placeholder={lang.prm.sharing.name}
              defaultValue={share?.name}
              required
              w={"100%"}
              autoFocus
              data-autoFocus
            />
          </Group>

          <Group w={"100%"} wrap="nowrap">
            <Stack gap={0} w={"100%"}>
              <InputLabel size="sm" required mb={2}>
                {lang.components.sharing.values.label}
              </InputLabel>
              <Group wrap="nowrap" gap={0} w={"100%"}>
                <Select
                  data={[
                    {
                      value: "User",
                      label: lang.components.sharing.types.user,
                    },
                    {
                      value: "Team",
                      label: lang.components.sharing.types.team,
                    },
                    {
                      value: "Organization",
                      label: lang.components.sharing.types.org,
                    },
                  ]}
                  placeholder={lang.components.sharing.types.label}
                  w={200}
                  styles={{
                    wrapper: {
                      height: "100%",
                    },
                    input: {
                      borderRight: 0,
                      borderBottomRightRadius: 0,
                      borderTopRightRadius: 0,
                      height: "100%",
                    },
                  }}
                  onChange={handleTypeChange}
                  value={type}
                />
                {getTargetsComponents()}
              </Group>
            </Stack>

            <Stack gap={0}>
              <InputLabel size="sm" required mb={2}>
                {lang.components.sharing.permissions.label}
              </InputLabel>
              <Group wrap="nowrap" gap={"xs"} mt={4}>
                <Tooltip label={lang.components.sharing.permissions.read}>
                  <Button variant="default" px={6} size="xs" disabled>
                    <IconEye size={16} />
                  </Button>
                </Tooltip>

                <Tooltip label={lang.components.sharing.permissions.write}>
                  <Button
                    color="tertiary.8"
                    variant={writePermission ? "filled" : "outline"}
                    px={6}
                    size="xs"
                    onClick={() => setWritePermission(!writePermission)}
                  >
                    <IconEdit size={16} />
                  </Button>
                </Tooltip>

                <Tooltip label={lang.components.sharing.permissions.delete}>
                  <Button
                    color="tertiary.8"
                    variant={deletePermission ? "filled" : "outline"}
                    px={6}
                    size="xs"
                    onClick={() => setDeletePermission(!deletePermission)}
                  >
                    <IconTrash size={16} />
                  </Button>
                </Tooltip>
              </Group>
            </Stack>
          </Group>

          <Stack gap={0}>
            <InputLabel size="sm" required mb={2}>
              {lang.table.toolbar.filter.main_button_label}
            </InputLabel>
            <InputDescription mb={4}>
              {lang.table.toolbar.filter.prm_text}
            </InputDescription>
            <PopOver
              opener={(openFunction, ref) => {
                return (
                  <Button
                    ref={ref}
                    variant="default"
                    onClick={openFunction}
                    id={"sharing-filter-" + "creation"}
                  >
                    <Indicator
                      color="red"
                      offset={2}
                      disabled={filter?.values?.length === 0 || !filter}
                      label={filter?.values?.length}
                      size={16}
                    >
                      <IconFilter size={18} />
                    </Indicator>
                    <span style={{ marginLeft: 6 }}>
                      {lang.table.toolbar.filter.main_button_label}
                    </span>
                  </Button>
                );
              }}
              popoverChildren={(closePopover) => (
                <FilterContent
                  fields={filters}
                  id={"nurturing-create"}
                  isClient={false}
                  pageName={"prm"}
                  maxHeight={350}
                  mainFilter={filter}
                  setMainFilter={(v) => {
                    closePopover();
                    setFilter(v);
                  }}
                  defaultFiltersClose
                  getFieldOptionName={(f) => f.option_name}
                />
              )}
              elevation={3}
            />
          </Stack>

          <Group justify="end">
            <BackButton onClick={handleClose} />
            <Button
              leftSection={<IconEdit size={18} />}
              loading={loading}
              type="submit"
            >
              {lang.prm.sharing.edit}
            </Button>
          </Group>
        </Stack>
      </Modal>
    </>
  );
};

export default LeadsSharingEdit;
