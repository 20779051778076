import { useCallback, useEffect, useMemo, useState } from "react";
import { useListStore } from "../../stores/ListStore";
import { createList, findList, getLists } from "../../api/ContactAPI";
import MultiSelector from "./MultiSelector";
import {
  Anchor,
  Button,
  Group,
  Loader,
  Stack,
  Text,
  TextInput,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import { useLanguage } from "../../stores/LanguageStore";
import { parseDate } from "../../functions/dates";
import PopOver from "../View/PopOver";
import BackButton from "../View/BackButton";
import { IconMail } from "@tabler/icons-react";
import { IconLinkedin } from "../Icons/IconLinkedin";

const RightSection = ({ onListCreated }) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e, closeMenu) => {
    e.preventDefault();
    e.stopPropagation();
    const fd = new FormData(e.target);
    const data = Object.fromEntries(fd);

    setLoading(true);
    createList({ name: data.name })
      .then((res) => {
        findList(res.data.contact_list_id)
          .then((findRespnose) => {
            onListCreated(findRespnose.data.contact_list_profile);
            closeMenu();
          })
          .finally(() => setLoading(false));
      })
      .catch(() => setLoading(false));
  };

  return (
    <PopOver
      opener={(handleOpen, ref) => (
        <Button
          ref={ref}
          size="xs"
          ml={16}
          variant="light"
          onClick={() => handleOpen(true)}
        >
          {lang.contact_lists.create.button_tooltip}
        </Button>
      )}
      popoverChildren={(handleClose) => (
        <Stack
          w={300}
          component={"form"}
          onSubmit={(e) => handleSubmit(e, handleClose)}
        >
          <TextInput
            name="name"
            label={lang.contact_lists.create.dialog.name}
            autoFocus
          />
          <Group justify="end">
            <BackButton onClick={handleClose} />
            <Button type="submit" loading={loading}>
              {lang.contact_lists.create.dialog.submit_button}
            </Button>
          </Group>
        </Stack>
      )}
    />
  );
};

export function getListOptionName(option) {
  return `${option.name} ( ${option.number_of_contacts} )`;
}

const ContactListSelector = ({
  label,
  size,
  values,
  onChange,
  disabled,
  leftSection,
  inputHeight = 110,
  newItems = [],
  required,
  dropdownHeight = 150,
  withCreator,
  withCounts,
}) => {
  const lang = useLanguage((s) => s.language);
  const lists = useListStore((s) => s.lists);
  const [loading, setLoading] = useState(false);
  const handleLoad = useCallback(() => {
    setLoading(true);
    getLists().finally(() => setLoading(false));
  }, []);

  const options = useMemo(() => {
    return lists.map((list) => ({
      ...list,
      option_name: getListOptionName(list),
    }));
  }, [lists]);

  const handleListCreated = (list) => {
    handleLoad();
    onChange(
      values?.length > 0
        ? [...values, list]
        : [{ ...list, option_name: list.name }]
    );
  };

  useEffect(() => {
    setLoading(true);
    getLists().finally(() => setLoading(false));
  }, []);

  return (
    <MultiSelector
      options={options}
      label={label}
      onDropdownOpen={handleLoad}
      loading={loading}
      values={lists.length > 0 ? values : []}
      onChange={onChange}
      placeholder={lang.campaign.search_list}
      leftSection={leftSection}
      rightOptionSection={(item) => {
        return (
          <>
            {item?.state_details?.find((x) =>
              ["InQueue", "InProgress"].includes(x.state)
            ) && (
              <Tooltip
                label={
                  lang.contact_lists.jobs.in_progress.loading_indicator_tooltip
                }
              >
                <Loader type="dots" size={14} />
              </Tooltip>
            )}
            {withCounts && (
              <Group gap={0}>
                <Text fz={11} mr={4}>
                  {item.number_of_emails}
                </Text>
                <IconMail size={14} />
                <Text fz={11} ml={16} mr={4}>
                  {item.number_of_linkedin_url}
                </Text>
                <IconLinkedin size={14} />
              </Group>
            )}
            {item?.created_on && (
              <span style={{ fontSize: 10, opacity: 0.35, width: 105 }}>
                {parseDate(item?.created_on)}
              </span>
            )}
            {item?.id && (
              <Anchor
                href={`/contact-lists/${item.id}`}
                fz={10}
                target="_blank"
                onClick={(e) => e.stopPropagation()}
                // opacity={0.35}
              >
                #{item.id}
              </Anchor>
            )}
          </>
        );
      }}
      rightSection={
        withCreator && <RightSection onListCreated={handleListCreated} />
      }
      size={size}
      disabled={disabled}
      w={"100%"}
      searchable
      checkIconPosition="left"
      dropdownHeight={dropdownHeight}
      styles={{
        input: {
          borderColor: "var(--mantine-primary-color-4)",
          borderWidth: 2,
          minHeight: inputHeight,
        },
      }}
      newItems={newItems}
      required={required}
    />
  );
};

export default ContactListSelector;
