import { useMemo, useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { useLeadsStore } from "../../../../../stores/LeadsStore";
import {
  findProspect,
  progressProspectToStep,
} from "../../../../../api/PRMAPI";
import {
  ActionIcon,
  Anchor,
  Button,
  Group,
  Indicator,
  LoadingOverlay,
  Modal,
  Paper,
  Stack,
  Text,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import {
  IconArrowMergeAltLeft,
  IconCornerDownLeftDouble,
  IconHourglassHigh,
  IconSpeakerphone,
  IconTimelineEventExclamation,
} from "@tabler/icons-react";
import BackButton from "../../../../../components/View/BackButton";
import { IconCalendarDue } from "@tabler/icons-react";
import { parseDate } from "../../../../../functions/dates";
import { notifications } from "@mantine/notifications";

const ResumeWorkflow = ({ current, isInDetail }) => {
  const lang = useLanguage((s) => s.language);
  const [prospect, setProspect] = useState(current);
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(null);
  const [fetching, setFetching] = useState(true);

  //   const about = useMemo(() => {
  //     const aboutList = [
  //       { key: "%first_name%", value: "firstName" },
  //       { key: "%last_name%", value: "lastName" },
  //       { key: "%job_title%", value: "jobTitle" },
  //     ];
  //     let result = {};

  //     aboutList.forEach((item) => {
  //       let fieldId = fields.find(
  //         (x) => x.identifier_placeholder === item.key
  //       )?.id;
  //       result[item.value] =
  //         current?.properties?.find((x) => x.data_field_id === fieldId)?.value ||
  //         " - ";
  //     });

  //     return result;
  //   }, [fields, current?.properties]);

  const waitlists = useMemo(() => {
    return (
      prospect?.workflow_waiting_steps?.map((x) => {
        return {
          ...x,
          workflow_name: prospect.programmations?.find(
            (p) => p.programmation_id === x.programmation_id
          )?.workflow_name,
          workflow_id: prospect.programmations?.find(
            (p) => p.programmation_id === x.programmation_id
          )?.workflow_id,
        };
      }) || []
    );
  }, [prospect]);

  const initiate = () => {
    setFetching(true);
    findProspect(current.id)
      .then((response) => {
        setProspect(response.data.contact_profile);
        if (prospect.workflow_waiting_steps.length < 1) {
          handleClose();
        }
      })
      .finally(() => setFetching(false));
  };

  const handleOpen = () => {
    setOpened(true);
    initiate();
  };
  const handleClose = () => {
    setOpened(false);
  };

  const handleProgress = ({ programmation_id, step_id }) => {
    setLoading({ programmation_id, step_id });
    progressProspectToStep({
      contact_id: prospect.id,
      programmation_id,
      step_id,
    })
      .then((response) => {
        notifications.show({
          message: lang.prm.resume.success,
          color: "green.8",
        });
        initiate();
      })
      .finally(() => setLoading(null));
  };

  return (
    <>
      {isInDetail ? (
        <Tooltip label={lang.prm.resume.button_tooltip}>
          <ActionIcon
            variant="light"
            onClick={handleOpen}
            disabled={prospect.workflow_waiting_steps.length < 1}
          >
            <IconArrowMergeAltLeft style={{ transform: "scaleY(-1)" }} />
          </ActionIcon>
        </Tooltip>
      ) : (
        <Tooltip
          label={`${lang.prm.resume.paused_on} ${
            prospect.workflow_waiting_steps.length
          } ${lang.prm.campaign}${
            prospect.workflow_waiting_steps.length > 1 ? "s" : ""
          }`}
        >
          <Indicator
            color="red"
            inline
            offset={2}
            disabled={current?.workflow_waiting_steps?.length === 0}
            styles={{
              indicator: {
                width: 8,
                height: 8,
                minWidth: 0,
              },
            }}
            size={16}
          >
            <ActionIcon
              size={"xs"}
              variant="subtle"
              color="pink"
              className="lead-item-button"
              onClick={handleOpen}
            >
              <IconHourglassHigh size={14} />
            </ActionIcon>
          </Indicator>
        </Tooltip>
      )}

      {opened && (
        <Modal
          opened={opened}
          onClose={handleClose}
          size={"lg"}
          title={
            <Text>
              {lang.prm.resume.dialog_title}{" "}
              <b>
                {current.properties.find(
                  (x) => x.identifier_placeholder === "%first_name%"
                )?.value || "-"}{" "}
                {current.properties.find(
                  (x) => x.identifier_placeholder === "%last_name%"
                )?.value || "-"}{" "}
                #{current.id}
              </b>
            </Text>
          }
        >
          <Stack>
            <LoadingOverlay visible={fetching} />
            <Text c={"dimmed"}>{lang.prm.resume.dialog_text}</Text>

            {waitlists.map((waitlist) => (
              <Paper key={waitlist?.programmation_id} p={"sm"} withBorder>
                <Group>
                  <Tooltip label={lang.prm.resume.source}>
                    <ThemeIcon variant="subtle">
                      <IconTimelineEventExclamation opacity={0.65} />
                    </ThemeIcon>
                  </Tooltip>

                  <Anchor
                    target="_blank"
                    href={`/campaign/edit/${waitlist.programmation_id}?workflow=${waitlist?.workflow_id}`}
                    fw={500}
                  >
                    {waitlist?.workflow_name} #{waitlist?.workflow_id}
                  </Anchor>

                  <Tooltip label="Campagne">
                    <ThemeIcon variant="subtle" ml={"auto"}>
                      <IconSpeakerphone style={{ opacity: 0.65 }} />
                    </ThemeIcon>
                  </Tooltip>

                  <Anchor
                    target="_blank"
                    href={`/statistics/campaigns?highlight=${waitlist.programmation_id}`}
                    fw={500}
                    fontSize={15}
                  >
                    {lang.prm.campaign} #{waitlist.programmation_id}
                  </Anchor>
                </Group>
                <Group mt={"md"}>
                  {Object.keys(waitlist?.steps).map((step) => (
                    <Stack key={step} w={"100%"}>
                      <Group>
                        <Text>
                          {lang.prm.resume.step}{" "}
                          <b>
                            {waitlist?.steps[step].name} #{step}
                          </b>
                        </Text>
                        <ThemeIcon
                          variant="subtle"
                          ml={"auto"}
                          size={"sm"}
                          color="gray"
                        >
                          <IconCalendarDue sx={{ fontSize: 12 }} />
                        </ThemeIcon>
                        <Text ml={1} size="sm">
                          {parseDate(waitlist?.steps[step].date_occured)}
                        </Text>
                      </Group>
                      <Group justify="space-between">
                        {waitlist.steps[step].childs.map((child) => (
                          <Tooltip
                            label={lang.prm.resume.choose_step}
                            key={child.id}
                          >
                            <Button
                              key={child.id}
                              w={"48%"}
                              variant="outline"
                              loading={
                                loading?.programmation_id ===
                                  waitlist.programmation_id &&
                                loading?.step_id === child.id
                              }
                              onClick={() =>
                                handleProgress({
                                  programmation_id: waitlist.programmation_id,
                                  step_id: child.id,
                                })
                              }
                              leftSection={
                                <IconCornerDownLeftDouble size={18} />
                              }
                            >
                              {child.name} #{child.id}
                            </Button>
                          </Tooltip>
                        ))}
                      </Group>
                    </Stack>
                  ))}
                </Group>
              </Paper>
            ))}
          </Stack>
          <Group mt={"md"} justify="end">
            <BackButton onClick={handleClose} />
          </Group>
        </Modal>
      )}
    </>
  );
};

export default ResumeWorkflow;
