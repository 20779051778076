import { Button } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { useLanguage } from "../../../../stores/LanguageStore";

const CreateUrlButton = () => {
  const lang = useLanguage((s) => s.language);
  const route = `create`;

  return (
    <Button
      leftSection={<IconPlus size={18} />}
      radius={"sm"}
            color="primary"
      component={Link}
      to={route}
    >
      {lang.url_shortener.create.button_tooltip}
    </Button>
  );
};

export default CreateUrlButton;
