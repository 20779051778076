import { useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import {
  Button,
  Group,
  Indicator,
  Modal,
  Stack,
  TextInput,
} from "@mantine/core";
import { IconFilter, IconPlus } from "@tabler/icons-react";
import { useLeadsStore } from "../../../../../stores/LeadsStore";
import { createNurturing } from "../../../../../api/PRMAPI";
import FilterContent from "../../../../../components/Filter/FilterContent";
import { notifications } from "@mantine/notifications";
import ContactListSelector from "../../../../../components/Selectors/ContactListSelector";
import PopOver from "../../../../../components/View/PopOver";
import BackButton from "../../../../../components/View/BackButton";

const CreateNurturing = ({ handleDone }) => {
  const lang = useLanguage((s) => s.language);
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const filters = useLeadsStore((s) => s.filters);

  const [filter, setFilter] = useState(null);
  const [lists, setLists] = useState([]);

  const handleOpen = () => {
    setOpened(true);
    setLists([]);
    setFilter(null);
  };
  const handleClose = () => {
    setOpened(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    let formData = new FormData(e.target);
    let data = Object.fromEntries(formData);

    createNurturing({
      name: data?.name,
      contact_list_ids: lists.map((x) => x.id),
      filter,
    })
      .then((response) => {
        handleClose();
        notifications.show({
          message: lang.prm.nurturing.created,
          color: "tertiary.8",
        });
        handleDone();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Button onClick={handleOpen} leftSection={<IconPlus size={18} />}>
        {lang.prm.nurturing.create_label}
      </Button>

      <Modal
        opened={opened}
        title={lang.prm.nurturing.create_dialog_title}
        onClose={handleClose}
        size={"50%"}
      >
        <Stack component={"form"} onSubmit={onSubmit}>
          <TextInput
            name="name"
            label={lang.prm.nurturing.create_name}
            placeholder={lang.prm.nurturing.create_name}
            required
            w={"100%"}
            autoFocus
            data-autoFocus
          />

          <PopOver
            opener={(openFunction, ref) => {
              return (
                <Button
                  ref={ref}
                  variant="default"
                  onClick={openFunction}
                  id={"nurturing-filter-" + "creation"}
                >
                  <Indicator
                    color="red"
                    offset={2}
                    disabled={filter?.items?.length === 0 || !filter}
                    label={filter?.items?.length}
                    size={16}
                  >
                    <IconFilter size={18} />
                  </Indicator>
                  <span style={{ marginLeft: 6 }}>
                    {lang.table.toolbar.filter.main_button_label} *
                  </span>
                </Button>
              );
            }}
            popoverChildren={(closePopover) => (
              <FilterContent
                fields={filters}
                id={"nurturing-create"}
                isClient={false}
                pageName={"prm"}
                maxHeight={350}
                mainFilter={filter}
                setMainFilter={(v) => {
                  closePopover();
                  setFilter(v);
                }}
                defaultFiltersClose
                getFieldOptionName={(f) => f.option_name}
              />
            )}
            elevation={3}
          />

          <ContactListSelector
            label={lang.prm.nurturing.lists}
            onChange={setLists}
            values={lists}
            required
            withCreator
          />

          <Group justify="end">
            <BackButton onClick={handleClose} />
            <Button
              disabled={!filter || filter?.values?.length === 0}
              leftSection={<IconPlus size={18} />}
              loading={loading}
              type="submit"
            >
              {lang.prm.nurturing.create}
            </Button>
          </Group>
        </Stack>
      </Modal>
    </>
  );
};

export default CreateNurturing;
