import { useState } from "react";
import EmailSending from "./EmailSending";
import { Button, Group, Text } from "@mantine/core";
import BackButton from "../../../../../components/View/BackButton";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { modals } from "@mantine/modals";
import { getEmailTypeProps } from "../../sendersUtil";
import { notifications } from "@mantine/notifications";

const EmailSendingModal = ({ type, id, setTableLoading }) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(false);

  const handleSendingOptions = (e) => {
    e.preventDefault();
    const fd = new FormData(e.target);
    const data = Object.fromEntries(fd);

    let final_data = {
      enable_unsubscribe_link: data?.enable_unsubscribe_link ? true : false,
      enable_tracking: data?.enable_tracking ? true : false,
      sending_frequency_start: parseInt(data?.sending_frequency_start),
      sending_frequency_end: parseInt(data?.sending_frequency_end),
      warm_up: {
        day_1: data.day1 ? parseInt(data.day1) : null,
        day_2: data.day2 ? parseInt(data.day2) : null,
        day_3: data.day3 ? parseInt(data.day3) : null,
        day_4: data.day4 ? parseInt(data.day4) : null,
        day_5: data.day5 ? parseInt(data.day5) : null,
        day_6: data.day6 ? parseInt(data.day6) : null,
        day_7: data.day7 ? parseInt(data.day7) : null,
        day_8: data.day8 ? parseInt(data.day8) : null,
        day_9: data.day9 ? parseInt(data.day9) : null,
        day_10: data.day10 ? parseInt(data.day10) : null,
        day_11: data.day11 ? parseInt(data.day11) : null,
        day_12: data.day12 ? parseInt(data.day12) : null,
        day_13: data.day13 ? parseInt(data.day13) : null,
        day_14: data.day14 ? parseInt(data.day14) : null,
        day_15: data.day15 ? parseInt(data.day15) : null,
      },
    };

    const main = () =>
      getEmailTypeProps(type).editFunction({
        id,
        data: final_data,
      });

    setLoading(true);
    main()
      .then((response) => {
        notifications.show({
          message: lang.emails_account.row_highlight.updated,
          color: "tertiary.8",
        });
        setTableLoading?.(true);
        modals.close("gmail");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <form onSubmit={handleSendingOptions}>
      <Text size="sm">{lang.senders.emails.google_modal_text}</Text>
      <EmailSending />

      <Group mt={"lg"} justify="end">
        <BackButton onClick={() => modals.close("gmail")} />
        <Button type="submit" loading={loading}>
          {lang.global.save}
        </Button>
      </Group>
    </form>
  );
};

export default EmailSendingModal;
