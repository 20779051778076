import { Button, Paper, Stack, Text } from "@mantine/core";
import { useFlowStore } from "../../../../../../stores/WorkflowStore";
import { useLanguage } from "../../../../../../stores/LanguageStore";

const LinkingNotification = () => {
  const lang = useLanguage((s) => s.language);
  const linking = useFlowStore((s) => s.linking);
  const setLinking = useFlowStore((s) => s.setLinking);

  const handleCancel = () => {
    setLinking(null);
  };

  if (!linking) {
    return "";
  }

  return (
    <Paper
      pos={"fixed"}
      bottom={16}
      left={"50%"}
      style={{ transform: "translateX(-50%)" }}
      withBorder
      p={16}
      shadow="md"
    >
      <Stack justify="center" align="center">
        <Text>
          {lang.workflows.linking_text} #{linking?.id}
        </Text>

        <Button onClick={handleCancel} w={200}>
          {lang.global.cancel}
        </Button>
      </Stack>
    </Paper>
  );
};

export default LinkingNotification;
